import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseURL from "../../../services/api";
import axios from "axios";

export const updateMyProfile = createAsyncThunk(
  "myProfile/updateUser",
  async (profileData: { [key: string]: string | number }) => {
    const response = await axios.post(
      "https://mentormenteedev.com/api/auth/updateuser",
      profileData,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }
    );
    return response?.data;
  }
);
