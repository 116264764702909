// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarreVisualStories .swiper-button-prev::after,
.CarreVisualStories .swiper-button-next::after {
  display: none;
}
.CarreVisualStories .carosalButtons {
  min-width: 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #cdcdcd;
}
.CarreVisualStories .carosalButtons svg {
  width: 20px;
  height: 20px;
}
.CarreVisualStories .careertalk-sec {
  height: 300px;
}
.CarreVisualStories .swiper-slide.swiper-slide-active {
  cursor: pointer;
  pointer-events: auto;
  opacity: 1;
}
.CarreVisualStories .swiper-slide.swiper-slide-active .careertalk-sec {
  height: 350px;
}
.CarreVisualStories .swiper-slide {
  transition: 0.5s;
  pointer-events: none;
}
.CarreVisualStories .swiper-wrapper {
  display: flex;
  align-items: center;
  transition: 0.5s;
  min-height: 350px;
}`, "",{"version":3,"sources":["webpack://./src/components/career-visual-stories/career-visual-style.scss"],"names":[],"mappings":"AACE;;EAEE,aAAA;AAAJ;AAEE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,2CAAA;EACA,yBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEE;EACE,aAAA;AAAJ;AAEE;EAEE,eAAA;EACA,oBAAA;EACA,UAAA;AADJ;AAEI;EACE,aAAA;AAAN;AAGE;EACE,gBAAA;EACA,oBAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AADJ","sourcesContent":[".CarreVisualStories {\n  .swiper-button-prev::after,\n  .swiper-button-next::after {\n    display: none;\n  }\n  .carosalButtons {\n    min-width: 44px;\n    width: 44px;\n    height: 44px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #f5f5f5;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    border: 1px solid #cdcdcd;\n    svg {\n      width: 20px;\n      height: 20px;\n    }\n  }\n  .careertalk-sec {\n    height: 300px;\n  }\n  .swiper-slide.swiper-slide-active {\n    // transform: scale(1);\n    cursor: pointer;\n    pointer-events: auto;\n    opacity: 1;\n    .careertalk-sec {\n      height: 350px;\n    }\n  }\n  .swiper-slide {\n    transition: 0.5s;\n    pointer-events: none;\n  }\n  .swiper-wrapper {\n    display: flex;\n    align-items: center;\n    transition: 0.5s;\n    min-height: 350px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
