import React from "react";
import BlogsImage from "../../../images/blog-article/blogsandarticles.jpeg";
import ShareIcon from "../../../images/blog-article/share-icon.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faShare } from "@fortawesome/free-solid-svg-icons";
import Popover from "../../../components/popover/Popover";
import SocialMediaList from "./SocialMediaMenuList";
import MeatBallMenuIcon from "../../../images/blog-article/meatballs-menu.svg";
interface BlogInfoCardProps {
  dateValue?: Boolean;
  rowReverse?: Boolean;
  blogInfo: any;
  height: any;
  categoryOrderReverse: Boolean;
  borderBottom: Boolean;
  imageField: Boolean;
  getCurrentHoveredItem: (currentHoverItem: any) => void;
}

const BlogInfoCard: React.FC<BlogInfoCardProps> = ({
  dateValue,
  rowReverse,
  blogInfo,
  getCurrentHoveredItem,
  height = "auto",
  categoryOrderReverse = false,
  borderBottom = false,
  imageField = true,
}) => {
  // console.log("blogInfo", blogInfo);
  const handleMouseOver = (currentHoveringItem: any) => {
    console.log("handleMouseOver", currentHoveringItem);
    getCurrentHoveredItem(currentHoveringItem);
  };

  return (
    <div
    // to={`/blogs/${blogInfo.category?.toLocaleLowerCase()}/${blogInfo?.title?.toLocaleLowerCase()}`}
    // state={{
    //   from: "blogInfoCard",
    //   blogDetail: blogInfo,
    //   blogCategory: blogInfo?.category,
    // }}
    // className="h-full"
    >
      <div
        className={`grid grid-rows-1 grid-flow-col w-full gap-4 ${
          borderBottom && "border-b-2 border-[#CCCCCC]"
        }  h-${height || `full`}`}
      >
        {/* <div className="flex grid grid-cols-6 gap-4 py-4 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-6">
        <div className="col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-2">
          <img src={BlogsImage} className="object-cover w-full h-full" />
        </div>
        <div className="flex flex-col w-full col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-4 height-full">
          <h1 className="text-sm font-bold ">
            Top 10 NIRF Engineering Colleges in India
          </h1>
          <div className="flex items-end grow md:col-span-2 lg:col-span-2">
            <p className="border-x-2 border-[#173CBA] px-1.5 text-[#173CBA] leading-none text-base sm:text-xs lg:text-sm xl:text-sm">
              College
            </p>
          </div>
        </div>
      </div> */}

        {/* <div className="flex flex-row gap-2 py-4">
        <div className="basis-1/4">
          <img src={BlogsImage} className="object-cover w-full h-full" />
        </div>

        <div className="flex flex-col basis-1/1">
          <h1 className="text-sm font-bold">
            Top 10 NIRF Engineering Colleges in India
          </h1>
          <div className="flex items-end grow">
            <p className="border-x-2 border-[#173CBA] px-1.5 text-[#173CBA] leading-none text-base sm:text-xs lg:text-sm xl:text-sm">
              College
            </p>
          </div>
        </div>
      </div> */}

        <div
          className={`flex gap-3 py-3 h-27 ${
            rowReverse ? "flex-row-reverse" : ""
          }`}
          onMouseEnter={() => handleMouseOver(blogInfo)}
        >
          {imageField && (
            <div className="w-1/4 h-auto shrink-0">
              <img
                src={blogInfo?.imageUrl || BlogsImage}
                className="object-cover w-full h-20"
              />
            </div>
          )}
          <div
            className={`w-${
              imageField ? "3/4" : "full"
            }  h-auto flex flex-col gap-y-2`}
          >
            {categoryOrderReverse && (
              <div className="flex">
                <p className="text-[#173CBA] leading-none text-base sm:text-xs lg:text-sm xl:text-sm">
                  {blogInfo?.category || "Category"}
                </p>
              </div>
            )}
            <Link
              to={`/blogs/${blogInfo.category?.toLocaleLowerCase()}/${
                blogInfo?.newsId
              }/${blogInfo?.title?.toLocaleLowerCase()}`}
              state={{
                from: "blogInfoCard",
                blogDetail: blogInfo,
                blogCategory: blogInfo?.category,
              }}
              className={`${
                categoryOrderReverse && ""
              } font-bold text-sm md:leading-none lg:leading-normal line-clamp-2 hover:text-[#173CBA]`}
            >
              {blogInfo?.title || "Top 10 NIRF Engineering Colleges in India"}
            </Link>
            {dateValue && (
              <p className="text-sm">
                {moment(blogInfo?.publishedDate).format("LL")}
              </p>
            )}
            {!categoryOrderReverse && (
              <div className="flex items-end grow ">
                <p className="border-x-2 border-[#173CBA] px-1.5 text-[#173CBA] leading-none text-base sm:text-xs lg:text-sm xl:text-sm">
                  {blogInfo?.category || "Category"}
                </p>
                <div className="flex items-end justify-end visible grow socialMedia-icon-block-responsive ">
                  <div className="listing_card_dropdown ">
                    <Popover content={<SocialMediaList />}>
                      <button className="" name="Share news">
                        <img src={MeatBallMenuIcon} alt="menu" width="20" />
                      </button>
                    </Popover>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogInfoCard;
