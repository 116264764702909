import { createSlice } from "@reduxjs/toolkit";
import { updateMyProfile } from "./myProfileThunk";

interface IProfile {
  userData: { [key: string]: string | number };
  loading: boolean;
  error: string | null;
}

const initialState: IProfile = {
  userData: {
    firstName: "Ashok",
    lastName: "Reddy",
    email: "ashokkunam999@gmail.com",
    password: "string",
    mobileNumber: "8888888888",
    currentLevelOfStudy: "12th",
    lookingForDegree: "UG",
    lookingForStream: "1",
    lookingForCourse: "3",
    state: "1",
    city: "1",
  },
  loading: false,
  error: null,
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMyProfile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.toString() || "Error updating profile data";
      });
  },
});

export default myProfileSlice.reducer;
