// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-hidden {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.2s ease-out;
}

.blog-page-card:hover .icon-hidden {
  opacity: 1;
  transform: translateX(0);
}

.related-blog:last-child {
  border: none !important;
}

.listing_card_dropdown_content {
  background-color: #f1f1f1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  display: none;
  min-width: 160px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.listing_card_dropdown:hover .listing_card_dropdown_content {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/screens/blogs-articles/components/BlogListItem.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,4BAAA;EACA,6BAAA;AACF;;AAEA;EACE,UAAA;EACA,wBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,yBAAA;EACA,2CAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".icon-hidden {\n  opacity: 0;\n  transform: translateX(-100%);\n  transition: all 0.2s ease-out;\n}\n\n.blog-page-card:hover .icon-hidden {\n  opacity: 1;\n  transform: translateX(0);\n}\n\n.related-blog:last-child {\n  border: none !important;\n}\n\n.listing_card_dropdown_content {\n  background-color: #f1f1f1;\n  box-shadow: 0 8px 16px 0 #0003;\n  display: none;\n  min-width: 160px;\n  position: absolute;\n  right: 0;\n  z-index: 1;\n}\n\n.listing_card_dropdown:hover .listing_card_dropdown_content {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
