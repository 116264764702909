// YearTabs.js
import React, { useState } from "react";
import LocationIcon from "../../../../images/college/details/location.svg";
import MailIcon from "../../../../images/college/details/mail.svg";
import PhoneIcon from "../../../../images/college/details/phone.svg";
import { FaLongArrowAltRight } from "react-icons/fa";
import { LiaSearchPlusSolid } from "react-icons/lia";
import SRM from '../Pick-Info/Images/srm.png'
import { FiPhoneCall } from "react-icons/fi";
import { IoMdMail } from "react-icons/io";
import { PiVirtualReality } from "react-icons/pi";
import { FiYoutube } from "react-icons/fi";
import { FaWifi } from "react-icons/fa6";

interface Contact_College_DetailsProps {
    Contacts: string;
}
interface CampusLayoutProps {
    name: string;
    description: string;
    imageUrl: string;
    city: string;
}
const campusLayouts = [
    {
        name: "Main Campus",
        description: "The central hub of our university, featuring state-of-the-art facilities and vibrant student life.",
        city: "Kattankulathur-chennai",
        imageUrl: "https://www.building-maps.com/wp-content/uploads/2020/10/Campus-Map-Full-Illustration-Map.jpg"
    },
    {
        name: "Science and Engineering Campus",
        description: "A dedicated space for science and engineering students, equipped with modern labs and research centers.",
        city: "Vadapalani-chennai",
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/SVCE_campus_layout_map.png/1200px-SVCE_campus_layout_map.png"
    },
    {
        name: "Sports Complex",
        description: "Our sports complex includes a stadium, swimming pool, and various courts and fields for different sports.",
        city: "Kattankulathur-chennai",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2YqdmgwZkmwM40QCgtzeoILjI6JTqBfLC-gZrGJwFXw&s"
    },
    {
        name: "Library and Research Center",
        description: "A quiet place for study and research, offering a vast collection of books, journals, and digital resources.",
        city: "Thiruchirapalli-chennai",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrDFVqJmhch1VZDi2b_Lp3gSGgPqK_guv0qc-wB43xzg&s"
    },
    {
        name: "Student Housing",
        description: "Comfortable and secure housing options for students, with a range of amenities and services.",
        city: "Kattankulathur-chennai",
        imageUrl: "https://i0.wp.com/www.re-thinkingthefuture.com/wp-content/uploads/2020/08/A1607-10-things-to-remember-while-designing-a-university-campus-Image-7.jpg?w=999"
    },
    {
        name: "Arts and Humanities Campus",
        description: "A campus dedicated to the arts and humanities, featuring studios, theaters, and exhibition spaces.",
        city: "Delhi-NCR",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQQmsDqbMS5Ei59PDlEE9eSxltVzDcbyvW5pg_Z7X27w&s"
    }
];
const Contact_College_Details: React.FC<Contact_College_DetailsProps> = ({ Contacts }) => {
    return (
        <div className=" relative" id="Contact-us">
            <div className="container mx-auto flex flex-col gap-10">
                <div id={Contacts} className="flex justify-center items-center">
                    <h3 className="text-[#231f40] text-3xl font-extrabold text-center">
                        Contact SRM
                    </h3>
                </div>
                <div className="flex flex-col justify-between items-center gap-6  rounded-2xl p-8 w-full">
                    <div className="flex flex-col lg:flex-row justify-between items-center w-full">
                        <div className="flex flex-col w-full lg:w-[40%] gap-4 justify-start items-start">
                            <div className="flex flex-row items-start justify-center gap-4">
                                <div className="flex gap-0.5">
                                    <img src={LocationIcon} alt="location" />
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div style={{ fontSize: '1.5rem' }} className=" text-base font-bold tracking-[1.60px]">
                                        Our Campuses
                                    </div>
                                    <h4 className="font-semibold flex items-center gap-2  text-lg tracking-[0] leading-[32px]">
                                        <FaLongArrowAltRight />
                                        Kattankulathur-chennai
                                    </h4>
                                    <h4 className="font-semibold flex items-center gap-2  text-lg tracking-[0] leading-[32px]">
                                        <FaLongArrowAltRight />
                                        Ramapuram-chennai
                                    </h4>
                                    <h4 className="font-semibold flex items-center gap-2  text-lg tracking-[0] leading-[32px]">
                                        <FaLongArrowAltRight />
                                        Vadapalani-chennai
                                    </h4>
                                    <h4 className="font-semibold flex items-center gap-2  text-lg tracking-[0] leading-[32px]">
                                        <FaLongArrowAltRight />
                                        Thiruchirapalli-chennai
                                    </h4>
                                    <h4 className="font-semibold flex items-center gap-2  text-lg tracking-[0] leading-[32px]">
                                        <FaLongArrowAltRight />
                                        Delhi-NCR
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-4 w-full lg:w-[40%] rounded-2xl overflow-hidden">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.799987272101!2d78.41578022582861!3d17.51705874905527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91d800543355%3A0x521b7f8d6bf19243!2sKailash%20Hills%2C%20Mahadevpur%20Colony%2C%20Hyderabad%2C%20Telangana%20500055!5e0!3m2!1sen!2sin!4v1714675498930!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ background: "#E0E8F2" }}>
                    <div className="flex justify-center">

                        <div style={{ fontSize: '1.5rem' }} className=" text-base  font-bold tracking-[1.60px] my-3 underline_text underline--blue">Campus Layout</div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 p-3" >
                        {campusLayouts && campusLayouts?.map((p, i) => {
                            return (
                                <div className="container_img" key={i}>
                                    <div className="content__">
                                        <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
                                            <div className="content-overlay"></div>


                                            <img src={p.imageUrl} alt="Map Image" style={{ height: '40vh' }} />
                                            <div className="content-details fadeIn-bottom">
                                                <div style={{ color: '#FFFFFF', fontSize: '1rem' }}>{p.city}</div>
                                                <div className="content-title flex justify-center"><LiaSearchPlusSolid style={{ fontSize: '1.5rem', color: '#FFFFFF' }} /></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            </div>
            <div className="p-5 my-10 flex flex-wrap items-center -mx-3" style={{ background: '#E8E8E8' }}>

                    <div className="w-full md:w-1/2 lg:w-1/5 px-3 mb-6">

                        <img src={SRM} style={{ width: '100%' }} />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/5 px-3 mb-6">
                        <div className="flex flex-col ">
                            <div className="flex gap-3 items-center contact_info_text">
                                <FiPhoneCall className="contact_info_text" /> +91 1432489765
                            </div>
                            <div className="flex gap-3 items-center contact_info_text">
                                <FiPhoneCall className="contact_info_text" /> +91 8075432189
                            </div>
                            <div className="flex gap-3 items-center contact_info_text">
                                <IoMdMail className="contact_info_text" /> collegementor@gmail.com
                            </div>

                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/5 px-3 mb-6">

                        <div className="flex items-center gap-3 contact_info_text"><PiVirtualReality className="contact_info_text" style={{ fontSize: '3rem' }} /> Virtual Tour</div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/5 px-3 mb-6">

                        <div className="flex items-center gap-3 contact_info_text"><FiYoutube className="contact_info_text" style={{ fontSize: '3rem' }} /> Vedios</div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/5 px-3 mb-6">

                        <div className="flex items-center gap-3 contact_info_text"><FaWifi className="contact_info_text" style={{ fontSize: '3rem' }} /> Community Radio</div>
                    </div>

                </div>
        </div>
    );
};
export default Contact_College_Details;
