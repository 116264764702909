import React from "react";
import { Outlet } from "react-router-dom";
import AuthHeader from "../components/AuthHeader";

const AuthLayout: React.FC = () => {
  return (
    <>
      {/* <h1>Authentication Layout</h1>
      <AuthHeader /> */}
      <Outlet />
    </>
  );
};

export default AuthLayout;
