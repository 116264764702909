import React from "react";
import { motion } from "framer-motion";

type CardProps = {
  title: string;
  isSelected: boolean;
  onClick: () => void;
  cardSize: any;
};

const OptionCard: React.FC<CardProps> = ({
  title,
  isSelected,
  onClick,
  cardSize = "auto",
}) => {
  return (
    <motion.div
      onClick={onClick}
      animate={isSelected ? { scale: 1.1 } : { scale: 1 }}
      transition={{ duration: 0.3 }}
      className={`flex items-center justify-start p-2 rounded-full shadow-md transition-shadow duration-300 ease-in-out w-${cardSize} max-sm:w-full cursor-pointer border-2 transform hover:scale-105 hover:shadow-2xl bg-white
      ${isSelected ? `border-[#00c798] border-1` : "border-gray-300"} 
      ${isSelected ? "shadow-lg" : "hover:shadow-lg"}
      `}
    >
      <div className="relative mr-2">
        <div
          className={`h-4 w-4 rounded-full border-2 flex items-center justify-center ${
            isSelected ? `#00c798` : "border-gray-400"
          }`}
          style={{ backgroundColor: isSelected ? "#00c798" : "transparent" }}
        ></div>
      </div>

      <span className="overflow-hidden text-sm font-semibold text-ellipsis line-clamp-1">
        {title}
      </span>
    </motion.div>
  );
};

export default OptionCard;
