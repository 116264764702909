import { title } from "process";
import React from "react";

const scholarships = [
  {
    title: "SRM IST Scholarship Types",
    description:
      "SRM Founder's Scholarship is available for students who have secured 95% and above in CBSE or in State Board examinations. The scholarship covers 100% of tuition, hostel and mess fee.",
  },
  {
    title: "SRM IST Founder’s Scholarship",
    description:
      "This scholarship aims to encourage toppers in the qualifying examination or JEE exam to seek admission in the first year of BTech. It also encourages outstanding sports personalities to seek admission in Diploma/ UG/ PG programmes offered at SRM IST. Candidates must have passed HSC or respective degree in regular mode in the first attempt in the current year of admission and meet the conditions provided in the table below.",
    tableData: [
      {
        "Sub Category": "Top 100 rank in SRMJEEE (UG)",
        "Concession Details":
          "100 % fee concession in Tuition fees and Hostel/ Transport fees.",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category": "Top 10,000 rank in IIT JEE (Mains)",
        "Concession Details":
          "100 % fee concession in Tuition fees and Hostel/ Transport fees.",

        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category":
          "State first rank in HSC of respective state boards or CBSE within India",
        "Concession Details":
          "100 % fee concession in Tuition fees and Hostel/ Transport fees.",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category":
          "District topper in HSC in Tamil Nadu State Board or CBSE within Tamil Nadu",
        "Concession Details":
          "100 % fee concession in Tuition fees and Hostel/ Transport fees.",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category":
          "Exemplary sports persons representing University/ District (Province)/ Zone/ State/ National/ International",
        "Concession Details":
          "100 % fee concession in Application & Registration fee, Tuition fees, Hostel/ Transport fees and Examination fees",
        "Pre-requisites": [
          "Only select programmes as decided by SRMIST are permissible under the sports quota",
          "Must be eligible for First Year Diploma/UG/PG programme permitted under the quota",
        ],
      },
    ],
  },
  {
    title: "SRM IST Merit Scholarship",
    description:
      "The scholarship aims to encourage deserving candidates in SRMJEEE (UG) or HSC examination to seek admission in the first year of BTech. To be eligible for the SRM IST Merit scholarship, candidates must have passed Class 12 in regular mode in the first attempt in the current year of admission and meet the conditions given below:",
    tableData: [
      {
        "Sub Category": "SRMJEEE Rank 101-500",
        "Concession Details": "100 %",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category": "SRMJEEE Rank 501-1000",
        "Concession Details": "75 %",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category": "SRMJEEE Rank 1001-2000",
        "Concession Details": "50 % ",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category": "SRMJEEE Rank 2001-3000",
        "Concession Details": "25 %",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
      {
        "Sub Category":
          "HSC Merit Scholarship 90% and above in PCM/ PCB & SRMJEEE Rank up to 10000",
        "Concession Details": "10 - 25 % ",
        "Pre-requisites": [
          "Must be eligible for admission to First Year B.Tech",
          "Must attend On-campus counselling",
        ],
      },
    ],
  },
  {
    title: "SRM IST Arts and Culture Scholarship ",
    description:
      "This scholarship is awarded to meritorious students in Art forms, creativity and cultural activities. Check the details in the following table:",
  },
];

const ClgScholarship = () => {
  return (
    <div className="flex flex-col py-16" id="Scholarships">
      <div className="container mx-auto">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <h3 className="text-3xl font-extrabold text-[#231F40] text-center">
              Scholarships
            </h3>
          </div>
          <div className="flex flex-col gap-4">
            {scholarships.map((scholarship, index) => (
              <div key={index} className="flex flex-col gap-4">
                <h4 className="text-2xl font-bold text-[#231F40]">
                  {scholarship.title}
                </h4>
                <p className="text-lg text-[#231F40]">
                  {scholarship.description}
                </p>
                {scholarship.tableData && (
                  <table className="w-full">
                    <thead>
                      <tr>
                        {Object.keys(scholarship.tableData[0]).map((key) => (
                          <th
                            key={key}
                            className="text-lg py-2 px-3 bg-gray-800 text-white whitespace-nowrap"
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="border border-bg-gray-800 text-white">
                      {scholarship.tableData.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(data).map((value, colIndex) => (
                            <td
                              key={colIndex}
                              className="text-lg text-[#231F40] py-2 px-3 text-center border border-bg-gray-800"
                            >
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClgScholarship;
