import { Description } from "@mui/icons-material";
import { title } from "process";
import React from "react";
import { SVGProps } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const CourseSubjectsData = {
  title: "Course Subjects",
  MechanicalEngUGCouse: {
    title: "UG Courses",
    description:
      "The syllabus for Mechanical Engineering at undergraduate courses depends on the institute, but it remains almost the same for every institute. Some of the subjects offered at undergraduate courses are Electrical Sciences, Engineering Drawing, Engineering Mathematics, Basic Electronics Laboratory, and Physics Laboratory. The below mentioned syllabus is from Indian Institute of Technology Guwahati.",
    courseData: {
      details: [
        {
          title: "Semester 1",
          content: "",
        },
        {
          title: "Mathematics - 1",
          content: "Workshop - 1",
        },
        {
          title: "Chemistry",
          content: "Electrical Science",
        },
        {
          title: "Chemistry Laboratory",
          content: "English*/HSS Elective",
        },
        {
          title: "Physics - 1",
          content: "Physical Training 1",
        },
        {
          title: "Engineering Drawing",
          content: "NCC / NSS / NSO",
        },
      ],
    },
  },
  MechanicalEngPGCouse: {
    title: "PG Courses",
    description:
      "Candidates can check the syllabus for Mechanical Engineering at postgraduate level on the official website of the chosen institute. The course curriculum of Mechanical Engineering is wide and includes subjects such as Computer Organisation & Architecture. Data Structure & Algorithms Laboratory, and Theoretical Foundations of Computer Science. Mentioned below is the syllabus of Maulana Abul Kalam Azad University of Technology.",
    courseData: {
      details: [
        {
          title: "Semester 1",
          content: "",
        },
        {
          title: "Computer Organisation & Architecture",
          content: "Data Structure & Algorithms Laboratory",
        },
        {
          title: "Data Structure & Algorithms",
          content: "Networks & System Programming Laboratory",
        },
        {
          title: "Systems Programming & Operating Systems",
          content: "-",
        },
        {
          title: "Elective 1",
          content: "Physical Training 1",
        },
      ],
    },
  },
};

const MechanicalEngUGCouseTable = ({ data }: { data: any }) => {
  return (
    <>
      <div className="flex flex-col bg-[#fff] relative">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {data.title}
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
          <p className="text-base text-[#4C495C] leading-7">
            {data.description}
          </p>
          <table className="w-full border-collapse border-[#383838] border">
            <tbody>
              {data.courseData.details.map((detail: any, index: number) => (
                <tr
                  key={index}
                  className={`border-[#383838] border ${
                    index % 2 === 0 ? "bg-[#EEF2FF]" : "bg-[#fff]"
                  }`}
                >
                  <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                    {detail.title}
                  </td>
                  <td className="py-2.5 px-5 text-base text-[#4C495C]">
                    {Array.isArray(detail.content)
                      ? detail.content.join(", ")
                      : typeof detail.content === "object"
                      ? Object.entries(detail.content)
                          .map(([key, value]) => `${key}: ${value}`)
                          .join("\n")
                      : detail.content}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="flex flex-col gap-6 items-end">
          <button className="text-base text-white bg-[--primary-color] py-2 px-4 flex rounded items-center justify-center gap-1 w-36">
            Read more{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
            >
              <path
                d="M7.2915 11.2773L12.4998 16.4857L17.7082 11.2773"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div> */}
      </div>
    </>
  );
};
const MechanicalEngPGCouseTable = ({ data }: { data: any }) => {
  return (
    <>
      <div className="flex flex-col bg-[#fff] relative">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {data.title}
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
          <p className="text-base text-[#4C495C] leading-7">
            {data.description}
          </p>
          <table className="w-full border-collapse border-[#383838] border">
            <tbody>
              {data.courseData.details.map((detail: any, index: number) => (
                <tr
                  key={index}
                  className={`border-[#383838] border ${
                    index % 2 === 0 ? "bg-[#EEF2FF]" : "bg-[#fff]"
                  }`}
                >
                  <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                    {detail.title}
                  </td>
                  <td className="py-2.5 px-5 text-base text-[#4C495C]">
                    {Array.isArray(detail.content)
                      ? detail.content.join(", ")
                      : typeof detail.content === "object"
                      ? Object.entries(detail.content)
                          .map(([key, value]) => `${key}: ${value}`)
                          .join("\n")
                      : detail.content}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="flex flex-col gap-6 items-end">
          <button className="text-base text-white bg-[--primary-color] py-2 px-4 flex rounded items-center justify-center gap-1 w-36">
            Read more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
            >
              <path
                d="M7.2915 11.2773L12.4998 16.4857L17.7082 11.2773"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div> */}
      </div>
    </>
  );
};

const CourseSubjects = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "" } = data || {};
  return (
    <>
      <div className="flex flex-col gap-6" id="subjects">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-6">
            <h3 className="flex text-xl lg:text-2xl font-semibold text-[#201F1F] m-0 px-2 py-8 md:py-5 md:px-9 gap-8">
              {`Course Subjects`}
            </h3>
          </div>
          <div className="flex flex-col gap-6">
            <MechanicalEngUGCouseTable
              data={CourseSubjectsData.MechanicalEngUGCouse}
            />
          </div>
          <div className="flex flex-col gap-6">
            <MechanicalEngPGCouseTable
              data={CourseSubjectsData.MechanicalEngPGCouse}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSubjects;
