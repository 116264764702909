import React from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { css } from "@emotion/css";
// Define the type for the video sources array
const videoSrcs: string[] = [
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
];
const SucessStories: React.FC = () => {
  return (
    <>
      <div className='MentorPages_heading'>Success Stories</div>
      <div
        className='Mentor_stories my-5'
      >
        {videoSrcs.map((src,i) => (
          <HoverVideoPlayer
            key={src}
            videoSrc={src}
            sizingMode="container"
            controlsList="nodownload noloading notime"
           
            
            onHoverStart={() => {
              console.log("Userjustmousedover");
            }}
            className='Mentor_stories_player'
            hoverOverlay={
              <div
                className={css`
                  position: absolute;
                  bottom:0%;
                  width: 100%;
                  
                  background: rgba(0, 0, 0, 0.7);
                  color: white;
                  text-align: center;
                  padding: 1em;
                  font-size: 1em;
                  opacity: 0;
                  transition: opacity 0.3s ease;
                  &:hover {
                    opacity: 1;
                  }
                `}
              >
                Storie Content {i + 1}
              </div>}
          />
        ))}
      </div>
    </>
  );
};
export default SucessStories;
