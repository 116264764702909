import React, { useEffect } from "react";
import MultiStepForm from "./components/MultiStepForm";
import RegistrationLayout from "./components/common/RegistrationLayout/RegistrationLayout";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store/store";

const Registration: React.FC = () => {
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);

  useEffect(() => {}, []);

  return (
    <>
      {/* <div className="grid grid-flow-col grid-cols-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
        <div className="hidden lg:block bg-blue-gray-100">Registration</div>
        <div className="col-span-2">
          <MultiStepForm />
        </div>
      </div> */}
      {/* <MultiStepForm /> */}

      <RegistrationLayout>
        <MultiStepForm />
      </RegistrationLayout>
    </>
  );
};

export default Registration;
