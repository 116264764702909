import React from "react";

interface DownloadItem {
  title: string;
  description: string;
  link: string;
}

const downloadItems: DownloadItem[] = [
  {
    title: "JEE mains Papers",
    description:
      "JEE Main Previous Year Question Papers with Answer Key and Solution (Free PDF Download)",
    link: "",
  },
  {
    title: "NEET ",
    description:
      "JEE Main Previous Year Question Papers with Answer Key and Solution (Free PDF Download)",
    link: "",
  },
  {
    title: "GRE",
    description: "Release notes for the latest version.",
    link: "",
  },
];

const DownloadExams: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-semibold mb-4 text-gray-800">
            Downloads
          </h1>
          <p className="text-[#383838] mb-6">
            Below you can find the resources available for download. Click on
            the titles to get the files.
          </p>
          <div className="space-y-4">
            {downloadItems.map((item, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <h2 className="text-xl font-medium text-gray-900">
                  {item.title}
                </h2>
                <p className="text-[#383838] mb-2">{item.description}</p>

                <button className="text-[#173CBA] hover:text-[#173CBA]">
                  Download
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadExams;
