import React, { useState, useEffect } from "react";
import MountLogo from "../../images/study-abroad/MountSaintVincentUniversity_logo.png";
import MissouriLogo from "../../images/study-abroad/missoristates.png";
import BrockLogo from "../../images/study-abroad/brock-logo.png";
import SounthernLogo from "../../images/study-abroad/university-southern-logo.png";
import MiamiLogo from "../../images/study-abroad/miami-university-logo.png";
import DeakinLogo from "../../images/study-abroad/deakin-university-logo.png";
import WesternLogo from "../../images/study-abroad/WSU_Logo.png";
import "./CarosalStyles.scss";

const logoData = [
  { title: "Mount Saint Vincent University", imgUrl: MountLogo },
  { title: "Missouri State University", imgUrl: MissouriLogo },
  { title: "Brock University", imgUrl: BrockLogo },
  { title: "University of Southern", imgUrl: SounthernLogo },
  { title: "Miami University", imgUrl: MiamiLogo },
  { title: "Deakin University", imgUrl: DeakinLogo },
  { title: "Western State University", imgUrl: WesternLogo },
];

const repeatedLogoData = Array.from({ length: 5 }, () => logoData).flat();

const CarosalLogos: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % repeatedLogoData.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [repeatedLogoData]);

  return (
    <div className="container mx-auto">
      <div className="relative w-full overflow-hidden">
        <div className="logo-container flex items-center justify-between py-8">
          <div
            className="slidetrack flex gap-8"
            style={{
              transform: `translateX(-${currentImageIndex * 100}%)`,
              transition: "transform 2s ease-in-out",
            }}
          >
            {repeatedLogoData.map((logo, index) => (
              <div key={index} className="logo-item">
                <img
                  src={logo.imgUrl}
                  alt={logo.title}
                  className="w-full h-full transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarosalLogos;
