import React from "react";
import { Accordion, Option } from "./Accordion";

interface FilterOption {
  key: string;
  label: string;
  isSelected: boolean;
}

export interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface DesktopFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
}

const DesktopFilters: React.FC<DesktopFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
}) => {
  const handleFilterChange = (
    value: string,
    filter: FilterCategory,
    index: number
  ) => {
    setSelectedVal((prevSelected: any) => {
      const updatedCategory =
        prevSelected[filter.categoryKey] === value ? "" : value;

      const newSelectedVal = {
        ...prevSelected,
        [filter.categoryKey]: updatedCategory,
      };

      const requestPayload: { [key: string]: string | number } = {};

      Object.keys(newSelectedVal).forEach((key) => {
        const value = newSelectedVal[key];
        if (value) {
          requestPayload[key] = value;
        }
      });

      requestPayload.sortBy = "";
      requestPayload.offset = 0;
      requestPayload.fetchCount = 10;
      requestPayload.search = "";

      return newSelectedVal;
    });

    const updatedFilter = filters.map((filter, filterIndex) => {
      if (filterIndex === index) {
        return {
          ...filter,
          options: filter.options.map((option) => {
            if (option.key === value) {
              return { ...option, isSelected: !option.isSelected };
            }
            return option;
          }),
        };
      }
      return filter;
    });

    setFilters(updatedFilter);
  };

  return (
    <>
      {filters
        .filter((filter) => filter.categoryKey !== "sortBy")
        .map((filter, index) => (
          <Accordion
            key={index}
            filters={{
              label: filter.categoryName,
              options: filter.options,
            }}
            isOpen={false}
            onFilterChange={(value: string) =>
              handleFilterChange(value, filter, index)
            }
            title={filter.categoryName}
          />
        ))}
    </>
  );
};

export default DesktopFilters;
