import { createSlice } from "@reduxjs/toolkit";
import { fetchLandingData } from "./collegePredictorIntermediateThunk";

interface DataState {
  landingData: any;
  landingDataLoading: boolean;
  landingDataError: string | null;
}

const initialState: DataState = {
  landingData: null,
  landingDataLoading: false,
  landingDataError: null,
};

const collegePredictorIntermediate = createSlice({
  name: "collegePredictorIntermediate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLandingData.pending, (state) => {
        state.landingDataLoading = true;
        state.landingDataError = null;
      })
      .addCase(fetchLandingData.fulfilled, (state, action) => {
        state.landingDataLoading = false;
        state.landingData = action.payload?.data;
      })
      .addCase(fetchLandingData.rejected, (state, action) => {
        state.landingDataLoading = false;
        state.landingDataError =
          action.error.message || "Failed to fetch predictor intermediate data";
      });
  },
});

export default collegePredictorIntermediate.reducer;
