import React from "react";

const SocialMediaComp: React.FC = () => {
  return (
    <div className="flex flex-col py-16">
      <div className="container mx-auto">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-4">
            <h3 className="text-3xl font-bold text-center">SRM Socials</h3>
          </div>
          <div className="grid grid-cols-3 gap-8 justify-center items-center">
            <div className="flex items-center justify-center">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSRMUniversityOfficial%2F&tabs=timeline&width=300&height=300&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1008466730845215"
                width="100%"
                height="300"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                title="Facebook Page"
              ></iframe>
            </div>
            <div className="flex items-center justify-center">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSRMUniversityOfficial%2F&tabs=timeline&width=300&height=300&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1008466730845215"
                width="100%"
                height="300"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                title="Facebook Page"
              ></iframe>
            </div>
            <div className="flex items-center justify-center">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSRMUniversityOfficial%2F&tabs=timeline&width=300&height=300&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1008466730845215"
                width="100%"
                height="300"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                title="Facebook Page"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaComp;
