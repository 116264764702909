import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";

const Button = styled.button`
  background-color: #ffffff;
  color: #047ae8;
  padding: 5px 15px;
  border-radius: 50px;
  outline: 0;
  border: 1px solid #047ae8;
  text-transform: uppercase;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &.active-class {
    background-color: #047ae8;
    color: #ffffff;
    opacity: 1;
  }
`;

const ButtonToggle = styled(Button)`
  opacity: 0.7;
`;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function GroupButtonsMore(props: {
  list: string[];
  title: string;
  setSelectedValue: any;
  selectedValue: string;
  moreButton: boolean;
}) {
  const handelClick = (val: string) => {
    setActive(val);
    props.setSelectedValue(val);
  };

  const types: string[] = props?.list;
  const selectedValue: string = props?.selectedValue;
  const moreButton: boolean = props?.moreButton;
  const [active, setActive] = useState(selectedValue);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div>{props?.title}</div>
      {types.slice(0, 10).map((type: string, index: number) => (
        <ButtonToggle
          className={active === type ? "active-class" : ""}
          onClick={() => handelClick(type)}
          key={index}
        >
          {type}
        </ButtonToggle>
      ))}
      {moreButton ? (
        <ButtonToggle onClick={handleOpen}>more</ButtonToggle>
      ) : (
        <></>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props?.title}
          </Typography>
          {types.map((type: string, index: number) => (
            <ButtonToggle
              className={active === type ? "active-class" : ""}
              onClick={() => handelClick(type)}
              key={index}
            >
              {type}
            </ButtonToggle>
          ))}
        </Box>
      </Modal>
    </div>
  );
}

export default GroupButtonsMore;
