// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-container {
  position: relative;
  display: inline-flex; /* Make the container only as wide as its contents */
  border-bottom: 2px solid #EAEAEA; /* The line */
}

.ExploreMore {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExploreMore .swiper-wrapper {
  padding-bottom: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/ExploreMore/ExploreMore.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,oBAAA,EAAA,oDAAA;EAEA,gCAAA,EAAA,aAAA;AAHJ;;AAKE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,oBAAA;AADN","sourcesContent":["\n\n  \n  .tab-container {\n    position: relative;\n    display: inline-flex; /* Make the container only as wide as its contents */\n\n    border-bottom: 2px solid #EAEAEA; /* The line */\n  }\n  .ExploreMore {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    .swiper-wrapper {\n      padding-bottom: 80px;\n      \n    }\n   \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
