import React from "react";
import ExamBlogsImage1 from "../../../images/blog-article/exam-section-image1.jpg";
import { Link, useNavigate } from "react-router-dom";
interface BlogCardwithTitleProps {
  item: any;
}

const BlogCardwithTitle: React.FC<BlogCardwithTitleProps> = ({ item }) => {
  // console.log("item", item);
  const navigate = useNavigate();

  const handleBlogClick = () => {
    navigate(
      `/blogs/${item.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`,
      { state: { questions: item } }
    );
  };
  return (
    <div className="overflow-hidden blog-image-title-container h-full">
      <Link
        // to={`/blogs/${item.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`}
        to={`/blogs/${item.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`}
        state={{ from: "BlogCardwithTitle", blogDetail: item }}
        // onClick={handleBlogClick}
      >
        <div className="relative h-full ">
          <div className="image-container">
            <img
              src={item?.imageUrl || ExamBlogsImage1}
              className="w-full h-96 object-cover blog-exam-image"
            />
            <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
          </div>
          <div>
            <div className="absolute bottom-0 left-0 text-white text-sm px-4 ">
              <button className="px-3 py-2 green-bg flex items-center text-sm text-[#ffffff]">
                {item?.category}
              </button>
              <div className="py-4">
                <h1 className="font-semibold text-lg line-clamp-2">
                  {item?.title || "Top 10 NIRF Engineering Colleges in India"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCardwithTitle;
