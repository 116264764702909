// src/components/StepNavigationWrapper.tsx
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../store/store";
import { prevStep } from "../../../../../store/slices/registrationFormSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface StepNavigationWrapperProps {
  children: React.ReactNode;
  onNext: () => void;
  showNext: boolean;
  showBack: boolean;
  stepName: string;
  stepDescription: string;
}

const StepNavigationWrapper: React.FC<StepNavigationWrapperProps> = ({
  children,
  onNext,
  showNext = true,
  showBack = true,
  stepName,
  stepDescription,
}) => {
  const dispatch = useDispatch();
  const { data, step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { otpSent, error, isAuthenticated, token, user } = useSelector(
    (state: RootState) => state.auth
  );

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    dispatch(prevStep());
  };

  return (
    <Fragment>
      <div className="flex flex-row items-center gap-2 mb-3">
        {showBack && step > 1 && (
          <button
            className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            onClick={handleBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        <div className="flex flex-col">
          <span className="text-2xl font-semibold text-[#113cc0]">{stepName}</span>
          <p className="text-sm text-black-500">{stepDescription}</p>
        </div>
      </div>
      <>{children}</>
      <div className="flex flex-row justify-center gap-2 navigation-buttons">
        {/* {showBack && step > 1 && (
          <button
            type="button"
            onClick={handleBack}
            className="p-3 hover:text-grey-500"
            style={{ padding: "8px 18px" }}
          >
            Previous
          </button>
        )} */}

        {showNext && isAuthenticated && (
          <button
            type="button"
            onClick={handleNext}
            className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
            style={{ padding: "8px 18px" }}
          >
            Continue
          </button>
        )}

        {/* <button
          type="button"
          // onClick={handleBack}
          className="p-3 hover:text-grey-500"
          style={{ padding: "8px 18px" }}
        >
          Skip
        </button> */}
      </div>
    </Fragment>
  );
};

export default StepNavigationWrapper;
