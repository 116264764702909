// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-in-one {
  border-radius: 20px;
  border-top: 6px solid #f5bd2d;
  background: var(--Background-9, #061958);
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/AllinOneExams.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,6BAAA;EACA,wCAAA;EACA,gDAAA;AACF","sourcesContent":[".all-in-one {\n  border-radius: 20px;\n  border-top: 6px solid #f5bd2d;\n  background: var(--Background-9, #061958);\n  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
