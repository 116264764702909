import React from "react";
import HoverCard from "../../../components/hover-card/HoverCard";
import Blogs from "../../../components/blogs/Blogs";

const fcData = [
  {
    image: "/images/courses/bcom.png",
    title: "B.com",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/courses/artificial.png",
    title: "Artificial Intelligence ",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/courses/bcom.png",
    title: "B.Tech",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/courses/artificial.png",
    title: "Artificial Intelligence ",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
];

const ResourceTabInfo = () => {
  return (
    <div className="resourcetab-info">
      <div className="container mx-auto ">
        {/* career quiz section starts */}
        <div className="c-featured-courses-section  my-[40px]">
          <h3>Carrer Quiz</h3>
          <div className="flex gap-[24px]">
            {fcData.map((card) => {
              return <HoverCard {...card} />;
            })}
          </div>
        </div>
        {/* career quiz section ends */}
      </div>
      {/* visual stories starts */}

      <div className="flex flex-col px-5 container mx-auto">
        <div className="self-center text-3xl font-extrabold text-center text-slate-800">
          Visual Stories
        </div>
        <div className="mt-10 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-[24px] max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs01.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col  w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs02.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs03.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col  w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs04.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.75] w-[316px] max-md:mt-6"
              />
            </div>
          </div>
        </div>
      </div>
      {/* visual stories ends */}
      {/* do you know starts */}
      <div className="flex flex-col items-center px-20 py-14 text-center text-white bg-[#00C798] max-md:px-5 mt-[40px] mb-[40px]">
        <div className="text-2xl font-semibold">Do you know?</div>
        <div className="mt-1 text-lg max-md:max-w-full">
          According to 2023 stats Remote Work: Approximately 41% of software
          developers work remotely, 42% work in a hybrid model, and
          <br />
          only 16.41% work fully in-house.
        </div>
      </div>
      {/* do you kknow ends */}
      {/* career spotlights starts */}
      <div className="flex flex-col px-5 container mx-auto">
        <div className="self-center text-3xl font-extrabold text-center text-slate-800">
          Career Spotlights
        </div>
        <div className="mt-10 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-[24px] max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs01.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col  w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs02.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs03.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
              />
            </div>
            <div className="flex flex-col  w-[316.83px] h-[420px] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/images/careers/cvs04.jpg"
                alt=""
                className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.75] w-[316px] max-md:mt-6"
              />
            </div>
          </div>
        </div>
      </div>
      {/* career spotlights ends */}

      <Blogs />
    </div>
  );
};

export default ResourceTabInfo;
