// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .popular-quizes .rounded-xl {
    max-width: 197px;
  }
}
@media (min-width: 1024px) {
  .popular-quizes {
    justify-content: space-between;
  }
}
@media (min-width: 321px) and (max-width: 450px) {
  .popular-quizes-parent {
    padding: 40px 0;
  }
}
@media (max-width: 321px) {
  .popular-quizes-parent {
    padding: 40px 0;
  }
  .quizes {
    width: 120px;
  }
}
@media (min-width: 1280px) {
  .popular-quizes .rounded-xl {
    max-width: 220px;
  }
}
.arrow-icon {
  transition: transform 0.2s ease-in-out;
}

.arrow-icon:hover {
  transform: translateX(6px);
}`, "",{"version":3,"sources":["webpack://./src/components/courses/CourseStreamQuiz.scss"],"names":[],"mappings":"AAUA;EAEI;IACE,gBAAA;EAVJ;AACF;AAcA;EACE;IACE,8BAAA;EAZF;AACF;AAcA;EACE;IACE,eAAA;EAZF;AACF;AAeA;EACE;IACE,eAAA;EAbF;EAgBA;IACE,YAAA;EAdF;AACF;AAkBA;EAEI;IACE,gBAAA;EAjBJ;AACF;AAoBA;EACE,sCAAA;AAlBF;;AAqBA;EACE,0BAAA;AAlBF","sourcesContent":["// Media query for 430px width devices\n// @media (min-width: 430px) and (max-width: 640px) {\n//   .flex-wrap {\n//     .rounded-xl {\n//       max-width: 176px; // Adjust max-width for smaller devices\n//     }\n//   }\n// }\n\n// Media queries for larger screens\n@media (min-width: 768px) {\n  .popular-quizes {\n    .rounded-xl {\n      max-width: 197px; // Reset max-width for medium devices\n    }\n  }\n}\n\n@media (min-width: 1024px) {\n  .popular-quizes {\n    justify-content: space-between; // Space items evenly on larger screens\n  }\n}\n@media (min-width: 321px) and (max-width: 450px) {\n  .popular-quizes-parent {\n    padding: 40px 0;\n  }\n  \n}\n@media (max-width: 321px) {\n  .popular-quizes-parent {\n    padding: 40px 0;\n  }\n  \n  .quizes{\n    width:120px;\n  }\n}\n\n// Additional styling for very large screens\n@media (min-width: 1280px) {\n  .popular-quizes {\n    .rounded-xl {\n      max-width: 220px; // Adjust max-width for very large screens\n    }\n  }\n}\n.arrow-icon {\n  transition: transform 0.2s ease-in-out;\n}\n\n.arrow-icon:hover {\n  transform: translateX(6px); // Adjust the value as needed\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
