// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chevron-icon {
  padding: 2px 0;
}
.chevron-icon img {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-right: 16px;
}

.magnifier-icon {
  cursor: pointer;
  padding: 9px 0;
}

.logo-section {
  width: 197px;
  height: 32px;
  flex-shrink: 0;
}

.navbar {
  display: flex; /* Make the navbar a flex container */
  justify-content: space-between; /* Distribute items horizontally */
}

.mobile-menu {
  z-index: 100;
}

.nav-list {
  display: flex; /* Make the list items a flex container */
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.nav-item {
  margin-right: 4px; /* Add some margin between items */
}

.nav-link {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 16px;
  }
}
.login-button {
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #113cc0;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/nav-bar/NavBar.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;EACA,cAAA;AAEF;;AAAA;EACE,aAAA,EAAA,qCAAA;EACA,8BAAA,EAAA,kCAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,aAAA,EAAA,yCAAA;EACA,gBAAA,EAAA,gCAAA;EACA,UAAA,EAAA,2BAAA;EACA,SAAA,EAAA,0BAAA;AAKF;;AAFA;EACE,iBAAA,EAAA,kCAAA;AAKF;;AAFA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAKF;;AAHA;EACE;IACA,eAAA;EAMA;AACF;AAHA;EACE,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AAKF","sourcesContent":[".chevron-icon {\n  padding: 2px 0;\n  img {\n    width: 18px;\n    height: 18px;\n    margin-left: 4px;\n    margin-right: 16px;\n  }\n}\n\n.magnifier-icon {\n  cursor: pointer;\n  padding: 9px 0;\n}\n.logo-section {\n  width: 197px;\n  height: 32px;\n  flex-shrink: 0;\n}\n.navbar {\n  display: flex; /* Make the navbar a flex container */\n  justify-content: space-between; /* Distribute items horizontally */\n}\n.mobile-menu{\n  z-index: 100;\n}\n.nav-list {\n  display: flex; /* Make the list items a flex container */\n  list-style: none; /* Remove default list styling */\n  padding: 0; /* Remove default padding */\n  margin: 0; /* Remove default margin */\n}\n\n.nav-item {\n  margin-right: 4px; /* Add some margin between items */\n}\n\n.nav-link {\n  color: #333;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n@media (max-width:768px) {\n  .nav-link{\n  font-size: 16px;\n  }\n}\n\n.login-button {\n  padding: 10px 24px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 50px;\n  background: #113cc0;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
