import React, { useState } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

import { SVGProps } from "react";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const FAQuestionsContent = {
  title: "Frequently Asked Questions",
  questionsList: [
    {
      question: "Who should pursue MTech in Mechanical Engineering?",
      answer:
        "Pursuing a Master's degree in the subject equips aspirants with a deeper understanding and knowledge of thermodynamics, solids, materials, fluid mechanics, and machine design. Students who completed their bachelor studies and feel the urge to learn more about the subject should pursue an MTech in Mechanical Engineering not only to enhance their knowledge but also to uncover many more career prospects mostly at the senior level.",
      bgColor: "#FFF9E5",
      borderColor: "#FFD540",
    },
    {
      question:
        "What are some specializations in the field of Mechanical Engineering?",
      answer:
        "Some of the areas of specialization include: Thermal Engineering, Fluid Mechanics, Automation and Control, Materials Engineering and Processing, and Machine Design and Solid Mechanics.",
      bgColor: "#EEF2FF",
      borderColor: "#173CBA",
    },
    {
      question: "What is the duration of BTech Mechanical Engineering?",
      answer:
        "The BTech programme is of an eight-semester duration, spread out over 4 years.",
      bgColor: "#FFF1FA",
      borderColor: "#FF66CC",
    },
    {
      question: "What are the job profiles available after the course?",
      answer:
        "Some of the job profiles available after the course are Mechanical Engineer, Design Engineer, Quality Control Executive, etc.",
      bgColor: "#FFF9E5",
      borderColor: "#FFD540",
    },
    {
      question:
        "What is the average salary of a Mechanical Engineering graduate?",
      answer:
        "The average salary of a Mechanical Engineering graduate depends on the capabilities and skills of the student; it also varies depending on the job profile and the location of the student. An estimated average salaries of some of the popular career options in the field of Mechanical Engineering are given in the table below.",
      bgColor: "#EEF2FF",
      borderColor: "#173CBA",
    },
  ],
};

const FAQuestions: React.FC = () => {
  const { data } = useCourseDetails();
  const { frequently_asked_questions } = data;

  const [visibleQuestions, setVisibleQuestions] = useState(3);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleQuestionsVisibility = () => {
    if (isExpanded) {
      setVisibleQuestions(3);
    } else {
      setVisibleQuestions(FAQuestionsContent.questionsList.length);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col bg-[#fff] relative" id="faqs">
      <div className="flex flex-col">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {FAQuestionsContent.title}
          </h3>
        </div>

        <div className="flex flex-col gap-6 px-2 py-8 md:py-5 md:px-9">
          <ul className="list-none flex flex-col gap-8 transition-opacity duration-300">
            {frequently_asked_questions.slice(0, visibleQuestions).map(
              (
                question: {
                  answer: string;
                  course: {};
                  id: number;
                  question: string;
                },
                index: number
              ) => (
                <li
                  key={index}
                  className={`text-base text-[#4C495C] leading-7 p-6 rounded-2xl m-0 flex gap-4 items-center border`}
                  style={{
                    backgroundColor:
                      FAQuestionsContent.questionsList[index].bgColor,
                    borderColor:
                      FAQuestionsContent.questionsList[index].borderColor,
                  }}
                >
                  <div className="flex flex-col gap-6">
                    <h4 className="text-lg text-[#25242B] font-semibold m-0">
                      Question: {question.question}
                    </h4>
                    <p className="text-base text-[#4C495C] font-normal">
                      <strong>Answer:</strong> {question.answer}
                    </p>
                  </div>
                </li>
              )
            )}
          </ul>
          <div className="flex justify-end">
            <button
              className="px-4 py-2 text-white bg-[#173CBA] rounded hover:bg-[#00C798] transition duration-300 flex items-center gap-2"
              onClick={toggleQuestionsVisibility}
            >
              {isExpanded ? "Show Less" : "Show More"}{" "}
              <svg
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`transform transition-transform duration-300 ${
                  isExpanded ? "rotate-180" : ""
                }`}
              >
                <path
                  d="M1.2915 0.963867L6.49984 6.1722L11.7082 0.963867"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQuestions;
