import { useEffect, useState } from "react"

const useDebouncedSearch = (debounceDuration: number = 500 ) => {
    const [searchKeyword, setSearchKeyword] = useState<string>("")
    const [deboundedSearchKeyword, setDebouncedSearchKeyword] = useState<string>("")

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedSearchKeyword(searchKeyword)
        }, debounceDuration)

        return () => clearTimeout(timeout)
    }, [searchKeyword])

    const handleKeywordChange  = (newSearchKeyword: string) => {
        setSearchKeyword(newSearchKeyword)
    }

    return {searchKeyword, handleKeywordChange, deboundedSearchKeyword}
}

export default useDebouncedSearch