import React from "react";

interface Props {
  title: string;
  description: string;
  laboratedGrid: {
    image: string;
    title: string;
    description: string;
  }[];
}
const LaboratoriesFacilitiesContent = [
  {
    title: "Central Research Laboratories And Facilities",
    description:
      "The University has state-of-the-art laboratories and facilities to support the research activities. The laboratories are well-equipped with the latest equipment and tools to provide a conducive environment for the students and faculty to carry out their research activities. The University has established various research centers to promote research activities in various disciplines.",
    laboratedGrid: [
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/05/medical-1.png",
        title: "NRC",
        description:
          "To promote the research in the field of traditional system of Indian Medicine, including Siddha, Ayurveda and Unani, Interdisciplinary Institute of Indian System of Medicine (IIISM)was established in 2008.",
      },
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2023/09/SRM-DBT-Platform.jpg",
        title: "CACR",
        description:
          "The Center for Advanced Concrete Research (CACR) at SRM University was started in the year 2010, to carry out interdisciplinary research in the field of concrete, a most common material of construction in any built environment and a basic necessity for accelerated economic development.",
      },
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/05/CACR-1.png",
        title: "SRM-DBT Platform",
        description:
          "SRM Institute of Science and Technology in collaboration with Department of Biotechnology, Govt. of India has established this advanced research facility under the project “ SRM-DBT Partnership Platform for Contemporary Research Services and Skill Development in Advanced Life Science Technologies",
      },
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2023/09/Nanotechnology-Research-Center-NRC.jpg",
        title: "IIISM",
        description:
          "REACH (Center for Research in Environment, Sustainability Advocacy and Climate CHange) was initiated at SRM Institute of Science and Technology, Kattankulathur, Tamil Nadu with the desire to support and enhance a cohesive university-wide environment of research excellence where faculty are inspired",
      },
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/03/Chemistry-research-149-1-scaled.jpg",
        title: "REACH",
        description:
          "REACH (Center for Research in Environment, Sustainability Advocacy and Climate CHange) was initiated at SRM Institute of Science and Technology, Kattankulathur, Tamil Nadu with the desire to support and enhance a cohesive university-wide environment of research excellence where faculty are inspired",
      },
      {
        image:
          "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/05/IIISM.png",
        title: "Medical Research Centre",
        description:
          "SRMIST’s aMRC established in 2008 promotes research in medical & allied sciences. In addition to helping PhDs Researchers, MRC among other things also mentors the faculty and the students in Scientific enquiry.",
      },
    ],
  },
];

const LaboratoriesFacilities: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-row py-16">
      <div className="container mx-auto">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col justify-center items-center gap-4">
            {LaboratoriesFacilitiesContent.map((content, index) => (
              <div
                key={index}
                className="flex flex-col gap-5 justify-center items-center"
              >
                <h3 className="text-3xl font-bold text-[#231F40]">
                  {content.title}
                </h3>
                <p className="text-lg text-[#231F40]">{content.description}</p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {LaboratoriesFacilitiesContent[0].laboratedGrid.map(
              (laboratedGrid, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-5 justify-center items-center border bg-gray-100 p-5 rounded-lg"
                >
                  <div className="flex justify-center items-center">
                    <img
                      src={laboratedGrid.image}
                      alt={laboratedGrid.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <h3 className="text-2xl font-bold text-[#231F40]">
                    {laboratedGrid.title}
                  </h3>
                  <p className="text-lg text-[#231F40]">
                    {laboratedGrid.description}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaboratoriesFacilities;
