import React, { useEffect, useState } from "react";

import Tabs from "./components/tabs";
import { useParams } from "react-router-dom";


const CareerDetails = () => {
  const { careerName } = useParams();
  const [number, setNumber] = useState(0);
  const handleDownload = () => {
    const brochureUrl =
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

    const anchor = document.createElement("a");
    anchor.href = brochureUrl;
    anchor.target = "_blank";

    anchor.download = "brochure.pdf";

    anchor.click();
  };

  const handleShareOnWhatsApp = () => {
    const text =
      "Check out this brochure: https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      setNumber(Math.floor(Math.random() * 100 + 1));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="career-details-main w-full">
      <div className="career-details-banner flex  max-md:flex-col max-md:gap-0">
        <div className="flex  relative flex-col w-[42%] max-md:ml-0 max-md:w-full">
          <div className="flex  grey-bg  relative flex-col grow items-end py-16 pl-40 min-h-[600px] max-md:max-w-full">
            <h2 className="relative text-[50px] mr-14 font-semibold  text-black leading-[66px] max-md:max-w-full max-md:text-4xl">
             {careerName}
            </h2>
            <div className="flex relative flex-wrap gap-3.5 content-start mt-4 w-full max-md:max-w-full">
              <button
                className="flex  gap-2 justify-center px-9 py-3.5 text-base font-semibold text-white green-color button-styles rounded-[50px] max-md:flex-wrap max-md:px-5"
                onClick={handleDownload}
              >
                Download Brochure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button
                className="flex flex-col justify-center items-start p-3.5 bg-white shadow-lg rounded-[50px] max-md:pr-5"
                onClick={handleShareOnWhatsApp}
              >
                <img
                  loading="lazy"
                  src="/images/common/whatsapp.png"
                  alt="whatsapp png"
                  className="w-6 aspect-square"
                />
              </button>
            </div>
            <div className="progress-bar-section flex absolute bottom-14 -right-40 z-50 flex-col p-6 mt-36 w-full text-base font-bold bg-white rounded-2xl shadow-lg max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="flex justify-between mb-1">
                <span className="text-base font-medium blue-text dark:text-white">
                  {`${number}% Match?`}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="blue-bg h-2.5 rounded-full"
                  style={{
                    width: `${number}%`,
                    transition: "width 0.68s linear",
                    transitionDelay: "0.5s",
                  }}
                ></div>
              </div>

              <span className="mt-2.5 text-zinc-800 max-md:max-w-full">
                Would you make a good agricultural engineer?
              </span>
              <p className="text-sm font-medium text-zinc-800 max-md:max-w-full">
                Take our career test and find your match with over 800 careers.
              </p>
              <div className="flex gap-4 mt-2.5 font-semibold max-md:flex-wrap">
                <button className="justify-center  px-9 py-3 text-white green-color button-styles bg-emerald-500 rounded-[50px] max-md:px-5">
                  Take the free career test
                </button>
                <a href="/" className="my-auto capitalize text-neutral-500">
                  Learn more about the career test
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col  w-[58%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/CareerDetails/banner01.png"
            alt="career detail banner"
            className="grow w-full  max-md:max-w-full"
          />
        </div>
      </div>
      <div className="career-details-tabs">
        <Tabs data={careerName}/>
      </div>
    </div>
  );
};

export default CareerDetails;
