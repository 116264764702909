import React, { useState } from 'react';
import webinar1 from '../../../college/assets/webinar1.png'
import webinar2 from '../../../college/assets/webinar2.png'
import webinar3 from '../../../college/assets/webinar3.png'
import webinar4 from '../../../college/assets/webinar4.png'
interface Tab {
    label: string;
    content: React.ReactNode;
}
interface TabProps {
    label: string;
    isActive: boolean;
    onClick: () => void;
}
const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => {
    return (
        <div
            className={`cursor-pointer px-4 py-2  ${isActive ? 'active_tab_webinar' : 'inactive_tab'}`}
            onClick={onClick}
        >
            <div className='lable_tab'>{label}</div>
        </div>
    );
};
interface TabPanelProps {
    children: React.ReactNode;
    isActive: boolean;
}
const TabPanel: React.FC<TabPanelProps> = ({ children, isActive }) => {
    return isActive ? <div>{children}</div> : null;
};
interface TabsProps {
    tabs: Tab[];
}
const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    return (
        <div className='grid '>
            <div className="flex justify-center gap-5 ps-10">
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        isActive={activeTab === index}
                        onClick={() => setActiveTab(index)}
                    />
                ))}
            </div>
            <div className='p-10 tab_bg' >
                {tabs.map((tab, index) => (
                    <TabPanel key={index} isActive={activeTab === index}>
                        {tab.content}
                    </TabPanel>
                ))}
            </div>
        </div>
    );
};
const WebinarsAndArticles: React.FC = () => {
    const tabs: Tab[] = [
        { label: 'Webinar', content: <Webinar /> },
        { label: 'Articles', content: <Article /> },
        { label: 'Spotlights', content: <Spotlight /> },
        { label: 'News', content: <News /> },
    ];
    return (
        <div className=' my-8' >
            <Tabs tabs={tabs} />
        </div>
    );
};
export default WebinarsAndArticles;
const Webinar = () => {
    return (
        <>
            <div className='tab_bg'>
                <div className='p-4'>
                    <div className="grid grid-cols-4 gap-4 ">
                        <div className="p-4">
                            <div><img src={webinar2} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Types of Colleges</div>
                                <div className='webinar_text_subheading'>Colleges offer various programs to serve students with different need and goals. Learning about the types of colleges will help you find the best choices for you.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar1} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar4} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar3} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Why college is Important</div>
                                <div className='webinar_text_subheading'>Considering the pros and cons of going to college? Learn reasons why college is worth it for career growth and future financial opportunities.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
const Article = () => {
    return (
        <>
            <div className='tab_bg'>
                <div className='p-4'>
                    <div className="grid grid-cols-4 gap-4 ">
                        <div className="p-4">
                            <div><img src={webinar1} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar2} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Types of Colleges</div>
                                <div className='webinar_text_subheading'>Colleges offer various programs to serve students with different need and goals. Learning about the types of colleges will help you find the best choices for you.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar3} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Why college is Important</div>
                                <div className='webinar_text_subheading'>Considering the pros and cons of going to college? Learn reasons why college is worth it for career growth and future financial opportunities.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar4} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const Spotlight = () => {
    return (
        <>
            <div className='tab_bg'>
                <div className='p-4'>
                    <div className="grid grid-cols-4 gap-4 ">
                        <div className="p-4">
                            <div><img src={webinar4} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar1} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar3} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Why college is Important</div>
                                <div className='webinar_text_subheading'>Considering the pros and cons of going to college? Learn reasons why college is worth it for career growth and future financial opportunities.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar2} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Types of Colleges</div>
                                <div className='webinar_text_subheading'>Colleges offer various programs to serve students with different need and goals. Learning about the types of colleges will help you find the best choices for you.</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
const News = () => {
    return (
        <>
            <div className='tab_bg'>
                <div className='p-4'>
                    <div className="grid grid-cols-4 gap-4 ">

                        <div className="p-4">
                            <div><img src={webinar2} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Types of Colleges</div>
                                <div className='webinar_text_subheading'>Colleges offer various programs to serve students with different need and goals. Learning about the types of colleges will help you find the best choices for you.</div>
                            </div>
                        </div>

                        <div className="p-4">
                            <div><img src={webinar1} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar4} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Concert your GPA to a 4.0 scale</div>
                                <div className='webinar_text_subheading'>Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.</div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div><img src={webinar3} /></div>
                            <div className='p-3'>
                                <div className='webinar_text_heading'>Why college is Important</div>
                                <div className='webinar_text_subheading'>Considering the pros and cons of going to college? Learn reasons why college is worth it for career growth and future financial opportunities.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
