import React, { FC, useState, useEffect, useRef } from "react";
import "./CompareBanner.scss";
import { Link } from "react-router-dom";
import useDebouncedSearch from "../../../components/filter/customHooks/useDebouncedSearch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  fetchAndAddCollegeData,
  fetchSearchData,
} from "../collegeCompareThunk";
import { useHighlight } from './HighlightContext'; 

const CompareBanner = () => {
  const comparisonCollegesLength = useSelector(
    (state: RootState) => state.collegeCompare.comparisonColleges?.length || 0
  );
  const [compareCols, setCompareCols] = useState(
    window.innerWidth < 768 ? 2 : 4
  );
  const { setHighlightField } = useHighlight();
  useEffect(() => {
    if (comparisonCollegesLength === 0) {
      setHighlightField(''); 
    }
  }, [comparisonCollegesLength]);

  useEffect(() => {
    const handleResize = () => {
      setCompareCols(window.innerWidth < 768 ? 2 : 4);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED]">
      <div className="container">
        <div className="mx-2 md:mx-0 flex  text-left md:text-center  flex-col mt-12 lg:mt-24 max-md:mb-20 md:mb-32">
          <div className=" w-full">
            <div>
              <div className="compare-heading  text-[32px] lg:text-[40px] text-left md:text-center">
                College & Universities Compare Tool
              </div>
            </div>
          </div>
          <div className="compare-subheading flex text-left md:text-center py-4">
            Find the College that's the best fit for you by using our College
            Comparison Tool. Compare colleges side by side to find the right
            school for you! Add up to four schools to compare college in India
            based on Overview, Rankings & Accreditations, placements, Courses &
            fees, Admission & Eligibility, International Collaborations.
          </div>
          <div className="mt-4 md:mt-8 lg:mt-4 flex justify-center w-full">
            <div className="w-full lg:w-[468px]">
              {comparisonCollegesLength < compareCols && <SearchBox />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareBanner;

const SearchBox = () => {
  const [search, setSearch] = useState("");
  const [id, setId] = useState<number | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector(
    (state: RootState) => state.collegeCompare.searchResults
  );
  const { handleKeywordChange, deboundedSearchKeyword } = useDebouncedSearch();

  useEffect(() => {
    dispatch(fetchSearchData(deboundedSearchKeyword));
  }, [deboundedSearchKeyword, fetchSearchData, dispatch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    handleKeywordChange(value);
    setIsDropdownOpen(!!value);
  };

  const handleOptionClick = (collegeId: number, collegeTitle: string) => {
    setId(collegeId);
    setSearch(collegeTitle);
    handleKeywordChange(collegeTitle);
    setIsDropdownOpen(false);
  };

  const handleCompareClick = () => {
    if (id !== null) {
      handleKeywordChange("");
      dispatch(fetchAndAddCollegeData(id));
      setSearch("");
      setId(null);
      setIsDropdownOpen(false);
    }
  };

  const handleClear = () => {
    setSearch("");
    handleKeywordChange("");
    setIsDropdownOpen(false);
  };

  return (
    <form className="bg-white pl-3 py-1 pr-1 md:pl-4 md:pr-2 md:py-2 rounded-full flex flex-row w-full justify-center">
      <div className="flex flex-row gap-2 items-center w-full">
        <div className="flex flex-col w-5 h-5">
          <svg
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 md:w-5 md:h-5"
          >
            <path
              d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
              fill="#113CC0"
            />
          </svg>
        </div>
        <div className="relative w-full">
          <input
            type="text"
            id="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Add a College to compare"
            className="block w-full px-2 pr-4 text-xs md:text-base text-[#A3A2AC] outline-none"
          />
          {search.length > 0 && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-2 top-1/2 transform text-xl -translate-y-1/2 text-blue hover:text-gray-600 rotate-45"
            >
              +
            </button>
          )}
          {isDropdownOpen && searchResults.length > 0 && (
            <div className="absolute z-10 w-[120%] md:w-full lg:w-[150%] bg-white border border-gray-200 mt-4 rounded-lg shadow-lg">
              {searchResults.map((item: any) => (
                <div
                  key={item.collegeId}
                  className="p-2 text-base cursor-pointer hover:bg-gray-100"
                  onClick={() =>
                    handleOptionClick(item.collegeId, item.collegeTitle)
                  }
                >
                  {item.collegeTitle}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <button
        type="button"
        className="arw-button-college bg-[--primary-color] text-white py-1 px-3 rounded-full"
        style={{
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
        onClick={handleCompareClick}
      >
        <div className="flex items-center whitespace-nowrap">
          <span className="hidden md:block">Compare</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon m-2"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>
    </form>
  );
};
