import { FC, useEffect, useState } from "react";
import Image from "../../../components/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal";
import useDebouncedSearch from "../../../components/filter/customHooks/useDebouncedSearch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { removeComparisonCollege } from "../collegeCompareSlice";
import {
  fetchAndAddCollegeData,
  fetchSearchData,
} from "../collegeCompareThunk";

const COMPARE_COLS = window.innerWidth < 768 ? 2 : 4;

const ComparisonEditor = () => {
  const colleges = useSelector(
    (state: RootState) => state.collegeCompare.comparisonColleges
  );

  return (
    <>
      {COMPARE_COLS == colleges.length && (
        <div className="bg-white mb-6 p-6 border border-green shadow text-xl lg:2xl font-semibold text-center">
          You are comparing the maximum of four schools. Remove a college to
          compare additional schools.
        </div>
      )}
      <div id="comparison" className="flex">
        {COMPARE_COLS == 4 && <div className="flex-1"></div>}
        {Array.from({ length: COMPARE_COLS }, (_, index) => {
          const isSelected = index < colleges.length;
          return (
            <CollegeSelector
              index={index}
              isSelected={isSelected}
              collegeName={isSelected ? colleges[index].collegeName : ""}
              image={isSelected ? colleges[index].collegeImage : ""}
            />
          );
        })}
      </div>
    </>
  );
};

export default ComparisonEditor;

interface ICollegeSelectorProps {
  index: number;
  isSelected: boolean;
  collegeName: string;
  image: string;
}

const CollegeSelector: FC<ICollegeSelectorProps> = ({
  index,
  isSelected,
  collegeName,
  image,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

  const handleRemoveCollege = (index: number) => {
    dispatch(removeComparisonCollege(index));
  };

  const nameContent = isSelected ? collegeName : `College ${index + 1}`;
  let imageContent = isSelected ? (
    <Image
      aspectRatio={70}
      className="w-full lg:w-4/5 overflow-hidden rounded-lg"
      src={image}
    />
  ) : (
    <div className="w-full lg:w-4/5">
      <div className="h-0 w-full pb-[70%] bg-[#C0C0C033] rounded-lg"></div>
    </div>
  );
  let buttonContent = isSelected ? (
    "REMOVE"
  ) : (
    <>
      <FontAwesomeIcon icon={faPlus} /> ADD
    </>
  );

  return (
    <>
      <div className="flex-1 p-4 flex flex-col gap-4 items-center">
        <h5 className="text-base font-bold text-grey line-clamp-1">
          {nameContent}
        </h5>
        {imageContent}
        <button
          className="border border-green rounded text-blue text-xs font-bold px-4 py-1"
          onClick={
            isSelected
              ? () => handleRemoveCollege(index)
              : () => setIsSearchPopupOpen(true)
          }
        >
          {buttonContent}
        </button>
      </div>
      <CollegeSearchPopup
        isOpen={isSearchPopupOpen}
        onClose={() => setIsSearchPopupOpen(false)}
      />
    </>
  );
};

const CollegeSearchPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const searchResults = useSelector(
    (state: RootState) => state.collegeCompare.searchResults
  );

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  useEffect(() => {
    dispatch(fetchSearchData(deboundedSearchKeyword));
  }, [deboundedSearchKeyword]);

  const addCollege = (collegeId: number) => {
    dispatch(fetchAndAddCollegeData(collegeId));
    onClose();
    handleKeywordChange("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="text-grey p-4 w-[80vw] md:w-[50vw] bg-white flex flex-col gap-2">
        <h3 className="text-xl text-blue font-bold">Search College</h3>
        <input
          value={searchKeyword}
          onChange={(e) => handleKeywordChange(e.target.value)}
          className="w-full p-2 text-base border border-grey outline-none"
        />
        <div className="h-[30vh] overflow-auto">
          {searchResults.map(
            (item: { collegeTitle: string; collegeId: number }) => {
              return (
                <div
                  className={`p-2 text-base`}
                  onClick={() => addCollege(item.collegeId)}
                >
                  {item.collegeTitle}
                </div>
              );
            }
          )}
        </div>
      </div>
    </Modal>
  );
};
