import React from "react";
import TitleCard from "../../components/TitleCard";
import StudyAbroadImage from "../../../../images/blog-article/study-abroad-image.png";
import ExamBlogsImage4 from "../../../../images/blog-article/exam-section-image4.jpg";
import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import BlogHoverContainer from "../../components/BlogHoverContainer";
import BlogInfoCard from "../../components/BlogInfoCard";
import VerticalNewsCard from "../../components/VerticalNewsCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";

import { Link } from "react-router-dom";
import { generateListofItems } from "../../../../util/helpers/helperFunctions";
interface CollegeAndScholarshipProps {
  collegeAndScholarshipResponse: any;
}
const CollegeAndScholarship: React.FC<CollegeAndScholarshipProps> = ({
  collegeAndScholarshipResponse = [],
}) => {
  console.log(
    "collegeAndScholarshipResponse",
    collegeAndScholarshipResponse[0]?.["College And Scholarship"]?.subcategories
  );
  const { width, height } = GetWindowDimensions();
  const getCurrentHoveredBlog = (param: any) => {
    console.log("first", param);
  };

  let isMobile = width < 700;

  const showOnlyFiveList = (results: any) => {
    if (isMobile) return generateListofItems(results, "Mobile", 5);
    else return generateListofItems(results, "Desktop", 10);
  };

  return (
    <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      <div className="grid grid-flow-row gap-4 grid-rows">
        <TitleCard
          title="Colleges And Scholarships"
          bgColor="#173CBA"
          color="#ffffff"
        />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 ">
          {/* <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            {[...Array(2)].map((item) => (
              <VerticalNewsCard item={item} />
            ))}          
          </div> */}
          <>
            <CustomSwiper
              className="!p-2"
              items={collegeAndScholarshipResponse[0]?.[
                "College And Scholarship"
              ]?.subcategories["Articles & Blogs"]?.results?.map(
                (item: any) => (
                  <VerticalNewsCard item={item} />
                )
              )}
              navigation={"bottom"}
              autoplay={true}
              progressBar={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 3, spaceBetween: 20 },
                1024: { slidesPerView: 2, spaceBetween: 18 },
                1280: { slidesPerView: 2, spaceBetween: 30 },
              }}
            />
          </>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-col-1">
            <div className="grid grid-row md:grid-rows-5 md:grid-flow-col gap-x-6">
              {showOnlyFiveList(
                collegeAndScholarshipResponse[0]?.["College And Scholarship"]
                  ?.subcategories["Latest News"]?.results
              )?.map((blogInfo: any) => (
                <BlogInfoCard
                  dateValue={true}
                  rowReverse={true}
                  blogInfo={blogInfo}
                  getCurrentHoveredItem={getCurrentHoveredBlog}
                  height=""
                  categoryOrderReverse={false}
                  borderBottom={true}
                  imageField={false}
                />
              ))}
            </div>
          </div>
        </div>
        {/* 
        <div className="grid grid-flow-row grid-rows">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-1">
              <div className="grid grid-cols-2 md:grid-cols-2">
                <img className="" src={StudyAbroadImage} alt="" width="100%" />

                <a href="#">
                  <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    Town Celebrates Grand Opening of New Public Library with
                    Festive Ribbon Cutting
                  </h5>
                </a>
                <p className="mb-3">College | September 13, 2024</p>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  In the dynamic world of business and entrepreneurship, success
                  is not just about having a great idea or a solid business
                  plan. It requires a combination of factors, including...
                </p>
              </div>
            </div>
            <div>News section</div>
          </div>
        </div> */}

        {/* <div className="flex items-center justify-center w-screen h-screen p-5 bg-white">
          <div className="max-w-2xl p-3 mx-auto rounded-lg shadow-md dark:bg-gray-600 dark:text-gray-200">
            <div className="flex items-center justify-center h-40 text-gray-700 bg-green-200 rounded-lg">
              <img
                className="w-full h-full"
                src={StudyAbroadImage}
                alt=""
                width="100%"
                height="100%"
              />
            </div>
            <h1 className="p-3 text-xl font-semibold sm:text-2xl">
              Card Heading
            </h1>
            <p className="px-3 text-sm font-medium sm:text-md md:text-lg">
              This is a small description of card
            </p>
            <button className="w-full p-2 mt-3 font-medium text-gray-700 rounded bg-sky-300">
              Button
            </button>
          </div>
        </div> */}
      </div>

      {isMobile && (
        <div className="flex flex-row items-center justify-center">
          <Link
            to={`/blogs/Colleges and Scholarships`}
            state={{
              from: "CollegeAndScholarship view all",
              category: "college",
            }}
            className="h-full white-color map-mentor button-styles arrow-button"
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CollegeAndScholarship;
