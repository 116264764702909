// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explore-tilte {
  color: #383838;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 150% */
}

.slidetrack {
  display: flex;
  width: 5040px;
  animation: scroll 20s linear infinite !important;
  animation-iteration-count: infinite;
  animation-delay: -2s;
}

.slidetrack:hover {
  animation-play-state: paused !important;
}

@media (min-width: 321px) and (max-width: 450px) {
  .explore-tilte {
    color: var(--Dark, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
    padding: 57px 27px 0px 27px;
  }
  .explore-card {
    width: 284.245px;
    border-radius: 17.266px;
    border: 1.079px solid var(--Gray-5, #e0e0e0);
    background: #fff;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/ExploreRankPredictor.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACF;;AACA;EACE,aAAA;EACA,aAAA;EACA,gDAAA;EACA,mCAAA;EACA,oBAAA;AAEF;;AACA;EACE,uCAAA;AAEF;;AAAA;EACE;IACE,wBAAA;IACA,kBAAA;IACA,mBAAA;IACA,eAAA;IACA,kBAAA;IACA,gBAAA;IACA,iBAAA,EAAA,WAAA;IACA,2BAAA;EAGF;EADA;IACE,gBAAA;IACA,uBAAA;IACA,4CAAA;IACA,gBAAA;EAGF;AACF","sourcesContent":[".explore-tilte {\n  color: #383838;\n  font-family: Roboto;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 60px; /* 150% */\n}\n.slidetrack {\n  display: flex;\n  width: calc(280px * 18);\n  animation: scroll 20s linear infinite !important;\n  animation-iteration-count: infinite;\n  animation-delay: -2s;\n}\n\n.slidetrack:hover {\n  animation-play-state: paused !important;\n}\n@media (min-width: 321px) and (max-width: 450px) {\n  .explore-tilte {\n    color: var(--Dark, #000);\n    text-align: center;\n    font-family: Roboto;\n    font-size: 32px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 44px; /* 137.5% */\n    padding: 57px 27px 0px 27px;\n  }\n  .explore-card {\n    width: 284.245px;\n    border-radius: 17.266px;\n    border: 1.079px solid var(--Gray-5, #e0e0e0);\n    background: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
