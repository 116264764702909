// YearTabs.js
import React, { useState } from "react";
import BlogImg from "../../../../images/college/details/first-news-img.jpeg";

const MediaReviewsSec = () => {
  return (
    <div className="bg-[#f4f7fb] relative py-16" id="News-media">
      <div className="container mx-auto">
        <div className="flex flex-col justify-between items-center gap-6">
          <div className="flex justify-between items-center">
            <h3 className="text-[#231f40] text-3xl font-extrabold text-center">
              News Media & Reviews
            </h3>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-full lg:w-3/5">
              <div className="bg-white rounded-[16px] border p-6">
                <div className="flex flex-row gap-6">
                  <div className="flex flex-col w-full lg:w-[60%] gap-4">
                    <h3 className="text-[#525FE1] text-base font-extrabold tracking-[1.60px]">
                      University
                    </h3>

                    <h4 className="font-semibold text-[#333333] text-lg tracking-[0] leading-[32px]">
                      How Universities Can Strategically Combat Top Challenges
                      in 2023
                    </h4>

                    <p className="font-medium text-[#4F4F4F] text-sm tracking-[0] leading-[22px]">
                      The priorities and focus of educational institutions, it’s
                      students, and their potential employers have been
                      switching and changing as we’ve navigated this new working
                      world. Here&#39;s how universities can adapt.
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-4 w-full lg:w-[40%] h-[568px] rounded-2xl overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      alt="Rectangle"
                      src={BlogImg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-2/5 gap-5 flex flex-col">
              <div
                className="bg-white rounded-[16px] border p-5 flex flex-col gap-10"
                style={{ boxShadow: "0px 10px 20px 0px #0000000F" }}
              >
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#525FE1] text-base font-extrabold tracking-[1.60px]">
                    Interns
                  </h3>
                  <p className="font-semibold text-[18px] tracking-[0] leading-[32px] text-[#333333]">
                    5 Effective Work Habits to Adopt for a Successful Career
                  </p>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <button className="flex flex-row bg-[#113CC0] py-3.5 px-8 justify-center items-center rounded-full text-base leading-6 text-white gap-2">
                    Learn more{" "}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="bg-white rounded-[16px] border p-5 flex flex-col gap-10">
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#525FE1] text-base font-extrabold tracking-[1.60px]">
                    Infographic
                  </h3>
                  <p className="font-semibold text-[18px] tracking-[0] leading-[32px] text-[#333333]">
                    The Importance of Internships for Employment{" "}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <button className="flex flex-row bg-[#113CC0] py-3.5 px-8 justify-center items-center rounded-full text-base leading-6 text-white gap-2">
                    Learn more{" "}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="bg-white rounded-[16px] border p-5 flex flex-col gap-10">
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#525FE1] text-base font-extrabold tracking-[1.60px]">
                    Companies
                  </h3>
                  <p className="font-semibold text-[18px] tracking-[0] leading-[32px] text-[#333333]">
                    How To Attract And Retain GenZ Digital Nomads{" "}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <button className="flex flex-row bg-[#113CC0] py-3.5 px-8 justify-center items-center rounded-full text-base leading-6 text-white gap-2">
                    Learn more{" "}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaReviewsSec;
