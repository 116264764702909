import React from "react";
import "./CourseStreamQuiz.scss";
import Arrow from "../../images/Arrow.svg";

interface IQuizItem {
  title: string;
  image: string;
  link: string;
  id: number;
}
interface IStreamItem {
  title: string;
  image: string;
  link: string;
  id: number;
}

interface IProps {
  data: IQuizItem[];
}

const card = (quiz: IStreamItem, additionalClasses: string = "") => {
  return (
<div
                  key={quiz.id}
                  className={`quizes lg:w-[197px] sm:w-[140px] w-[160px] mb-8 rounded-xl overflow-hidden  bg-[#FFFFFF] items-center ${additionalClasses}`}
                >
                  <img
                    loading="lazy"
                    src={quiz.image}
                    className="w-full aspect-[1.39] max-w-[197px]"
                    alt={quiz.title}
                  />
                  <div className="self-center mt-4 mb-3 text-center text-[#173CBA] text-base font-bold">
                    {quiz.title}
                    <br />
                    Quizzes
                  </div>
                  <a
                    href={quiz.link}
                    className="pb-2 cta flex justify-center items-center "
                  >
                    <div className="pt-4">
                      <img src={Arrow} className="arrow-icon" />
                    </div>
                  </a>
                </div>)}
  
  export const CourseQuizContent = [
  {
    title: "Popular Quizes",
    button: "View All",
    quizcategories: [
      {
        id: 1,
        title: "Assessment",
        image: "/images/Assesment.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 2,
        title: "Knowledge",
        image: "/images/Knowledge.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 3,
        title: "Training",
        image: "/images/Training.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 4,
        title: "Online Exam",
        image: "/images/OnlineExam.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 5,
        title: "Study",
        image: "/images/Study.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 6,
        title: "Communication",
        image: "/images/Communication.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 7,
        title: "Career",
        image: "/images/Carrer.jpg",
        link: "/courses/quiz-modal",
      },

      {
        id: 8,
        title: "Online Test",
        image: "/images/Online-test.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 9,
        title: "School",
        image: "/images/School.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 10,
        title: "Student",
        image: "/images/Student.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 11,
        title: "University",
        image: "/images/University.jpg",
        link: "/courses/quiz-modal",
      },
      {
        id: 12,
        title: "College",
        image: "/images/College.jpg",
        link: "/courses/quiz-modal",
      },
    ],
  },
];

const CourseStreamQuiz = () => {
  return (
    <div className="popular-quizes-parent flex sm:py-10 sm:h-[673px] md:h-auto sm:overflow-hidden py-20 bg-[#F7F7F7]">
      <div className="container mx-auto parent-container">
        <div className="flex flex-col">
          <div
            key="explore-course-by-stream"
            className="flex flex-col gap-4 lg:gap-10"
          >
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-3xl lg:text-[40px] font-bold lg:leading-[58px] text-black m-0">
                Popular Quizzes
              </h3>
              <div className="hidden md:block">
                <a
                  href="/courses/quiz-modal"
                  className="white-color map-mentor button-styles arrow-button"
                >
                  View All
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    className="arrow-icon"
                  >
                    <path
                      d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="popular-quizes flex flex-wrap lg:justify-center justify-between sm:gap-1 lg:gap-6 ">
              {CourseQuizContent[0].quizcategories.map((quiz) => (card(quiz,"hidden md:block")
               
              ))}
               {CourseQuizContent[0].quizcategories.slice(0,4).map((quiz) => (card(quiz,"block md:hidden")
               
              ))}
            </div>
            <div className="block md:hidden flex flex-row items-center justify-center mt-6">
              <a
                href="/courses/quiz-modal"
                className="white-color map-mentor button-styles arrow-button"
              >
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseStreamQuiz;
