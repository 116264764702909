import React from "react";
import "./HoverCard.scss";

export default function HoverCard({data}:any) {
  return (
    <div className="hc-card">
      <div className="hc-img">
        <img
          src={ data && data.iconUrl}
          alt=""
          className="object-cover aspect-[0.85] w-full rounded-2xl"
          loading="lazy"
        />
      </div>
      <div className="hc-content">
        <h3>{data && data.careerName}</h3>
        <p>{data && data.careerDescriptionShort}</p>
        <button>
          View details
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="#113CC0"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
