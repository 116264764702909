// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vs {
  color: #000;
  font-family: Rakkas;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.17px; /* 100.775% */
}

.title-cs {
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.17px;
}

.compare {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14.78px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-compare/components/CompareScholarship.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA,EAAA,aAAA;AAAJ;;AAGA;EACI,WAAA;EAEJ,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AADA;;AAGA;EACI,WAAA;EAEJ,wBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADA","sourcesContent":[".vs{\n    color: #000;\n\n    font-family: Rakkas;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22.17px; /* 100.775% */\n\n}\n.title-cs{\n    color: #000;\n\nfont-family: \"Open Sans\";\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 22.17px;\n}\n.compare{\n    color: #FFF;\n\nfont-family: \"Open Sans\";\nfont-size: 14.78px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
