import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./BannerStyles.scss";

interface BannerSlideProps {
  bannerInfo: {
    image: string;
    title: string;
    description: string;
  }[];
}

export const BannerSlides: React.FC<BannerSlideProps> = ({ bannerInfo }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const displayedSlides = isMobile ? bannerInfo.slice(0, 3) : bannerInfo;

  return (
    <div className="CareerBanner flex gap-10">
      {displayedSlides.map((slide, index) => (
        <div
          key={index}
          className={classNames(
            "career text-white transition-all duration-500 ease-in-out",
            {
              "bg-cover bg-center": true,
              active: activeIndex === index,
            }
          )}
          style={{
            backgroundImage: `url(${slide.image})`,
            width: activeIndex === index ? "300px" : "100px",
            transition: "width 0.5s ease-in-out",
          }}
          onMouseEnter={() => setActiveIndex(index)}
        >
          <h4 className="title">{slide.title}</h4>
          <div className="career-content">
            <p className="text-base text-white leading-[21.79px]">
              {slide.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
