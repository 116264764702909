import {
  motion,
  animate,
  useMotionValue,
  useTransform,
  useInView,
} from "framer-motion";
import { useLayoutEffect, useRef } from "react";

interface propsType {
  start: number;
  end: number;
  duration?: number;
}

const CounterAnimation = ({ start, end, duration = 1 }: propsType) => {
  const count = useMotionValue(start);
  const roundedCount = useTransform(count, (num) => Math.round(num));

  const ref = useRef<HTMLSpanElement>(null);
  const isInView = useInView(ref);

  useLayoutEffect(() => {
    const controls = animate(count, isInView ? end : start, {
      duration: isInView ? duration : 0,
    });

    return () => controls.stop();
  }, [start, end, duration, isInView]);

  return <motion.span ref={ref}>{roundedCount}</motion.span>;
};

export default CounterAnimation;
