import React from "react";
import buldIcon from "../../images/courses/building-icon.svg";
import "./TakeAFreetest.scss";
import { Link } from "react-router-dom";

const takeAFreetestContent = [
  {
    title: "Find out which bachelor’s programmes, match your personality!",
    button: "Take a Free Test!",
  },
];

const TakeAFreetest: React.FC = () => {
  return (
    <div className="bg-gradient-to-r from-blue-900 to-teal-500 flex py-8 my-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-row w-full">
          {takeAFreetestContent.map((content) => (
            <div className="flex  md:flex-row gap-4 justify-between items-center w-full">
              <div className="flex takefree-heading flex-row gap-3 items-center">
                <img
                  src={buldIcon}
                  alt="building icon"
                  className="w-11 h-11 "
                />
                <h3 className="text-xl  lg:text-2xl font-bold text-white m-0 xs:text-xs sm:text-xs capitalize">
                  {content.title}
                </h3>
              </div>
              <div className="flex takefree-btn h3 flex-col">
                <Link
                  to={"/college-predictor"}
                  className="white-color btn-style flex flex-row items-center justify-center gap-2 sm:text-xs ] button-styles"
                >
                  {content.button}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TakeAFreetest;
