import React, { useState } from "react";

interface Item {
  title: string;
  description: string;
}

const items: Item[] = [
  {
    title: "What is Webflow and why is it the best website builder?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "What is your favorite template from BRIX Templates?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "How do you clone a Webflow Template from the Showcase?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "Why is BRIX Templates the best Webflow agency out there?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

const FaqAccordion: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveItem(index === activeItem ? null : index);
  };

  return (
    <div className="items-container">
      {items.map((item, index) => (
        <>
          <div
            key={index}
            className={`flex flex-col px-12 pt-9 pb-9 mt-10 bg-white rounded-2xl  max-md:px-5 max-md:max-w-full ${
              activeItem === index
                ? "border-2 border-blue-800 border-solid shadow-lg"
                : ""
            }`}
          >
            <div className="flex gap-5 font-medium text-zinc-800 max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto my-auto max-md:max-w-full">
                {item.title}
              </div>
              <button onClick={() => handleClick(index)}>
                {activeItem === index ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                  >
                    <circle
                      cx="25.4871"
                      cy="25.3309"
                      r="25.3309"
                      fill="#113CC0"
                    />
                    <path
                      d="M18.8301 22L25.0783 28.22L31.3266 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                  >
                    <circle
                      cx="25.8311"
                      cy="25"
                      r="24.5"
                      fill="white"
                      stroke="#BDBDBD"
                    />
                    <path
                      d="M22.8311 31L29.0511 24.7518L22.8311 18.5035"
                      stroke="#113CC0"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {activeItem === index && (
              <div className="mt-6 leading-7 text-zinc-500 max-md:max-w-full">
                {item.description}
              </div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default FaqAccordion;
