import React, { createContext, useState, useContext, ReactNode } from 'react';

interface HighlightContextType {
  highlightField: string | null;
  setHighlightField: (field: string | null) => void;
}

const HighlightContext = createContext<HighlightContextType | undefined>(undefined);

export const HighlightProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [highlightField, setHighlightField] = useState<string | null>(null);
  console.log('HighlightProvider rendered', { highlightField });

  return (
    <HighlightContext.Provider value={{ highlightField, setHighlightField }}>
      {children}
    </HighlightContext.Provider>
  );
};

export const useHighlight = () => {
  const context = useContext(HighlightContext);
  if (!context) {
    throw new Error('useHighlight must be used within a HighlightProvider');
  }
  return context;
};
