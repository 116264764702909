import React, { FC, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "./WorldClassUni.scss";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const WorldClassUni = () => {
  const worldClass = useSelector(
    (state: RootState) => state.collegeLanding.worldClass
  );

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  if (!worldClass || worldClass.length <= 0) return;

  const items = worldClass.map((card, index) => (
    <>
      <div className="relative w-fit">
        <div
          className={`card-uni mt-3 ${
            index % 2 === 0 ? "even" : "odd"
          } relative w-[155px] h-[265px] flex-shrink-0 rounded-2xl transition-transform duration-300`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={card.image}
            alt={`University ${index + 1}`}
            className="card-image"
          />
          {hoveredIndex === index && (
            <iframe
              src={card.video}
              className="card-video"
              allow="autoplay; loop"
            />
          )}
        </div>
        <div
          className={`absolute inset-0 bg-[#A6E0DE] rounded-2xl -translate-y-2 translate-x-2 z-[-1]`}
        ></div>
      </div>
    </>
  ));

  return (
    <div className="bg-[#FFF9E5] relative flex">
      <div className="container max-w-[1080px] mt-8 mb-6">
        <h1 className="heading-uni whitespace-nowrap text-center">
          World-Class <span className="text-[#00CC99]">Universities</span>
        </h1>
        <p className="description-uni px-4 md:px-16 py-4 text-center leading-loose">
          Discover world-class universities through virtual or off-campus tours
          in India and abroad, in collaboration with College Mentor. Experience
          firsthand the academic and cultural environments of top institutions.
          Join us to explore and find the perfect Dream College for your
          educational journey.
        </p>
        <CustomSwiper
          className="mt-6 md:max-w-[580px] lg:max-w-full"
          items={items}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            240: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 6,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default WorldClassUni;
