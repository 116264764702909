// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.predictor-subheading {
  color: #383838;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.predictor-heading {
  color: #173CBA;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
}

.predictor-para {
  color: #383838;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

@media (min-width: 760px) and (max-width: 1020px) {
  .predictor-heading {
    font-size: 20px; /* Adjusted font size for medium screens */
    line-height: 22px;
  }
  .predictor-subheading {
    font-size: 14px; /* Adjusted font size for medium screens */
    line-height: 16px;
  }
  .predictor-para {
    font-size: 12px; /* Adjusted font size for medium screens */
    line-height: 14px;
  }
}
.arrow-svg {
  transition: transform 0.3s ease; /* Smooth transition */
}

button:hover .arrow-svg {
  transform: translateX(5px); /* Adjust the amount of movement as needed */
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/landing/components/Banner.scss"],"names":[],"mappings":"AAAA;EAEI,cAAA;EAEJ,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADA;;AAGA;EACI,cAAA;EACJ,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAA;;AAEA;EACI,cAAA;EAEA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAMA;EACI;IACE,eAAA,EAAA,0CAAA;IACA,iBAAA;EAHJ;EAME;IACI,eAAA,EAAA,0CAAA;IACA,iBAAA;EAJN;EAME;IACI,eAAA,EAAA,0CAAA;IACA,iBAAA;EAJN;AACF;AAQA;EACI,+BAAA,EAAA,sBAAA;AANJ;;AASE;EACE,0BAAA,EAAA,4CAAA;AANJ","sourcesContent":[".predictor-subheading\n{\n    color: #383838;\n\nfont-family: \"Open Sans\";\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 27px; \n}\n.predictor-heading{\n    color: #173CBA;\nfont-family: Roboto;\nfont-size: 40px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 55px; \n}\n.predictor-para{\n    color: #383838;\n\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 27px;\n    \n    \n\n}\n\n@media (min-width: 760px) and (max-width: 1020px) {\n    .predictor-heading {\n      font-size: 20px; /* Adjusted font size for medium screens */\n      line-height: 22px; \n\n    }\n    .predictor-subheading{\n        font-size: 14px; /* Adjusted font size for medium screens */\n        line-height: 16px; \n    }\n    .predictor-para{\n        font-size: 12px; /* Adjusted font size for medium screens */\n        line-height: 14px; \n\n    }\n}\n\n.arrow-svg {\n    transition: transform 0.3s ease; /* Smooth transition */\n  }\n  \n  button:hover .arrow-svg {\n    transform: translateX(5px); /* Adjust the amount of movement as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
