import React, { useEffect, useState } from "react";
import TitleCard from "../../components/TitleCard";

import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import ExamBlogsImage4 from "../../../../images/blog-article/exam-section-image4.jpg";
import StudyAbroadImage from "../../../../images/blog-article/study-abroad-image.png";
import BlogInfoCard from "../../components/BlogInfoCard";

import "../BlogsAndArticlesMain.scss";
import BlogCardwithTitle from "../../components/BlogCardwithTitle";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import MentorCard from "../../components/MentorCard";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import { Link } from "react-router-dom";

interface ExamsAndAdmissionsProps {
  examsAndAdmissionsResponse: any;
}
const ExamsAndAdmissions: React.FC<ExamsAndAdmissionsProps> = ({
  examsAndAdmissionsResponse = [],
}) => {
  console.log(
    "examsAndAdmissionsResponse",
    examsAndAdmissionsResponse[0]?.["Exams And Admissions"]?.subcategories
  );
  const { width, height } = GetWindowDimensions();
  const getCurrentHoveredBlog = (param: any) => {
    console.log("first", param);
  };

  let isMobile = width < 700;

  const showOnlyFiveList = (results: any) => {
    if (isMobile) return results.slice(0, 5);
    else return results;
  };
  return (
    <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      <div className="grid grid-flow-row gap-4 grid-rows">
        <TitleCard
          title="Exams & Admissions"
          bgColor="#173CBA"
          color="#ffffff"
        />
        {/* <div className="grid hidden grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:flex">
          <div className="bg-gray-100 ">
            <img src={ExamBlogsImage1} className="object-cover " />
          </div>
          <div className="bg-gray-100 ">
            <img src={ExamBlogsImage2} className="object-cover " />
          </div>
          <div className="bg-gray-100 ">
            <img src={ExamBlogsImage3} className="object-cover h-auto" />
          </div>

          <div className="bg-gray-100 ">
            <img src={ExamBlogsImage4} className="object-cover "  />
          </div>
        </div> */}
        {/* Mobile Only View Swiper with Arrows - working */}
        {/* <div className="grid visible grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-4 md:hidden sm:block">
          <CustomSwiper
            className="!px-2"
            items={[
              ExamBlogsImage1,
              ExamBlogsImage2,
              ExamBlogsImage3,
              ExamBlogsImage4,
            ].map((item) => (
              <BlogCardwithTitle item={item} />
            ))}
            navigation={true}
            autoplay={false}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1280: { slidesPerView: 4, spaceBetween: 30 },
            }}
          />
        </div> */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1">
          <CustomSwiper
            className="!px-2"
            items={examsAndAdmissionsResponse[0]?.[
              "Exams And Admissions"
            ]?.subcategories["Articles & Blogs"]?.results?.map((item: any) => (
              <BlogCardwithTitle item={item} />
            ))}
            navigation={"bottom"}
            autoplay={true}
            progressBar={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 3, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 18 },
              1280: { slidesPerView: 4, spaceBetween: 15 },
            }}
          />
        </div>

        {/* Exams Page News Section - working */}
        {/* <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-4">
          <div className="">
            {[...Array(2)].map(() => (
              <BlogInfoCard dateValue={true} rowReverse={true} />
            ))}
          </div>
          <div className="">
            {[...Array(2)].map(() => (
              <BlogInfoCard dateValue={true} rowReverse={true} />
            ))}
          </div>
          <div className="">
            {[...Array(2)].map(() => (
              <BlogInfoCard dateValue={true} rowReverse={true} />
            ))}
          </div>
          <div className="">
            {[...Array(2)].map(() => (
              <BlogInfoCard dateValue={true} rowReverse={true} />
            ))}
          </div>
        </div> */}

        <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {/* {[...Array(4)].map(() => ( */}
          <>
            {showOnlyFiveList(
              examsAndAdmissionsResponse[0]?.["Exams And Admissions"]
                ?.subcategories["Latest News"]?.results
            )?.map((blogInfo: any) => (
              <BlogInfoCard
                dateValue={true}
                rowReverse={false}
                blogInfo={blogInfo}
                getCurrentHoveredItem={getCurrentHoveredBlog}
                height="full"
                categoryOrderReverse={false}
                borderBottom={false}
                imageField={true}
              />
            ))}
          </>
          {/* ))} */}
        </div>
        {isMobile && (
          <div className="flex flex-row items-center justify-center">
            <Link
              to={`/blogs/Exams And Admissions`}
              state={{
                from: "ExamsandAdminssions view all",
                category: "Exams",
              }}
              className="h-full white-color map-mentor button-styles arrow-button"
            >
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                className="arrow-icon"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamsAndAdmissions;
