
export const statesList = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
];

export const stateAbbrivations = [
    { name: "Andhra Pradesh", code: "AP" },
    { name: "Arunachal Pradesh", code: "AR" },
    { name: "Assam", code: "AS" },
    { name: "Bihar", code: "BR" },
    { name: "Chhattisgarh", code: "CG" },
    { name: "Goa", code: "GA" },
    { name: "Gujarat", code: "GJ" },
    { name: "Haryana", code: "HR" },
    { name: "Himachal Pradesh", code: "HP" },
    { name: "Jammu and Kashmir", code: "JK" },
    { name: "Jharkhand", code: "JH" },
    { name: "Karnataka", code: "KA" },
    { name: "Kerala", code: "KL" },
    { name: "Madhya Pradesh", code: "MP" },
    { name: "Maharashtra", code: "MH" },
    { name: "Manipur", code: "MN" },
    { name: "Meghalaya", code: "ML" },
    { name: "Mizoram", code: "MZ" },
    { name: "Nagaland", code: "NL" },
    { name: "Odisha", code: "OD" },
    { name: "Punjab", code: "PB" },
    { name: "Rajasthan", code: "RJ" },
    { name: "Sikkim", code: "SK" },
    { name: "Tamil Nadu", code: "TN" },
    { name: "Telangana", code: "TS" },
    { name: "Tripura", code: "TR" },
    { name: "Uttarakhand", code: "UK" },
    { name: "Uttar Pradesh", code: "UP" },
    { name: "West Bengal", code: "WB" },
    { name: "Andaman and Nicobar Islands", code: "AN" },
    { name: "Chandigarh", code: "CH" },
    { name: "Dadra and Nagar Haveli", code: "DN" },
    { name: "Daman and Diu", code: "DD" },
    { name: "Delhi", code: "DL" },
    { name: "Lakshadweep", code: "LD" },
    { name: "Puducherry", code: "PY" },
];

export const standardList = [
    "9",
    "10",
    "11",
    "12",
    "Graduate",
    "Post Graduate",
];

export const standardBoardList = [
    {
        standard: "9",
        boardList: [
            "CBSE",
            "State board",
            "International",
            "Others",
        ]
    }, {
        standard: "10",
        boardList: [
            "CBSE",
            "State board",
            "International",
            "Others",
        ]
    }, {
        standard: "11",
        boardList: [
            "State board",
            "International",
            "Others",
        ]
    }, {
        standard: "12",
        boardList: [
            "State board",
            "International",
            "Others",
        ]
    }, {
        standard: "Graduate",
        boardList: [
            "CBSE",
            "ISCE",
            "State board",
            "IB",
            "International",
            "Cambridge",
            "NIOD",
            "Others",
        ]
    }, {
        standard: "Post Graduate",
        boardList: [
            "CBSE",
            "ISCE",
            "State board",
            "IB",
            "International",
            "Cambridge",
            "NIOD",
            "Others",
        ],
    }];

export const fundTypes = [
    "Self Funded",
    "Bank Loan",
    "Scholarship",
    "Need Help",
];
