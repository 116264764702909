import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import Divider from '@mui/material/Divider';
import { HiAcademicCap } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import { FaRegFolderOpen } from "react-icons/fa";
import { BiSelectMultiple } from "react-icons/bi";
import { FaHandPointUp } from "react-icons/fa";

import {
    Autoplay,
    Navigation,
    EffectCreative,
    Pagination,
} from "swiper/modules";
import { title } from "process";
import { Description } from "@mui/icons-material";
import "../../../../components/career-visual-stories/career-visual-style.scss";
const careerVisualStories = [
    {
        id: 1,
        src: "/images/careers/cvs01.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        id: 2,
        src: "/images/careers/cvs02.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        id: 3,
        src: "/images/careers/cvs03.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        id: 4,
        src: "/images/careers/cvs04.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        id: 5,
        src: "/images/careers/cvs04.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        id: 6,
        src: "/images/careers/cvs04.jpg",
        title: "Career Visual Stories",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
];
const careerVisualStoriesRelatedPosts = [
    {
        id: 1,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
    {
        id: 2,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
    {
        id: 3,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
    {
        id: 4,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
    {
        id: 5,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
    {
        id: 6,
        postImgs1: "/images/careers/cvs01.jpg",
        postImgs2: "/images/careers/cvs02.jpg",
        postImgs3: "/images/careers/cvs03.jpg",
    },
];
interface Visual_storiesProps {
    VisualStories: string;
}
export const Visual_stories: React.FC<Visual_storiesProps> = ({ VisualStories }) => {
    const [activeTab, setActiveTab] = useState<string>("testNarratives");
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);
    const tabs = [
        { label: 'Home', content: <div>Home Content</div> },
        { label: 'Profile', content: <div>Profile Content</div> },
        { label: 'Settings', content: <div>Settings Content</div> },
    ];
    const openPopup = (index: number) => {
        setSelectedSlideIndex(index);
        setPopupOpen(true);
    };
    const closePopup = () => {
        setPopupOpen(false);
    };
    const handleTabChange = (tabName: string) => {
        setActiveTab(tabName);
    };
    const tabContent: { [key: string]: JSX.Element } = {
        testNarratives: (
            <>
                <div>
                    Faculty position: Applications are invited for the post of Professor, Associate Professor, Assistant Professor, Visiting faculty and Contractual faculty.
                </div>
            </>
        ),
        examDayStories: (
            <>
                <div>
                    Director, Associate/ Assistant Director
                </div>
            </>
        ),
        studentExperiences: (
            <>
                <div>
                </div>
                <h1>studentExperiences</h1>
            </>
        ),
        examNews: (
            <>
                <div>
                    Department Positions

                </div>

            </>
        ),
    };
    return (
        <div className="CarreVisualStories flex flex-col px-5 w-[1360px] mx-auto">
            <div id={VisualStories} className=" flex">
                <h3 className="text-3xl text-[#231F40] font-extrabold">
                    Visual Stories
                </h3>
            </div>
            <div className="mt-10 w-full max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-[24px] max-md:flex-col max-md:gap-0">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={5}
                        navigation={{
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        }}
                        // autoplay={{
                        //   delay: 3000,
                        //   disableOnInteraction: false,
                        //   pauseOnMouseEnter: true,
                        // }}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Autoplay]}
                        loop={true}
                        className="h-full w-full flex flex-row justify-center items-center"
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 16,
                            },
                            1260: {
                                slidesPerView: 5,
                                spaceBetween: 16,
                            },
                        }}
                    >
                        {careerVisualStories.map((content, index) => (
                            <SwiperSlide key={index} onClick={() => openPopup(index)}>
                                <div className="flex flex-col max-md:ml-0 max-md:w-full rounded-2xl relative">
                                    <img
                                        loading="lazy"
                                        src={content.src}
                                        alt={content.title}
                                        className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
                                    />
                                    <div className="career-visual-content flex flex-col gap-3 hidden absolute bg-black/30 bottom-0 text-white p-4 rounded-2xl transition duration-200 ease-in-out">
                                        <h3 className="text-2xl text-white transition duration-200 ease-in-out font-bold">
                                            {content.title}
                                        </h3>
                                        <p className="transition duration-200 ease-in-out text-base line-clamp-3">
                                            {content.description}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev">
                            <button className="carosalButtons rounded-full bg-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    width={20}
                                    height={20}
                                >
                                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                                </svg>{" "}
                            </button>
                        </div>
                        <div className="swiper-button-next">
                            <button className="carosalButtons rounded-full bg-white flex">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    width={14}
                                    height={14}
                                >
                                    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                </svg>
                            </button>
                        </div>
                    </Swiper>
                    {popupOpen && (
                        <>
                            <div
                                className="fixed inset-0 bg-black opacity-50 z-40"
                                onClick={closePopup}
                            ></div>
                            <div
                                id="modal"
                                className="fixed inset-0 flex items-center justify-center z-50 w-full h-full rounded-lg"
                            >
                                <button className="close-button" onClick={closePopup}>
                                    X
                                </button>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    navigation={true}
                                    pagination={true}
                                    effect={"creative"}
                                    loop={true}
                                    initialSlide={selectedSlideIndex}
                                    className="popup-swiper flex flex-row justify-center items-center w-full h-full"
                                >
                                    {[1, 2, 3].map((index) => (
                                        <SwiperSlide key={index}>
                                            <div className="flex justify-center items-center">
                                                <img
                                                    src={
                                                        (
                                                            careerVisualStoriesRelatedPosts[
                                                            selectedSlideIndex
                                                            ] as any
                                                        )[`postImgs${index}`]
                                                    }
                                                    alt={`Related Post ${index}`}
                                                    className="popup-slide"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div id={VisualStories} className=" flex">
                <h3 className="text-3xl text-[#231F40] font-extrabold">
                    Careers at SRM
                </h3>
            </div>
            <div>In the year 2017, the multi-disciplinary research-intensive university was established in Amaravati, Andhra Pradesh, envisioning quality education and international exposure to budding students pursuing their higher studies.
                We strive to be a leading university that is globally connected, regionally transformative and nationally relevant by combining academic rigour, an excellent research ecosystem, entrepreneurial heritage, and pioneering faculty</div>
            <div className="text-center font_1_position">Current Open Positions</div>
            <Divider>
                <div style={{ background: '#473D00', height: '2px' }} className="py-0 px-10"></div>
            </Divider>
            <div className="flex justify-center gap-4 mt-8 text-sm font-semibold text-center text-zinc-800 max-md:flex-wrap">
                <button
                    className={`justify-center flex gap-2 items-center px-6 py-3 bg-[#C58A2B] border border-solid border-neutral-200  max-md:px-5 ${activeTab === "testNarratives" ? "active_current_opinion" : "inactive_current_opinion"
                        }`}
                    onClick={() => handleTabChange("testNarratives")}
                >
                    <HiAcademicCap style={{ color: '#FFFFFF', fontSize: '1rem' }} />Academic Positions
                </button>
                <button
                    className={`justify-center flex gap-2 items-center px-6 py-3 bg-[#C58A2B] border border-solid border-neutral-200  max-md:px-5 ${activeTab === "examDayStories" ? "active_current_opinion" : "inactive_current_opinion"
                        }`}
                    onClick={() => handleTabChange("examDayStories")}
                >
                    <FaUserAlt style={{ color: '#FFFFFF', fontSize: '1rem' }} /> Academic Leadership Positions
                </button>
                <button
                    className={`justify-center flex gap-2 items-center px-6 py-3 bg-[#C58A2B] border border-solid border-neutral-200  max-md:px-5 ${activeTab === "studentExperiences" ? "active_current_opinion" : "inactive_current_opinion"
                        }`}
                    onClick={() => handleTabChange("studentExperiences")}
                >
                    <FaRegFolderOpen style={{ color: '#FFFFFF', fontSize: '1rem' }} /> Non-Teaching Leadership Positions
                </button>
                <button
                    className={`justify-center flex gap-2 items-center px-6 py-3 bg-[#C58A2B] border border-solid border-neutral-200  max-md:px-5 ${activeTab === "examNews" ? "active_current_opinion" : "inactive_current_opinion"
                        }`}
                    onClick={() => handleTabChange("examNews")}
                >
                    <BiSelectMultiple style={{ color: '#FFFFFF', fontSize: '1rem' }} />  Non-Teaching Positions
                </button>
            </div>
            <div className="tabcontent_style">{tabContent[activeTab]}</div>
            <div className="Rolling_advertise">Rolling Advertisement : Applications Are Invited through out the year</div>
            <div className="flex gap-5">
                <div className="px-5" style={{ height: '3px', background: '#C58A2B', width: '7%' }}></div>
                <div className="px-5" style={{ height: '1px', background: '#a4a6a5', width: '61%' }}></div>
            </div>
            <div className="flex  gap-5">
                <div>
                    <div className="font-bold">Advertisement: <span className="font-normal">Rolling Advertisement(SRM,AP/2024/Faculty Positions/01;Dated 30 APR 2024)</span></div>
                    <div className="flex items-center gap-10 my-3">
                        <div className="font-bold" style={{width:'35%'}}>For Details Information</div>
                        <button className="rounded-full rolling_btn p-2" style={{background:'#C3892B'}}>Click here</button>
                    </div>
                    <div className="flex gap-10 items-center">
                        <div className="font-bold" style={{width:'35%'}}>To apply please click the link</div>
                        <button className="rounded-full rolling_btn p-2 flex items-center justify-center gap-2" style={{background:'#C3892B'}}>Apply <FaHandPointUp/></button>
                    </div>

                </div>
                <div>
                    <div className="star-badge">NEW</div>
                </div>
            </div>
        </div>
    );
};
