import { url } from "inspector";
import { title } from "process";
import React, { useMemo, useState } from "react";
import programeImg from "../../../../images/college/details/course-fee/program-img.jpeg";
import { Value } from "sass";

interface AccordionProps {
  title: string;
  //   options: string[];
  filters: IFilter;
  onFilterChange: (value: string) => void;
}
interface Item {
  title: string;
  duration: number;
  intake: number;
  fee: number;
  //   eligibility: string;
  //   curriculum: string;
  //   syllabus: string;
  examsAccepted: string;
  eligibility: { title: string; url: string } | string;
  curriculum: { title: string; url: string } | string;
  syllabus: { title: string; url: string } | string;
  elg_school: string;
  class: string;
  academic_session: string;
  monthly_fee_range: string;
  admission_process: string;
  board: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  filters,
  onFilterChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: boolean;
  }>({});
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (index: number) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    onFilterChange(filters.options[index].value);
  };

  return (
    <div className="flex flex-col border-b border-[#E0E0E0] last:border-b-0">
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="flex flex-row justify-between items-center w-full">
          <h4 className="text-base text-black w-full flex flex-row justify-between items-center py-4 font-bold ">
            {title}
            <span className={`text-[#333333]`}>
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isOpen ? "transform rotate-180" : ""}`}
              >
                <path
                  d="M0.408323 0.341667C0.485792 0.26356 0.577959 0.201565 0.679508 0.159258C0.781058 0.116951 0.889979 0.0951691 0.99999 0.0951691C1.11 0.0951691 1.21892 0.116951 1.32047 0.159258C1.42202 0.201565 1.51419 0.26356 1.59166 0.341667L5.40832 4.15833C5.48579 4.23643 5.57796 4.29843 5.67951 4.34073C5.78106 4.38304 5.88998 4.40482 5.99999 4.40482C6.11 4.40482 6.21892 4.38304 6.32047 4.34073C6.42202 4.29843 6.51419 4.23643 6.59166 4.15833L10.4083 0.341667C10.4858 0.26356 10.578 0.201565 10.6795 0.159258C10.7811 0.116951 10.89 0.0951691 11 0.0951691C11.11 0.0951691 11.2189 0.116951 11.3205 0.159258C11.422 0.201565 11.5142 0.26356 11.5917 0.341667C11.7469 0.497802 11.834 0.709012 11.834 0.929166C11.834 1.14932 11.7469 1.36053 11.5917 1.51666L7.76666 5.34166C7.29791 5.80982 6.66249 6.07279 5.99999 6.07279C5.33749 6.07279 4.70207 5.80982 4.23332 5.34166L0.408323 1.51666C0.253114 1.36053 0.165995 1.14932 0.165995 0.929166C0.165995 0.709012 0.253114 0.497802 0.408323 0.341667Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </h4>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <ul className="flex flex-col gap-3 pb-3">
            {filters.options.map((option, index) => (
              <li
                key={index}
                className="flex items-center text-[#4F4F4F] font-medium text-sm"
              >
                <input
                  type="checkbox"
                  id={`option-${index}`}
                  name={`option-${index}`}
                  onChange={() => handleCheckboxChange(index)}
                  className={`h-5 w-5 border ${
                    selectedOptions[index]
                      ? "border-[#113CC0]"
                      : "border-[#E0E0E0]"
                  }`}
                />
                <label
                  htmlFor={`option-${index}`}
                  className={`ml-2 ${
                    selectedOptions[index] ? "text-[#113CC0]" : ""
                  }`}
                >
                  {option.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const items: Item[] = [
  {
    title: "What is Webflow and why is it the best website builder?",
    duration: 4,
    intake: 120,
    fee: 20000,
    eligibility: {
      title: "eligibility",
      url: "#",
    },
    curriculum: {
      title: "curriculum",
      url: "#",
    },
    syllabus: {
      title: "syllabus",
      url: "#",
    },
    examsAccepted: "JEE",
    elg_school: "school_of_engineering",
    class: "grade_1",
    academic_session: "2021-2022",
    monthly_fee_range: "0-500",
    admission_process: "direct",
    board: "cbse",
  },
  {
    title: "What is your favorite template from BRIX Templates?",
    duration: 4,
    intake: 120,
    fee: 20000,
    eligibility: {
      title: "eligibility",
      url: "#",
    },
    curriculum: {
      title: "curriculum",
      url: "#",
    },
    syllabus: {
      title: "syllabus",
      url: "#",
    },
    examsAccepted: "NEET",
    elg_school: "school_of_medicine",

    class: "grade_2",
    academic_session: "2022-2023",
    monthly_fee_range: "500-1000",
    admission_process: "entrance_exam",
    board: "icse",
  },
  {
    title: "How do you clone a Webflow Template from the Showcase?",
    duration: 4,
    intake: 120,
    fee: 20000,
    eligibility: {
      title: "eligibility",
      url: "#",
    },
    curriculum: {
      title: "curriculum",
      url: "#",
    },
    syllabus: {
      title: "syllabus",
      url: "#",
    },
    examsAccepted: "JEE",
    elg_school: "school_of_engineering",
    class: "grade_1",
    academic_session: "2021-2022",
    monthly_fee_range: "0-500",
    admission_process: "direct",
    board: "cbse",
  },
  {
    title: "Why is BRIX Templates the best Webflow agency out there?",

    duration: 4,
    intake: 120,
    fee: 20000,
    eligibility: {
      title: "eligibility",
      url: "#",
    },
    curriculum: {
      title: "curriculum",
      url: "#",
    },
    syllabus: {
      title: "syllabus",
      url: "#",
    },
    examsAccepted: "JEE",
    elg_school: "school_of_engineering",

    class: "grade_1",
    academic_session: "2021-2022",
    monthly_fee_range: "0-500",
    admission_process: "direct",
    board: "cbse",
  },
];

type IFilter = {
  title: string;
  data_key: keyof Item;
  options: Array<{
    value: string;
    label: string;
    isSelected?: boolean;
  }>;
};

const programsFilters: Array<IFilter> = [
  {
    title: "Eligible Schools",
    data_key: "elg_school",
    options: [
      { value: "school_of_engineering", label: "School of Engineering" },
      { value: "school_of_medicine", label: "School of Medicine" },
      { value: "school_of_business", label: "School of Business" },
      { value: "school_of_law", label: "School of Law" },
      { value: "school_of_arts", label: "School of Arts" },
      { value: "school_of_science", label: "School of Science" },
    ],
  },
  {
    title: "Class",
    data_key: "class",
    options: [
      { value: "play_group", label: "Play Group" },
      { value: "nursery", label: "Nursery" },
      { value: "kindergarten", label: "Kindergarten" },
      { value: "preparatory", label: "Preparatory" },
      { value: "grade_1", label: "Grade 1" },
      { value: "grade_2", label: "Grade 2" },
      { value: "grade_3", label: "Grade 3" },
      { value: "grade_4", label: "Grade 4" },
      { value: "grade_5", label: "Grade 5" },
      { value: "lkg", label: "LKG" },
    ],
  },
  {
    title: "Academic Session",
    data_key: "academic_session",

    options: [
      { label: "2021-2022", value: "2021-2022" },
      { label: "2022-2023", value: "2022-2023" },
      { label: "2023-2024", value: "2023-2024" },
      { label: "2024-2025", value: "2024-2025" },
      { label: "2025-2026", value: "2025-2026" },
      { label: "2026-2027", value: "2026-2027" },
      { label: "2027-2028", value: "2027-2028" },
      { label: "2028-2029", value: "2028-2029" },
      { label: "2029-2030", value: "2029-2030" },
      { label: "2030-2031", value: "2030-2031" },
    ],
  },
  {
    title: "Monthly Fee Range",
    data_key: "monthly_fee_range",

    options: [
      { value: "0-500", label: "0-500" },
      { value: "500-1000", label: "500-1000" },
      { value: "1000-1500", label: "1000-1500" },
      { value: "1500-2000", label: "1500-2000" },
      { value: "2000-2500", label: "2000-2500" },
      { value: "2500-3000", label: "2500-3000" },
      { value: "3000-3500", label: "3000-3500" },
      { value: "3500-4000", label: "3500-4000" },
      { value: "4000-4500", label: "4000-4500" },
      { value: "4500-5000", label: "4500-5000" },
    ],
  },
  {
    title: "Admission Process",
    data_key: "admission_process",
    options: [
      { value: "direct", label: "Direct" },
      { value: "entrance_exam", label: "Entrance Exam" },
      { value: "interview", label: "Interview" },
      { value: "merit_based", label: "Merit Based" },
      { value: "first_come_first_serve", label: "First Come First Serve" },
      { value: "lucky_draw", label: "Lucky Draw" },
    ],
  },
  {
    title: "Board",
    data_key: "board",
    options: [
      { value: "cbse", label: "CBSE" },
      { value: "icse", label: "ICSE" },
      { value: "state_board", label: "State Board" },
      { value: "international_board", label: "International Board" },
      { value: "ib", label: "IB" },
      { value: "igcse", label: "IGCSE" },
      { value: "cambridge", label: "Cambridge" },
      { value: "edexcel", label: "Edexcel" },
      { value: "n/a", label: "N/A" },
    ],
  },
];
const Programs: React.FC = () => {
  const campusDropdown = [
    "All Campuses",
    "Kattankulathur Campus",
    "Ramapuram Campus",
    "Vadapalani Campus",
    "NCR Campus",
    "Sikkim Campus",
    "Amaravati Campus",
  ];

  const facultyDropdown = [
    "All Faculties",
    "Faculty of Engineering",
    "Faculty of Medicine",
    "Faculty of Business",
    "Faculty of Law",
    "Faculty of Arts",
    "Faculty of Science",
  ];
  const departmentDropdown = [
    "All Departments",
    "Department of Computer Science",
    "Department of Electronics",
    "Department of Mechanical",
    "Department of Civil",
    "Department of Electrical",
    "Department of Chemical",
  ];

  const [allColleges, setAllColleges] = useState(items);

  const [filters, setFilters] = useState(programsFilters);

  const [searchValue, setSearchValue] = useState("");


  const filteredColleges = useMemo(() => {
    return allColleges.filter((eachCollege) => {
      return filters.every((filter) => {
        if (filter.options.some((option) => option.isSelected)) {
          return filter.options.some((option) => {
            if (option.isSelected) {
              return eachCollege[filter.data_key] === option.value;
            }
            return false;
          });
        }
        return true;
      });
    });
  }, [allColleges, filters]);
  const handleDropdownChange = (
    filterKey: string,
    selectedOptionValue: string
  ) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.data_key === filterKey
          ? {
              ...filter,
              options: filter.options.map((option) =>
                option.value === selectedOptionValue
                  ? { ...option, isSelected: true }
                  : { ...option, isSelected: false }
              ),
            }
          : filter
      )
    );
  };

  const searchedColleges = useMemo(() => {
    if (searchValue?.length > 0) {
      return filteredColleges.filter((college) => {
        return college.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    } else {
      return filteredColleges;
    }
  }, [searchValue, filteredColleges]);
  return (
    <div className="flex flex-row py-16 bg-[#F4F7FB]" id="faqs">
      <div className="container mx-auto">
        <div className="">
          <div className="flex flex-col justify-center gap-8">
            <div className="flex flex-row flex-wrap justify-center">
              <h3 className="text-3xl font-bold text-[#231F40]">Programs</h3>
            </div>
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="flex gap-4 md:flex-col lg:w-1/5">
                <div className="bg-white rounded-lg p-4">
                  {programsFilters.map((filter, index) => (
                    <Accordion
                      key={index}
                      filters={filter}
                      //   setFilters={setFilters}
                      onFilterChange={(value) => {
                        let updatedFilter = [...filters];

                        updatedFilter[index].options = updatedFilter[
                          index
                        ].options.map((option) => {

                          if (option.value === value) {
                            return {
                              ...option,
                              isSelected: !option.isSelected,
                            };
                          }
                          return option;
                        });

                        setFilters(updatedFilter);
                      }}
                      title={filter.title}
                      //   options={filter.options}
                    />
                  ))}
                </div>
              </div>
              <div className="flex gap-3 md:flex-col lg:w-4/5">
                <div className="flex flex-col gap-3 sm:flex-row">
                  <input
                    type="text"
                    name="Program Name Input"
                    placeholder={`Program Name`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="py-3 px-6 rounded-[30px] bg-white text-base w-full text-[#000000] border-none"
                  />
                  <select
                    name="Campus Dropdown"
                    className="p-3 rounded-[30px] text-base w-full text-[#000000] border-none"
                    onChange={(e) =>
                      handleDropdownChange("elg_school", e.target.value)
                    }
                  >
                    <option value="" disabled selected>
                      All Campuses
                    </option>
                    {campusDropdown.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    name="Faculty Dropdown"
                    className="p-3 rounded-[30px] text-base w-full text-[#000000] border-none"
                    onChange={(e) =>
                      handleDropdownChange("class", e.target.value)
                    }
                  >
                    <option value="" disabled selected>
                      All Faculties
                    </option>
                    {facultyDropdown.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    name="Departments Dropdown"
                    className="p-3 rounded-[30px] text-base w-full text-[#000000] border-none"
                    onChange={(e) =>
                      handleDropdownChange("academic_session", e.target.value)
                    }
                  >
                    <option value="" disabled selected>
                      All Departments
                    </option>
                    {departmentDropdown.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <button className="blue-color button-styles">Search</button>
                </div>
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex flex-col justify-between items-center w-full">
                    <div className="flex flex-col lg:flex-row gap-3 justify-between items-center w-full">
                      <h3 className="text-lg font-bold text-[#231F40]">
                        Courses Offered By SRMIST, Chennai
                      </h3>
                      <a
                        href="#"
                        className="text-[#020842] text-base font-semibold flex flex-row gap-2 items-center"
                      >
                        Write Review{" "}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2507_1230)">
                            <path
                              d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
                              fill="currentColor"
                            />
                            <path
                              d="M7.99935 6.66687H7.33268C7.15587 6.66687 6.9863 6.73711 6.86128 6.86213C6.73625 6.98716 6.66602 7.15673 6.66602 7.33354C6.66602 7.51035 6.73625 7.67992 6.86128 7.80494C6.9863 7.92997 7.15587 8.0002 7.33268 8.0002H7.99935V12.0002C7.99935 12.177 8.06958 12.3466 8.19461 12.4716C8.31963 12.5966 8.4892 12.6669 8.66601 12.6669C8.84282 12.6669 9.01239 12.5966 9.13742 12.4716C9.26244 12.3466 9.33268 12.177 9.33268 12.0002V8.0002C9.33268 7.64658 9.1922 7.30744 8.94215 7.05739C8.69211 6.80735 8.35297 6.66687 7.99935 6.66687Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8 5.33313C8.55228 5.33313 9 4.88541 9 4.33313C9 3.78085 8.55228 3.33313 8 3.33313C7.44772 3.33313 7 3.78085 7 4.33313C7 4.88541 7.44772 5.33313 8 5.33313Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2507_1230">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                      {searchedColleges.map((eachCollege) => {
                        const {
                          title,
                          examsAccepted,
                          duration,
                          intake,
                          fee,
                          eligibility,
                          curriculum,
                          syllabus,
                        } = eachCollege;
                        return (
                          <div className="flex flex-row gap-5 items-center bg-white p-3 rounded-2xl w-full">
                            <div className="flex flex-col gap-3 w-44 h-44 overflow-hidden rounded-xl">
                              <img
                                src={programeImg}
                                alt="course"
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <div className="flex flex-col gap-6 w-full">
                              <div className="flex flex-col gap-3">
                                <div className="flex flex-row gap-3 justify-between items-center w-full">
                                  <h4 className="text-lg font-bold text-[#231F40]">
                                    {title}
                                  </h4>
                                  <p className="text-base text-[#333333]">
                                    Exams Accepted:{" "}
                                    <span className="text-[#EB5757]">
                                      {examsAccepted}
                                    </span>
                                  </p>
                                </div>
                                <div className="flex flex-row gap-3 items-center">
                                  <span className="border border-[#E0E0E0] py-2 px-6 rounded-[40px] flex flex-row gap-4">
                                    <a
                                      href="#"
                                      className="border-r border-[#E0E0E0] pr-3"
                                    >
                                      Duration: {duration}
                                    </a>
                                    <a
                                      href="#"
                                      className="border-r border-[#E0E0E0] pr-3"
                                    >
                                      Intake: {intake}
                                    </a>
                                    <a href="#">Fee: {fee}</a>
                                  </span>
                                </div>
                                <div className="flex flex-row gap-3">
                                  <span className="border border-[#E0E0E0] py-2 px-6 rounded-[40px] flex flex-row gap-4 font-semibold">
                                    <a
                                      href="#"
                                      className="border-r border-[#E0E0E0] pr-3"
                                    >
                                      Eligibility
                                    </a>
                                    <a
                                      href="#"
                                      className="border-r border-[#E0E0E0] pr-3"
                                    >
                                      Curriculum
                                    </a>
                                    <a href="#">Syllabus</a>
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row gap-2">
                                <button className=" bg-[#2D9CDB] text-white py-2 px-4 text-sm font-medium rounded-full">
                                  Admission Predictor
                                </button>
                                <button className="bg-[#27AE60] text-white py-2 px-4 text-sm font-medium rounded-full flex flex-row items-center gap-2">
                                  Download Brochure{" "}
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_2511_430)">
                                      <path
                                        d="M4.9396 9.061C5.0789 9.20039 5.2443 9.31097 5.42635 9.38641C5.6084 9.46185 5.80354 9.50068 6.0006 9.50068C6.19766 9.50068 6.3928 9.46185 6.57485 9.38641C6.7569 9.31097 6.9223 9.20039 7.0616 9.061L8.6671 7.4555C8.75318 7.3603 8.79935 7.23564 8.79606 7.10733C8.79277 6.97902 8.74025 6.8569 8.6494 6.76624C8.55855 6.67558 8.43631 6.62333 8.308 6.6203C8.17968 6.61728 8.05512 6.66372 7.9601 6.75L6.4971 8.2135L6.5006 0.5C6.5006 0.367392 6.44792 0.240215 6.35415 0.146447C6.26039 0.0526784 6.13321 0 6.0006 0V0C5.86799 0 5.74082 0.0526784 5.64705 0.146447C5.55328 0.240215 5.5006 0.367392 5.5006 0.5L5.4961 8.204L4.0411 6.75C3.94728 6.65625 3.82006 6.6036 3.68742 6.60365C3.55479 6.6037 3.42761 6.65643 3.33385 6.75025C3.2401 6.84407 3.18745 6.97129 3.1875 7.10393C3.18755 7.23656 3.24028 7.36375 3.3341 7.4575L4.9396 9.061Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M11.5 7.99994C11.3674 7.99994 11.2402 8.05262 11.1464 8.14638C11.0527 8.24015 11 8.36733 11 8.49994V10.4999C11 10.6325 10.9473 10.7597 10.8536 10.8535C10.7598 10.9473 10.6326 10.9999 10.5 10.9999H1.5C1.36739 10.9999 1.24021 10.9473 1.14645 10.8535C1.05268 10.7597 1 10.6325 1 10.4999V8.49994C1 8.36733 0.947322 8.24015 0.853553 8.14638C0.759785 8.05262 0.632608 7.99994 0.5 7.99994C0.367392 7.99994 0.240215 8.05262 0.146447 8.14638C0.0526784 8.24015 0 8.36733 0 8.49994L0 10.4999C0 10.8978 0.158035 11.2793 0.43934 11.5606C0.720644 11.8419 1.10218 11.9999 1.5 11.9999H10.5C10.8978 11.9999 11.2794 11.8419 11.5607 11.5606C11.842 11.2793 12 10.8978 12 10.4999V8.49994C12 8.36733 11.9473 8.24015 11.8536 8.14638C11.7598 8.05262 11.6326 7.99994 11.5 7.99994Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2511_430">
                                        <rect
                                          width="12"
                                          height="12"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                <button className="bg-[#2F80ED] text-white py-2 px-4 text-sm font-medium rounded-full">
                                  Compare
                                </button>
                                <button className="bg-[#F2994A] text-white py-2 px-4 text-sm font-medium rounded-full flex flex-row items-center justify-center gap-2">
                                  Add to list{" "}
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.75047 0.958252C8.18744 0.967009 7.6367 1.12418 7.15385 1.41389C6.671 1.7036 6.27315 2.11559 6.00047 2.60825C5.72779 2.11559 5.32993 1.7036 4.84709 1.41389C4.36424 1.12418 3.81349 0.967009 3.25047 0.958252C2.35294 0.997247 1.50732 1.38988 0.898359 2.05037C0.289397 2.71086 -0.0334078 3.58551 0.000467427 4.48325C0.000467427 6.75675 2.39347 9.23975 4.40047 10.9233C4.84858 11.2998 5.41514 11.5063 6.00047 11.5063C6.58579 11.5063 7.15236 11.2998 7.60047 10.9233C9.60747 9.23975 12.0005 6.75675 12.0005 4.48325C12.0343 3.58551 11.7115 2.71086 11.1026 2.05037C10.4936 1.38988 9.648 0.997247 8.75047 0.958252ZM6.95797 10.1583C6.68996 10.3839 6.35084 10.5077 6.00047 10.5077C5.65009 10.5077 5.31098 10.3839 5.04297 10.1583C2.47397 8.00275 1.00047 5.93475 1.00047 4.48325C0.966288 3.85061 1.18365 3.23012 1.60515 2.7571C2.02665 2.28409 2.61808 1.99693 3.25047 1.95825C3.88285 1.99693 4.47429 2.28409 4.89579 2.7571C5.31728 3.23012 5.53465 3.85061 5.50047 4.48325C5.50047 4.61586 5.55315 4.74304 5.64691 4.83681C5.74068 4.93057 5.86786 4.98325 6.00047 4.98325C6.13308 4.98325 6.26025 4.93057 6.35402 4.83681C6.44779 4.74304 6.50047 4.61586 6.50047 4.48325C6.46629 3.85061 6.68365 3.23012 7.10515 2.7571C7.52665 2.28409 8.11808 1.99693 8.75047 1.95825C9.38285 1.99693 9.97429 2.28409 10.3958 2.7571C10.8173 3.23012 11.0346 3.85061 11.0005 4.48325C11.0005 5.93475 9.52697 8.00275 6.95797 10.1563V10.1583Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                                <button className="bg-[#003FBC] text-white py-2 px-4 text-sm font-medium rounded-full">
                                  Apply Now
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
