import React from "react";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-header">
        <h1>404 NOT FOUND</h1>
      </div>

      <div className="not-found-main">
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
      {/* 
      <div className="not-found-footer">
        <p>Footer content here</p>
      </div> */}
    </div>
  );
};

export default NotFoundPage;
