import React, { useEffect, useState } from 'react'
import { PiWechatLogoBold } from "react-icons/pi";
import '../Pick-Info/styles.scss'
import { RiArrowRightSLine } from "react-icons/ri";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import reviews from '../Pick-Info/Images/reviews.png'
type Props = {}
interface Profile {
  name: string;
  avatarUrl: string;
  bio: string;
  lastOnline: string;
  interestsAndHobbies: string;
}
const profiles = [
  {
    name: "Keertana",
    avatarUrl: "https://example.com/keertana.jpg",
    bio: "Hi! I am Keertana, a passionate Computer Science student pursuing B.Tech degree at SRM University. I have a strong work ethic and commitment to excellence. SRM University has provided me with a dynamic learning environment with a supportive community of educators, where I strive to make a difference. I am always open and willing to answer any questions you may have!",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Chennai",
    study: "B.Tech in Computer Science"
  },
  {
    name: "Dhruv Bagai",
    avatarUrl: "https://example.com/dhruv.jpg",
    bio: "Hey ya aspirant! 👋 I'm Dhruv Bagai, a 2nd year UG student at SRM University AP, pursuing BTech in Mechanical Engineering. I'm here to assist you on your journey through our campus and to clarify any doubts you may have about SRM University AP while searching for a great university for yourself. Feel free to chat with me, and I'll guide you as a peer in your search for the perfect university for you with honesty you may be looking for, just like I did once, long back... 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Amaravati",
    study: "B.Tech in Mechanical Engineering"
  },
  {
    name: "Meghana Kiran",
    avatarUrl: "https://example.com/meghana.jpg",
    bio: "Hey there! I'm Meghana Kiran, a 2nd-year student pursuing Computer Science Engineering at SRM University AP. Beyond coding, I'm deeply involved in various extra curriculars like clubs and societies. I'm here to guide you through both academic and other extracurricular aspects in the University. Excited to assist you on this journey. Let's connect! 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Vijayawada",
    study: "B.Tech in Computer Science Engineering"
  },
  {
    name: "Keertana",
    avatarUrl: "https://example.com/keertana.jpg",
    bio: "Hi! I am Keertana, a passionate Computer Science student pursuing B.Tech degree at SRM University. I have a strong work ethic and commitment to excellence. SRM University has provided me with a dynamic learning environment with a supportive community of educators, where I strive to make a difference. I am always open and willing to answer any questions you may have!",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Chennai",
    study: "B.Tech in Computer Science"
  },
  {
    name: "Dhruv Bagai",
    avatarUrl: "https://example.com/dhruv.jpg",
    bio: "Hey ya aspirant! 👋 I'm Dhruv Bagai, a 2nd year UG student at SRM University AP, pursuing BTech in Mechanical Engineering. I'm here to assist you on your journey through our campus and to clarify any doubts you may have about SRM University AP while searching for a great university for yourself. Feel free to chat with me, and I'll guide you as a peer in your search for the perfect university for you with honesty you may be looking for, just like I did once, long back... 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Amaravati",
    study: "B.Tech in Mechanical Engineering"
  },
  {
    name: "Meghana Kiran",
    avatarUrl: "https://example.com/meghana.jpg",
    bio: "Hey there! I'm Meghana Kiran, a 2nd-year student pursuing Computer Science Engineering at SRM University AP. Beyond coding, I'm deeply involved in various extra curriculars like clubs and societies. I'm here to guide you through both academic and other extracurricular aspects in the University. Excited to assist you on this journey. Let's connect! 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Vijayawada",
    study: "B.Tech in Computer Science Engineering"
  },
  {
    name: "Keertana",
    avatarUrl: "https://example.com/keertana.jpg",
    bio: "Hi! I am Keertana, a passionate Computer Science student pursuing B.Tech degree at SRM University. I have a strong work ethic and commitment to excellence. SRM University has provided me with a dynamic learning environment with a supportive community of educators, where I strive to make a difference. I am always open and willing to answer any questions you may have!",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Chennai",
    study: "B.Tech in Computer Science"
  },
  {
    name: "Dhruv Bagai",
    avatarUrl: "https://example.com/dhruv.jpg",
    bio: "Hey ya aspirant! 👋 I'm Dhruv Bagai, a 2nd year UG student at SRM University AP, pursuing BTech in Mechanical Engineering. I'm here to assist you on your journey through our campus and to clarify any doubts you may have about SRM University AP while searching for a great university for yourself. Feel free to chat with me, and I'll guide you as a peer in your search for the perfect university for you with honesty you may be looking for, just like I did once, long back... 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Amaravati",
    study: "B.Tech in Mechanical Engineering"
  },
  {
    name: "Meghana Kiran",
    avatarUrl: "https://example.com/meghana.jpg",
    bio: "Hey there! I'm Meghana Kiran, a 2nd-year student pursuing Computer Science Engineering at SRM University AP. Beyond coding, I'm deeply involved in various extra curriculars like clubs and societies. I'm here to guide you through both academic and other extracurricular aspects in the University. Excited to assist you on this journey. Let's connect! 😊",
    lastOnline: "Yesterday",
    interestsAndHobbies: "INTERESTS AND HOBBIES",
    city: "Vijayawada",
    study: "B.Tech in Computer Science Engineering"
  },
];
export const ChatWithAmbasiddors = (props: Props) => {

  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);
  const [visibleCount, setVisibleCount] = useState(6); // Initial number of visible cards
  const [showAll, setShowAll] = useState(false);
  const toggleVisibility = () => {
    setShowAll(!showAll); // Toggle the visibility state
    setVisibleCount(showAll ? 3 : profiles.length); // Reset the visible count based on the new visibility state
  };
  const [expandedCardId, setExpandedCardId] = useState<number | null>(null);
  const toggleExpand = (cardId: number) => {
    setExpandedCardId((prevId) => prevId === cardId ? null : cardId);
  };
  const buttonText = showAll ? 'View less' : 'View more';
  const visibleProfile = profiles.slice(0, visibleCount);
  return (
    <>
      <div className="container mx-auto p-4">
        <div className='mb-5 accordion_title'>Chat with our ambassadors</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {visibleProfile.map((p, i) => (
            <div data-aos="fade-down" key={i} className="profile-card flex flex-col bg-white shadow-md rounded-lg overflow-hidden">
              <div className="cover-photo bg-gray-300 h-24"></div>
              <div className="avatar flex justify-center -mt-12">
                <div className="relative">
                  <img className="w-10 h-10 rounded-full" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1820405/profile/profile-512.jpg?1533058950" />
                  <span className="bottom-0 absolute w-6 h-6 bg-green-400 border-2 border-white rounded-full" style={{ left: '75%' }}></span>
                </div>
              </div>
              <div className="profile-details p-4 flex flex-col flex-grow">
                <h2 className="profile-name text-xl font-semibold text-center">{p.name}</h2>
                <p className="profile-bio text-center text-gray-600">{p.city}</p>
                <div className="study_info text-center text-sm text-gray-500">{p.study}</div>
                <div className="mt-4 text-gray-700 text-sm">
                  <div>{p.bio}</div>
                </div>
                <div className="mt-4 text-gray-700 text-sm">
                  <div style={{ color: "#C3892B" }}>{p.interestsAndHobbies}</div>
                </div>
                <div className="flex justify-center mt-auto">
                  <button className="ask_me_button flex items-center mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600">
                    <p className="text mr-2">Ask me a Question</p>
                    <PiWechatLogoBold className="text-lg" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end my-6">
          {(profiles.length > visibleCount || showAll) && (
            <>
              <a onClick={toggleVisibility} className="anchor_tag underline flex gap-2 items-center">See more Profiles <RiArrowRightSLine className='anchor_tag' /></a>

            </>
          )}
        </div>
        <div className='flex gap-1 items-center' style={{ background: '#EFA42D' }}>
          <div style={{width:'50%'}}>
            <img src={reviews} style={{width:'100%'}}/>
          </div>
          <div className=''>
            <AvatarGroup renderSurplus={(surplus) => <span >+{surplus.toString()[0]}k students have already done it!</span>}
              total={3000}>
              <Avatar alt="Remy Sharp" src="https://img.freepik.com/free-photo/happy-young-female-student-holding-notebooks-from-courses-smiling-camera-standing-spring-clothes-against-blue-background_1258-70161.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1715817600&semt=sph" />
              <Avatar alt="Remy Sharp" src="https://www.shutterstock.com/shutterstock/photos/2071252046/display_1500/stock-photo-portrait-of-cheerful-male-international-indian-student-with-backpack-learning-accessories-standing-2071252046.jpg" />
              <Avatar alt="Remy Sharp" src="https://t3.ftcdn.net/jpg/04/76/17/70/360_F_476177047_XHsk4GWUeHF5g8YJao1CkWAVBOFpeBZV.jpg" />
              <Avatar alt="Remy Sharp" src="https://i.pinimg.com/736x/73/b1/d2/73b1d221c14a2a6e8275da6b1299aaaf.jpg" />
              <Avatar alt="Remy Sharp" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr4qgPlntKJqPzKTS0J_3Oi6eKcQBs-mZH8WaYOqhbWA&s" />
              <Avatar alt="Remy Sharp" src="https://img.freepik.com/free-photo/happy-young-female-student-holding-notebooks-from-courses-smiling-camera-standing-spring-clothes-against-blue-background_1258-70161.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1715817600&semt=sph" />
              <Avatar alt="Remy Sharp" src="https://e7.pngegg.com/pngimages/872/991/png-clipart-toulouse-1-university-capitole-student-school-college-student-tshirt-microphone.png" />
            </AvatarGroup>
            <div className='review_banner_heading'>Write your review</div>
            <div className='review_banner_subheading'>Help other students by sharing your experience with them. Your opinion matters!</div>
            <div className='relative my-2'>
              <div className="search">
                <input type="text" className="search__input" placeholder="Type your University and write a Review" />
                <button className="search__button">
                  <svg className="search__icon" aria-hidden="true" viewBox="0 0 24 24">
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                </button>
              </div>
              <div className='flex justify-end mt-2' style={{color:'#fff',width:'75%',fontSize:'0.8rem'}}>It will only take you 3 min.</div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
