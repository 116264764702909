import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { indianColleges } from "../utils";
import { Swiper as SwiperClass } from 'swiper/types';

import "swiper/css";
import "swiper/css/bundle";
import "./TabsSlider.scss";
import EligibilityAndFeesTable from "./EligibilityAndFeesTable";

const roadmaps = ["Diploma", "UG", "PG", "PHD"]
const roadmapDiagram = <div className="w-full flex justify-center gap-0 px-5 max-md:flex-wrap">
  <div className="flex flex-col">
    <div className="flex gap-0 items-start self-end">
      <span className="w-[70px] h-[70px] bg-white rounded-full content-center  content-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          className="mx-auto"
        >
          <path
            d="M28.5875 7.71251C28.325 8.11251 21.8625 17.6375 13.7875 15.1875C8.7625 13.6625 5.3 15.2125 3.75 16.1875V1.31251C6.3 1.05001 11.1375 1.06251 15.225 3.92501C21.05 8.00001 27.5 6.30001 27.5625 6.28751C27.9375 6.17501 28.35 6.32501 28.575 6.63751C28.8 6.96251 28.8125 7.38751 28.5875 7.71251Z"
            fill="#8BC34A"
          />
          <path
            d="M3.75 1.25V28.75C3.75 29.4375 3.1875 30 2.5 30C1.8125 30 1.25 29.4375 1.25 28.75V1.25C1.25 0.5625 1.8125 0 2.5 0C3.1875 0 3.75 0.5625 3.75 1.25Z"
            fill="#607D8B"
          />
        </svg>
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="238"
        height="43"
        viewBox="0 0 238 43"
        fill="none"
      >
        <path
          d="M1 42.5L84.7857 8.06264C106.706 -0.946789 131.294 -0.946793 153.214 8.06264L237 42.5"
          stroke="#113CC0"
          stroke-dasharray="4 4"
        />
      </svg>
    </div>
    <div className="mt-4 text-base font-semibold  gray-1">
      Journalist (Print Media)
    </div>
  </div>
  <div className="flex flex-col self-start mt-4 text-base font-semibold text-center whitespace-nowrap gray-1">
    <span className="w-[70px] h-[70px] bg-white rounded-full content-center  content-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        className="mx-auto"
      >
        <path
          d="M24.375 17.1844V21.2625C24.375 22.7438 23.5031 24.0656 22.1531 24.6844C20.5125 25.4344 18.0281 26.25 15 26.25C11.9719 26.25 9.4875 25.4344 7.8375 24.6844C6.49687 24.0656 5.625 22.7438 5.625 21.2625V17.1844L12.2906 20.2313C13.1437 20.625 14.0625 20.8219 15 20.8219C15.9375 20.8219 16.8562 20.625 17.7094 20.2313L24.375 17.1844Z"
          fill="#BB6BD9"
        />
        <path
          d="M28.125 15.4688V21.5625C28.125 22.0781 27.7031 22.5 27.1875 22.5C26.6719 22.5 26.25 22.0781 26.25 21.5625V16.3313L28.125 15.4688Z"
          fill="#BB6BD9"
        />
        <path
          d="M13.0669 18.5288C13.6809 18.8091 14.34 18.9497 15 18.9497C15.66 18.9497 16.3181 18.81 16.9331 18.5288L28.9059 13.0557C29.5809 12.7472 30 12.0938 30 11.3503C30 10.6069 29.5809 9.95253 28.9059 9.64409L16.9331 4.1719C15.7041 3.61034 14.2969 3.61034 13.0678 4.1719L1.09406 9.64316C0.419063 9.95253 0 10.606 0 11.3494C0 12.0928 0.419063 12.7463 1.09406 13.0557L13.0669 18.5288Z"
          fill="#BB6BD9"
        />
      </svg>
    </span>
    <div className="mt-4">Graduation</div>
  </div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="238"
    height="43"
    viewBox="0 0 238 43"
    fill="none"
    className="mt-16"
  >
    <path
      d="M1 1L84.7857 35.4374C106.706 44.4468 131.294 44.4468 153.214 35.4374L237 1"
      stroke="#113CC0"
      stroke-dasharray="4 4"
    />
  </svg>
  <div className="flex flex-col self-start mt-4 text-base font-semibold text-center gray-1">
    <span className="w-[70px] h-[70px] bg-white rounded-full content-center  content-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        className="mx-auto"
      >
        <path
          d="M22.1239 14.11C21.9792 13.5485 21.7364 13.0169 21.4068 12.5398L19.8037 13.0179C20.3434 13.8895 20.6396 14.8898 20.6615 15.9147L22.2271 15.4459C22.2552 15.4365 22.2739 15.4225 22.2739 15.4178C22.2777 14.9774 22.2273 14.5381 22.1239 14.11Z"
          fill="#F2994A"
        />
        <path
          d="M10.4153 7.3884C13.4291 11.4381 12.7482 16.6168 19.6539 15.2161C19.5493 14.582 19.313 13.9769 18.9602 13.4397C18.8647 13.3522 18.7943 13.2409 18.7561 13.1172C18.7179 12.9935 18.7134 12.8619 18.743 12.7358C18.7726 12.6098 18.8353 12.4939 18.9245 12.4001C19.0138 12.3063 19.1264 12.238 19.2508 12.2022L21.1585 11.6351C21.3391 11.576 21.5339 11.577 21.7139 11.6378C21.8938 11.6986 22.0493 11.8161 22.1569 11.9726C22.6346 12.6549 22.9607 13.4317 23.1131 14.2506C25.4141 13.4184 26.3425 12.3399 25.6583 9.70855C25.0868 8.03482 24.227 6.47391 23.1179 5.09628C22.1381 3.8867 20.7923 4.27947 19.3774 4.52922C19.3092 4.11696 19.16 3.72229 18.9384 3.36798C18.7169 3.01367 18.4274 2.70673 18.0866 2.46488C17.7458 2.22303 17.3605 2.05104 16.953 1.95885C16.5454 1.86666 16.1236 1.85609 15.7119 1.92774C13.9094 2.22742 12.9265 3.86789 13.0918 5.59788C12.3037 5.81734 9.74442 5.82194 9.99807 6.83058C10.0168 6.88218 10.1621 7.03686 10.4153 7.3884ZM15.8994 2.84642C16.473 2.75171 17.0608 2.8857 17.5367 3.21961C18.0126 3.55352 18.3385 4.06072 18.4446 4.6323L14.0292 5.37755V5.02598C14.0305 4.49955 14.2196 3.99085 14.5624 3.59134C14.9052 3.19183 15.3793 2.92769 15.8994 2.84642Z"
          fill="#F2994A"
        />
        <path
          d="M12.6651 25.9643C12.2546 22.7512 11.9184 19.6225 11.6573 16.7256C11.5579 15.5726 11.4711 14.3329 11.3573 13.1914C11.1219 11.6537 11.2504 10.6214 10.659 9.56805C10.2822 8.83603 9.83306 8.14355 9.31834 7.50098C8.70138 9.1437 8.5605 10.5863 8.33865 12.507C8.11583 16.258 6.69875 23.5249 9.85254 26.4846C10.0355 26.6955 10.9214 27.572 12.9088 27.947C12.8352 27.3005 12.7485 26.6143 12.6651 25.9643Z"
          fill="#F2994A"
        />
        <path
          d="M27.8047 20.2973C23.2 22.1691 17.6465 23.5985 13.3584 23.9721C13.4694 24.8485 13.6273 26.1464 13.7568 27.1267C13.857 28.2639 13.7837 28.1078 14.9006 28.125C16.0884 28.1143 17.2717 27.9761 18.43 27.7126C18.6175 27.6845 23.0048 27.0095 25.5125 25.6736C27.5121 24.6257 27.9996 22.4518 27.8047 20.2973Z"
          fill="#F2994A"
        />
        <path
          d="M25.8781 20.0348C26.4845 19.8174 27.0751 19.5877 27.6686 19.3457C27.4142 18.2699 27.0773 17.2153 26.6608 16.1912L25.8687 13.7585C25.0349 14.5237 24.2002 14.8092 23.2017 15.221L23.2063 15.4412C23.2018 15.6489 23.1298 15.8494 23.0013 16.0126C22.8728 16.1758 22.6946 16.2928 22.4938 16.3459L20.722 16.8709C20.6088 16.9102 20.4878 16.9218 20.3692 16.9046C20.2506 16.8875 20.1379 16.8421 20.0405 16.7722C19.9431 16.7024 19.8639 16.6102 19.8096 16.5033C19.7552 16.3965 19.7274 16.2782 19.7283 16.1584C16.4632 16.8307 13.7163 16.07 12.2803 13.0131C12.576 16.4393 12.8217 19.3538 13.2505 23.0393C17.5684 22.5677 21.8105 21.5584 25.8781 20.0348Z"
          fill="#F2994A"
        />
        <path
          d="M7.93006 25.472C7.56362 24.6861 7.30807 23.8532 7.17072 22.9971C6.44572 22.4536 5.96273 21.6475 5.82544 20.7519C5.49266 19.0645 5.93326 17.1755 7.13321 15.1318C7.19466 14.4957 7.25805 14.0351 7.31134 13.3413C7.4279 11.8249 7.63924 10.3173 7.94411 8.82739C4.32952 10.7346 1.0888 16.9255 2.48821 21.5722C3.01775 23.6299 4.06771 25.0595 5.60046 25.8282C6.50483 26.2638 7.5041 26.4653 8.50659 26.4141C8.28326 26.1202 8.09011 25.8046 7.93006 25.472Z"
          fill="#F2994A"
        />
      </svg>
    </span>

    <div className="self-start mt-5 ml-4 max-md:ml-2.5">Class 12</div>
  </div>
</div>

export default function EducationTab() {
  const [targetSwiperInstance, setTargetSwiperInstance] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleTargetSwiper = (swiper: SwiperClass) => {
    setTargetSwiperInstance(swiper);
  };

  useEffect(() => {
    if (targetSwiperInstance) {
      const handleSlideChange = () => {
        setActiveIndex(targetSwiperInstance.activeIndex);
      };

      targetSwiperInstance.on('slideChange', handleSlideChange);

      return () => {
        targetSwiperInstance.off('slideChange', handleSlideChange);
      };
    }
  }, [targetSwiperInstance]);

  return (
    <div>
      <div className="career-roadmap-section grey-bg p-10">
        <h2 className="text-[#231F40] mb-6 text-center text-3xl font-extrabold">
          Career Roadmap
        </h2>
        <div className="flex justify-center">
          {/* tabs slider static */}
          <div className="flex gap-4 max-md:flex-wrap">
            <div className="roadmap-prev-btn flex justify-center items-center self-start p-2.5 w-11 h-11 bg-white border border-solid border-neutral-200 rounded-[50px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 11H9L12.29 7.71C12.3837 7.61703 12.4581 7.50643 12.5089 7.38457C12.5597 7.26272 12.5858 7.13201 12.5858 7C12.5858 6.86799 12.5597 6.73728 12.5089 6.61542C12.4581 6.49356 12.3837 6.38296 12.29 6.29C12.1026 6.10375 11.8492 5.99921 11.585 5.99921C11.3208 5.99921 11.0674 6.10375 10.88 6.29L6.59 10.59C6.21441 10.9633 6.00223 11.4704 6 12C6.00487 12.5261 6.21684 13.0291 6.59 13.4L10.88 17.7C10.9732 17.7926 11.0838 17.8659 11.2054 17.9157C11.3269 17.9656 11.4571 17.991 11.5885 17.9905C11.7199 17.9901 11.8499 17.9637 11.9712 17.913C12.0924 17.8623 12.2024 17.7882 12.295 17.695C12.3876 17.6017 12.4609 17.4912 12.5107 17.3696C12.5606 17.248 12.586 17.1178 12.5856 16.9864C12.5851 16.8551 12.5588 16.725 12.508 16.6038C12.4573 16.4826 12.3832 16.3726 12.29 16.28L9 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                  fill="#333333"
                />
              </svg>
            </div>
            {roadmaps.map((item, index) => <div className={`justify-center px-6 py-3 text-sm font-semibold text-center whitespace-nowrap ${index == activeIndex ? "text-white bg-blue-800" : "gray-1 bg-white"} rounded-[50px] max-md:px-5`}>
              {item}
            </div>)}
            <div className="roadmap-next-btn flex justify-center items-center self-start p-2.5 w-11 h-11 bg-white border border-solid border-neutral-200 rounded-[50px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 11H15L11.71 7.71C11.6163 7.61703 11.5419 7.50643 11.4911 7.38457C11.4403 7.26272 11.4142 7.13201 11.4142 7C11.4142 6.86799 11.4403 6.73728 11.4911 6.61542C11.5419 6.49356 11.6163 6.38296 11.71 6.29C11.8974 6.10375 12.1508 5.99921 12.415 5.99921C12.6792 5.99921 12.9326 6.10375 13.12 6.29L17.41 10.59C17.7856 10.9633 17.9978 11.4704 18 12C17.9951 12.5261 17.7832 13.0291 17.41 13.4L13.12 17.7C13.0268 17.7926 12.9162 17.8659 12.7946 17.9157C12.6731 17.9656 12.5429 17.991 12.4115 17.9905C12.2801 17.9901 12.1501 17.9637 12.0288 17.913C11.9076 17.8623 11.7976 17.7882 11.705 17.695C11.6124 17.6017 11.5391 17.4912 11.4893 17.3696C11.4394 17.248 11.414 17.1178 11.4144 16.9864C11.4149 16.8551 11.4412 16.725 11.492 16.6038C11.5427 16.4826 11.6168 16.3726 11.71 16.28L15 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </div>
          {/* tabs slider static ends */}
          {/* <TabsSlider /> */}
        </div>
        <Swiper
          onSwiper={handleTargetSwiper}
          spaceBetween={0}
          slidesPerView={1}
          navigation={{
            prevEl: ".roadmap-prev-btn",
            nextEl: ".roadmap-next-btn",
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Autoplay]}
          loop={false}
          className="h-full w-full flex flex-row justify-center items-center mt-12"
        >
          {roadmaps.map((item, index) => <SwiperSlide key={index}>{roadmapDiagram}</SwiperSlide>)}
        </Swiper>
      </div>
      <div className="eligibility-fee-section  p-10">
        <div className="container mx-auto flex gap-5">
          <div className="grid-section w-8/12">
            <h2 className="text-[#231F40] mb-6 text-center text-3xl font-extrabold">
              Eligibility & Fees
            </h2>
            <EligibilityAndFeesTable />
            {/* courses section */}
            <div className="flex flex-col w-full mt-10">
              <div className="self-center text-3xl font-extrabold text-center text-slate-800">
                Courses
              </div>
              <div className="mt-7 w-full max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow text-sm font-semibold max-md:mt-5 max-md:max-w-full">
                      <div className="flex flex-col justify-center p-6 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">
                              Courses coverd in 10 th class
                            </div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="my-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center p-6 mt-5 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">
                              Under graduation
                            </div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="my-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center p-6 mt-5 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">Doctorate</div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="my-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow text-sm font-semibold max-md:mt-5 max-md:max-w-full">
                      <div className="flex flex-col justify-center p-6 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">
                              Courses coverd in 12 th class
                            </div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="my-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center p-6 mt-5 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">Post graduation</div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="my-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center p-6 mt-5 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                        <div className="flex gap-4">
                          <img
                            loading="lazy"
                            src="/images/exams/alert.png"
                            alt=""
                            className="shrink-0 self-start aspect-square w-[92px]"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-zinc-800">
                              Skill Certification courses{" "}
                            </div>
                            <div className="mt-1 text-neutral-600">
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </div>
                            <div className="flex gap-1.5 pr-6 mt-1 text-blue-800 max-md:pr-5">
                              <div>Learn more</div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                className="m-1"
                              >
                                <path
                                  d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                  fill="#113CC0"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* courses section ends */}
            {/* exams */}
            <div className="flex flex-col px-5 max-w-[901px]">
              <div className="self-center text-3xl font-extrabold mt-10 text-center text-slate-800">
                Exams
              </div>
              <div className="flex gap-5 px-5 py-6 mt-10 w-full rounded-2xl border border-solid bg-zinc-50 border-neutral-200 max-md:flex-wrap max-md:pl-5 max-md:mt-10 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/Ellipse991.png"
                  alt=""
                  className="shrink-0 self-start w-20 rounded-full border border-solid aspect-square border-neutral-200"
                />
                <div className="flex-auto max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow max-md:mt-2.5 max-md:max-w-full">
                        <div className="text-base font-semibold text-black max-md:max-w-full">
                          JEE Main
                        </div>
                        <div className="mt-1 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                          JEE Main is a national-level entrance exam for
                          undergraduate engineering programs in India, conducted
                          by the National Testing Agency (NTA). It assesses
                          candidates' knowledge in Physics, Chemistry, and
                          Mathematics through multiple-choice questions. The
                          exam is held multiple times a year, providing
                          candidates with flexibility in choosing their exam
                          dates.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col mt-10 text-sm font-semibold text-white max-md:mt-10">
                        <a
                          href="/exam-details"
                          className="flex gap-2 justify-center px-6 py-3 blue-color button-styles rounded-[50px] max-md:px-5"
                        >
                          Take free mock test now
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                        <a
                          href="/college"
                          className="flex gap-2 justify-center px-5 py-3 mt-3 green-color button-styles  rounded-[50px]"
                        >
                          View more
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 px-5 py-6 mt-4 w-full rounded-2xl border border-solid bg-zinc-50 border-neutral-200 max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/Ellipse991.png"
                  alt=""
                  className="shrink-0 self-start w-20 rounded-full border border-solid aspect-square border-neutral-200"
                />
                <div className="flex-auto max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow max-md:mt-2.5 max-md:max-w-full">
                        <div className="text-base font-semibold text-black max-md:max-w-full">
                          JEE Main
                        </div>
                        <div className="mt-1 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                          JEE Main is a national-level entrance exam for
                          undergraduate engineering programs in India, conducted
                          by the National Testing Agency (NTA). It assesses
                          candidates' knowledge in Physics, Chemistry, and
                          Mathematics through multiple-choice questions. The
                          exam is held multiple times a year, providing
                          candidates with flexibility in choosing their exam
                          dates.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col mt-10 text-sm font-semibold text-white max-md:mt-10">
                        <a
                          href="/exam-details"
                          className="flex gap-2 justify-center px-6 py-3 blue-color button-styles rounded-[50px] max-md:px-5"
                        >
                          Take free mock test now
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                        <a
                          href="/college"
                          className="flex gap-2 justify-center px-5 py-3 mt-3 green-color button-styles rounded-[50px]"
                        >
                          View more
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 px-5 py-6 mt-4 w-full rounded-2xl border border-solid bg-zinc-50 border-neutral-200 max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/Ellipse991.png"
                  alt=""
                  className="shrink-0 self-start w-20 rounded-full border border-solid aspect-square border-neutral-200"
                />
                <div className="flex-auto max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow max-md:mt-2.5 max-md:max-w-full">
                        <div className="text-base font-semibold text-black max-md:max-w-full">
                          JEE Main
                        </div>
                        <div className="mt-1 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                          JEE Main is a national-level entrance exam for
                          undergraduate engineering programs in India, conducted
                          by the National Testing Agency (NTA). It assesses
                          candidates' knowledge in Physics, Chemistry, and
                          Mathematics through multiple-choice questions. The
                          exam is held multiple times a year, providing
                          candidates with flexibility in choosing their exam
                          dates.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col mt-10 text-sm font-semibold text-white max-md:mt-10">
                        <a
                          href="/exam-details"
                          className="flex gap-2 justify-center px-6 py-3 blue-color button-styles rounded-[50px] max-md:px-5"
                        >
                          Take free mock test now
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                        <a
                          href="/college"
                          className="flex gap-2 justify-center px-5 py-3 mt-3 green-color button-styles rounded-[50px]"
                        >
                          View more
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* exams ends */}
          </div>
          <div className="grid-section w-4/12 flex flex-col gap-4">
            <div className="rounded-lg bg-[#0fa] h-36 w-full"></div>
            <div className="rounded-lg bg-[#0af] h-36 w-full"></div>
            <div className="flex gap-4">
              <div className="rounded-lg bg-[#af0] h-36 w-full"></div>
              <div className="rounded-lg bg-[#fa0] h-36 w-full"></div>
            </div>
            <div className="rounded-lg bg-[#a0f] h-48 w-full"></div>
          </div>
        </div>

      </div>
      <section className="education-top-colleges-section flex mt-6 pb-6 justify-between">
        {/* top indian colleges section starts */}
          <div className="w-full flex flex-col items-center p-8 grey-bg relative">
            <h3 className="text-center my-6">Indian Colleges</h3>
            <Swiper
              spaceBetween={16}
              slidesPerView={6}
              navigation={{
                prevEl: ".indian-college-swiper-button-prev",
                nextEl: ".indian-college-swiper-button-next",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Autoplay]}
              loop={true}
              className="h-full w-full flex flex-row justify-center items-center"
            >
              {indianColleges.map((college) => (
                <SwiperSlide key={college.id}>
                  <div className="high-paid-card flex flex-col px-2.5 pt-2.5 pb-4 font-semibold bg-white rounded-2xl border border-solid border-neutral-200 max-w-[260px]">
                    <img
                      loading="lazy"
                      alt=""
                      src={college.image}
                      className="w-full h-[114px]  rounded-2xl "
                    />
                    <p className="blue-text rounded-3xl bg-[#113CC014] py-2 px-3 mt-2">
                      {college.pillText}
                    </p>
                    <div className="mt-3.5 text-xl text-black">
                      {college.university}
                    </div>
                    <p className="text-base text-black">{college.address}</p>
                    <p className="text-base text-[#00c798] mt-3">
                      {college.placement}
                    </p>
                    <p className="text-sm"> {college.ctc}</p>

                    <a
                      href="/college-details"
                      className="flex gap-2 green-color button-styles justify-center px-6 py-3 mt-6 text-sm text-white  rounded-[50px]"
                    >
                      Apply now
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="currentColor"
                      >
                        <path
                          d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <a href="college" className="button-styles blue-color mt-6">
              View More
            </a>
            <div className="indian-college-swiper-button-prev absolute top-8 left-8">
              <button className="carosalButtons rounded-full bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  width={20}
                  height={20}
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>{" "}
              </button>
            </div>
            <div className="indian-college-swiper-button-next absolute top-8 right-8">
              <button className="carosalButtons rounded-full bg-white flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  width={20}
                  height={20}
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
            </div>
          </div>
        {/* top indian colleges section ends */}
      </section>
    </div>
  );
}
