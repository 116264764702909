import React, { useState, useRef, useEffect } from "react";
import type { SVGProps } from "react";
import "./CustomTabsNavigationBar.scss";
import TrendIconRight from "../../images/blog-article/TrendIconRight.svg";
import TrendIconLeft from "../../images/blog-article/TrendIconLeft.svg";
import { Link } from "react-router-dom";

interface CustomTabsNavigationBarProps {
  tabs: { data: any }[];
}

const CustomTabsNavigationBar: React.FC<CustomTabsNavigationBarProps> = ({
  tabs,
}) => {
  console.log("CustomTabsNavigationBar", tabs);
  const [activeTab, setActiveTab] = useState(1);
  const [hover, setHover] = useState<number | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleClick = (id: number) => {
    setActiveTab(id);
  };

  const handleScroll = () => {
    const stickyPosition = 730;
    setIsSticky(window.pageYOffset > stickyPosition);
  };

  const scrollLeft = () => {
    menuRef.current?.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    menuRef.current?.scrollBy({ left: 200, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // list rendering

  const tabList = (tabs: any) => {
    // console.log("tabs", tabs);
    return (
      <>
        {tabs?.map((tab: any) => (
          <Link
            to={`/blogs/${tab?.topic}/${tab?.blogId || tab?.newsId}/${
              tab?.title
            }`}
            state={{
              from: "BlogDetail",
              blogDetail: tab,
            }}
            key={tab.id}
            className={`
          custom-tab  cursor-pointer`}
            onClick={() => handleClick(tab?.id)}
          >
            <button className="text-xs md:text-base">
              {tab?.trendingTopic}
            </button>
          </Link>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col justify-center">
      <div
        className={`w-full bg-white ${
          isSticky
            ? " top-0 z-20 bg-white w-full transition duration-700"
            : "relative transition duration-300"
        }`}
      >
        <div className="container relative flex items-center mx-auto">
          <button onClick={scrollLeft} className="absolute left-0">
            <img src={TrendIconLeft} />
          </button>
          <div className="flex items-center px-2 overflow-hidden 5">
            <ul
              className="flex overflow-x-auto tabs whitespace-nowrap scrollbar-hide"
              ref={menuRef}
            >
              {tabList(tabs)}
            </ul>
          </div>
          <button onClick={scrollRight} className="absolute right-0">
            <img src={TrendIconRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomTabsNavigationBar;
