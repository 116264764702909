import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import TestimonialCard from "../../components/TestimonialCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";

const Testimonial = () => {
  const studentReviewsData: {
    review: string;
    reviewBy: string;
    imageUrl: string;
  }[] = useSelector(
    (state: RootState) =>
      state.collegePredictorIntermediate.landingData?.studentReviews
  );

  if (!studentReviewsData || studentReviewsData.length <= 0) return;

  const testimonialsContent = studentReviewsData?.map((data, index) => (
    <TestimonialCard {...data} />
  ));

  return (
    <section className="py-12 bg-[#FFF6F1] text-black">
      <div className="container">
        <h1 className="text-4xl lg:text-5xl font-bold text-center">
          Students Love College Mentor Predictor Tools
        </h1>
        <CustomSwiper
          className="!px-2"
          items={testimonialsContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </section>
  );
};

export default Testimonial;
