// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.did-you-know-main {
  width: 100%;
  height: 200px;
  background: #00c798;
}
.did-you-know-main .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}
.did-you-know-main .swiper-pagination .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/did-you-know/DidYouKnow.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AACI;EACE,sBAAA;AACN;AACI;EACE,oCAAA;AACN","sourcesContent":[".did-you-know-main {\n  width: 100%;\n  height: 200px;\n  background: #00c798;\n  .swiper-pagination {\n    .swiper-pagination-bullet-active {\n      background-color: #fff;\n    }\n    .swiper-pagination-bullet {\n      background: rgba(255, 255, 255, 0.5);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
