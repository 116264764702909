import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import { FC } from "react";
import "./SuccessStories.scss";

interface ICard {
  story: string;
  userName: string;
  userEmail: string;
  userImage: string;
}

const SuccessStories = () => {
  const data = useSelector(
    (state: RootState) => state.collegeCompare.landingData?.successStories
  );

  if (!data) return;

  return (
    <div className="success-story py-12 bg-[#DAFFF7] text-black">
      <div className="container overflow-hidden">
        <h1 className="text-4xl lg:text-5xl font-bold text-center">
          Success Stories
        </h1>
        <CustomSwiper
          className="mt-4 max-md:w-[200vw] max-md:-translate-x-[27%]"
          items={data.map((item: ICard) => (
            <Card {...item} />
          ))}
          navigation={"bottom"}
          breakpoints={{
            320: { slidesPerView: 3, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 40 },
          }}
          autoplay={true}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default SuccessStories;

const Card: FC<ICard> = ({ story, userName, userEmail, userImage }) => {
  return (
    <div className="success-story-card my-4 p-4 bg-white rounded-md">
      <p className="text-xs lg:text-xl text-grey">{story}</p>
      <div className="flex items-center gap-2 mt-4 lg:mt-6">
        <img className="rounded-full w-10 lg:w-14" src={userImage} />
        <div>
          <p className="text-xs lg:text-lg ">{userName}</p>
          <p className="text-[10px] lg:text-base text-grey">{userEmail}</p>
        </div>
      </div>
    </div>
  );
};
