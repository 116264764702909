// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 321px) and (max-width: 450px) {
  .takefree-heading img {
    width: 20px;
    height: 20px;
  }
  .takefree-heading h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .takefree-btn button {
    width: 130px;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/courses/TakeAFreetest.scss"],"names":[],"mappings":"AAAA;EACE;IACE,WAAA;IACA,YAAA;EACF;EACA;IACE,eAAA;IACA,gBAAA;IACA,mBAAA;IACA,0BAAA;EACF;EACA;IACE,YAAA;IACA,eAAA;IACA,gBAAA;IACA,iBAAA;EACF;AACF","sourcesContent":["@media (min-width: 321px) and (max-width: 450px) {\n  .takefree-heading img {\n    width: 20px;\n    height: 20px;\n  }\n  .takefree-heading h3 {\n    font-size: 12px;\n    font-weight: 600;\n    line-height: normal;\n    text-transform: capitalize;\n  }\n  .takefree-btn button {\n    width: 130px;\n    font-size: 12px;\n    font-weight: 600;\n    padding: 8px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
