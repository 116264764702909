export const quickLinks = ["About us", " Contact us", "Join us"];

export const topExams = [
  " JEE Main 2024",
  "CAT 2024",
  "NEET 2024",
  " GATE 2024",
  " CMAT 2024",
  "CLAT 2024",
  "MHCET Law 2024",
  "MAT 2024",
  " SNAP 2024",
  "IIT JAM 2024",
  " LPUNEST 2024",
  " MET 2024",
  " CUET 2024",
  "SET 2024",
  "JEE Advanced 2024",
];

export const colleges = [
  "College Review",
  "Top Colleges in India",
  "Top MBA Colleges in India",
  "Top Engineering Colleges in India",
  "Top MBA Colleges in India Accepting CAT Score",
  "Top Law Colleges in India",
  "Top Colleges in Chennai",
  "Top Medical Colleges in India",
  "LPU",
  "UPES",
  "MAHE (Manipal University)",
  "SRM University Chennai",
  "Great Lakes",
];

export const predictors = [
  "CAT Percentile Predictor",
  "JEE Main College Predictor",
  "JEE Main Rank Predictor",
  "CAT College Predictor",
  "JEE Advanced College Predictor",
  "NEET College Predictor",
  "GATE College Predictor with PSU Chances",
  "CLAT College Predictor",
  "E-books & Sample Paper",
  "College Predictor",
  "NEET Rank Predictor",
  "MHCET Law College Predictor",
  "AILET College Predictor",
];

