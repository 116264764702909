import React, { useState } from "react";
import { SVGProps } from "react";
import "./AllinOneExams.scss";
import { AlsoIncludesData } from "../mock/mockData";
import { MaterialSymbolsLightClose } from "../../../components/courses/SubscribeNow";
import ApplyNowForm from "../../../components/applynow/ApplyNowForm";
import { LeadGenerationPopup } from "../../colleges-new/landing/components/WebinarAndArticles";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

const AllinOneExams = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <div className="mx-20 my-10 min-max-xss:ml-10 min-max-xs:ml-5">
      <div className="flex flex-col  bg-[#061958] all-in-one min-max-xss:w-[280px] min-max-xs:w-[385px] ">
        <div className="flex z-10 relative self-start ml-10 text-base font-bold text-white whitespace-nowrap max-md:ml-2.5 bg-gradient-to-b from-[#173CBA] to-[#00C798] rounded-md mt-[-24px]">
          <span className="px-5 py-2 rounded-lg">NEW</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="17"
            viewBox="0 0 12 17"
            fill="none"
            className="object-contain shrink-0 self-start mt-1.5 aspect-[0.69] w-[11px] absolute right-[-10px] top-[-3px]"
          >
            <path
              d="M11.5 17L0.5 0.5L1.5 17H11.5Z"
              fill="url(#paint0_linear_4_832)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_4_832"
                x1="6"
                y1="0.5"
                x2="6"
                y2="17"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#173CBA" />
                <stop offset="1" stop-color="#00C798" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex flex-col py-10 pr-4 pl-14 w-full rounded-3xl shadow-lg  max-md:pl-5 max-md:max-w-full">
          <div className="w-full max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-white max-md:mt-10 max-md:max-w-full">
                  <h3 className="self-start text-2xl font-semibold min-max-xs:">
                    College Mentor PRO:
                  </h3>
                  <p className="mt-7 text-3xl font-bold max-md:max-w-full">
                    All-in-One Pass For All Your Exams
                  </p>
                </div>
              </div>
              <button
                onClick={handlePopup}
                className="flex flex-col min-max-md:w-[276px] min-max-lg:ml-0 min-max-md:ml-[10px]  max-md:ml-0 max-md:w-full w-[531px] ml-[300px] min-max-xs:hidden min-max-xss:hidden min-max-sm:hidden"
              >
                <p className="gap-2 self-stretch px-6 py-3.5 my-auto w-full text-2xl font-semibold bg-[#F0C52B] rounded-lg min-h-[61px] text-[#061958] max-md:px-5 max-md:mt-10 text-center ">
                  Get Pass Pro
                </p>
              </button>

              <LeadGenerationPopup
                isOpen={isPopupVisible}
                onClose={() => setIsPopupVisible(!isPopupVisible)}
              />
            </div>
          </div>
          <div className="shrink-0 mt-9 max-w-full h-px bg-[#FFD540] w-full" />
          <div className="min-max-xs:flex-wrap min-max-xss:flex-wrap min-max-sm:flex-wrap min-max-xs:text-base flex gap-5 mt-9 w-full text-lg font-bold text-white max-md:mr-2.5 max-md:max-w-full justify-between min-max-xs:">
            <h3 className="">Also Includes</h3>
            <div className="flex  gap-8 !flex-wrap ">
              {AlsoIncludesData.map((item) => {
                return (
                  <div className="flex align-middle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      className="object-contain shrink-0 self-start aspect-square w-[21px]"
                    >
                      <circle cx="11" cy="10.5" r="10.5" fill="white" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.2168 6.20058C17.5661 6.49098 17.5964 6.99004 17.2846 7.31528L9.21007 15.7363C9.04921 15.9041 8.81916 16 8.57765 16C8.33613 16 8.10607 15.9041 7.94522 15.7363L4.7154 12.3679C4.40355 12.0426 4.43389 11.5436 4.78317 11.2533C5.13244 10.9628 5.66841 10.9911 5.98026 11.3163L8.57765 14.0252L16.0198 6.26368C16.3316 5.93844 16.8675 5.91019 17.2168 6.20058Z"
                        fill="#061958"
                      />
                    </svg>
                    <button className="ml-3 md:ml-4">{item}</button>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            onClick={handlePopup}
            className="flex flex-col   max-md:ml-0 max-md:w-full w-[531px] ml-[300px] md:hidden"
          >
            <p className="gap-2 self-stretch px-6 py-3.5 my-auto w-full text-2xl font-semibold bg-[#F0C52B] rounded-lg min-h-[61px] text-[#061958] max-md:px-5 max-md:mt-10 text-center ">
              Get Pass Pro
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllinOneExams;
