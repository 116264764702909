import { useState } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import "./Banner.scss";

const Banner = () => {
  const data = useSelector(
    (state: RootState) => state.collegePredictorIntermediate.landingData
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <section className="college-predictor-intermediate py-12 bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] text-black">
      <div className="container flex gap-12 max-lg:flex-col items-center">
        <div className="w-full lg:w-1/2">
          <Breadcrumbs
            links={[
              { name: "Home", path: "/" },
              {
                name: "Engineering College Predictors",
                path: "/college-predictor",
              },
              { name: "JEE Main College Predictor", path: "/" },
            ]}
          />
          <h1 className="text-5xl font-bold text-blue mt-8">
            {data?.bannerInfo?.title}
          </h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: data?.bannerInfo?.description }}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <div className="relative w-full h-0 pb-[56.25%]">
            {isPlaying ? (
              <iframe
                width="100%"
                height="100%"
                src={"https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1"}
                title="YouTube Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
              ></iframe>
            ) : (
              <button
                onClick={handlePlayVideo}
                className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
              >
                <img
                  src={
                    "/images/college/landing/building-bridges-video-thumbnail.png"
                  }
                  alt="video thumbnail"
                  className="w-full h-full object-cover rounded-lg"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
