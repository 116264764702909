import React, { useState, useEffect, useRef } from 'react';
import CarouselModule from 'react-spring-3d-carousel';
import { config } from 'react-spring';

const Carousel = CarouselModule.default || CarouselModule;

const Carroussel = (props) => {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index), key: index };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards, setCards] = useState(table);
  const [isHovered, setIsHovered] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  useEffect(() => {
    setGoToSlide(props.goToSlide);
  }, [props.goToSlide]);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        setGoToSlide((prevSlide) => (prevSlide + 1) % cards.length);
      }, 3000); // Change slide every 3 seconds
    }

    return () => clearInterval(intervalRef.current); // Cleanup interval on component unmount or hover
  }, [isHovered, cards.length]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    clearInterval(intervalRef.current);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const highlightStyle = {
    transform: 'scale(1.1)',
    transition: 'transform 0.3s',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
  };

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Carousel
        slides={cards.map((card, index) => ({
          ...card,
          content: (
            <div style={index === goToSlide ? highlightStyle : {}}>
              {card.content}
            </div>
          ),
        }))}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
};

export default Carroussel;
