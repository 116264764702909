import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchAndAddCollegeData,
  fetchLandingData,
  fetchSearchData,
} from "./collegeCompareThunk";

const COMPARE_COLS = window.innerWidth < 768 ? 2 : 4;

interface ICompareData {
  landingData: any;
  landingLoading: boolean;
  landingError: string | null;
  comparisonColleges: any[];
  searchResults: any;
}

const initialState: ICompareData = {
  landingData: null,
  landingLoading: false,
  landingError: null,
  comparisonColleges: [],
  searchResults: [],
};

const collegeCompare = createSlice({
  name: "collegeCompare",
  initialState,
  reducers: {
    removeComparisonCollege: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        comparisonColleges: state.comparisonColleges.filter(
          (college, index) => index != action.payload
        ),
      };
    },
    resetComparison: (state) => {
      return {
        ...state,
        comparisonColleges: [],
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLandingData.pending, (state) => {
        state.landingLoading = true;
        state.landingError = null;
      })
      .addCase(fetchLandingData.fulfilled, (state, action) => {
        state.landingLoading = false;
        state.landingData = action.payload;
      })
      .addCase(fetchLandingData.rejected, (state, action) => {
        state.landingLoading = false;
        state.landingError =
          action.error.message || "Failed to fetch compare data";
      })
      .addCase(fetchAndAddCollegeData.fulfilled, (state, action) => {
        const existingCompareCollegeIds = state.comparisonColleges.map(
          (college) => college.id
        );
        if (
          state.comparisonColleges.length < COMPARE_COLS &&
          action.payload &&
          !existingCompareCollegeIds.includes(action.payload.id)
        )
          state.comparisonColleges = [
            ...state.comparisonColleges,
            action.payload,
          ];
      })

      .addCase(fetchSearchData.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      });
  },
});
export default collegeCompare.reducer;

export const { removeComparisonCollege, resetComparison } =
  collegeCompare.actions;
