// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviewBy {
  color: #383838;
  text-align: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/components/TestimonialCard.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAEA,iBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAF","sourcesContent":[".reviewBy {\n  color: #383838;\n\n  text-align: right;\n  font-family: \"Open Sans\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
