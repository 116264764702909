// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1280px) {
  .topAdministator .firstslide {
    padding-top: 239px;
  }
  .topAdministator .secondslide {
    padding-top: 192px;
  }
  .topAdministator .thirdslide {
    padding-top: 145px;
  }
  .topAdministator .fourthslide {
    padding-top: 73px;
  }
}
.topAdministator {
  padding-bottom: 90px;
}

.progressBar {
  height: 3px;
  width: 100%;
  background-color: #d9d9d9;
}
.progressBar .progress-bar-inner {
  height: 100%;
  background-color: #ff66cc;
  transition: width 0.3s ease-in-out;
}

@media (max-width: 360px) {
  .paginationSec {
    width: 100%;
  }
}
.slidetrack {
  display: flex;
  width: 5040px;
  animation: scroll 20s linear infinite !important;
  animation-iteration-count: infinite;
  animation-delay: -2s;
}

.slidetrack:hover {
  animation-play-state: paused !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/exploretopadmission/ExploreTopAdmissionMentors.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,kBAAA;EAAJ;EAGE;IACE,kBAAA;EADJ;EAIE;IACE,kBAAA;EAFJ;EAKE;IACE,iBAAA;EAHJ;AACF;AAOA;EACE,oBAAA;AALF;;AAOA;EACE,WAAA;EACA,WAAA;EACA,yBAAA;AAJF;AAKE;EACE,YAAA;EACA,yBAAA;EACA,kCAAA;AAHJ;;AAMA;EACE;IACE,WAAA;EAHF;AACF;AAMA;EACE,aAAA;EACA,aAAA;EACA,gDAAA;EACA,mCAAA;EACA,oBAAA;AAJF;;AAOA;EACE,uCAAA;AAJF","sourcesContent":["@media screen and (min-width: 1280px) {\n  .topAdministator {\n    .firstslide {\n      padding-top: 239px;\n    }\n\n    .secondslide {\n      padding-top: 192px;\n    }\n\n    .thirdslide {\n      padding-top: 145px;\n    }\n\n    .fourthslide {\n      padding-top: 73px;\n    }\n  }\n}\n\n.topAdministator {\n  padding-bottom: 90px;\n}\n.progressBar {\n  height: 3px;\n  width: 100%;\n  background-color: #d9d9d9;\n  .progress-bar-inner {\n    height: 100%;\n    background-color: #ff66cc;\n    transition: width 0.3s ease-in-out;\n  }\n}\n@media (max-width: 360px) {\n  .paginationSec {\n    width: 100%;\n  }\n}\n\n.slidetrack {\n  display: flex;\n  width: calc(280px * 18);\n  animation: scroll 20s linear infinite !important;\n  animation-iteration-count: infinite;\n  animation-delay: -2s;\n}\n\n.slidetrack:hover {\n  animation-play-state: paused !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
