// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes horizontal-slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.slide-items-container:hover {
  animation-play-state: paused !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/SlideTrack.scss"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAA;EACF;EACA;IACE,2BAAA;EACF;AACF;AACA;EACE,uCAAA;AACF","sourcesContent":["@keyframes horizontal-slide {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-50%);\n  }\n}\n.slide-items-container:hover {\n  animation-play-state: paused !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
