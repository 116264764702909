import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Navigate, Outlet, RouterProps, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  // const { isAuthenticated, token, user } = useSelector(
  //   (state: RootState) => state?.auth
  // );

  const location = useLocation();

  // use it for local dev purpose incase login api fails
  const isAuthenticated = true;

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
