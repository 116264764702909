// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CareerVisualSpotBlogPopup .progress-bar {
  background-color: #f3f3f3;
}
.CareerVisualSpotBlogPopup .progress-bar.completed {
  background-color: #173cba;
}`, "",{"version":3,"sources":["webpack://./src/components/careers-comp/knowledgeHub/KnowledgeHubStyle.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".CareerVisualSpotBlogPopup {\n  .progress-bar {\n    background-color: #f3f3f3;\n  }\n  .progress-bar.completed {\n    background-color: #173cba;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
