import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function Card({ content, keyindex }) {
  const [show, setShown] = useState(false);
  const [keykey, setKeykey] = useState(0);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  return (
    <div>
      {/* {keykey} */}
      <animated.div
        onClick={() => setKeykey(keyindex)}
        className={Styles.card}
        style={props3}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        {content.map((item, index) => (
          <div key={index} className={Styles.cardItem}>
            <div className={Styles.image} /*style={{width:"92px", height:"92px"}}*/>
              <img src={item.image} alt={item.title} 
                              style={{borderRadius: "5%" }} 

              />
            </div>
            <div className={Styles.content}>
            <div style={{ fontWeight: 600, fontSize: "14px", lineHeight: "21px", marginTop: "0.5rem" }}>
                {item.title}
              </div>              <p>{item.content}</p>
              <a href={item.url} style={{ textDecoration: "none", color: "#113CC0" }}>
  Learn more
</a>
            </div>
          </div>
        ))}
      </animated.div>
    </div>
  );
}

export default Card;
