import { useEffect, useRef, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const ApplySmarter = () => {
  return (
    <div className="bg-[#ECFFFF] text-black">
      <div className="container py-12 flex flex-col lg:flex-row gap-12 items-center">
        <div className="w-full lg:w-3/5 flex flex-col justify-center">
          <h1 className="text-4xl lg:text-5xl font-bold">
            Apply Smarter, Not Harder
          </h1>
          <p className="text-base text-grey">
            We understand how confusing the process can be - that's why
            we'vemade it easier
          </p>
          <h3 className="text-xl lg:text-3xl font-bold mt-6">
            Application to Direct Admissions
            <ToolTip text="update this text!" mb={3} />
          </h3>
          <p className="text-base text-grey mt-2">
            Get Accepted with just one application. No waiting, No
            Recommendations with College Mentor Profile
          </p>
          <ul className="mt-6 text-grey text-base flex flex-col gap-4">
            <li>
              <CircledTickIcon />
              <span className="font-bold">
                Cheaper application fees
                <ToolTip text="update this text!" mb={1} />
              </span>
              - you can often get a discount when you apply with CM
            </li>
            <li>
              <CircledTickIcon />
              <span className="font-bold">Faster applications</span> - Apply to
              multiple universities with one Click
            </li>
            <li>
              <CircledTickIcon />
              <span className="font-bold">Find the best fit</span> - discover
              courses universities & Scholarships that match your goals
            </li>
            <li>
              <CircledTickIcon />
              <span className="font-bold">
                Know if you're eligible
                <ToolTip text="update this text!" mb={1} />
              </span>
              - Receive early admissions offers and scholarships from multiple
              universities with one CM application
            </li>
          </ul>
          <Link
            to="/college-admissions"
            className="mt-6 w-fit arrow-button button-styles blue-color items-center"
          >
            Know more
            <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
          </Link>
        </div>
        <div className="w-full lg:w-2/5 flex items-center justify-center">
          <img
            src="/images/college/landing/apply-smarter.png"
            alt="scholarship and admission"
          />
        </div>
      </div>
    </div>
  );
};

export default ApplySmarter;

// TODO - this icon is used in other sections as well, store these separately and reuse
const CircledTickIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block mr-2"
  >
    <path
      d="M13.6822 4.18881L12.452 5.41166C12.6466 5.98382 12.7523 6.59804 12.7523 7.23751C12.7523 10.3451 10.2544 12.8651 7.17403 12.8651C4.09368 12.8651 1.59577 10.3465 1.59577 7.23751C1.59577 4.12991 4.09368 1.60989 7.17403 1.60989C8.70586 1.60989 10.0931 2.23254 11.1009 3.24082L12.4437 2.32649C11.1329 0.8961 9.2577 0 7.17403 0C3.2124 0 0 3.24082 0 7.23751C0 11.2342 3.2124 14.475 7.17403 14.475C11.1357 14.475 14.3481 11.2342 14.3481 7.23751C14.3481 6.14789 14.109 5.11436 13.6822 4.18881Z"
      fill="#00CC99"
    />
    <path
      d="M7.17371 8.0539C9.22541 5.17769 11.8387 3.24525 14.8009 1.89619C14.9496 1.79663 15.0636 2.00277 14.9594 2.08972C12.2891 4.38255 9.83564 7.11853 7.57404 10.2486C7.34051 10.5066 6.93323 10.4884 6.72333 10.2107L5.32912 8.36101C4.44922 7.25035 3.60685 6.80861 2.80618 7.06945C2.74085 7.09329 2.69498 7.02317 2.75336 6.96428L3.36081 6.24066C4.81758 4.69388 6.47312 7.36675 7.17371 8.0539Z"
      fill="#00CC99"
    />
  </svg>
);

const ToolTipIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.497 6.125C8.497 6.50633 8.38867 7.1 8.172 7.906C7.95533 8.70333 7.847 9.22333 7.847 9.466C7.847 9.70867 7.89467 9.83 7.99 9.83C8.08533 9.83 8.25867 9.78667 8.51 9.7L8.627 9.648L8.861 10.298C8.56633 10.5233 8.198 10.7227 7.756 10.896C7.32267 11.0693 6.92833 11.156 6.573 11.156C6.21767 11.156 5.936 11.0563 5.728 10.857C5.52867 10.6577 5.429 10.3717 5.429 9.999C5.429 9.61767 5.533 9.04133 5.741 8.27C5.95767 7.49 6.066 6.996 6.066 6.788C6.066 6.46733 5.95333 6.164 5.728 5.878L5.624 5.735L5.663 5.514C6.11367 5.37533 6.989 5.306 8.289 5.306C8.42767 5.462 8.497 5.735 8.497 6.125ZM7.522 4.292C7.132 4.292 6.82433 4.188 6.599 3.98C6.38233 3.772 6.274 3.50767 6.274 3.187C6.274 2.85767 6.40833 2.58467 6.677 2.368C6.95433 2.14267 7.288 2.03 7.678 2.03C8.068 2.03 8.37133 2.121 8.588 2.303C8.80467 2.485 8.913 2.74067 8.913 3.07C8.913 3.39933 8.77867 3.68533 8.51 3.928C8.25 4.17067 7.92067 4.292 7.522 4.292Z"
      fill="#383838"
    />
    <circle cx="7" cy="7" r="6.5" stroke="#383838" />
  </svg>
);

const ToolTip = ({ text, mb }: { text: string; mb: number }) => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (tooltipRef?.current) {
      const buttonRight = tooltipRef.current.getBoundingClientRect().right;
      const screenWidth = window.innerWidth;

      if (buttonRight > screenWidth) setOverflow(true);
      else setOverflow(false);
    }
  }, [tooltipRef]);

  return (
    <div className={`inline-block relative group mb-${mb}`}>
      <ToolTipIcon />
      <span
        ref={tooltipRef}
        className={`absolute opacity-0 group-hover:opacity-100 w-max px-2 py-1 text-sm text-grey font-normal bg-white border border-grey rounded top-0 left-1/2 -translate-y-[110%] ${
          overflow ? "-translate-x-full" : ""
        }`}
      >
        {text}
      </span>
    </div>
  );
};
