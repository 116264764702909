// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  width: 1360px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer-container {
    width: 100%;
    margin: 0 auto;
  }
}
.quick-links {
  text-align: left;
}
.quick-links li {
  list-style: disc;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;AACF;;AACA;EACE;IACE,WAAA;IACA,cAAA;EAEF;AACF;AAAA;EACE,gBAAA;AAEF;AADE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAGJ","sourcesContent":[".footer-container {\n  width: 1360px;\n  margin: 0 auto;\n}\n@media (max-width: 768px) {\n  .footer-container {\n    width: 100%;\n    margin: 0 auto;\n  }\n}\n.quick-links {\n  text-align: left;\n  li {\n    list-style: disc;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
