const captilizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const ReponsiveDeviceList: any = {
  Mobile: "Mobile",
  Desktop: "Desktop",
};

const generateListofItems = (
  list: [],
  type = "Desktop",
  countsToShow: number
) => {
  return list.slice(0, countsToShow);
};

export { captilizeFirstLetter, generateListofItems };
