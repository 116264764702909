import React from "react";
import PlacementsTabContent from "./placements/OverviewSection";

export const PlacementsTab = ({}) => {
  return (
    <div>
      <PlacementsTabContent />
    </div>
  );
};
