import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import Modal from "../../components/Modal";
import { NavLink } from "react-router-dom";

interface IProps {
  navLinks: { name: string; icon: ReactNode; link: string }[];
}

const MobileHeader: FC<IProps> = ({ navLinks }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <div className="px-8 py-4 flex items-center justify-between">
        <button onClick={() => setExpanded((prev) => !prev)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <button onClick={() => setExpanded((prev) => !prev)}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <Modal isOpen={expanded} onClose={() => setExpanded(false)}>
        <div className="pt-12 p-4 bg-white h-screen w-screen flex flex-col">
          {navLinks.map((item) => (
            <NavLink
              to={item.link}
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                `p-4 ${
                  isActive ? "bg-[#e6e6e6] text-black font-bold" : ""
                } rounded-lg flex gap-4`
              }
            >
              {item.icon}
              <div className="w-full overflow-hidden">{item.name}</div>
            </NavLink>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default MobileHeader;
