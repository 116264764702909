import { useEffect, useState } from 'react'
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SlBadge } from "react-icons/sl";
import { LuClock } from "react-icons/lu";
import { FaCalendarDay } from "react-icons/fa6";
import { LiaUserClockSolid } from "react-icons/lia";
import moment from 'moment'
import { Divider } from '@mui/material';
interface Mentors {
  service: string;
  description: string;
  percentages: {
    chat: number;
    call: number;
    appointment: number;
  };
  banner_img: string;
  profile_img: string;
  university_name: string;
  university_description: string;
  study: string;
  full_partTime: string;
  
}
interface AllRoundMentors {
  service: string;
  description: string;
  percentages: {
    chat: number;
    call: number;
    appointment: number;
  };
  banner_img: string;
  profile_img: string;
  university_name: string;
  university_description: string;
  study: string;
  full_partTime: string;
  name:string;
  profession:string;
}
const studentMentors: Mentors[] = [
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'Stanford University',
    university_description: 'Stanford University’s mentoring programs are designed to support students academically, personally, and professionally. The UAR program offers a variety of mentoring opportunities, including peer mentors, faculty advisors, and career mentors.',
    banner_img: 'https://www.thebalancemoney.com/thmb/A_uhaT0yyVle46Bfki8domRJ9Mo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/colleagues-business-woman-working-978531556-b538d8fc0ddc492db50b9189235b2bc8.jpg',
    profile_img: 'https://www.careerguide.com/career/wp-content/uploads/2023/07/DELHI-TECHNOLOGICAL-UNIVERSITY.webp',
    service: "Course Selection",
    description: "Helps mentees choose courses that align with their academic goals and career aspirations.",
    percentages: {
      chat: 20,
      call: 75,
      appointment: 45
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Part-Time',
    university_name: 'Harvard University',
    university_description: 'The Peer Advising Fellows (PAF) program at Harvard College pairs first-year students with upperclassmen who provide guidance and support throughout their first year. PAFs help new students navigate academic and social challenges and connect them with resources across campus',
    banner_img: 'https://prowisdom.in/sites/default/files/learn/indus-prof.jpg',
    profile_img: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,g_xy_center,h_659,q_75,w_640,x_515,y_290/v1/clients/milledgeville/20230731VisitMilledgevilleHistoricDay_38_40abded5-5541-4d16-ab67-8631611fb77e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 18,
      call: 65,
      appointment: 95
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'University of Michigan',
    university_description: 'The University of Michigan offers a comprehensive mentorship program that includes peer mentoring, academic advising, and professional mentoring. The program aims to support students in their academic journeys, personal development, and career preparation.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShikLEoW1O9HxIrG59jYVHoNZ7sXU-pjbUWw&s',
    profile_img: 'https://media.getmyuni.com/assets/images/articles/cprrltn17g-6n2fegei7-psoa0u-o-la6blo-o43c2e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 64,
      call: 49,
      appointment: 75
    }
  }
];
const CarrerMentors: Mentors[] = [
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'Stanford University',
    university_description: 'Stanford University’s mentoring programs are designed to support students academically, personally, and professionally. The UAR program offers a variety of mentoring opportunities, including peer mentors, faculty advisors, and career mentors.',
    banner_img: 'https://www.impactfactory.com/wp-content/uploads/2022/05/business-mentor-talking-to-employee-2022-02-02-18-39-51-utc-scaled.jpg',
    profile_img: 'https://www.careerguide.com/career/wp-content/uploads/2023/07/DELHI-TECHNOLOGICAL-UNIVERSITY.webp',
    service: "Course Selection",
    description: "Helps mentees choose courses that align with their academic goals and career aspirations.",
    percentages: {
      chat: 20,
      call: 75,
      appointment: 45
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Part-Time',
    university_name: 'Harvard University',
    university_description: 'The Peer Advising Fellows (PAF) program at Harvard College pairs first-year students with upperclassmen who provide guidance and support throughout their first year. PAFs help new students navigate academic and social challenges and connect them with resources across campus',
    banner_img: 'https://media.licdn.com/dms/image/C511BAQG3i-iJ_iwLIA/company-background_10000/0/1583928997132?e=2147483647&v=beta&t=fj3clOKNxZysJNq_EJvnyGS5yDAHnkx5ywsG8BlbFMw',
    profile_img: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,g_xy_center,h_659,q_75,w_640,x_515,y_290/v1/clients/milledgeville/20230731VisitMilledgevilleHistoricDay_38_40abded5-5541-4d16-ab67-8631611fb77e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 18,
      call: 65,
      appointment: 95
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'University of Michigan',
    university_description: 'The University of Michigan offers a comprehensive mentorship program that includes peer mentoring, academic advising, and professional mentoring. The program aims to support students in their academic journeys, personal development, and career preparation.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJlkiSmdqn6jzEhLBpfgDGGrALRfzh3J5cQSk6u_cydyouzxv8hX-Q9ORErG9JlklUqhs&usqp=CAU',
    profile_img: 'https://media.getmyuni.com/assets/images/articles/cprrltn17g-6n2fegei7-psoa0u-o-la6blo-o43c2e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 64,
      call: 49,
      appointment: 75
    }
  }
];
const HealthMentors: Mentors[] = [
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'Stanford University',
    university_description: 'Stanford University’s mentoring programs are designed to support students academically, personally, and professionally. The UAR program offers a variety of mentoring opportunities, including peer mentors, faculty advisors, and career mentors.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbqJr1KCuS4QSOg7y6DUjGsi75qONtJNVZog&s',
    profile_img: 'https://www.careerguide.com/career/wp-content/uploads/2023/07/DELHI-TECHNOLOGICAL-UNIVERSITY.webp',
    service: "Course Selection",
    description: "Helps mentees choose courses that align with their academic goals and career aspirations.",
    percentages: {
      chat: 20,
      call: 75,
      appointment: 45
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Part-Time',
    university_name: 'Harvard University',
    university_description: 'The Peer Advising Fellows (PAF) program at Harvard College pairs first-year students with upperclassmen who provide guidance and support throughout their first year. PAFs help new students navigate academic and social challenges and connect them with resources across campus',
    banner_img: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://artofmentoring.net/wp-content/uploads/2023/05/AdobeStock_576596676-2-1568x1046.jpg',
    profile_img: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,g_xy_center,h_659,q_75,w_640,x_515,y_290/v1/clients/milledgeville/20230731VisitMilledgevilleHistoricDay_38_40abded5-5541-4d16-ab67-8631611fb77e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 18,
      call: 65,
      appointment: 95
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'University of Michigan',
    university_description: 'The University of Michigan offers a comprehensive mentorship program that includes peer mentoring, academic advising, and professional mentoring. The program aims to support students in their academic journeys, personal development, and career preparation.',
    banner_img: 'https://assets.contenthub.wolterskluwer.com/api/public/content/b19108e6818f476cb963c8692854995b?v=03f297bc',
    profile_img: 'https://media.getmyuni.com/assets/images/articles/cprrltn17g-6n2fegei7-psoa0u-o-la6blo-o43c2e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 64,
      call: 49,
      appointment: 75
    }
  }
];
const AllRoundMentors: AllRoundMentors[] = [
  {
    profession:"Visual Designer",
    name:'David Brance',
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'Stanford University',
    university_description: 'Stanford University’s mentoring programs are designed to support students academically, personally, and professionally. The UAR program offers a variety of mentoring opportunities, including peer mentors, faculty advisors, and career mentors.',
    banner_img: 'https://www.thebalancemoney.com/thmb/A_uhaT0yyVle46Bfki8domRJ9Mo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/colleagues-business-woman-working-978531556-b538d8fc0ddc492db50b9189235b2bc8.jpg',
    profile_img: 'https://www.parangat.com/wp-content/uploads/2023/09/dummy-profile.png',
    service: "Course Selection",
    description: "Helps mentees choose courses that align with their academic goals and career aspirations.",
    percentages: {
      chat: 20,
      call: 75,
      appointment: 45
    }
  },
  {
    profession:"Software Developer",
    name:'Browsie',
    study: 'BA (Hons)',
    full_partTime: 'Part-Time',
    university_name: 'Harvard University',
    university_description: 'The Peer Advising Fellows (PAF) program at Harvard College pairs first-year students with upperclassmen who provide guidance and support throughout their first year. PAFs help new students navigate academic and social challenges and connect them with resources across campus',
    banner_img: 'https://prowisdom.in/sites/default/files/learn/indus-prof.jpg',
    profile_img: 'https://cnmi.spmi.pt/wp-content/uploads/2014/10/speaker-3.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 18,
      call: 65,
      appointment: 95
    }
  },
  {
    profession:"Graphics Designer",
    name:'Jhon',
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'University of Michigan',
    university_description: 'The University of Michigan offers a comprehensive mentorship program that includes peer mentoring, academic advising, and professional mentoring. The program aims to support students in their academic journeys, personal development, and career preparation.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShikLEoW1O9HxIrG59jYVHoNZ7sXU-pjbUWw&s',
    profile_img: 'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 64,
      call: 49,
      appointment: 75
    }
  }
];
const BecomeMentors: Mentors[] = [
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'Stanford University',
    university_description: 'Stanford University’s mentoring programs are designed to support students academically, personally, and professionally. The UAR program offers a variety of mentoring opportunities, including peer mentors, faculty advisors, and career mentors.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjCtlnEB326MT0Toew7gE4H3iZMPE3P8gW_w&s',
    profile_img: 'https://www.careerguide.com/career/wp-content/uploads/2023/07/DELHI-TECHNOLOGICAL-UNIVERSITY.webp',
    service: "Course Selection",
    description: "Helps mentees choose courses that align with their academic goals and career aspirations.",
    percentages: {
      chat: 20,
      call: 75,
      appointment: 45
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Part-Time',
    university_name: 'Harvard University',
    university_description: 'The Peer Advising Fellows (PAF) program at Harvard College pairs first-year students with upperclassmen who provide guidance and support throughout their first year. PAFs help new students navigate academic and social challenges and connect them with resources across campus',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX_Ix1D3OJhBL7eEzMSITcDo1aTbS4dfNxvw&s',
    profile_img: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,g_xy_center,h_659,q_75,w_640,x_515,y_290/v1/clients/milledgeville/20230731VisitMilledgevilleHistoricDay_38_40abded5-5541-4d16-ab67-8631611fb77e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 18,
      call: 65,
      appointment: 95
    }
  },
  {
    study: 'BA (Hons)',
    full_partTime: 'Full-Time',
    university_name: 'University of Michigan',
    university_description: 'The University of Michigan offers a comprehensive mentorship program that includes peer mentoring, academic advising, and professional mentoring. The program aims to support students in their academic journeys, personal development, and career preparation.',
    banner_img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLucWsiIihmzMq-E4hPBIaOrjFP5wX4NOrPg&s',
    profile_img: 'https://media.getmyuni.com/assets/images/articles/cprrltn17g-6n2fegei7-psoa0u-o-la6blo-o43c2e.jpg',
    service: "Study Strategies",
    description: "Offers advice on effective study habits, time management, and exam preparation.",
    percentages: {
      chat: 64,
      call: 49,
      appointment: 75
    }
  }
];
const ConnectWithDreams = () => {
  const [activeTab, setActiveTab] = useState<string>("StudentMentors");
  const [percentage, setPercentage] = useState({
    chat: 0,
    call: 0,
    appointment: 0
  });
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };
  useEffect(() => {
    setPercentage({
      chat: 20,
      call: 75,
      appointment: 45
    });
  });
  const tabContent: { [key: string]: JSX.Element } = {
    StudentMentors: (
      <>
        <div className='flex justify-evenly my-5'>
          {studentMentors.map((service, i) => {
            return (
              <div className="dream_mentorCard relative">
                <img src={service.banner_img} style={{ height: '30vh' }} />
                <div>
                </div>
                <div className="banner">
                  <img src={service.profile_img} />
                </div>
                <div className="menu">
                  <div className="opener">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="heart-container" title="Like">
                  <input type="checkbox" className="checkbox" id="Give-It-An-Id" />
                  <div className="svg-container">
                    <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                      </path>
                    </svg>
                    <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                      </path>
                    </svg>
                    <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,10 20,20"></polygon>
                      <polygon points="10,50 20,50"></polygon>
                      <polygon points="20,80 30,70"></polygon>
                      <polygon points="90,10 80,20"></polygon>
                      <polygon points="90,50 80,50"></polygon>
                      <polygon points="80,80 70,70"></polygon>
                    </svg>
                  </div>
                </div>
                <div className='univesity_name px-5'>{service.university_name}</div>
                <div className=' univesity_description px-5' ><b>Description</b> : {service.university_description}</div>
                <div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><SlBadge />{service.study}</div>
                    <div className='flex gap-1 items-center'><LuClock />{service.study}</div>
                  </div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><LiaUserClockSolid />{service.full_partTime}</div>
                    <div className='flex gap-1 items-center'><FaCalendarDay />{`${moment().format('YYYY')} - ${moment().add(1, 'year').format('YYYY')}`}        </div>
                  </div>
                </div>
                <div className='py-12 flex justify-center gap-5'>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#4E61A8",
                        trailColor: "#a9afc7"
                      })} value={service.percentages.chat} text={`${service.percentages.chat}%`} />
                    <div className='proggessBarHeading'>Chat</div>
                  </div>
                  <div style={{ height: '100px', width: '100px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#FBAF17",
                        trailColor: "#ebe4d5"
                      })} value={service.percentages.call} text={`${service.percentages.call}%`} />
                    <div className='proggessBarHeading'>Call</div>
                  </div>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#fe0063",
                        trailColor: "#ffd9e6"
                      })} value={service.percentages.appointment} text={`${service.percentages.appointment}%`} />
                    <div className='proggessBarHeading'>Appointment</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    ),
    CareerMentors: (
      <>
        <div className='flex justify-evenly my-5'>
          {CarrerMentors.map((service, i) => {
            return (
              <div className="dream_mentorCard relative">
                <img src={service.banner_img} style={{ height: '30vh' }} />
                <div>
                </div>
                <div className="banner">
                  <img src={service.profile_img} />
                </div>
                <div className="menu">
                  <div className="opener">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="heart-container" title="Like">
                  <input type="checkbox" className="checkbox" id="Give-It-An-Id" />
                  <div className="svg-container">
                    <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                      </path>
                    </svg>
                    <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                      </path>
                    </svg>
                    <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,10 20,20"></polygon>
                      <polygon points="10,50 20,50"></polygon>
                      <polygon points="20,80 30,70"></polygon>
                      <polygon points="90,10 80,20"></polygon>
                      <polygon points="90,50 80,50"></polygon>
                      <polygon points="80,80 70,70"></polygon>
                    </svg>
                  </div>
                </div>
                <div className='univesity_name px-5'>{service.university_name}</div>
                <div className=' univesity_description px-5' ><b>Description</b> : {service.university_description}</div>
                <div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><SlBadge />{service.study}</div>
                    <div className='flex gap-1 items-center'><LuClock />{service.study}</div>
                  </div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><LiaUserClockSolid />{service.full_partTime}</div>
                    <div className='flex gap-1 items-center'><FaCalendarDay />{`${moment().format('YYYY')} - ${moment().add(1, 'year').format('YYYY')}`}        </div>
                  </div>
                </div>
                <div className='py-12 flex justify-center gap-5'>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#4E61A8",
                        trailColor: "#a9afc7"
                      })} value={service.percentages.chat} text={`${service.percentages.chat}%`} />
                    <div className='proggessBarHeading'>Chat</div>
                  </div>
                  <div style={{ height: '100px', width: '100px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#FBAF17",
                        trailColor: "#ebe4d5"
                      })} value={service.percentages.call} text={`${service.percentages.call}%`} />
                    <div className='proggessBarHeading'>Call</div>
                  </div>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#fe0063",
                        trailColor: "#ffd9e6"
                      })} value={service.percentages.appointment} text={`${service.percentages.appointment}%`} />
                    <div className='proggessBarHeading'>Appointment</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    ),
    HealthMentors: (
      <>
        <div className='flex justify-evenly my-5'>
          {HealthMentors.map((service, i) => {
            return (
              <div className="dream_mentorCard relative">
                <img src={service.banner_img} style={{ height: '30vh' }} />
                <div>
                </div>
                <div className="banner">
                  <img src={service.profile_img} />
                </div>
                <div className="menu">
                  <div className="opener">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="heart-container" title="Like">
                  <input type="checkbox" className="checkbox" id="Give-It-An-Id" />
                  <div className="svg-container">
                    <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                      </path>
                    </svg>
                    <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                      </path>
                    </svg>
                    <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,10 20,20"></polygon>
                      <polygon points="10,50 20,50"></polygon>
                      <polygon points="20,80 30,70"></polygon>
                      <polygon points="90,10 80,20"></polygon>
                      <polygon points="90,50 80,50"></polygon>
                      <polygon points="80,80 70,70"></polygon>
                    </svg>
                  </div>
                </div>
                <div className='univesity_name px-5'>{service.university_name}</div>
                <div className=' univesity_description px-5' ><b>Description</b> : {service.university_description}</div>
                <div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><SlBadge />{service.study}</div>
                    <div className='flex gap-1 items-center'><LuClock />{service.study}</div>
                  </div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><LiaUserClockSolid />{service.full_partTime}</div>
                    <div className='flex gap-1 items-center'><FaCalendarDay />{`${moment().format('YYYY')} - ${moment().add(1, 'year').format('YYYY')}`}        </div>
                  </div>
                </div>
                <div className='py-12 flex justify-center gap-5'>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#4E61A8",
                        trailColor: "#a9afc7"
                      })} value={service.percentages.chat} text={`${service.percentages.chat}%`} />
                    <div className='proggessBarHeading'>Chat</div>
                  </div>
                  <div style={{ height: '100px', width: '100px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#FBAF17",
                        trailColor: "#ebe4d5"
                      })} value={service.percentages.call} text={`${service.percentages.call}%`} />
                    <div className='proggessBarHeading'>Call</div>
                  </div>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#fe0063",
                        trailColor: "#ffd9e6"
                      })} value={service.percentages.appointment} text={`${service.percentages.appointment}%`} />
                    <div className='proggessBarHeading'>Appointment</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    ),
    AllRoundMentors: (
      <>
        <div className='flex justify-evenly my-5'>
          {AllRoundMentors.map((service, i) => {
            return (
              <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="flex justify-end px-4 pt-4">
                  <button id="dropdownButton" data-dropdown-toggle="dropdown" className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                    <span className="sr-only">Open dropdown</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                      <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>
                  </button>
                  <div id="dropdown" className="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2" aria-labelledby="dropdownButton">
                      <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex  justify-evenly items-center pb-10">
                  <div>
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{service.name}</h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">{service.profession}</span>
                  </div>
                  <div>
                    <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={service.profile_img} />
                  </div>
                </div>
                <div className='flex px-5 py-3  justify-between rounded m-5' style={{ background: '#F3F4F6' }} >
                  <div className='card_left'>MentorShip</div>
                  <div className='card_right'>$150/month</div>
                </div>
                <Divider variant="middle" />
                <div className='flex px-5 py-3  justify-between rounded m-5' style={{ background: '#F3F4F6' }} >
                  <div className='card_left'>Introsession</div>
                  <div className='card_right'>$39</div>
                </div>
                <div className='flex px-5 py-3  justify-between rounded m-5' style={{ background: '#F3F4F6' }} >
                  <div className='card_left'>CV Review</div>
                  <div className='card_right'>$69</div>
                </div>
                <div className='flex px-5 py-3  justify-between rounded m-5' style={{ background: '#F3F4F6' }} >
                  <div className='card_left'>Launch Plan</div>
                  <div className='card_right'>$129</div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    ),
    BecomeMentors: (
      <>
        <div className='flex justify-evenly my-5'>
          {BecomeMentors.map((service, i) => {
            return (
              <div className="dream_mentorCard relative">
                <img src={service.banner_img} style={{ height: '30vh' }} />
                <div>
                </div>
                <div className="banner">
                  <img src={service.profile_img} />
                </div>
                <div className="menu">
                  <div className="opener">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="heart-container" title="Like">
                  <input type="checkbox" className="checkbox" id="Give-It-An-Id" />
                  <div className="svg-container">
                    <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                      </path>
                    </svg>
                    <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                      </path>
                    </svg>
                    <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,10 20,20"></polygon>
                      <polygon points="10,50 20,50"></polygon>
                      <polygon points="20,80 30,70"></polygon>
                      <polygon points="90,10 80,20"></polygon>
                      <polygon points="90,50 80,50"></polygon>
                      <polygon points="80,80 70,70"></polygon>
                    </svg>
                  </div>
                </div>
                <div className='univesity_name px-5'>{service.university_name}</div>
                <div className=' univesity_description px-5' ><b>Description</b> : {service.university_description}</div>
                <div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><SlBadge />{service.study}</div>
                    <div className='flex gap-1 items-center'><LuClock />{service.study}</div>
                  </div>
                  <div className='flex justify-evenly'>
                    <div className='flex gap-1 items-center'><LiaUserClockSolid />{service.full_partTime}</div>
                    <div className='flex gap-1 items-center'><FaCalendarDay />{`${moment().format('YYYY')} - ${moment().add(1, 'year').format('YYYY')}`}        </div>
                  </div>
                </div>
                <div className='py-12 flex justify-center gap-5'>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#4E61A8",
                        trailColor: "#a9afc7"
                      })} value={service.percentages.chat} text={`${service.percentages.chat}%`} />
                    <div className='proggessBarHeading'>Chat</div>
                  </div>
                  <div style={{ height: '100px', width: '100px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#FBAF17",
                        trailColor: "#ebe4d5"
                      })} value={service.percentages.call} text={`${service.percentages.call}%`} />
                    <div className='proggessBarHeading'>Call</div>
                  </div>
                  <div style={{ height: '80px', width: '80px' }}>
                    <CircularProgressbar strokeWidth={8}
                      styles={buildStyles({
                        textSize: "16px",
                        pathColor: "#fe0063",
                        trailColor: "#ffd9e6"
                      })} value={service.percentages.appointment} text={`${service.percentages.appointment}%`} />
                    <div className='proggessBarHeading'>Appointment</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    ),
  };
  return (
    <>
    <div className='connectWithDreams my-5'>Connect with the Dream Mentors</div>
      <div className="flex justify-center gap-4 mt-8 text-sm font-semibold text-center text-zinc-800 max-md:flex-wrap">
        <button
          className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${activeTab === "StudentMentors" ? "active_tab" : ""
            }`}
          onClick={() => handleTabChange("StudentMentors")}
        >
          Student Mentors
        </button>
        <button
          className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${activeTab === "CareerMentors" ? "active_tab" : ""
            }`}
          onClick={() => handleTabChange("CareerMentors")}
        >
          Career Mentors
        </button>
        <button
          className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${activeTab === "HealthMentors" ? "active_tab" : ""
            }`}
          onClick={() => handleTabChange("HealthMentors")}
        >
          Health Mentors
        </button>
        <button
          className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${activeTab === "AllRoundMentors" ? "active_tab" : ""
            }`}
          onClick={() => handleTabChange("AllRoundMentors")}
        >
          All Round Mentors
        </button>
        <button
          className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${activeTab === "BecomeMentors" ? "active_tab" : ""
            }`}
          onClick={() => handleTabChange("BecomeMentors")}
        >
          Become mentors
        </button>
      </div>
      <div className="self-stretch mt-8 w-full max-md:max-w-full">
        {tabContent[activeTab]}
      </div>
    </>
  )
}
export default ConnectWithDreams