import "./PredictorTools.scss";
import CustomNavigation from "./CustomNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

    const payloadFormatter = (data: any) => {
        return Object.keys(data).map((key, index) => {
            return {
            title: key,
            id: index,
          };
        });
      };

const PredictorTools =()=>{
    const ToolsData = useSelector(
        (state: RootState) => state.predictorLanding.predictedExams
      );
      if (!ToolsData || ToolsData.streams.length === 0) {
        return null; 
      }
      const streams = ToolsData?.streams ?? [];

return(
<>
<div className="w-full flex  bg-[#FFF1FA] relative mb-7">

<div className="container overflow-hidden">
<div className="heading-tools py-4 lg:px-36">Discover your Ideal Exam & Course with our
Advanced College Predictor and Rank Predictor Tools</div>
<CustomNavigation tabs={streams} data={ToolsData}/>
</div>
</div>
</>
)


}

export default PredictorTools;