import React, { useState, useEffect } from "react";
import TitleCard from "../../components/TitleCard";

import StudyAbroadImage from "../../../../images/blog-article/study-abroad-image.png";
import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import { VisualStoriesCard } from "../../components/VisualStoriesCard";
import VisualStory from "../../../../components/VisualStory";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import VisualStoryCard from "../../../../components/blogs-and-articles/VisualStoryCard-new";
interface WebAndVisualStoriesProps {
  webAndVisualStoriesResponse: any;
}

const WebAndVisualStories: React.FC<WebAndVisualStoriesProps> = ({
  webAndVisualStoriesResponse,
}) => {
  const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);
  const [isOpenVisualStory, setIsOpenVisualStory] = useState(false);
  // const isVisualStoryOpen =

  console.log("webAndVisualStoriesResponse", webAndVisualStoriesResponse);

  const getVisualStorySlide = () => {
    let slides = [];
    console.log("Helloooo-visualStoryIndex", visualStoryIndex);
    console.log(
      "Helloooo",

      webAndVisualStoriesResponse[0]?.["Web And VisualStories"]?.subcategories[
        "Articles & Blogs"
      ]?.results[visualStoryIndex]
    );
  };

  const getVisualStorySlides = (
    slides: {
      title: string;
      description: string;
      image: string;
    }[]
  ) => {
    return slides.map((slide) => ({
      image: slide.image,
      content: (
        <div className="p-4">
          <h1 className="text-xl font-bold text-center text-white">
            {slide.title}
          </h1>
          <p className="mt-2 text-base text-justify text-white">
            {slide.description}
          </p>
        </div>
      ),
    }));
  };
  // useEffect(() => {
  //   getVisualStorySlides();
  // }, [visualStoryIndex]);
  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard
            title="Web and Visual Stories"
            bgColor="#173CBA"
            color="#ffffff"
          />
        </div>

        <div>
          {/* {[
            StudyAbroadImage,
            ExamBlogsImage1,
            ExamBlogsImage2,
            ExamBlogsImage3,
          ].map((item, index) => (
            <>
              <VisualStoriesCard
                title="Tset"
                description="Hello"
                image={item}
                openVisualStory={() => setIsOpenVisualStory(true)}
              />
            </>
          ))} */}
          <CustomSwiper
            className="!p-2 h-full"
            items={webAndVisualStoriesResponse[0]?.[
              "Web And VisualStories"
            ]?.subcategories["Articles & Blogs"]?.results?.map(
              (item: any, index: any) => (
                <>
                  <VisualStoriesCard
                    title={item?.title}
                    description={item?.description}
                    image={item?.imageUrl}
                    category={item?.category}
                    date={item?.date}
                    openVisualStory={() => {
                      setIsOpenVisualStory(true);
                      setVisualStoryIndex(index);
                    }}
                  />
                </>
              )
            )}
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1280: { slidesPerView: 4, spaceBetween: 30 },
            }}
            progressBar={true}
          />
        </div>
      </div>

      <VisualStory
        isOpen={isOpenVisualStory}
        slides={
          (isOpenVisualStory &&
            getVisualStorySlides(
              webAndVisualStoriesResponse[0]?.["Web And VisualStories"]
                ?.subcategories["Articles & Blogs"]?.results[visualStoryIndex]
                ?.sliders
            )) ||
          []
          // webAndVisualStoriesResponse[0]?.["Web And VisualStories"]?.subcategories[
          //   "Articles & Blogs"
          // ]?.results[visualStoryIndex]?.sliders
        }
        handleClose={() => {
          setIsOpenVisualStory(false);
        }}
      />
    </>
  );
};

export default WebAndVisualStories;
