import { title } from "process";
import React from "react";

const resultsCounselling = [
  {
    title: "SRM University Chennai Result 2024",
    description:
      "SRM Institute of Science & Technology (SRM IST) formerly known as SRM University Chennai publishes the results of the End-Term examinations and results for the entrance examination (SRMJEEE) on the official website. Students can log in using their credentials to check their results on the university’s website. SRM Institute of Science & Technology offers multiferous courses at graduate, postgraduate and doctoral levels. Only students with already created Academic Bank of Credits (ABC) accounts can view their results on the portal. ",
  },
  {
    subhead:
      "Candidates can view their semester results & scorecard by following the given procedure:",
    option: [
      "The counselling process for SRM IST is conducted in two phases.",
      "The first phase of counselling is for the students who have secured ranks in the SRMJEEE.",
      "The second phase of counselling is for the students who have secured ranks in the JEE Main examination.",
      "The counselling process is conducted in offline mode.",
      "The counselling process is conducted at the university campus.",
      "The counselling process is conducted in multiple rounds.",
      "The counselling process is conducted for the students who have qualified the entrance examination.",
      "The counselling process is conducted for the students who have secured ranks in the entrance examination.",
      "The counselling process is conducted for the students who have secured ranks in the entrance examination.",
      "The counselling process is conducted for the students who have secured ranks in the entrance examination.",
    ],
    description:
      "Students looking forward to their results must keep in mind that the results displayed on the website are only to provide immediate information to the students. To receive accurate information about the result, students are advised to contact the respective department of SRM University.",
  },
];

const reCounselling = () => {
  return (
    <div className="flex flex-col py-16 bg-[#F4F7FB]" id="Results-counselling">
      <div className="container mx-auto">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <h3 className="text-3xl font-extrabold text-[#231F40] text-center">
              Results & Counselling
            </h3>
          </div>
          <div className="flex flex-col gap-4">
            {resultsCounselling.map((item, index) => (
              <div key={index} className="flex flex-col gap-4">
                {item.title && (
                  <h3 className="text-2xl font-semibold text-[#231F40]">
                    {item.title}
                  </h3>
                )}
                {item.description && (
                  <p className="text-[#4E4B66] text-base leading-6">
                    {item.description}
                  </p>
                )}
                {item.subhead && (
                  <h4 className="text-xl font-semibold text-[#231F40]">
                    {item.subhead}
                  </h4>
                )}
                {item.option && (
                  <ul className="list-disc text-base text-[#4E4B66] pl-8 leading-6 font-normal">
                    {item.option.map((option, optionIndex) => (
                      <li
                        key={optionIndex}
                        className="mb-1 text-base font-normal"
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
                {item.description && (
                  <p className="text-[#4E4B66] text-base leading-6">
                    {item.description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default reCounselling;
