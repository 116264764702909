import React from "react";
interface TitleCardProps {
  title: string;
  color: string;
  bgColor: string;
  defaultColors?: { [key: string]: string };
}

export const constDefaultColorObj: { [key: string]: string } = {
  green: "#0EB78F" || "#00c798",
  blue: "#173CBA",
};

const TitleCard = ({
  title,
  bgColor,
  color,
  defaultColors = constDefaultColorObj,
}: TitleCardProps) =>
  // {
  //   title,
  //   bgColor,
  //   defaultColors,
  //   color,
  // }: {
  //   title: string;
  //   bgColor: any;
  //   defaultColors: typeof constDefaultColorObj;
  //   color: string;
  // }
  {
    return (
      <p
        // className={`p-2.5 md:p-3 bg-[${bgColor}] text-center text-white font-bold tracking-widest`}
        className={`p-2.5 md:p-3 text-center font-bold tracking-widest`}
        style={{ background: `${bgColor}`, color: `${color || "#000000"}` }}
      >
        {title}
      </p>
    );
  };
export default TitleCard;
