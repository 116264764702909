
const HEADER = ["Courses", "Fees", "Eligiblity", "Application Date", "Action"]

const DATA = [
    {
        courses: "B. Tech",
        fees: "₹2.1 Lakhs (1st Year Fees)",
        eligibility: "10+2 with 75% + JEE Advanced",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
    {
        courses: "M.Tech",
        fees: "₹24,600 (1st Year Fees)",
        eligibility: "Pass in Graduation + GATE",
        applicationDate: "Pass in Graduation + GATE",
        action: "Apply Now",
    },
    {
        courses: "Ph.D",
        fees: "₹15,650 (1st Year Fees)",
        eligibility: "Post-graduation",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
    {
        courses: "B.Tech + M.Tech",
        fees: "₹2.1 Lakhs (1st Year Fees)",
        eligibility: "10+2 with 75%",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
    {
        courses: "EMBA",
        fees: "₹2.1 Lakhs (1st Year Fees)",
        eligibility: "Graduation",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
    {
        courses: "M.Sc",
        fees: "₹2.1 Lakhs (1st Year Fees)",
        eligibility: "Graduation with 55% + IIT JAM",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
    {
        courses: "BS + MS",
        fees: "₹2.1 Lakhs (1st Year Fees)",
        eligibility: "10+2",
        applicationDate: "19 June - 29 Jul 2024",
        action: "Apply Now",
    },
]

const EligibilityAndFeesTable = () => {
    return <div className="flex flex-col gap-4 border-2 shadow-lg rounded-3xl p-6">
        <div className="grid gap-4" style={{gridTemplateColumns: "1fr 2fr 2fr 2fr 1fr"}}>
            {HEADER.map(item => <div className="font-bold">{item}</div>)}
        </div>
        {DATA.map((item, index) => <>
            <hr />
            <div className="grid gap-4 text-sm" style={{gridTemplateColumns: "1fr 2fr 2fr 2fr 1fr"}}>
                <div>{item.courses}</div>
                <div>{item.fees}</div>
                <div>{item.eligibility}</div>
                <div>{item.applicationDate}</div>
                <div><a className="text-blue-800 font-bold" href="/course-details">{item.action}</a></div>
            </div>
        </>)}
    </div>
}

export default EligibilityAndFeesTable