import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import {
  confirmPasswordValidator,
  passwordValidator,
} from "../../util/FormValidator";

function ResetPassword() {
  const [submitted, setSubmitted] = useState(false);

  const [data, setData] = useState({
    confirmPassword: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    confirmPassword: "",
    password: "",
  });

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    let errorRes = "";
    if (name === "confirmPassword") {
      errorRes = confirmPasswordValidator(value, data?.password);
    } else if (name === "password") {
      errorRes = passwordValidator(value);
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: errorRes,
    }));
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    Object.entries(data).map(
      // eslint-disable-next-line array-callback-return
      ([key, value]: [string, string], index: number) => {
        validator(key, value);
      }
    );

    if (!invalid) {
      setSubmitted(true);
    }
  };
  const [invalid, setValid] = useState(false);
  const validator = (name: any, value: any) => {
    let errorRes = "";
    if (name === "confirmPassword") {
      errorRes = confirmPasswordValidator(value, data?.password);
    } else if (name === "password") {
      errorRes = passwordValidator(value);
    }
    setValid(errorRes !== "");
    setErrors((prevState) => ({
      ...prevState,
      [name]: errorRes,
    }));
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      {/* <CssBaseline /> */}
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Login</Typography>
        <Box sx={{ mt: 1, width: "100%" }} width={"100%"}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={data?.password}
            onChange={handleChange}
            error={errors?.password !== ""}
            helperText={errors?.password !== "" ? errors?.password : " "}
            sx={{ borderRadius: 10 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            name="confirmPassword"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            value={data?.confirmPassword}
            onChange={handleChange}
            error={errors?.confirmPassword !== ""}
            helperText={
              errors?.confirmPassword !== "" ? errors?.confirmPassword : " "
            }
            sx={{ borderRadius: 10 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPassword;
