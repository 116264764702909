import React, { useEffect, useState } from "react";
import ToggleGroup from "./GroupButtonsMore";
import { Box, Button, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { registrationData } from "../../services/apiReq";
import { standardList, standardBoardList } from "../../constants/signInForm";
function StandardInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const [standard, setStandard] = useState("");
  const [schoolBoardList, setSchoolBoardList] = useState<string[]>([]);

  const [schoolBoard, setSchoolBoard] = useState("");
  const [data, setData] = useState<registrationData>(location.state);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      standard: standard,
      boardType: schoolBoard,
    }));
  }, [standard, schoolBoard]);

  useEffect(() => {
    standardBoardList.forEach((list) => {
      if (list.standard === standard) {
        setSchoolBoardList(list.boardList);
      }
    });
  }, [standard]);
  const handleContinue = (event: any) => {
    navigate("/dreamCareerInfo", { state: { ...data } });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>

        <ToggleGroup
          list={standardList}
          title="Which Standard are you currently in ?"
          setSelectedValue={setStandard}
          selectedValue={standard}
          moreButton={false}
        />
        {standard === "" ? (
          <></>
        ) : (
          <ToggleGroup
            list={schoolBoardList}
            title="Which is your Current School Board ?"
            setSelectedValue={setSchoolBoard}
            selectedValue={schoolBoard}
            moreButton={false}
          />
        )}

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          onClick={handleContinue}
          disabled={standard === "" || schoolBoard === ""}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default StandardInfo;
