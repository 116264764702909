import CustomSwiper from "../../../../components/Slider/CustomSwiper"
import "./FeaturedPredictors.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
const data = [
    {
        image: "/images/college-predictor/image-feature.png",
        logo: "/images/college-predictor/logo-feature.png",
        name: "PSG JEE"
    }, 
]
const CollegeCard = ({
    image,
    collegeLogo,
    url,
}: {
    image: string;
    collegeLogo: string;
    url: string;

}) => (
    <div className="w-[304px]">
        <div className="flex flex-col gap-5 overflow-hidden ">

            <img
                src={image}
                className="rounded-xl object-contain w-[304px] h-[314px] "
            />
        </div>
        <a href="/college-predictor-intermediate">
        <div className="flex px-12">
            <div className="relative w-full h-[140px] flex  flex-col bg-white border pb-2 -translate-y-6  z-2 rounded-2xl justify-center items-center">


                <div className="pt-4 w-[60px] h-[60px]">
                    <img src={collegeLogo} className="" />
                </div>
                <div className="flex name-featured mt-2 pt-4 pb-2 ">
                  <span className="line-clamp-2 px-1">  {url}</span>
                </div>
            </div>
        </div>
        </a>
        </div>
);

const FeaturedPredictors = () => {
    const featuredpredictorsData = useSelector(
        (state: RootState) => state.predictorLanding.featuredPredictors
      );
      if (!featuredpredictorsData || featuredpredictorsData.length <= 0) return;
      const cardContent = featuredpredictorsData?.map((college) => (
        <CollegeCard
            {...college}
    
        />
    ));
    return (
        <>
            <div className="bg-[#FFF9E5] text-black my-7">
                <div className="container py-12">
                    <h1 className="text-4xl lg:text-5xl font-bold text-center">
                        Featured Predictors
                    </h1>
                    <div className="flex justify-center mt-6">
                        <CustomSwiper
                            className="!px-2"
                            items={cardContent}
                            navigation={"bottom"}
                            autoplay={true}
                            breakpoints={{
                                320: { slidesPerView: 1, spaceBetween: 20 },
                                768: { slidesPerView: 2, spaceBetween: 20 },
                                1024: { slidesPerView: 3, spaceBetween: 20 },
                                1280: { slidesPerView: 4, spaceBetween: 30 },
                            }}
                            progressBar={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )

}

export default FeaturedPredictors