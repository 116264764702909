import {
    faBell
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
    const user="Krishna";
const role="Student";
return(
    <>
    <div className="w-full flex bg-white flex-row">
    <div className="flex-grow"></div>

      <div className="user-content relative flex flex-row gap-1 item-center pr-4 md:pr-12 py-2"      >
      <button><FontAwesomeIcon icon={faBell} className="h-4 w-4 text-gray-600  pr-4" /></button>
      <div className="w-12 h-12 flex items-center justify-center bg-gray-200 rounded-lg shadow-lg ml-2 mr-1">
          <img src="/images/avatar.webp" className="h-8 w-8 text-gray-700" />
        </div><div className="flex flex-col">
  <div className="text-[12px] font-bold">
{user}
  </div>
  <div className="text-[12px]"></div>
{role}
</div>
      </div>

    </div>
    </>
)

}

export default Header;