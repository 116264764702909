import React from "react";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { GetWindowDimensions } from "../../../hooks/window-hooks/WindowHooks";
const BlogShareFloatingMenu = () => {
  const location = useLocation();
  const { width } = GetWindowDimensions();
  console.log("location", window.location.href);
  return (
    <div className="icon-bar">
      <div
        className={`fixed flex flex-col bg-[#00C798] ${
          width < 700
            ? "bottom-70 right-0 left-auto top-80"
            : "bottom-40 left-0 right-auto"
        }  `}
      >
        <ul>
          <li
            className="flex items-center justify-center group"
            data-value="facebook"
          >
            <FacebookShareButton url={"https://www.facebook.com/"}>
              <button className="flex p-4 group focus:outline-none">
                {/* <a
                href={`whatsapp://send?text=${encodeURIComponent(
                  window.location.href
                )}`}
                rel="nofollow noopener"
                target="_blank"
                className="share-icon"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-white group-hover:text-[#365899]   text-md"
                />

                
              </a> */}

                {/* For WhatsApp share */}
                {/* <WhatsappShareButton url={window.location.href}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-white group-hover:text-[#365899]   text-md"
                />
              </WhatsappShareButton> */}
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-white group-hover:text-[#365899]   text-md"
                />
              </button>
            </FacebookShareButton>
          </li>
          <li className="flex items-center group" data-value="facebook">
            <LinkedinShareButton url={"https://linkedin.com/"}>
              <button className="flex p-4 focus:outline-none">
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="text-white  group-hover:text-[#0a66c2] text-md"
                />
              </button>
            </LinkedinShareButton>
          </li>
          <li className="flex items-center group" data-value="linkedin">
            <TwitterShareButton url={"https://twitter.com/"}>
              <button className="flex p-4 bg-transparent focus:outline-none">
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-white group-hover:text-[#1d9bf0] text-md"
                />
              </button>
            </TwitterShareButton>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogShareFloatingMenu;
