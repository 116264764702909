import React, { useEffect, useState } from "react";
import { NavigationMenu } from "../data/UtilsData";
import MenuItems from "./MenuItems";
import NavDropdown from "./NavDropdown";
import SearchIcon from "../../../images/blog-article/search-icon.svg";
import AlertIcon from "../../../images/blog-article/alert-icon.svg";
import AlignRightMenu from "../../../images/blog-article/align-right-menu.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";
interface BlogsNavigationBarProps {
  categories?: any;
}
const BlogsNavigationBar: React.FC<BlogsNavigationBarProps> = ({
  categories,
}) => {
  const dispatch = useDispatch();

  const prevRoute = useLocation();
  // console.log("headerData", categories);
  const [isHovering, setIsHovering] = useState("");

  const [hoveredMenu, setHoveredMenu] = useState([""]);

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleEnter = (menu: any) => {
    setIsMouseOver(true);
    // console.log("handleMouseOver", categories[menu]?.subcategories);
    setHoveredMenu(categories[menu]?.subcategories);
    setIsHovering(menu);
  };

  const handleLeave = () => {
    setIsMouseOver(false);
    setIsHovering("");
  };
  // console.log("hoveredMenu", isHovering);

  useEffect(() => {}, [hoveredMenu]);

  //

  return (
    <div className="max-lg:hidden" onMouseLeave={handleLeave}>
      <div className="container flex py-4 overflow-auto bg-white-300">
        <div className="grow">
          <ul className="flex items-center justify-center">
            {/* {NavigationMenu.map((navigationItem, index) => (
              <>
                <li className="px-2 text-nowrap" key={index}>
                
                  {navigationItem?.isMainMenu ? (
                    <a className="px-2.5 py-1 bg-[#000000] flex justify-center items-center gap-2 text-[#ffffff] leading-4 cursor-pointer">
                      <img src={AlignRightMenu} />
                      <p>{navigationItem?.label}</p>
                    </a>
                  ) : (
                    <a
                      className="px-4 py-2  hover:bg-[#113cc0] hover:text-[#ffffff] cursor-pointer"
                      onMouseOver={(e) => handleEnter(navigationItem)}
                    >
                      {navigationItem?.label}
                    </a>
                  )}
                </li>

                
              </>
            ))} */}

            <li className="flex items-center justify-center gap-2 px-2 text-nowrap">
              {/* <a className="px-4 py-2  hover:bg-[#113cc0] hover:text-[#ffffff] cursor-pointer">
                Home
              </a>
              <a
                className="px-2.5 py-1 bg-[#000000] flex justify-center items-center gap-2 text-[#ffffff] leading-4 cursor-pointer m-3"
                href="/blogs"
              >
                <img src={AlignRightMenu} />
                <p>All</p>
              </a> */}
              {Object.keys(categories).map((navigationItem, index) => (
                <>
                  <NavLink
                    className={
                      navigationItem == isHovering
                        ? "px-4 py-2  bg-gray-300  cursor-pointer"
                        : "px-4 py-2  hover:bg-[#113cc0]  hover:text-[#ffffff] cursur-pointer aria-[current=page]:bg-[#113cc0] aria-[current=page]:text-[#ffffff] gap-3"
                    }
                    onMouseOver={(e) => handleEnter(navigationItem)}
                    onClick={() =>
                      dispatch(
                        setCurrentCategoryPageValue({
                          category: navigationItem,
                          subCategory: "",
                        })
                      )
                    }
                    to={`/blogs/${navigationItem.toLocaleLowerCase()}`}
                    state={{
                      from: "BlogsNavigationBar",
                      category: navigationItem,
                      prevRoute: prevRoute,
                    }}
                    // onMouseDown={(e) => setIsHovering("")}
                  >
                    {navigationItem}
                  </NavLink>
                  {/* {navigationItem?.isMainMenu ? (
                      <a className="px-2.5 py-1 bg-[#000000] flex justify-center items-center gap-2 text-[#ffffff] leading-4 cursor-pointer">
                        <img src={AlignRightMenu} />
                        <p>{navigationItem?.label}</p>
                      </a>
                    ) : (
                      <a
                        className="px-4 py-2  hover:bg-[#113cc0] hover:text-[#ffffff] cursor-pointer"
                        onMouseOver={(e) => handleEnter(navigationItem)}
                      >
                        {navigationItem?.label}
                      </a>
                    )} */}
                </>
              ))}
            </li>
            {/* <li className="p-1 cursor-pointer">Search</li> */}
            {/* <li className="cursor-pointer text-nowrap">
              <a className="px-4 py-2">
                <img src={AlertIcon} className="scale-[120%]" />
              </a>
            </li> */}
            {/* <li className="mx-1 grow"></li> */}
            {/* <li className="cursor-pointer text-nowrap">
              <a className="px-4 py-2">
                <img src={AlertIcon} className="scale-[120%]" />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      {isMouseOver && hoveredMenu && (
        <>
          <NavDropdown subCategories={hoveredMenu} category={isHovering} />

          {/* <div
            className={`md:w-[1000px] mx-auto absolute z-[1111] bg-white rounded-lg left-0  p-5 gap-4 `}
            style={{ boxShadow: "0 5px 4px rgba(0,0,0,.161)", height: 500 }}
           
          >
            <div className="flex flex-row gap-2">
              <div className="relative flex flex-row items-center justify-end">
                {hoveredMenu?.map((item: any) => (
                  <>{item}</>
                ))}
              </div>
            </div>
          </div> */}

          {/* <ul
            className="bg-yellow-600 cursor-pointer dropdown"
            onMouseLeave={handleLeave}
          >
            {hoveredMenu?.map((title: any, index: any) => (
              <li
                key={index}
                className="menu-items"
                onClick={() => console.log("title", title)}
              >
                {title}
              </li>
            ))}
          </ul> */}
        </>
      )}
    </div>
  );
};

export default BlogsNavigationBar;
