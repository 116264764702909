import React from "react";

interface Props {
  title: string;
  description: string;
}
const CollaborationsContent = [
  {
    title: "Collaborations",
    description:
      "Research collaborations with our foreign colleagues are crucial to the success of our global activities involving faculties and students. Within SRMIST, the research platform is significantly strengthened by the collaborative spirit between and among the various highly diversified streams of research undertaken at various centers and departments. This is a great advantage to any research minded scholar or student because this serves as an unparalleled platform to learn, work together and excel.  Our recent past collaborations have been with the following indicative list of researchers who have a high degree of accomplishment and are / have been affiliated with institutions of repute and standing.",
  },
];

const Collaborations: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col py-16 bg-[#113cc0]">
      <div className="container mx-auto">
        <div className="flex flex-col gap-5 justify-center items-center">
          {CollaborationsContent.map((content, index) => (
            <div
              key={index}
              className="flex flex-col gap-5 justify-center items-center"
            >
              <h3 className="text-3xl font-bold text-white">{content.title}</h3>
              <p className="text-lg text-white">{content.description}</p>
            </div>
          ))}
          <div className="flex flex-row">
            <a
              href="#"
              className="white-color button-styles font-bold text-white"
            >
              Academic Research
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collaborations;
