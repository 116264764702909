/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";

interface Item {
  title: string;
  listOfCollapses?: CollapseItem[];
  description?: string;
  tableTitle?: string;
  tableofHead?: string[];
  tableofBody?: string[][];
  buttonLink?: string;
  buttonText?: string;
}

interface CollapseContent {
  title: string;
  description: string;
  points?: string[];
}
interface CollapseItem {
  title: string;
  description: string;
  points?: string[];
}
const whatNew: CollapseContent = {
  title: "What's New",
  description:
    "The SRMHCAT 2024 cut off will be released by the SRM University. The cut off will be released in the form of opening and closing ranks. The cut off will be released for various courses offered by the university.",
  points: [
    "The CUET 2024 exam is scheduled to be held from May 15 to May 24, 2024.",
    "The LSAT India 2024 exam (May session) result for admission to 3-year LLB, 5-year Integrated LLB, and LLM courses is expected to be declared by the last week of May 2024.",
    "CUET PG 2024 counselling process registration for admission to PG courses is open and the last date to apply is Jun 20, 2024.",
    "NATA Result 2024 has been released for admission to the BArch course.",
    "The TANCET 2024 counseling process for admission to MBA/MCA courses is expected to begin in May 2024.",
  ],
};

const items: Item[] = [
  {
    title: "Table of content",
    listOfCollapses: [
      {
        title: "SRM IST Chennai SRMHCAT Cut Off 2024",
        description:
          "The SRMHCAT 2024 cut off will be released by the SRM University. The cut off will be released in the form of opening and closing ranks. The cut off will be released for various courses offered by the university.",
      },
      {
        title: "Factors Determining SRMHCAT Cut Off 2024",
        description:
          "The SRMHCAT 2024 cut off will be determined by the authorities based on various factors. The factors that will be considered while determining the cut off are mentioned below.",
        points: [
          "Number of candidates who appeared for the exam",
          "Number of seats available in the course",
          "Difficulty level of the exam",
          "Previous year cut off trends",
          "Performance of the candidates in the exam",
        ],
      },
      {
        title: "SRMHCAT Cut Off 2024 - Important Points",
        description:
          "The SRMHCAT 2024 cut off will be released by the authorities in online mode. The cut off will be released for various courses offered by the university. The cut off will be released in the form of opening and closing ranks. The cut off will be released for various categories of candidates. The cut off will be released based on various factors. The cut off will be released for various campuses of the university.",
      },
    ],
  },
  {
    title: "SRM University Chennai MBA Cutoff Year Wise Comparison 2022, 2021",
    description:
      "SRM University Chennai offers admission to the MBA course based XAT/ NMAT/SRMHCAT scores through SRM School of Management. As per the previous year cutoff 2022, candidates with XAT and MAT Percentiles of 80 each for the General category All India Quota are eligible for the further round of admissions. Tabulated below are the previous year's percentile for SRM University Chennai cutoff:",
    tableTitle:
      "Master of Business Administration (MBA) - SRM School of Management : Cutoffs (GN-All India)",
    tableofHead: ["Exam", "2022", "2021"],
    tableofBody: [
      ["MAT (percentile)", "80", "80"],
      ["XAT (percentile)", "80", "80"],
      ["SRMHCAT", "80", "80"],
    ],
    buttonText: "Cut-off details",
    buttonLink: "#",
  },
  {
    title: "SRM University Chennai MBA Important Dates 2024",
    description:
      "Some of the important dates related to the SRM University MBA course are tabulated below:",
    tableTitle: "",
    tableofHead: ["Dates", "Upcoming Events"],
    tableofBody: [
      ["24 May '24", "MAT 2024 IBT 2"],
      ["25 May '24", "MAT 2024 IBT 3"],
      ["26 May '24", "MAT 2024 Registration Process PBT"],
      ["29 Mar '24 - 28 May '24", "MAT 2024 Registration IBT 3"],
      ["28 May '24", "MAT 2024 IBT 6"],
    ],
    buttonText: "All dates",
    buttonLink: "#",
  },
  {
    title: "SRM University Chennai 2023 Cutoff Year-Wise PDF Download",
    description:
      "Candidates can refer to the table given below to download the consolidated previous year's NMAT/ XAT/ SRMHCAT cutoff lists for the General All India category students:",
    tableTitle: "SRM IST Chennai MAT,XAT Counselling Rounds",
    tableofHead: ["Exam", "2022", "2023"],
    tableofBody: [
      ["MAT (percentile)", "Pdf", "Pdf"],
      ["XAT (percentile)", "Pdf", "Pdf"],
      ["SRMHCAT", "Pdf", "Pdf"],
    ],
    buttonText: "",
    buttonLink: "",
  },
];

const CutoffsContent: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [showAllPoints, setShowAllPoints] = React.useState(false);
  const [activeCollapse, setActiveCollapse] = useState<{
    [key: number]: number | null;
  }>({});

  const handleItemClick = (index: number) => {
    setActiveItem(index === activeItem ? null : index);
  };

  const handleCollapseClick = (itemIndex: number, collapseIndex: number) => {
    setActiveCollapse((prev) => ({
      ...prev,
      [itemIndex]: prev[itemIndex] === collapseIndex ? null : collapseIndex,
    }));
  };

  return (
    <div className="flex flex-col my-11">
      <div className="container mx-auto">
        <div className="bg-[#F4F7FB] rounded-[20px] p-16 flex flex-col gap-5">
          <div className="flex flex-col gap-4">
            <h3 className="text-3xl font-extrabold text-[#231F40] text-center">
              Cutoff 2023: Check Previous Year Ranks
            </h3>
          </div>
          {whatNew && (
            <div className="flex flex-col gap-4 px-5 py-3 bg-white rounded-2xl max-md:px-5 max-md:max-w-full">
              <h3 className="text-2xl font-bold text-[#231F40]">
                {whatNew.title}
              </h3>
              <p className="text-lg text-[#231F40]">{whatNew.description}</p>
              {whatNew.points && (
                <ul className="list-disc pl-6 transition-all duration-1000">
                  {(showAllPoints
                    ? whatNew.points
                    : whatNew.points.slice(0, 1)
                  ).map((point, index) => (
                    <li key={index} className="text-lg text-[#231F40]">
                      {point}
                    </li>
                  ))}
                </ul>
              )}
              <div className="flex justify-end gap-5">
                <a
                  href="#"
                  className="text-blue-800 text-base cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAllPoints(!showAllPoints);
                  }}
                >
                  {showAllPoints ? "Show less" : "Show more"}
                </a>
              </div>
            </div>
          )}
          {items.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col gap-4 px-5 py-3 bg-white rounded-2xl max-md:px-5 max-md:max-w-full ${
                activeItem === index
                  ? "border-2 border-blue-800 border-solid shadow-lg"
                  : "border-2 border-transparent border-solid"
              }`}
            >
              <div
                className="flex gap-5 font-medium text-zinc-800 max-md:flex-wrap max-md:max-w-full"
                onClick={() => handleItemClick(index)}
              >
                <h3 className="text-2xl flex-auto my-auto max-md:max-w-full ">
                  {item.title}
                </h3>
                <button
                  className={` border p-3 h-10 w-10 text-center flex justify-center items-center rounded-full ${
                    activeItem === index
                      ? "transform rotate-180 text-white bg-[#113CC0]"
                      : "text-[#113CC0] bg-white border-[#113CC0]"
                  }`}
                >
                  <svg
                    width="16"
                    height="9"
                    viewBox="0 0 16 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.83008 0.999756L8.07832 7.21976L14.3266 0.999756"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              {activeItem === index && (
                <div className="leading-7 text-zinc-500 max-md:max-w-full px-3 flex flex-col gap-5">
                  {item.listOfCollapses?.map((collapse, collapseIndex) => (
                    <div key={collapseIndex} className="flex flex-col gap-4">
                      <h4
                        className="text-lg font-bold text-[#231F40] cursor-pointer w-full flex flex-row justify-between items-center"
                        onClick={() =>
                          handleCollapseClick(index, collapseIndex)
                        }
                      >
                        {collapse.title}
                        <button
                          className={`${
                            activeCollapse[index] === collapseIndex
                              ? "transform rotate-180"
                              : ""
                          }`}
                        >
                          <svg
                            width="16"
                            height="9"
                            viewBox="0 0 16 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.83008 0.999756L8.07832 7.21976L14.3266 0.999756"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </h4>
                      {activeCollapse[index] === collapseIndex && (
                        <div>
                          <p className="text-lg text-[#231F40]">
                            {collapse.description}
                          </p>
                          {collapse.points && (
                            <ul className="list-disc pl-6">
                              {collapse.points.map((point, pointIndex) => (
                                <li
                                  key={pointIndex}
                                  className="text-lg text-[#231F40]"
                                >
                                  {point}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {activeItem === index && !item.listOfCollapses && (
                <div className="flex flex-col gap-4">
                  <p className="text-lg text-[#231F40]">{item.description}</p>
                  {item.tableTitle && (
                    <h4 className="text-lg font-bold text-[#231F40]">
                      {item.tableTitle}
                    </h4>
                  )}
                  {item.tableofHead && item.tableofBody && (
                    <table className="w-full">
                      <thead>
                        <tr>
                          {item.tableofHead.map((header, index) => (
                            <th
                              key={index}
                              className="border border-gray-400 text-white px-4 py-2 text-left bg-gray-600"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {item.tableofBody.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                              <td
                                key={cellIndex}
                                className="border border-gray-400 px-4 py-2"
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {item.buttonText && item.buttonLink && (
                    <div className="flex justify-center gap-5">
                      <a
                        href={item.buttonLink}
                        className="blue-color button-styles"
                      >
                        {item.buttonText}
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CutoffsContent;
