import React from "react";
import collegeHistory from "../../../../images/college/details/college-history.png";
import { url } from "inspector";
import excellenceSecBg from "../../../../images/college/details/excellence-sec-bg.jpeg";
import collegesIcon from "../../../../images/college/details/Colleges.svg";
import departmentsIcon from "../../../../images/college/details/Departments.svg";
import ProgramsIcon from "../../../../images/college/details/Programs.svg";
import AcadamicationsIcon from "../../../../images/college/details/Academicians.svg";
import CounterAnimation from "../../../../components/CounterAnimation";

const careerExcellence = [
  {
    statisticsData: [
      {
        year: "40",
        title: "Colleges",
        image: collegesIcon,
      },
      {
        year: "111",
        title: "Departments",
        image: departmentsIcon,
      },
      {
        year: "300+",
        title: "Programs",
        image: ProgramsIcon,
      },
      {
        year: "3200+",
        title: "Acadamications",
        image: AcadamicationsIcon,
      },
    ],
  },
];

const CollegeCareerExcellSection = () => {

  const transformedStatisticsData = careerExcellence[0].statisticsData.map(item => {
    const finalValue = extractNumberAndSymbol(item.year)
    return {number: finalValue.number, symbol: finalValue.symbol, ...item}
  })

  return (
    <>
      <div
        className="tab-content mt-12 py-16 bg-[#F4F7FB] relative bg-cover bg-center before:absolute before:inset-0 before:bg-[#113CC0] before:opacity-80 before:z-0"
        id="Overview"
        style={{ backgroundImage: `url(${excellenceSecBg})` }}
      >
        <div className="flex justify-between items-center relative z-10">
          <div className="container mx-auto">
            <div className="flex flex-col items-center gap-12 w-full">
              <div className="flex flex-row items-center">
                <h3 className="text-3xl font-extrabold text-white">
                  {" "}
                  Learning For Career Excellence
                </h3>
              </div>
              <div className="grid grid-cols-4 gap-4 flex-row justify-center items-start w-full">
                {transformedStatisticsData.map((excellence, index) => (
                  <div
                    key={index}
                    className="flex flex-col flex-grow justify-between items-center gap-3.5"
                  >
                    <div className="flex items-center justify-center w-14 h-14 overflow-hidden">
                      <img
                        src={excellence.image}
                        alt={excellence.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex flex-col items-center gap-1">
                      <h3 className={"text-3xl font-extrabold text-white"}>
                        <CounterAnimation start={0} end={excellence.number} />{excellence.symbol}
                      </h3>
                      <h4 className="text-base font-medium text-white">
                        {excellence.title}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollegeCareerExcellSection;

function extractNumberAndSymbol(str : string) {
  const regex = /^(\d+)(\+?)$/;
  const match = str.match(regex);
  if (match) {
    return {
      number: parseInt(match[1],10),
      symbol: match[2] || ''
    };
  }
  return {
    number: 0,
    symbol: ''
  };
}