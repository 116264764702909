export enum FilterCategory {
  Radio = "RADIO",
  MultiSelect = "MULTI_SELECT",
  SingleSelect = "SINGLE_SELECT",
}

export enum FilterActions {
  ChangeRadio = "CHANGE_RADIO",
  AddMultiSelect = "ADD_MULTI_SELECT",
  RemoveMultiSelect = "REMOVE_MULTI_SELECT",
  ChangeSingleSelect = "CHANGE_SINGLE_SELECT",
}
