// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.college-predictor-details .description p {
  font-size: 1rem;
  margin-top: 1rem;
}
.college-predictor-details .description ul {
  margin-top: 1rem;
  margin-left: 1rem;
}
.college-predictor-details .description li {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.college-predictor-details .description h3,
.college-predictor-details .description h2 {
  font-size: 1rem;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/intermediate/components/Details.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,gBAAA;AADN;AAII;EACE,gBAAA;EACA,iBAAA;AAFN;AAKI;EACE,eAAA;EACA,kBAAA;AAHN;AAMI;;EAEE,eAAA;EACA,gBAAA;AAJN","sourcesContent":[".college-predictor-details {\n  .description {\n    p {\n      font-size: 1rem;\n      margin-top: 1rem;\n    }\n\n    ul {\n      margin-top: 1rem;\n      margin-left: 1rem;\n    }\n\n    li {\n      font-size: 1rem;\n      margin-top: 0.5rem;\n    }\n\n    h3,\n    h2 {\n      font-size: 1rem;\n      margin-top: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
