import React from "react";

interface Props {
  title: string;
  pdfLink: string;
  description: string;
}
const ThrustAreaResearchContent = [
  {
    title: "Thrust Area Of Research",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum",
    pdfLink:
      "https://webstor.srmist.edu.in/web_assets/downloads/2023/thrust-area-of-research.pdf",
  },
];

const ThrustAreaResearch: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col py-16">
      <div className="container mx-auto">
        {ThrustAreaResearchContent.map((content, index) => (
          <div
            key={index}
            className="flex flex-col gap-5 justify-center items-center"
          >
            <h3 className="text-3xl font-bold text-[#231F40]">
              {content.title}
            </h3>
            <p className="text-lg text-[#231F40]">{content.description}</p>
            <a
              href={content.pdfLink}
              className="blue-color button-styles font-bold"
            >
              Download PDF
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThrustAreaResearch;
