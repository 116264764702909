import ResultsGuidance from "./Cards/ResultsGuidance";
import Summary from "./Cards/Summary";
import ReportDownload from "./Cards/ReportDownload";
import Advertisment from "./Cards/Advertisment";
const CollegePredictorResults = () =>{
return(
    <>
    <Summary/>
    <ReportDownload/>
    <ResultsGuidance/>
    <Advertisment/>
    </>
)

}

export default CollegePredictorResults