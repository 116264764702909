import {
  faArrowRight,
  faBell,
  faHeart,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";

const logo = "/images/scholarship/intermediate/tata-capital-logo.png";
const title = "TATA Capital Pankh Scholarship Program 2024-25";
const daysToGo = 10;
const type = "Private ( Partial )";
const category = "School & College Scholarships";
const award = "Up to INR :12,000";
const eligibility = "For class 11 to Graduation";

const ScholarshipCard = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <div className="p-4 rounded-lg shadow-lg bg-white text-black font-inter">
      <div className="flex items-center justify-between">
        <img src={logo} />
        <button onClick={handleClick}>
          <FontAwesomeIcon
            icon={faHeart}
            color={isClicked ? "#EC1A3B" : "#FFF"}
            stroke="#EC1A3B"
            size="lg"
            strokeWidth={40}
          />
        </button>
      </div>
      <div className="flex">
        <div className="flex-1">
          <h3 className="text-lg font-bold">
            {title}
            {"  "}
            <span className="text-xs text-green">{`${daysToGo} Days to Go`}</span>
          </h3>
          <p className="mt-2 text-sm">
            <strong>Scholarship Type: </strong>
            {type}
            {"  "}
            <strong>Category: </strong>
            {category}
          </p>
          <table className="mt-2 text-sm">
            <tr className="border-b first:border-t border-grey">
              <th className="text-left text-blue font-medium border-x border-grey p-2">
                Scholarship Award
              </th>
              <td className="text-left  border-r border-grey p-2">{award}</td>
            </tr>
            <tr className="border-b first:border-t border-grey">
              <th className="text-left  text-blue font-medium border-x border-grey p-2">
                Scholarship Eligibility
              </th>
              <td className="text-left border-r border-grey p-2">
                {eligibility}
              </td>
            </tr>
          </table>
          <div className="mt-4 flex gap-4 items-center text-[#69678B]">
            {[
              { name: "About Scholarship", link: "/scholarship-details/about" },
              {
                name: "Scholarship Eligibility",
                link: "/scholarship-details/eligibility",
              },
              {
                name: "Benefits & Documents",
                link: "/scholarship-details/benefits",
              },
              {
                name: "How to Apply",
                link: "/scholarship-details/how-to-apply",
              },
            ].map((item, index) => (
              <>
                {index > 0 && "|"}
                <Link to={item.link}>{item.name}</Link>
              </>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4 items-stretch">
          <button className="px-4 py-2 bg-blue rounded-lg text-base text-white flex gap-2 items-center justify-between">
            <span>Apply Now</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
          <button className="px-4 py-2 border-2 border-blue bg-blue-light rounded-lg text-base text-blue flex gap-2 items-center justify-between">
            <span>Get Alerts</span>
            <FontAwesomeIcon icon={faBell} />
          </button>
          <button className="px-4 py-2 border-2 border-blue rounded-lg text-base text-blue flex gap-2 items-center justify-between">
            <span>Mentor</span>
            <FontAwesomeIcon icon={faMobile} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipCard;
