import React, { useState } from "react";

import amityUniversityLogo from "../../../images/college/details/amity-university-logo.svg";
import digitalLiteracyLogo from "../../../images/college/details/digital-literacy-logo.svg";
interface ApplyNowFormProps {
  logo?: string;
}

const ApplyNowForm: React.FC<ApplyNowFormProps> = ({ logo }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "58px",
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
      ></div>
      <div className="max-h-[80vh] overflow-y-auto max-lg:w-[90vw] flex flex-col items-center lg:flex-row gap-4 p-4 bg-white">
        <div className="flex flex-col gap-4">
          <div className="bg-white text-center flex justify-center items-center">
            <img src={logo || amityUniversityLogo} alt="apply now" />
          </div>
          <div className="bg-white flex justify-center items-center">
            <img src={digitalLiteracyLogo} alt="apply now" />
          </div>
        </div>

        <div className="w-full lg:w-2/3">
          <h4 className="text-[--primary-color] text-3xl">
            Register Now To Apply
          </h4>
          <form className="w-full flex flex-col gap-4 mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  NAME<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  INTERSTED COURSE<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  PHONE NUMBER<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  EMAIL<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="CURRENT LOCATION"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  CURRENT LOCATION<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div className="flex items-start gap-2">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                    required
                  />
                </div>
                <label
                  htmlFor="terms"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  I agree to the{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Privacy Policy.
                  </a>
                </label>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 max-lg:mx-auto">
              <button
                type="submit"
                style={{
                  background:
                    "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
                }}
                className="text-white rounded-md py-2.5 px-5 text-sm font-medium text-center"
              >
                SUBMIT
              </button>
              <button
                type="reset"
                style={{
                  border: "1px solid #06afa9",
                }}
                className="text-[#747474] rounded-md py-2.5 px-5 text-sm font-medium text-center"
              >
                CLEAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ApplyNowForm;
