import React from "react";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
const SocialMediaList = () => {
  return (
    <>
      <ul className="flex flex-col bg-[#00C798] gap-2 p-2">
        <li
          className="flex flex-row items-center justify-center gap-2 group"
          data-value="facebook"
        >
          <FacebookShareButton
            url={"https://www.facebook.com/"}
            className="flex flex-row items-center justify-center gap-2 p-1"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="text-sm text-white"
            />
            <span className="text-xs text-white">Facebook</span>
          </FacebookShareButton>
        </li>
        <li className="flex items-center group" data-value="facebook">
          <LinkedinShareButton
            url={"https://linkedin.com/"}
            className="flex flex-row items-center justify-center gap-2 p-1"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              className="text-sm text-white"
            />
            <span className="text-xs text-white">LinkedIn</span>
          </LinkedinShareButton>
        </li>
        <li className="flex items-center group" data-value="linkedin">
          <TwitterShareButton
            url={"https://twitter.com/"}
            className="flex flex-row items-center justify-center gap-2 p-1"
          >
            <FontAwesomeIcon icon={faTwitter} className="text-sm text-white" />
            <span className="text-xs text-white">Twitter</span>
          </TwitterShareButton>
        </li>
        <li className="flex items-center group" data-value="linkedin">
          <WhatsappShareButton
            url={"https://www.whatsapp.com/"}
            className="flex flex-row items-center justify-center gap-2 p-1"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="text-sm text-white" />
            <span className="text-xs text-white">WhatsApp</span>
          </WhatsappShareButton>
        </li>
        <li className="flex items-center group" data-value="linkedin">
          <EmailShareButton
            url={window.location.href}
            className="flex flex-row items-center justify-center gap-2 p-1"
          >
            <FontAwesomeIcon icon={faEnvelope} className="text-sm text-white" />
            <span className="text-xs text-white">E-mail</span>
          </EmailShareButton>
        </li>
      </ul>
    </>
  );
};

export default SocialMediaList;
