import { useDispatch } from "react-redux";
import Banner from "./components/Banner";
import CutOffs from "./components/CutOffs";
import Details from "./components/Details";
import KnowledgeHub from "./components/KnowledgeHub";
import Rankings from "./components/Rankings";
import { AppDispatch } from "../../../store/store";
import { useEffect } from "react";
import { fetchLandingData } from "./collegePredictorIntermediateThunk";
import PredictionForm from "./components/PredictionForm";
import Testimonial from "./components/Testimonial";

const CollegePredictorIntermediate = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchLandingData());
  }, []);

  return (
    <div className="font-inter flex flex-col gap-4 lg:gap-6 py-4 lg:py-6">
      <Banner />
      <PredictionForm />
      <Details />
      <CutOffs />
      <KnowledgeHub />
      <Testimonial />
      <Rankings />
    </div>
  );
};

export default CollegePredictorIntermediate;
