// NEW IMPLEMENTATION FOR DIFFERENT kEY NAMES

import React, { Fragment, useState } from "react";
import OptionCard from "./OptionCard";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../../store/store";
import {
  prevStep,
  nextStep,
} from "../../../../../../store/slices/registrationFormSlice";
type Option = string | { [key: string]: any };

type OptionGroupProps = {
  options: Option[];
  mode: "single" | "multiple";
  getTitle: (option: Option) => string;
  handleCardSelect: (any: any) => void;
  cardSize: string;
};

const OptionGroup: React.FC<OptionGroupProps> = ({
  options,
  mode,
  getTitle,
  cardSize = "54",
  handleCardSelect,
}) => {
  const dispatch = useDispatch();
  const { data, step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { otpSent, error, isAuthenticated, token, user } = useSelector(
    (state: RootState) => state.auth
  );

  const [selectedOption, setSelectedOption] = useState<string | string[]>(
    mode === "single" ? "" : []
  );

  const handleOptionClick = (option: Option) => {
    const optionTitle = getTitle(option);

    if (mode === "single") {
      setSelectedOption(optionTitle);

      // setTimeout(() => {
      //   dispatch(nextStep());
      // }, 300);
    } else {
      if (Array.isArray(selectedOption)) {
        if (selectedOption.includes(optionTitle)) {
          setSelectedOption(
            selectedOption.filter((selected) => selected !== optionTitle)
          );
        } else {
          setSelectedOption([...selectedOption, optionTitle]);
        }
      }
    }
  };

  const isSelected = (option: Option) => {
    const optionTitle = getTitle(option);
    if (mode === "single") {
      return selectedOption === optionTitle;
    } else {
      return (
        Array.isArray(selectedOption) && selectedOption.includes(optionTitle)
      );
    }
  };

  handleCardSelect(selectedOption);

  return (
    <Fragment>
      {/* <div className="container mx-auto mt-10"> */}
      {/* <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"> */}

      {/* <div className="flex flex-wrap -mx-4">
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 1
        </div>
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 2
        </div>
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 3
        </div>
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 4
        </div>
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 5
        </div>
        <div className="w-full px-4 mb-4 sm:w-full md:w-1/3 lg:w-1/4">
          Card 6
        </div>
      </div> */}
      {/* <div className="flex flex-wrap gap-2"> */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {options.map((option, index) => (
          <OptionCard
            key={index}
            title={getTitle(option)}
            isSelected={isSelected(option)}
            onClick={() => handleOptionClick(option)}
            cardSize={cardSize}
          />
        ))}
      </div>
      {/* </div> */}
    </Fragment>
  );
};

export default OptionGroup;

// OLD Implementation

// import React, { useState } from "react";
// import OptionCard from "./OptionCard";
// type OptionGroupProps = {
//   mode: "single" | "multiple";
//   options: string[];
//   handleCardSelect: (any: any) => void;
//   cardSize: string;
// };
// const OptionGroup: React.FC<OptionGroupProps> = ({
//   mode,
//   options,
//   handleCardSelect,
//   cardSize,
// }) => {
//   const [selectedOptions, setSelectedOptions] = useState<string | string[]>([]);
//   const handleOptionClick = (option: string) => {
//     if (mode === "single") {
//       setSelectedOptions(option);
//     } else {
//       if (Array.isArray(selectedOptions)) {
//         if (selectedOptions.includes(option)) {
//           setSelectedOptions(
//             selectedOptions.filter((selected) => selected !== option)
//           );
//         } else {
//           setSelectedOptions([...selectedOptions, option]);
//         }
//       }
//     }
//   };
//   handleCardSelect(selectedOptions);
//   return (
//     <div className="flex flex-row w-full gap-4 mb-6 card-group md:flex-wrap max-lg:flex-wrap max-sm:flex-col">
//       {options.map((option, index) => (
//         <OptionCard
//           key={option}
//           title={option}
//           isSelected={selectedOptions.includes(option)}
//           onClick={() => handleOptionClick(option)}
//           cardSize={cardSize}
//         />
//       ))}
//     </div>
//   );
// };
// export default OptionGroup;
