// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button:hover {
  background-color: #0a2a7b;
  color: #f3f3f3;
}

.tooltip {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  background-color: #fff;
  color: #4c495c;
}

.tooltipTarget:hover .tooltip {
  display: block;
}

/* Style for the entire scrollbar */
.filter-section ::-webkit-scrollbar {
  width: 6px;
}

/* Style for the scrollbar track */
.filter-section ::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 7px;
}

/* Style for the scrollbar thumb */
.filter-section ::-webkit-scrollbar-thumb {
  background-color: #00b489;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/screens/colleges/CollegeIntermediate.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,sBAAA;EACA,cAAA;AACF;;AACA;EACE,cAAA;AAEF;;AACA,mCAAA;AACA;EACE,UAAA;AAEF;;AAEA,kCAAA;AACA;EACE,mBAAA;EACA,kBAAA;AACF;;AAEA,kCAAA;AACA;EACE,yBAAA;EACA,kBAAA;AACF","sourcesContent":[".button:hover {\n  background-color: #0a2a7b;\n  color: #f3f3f3;\n}\n\n.tooltip {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  z-index: 10;\n  background-color: #fff;\n  color: #4c495c;\n}\n.tooltipTarget:hover .tooltip {\n  display: block;\n}\n\n/* Style for the entire scrollbar */\n.filter-section ::-webkit-scrollbar {\n  width: 6px;\n  // height: 12px; /* height of the scrollbar */\n}\n\n/* Style for the scrollbar track */\n.filter-section ::-webkit-scrollbar-track {\n  background: #d9d9d9;\n  border-radius: 7px;\n}\n\n/* Style for the scrollbar thumb */\n.filter-section ::-webkit-scrollbar-thumb {\n  background-color: #00b489;\n  border-radius: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
