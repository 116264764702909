import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/navbar/newLogo.svg";
import chevronDown from "../../images/navbar/chevron-down.png";
import magnifier from "../../images/navbar/Magnifer.png";
import "./NavBar.scss";
import SearchBox from "../searchbox/SearchBox";

const menuItems = [
  "Home",
  "College",
  "Courses",
  "Exams",
  "Careers",
  "News",
  "Blogs",
  "Our Services",
];
const menuItems2 = [
  "For students",
  "Architecture",
  "Engineering",
  "Medical",
  "Management",
  "Law",
  "Liberal arts",
  "Design",
  "Others",
];

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMenu();
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="relative z-10">
      <div
        className={`nav-bar-section py-[25px] ${
          isMenuOpen ? "fixed inset-0 z-20 bg-white" : ""
        }`}
      >
        <div className="container mx-auto flex justify-between items-center">
          <button
            className="block translate-y-1 md:hidden"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>
          <div className="logo-section">
            <a href="/" className="main-logo">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="flex md:justify-between md:items-center md:gap-[16px]">
            <SearchBox />
          </div>
          <div className="menu-and-button-section flex md:gap-[25px] md:justify-between items-center">
            <div
              className={`hidden md:block ${
                isMenuOpen ? "block" : "hidden"
              } md:flex md:gap-[25px] md:justify-between`}
            >
              <nav className="navbar items-center">
                <ul className="nav-list gap-[16px]">
                  {menuItems.map((item, index) => (
                    <li key={index} className="nav-item">
                      <a
                        href={
                          item.toLowerCase() === "home"
                            ? "/"
                            : `/${item.toLowerCase()}`
                        }
                        className="nav-link flex items-center justify-center hover:bg-[#173CBA] hover:text-white px-2 py-1"
                      >
                        {item}
                        {item === "Our Services" && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                                fill="#333333"
                              />
                            </svg>
                          </span>
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="flex md:justify-between md:items-center md:gap-[16px]">
              <button className="py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200">
                login
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          ref={menuRef}
          className={`mobile-menu absolute w-full mt-6 mb-4 pb-6 z-20 text-center justify-center bg-[#ffffff] ${
            isMenuOpen ? "block" : "hidden"
          } md:hidden`}
        >
          <nav>
            <ul className="nav-list flex flex-col items-center justify-center text-center gap-4 p-4">
              {menuItems.map((item, index) => (
                <li key={index} className="nav-item">
                  <a
                    href={
                      item.toLowerCase() === "home"
                        ? "/"
                        : `/${item.toLowerCase()}`
                    }
                    className="nav-link flex gap-[4px] items-center justify-center text-center"
                  >
                    {item}
                  </a>
                </li>
              ))}
              {menuItems2.map((item, index) => (
                <li key={index} className="nav-item">
                  <a
                    href={
                      item.toLowerCase() === "home"
                        ? "/"
                        : `/${item.toLowerCase()}`
                    }
                    className="nav-link flex gap-[4px] items-center justify-center text-center"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-10 bg-black opacity-50"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
}
