// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background: #113cbf;
  border-radius: 50%;
  cursor: pointer;
}

.page-number.active {
  background: #00c798;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/CustomPagination.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,mBAAA;EACA,YAAA;AADF","sourcesContent":["// flex items-center justify-center w-10 h-10 p-4 border text-white text-sm rounded-full bg-blue border-blue\n\n.page-number {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n  color: #ffffff;\n  background: #113cbf;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.page-number.active {\n  background: #00c798;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
