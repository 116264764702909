import React, { useState } from "react";
import TopPreferencesImg from "../../../../images/college/details/course-fee/TopPreferences.svg";

const TopPreferences = () => {
  const [preference, setPreference] = useState<string | null>(null);

  const handlePreferenceChange = () => {
    setPreference("newValue");
  };

  return (
    <div className="bg-[#00C798] flex flex-col py-11">
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-6 lg:w-2/5">
            <div className="flex">
              <h3 className="text-lg text-white">
                Is this school among your top 3 preferences?
              </h3>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-3.5">
                <button
                  onClick={() => setPreference("Yes")}
                  className={`border-#FFF7F2 border text-white rounded-lg text-base font-medium py-1 px-3.5 ${
                    preference === "Yes" ? "active" : ""
                  }`}
                >
                  Yes
                </button>
                <button
                  onClick={() => setPreference("No")}
                  className={`border-#FFF7F2 border text-white rounded-lg text-base font-medium py-1 px-3.5 ${
                    preference === "No" ? "active" : ""
                  }`}
                >
                  No
                </button>
                <button
                  onClick={() => setPreference("Not Sure")}
                  className={`border-#FFF7F2 border text-white rounded-lg text-base font-medium py-1 px-3.5 ${
                    preference === "Not Sure" ? "active" : ""
                  }`}
                >
                  Not Sure
                </button>
              </div>
              <div className="flex">
                <h4 className="text-sm font-medium text-white">
                  Submit your response to view opinions of other parents
                </h4>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-6 items-center lg:w-2/5">
            <div className="flex">
              <img src={TopPreferencesImg} alt="TopPreferencesImg" />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-3.5">
                <button
                  onClick={() => setPreference("Yes")}
                  className={`border-#FFF7F2 border text-white rounded-lg text-base font-medium py-1 px-3.5 ${
                    preference === "Yes" ? "active" : ""
                  }`}
                >
                  67
                </button>
              </div>
              <div className="flex">
                <h4 className="text-sm font-medium text-white">
                  Parents/Guardians already share there opinion on this
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPreferences;
