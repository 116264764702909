import React from "react";
import { Carousel } from "react-responsive-carousel";
import { image } from "../services/apiReq";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface CarouselProps {
  images: image[];
}

function CarouselView(props: CarouselProps) {
  const imagesList = props?.images;
  return (
    <Carousel autoPlay showThumbs={false} infiniteLoop={true}>
      {imagesList.map((img, index: number) => {
        return (
          <div key={index}>
            <img alt={img?.scr} src={img?.scr} />
            <p className="legend">{img?.title}</p>
          </div>
        );
      })}
    </Carousel>
  );
}

export default CarouselView;
