import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import BlogsHeader from "./BlogsHeader";
import Footer from "../../../components/footer/Footer";
import { HeaderAPIResponse } from "../data/UtilsData";
import axios from "axios";

const BlogsLayout = () => {
  const [headerResponse, setHeaderResponse] = useState<any>([]);
  const getHeaderData = async () => {
    await axios
      .get("https://mentormenteedev.com/api/blogs/allsections")
      .then((response: { data: any }) => {
        // console.log("response", response);
        // const allSectionData = response.data;
        // console.log("allSectionData", allSectionData);
        setHeaderResponse(response.data);
        // setSectionWiseData(allSectionData?.sections[0]);
      })
      .catch((error: any) => console.log("error", error));

    // setHeaderResponse(HeaderAPIResponse);
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  return (
    <>
      <BlogsHeader headerResponse={headerResponse?.categories} />
      <Outlet />
      {/* <Footer /> */}
    </>
  );
};

export default BlogsLayout;
