import React, { useState, useEffect } from "react";
import "./Banner.scss";

import happyStudent from "../../images/banner/happy-student.png";
import womenIcon from "../../images/banner/women.jpg";
import designIcon from "../../images/banner/design-icon.svg";
import graduateIcon from "../../images/banner/graduate-icon.svg";
import medicineIcon from "../../images/banner/medicine-icon.svg";
import engineeringIcon from "../../images/banner/engineering-icon.svg";
import managementIcon from "../../images/banner/management-icon.svg";
import lawIcon from "../../images/banner/law-icon.svg";
import fashionDesignIcon from "../../images/banner/fashion-design-icon.svg";
import { MotionValue, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTicket } from "@fortawesome/free-solid-svg-icons";

const careerPaths = [
  "Engineering",
  "Medicine",
  "Fashion design",
  "Law",
  "Management",
  "Design",
  "Alum & Mentor",
];

const careerPathData = [
  {
    path: "Engineering",
    avatar: womenIcon,
    icon: engineeringIcon,
  },
  {
    path: "Alum & Mentor",
    avatar: null,
    icon: graduateIcon,
  },
  {
    path: "Medicine",
    avatar: womenIcon,
    icon: medicineIcon,
  },
  {
    path: "Law",
    avatar: womenIcon,
    icon: lawIcon,
  },
  {
    path: "Fashion design",
    avatar: womenIcon,
    icon: fashionDesignIcon,
  },
  {
    path: "Management",
    avatar: womenIcon,
    icon: managementIcon,
  },
  {
    path: "Design",
    avatar: womenIcon,
    icon: designIcon,
  },
];

interface props {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
  animationItemRef: React.RefObject<HTMLDivElement>;
  animatedItemProps: {
    x: MotionValue<number>;
    y: MotionValue<number>;
    scale: MotionValue<number>;
  };
  isPulse: boolean;
}

const pulseVariant1 = {
  pulse: { scale: [1, 2], opacity: [1, 0], transition: { duration: 2 } },
  stop: { scale: [1], opacity: [1] },
};

const pulseVariant2 = {
  pulse: {
    scale: [1, 2],
    opacity: [1, 0],
    transition: { duration: 1.6, delay: 0.4, ease: "easeOut" },
  },
  stop: { scale: [1], opacity: [1] },
};

export default function Banner({
  animationCheckPointRef,
  animationItemRef,
  animatedItemProps,
  isPulse,
}: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isGif, setIsGif] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  const handleOpenModal = () => {
    setIsOpen(true);
    setIsGif(false);
  };
  const showModalPopup = () => {
    setIsOpen(true);
    setIsGif(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="banner-section ">
      <div className="container-section parent">
        <div className="banner-left   text-base font-semibold text-neutral-600">
          <div className="max-w-[505px]">
            <div className="w-full max-md:max-w-full">
              Nurturing Your Pathway to Success
            </div>
            <div className="w-full text-5xl leading-[66px] text-blue-950 max-md:max-w-full max-md:text-4xl">
              Dream's to Degree's
            </div>
            <div className="mt-3.5 w-full text-sm leading-7 max-md:max-w-full">
              College Mentor connects you with Mentors who share their
              experiences, provide insights, educational content and inspire you
              to reach for the stars. It's Mentorship in the Digital Age.
            </div>
            <div className="flex gap-3.5 mt-10 text-white max-md:flex-wrap">
              <button className="blue-color button-styles arrow-button">
                <button onClick={showModalPopup}>
                  <span>How college mentor works</span>
                </button>

                {/* You can customize the arrow icon here */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button
                className="green-color button-styles "
                onClick={handleOpenModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="currentColor"
                >
                  <g clip-path="url(#clip0_110_1714)">
                    <path
                      d="M12.9883 0C6.36086 0 0.988281 5.37257 0.988281 12C0.988281 18.6274 6.36086 24 12.9883 24C19.6157 24 24.9882 18.6274 24.9882 12C24.9812 5.37554 19.6127 0.00708147 12.9883 0ZM18.0419 12.3823C17.9588 12.549 17.8238 12.6841 17.6571 12.7671V12.7714L10.8 16.2C10.3765 16.4116 9.86171 16.2399 9.65007 15.8164C9.5899 15.696 9.55892 15.5631 9.55967 15.4286V8.57144C9.55947 8.09804 9.94302 7.71413 10.4164 7.71388C10.5496 7.71383 10.6809 7.74477 10.8 7.80428L17.6571 11.2329C18.0808 11.444 18.2531 11.9586 18.0419 12.3823Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_110_1714">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.988281)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span>See Video</span>
              </button>
              {isOpen && (
                <div className="z-[100]">
                  <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                  <div
                    id="modal"
                    className="fixed inset-0 flex items-center justify-center z-50 w-full h-full rounded-lg"
                    onClick={handleCloseModal}
                  >
                    <button
                      className="absolute top-8 right-8"
                      onClick={handleCloseModal}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                        width={20}
                        height={20}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <div
                      className="modal-content relative m-0  duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
                      style={{
                        animation: "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
                      }}
                    >
                      {!isGif ? (
                        <iframe
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/T6oKlQoHrIg?si=SRxz8q4ZyXoDqvb1?autoplay=1`}
                          title="YouTube Video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="inset-0 w-full h-full left-0 top-0 rounded-lg"
                        ></iframe>
                      ) : (
                        <div>
                          <img src="./images/careers/pop up.gif" alt="" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="banner-right">
          <div className="relative flex flex-col justify-center px-16 py-16 rounded-full border border-solid border-stone-300 aspect-square max-w-[550px] max-md:px-5">
            {careerPathData.map((data, index) => {
              const angle = (index * 360) / 7 - 60;
              const angleInRadians = (angle * Math.PI) / 180;
              const x = Math.cos(angleInRadians);
              const y = Math.sin(angleInRadians);

              return (
                <>
                  {data.avatar && (
                    <div
                      className="absolute w-12 h-12 z-50 -translate-x-1/2 -translate-y-1/2"
                      style={{
                        top: `calc(50% + ${x} * 50%)`,
                        left: `calc(50% + ${y} * 50%)`,
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 1, scale: 1 }}
                        animate={{
                          opacity: [0, 1, 1, 1, 1, 1, 0, 0],
                          scale: [0, 1, 1, 1, 1, 1, 0, 0],
                        }}
                        transition={{
                          duration: 5,
                          repeatDelay: 30,
                          repeat: Infinity,
                          delay: index * 5,
                        }}
                        className="h-full w-full flex items-center justify-center"
                      >
                        {" "}
                        <img
                          loading="lazy"
                          src={data.avatar}
                          alt="women icon"
                          className="w-full h-full object-cover rounded-full z-50"
                        />
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.2 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.4 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                      </motion.div>
                    </div>
                  )}
                  <div
                    className="absolute w-8 h-8 z-50 translate-x-4 -translate-y-[250%]"
                    style={{
                      top: `calc(50% + ${x} * 50%)`,
                      left: `calc(50% + ${y} * 50%)`,
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{
                        opacity: [0, 0, 1, 1, 0, 0],
                        scale: [0, 0, 1, 1, 0, 0],
                      }}
                      transition={{
                        duration: 5,
                        repeatDelay: 30,
                        repeat: Infinity,
                        delay: 1 + index * 5,
                      }}
                    >
                      <div
                        className={`relative py-1.5 px-4 w-max shadow-xl bg-white border-2 border-[#00c798] rounded-full flex gap-2 items-center`}
                      >
                        <img
                          loading="lazy"
                          src={data.icon}
                          alt={data.path + " icon"}
                          className={`h-6 aspect-square object-cover`}
                        />
                        <span className="text-base text-[#0e2059]">
                          {data.path}
                        </span>
                        {data.avatar && (
                          <svg
                            className="absolute top-[100%] left-4"
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polygon points="0,0 16,0 0,16" fill="#00c798" />
                          </svg>
                        )}
                        {data.avatar == null && (
                          <motion.div
                            initial={{ opacity: 1, scale: 1 }}
                            animate={{
                              opacity: [0, 0, 1, 1, 0, 0],
                              scale: [0, 0, 1, 1, 0, 0],
                            }}
                            transition={{
                              duration: 3,
                              repeatDelay: 32,
                              repeat: Infinity,
                              delay: 2 + index * 5,
                            }}
                            className={`aspect-square bg-[#0e2059] rounded-full py-1 px-2 flex items-center absolute bottom-[80%] -right-2`}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="2x"
                              style={{ color: "#00c798" }}
                            />
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                </>
              );
            })}
            <div className="small-circle shrink-0 w-full rounded-full border border-solid border-stone-300 aspect-square max-md:mr-0.5">
              <div className="flex flex-col pt-10 max-w-[626px]">
                <img
                  src={happyStudent}
                  alt="happy student"
                  className="student"
                />
                <div
                  ref={animationCheckPointRef}
                  className="flex flex-col items-start w-full max-md:pl-5 max-md:max-w-full absolute -left-20 top-1/2 z-50"
                >
                  <motion.div
                    className="w-16 h-16 flex items-center justify-center"
                    ref={animationItemRef}
                    style={{ ...animatedItemProps }}
                  >
                    <div className="absolute w-16 h-16 rounded-full overflow-hidden z-10">
                      <img
                        loading="lazy"
                        src={womenIcon}
                        alt="women icon"
                        className="h-full w-full object-cover women-logo"
                      />
                    </div>
                    <motion.div
                      variants={pulseVariant1}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                    <motion.div
                      variants={pulseVariant2}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
