import React, { useState } from 'react';
import Card from "./Card";
import Carroussel from "./Carroussel";
import "./blog-styles.scss";


function Blogs() {
  const [goToSlide, setGoToSlide] = useState(0); // State to handle which slide to go to

  const newRelease = {
    blogs: [
      {
        title: "Blog Title 1",
        url: "",
        image:
        "https://img.freepik.com/free-photo/teamwork-making-online-blog_53876-94868.jpg?t=st=1714744642~exp=1714748242~hmac=bc64d52cf75f2f4e7e858b5a3d4ee0601334a340f24a7f24575ec873f39f1176&w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "Migrating to Linear 101",
        url: "",
        image:
        "https://img.freepik.com/premium-photo/woman-relax-connecting-website-concept_53876-52314.jpg?w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "Be yourself",
        url: "",
        image:
        "https://img.freepik.com/free-photo/online-message-blog-chat-communication-envelop-graphic-icon-concept_53876-139717.jpg?t=st=1714744689~exp=1714748289~hmac=f2fed02f0c38db02efb360c4ebf8c7570a4ba2633836628cc716be534ed05585&w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
    ],
    news: [
      {
        title: "News Title 1",
        url: "",
        image:
        "https://img.freepik.com/free-vector/daily-newspaper-journal-design-template-with-two-page-opening-editable-headlines-quotes-text-articles-images-vector-illustration_1284-19684.jpg?w=2000&t=st=1714745008~exp=1714745608~hmac=52fed8dcfbabb666d8d6ddbece167b10c1be34c00c71b819fdde5598318f681e",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "Programmer's life",
        url: "",
        image:
        "https://img.freepik.com/free-vector/newspaper-template-realistic-poster_1284-20551.jpg?w=2000&t=st=1714745023~exp=1714745623~hmac=897bb83e9f54cf885bb747de82a7ddfcb0673d2aec5a082987761f59497ed226",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "What do you think of yourself",
        url: "",
        image:
        "https://img.freepik.com/free-vector/newspaper-template-design-with-financial-articles-news-advertising-information-flat_1284-32052.jpg?w=2000&t=st=1714745033~exp=1714745633~hmac=7f529a717cd7f682788ca8a4e460dc869adb7caafb5d11a49c4e9da54276124c",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
    ],
    events: [
      {
        title: "Event Title 1",
        url: "",
        image:
        "https://img.freepik.com/free-photo/bunch-flowers-leaves-table_1304-4187.jpg?t=st=1714745056~exp=1714748656~hmac=74f7fdfb1a1a6634f9252b7804186055102bb32955dcb8a8be684ecd6f19744c&w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "Godzilla v/s Kong",
        url: "",
        image:
        "https://img.freepik.com/free-photo/cute-couple-holding-christmas-decorations-gift_1304-5649.jpg?t=st=1714745073~exp=1714748673~hmac=7787d0c54f2622d6d296bb48bb0d2888755f797ee357095a34619711c279146a&w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
      {
        title: "House of the dragons",
        url: "",
        image:
        "https://img.freepik.com/free-photo/you-know-what-is-inside_329181-9505.jpg?t=st=1714745089~exp=1714748689~hmac=9d685d281b7f290b24918d21f757eb72d6140b1925467929f58c9c324f54c5dc&w=2000",
        content: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      },
    ],
  };

  const cards = [
    {
      key: 1,
      content: <Card content={newRelease.blogs} keyindex="1" />,
    },
    {
      key: 2,
      content: <Card content={newRelease.news} keyindex="2" />,
    },
    {
      key: 3,
      content: <Card content={newRelease.events} keyindex="3" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });
  return (
    <div className="flex flex-row p-12 relative">
      <div className="mx-auto container">
        <div className="flex justify-center items-center mb-8">
          <h3 className="text-3xl font-extrabold text-center">
            Blogs & Article
          </h3>
        </div>
        <div className="">
      <Carroussel
        cards={cards}
        height="400px"
        width="60%"
        margin="0 auto"
        offset={2}
        showArrows={false}
        goToSlide={goToSlide}
      />
    </div>
      </div>
    </div>
  );
}

export default Blogs;