// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highPaidCareer {
  padding-bottom: 2rem;
}
.highPaidCareer .swiper-wrapper {
  padding-bottom: 2rem;
}

.know-more {
  background: #173cba;
  cursor: pointer;
  position: relative;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.know-more:hover .arrow-icon {
  transform: translateX(6px);
}`, "",{"version":3,"sources":["webpack://./src/components/careers-comp/highPaidCareer/HighpaidCareerStyles.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,oBAAA;AAEJ;;AACA;EACE,mBAAA;EAEA,eAAA;EAEA,kBAAA;AAAF;;AAIA;EACE,qBAAA;EACA,mCAAA;AADF;;AAIA;EACE,0BAAA;AADF","sourcesContent":[".highPaidCareer {\n  padding-bottom: 2rem;\n  .swiper-wrapper {\n    padding-bottom: 2rem;\n  }\n}\n.know-more {\n  background: #173cba;\n  // border: none;\n  cursor: pointer;\n  // padding: 10px;\n  position: relative;\n  // overflow: hidden;\n}\n\n.arrow-icon {\n  display: inline-block;\n  transition: transform 0.3s ease-out;\n}\n\n.know-more:hover .arrow-icon {\n  transform: translateX(6px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
