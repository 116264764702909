import { FC, useRef } from "react";

interface IProps {
  tabs: string[];
  activeTabIndex: number;
  changeActiveTabIndex: (index: number) => void;
}

const TabNavigations: FC<IProps> = ({
  tabs,
  activeTabIndex,
  changeActiveTabIndex,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <section
      className="py-4"
      style={{
        background:
          "linear-gradient(rgb(23, 60, 186) 0%, rgb(6, 175, 169) 100%)",
      }}
    >
      <div className="container flex gap-4 items-center">
        <button
          onClick={() =>
            scrollContainerRef?.current?.scrollBy({
              left: -200,
              behavior: "smooth",
            })
          }
        >
          <PrevButtonIcon />
        </button>
        <div
          className="flex-1 flex gap-2 overflow-x-auto no-scrollbar"
          ref={scrollContainerRef}
        >
          {tabs.map((tab, index) => (
            <button
              className={`${
                index == activeTabIndex ? "bg-green" : "bg-white"
              } shrink-0 rounded text-xs sm:text-base text-black px-2 py-1.5`}
              onClick={() => changeActiveTabIndex(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <button
          onClick={() =>
            scrollContainerRef?.current?.scrollBy({
              left: 200,
              behavior: "smooth",
            })
          }
        >
          <NextButtonIcon />
        </button>
      </div>
    </section>
  );
};

export default TabNavigations;

const PrevButtonIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.849609"
      y="0.5"
      width="33.875"
      height="35"
      rx="2.5"
      fill="#00CC99"
      stroke="white"
    />
    <path
      d="M13.6425 17.2929C13.252 17.6834 13.252 18.3166 13.6425 18.7071L20.0065 25.0711C20.397 25.4616 21.0302 25.4616 21.4207 25.0711C21.8112 24.6805 21.8112 24.0474 21.4207 23.6569L15.7638 18L21.4207 12.3431C21.8112 11.9526 21.8112 11.3195 21.4207 10.9289C21.0302 10.5384 20.397 10.5384 20.0065 10.9289L13.6425 17.2929ZM15.3496 17L14.3496 17L14.3496 19L15.3496 19L15.3496 17Z"
      fill="white"
    />
  </svg>
);

const NextButtonIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="-0.5"
      y="0.5"
      width="33.875"
      height="35"
      rx="2.5"
      transform="matrix(-1 0 0 1 34.0996 0)"
      fill="#00CC99"
      stroke="white"
    />
    <path
      d="M21.8067 17.2929C22.1972 17.6834 22.1972 18.3166 21.8067 18.7071L15.4428 25.0711C15.0522 25.4616 14.4191 25.4616 14.0285 25.0711C13.638 24.6805 13.638 24.0474 14.0285 23.6569L19.6854 18L14.0285 12.3431C13.638 11.9526 13.638 11.3195 14.0285 10.9289C14.4191 10.5384 15.0522 10.5384 15.4428 10.9289L21.8067 17.2929ZM20.0996 17L21.0996 17L21.0996 19L20.0996 19L20.0996 17Z"
      fill="white"
    />
  </svg>
);
