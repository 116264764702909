import React from "react";
import FAQuestions from "./resources/Faqs";
import AskQuestions from "./resources/AskQuestions";
import BlogsArticles from "./resources/BlogsArticles";

const CourseResources: React.FC = () => {
  return (
    <div>
      <div className="bg-[#ECECEC] py-6">
        <div className="container mx-auto">
          <div className="flex flex-col lg:gap-8">
            <div className="flex lg:flex-row flex-col ">
              <div className="w-full lg:w-9/12 flex flex-col gap-12">
                <FAQuestions />
              </div>
              <div className="w-full lg:w-3/12">
                <div className="bg-[#fff] w-full h-full flex justify-center items-center"></div>
              </div>
            </div>
            {/* <div className="flex lg:flex-row flex-col gap-12 lg:gap-14 bg-white">
              <div className="w-full lg:w-3/4 flex flex-col gap-12">
                <AskQuestions /> 
              </div>
            </div>  */}
            <div className="flex lg:flex-row flex-col gap-12 lg:gap-14">
              <div className="w-full flex flex-col gap-12">
                <BlogsArticles />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseResources;
