import React from "react";
import HeroLogo from "../../../../../images/college/details/placements/Hero_logo.png";
import TcsLogo from "../../../../../images/college/details/placements/TCS-Logo.png";
import WiproLogo from "../../../../../images/college/details/placements/Wipro_logo.png";
const TopRecruiters: React.FC = () => {
  const clientLogos = [
    {
      name: "TCS",
      logoUrl: "https://logo.clearbit.com/tcs.com",
      imgLogo: TcsLogo,
    },
    {
      name: "Infosys",
      logoUrl: "https://logo.clearbit.com/infosys.com",
      imgLogo: WiproLogo,
    },
    {
      name: "Wipro",
      logoUrl: "https://logo.clearbit.com/wipro.com",
      imgLogo: HeroLogo,
    },
    {
      name: "Accenture",
      logoUrl: "https://logo.clearbit.com/accenture.com",
      imgLogo: TcsLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: TcsLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: HeroLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: WiproLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: WiproLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: TcsLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: TcsLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: WiproLogo,
    },
    {
      name: "IBM",
      logoUrl: "https://logo.clearbit.com/ibm.com",
      imgLogo: HeroLogo,
    },
  ];

  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-5 justify-center items-center">
          <h3 className="text-3xl font-bold">Top Recruiters</h3>
        </div>
        <div className="grid grid-cols-6 gap-8 justify-center items-center">
          {clientLogos.map((client, index) => (
            <div
              key={index}
              className="flex items-center justify-center w-[150px] h-[150px]"
            >
              <img
                src={client.imgLogo}
                alt={client.name}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopRecruiters;
