import React, { SVGProps, useState } from "react";
import "./SubscribeNow.scss";
import ApplyNowForm from "../applynow/ApplyNowForm";

const takeAFreeTestContent = [
  {
    title:
      "Discover Top 100 Trending  Dream Careers, Dream Courses, Dream Colleges",
    button: "Subscribe Now",
  },
];
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}

const SubscribeNow = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="subscribe-now-section bg-gradient-to-r from-blue-900 to-teal-500 flex py-8 my-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-row w-full">
          {takeAFreeTestContent.map((content, index) => (
            <div
              key={index}
              className="relative flex md:flex-row gap-4 justify-between items-center w-full"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className="magazine-box p-2">
                  <span className="text-xs text-white">
                    <strong>College Mentor</strong> <br /> Magazine PRO
                  </span>
                  <img src="/images/magazinePro.png" alt="" className="mb-2" />
                  <button
                    className="subscribe-btn mx-auto white-color flex flex-row items-center justify-center gap-2 sm:text-xs sm:w-48"
                    onClick={handlePopup} // Open popup on button click
                  >
                    {content.button}
                  </button>
                </div>
                <h3 className="subscribe-title text-sm md:text-lg lg:text-2xl ml-40 font-bold text-white m-0">
                  {content.title}
                </h3>
              </div>
              <div className="flex flex-col">
                <button
                  className="subscribe-now white-color btn-styles flex flex-row items-center justify-center gap-2 sm:text-xs sm:w-48 button-styles"
                  onClick={handlePopup} // Open popup on button click
                >
                  {content.button}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]">
          <div className="bg-[#F7F7F7] rounded shadow-lg w-1/2 relative">
            <ApplyNowForm />
            <button
              onClick={handlePopup}
              className="text-white absolute top-3 right-2"
            >
              <MaterialSymbolsLightClose />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscribeNow;
