import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/bundle";

import { Autoplay, Pagination } from "swiper/modules";
import collegeLogo from "../../../images/college/details/college-logo.png";
import { Chat } from "@mui/icons-material";
import ChatOptions from "./ChatOptions";
import bannerImges from "../../../images/college/details/free-time-students-1.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CollegeBannerImg = [
  {
    id: 1,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
  {
    id: 2,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
  {
    id: 3,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
  {
    id: 4,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
  {
    id: 5,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
  {
    id: 6,
    img: bannerImges,
    location: "Campuses, hyderapad,vishakapatnam",
  },
];
const embedUrl = `https://www.youtube.com/watch?v=LsorcIze6IM`;
const CollegeBanner = (collegeDetails: any) => {
  const { collegeOverview, collegeHighlights } =
    collegeDetails.collegeDetails.data;
  const [tabIndex, setTabIndex] = useState("video");
  const collegeId = collegeHighlights.college.collegeId;

  const handleTabSelect = (tab: string) => {
    setTabIndex(tab);
  };

  const getCollegeImage = (collegeId: any) => {
    switch (collegeId) {
      case 1484:
        return "/images/SRM.jpg";
      case 1485:
        return "/images/jnu_delhi.png";

      case 1486:
        return "/images/GITAM_School_of_Pharmacy_Hyderabad_Logo.png";

      case 1487:
        return "/images/iit-madras.png";

      case 1488:
        return "/images/Chaitanya-Bharathi-Institute-of-Technology-Hyderabad.jpg";

      default:
        return collegeLogo;
    }
  };

  return (
    <>
      <div className="college-banner flex flex-col bg-[#f1f1f1]">
        <div className="flex flex-row gap-6 lg:min-h-[527px]">
          <div className="w-full lg:w-[45%] flex flex-col min-h-full justify-between">
            <div className="px-20 py-12 flex justify-center flex-col items-center h-full justify-between">
              <div className="flex flex-col justify-center items-center gap-5">
                <img
                  src={getCollegeImage(collegeId)}
                  alt={`College ${collegeId}`}
                  className="w-20 h-20"
                />
                <h2 className="text-3xl font-bold">
                  {collegeOverview?.CollegeName}
                </h2>
                {/* <p className="text-center">
                  {collegeOverview?.collegeDescription
                    ? collegeOverview?.collegeDescription
                    : `Amet consectetur adipisicing elit.
                  Praesentium mollitia cum voluptate, iusto delectus, voluptatum
                  ipsam magni laboriosam sit quo saepe cupiditate, quod quos sed
                  distinctio laborum ullam? Molestiae, blanditiis!`}
                </p> */}
              </div>
              <div className="flex gap-3 my-2">
                <button
                  onClick={() => handleTabSelect("photos")}
                  className="bg-[#113cc0] py-2 px-4 rounded-lg text-white"
                >
                  Photos
                </button>
                <button
                  onClick={() => handleTabSelect("video")}
                  className="bg-[#113cc0] py-2 px-4 rounded-lg text-white"
                >
                  Video
                </button>
                <button
                  onClick={() => handleTabSelect("VR360")}
                  className="bg-gray-400 py-2 px-3 rounded-lg pointer-events-none"
                >
                  3VR 360
                </button>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[55%] flex justify-center items-center">
            {tabIndex === "photos" && (
              <div className="w-full h-full relative">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={1}
                  pagination={true}
                  modules={[Pagination, Autoplay]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  mousewheel={true}
                  keyboard={true}
                  loop={true}
                  className="h-full w-full flex flex-row justify-center items-center"
                >
                  {CollegeBannerImg.map((img) => (
                    <SwiperSlide key={img.id}>
                      <img src={img.img} alt="college banner" />
                      <div className="absolute bottom-10 left-10 bg-[#0ab38b] text-white text-lg flex py-4 px-6 rounded-full">
                        <p className="flex flex-row text-white  z-10">
                          <a
                            href="#Contact-us"
                            className="flex flex-row justify-center items-center gap-2"
                          >
                            {img.location}{" "}
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                                fill="white"
                              />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            {tabIndex === "video" && (
              <div className="video">
                <video
                  width="100%"
                  height="100%"
                  muted
                  autoPlay
                  playsInline
                  onCanPlay={(event) => {
                    event.currentTarget.muted = true;
                  }}
                  loop
                  poster="https://woxsen.edu.in/assets/images/main-banner.webp"
                >
                  <source
                    src="https://woxsen.edu.in/assets/images/woxsen-banner-video.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
            {tabIndex === "VR360" && (
              <div className="VR360">
                <iframe
                  width="560"
                  height="315"
                  src={embedUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>

      <ChatOptions applyLink={collegeHighlights.college.applyLink} />
    </>
  );
};

export default CollegeBanner;
