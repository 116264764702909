import ApplySmarter from "./components/ApplySmarter";
import BuildingBridges from "./components/BuildingBridges";
import FeaturedColleges from "./components/FeaturedColleges";
import CollegeBanner from "./components/CollegeBanner";
import StateWise from "./components/StateWise";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import About from "./components/About";
import WebinarAndArticles from "./components/WebinarAndArticles";
import ChooseCollegeMentor from "./components/ChooseCollegeMentor";
import WorldClassUni from "./components/WorldClassUni";
import Preference from "./components/Preference";
import Dreams from "./components/Dreams";
import FindPerfectCollege from "./components/FindPerfectCollege";
import axios from "axios";
import collegeLanding, { update } from "../../../store/slices/collegeLanding";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const CollegeLanding = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios({
      url: "https://mentormenteedev.com/api/college/landing-page",
      method: "GET",
    }).then((response) => {
      dispatch(update(response.data.data));
    });
  }, []);
  const featuredColleges = useSelector(
    (state: RootState) => (state.collegeLanding.featuredColleges )
  );
  return (
    <div className="font-inter">
      <CollegeBanner />
      <FeaturedColleges title={"Featured Colleges"} featuredColleges={featuredColleges}/>
      <BuildingBridges />
      <FindPerfectCollege />
      <ApplySmarter />
      <Preference />
      <ChooseCollegeMentor />
      <Dreams />
      <WorldClassUni />
      <StateWise />
      <WebinarAndArticles />
      <About />
    </div>
  );
};

export default CollegeLanding;
