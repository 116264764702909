import { Outlet } from "react-router-dom";
import {
  faBars,
  faChartLine,
  faCalendar,
  faMessage,
  faBell, faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapsibleSideBar from "./components/CollapsibleSideBar";
import MobileHeader from "./components/MobileHeader";
import Header from "./components/Header";
const isLargeDevice = window.innerWidth >= 1024;

const NAV_LINKS = [
  {
    name: "Profile",
    icon: <FontAwesomeIcon icon={faBars} size="xl" />,
    link: "my-profile",
  },
  {
    name: "Admissions",
    icon: <FontAwesomeIcon icon={faChartLine} size="xl" />,
    link: "college-admission",
  },
  {
    name: "Mentors",
    icon: <FontAwesomeIcon icon={faUserCircle} size="xl" />,
    link: "mentors",
  },
  {
    name: "Calendar",
    icon: <FontAwesomeIcon icon={faCalendar} size="xl" />,
    link: "dashboard",
  },
  {
    name: "Messages",
    icon: <FontAwesomeIcon icon={faMessage} size="xl" />,
    link: "dashboard",
  },
];

const DashboardLayout = () => {
  return (
    <div className="bg-[#eef2f5]">
           {!isLargeDevice && <Header />}

    <div className="flex max-lg:flex-col">
      {isLargeDevice && <CollapsibleSideBar navLinks={NAV_LINKS} />}
      {!isLargeDevice && <MobileHeader navLinks={NAV_LINKS} />}
      <div className="flex-1 bg-[#eef2f5]">
      {isLargeDevice && <Header />}
        <Outlet />
      </div>
      </div>
      </div>
  );
};

export default DashboardLayout;
