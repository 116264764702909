export const ArticlesAndBlogsData = [
  {
    id: 1,
    img: "/images/articles01.jpg",
    title: "Best LearnPress WordPress Theme Collection for 2023",
    publishDate: "Jan 24, 2024",
    description: "Best LearnPress WordPress Theme Collection for 2023",
  },
  {
    id: 2,
    img: "/images/articles02.jpg",
    title: "Best LearnPress WordPress Theme Collection for 2023",
    publishDate: "Jan 24, 2024",
    description: "Best LearnPress WordPress Theme Collection for 2023",
  },
  {
    id: 3,
    img: "/images/articles03.jpg",
    title: "Best LearnPress WordPress Theme Collection for 2023",
    publishDate: "Jan 24, 2024",
    description: "Best LearnPress WordPress Theme Collection for 2023",
  },
  {
    id: 4,
    img: "/images/articles03.jpg",
    title: "Best LearnPress WordPress Theme Collection for 2023",
    publishDate: "Jan 24, 2024",
    description: "Best LearnPress WordPress Theme Collection for 2023",
  },
];
