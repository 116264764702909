import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState ,useEffect} from "react";
import TabSelectorV1 from "../../../../components/TabSelectorV1";
import Accordion from "../../components/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import axios from "axios";
const KnowledgeHub = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [title, setTitle] = useState<string>('Engineering');
  const [datadynamic, setDatadynamic] = useState<any[]>([]); 

  const hubData = useSelector(
    (state: RootState) => state.predictorLanding.knowledgrHub
  );
  const categories = hubData?.categories ?? [];
  const fqas = hubData?.fqas ?? [];

  useEffect(() => {
    if (title) {
      axios({
        url: `https://mentormenteedev.com/api/college/streamLevelFqs/${title}`,
        method: 'GET',
      })
        .then((response) => {
          setDatadynamic(response.data.data || []); 
          // console.log("title",datadynamic[0]);
        })
        .catch((error) => {
          console.error('Failed to fetch data', error);
          setDatadynamic([]); 

        });
    }
  }, [title]);

  useEffect(() => {
    if (categories.length > 0) {
      setTitle(categories[activeTab]);
        console.log()
    }
  }, [activeTab, categories]);

  if (!hubData || categories.length === 0 || fqas.length === 0) {
    return;
  }

  const currentCategoryFAQs = fqas; 

  return (
    <section className="py-12 bg-[#FFF1FA] text-black">
      <div className="container flex flex-col gap-4">
        <h1 className="text-5xl">
          <span className="font-bold">Knowledge Hub</span> (FAQs)
        </h1>
        <TabSelectorV1
          tabList={categories} 
            
          
          activeTab={activeTab}
          changeActiveTab={(index) => setActiveTab(index)}
        />
          <div className="w-full md:w-4/5 grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-2">
          {
            
              datadynamic.map((item, index) => (
                <Accordion
                  key={index}
                  question={item.question}
                  answer={item.answer}
                />
              ))
            
          }
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
