import React, { useState } from "react";

interface TooltipProps {
  content: React.ReactNode; // Content to display inside the tooltip
  children: React.ReactNode; // The element that triggers the tooltip
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to show the tooltip
  const showTooltip = () => {
    setIsVisible(true);
  };

  // Function to hide the tooltip
  const hideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <div className="relative inline-block">
      {/* Tooltip Trigger Element */}
      <div
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="cursor-pointer"
      >
        {children}
      </div>

      {/* Tooltip Content */}
      {isVisible && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 p-4 bg-white border border-gray-200 rounded-lg shadow-lg z-10 w-72">
          {/* Tooltip Arrow */}
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full w-3 h-3 bg-white border-l border-t border-gray-200 rotate-45"></div>

          {/* Tooltip Content */}
          <div className="text-sm text-gray-700">{content}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
