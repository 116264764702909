// src/components/Quiz.js
import React, { useState } from "react";
import { quizData } from "./quizData.js";
import { useNavigate } from "react-router-dom";

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [savedForLater, setSavedForLater] = useState([]);
  const navigate = useNavigate();

  const handleAnswerClick = (index) => {
    const newAnswers = [...answers];
    if (newAnswers[currentQuestion] === index) {
      newAnswers[currentQuestion] = null;
    } else {
      newAnswers[currentQuestion] = index;
    }
    setAnswers(newAnswers);
  };

  const handleSaveForLater = () => {
    if (!savedForLater.includes(currentQuestion)) {
      setSavedForLater([...savedForLater, currentQuestion]);
    }
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    setCurrentQuestion((prev) => (prev + 1) % quizData.length);
  };

  const handleQuestionClick = (index) => {
    setCurrentQuestion(index);
  };

  const handleSubmit = () => {
    navigate("/courses/quiz-analysis", { state: { answers } });
  };

  const current = quizData[currentQuestion];

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-[#ffffff] md:bg-grey-3 relative translate-y-20 md:translate-y-0">
      <div className="max-w-3xl w-full bg-white p-8 rounded shadow">
        <h2 className="text-xl font-bold mb-4">{`${current.number}. ${current.question}`}</h2>
        <div className="flex flex-col space-y-2 mb-4">
          {current.choices.map((choice, index) => (
            <button
              key={index}
              onClick={() => handleAnswerClick(index)}
              className={`py-2 px-4 rounded ${
                answers[currentQuestion] === index
                  ? "bg-blue text-white"
                  : "bg-grey-3"
              }`}
            >
              {choice}
            </button>
          ))}
        </div>
        <div className="flex space-x-4">
          <button
            onClick={handleNextQuestion}
            className="bg-green text-white px-4 py-2 rounded"
          >
            Next Question
          </button>
          <button
            onClick={handleSaveForLater}
            className="bg-yellow text-white px-4 py-2 rounded"
          >
            Save for Later
          </button>
        </div>
      </div>
      <div className="absolute -top-12 md:top-48 md:right-2 p-4 bg-white rounded-lg shadow-lg">
        <h3 className="text-lg font-bold mb-2 text-center">Questions</h3>
        <div className="grid grid-cols-5 gap-2">
          {quizData.map((_, index) => (
            <button
              key={index}
              onClick={() => handleQuestionClick(index)}
              className={`w-12 h-12 flex items-center justify-center rounded-full ${
                currentQuestion === index
                  ? "bg-blue text-white"
                  : answers[index] !== undefined
                  ? "bg-green text-white"
                  : savedForLater.includes(index)
                  ? "bg-yellow text-white"
                  : "bg-grey-3"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {currentQuestion === quizData.length - 1 && (
        <button
          onClick={handleSubmit}
          className="mt-4 bg-red text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default Quiz;
