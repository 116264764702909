import React from "react";
import PersonalInfoForm from "./PersonalInfoForm";
import CurrentLevelStudy from "./CurrentLevelStudy";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CourseLookingFor from "./CourseLookingFor";
import CourseSpecialization from "./CourseSpecialization";

import ChooseDepartments from "./ChooseDepartments";
import PreferredState from "./PreferredState";
import "../Registration.scss";

import { motion, AnimatePresence } from "framer-motion";

const MultiStepForm = () => {
  const { step, direction } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return <PersonalInfoForm />;
      case 2:
        return <CurrentLevelStudy />;
      case 3:
        return <CourseLookingFor />;
      case 4:
        return <ChooseDepartments />;
      case 5:
        return <CourseSpecialization />;
      case 6:
        return <PreferredState />;
      default:
        return <PreferredState />;
    }
  };

  return (
    <div
      // className={`flex flex-col justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-xl`}
      className={`flex flex-col justify-center px-6 py-8 mx-auto lg:py-0`}
      style={{
        position: "relative",
        // overflow: "hidden",
        // width: "100%",
      }}
    >
      {/* <div
      className={`flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-2xl`}
    > */}

      {renderStep()}

      {/* <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          style={{ position: "absolute" }}
        >
          <div className="step-content"> {renderStep()}</div>
        </motion.div>
      </AnimatePresence> */}
    </div>
  );
};

export default MultiStepForm;
