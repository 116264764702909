const VideoModal = ({
  closeModal,
  link,
}: {
  closeModal: () => void;
  link: string;
}) => {
  return (
    <>
      <div className="fixed top-0 left-0 h-screen w-screen bg-black opacity-50 z-40"></div>
      <div
        className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50"
        onClick={closeModal}
      >
        <button className="absolute top-8 right-8" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            width={20}
            height={20}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div
          className="modal-content relative m-0  duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
          style={{
            animation: "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={link}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default VideoModal;
