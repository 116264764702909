import React, { useState } from "react";
import "./Preference.scss";
import ApplyNowForm from "../../../../components/applynow/ApplyNowForm";
import Modal from "../../../../components/Modal";

const questions = [
  {
    id: 1,
    text: "Is this school among your top 3 preferences?",
  },
  {
    id: 2,
    text: "Do you know it is among the top 10 schools in India's best schools?",
  },
];

export const LeadGenerationPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ApplyNowForm />
    </Modal>
  );
};

const Preference: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] = useState(false);

  const handleResponse = (response: "yes" | "no") => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      triggerLogin();
    }
  };

  const triggerLogin = () => {
    // Implement the login function here
    setLeadGenerationPopupIsOpen(true);
  };

  return (
    <div className="preference w-full relative pb-4">
      <div className="container flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 flex flex-col mt-8">
          {questions[currentQuestionIndex] && (
            <Question
              questionText={questions[currentQuestionIndex].text}
              onResponse={handleResponse}
            />
          )}
        </div>
        <InfoSection />
      </div>
      <LeadGenerationPopup
        isOpen={leadGenerationPopupIsOpen}
        onClose={() => setLeadGenerationPopupIsOpen(false)}
      />
    </div>
  );
};

export default Preference;

const InfoSection: React.FC = () => (
  <div className="w-full md:w-1/2 flex flex-col md:flex-row md:items-center mt-8 md:mt-4 md:ml-12 mb-8 md:mb-0">
    <img src="/images/College-landing/school.png" className="w-[180px] h-[160px]" alt="School" />
    <div className="flex flex-row items-center gap-4 md:items-start md:flex-col mt-8 md:ml-12 lg:ml-24">
      <div>
        <span className="number-in px-4 py-2 text-[#FFF]">169</span>
      </div>
      <p className="flex justify-start py-2 text-white font-sans text-sm font-semibold leading-normal">
        Parents/Guardians already share their opinion on this
      </p>
    </div>
  </div>
);

const Question: React.FC<{ questionText: string; onResponse: (response: "yes" | "no") => void; }> = ({ questionText, onResponse }) => (
  <div className="question-container">
    <p className="para-pref pr-12 md:pr-0">{questionText}</p>
    <div className="mt-4 gap-4">
      <button className="button-pref px-4 py-2" onClick={() => onResponse("yes")}>
        YES
      </button>
      <button className="button-pref px-4 py-2" onClick={() => onResponse("no")}>
        NO
      </button>
    </div>
    <p className="mt-2 parasub-pref">
      Submit your response to view opinions of other parents
    </p>
  </div>
);