import React from "react";

interface UniversityHighlightsProps {
  data:
    | {
        [key: string]: any;
      }
    | undefined;
}

const UniversityHighlights: React.FC<UniversityHighlightsProps> = ({
  data,
}) => {
  if (!data) return null;

  return (
    <div className="flex gap-4 flex-row justify-center items-start w-full">
      <div
        className="relative overflow-x-auto border boder-[#E0E0E0] rounded-3xl"
        style={{ boxShadow: "0px 10px 60px 0px #00000014" }}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-[#222222] text-base bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-6">
                Parameter
              </th>
              <th scope="col" className="p-6">
                Description
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-[#333333]">
            {Object.entries(data).map(([key, value]) => (
              <tr
                key={key}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {key}
                </th>
                <td className="px-6 py-4">
                  {typeof value === "object" ? JSON.stringify(value) : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CollegeUniversityHighlightsSection = (collegeDetails: any) => {
  const { collegeHighlights } =
    collegeDetails.collegeDetails.collegeDetails.collegeDetails.data;
  const collegeData =
    collegeDetails.collegeDetails.collegeDetails.collegeDetails.data;
  const universityHighlights = {
    Established: collegeHighlights.established,
    Exam: "JEE Advanced, GATE, CAT, and one more",
    Courses: collegeHighlights.courses ? collegeHighlights.courses : "-",
    "Institute Type": "Institute of National Importance",
    "Special Status": "Institute of Eminence",
    Approvals: collegeHighlights.approvals,
    Gender: "Co-ed",
    "Student Count": collegeHighlights.studentCount,
    "Faculty Count": collegeHighlights.facultyCount,
    "Campus Size": "650 acres",
    "Gender Percentage": "Male: 82%, Female: 18%",
    "Percentage Of Students from Outside the States": "84%",
  };

  return (
    <div className="tab-content py-16 bg-[#F4F7FB] relative" id="Highlights">
      <div className="flex justify-between items-center relative">
        <div className="container mx-auto">
          <div className="flex flex-col items-center gap-6 w-full">
            <div className="flex flex-row items-start">
              <h3 className="text-3xl font-extrabold text-[#231F40]">
                {collegeHighlights.college.collegeName} Highlights
              </h3>
            </div>
            <UniversityHighlights data={universityHighlights} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeUniversityHighlightsSection;
