import React from 'react'
import MentorHeroBanner from './Components/MentorHeroBanner'
import '../Mentor/Styles/MentorStyles.scss'
import ShapeYourFutureSection from './Components/ShapeYourFutureSection'
import QuizCard from './Components/QuizCard'
import Every60Seconds from './Components/Every60Seconds'
import ConnectWithDreams from './Components/ConnectWithDreams'
import SucessStories from './Components/SucessStories'
import WebinarsAndArticles from './Components/WebinarsAndArticles'
import FaqMentor from './Components/FaqMentor'
import DreamAndFeature from './Components/DreamAndFeature'
const MentorsMain = () => {
  return (
    <>
    <div>
    <MentorHeroBanner/>
    <ShapeYourFutureSection/>
    <QuizCard/>
    <Every60Seconds/>
    <ConnectWithDreams/>
    <SucessStories/>
    <WebinarsAndArticles/>
    <FaqMentor/>
    <DreamAndFeature/>
    </div>
    </>
  )
}

export default MentorsMain