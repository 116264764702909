import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const PAGE_SIZE = 5;

const usePagination = (pageSize: number = PAGE_SIZE) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [totalCount, setTotalCount] = useState(1);
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleNextPage = () => {
    setCurrentPage((prev) => {
      if (prev + 1 <= totalPages) return prev + 1;
      else return prev;
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => {
      if (prev - 1 > 0) return prev - 1;
      else return prev;
    });
  };

  const goToPage = (page: number) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const handleResetPagination = () => {
    if (currentPage != 1) setCurrentPage(1);
  };

  const updateTotalCount = (newTotalCount: number) =>
    setTotalCount(newTotalCount);

  return {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    goToPage,
    handleResetPagination,
  };
};

export default usePagination;
