import React, { useEffect, useState } from "react";
import TrendingTopics from "../components/TrendingTopics";
import BlogsBanner from "../components/BlogsAdBanner";
import BlogsBreadcrumb from "../components/BlogsBreadcrumb";
import Blog from "./Blog";
import RelatedBlogs from "./sections/RelatedBlogs";
import { GetWindowDimensions } from "../../../hooks/window-hooks/WindowHooks";
import BannerImage1 from "../../../images/blog-article/blogsAdBanner.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import addTopMechanicalEngineering from "../../../images/courses/top-mechanical-add.svg";
const BlogDetails = () => {
  const location = useLocation();
  let { category, id, topic } = useParams();

  const { width } = GetWindowDimensions();

  const navigate = useNavigate();

  const [blogResponse, setBlogResponse] = useState({});
  const [trendingTopic, setTrendingTopic] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [prevBlogId, setPrevBlogId] = useState(0);
  const [nextBlogId, setNextBlogId] = useState(0);
  //Page loading
  const [loading, setLoading] = useState<boolean>(true);

  // get Blog Information based on Blog / News Id
  const getBlogById = async () => {
    await axios
      .get(`https://mentormenteedev.com/api/blogs/blogDetails/${id}`)
      .then((response) => {
        console.log(response.data);
        setTrendingTopic(response?.data?.trendingTopics);
        setBlogResponse(response?.data?.blogDetails);
        setRelatedBlogs(response?.data?.blogRelated);
        setPrevBlogId(response?.data?.previousBlogId);
        setNextBlogId(response?.data?.nextBlogId);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    setLoading(true);
    getBlogById();
  }, [location]);

  if (loading) {
    return <Loader />;
  }

  const PrevButton = () => {
    return (
      <button
        onClick={() => navigate(`/blogs/${category}/${prevBlogId}/${topic}`)}
        disabled={!prevBlogId || prevBlogId == 0}
        className={`${
          !prevBlogId || prevBlogId == 0 ? "cursor-not-allowed opacity-30" : ""
        }`}
      >
        <LeftArrow />
      </button>
    );
  };

  const NextButton = () => {
    return (
      <button
        onClick={() => navigate(`/blogs/${category}/${nextBlogId}/${topic}`)}
        disabled={!nextBlogId || nextBlogId == 0}
        className={`${
          !nextBlogId || nextBlogId == 0 ? "cursor-not-allowed opacity-30" : ""
        }`}
      >
        <RightArrow />
      </button>
    );
  };

  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-8 sm:grid-cols-1 md:grid-cols-1 gap-y-4">
        {/* <div className="visible md:hidden sm:block">Friday, July 26, 2024</div> */}
        <TrendingTopics trendingTopics={trendingTopic} />
        <div className="hidden py-4 md:flex lg:flex">
          <div className="flex justify-center w-full py-4">
            <BlogsBanner width="" height="" image={BannerImage1} />
          </div>
        </div>
        <BlogsBreadcrumb />
        {/* <div className="grid grid-row">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-y-4 md:gap-4">
            <div className="grid col-span-3 gap-4 grid-row">
              <Blog />
            </div>
            <div className="p-4 bg-gray-100">Add Section</div>
          </div>
          <div>
            <h1 className="mt-6 mb-6 text-3xl font-semibold">
              More Content...
            </h1>
            <RelatedBlogs />
          </div>
        </div> */}
        {width > 700 ? (
          <div className="grid grid-cols-4 grid-rows gap-x-10">
            <div className="col-span-3 row-span-2">
              <Blog blogResponse={blogResponse} />
              <div className="mt-6 w-fit mx-auto flex gap-4">
                <PrevButton />
                <NextButton />
              </div>
            </div>

            {/* Right side Ad Section */}
            <div className="col-span-1 bg-gray-100 p-4 h-[auto]">
              <div className="course-quiz-box  bg-[#C7EAFB] pt-4">
                <div>
                  <img
                    src="/images/course-details/course-quiz-text.png"
                    alt="course quiz text"
                    className="mx-auto"
                  />
                </div>
                <div>
                  <img
                    src="/images/course-details/course-quiz.png"
                    alt="course quiz"
                    className=""
                  />
                </div>
                <div>
                  <Link
                    to={"/courses/quiz-modal"}
                    className="w-full h-12 bg-[#F6C] text-white justify-end text-xl font-extrabold"
                  >
                    Take Our Quiz
                  </Link>
                </div>
              </div>
              <div className="chat-with-mentor bg-[#FFEFB5] mt-5">
                <div>
                  <img
                    src="/images/course-details/logo.png"
                    alt="logo"
                    className=""
                  />
                </div>
                <div className="relative">
                  <img
                    src={addTopMechanicalEngineering}
                    alt="logo"
                    className=""
                  />

                  <a
                    href="/mentor"
                    className="mx-auto flex flex-row w-60 font-inter text-white text-xs sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-2"
                  >
                    <span>Chat with Buddy Mentor</span>
                    <img
                      src="/images/rightarrow.svg"
                      className="w-5 h-5 transition-transform duration-300 lg:w-8 lg:h-8 group-hover:translate-x-2"
                    />
                  </a>
                </div>
              </div>
            </div>

            {/* Right side Related Blog Section - Business Requirement */}

            <div className="col-span-1">
              <RelatedBlogs blogRelated={relatedBlogs} />
            </div>

            {/* Bottom Related Blogs Section`` */}

            {/* <div className="col-span-3">
              <h1 className="mt-6 mb-6 text-3xl font-semibold">
                More Content...
              </h1>
              <RelatedBlogs blogRelated={relatedBlogs} />
            </div> */}
          </div>
        ) : (
          <div className="grid grid-rows">
            <div className="">
              <Blog blogResponse={blogResponse} />
              <div className="my-6 w-fit mx-auto flex gap-4">
                <PrevButton />
                <NextButton />
              </div>
            </div>
            <div className="col-span-1 row-span-2 bg-gray-100 p-4 h-[auto]">
              <div className="course-quiz-box  bg-[#C7EAFB] pt-4">
                <div>
                  <img
                    src="/images/course-details/course-quiz-text.png"
                    alt="course quiz text"
                    className="mx-auto"
                  />
                </div>
                <div>
                  <img
                    src="/images/course-details/course-quiz.png"
                    alt="course quiz"
                    className=""
                  />
                </div>
                <div>
                  <Link
                    to={"/courses/quiz-modal"}
                    className="w-full h-12 bg-[#F6C] text-white justify-end text-xl font-extrabold"
                  >
                    Take Our Quiz
                  </Link>
                </div>
              </div>
              <div className="chat-with-mentor bg-[#FFEFB5] mt-5">
                <div>
                  <img
                    src="/images/course-details/logo.png"
                    alt="logo"
                    className=""
                  />
                </div>
                <div className="relative">
                  <img
                    src={addTopMechanicalEngineering}
                    alt="logo"
                    className=""
                  />
                  {/* <div className="absolute top-[85px] left-[75px] w-[75px] text-center">
                <p className="text-[14px] text-[#263238]">
                  Not Sure University is <br />
                  <strong>Right For You ?</strong>
                </p>
              </div> */}
                  <a
                    href="/mentor"
                    className="mx-auto flex flex-row w-60 font-inter text-white text-xs sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-2"
                  >
                    <span>Chat with Buddy Mentor</span>
                    <img
                      src="/images/rightarrow.svg"
                      className="w-5 h-5 transition-transform duration-300 lg:w-8 lg:h-8 group-hover:translate-x-2"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {width < 700 && (
        <div className="pb-4">
          <RelatedBlogs blogRelated={relatedBlogs} />
        </div>
      )}
    </>
  );
};

export default BlogDetails;

// Right Arrow SVG
const RightArrow = () => {
  return (
    <div className={`p-4 border rounded-full ${"bg-blue border-blue"}`}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${"fill-current text-white"}`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
        />
      </svg>
    </div>
  );
};

//Left Arrow SVG
const LeftArrow = () => {
  return (
    <div className="p-4 bg-white border border-black rounded-full hover:border-blue hover:bg-blue group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="text-black fill-current group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
        />
      </svg>
    </div>
  );
};
