export const CollegeAndRandPredictorData = [
  {
    id: 1,
    img: "/images/explore-rank-img.jpg",
    title: "Engineering Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
  },
  {
    id: 2,
    img: "/images/explore-rank-img.jpg",
    title: "Medical Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
  },
  {
    id: 3,
    img: "/images/explore-rank-img.jpg",
    title: "Law Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
  },
  {
    id: 4,
    img: "/images/explore-rank-img.jpg",
    title: "Design Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
  },
  {
    id: 5,
    img: "/images/explore-rank-img.jpg",
    title: "Medical Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
  },
];

export const AlsoIncludesData = [
  "All Test Series",
  "Prev. Year Paper",
  "Practice",
  "Pro Live Tests",
  "Unlimited Test Re-Attempts",
];

export const ExamFiltersData = {
  filters: [
    {
      categoryName: "DEGREES",
      categoryKey: "DEGREES",
      options: [
        {
          name: "B.E / B.Tech",
          key: "be_btech",
        },
        {
          name: "M.E / M.Tech",
          key: "me_mtech",
        },
        {
          name: "Diploma",
          key: "diploma",
        },
        {
          name: "MBA",
          key: "mba",
        },
        {
          name: "M.Sc",
          key: "msc",
        },
      ],
    },
    {
      categoryName: "MODES",
      categoryKey: "MODES",
      options: [
        {
          name: "Online",
          key: "online",
        },
        {
          name: "Offline",
          key: "offline",
        },
      ],
    },
    {
      categoryName: "EDUCATION LEVELS",
      categoryKey: "EDUCATION_LEVELS",
      options: [
        {
          name: "UG",
          key: "ug",
        },
        {
          name: "PG",
          key: "pg",
        },
        {
          name: "Diploma",
          key: "diploma",
        },
      ],
    },
    {
      categoryName: "EXAMINATION TYPES",
      categoryKey: "EXAMINATION_TYPES",
      options: [
        {
          name: "University Level Exam - UG",
          key: "university_level_exam_ug",
        },
        {
          name: "State Level Exam - UG",
          key: "state_level_exam_ug",
        },
        {
          name: "State Level Exam - Diploma",
          key: "state_level_exam_diploma",
        },
        {
          name: "National Level Exam - UG",
          key: "national_level_exam_ug",
        },
        {
          name: "University Level Exam - PG",
          key: "university_level_exam_pg",
        },
        {
          name: "State Level Exam - PG",
          key: "state_level_exam_pg",
        },
        {
          name: "National Level Exam - PG",
          key: "national_level_exam_pg",
        },
        {
          name: "National Level Exam - Diploma",
          key: "national_level_exam_diploma",
        },
      ],
    },
    {
      categoryName: "EXAM CATEGORIES",
      categoryKey: "EXAM_CATEGORIES",
      options: [
        {
          name: "College Entrance Exams",
          key: "college_entrance_exams",
        },
      ],
    },
    {
      categoryName: "SORT BY",
      categoryKey: "SORT_BY",
      options: [
        {
          name: "Popularity",
          key: "popularity",
        },
        {
          name: "Application Date",
          key: "application_date",
        },
        {
          name: "Exam Date",
          key: "exam_date",
        },
        {
          name: "Result Date",
          key: "result_date",
        },
        {
          name: "Upcoming Event",
          key: "upcoming_event",
        },
      ],
    },
    {
      categoryName: "LINGUISTIC MEDIUMS",
      categoryKey: "LINGUISTIC_MEDIUMS",
      options: [
        {
          name: "English",
          key: "english",
        },
        {
          name: "Hindi",
          key: "hindi",
        },
        {
          name: "Telugu",
          key: "telugu",
        },
        {
          name: "Urdu",
          key: "urdu",
        },
      ],
    },
  ],
  data: {
    totalCount: 625,
    exams: [
      {
        name: "JEE MAIN - Joint Entrance Examination (Main)",
        status: "Opened",
        mode: "Online",
        level: "National level exam",
        frequency: "2 Times/Year",
        application: {
          openDates: "05-12-2024 to 31-03-2025",
          cost: 1000,
        },
        examDates: "06-04-2025 to 10-04-2025 (Phase 1 Exam)",
      },
      {
        name: "TEST MAIN - Joint Entrance Examination (Main)",
        status: "Opened",
        mode: "Online",
        level: "National level exam",
        frequency: "2 Times/Year",
        application: {
          openDates: "05-12-2024 to 31-03-2025",
          cost: 1000,
        },
        examDates: "06-04-2025 to 10-04-2025 (Phase 1 Exam)",
      },
    ],
  },
};

export const FiltersData = {
  filters: [
    {
      categoryName: "SORT BY",
      categoryKey: "SORT_BY",
      options: [
        {
          name: "A to Z",
          key: "a_to_z",
        },
        {
          name: "Z to A",
          key: "z_to_a",
        },
        {
          name: "Featured Colleges",
          key: "featured_colleges",
        },
        {
          name: "Direct Admission",
          key: "direct_admission",
        },
      ],
    },
    {
      categoryName: "UNIVERSITIES",
      categoryKey: "UNIVERSITIES",
      options: [
        {
          name: "Central Universities",
          key: "central_universities",
        },
        {
          name: "Deemed Universities",
          key: "deemed_universities",
        },
        {
          name: "State Government Universities",
          key: "state_government_universities",
        },
        {
          name: "State Private Universities",
          key: "state_private_universities",
        },
        {
          name: "Autonomous Colleges",
          key: "autonomous_colleges",
        },
        {
          name: "Affiliated Colleges",
          key: "affiliated_colleges",
        },
      ],
    },
    {
      categoryName: "RANKING ACCREDITATIONS",
      categoryKey: "RANKING_ACCREDITATIONS",
      options: [
        {
          name: "NIRF Ranking",
          key: "nirf_ranking",
        },
        {
          name: "NAAC Grade",
          key: "naac_grade",
        },
        {
          name: "NBA Accreditation",
          key: "nba_accreditation",
        },
        {
          name: "Institutes of Eminence (IOE)",
          key: "institutes_of_eminence_ioe",
        },
        {
          name: "International Rankings",
          key: "international_rankings",
        },
        {
          name: "QS Ranking",
          key: "qs_ranking",
        },
        {
          name: "World Ranking",
          key: "world_ranking",
        },
        {
          name: "Asia Ranking",
          key: "asia_ranking",
        },
        {
          name: "Indian Colleges Ranking",
          key: "indian_colleges_ranking",
        },
        {
          name: "ARIIA Ranking",
          key: "ariia_ranking",
        },
        {
          name: "Times Higher Education Ranking",
          key: "times_higher_education_ranking",
        },
        {
          name: "Outlook Ranking",
          key: "outlook_ranking",
        },
        {
          name: "Career 360 Ranking",
          key: "career_360_ranking",
        },
      ],
    },
    {
      categoryName: "STATE/UT",
      categoryKey: "STATE_UT",
      options: [
        {
          name: "Andhra Pradesh",
          key: "andhra_pradesh",
        },
        {
          name: "Arunachal Pradesh",
          key: "arunachal_pradesh",
        },
        {
          name: "Assam",
          key: "assam",
        },
        {
          name: "Bihar",
          key: "bihar",
        },
        {
          name: "Chhattisgarh",
          key: "chhattisgarh",
        },
        {
          name: "Delhi",
          key: "delhi",
        },
        {
          name: "Goa",
          key: "goa",
        },
        {
          name: "Gujarat",
          key: "gujarat",
        },
      ],
    },
    {
      categoryName: "CITIES",
      categoryKey: "CITIES",
      options: [
        {
          name: "Mumbai",
          key: "mumbai",
        },
        {
          name: "Bengaluru",
          key: "bengaluru",
        },
        {
          name: "Hyderabad",
          key: "hyderabad",
        },
        {
          name: "Pune",
          key: "pune",
        },
        {
          name: "Chennai",
          key: "chennai",
        },
        {
          name: "Surat",
          key: "surat",
        },
      ],
    },
    {
      categoryName: "DEGREES",
      categoryKey: "DEGREES",
      options: [
        {
          name: "Diploma",
          key: "diploma",
        },
        {
          name: "BE/B-Tech",
          key: "be_btech",
        },
        {
          name: "ME/M-Tech",
          key: "me_mtech",
        },
        {
          name: "M.Tech Ph.D",
          key: "mtech_phd",
        },
        {
          name: "B-Arch",
          key: "b_arch",
        },
        {
          name: "M-Arch",
          key: "m_arch",
        },
        {
          name: "B-Tech + M-Tech (Integrated)",
          key: "btech_mtech_integrated",
        },
        {
          name: "B-Tech + MBA",
          key: "btech_mba",
        },
        {
          name: "BSc",
          key: "bsc",
        },
      ],
    },
    {
      categoryName: "ENTRANCE EXAMINATIONS",
      categoryKey: "ENTRANCE_EXAMINATIONS",
      options: [
        {
          name: "JEE Mains",
          key: "jee_mains",
        },
        {
          name: "JEE Advanced",
          key: "jee_advanced",
        },
        {
          name: "AP EAPCET",
          key: "ap_eapcet",
        },
        {
          name: "TS EAPCET",
          key: "ts_eapcet",
        },
        {
          name: "CUET",
          key: "cuet",
        },
      ],
    },
    {
      categoryName: "FEE RANGES",
      categoryKey: "FEE_RANGES",
      options: [
        {
          name: "\u003C 1 lac",
          key: "under_1_lac",
        },
        {
          name: "1 - 2 lac",
          key: "1_to_2_lac",
        },
        {
          name: "2 - 3 lac",
          key: "2_to_3_lac",
        },
        {
          name: "3 - 4 lac",
          key: "3_to_4_lac",
        },
        {
          name: "4 - 5 lac",
          key: "4_to_5_lac",
        },
      ],
    },
    {
      categoryName: "SCHOLARSHIPS",
      categoryKey: "SCHOLARSHIPS",
      options: [
        {
          name: "10%",
          key: "10_percent",
        },
        {
          name: "11 - 25%",
          key: "11_to_25_percent",
        },
        {
          name: "26 - 50%",
          key: "26_to_50_percent",
        },
        {
          name: "51 - 75%",
          key: "51_to_75_percent",
        },
        {
          name: "76 - 100%",
          key: "76_to_100_percent",
        },
      ],
    },
    {
      categoryName: "EDUCATION MODES",
      categoryKey: "EDUCATION_MODES",
      options: [
        {
          name: "Full time",
          key: "full_time",
        },
        {
          name: "Part-time",
          key: "part_time",
        },
        {
          name: "Online",
          key: "online",
        },
        {
          name: "Distance",
          key: "distance",
        },
        {
          name: "Hybrid",
          key: "hybrid",
        },
      ],
    },
    {
      categoryName: "GENDER DIVERSITY",
      categoryKey: "GENDER_DIVERSITY",
      options: [
        {
          name: "Co-Education",
          key: "co_education",
        },
        {
          name: "Girls",
          key: "girls",
        },
        {
          name: "Boys",
          key: "boys",
        },
      ],
    },
    {
      categoryName: "MINORITY AFFILIATIONS",
      categoryKey: "MINORITY_AFFILIATIONS",
      options: [
        {
          name: "Muslim",
          key: "muslim",
        },
        {
          name: "Christain",
          key: "christian",
        },
        {
          name: "Jain",
          key: "jain",
        },
        {
          name: "Sikh",
          key: "sikh",
        },
        {
          name: "Parsi",
          key: "parsi",
        },
      ],
    },
  ],
  data: [
    {
      college_ranking: "#NIRF 38 College in India",
      college_name: "SRM Institute of Science and Technology, Madras",
      location: "Chennai, Tamil Nadu",
      type: "Private",
      details: {
        courses: ["B-Tech", "M-Tech", "MBA", "B.S", "M.A", "PH.D"],
        exams: ["JEE Advanced", "2"],
      },
      actions: [
        "Admission & Eligibility",
        "Placement",
        "Facilities",
        "Compare",
        "Request Campus Visit",
        "Virtual Tour",
        "Apply Now",
      ],
      brochure: "Download link",
      additional_info: {
        accreditations: ["NAAC A++", "ABET", "NBA", "ICAR"],
        nirf: {
          overall: 32,
          university: 18,
          engineering: 28,
        },
        qs_ranking: {
          world: "1000 - 1200",
          asia: 162,
          india: 17,
        },
        times_higher_education: {
          world: "1201 - 1500",
          asia: "401 - 500",
        },
        shanghai_ranking: {
          world: "801 - 900",
          india: "9 - 12",
        },
      },
    },
    {
      college_ranking: "#NIRF 38 College in India",
      college_name: "SRM Institute of Science and Technology, Madras",
      location: "Chennai, Tamil Nadu",
      type: "Private",
      details: {
        courses: ["B-Tech", "M-Tech", "MBA", "B.S", "M.A", "PH.D"],
        exams: ["JEE Advanced", "+2"],
      },
      actions: [
        {
          name: "Admission & Eligibility",
          link: "https://example.com/admission-eligibility",
        },
        {
          name: "Placement",
          link: "https://example.com/placement",
        },
        {
          name: "Facilities",
          link: "https://example.com/facilities",
        },
        {
          name: "Compare",
          link: "https://example.com/compare",
        },
        {
          name: "Request Campus Visit",
          link: "https://example.com/request-campus-visit",
        },
        {
          name: "Virtual Tour",
          link: "https://example.com/virtual-tour",
        },
        {
          name: "Apply Now",
          link: "https://example.com/apply-now",
        },
      ],
      brochure: "Download link",
      placeholders_info: {
        accreditations: ["NAAC A++", "ABET", "NBA", "ICAR"],
        nirf: {
          overall: 32,
          university: 18,
          engineering: 28,
        },
        qs_ranking: {
          world: "1000 - 1200",
          asia: 162,
          india: 17,
        },
        times_higher_education: {
          world: "1201 - 1500",
          asia: "401 - 500",
        },
        shanghai_ranking: {
          world: "801 - 900",
          india: "9 - 12",
        },
      },
    },
  ],
};
