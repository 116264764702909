// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-heading {
  color: #383838;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-heading {
  color: #383838;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.result-para {
  color: #383838;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

/* ServicesList.css */
.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}
.service-item {
  color: #0C9;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding-right: 20px;
  padding-top: 5px;
}

.download-brochure {
  color: #FFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor-result/Cards/ResultsGuidance.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EAEJ,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAA;;AAGA;EACI,cAAA;EAEJ,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADA;;AAIA;EAEI,cAAA;EAEJ,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAHA;;AAMA,qBAAA;AACA;EACI,aAAA;EACA,qCAAA;AAHJ;;AAKE;EACE;IACE,0BAAA;EAFJ;AACF;AAIE;EACE,WAAA;EAEJ,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAHA;;AAKE;EACE,WAAA;EAEJ,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHA","sourcesContent":[".result-heading{\n    color: #383838;\n\nfont-family: Roboto;\nfont-size: 24px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n}\n\n.sub-heading{\n    color: #383838;\n\nfont-family: Roboto;\nfont-size: 24px;\nfont-style: normal;\nfont-weight: 300;\nline-height: normal;\n}\n\n.result-para{\n\n    color: #383838;\n\nfont-family: Roboto;\nfont-size: 18px;\nfont-style: normal;\nfont-weight: 300;\nline-height: normal;\n}\n\n/* ServicesList.css */\n.services-grid {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr); \n  }\n  @media (max-width: 768px) {\n    .services-grid {\n      grid-template-columns: 1fr; \n    }\n  }\n  .service-item {\n    color: #0C9;\n\ntext-align: center;\nfont-family: \"Open Sans\";\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\npadding-right: 20px;\npadding-top: 5px;\n  }\n  .download-brochure{\n    color: #FFF;\n\ntext-align: center;\nfont-family: \"Open Sans\";\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 21px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
