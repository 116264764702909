// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carrerInCourse .swiper-pagination {
  position: relative;
  top: -20px;
}
.carrerInCourse .swiper-pagination .swiper-pagination-bullet {
  position: relative;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/course-details/careers/CareersModule.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,UAAA;AAAJ;AACI;EACE,kBAAA;EACA,WAAA;AACN","sourcesContent":[".carrerInCourse {\n  .swiper-pagination {\n    position: relative;\n    top: -20px;\n    .swiper-pagination-bullet {\n      position: relative;\n      z-index: 10;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
