import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterCategory } from "../../components/filter/FilterEnum";

interface IFilter {
  filterData: {
    [categoryKey: string]: {
      name: string;
      type: FilterCategory;
      options: { key: string; name: string }[];
      selected: string[];
    };
  };
}

const initialState: IFilter = {
  filterData: {},
};

const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    addNewFilter: (
      state,
      action: PayloadAction<{
        categoryKey: string;
        categoryName: string;
        type: FilterCategory;
        options: { key: string; name: string }[];
      }>
    ) => {
      const newFilter = action.payload;
      state.filterData = {
        ...state.filterData,
        [newFilter.categoryKey]: {
          name: newFilter.categoryName,
          type: newFilter.type,
          options: newFilter.options,
          selected: [],
        },
      };
    },
    changeFilterState: (
      state,
      action: PayloadAction<{ categoryKey: string; optionKey: string }>
    ) => {
      const category = action.payload.categoryKey;
      const option = action.payload.optionKey;
      const type = state.filterData[category].type;
      const selected = state.filterData[category].selected;

      switch (type) {
        case FilterCategory.MultiSelect:
          if (selected.includes(option))
            state.filterData[category].selected = selected.filter(
              (selectedOption) => selectedOption != option
            );
          else state.filterData[category].selected.push(option);
      }
    },
    clearAllSelectedFilters: (state) => {
      Object.entries(state.filterData).forEach(([_, filterDataItem]) => {
        filterDataItem.selected = [];
      });
    },
  },
});

export const { addNewFilter, changeFilterState, clearAllSelectedFilters } =
  filterSlice.actions;

export default filterSlice.reducer;
