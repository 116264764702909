import React, { Fragment } from "react";
import RegistrationLayout from "../registration/components/common/RegistrationLayout/RegistrationLayout";
import SignInForm from "./SignInForm";
const Login: React.FC = () => {
  return (
    <Fragment>
      <RegistrationLayout>
        <SignInForm />
      </RegistrationLayout>
    </Fragment>
  );
};

export default Login;
