import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isPhoneValid } from "../../util/FormValidator";
import { MuiPhone } from "./MuiPhone";

function ForgotEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  // const [submitted, setSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhone, setValidPhone] = useState(false);

  useEffect(() => {
    setPhoneNumber(state?.phoneNumber || "");
  }, [state]);

  const handleChangePhoneNum = (value: string) => {
    setPhoneNumber(value);
    setValidPhone(isPhoneValid(value));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let isValidPhoneNum = isPhoneValid(phoneNumber);

    if (isValidPhoneNum) {
      navigate("/otp-verification", { state: { phoneNumber } });
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      {/* <CssBaseline /> */}
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5">Forgot Email?</Typography>
        <Box>
          Enter the email address we’ll send you code to reset your password.
        </Box>
        <Box sx={{ mt: 1, width: "100%" }}>
          <MuiPhone value={phoneNumber} onChange={handleChangePhoneNum} />
          {!isValidPhone && (
            <div style={{ color: "red" }}>Phone is not valid</div>
          )}

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ForgotEmail;
