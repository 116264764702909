import { Outlet } from "react-router-dom";
// import Header from "./Header";
import Footer from "./footer/Footer";
import ContactBar from "./contact-bar/ContactBar";
import NavBar from "./nav-bar/NavBar";
import ScrollToTop from "./ScrollToTop";

const Layout = () => {
  return (
    <>
      {/* <Header /> */}
      <NavBar />
      <ContactBar />
      
      <Outlet />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Layout;
