import React from "react";
import OverviewSection from "./courseFees/OverviewSection";
import { Web } from "@mui/icons-material";
import WebinarEvents from "./courseFees/WebinarEvents";
import Faculty from "./courseFees/Faculty";
import FaqCollegesection from "./courseFees/FaqCollegesection";

import LikelyCollege from "./courseFees/LikelyCollege";
import TopPreferences from "./courseFees/TopPreferences";
import Programs from "./courseFees/Programs";

const CourseFeesFacultyTab = ({}) => {
  return (
    <div>
      <OverviewSection />
      <Programs />
      <TopPreferences />
      <WebinarEvents />
      <LikelyCollege />
      <FaqCollegesection />
      <Faculty />
    </div>
  );
};

export default CourseFeesFacultyTab;
