// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-card-main {
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}
.image-card-main .city-btn-section {
  margin-top: 14px;
}

.image-card-main:hover {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
}`, "",{"version":3,"sources":["webpack://./src/components/image-card/ImageCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,gDAAA;AACF","sourcesContent":[".image-card-main {\n  padding: 16px;\n  flex-direction: column;\n  align-items: center;\n  gap: 14px;\n  .city-btn-section {\n    margin-top: 14px;\n  }\n}\n\n.image-card-main:hover {\n  border-radius: 16px;\n  background: #fff;\n  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
