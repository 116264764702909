import React from "react";

export default function CareerTypesTab() {
  return (
    <div className="career-types-tab my-10">
      <div className="container mx-auto">
        <div className="flex flex-col items-start">
          <div className="self-stretch w-full max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                  <div className="flex flex-col p-6 text-sm font-semibold bg-white rounded-3xl shadow-2xl text-neutral-500 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-4 pr-20 text-xl text-zinc-800 max-md:flex-wrap max-md:pr-5">
                      <div className="w-[60px] h-[60px] bg-[#F2994A] rounded-lg content-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          className="mx-auto"
                        >
                          <path
                            d="M10.8627 20.5309L8.4834 22.9101L10.8627 25.2894L12.1056 24.0465L10.9693 22.9101L12.1056 21.7738L10.8627 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M19.1374 20.5309L17.8945 21.7738L19.0308 22.9101L17.8945 24.0465L19.1374 25.2894L21.5167 22.9101L19.1374 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M26.4258 28.2422V15.8789H24.668V14.9414C24.668 12.5183 22.6966 10.5469 20.2734 10.5469H15H9.72656C7.30342 10.5469 5.33203 12.5183 5.33203 14.9414V15.8789H3.57422V28.2422H1.81641V30H28.1836V28.2422H26.4258ZM7.08984 14.9414C7.08984 13.4875 8.27268 12.3047 9.72656 12.3047H20.2734C21.7273 12.3047 22.9102 13.4875 22.9102 14.9414V15.8789H7.08984V14.9414ZM5.33203 17.6367H24.668V28.2422H5.33203V17.6367Z"
                            fill="white"
                          />
                          <path
                            d="M12.4443 26.0331L15.9583 19.0053L17.5298 19.791L14.0158 26.8189L12.4443 26.0331Z"
                            fill="white"
                          />
                          <path
                            d="M20.2734 5.27344C20.2734 2.36566 17.9078 0 15 0C12.0922 0 9.72656 2.36566 9.72656 5.27344C9.72656 8.18121 12.0922 10.5469 15 10.5469C17.9078 10.5469 20.2734 8.18121 20.2734 5.27344ZM15 8.78906C13.0615 8.78906 11.4844 7.21195 11.4844 5.27344C11.4844 3.33492 13.0615 1.75781 15 1.75781C16.9385 1.75781 18.5156 3.33492 18.5156 5.27344C18.5156 7.21195 16.9385 8.78906 15 8.78906Z"
                            fill="white"
                          />
                        </svg>
                      </div>

                      <div className="flex gap-2 my-auto">
                        <div>Software Architect</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0035 10C21.5558 10 22.0035 9.55228 22.0035 9V3C22.0035 2.44772 21.5558 2 21.0035 2H14.9999C14.4476 2 13.9999 2.44771 13.9999 3C13.9999 3.55228 14.4476 4 14.9999 4H18.5895L9.98283 12.6066C9.59231 12.9971 9.59231 13.6303 9.98283 14.0208C10.3734 14.4114 11.0065 14.4114 11.397 14.0208L20.0035 5.41437V9C20.0035 9.55228 20.4512 10 21.0035 10ZM5 5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H16C17.6569 22 19 20.6569 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20H5C4.44772 20 4 19.5523 4 19V8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5Z"
                            fill="#003FBC"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-2.5 max-md:max-w-full">
                      Software Engineering{" "}
                      <span className=" text-neutral-500">
                        is concerned with developing techniques for improving
                        the cost, accuracy and usability of software systems.
                      </span>
                    </div>
                    <div className="mt-2.5 max-md:max-w-full">
                      Computer Science Engineering{" "}
                      <span className=" text-neutral-500">
                        is concerned with designing elements that help computers
                        to operate efficiently. They may develop computer chips,
                        create software programme that helps computer systems
                        run of design software applications.
                      </span>
                    </div>
                    <div className="mt-2.5 max-md:max-w-full">
                      Information Technology (IT){" "}
                      <span className=" text-neutral-500">
                        is an engineering discipline with focus on utilisation
                        of computers and telecommunication systems to control,
                        gather, store and circulate information.
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col p-6 mt-5 bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-4 pr-20 text-xl font-semibold text-zinc-800 max-md:flex-wrap max-md:pr-5">
                      <div className="w-[60px] h-[60px]  rounded-lg content-center bg-[#27AE60]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          className="mx-auto"
                        >
                          <path
                            d="M10.8627 20.5309L8.4834 22.9101L10.8627 25.2894L12.1056 24.0465L10.9693 22.9101L12.1056 21.7738L10.8627 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M19.1374 20.5309L17.8945 21.7738L19.0308 22.9101L17.8945 24.0465L19.1374 25.2894L21.5167 22.9101L19.1374 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M26.4258 28.2422V15.8789H24.668V14.9414C24.668 12.5183 22.6966 10.5469 20.2734 10.5469H15H9.72656C7.30342 10.5469 5.33203 12.5183 5.33203 14.9414V15.8789H3.57422V28.2422H1.81641V30H28.1836V28.2422H26.4258ZM7.08984 14.9414C7.08984 13.4875 8.27268 12.3047 9.72656 12.3047H20.2734C21.7273 12.3047 22.9102 13.4875 22.9102 14.9414V15.8789H7.08984V14.9414ZM5.33203 17.6367H24.668V28.2422H5.33203V17.6367Z"
                            fill="white"
                          />
                          <path
                            d="M12.4443 26.0331L15.9583 19.0053L17.5298 19.791L14.0158 26.8189L12.4443 26.0331Z"
                            fill="white"
                          />
                          <path
                            d="M20.2734 5.27344C20.2734 2.36566 17.9078 0 15 0C12.0922 0 9.72656 2.36566 9.72656 5.27344C9.72656 8.18121 12.0922 10.5469 15 10.5469C17.9078 10.5469 20.2734 8.18121 20.2734 5.27344ZM15 8.78906C13.0615 8.78906 11.4844 7.21195 11.4844 5.27344C11.4844 3.33492 13.0615 1.75781 15 1.75781C16.9385 1.75781 18.5156 3.33492 18.5156 5.27344C18.5156 7.21195 16.9385 8.78906 15 8.78906Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex gap-2 my-auto">
                        <div>AI/ML Engineer</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0035 10C21.5558 10 22.0035 9.55228 22.0035 9V3C22.0035 2.44772 21.5558 2 21.0035 2H14.9999C14.4476 2 13.9999 2.44771 13.9999 3C13.9999 3.55228 14.4476 4 14.9999 4H18.5895L9.98283 12.6066C9.59231 12.9971 9.59231 13.6303 9.98283 14.0208C10.3734 14.4114 11.0065 14.4114 11.397 14.0208L20.0035 5.41437V9C20.0035 9.55228 20.4512 10 21.0035 10ZM5 5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H16C17.6569 22 19 20.6569 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20H5C4.44772 20 4 19.5523 4 19V8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5Z"
                            fill="#003FBC"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-2.5 text-sm font-medium text-neutral-500 max-md:max-w-full">
                      Artificial Intelligence (AI) is a branch of computer
                      science that creates intelligent machines capable of
                      thinking, behaving and even making decisions like humans
                      do. The essence of Artificial Intelligence is that it can
                      simulate thought and action based on its own algorithms -
                      it does not need to be programmed to deal with a
                      situation, but manages to do so on its own. Machine
                      Learning is a subfield of artificial intelligence, which
                      enables machines to learn from past data or experiences
                      without being explicitly programmed. It enables computer
                      systems to make predictions using historical data. It uses
                      a massive amount of structured and semi-structured data so
                      that a machine learning model can generate accurate
                      results or give predictions based on that data
                    </div>
                  </div>
                  <div className="flex flex-col p-6 mt-5 max-w-full bg-white rounded-3xl shadow-2xl  max-md:px-5">
                    <div className="flex gap-4 pr-20 text-xl font-semibold text-zinc-800 max-md:flex-wrap max-md:pr-5">
                      <div className="w-[60px] h-[60px]  rounded-lg text-center content-center bg-[#2F80ED]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          className="mx-auto"
                        >
                          <path
                            d="M10.8627 20.5309L8.4834 22.9101L10.8627 25.2894L12.1056 24.0465L10.9693 22.9101L12.1056 21.7738L10.8627 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M19.1374 20.5309L17.8945 21.7738L19.0308 22.9101L17.8945 24.0465L19.1374 25.2894L21.5167 22.9101L19.1374 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M26.4258 28.2422V15.8789H24.668V14.9414C24.668 12.5183 22.6966 10.5469 20.2734 10.5469H15H9.72656C7.30342 10.5469 5.33203 12.5183 5.33203 14.9414V15.8789H3.57422V28.2422H1.81641V30H28.1836V28.2422H26.4258ZM7.08984 14.9414C7.08984 13.4875 8.27268 12.3047 9.72656 12.3047H20.2734C21.7273 12.3047 22.9102 13.4875 22.9102 14.9414V15.8789H7.08984V14.9414ZM5.33203 17.6367H24.668V28.2422H5.33203V17.6367Z"
                            fill="white"
                          />
                          <path
                            d="M12.4443 26.0331L15.9583 19.0053L17.5298 19.791L14.0158 26.8189L12.4443 26.0331Z"
                            fill="white"
                          />
                          <path
                            d="M20.2734 5.27344C20.2734 2.36566 17.9078 0 15 0C12.0922 0 9.72656 2.36566 9.72656 5.27344C9.72656 8.18121 12.0922 10.5469 15 10.5469C17.9078 10.5469 20.2734 8.18121 20.2734 5.27344ZM15 8.78906C13.0615 8.78906 11.4844 7.21195 11.4844 5.27344C11.4844 3.33492 13.0615 1.75781 15 1.75781C16.9385 1.75781 18.5156 3.33492 18.5156 5.27344C18.5156 7.21195 16.9385 8.78906 15 8.78906Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex gap-2 my-auto">
                        <div>Game Developer</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0035 10C21.5558 10 22.0035 9.55228 22.0035 9V3C22.0035 2.44772 21.5558 2 21.0035 2H14.9999C14.4476 2 13.9999 2.44771 13.9999 3C13.9999 3.55228 14.4476 4 14.9999 4H18.5895L9.98283 12.6066C9.59231 12.9971 9.59231 13.6303 9.98283 14.0208C10.3734 14.4114 11.0065 14.4114 11.397 14.0208L20.0035 5.41437V9C20.0035 9.55228 20.4512 10 21.0035 10ZM5 5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H16C17.6569 22 19 20.6569 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20H5C4.44772 20 4 19.5523 4 19V8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5Z"
                            fill="#003FBC"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-2.5 text-sm font-medium text-neutral-500 max-md:max-w-full">
                      Game development as a career is about developing and
                      designing games which everyone plays on their laptop,
                      mobile phones etc. It is a profession which involves hard
                      work, dedication and passion for games. Making games is
                      technical and creative work, and in this career, you will
                      understand the technicality involved in the creation of a
                      game. There is an increasing demand of games as there is
                      an increasing development of gadgets, hence Game
                      Development is a promising career option. Game developers
                      can work as individuals or in an organization which is
                      involved in developing games for various gadgets. The
                      students of game development study about the technical
                      steps, policies, developing creative games etc.
                      Proficiency in C++, JAVA will be of an advantage in this
                      career. Game developers make the core features of the
                      video games which include conducting design reviews,
                      designing role play mechanics, coding and programing etc.
                      Various paths are available for an individual to become a
                      game developer that involve studying computer science
                      engineering, animation and graphic designing etc.
                    </div>
                  </div>
                  <div className="flex flex-col p-6 mt-5 max-w-full bg-white rounded-3xl shadow-2xl  max-md:px-5">
                    <div className="flex gap-4 pr-20 text-xl font-semibold text-zinc-800 max-md:flex-wrap max-md:pr-5">
                      <div className="w-[60px] h-[60px]  rounded-lg content-center bg-[#BB6BD9]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          className="mx-auto"
                        >
                          <path
                            d="M10.8627 20.5309L8.4834 22.9101L10.8627 25.2894L12.1056 24.0465L10.9693 22.9101L12.1056 21.7738L10.8627 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M19.1374 20.5309L17.8945 21.7738L19.0308 22.9101L17.8945 24.0465L19.1374 25.2894L21.5167 22.9101L19.1374 20.5309Z"
                            fill="white"
                          />
                          <path
                            d="M26.4258 28.2422V15.8789H24.668V14.9414C24.668 12.5183 22.6966 10.5469 20.2734 10.5469H15H9.72656C7.30342 10.5469 5.33203 12.5183 5.33203 14.9414V15.8789H3.57422V28.2422H1.81641V30H28.1836V28.2422H26.4258ZM7.08984 14.9414C7.08984 13.4875 8.27268 12.3047 9.72656 12.3047H20.2734C21.7273 12.3047 22.9102 13.4875 22.9102 14.9414V15.8789H7.08984V14.9414ZM5.33203 17.6367H24.668V28.2422H5.33203V17.6367Z"
                            fill="white"
                          />
                          <path
                            d="M12.4443 26.0331L15.9583 19.0053L17.5298 19.791L14.0158 26.8189L12.4443 26.0331Z"
                            fill="white"
                          />
                          <path
                            d="M20.2734 5.27344C20.2734 2.36566 17.9078 0 15 0C12.0922 0 9.72656 2.36566 9.72656 5.27344C9.72656 8.18121 12.0922 10.5469 15 10.5469C17.9078 10.5469 20.2734 8.18121 20.2734 5.27344ZM15 8.78906C13.0615 8.78906 11.4844 7.21195 11.4844 5.27344C11.4844 3.33492 13.0615 1.75781 15 1.75781C16.9385 1.75781 18.5156 3.33492 18.5156 5.27344C18.5156 7.21195 16.9385 8.78906 15 8.78906Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex gap-2 my-auto">
                        <div>UI/UX Designer</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0035 10C21.5558 10 22.0035 9.55228 22.0035 9V3C22.0035 2.44772 21.5558 2 21.0035 2H14.9999C14.4476 2 13.9999 2.44771 13.9999 3C13.9999 3.55228 14.4476 4 14.9999 4H18.5895L9.98283 12.6066C9.59231 12.9971 9.59231 13.6303 9.98283 14.0208C10.3734 14.4114 11.0065 14.4114 11.397 14.0208L20.0035 5.41437V9C20.0035 9.55228 20.4512 10 21.0035 10ZM5 5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H16C17.6569 22 19 20.6569 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20H5C4.44772 20 4 19.5523 4 19V8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5Z"
                            fill="#003FBC"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-2.5 text-sm font-medium text-neutral-500 max-md:max-w-full">
                      Product design is a subset of Industrial design. They use
                      their designing skills and technical knowledge to design
                      new products or improve the way that existing product look
                      or work. Product designer designs product keeping in mind
                      the consumer market and are involved in the whole product
                      design life cycle, from concept to its design delivery.
                      <br />
                      Product design is a subset of Industrial design. They use
                      their designing skills and technical knowledge to design
                      new products or improve the way that existing product look
                      or work. Product designer designs product keeping in mind
                      the consumer market and are involved in the whole product
                      design life cycle, from concept to its design delivery.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-xs font-semibold max-md:mt-6">
                  <div className="flex flex-col justify-center p-4 w-full bg-white rounded-3xl shadow-2xl">
                    <div className="flex gap-4">
                      <div className="w-[60px] h-[60px] bg-[#F2994A] rounded-lg text-center">
                        <img
                          loading="lazy"
                          src="/images/iit-madras.png"
                          alt=""
                          className="shrink-0 self-start aspect-square w-[92px]"
                        />
                      </div>
                      <div className="flex flex-col flex-1">
                        <div className="text-sm text-zinc-800">IIT Madras</div>
                        <div className="mt-1 text-neutral-600">
                          IIT Madras is an institute of National Importance that
                          was established in the year 1959.
                        </div>
                        <div className="justify-center px-5 py-2.5 mt-1 text-white bg-blue-800 rounded-[50px]">
                          Apply Now
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center p-4 mt-4 w-full bg-white rounded-3xl shadow-2xl">
                    <div className="flex gap-4">
                      <img
                        loading="lazy"
                        src="/images/vit-vellore.png"
                        alt="vit vellore"
                        className="shrink-0 self-start aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1">
                        <div className="text-sm text-zinc-800">VIT Vellore</div>
                        <div className="mt-1 text-neutral-600">
                          Vellore Institute of Technology (VIT), Vellore is a
                          private deemed university established in 2001.
                        </div>
                        <div className="justify-center px-5 py-2.5 mt-1 text-white bg-blue-800 rounded-[50px]">
                          Apply Now
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center p-4 mt-4 w-full bg-white rounded-3xl shadow-2xl">
                    <div className="flex gap-4">
                      <img
                        loading="lazy"
                        src="/images/thapar-university.png"
                        alt="thapar university"
                        className="shrink-0 self-start aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1">
                        <div className="text-sm text-zinc-800">
                          Thapar University
                        </div>
                        <div className="mt-1 text-neutral-600">
                          Established in the year 1956, Thapar University is a
                          private university, which is also known as TIET
                          Patiala.
                        </div>
                        <div className="justify-center px-5 py-2.5 mt-1 text-white bg-blue-800 rounded-[50px]">
                          Apply Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
