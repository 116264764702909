// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #ffffff;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 12px;
  border: solid #00b489;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/screens/colleges/components/Accordion.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,qBAAA;EACA,yBAAA;EACA,wBAAA;AACF","sourcesContent":[".custom-checkbox {\n  appearance: none;\n  width: 18px;\n  height: 18px;\n  border: 1px solid #ffffff;\n  border-radius: 3px;\n  display: inline-block;\n  position: relative;\n  cursor: pointer;\n}\n\n.custom-checkbox:checked {\n  background-color: #ffffff;\n}\n\n.custom-checkbox:checked::after {\n  content: \"\";\n  position: absolute;\n  top: 0px;\n  left: 5px;\n  width: 7px;\n  height: 12px;\n  border: solid #00b489;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
