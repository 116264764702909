import React, { ReactNode } from "react";

import InfoIcon from "../../../../images/courses/course-details/infoIcon.svg";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
import { SVGProps } from "react";
import { CourseFeeChart } from "./CourseFeeChart";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

interface CourseData {
  [key: string]: {
    "Minimum Fees": {
      Private: string;
      Government: string;
    };
    "Maximum Fees": {
      Private: string;
      Government: string;
    };
  };
}

interface CourseSubjectsData {
  title: string;
  description: string;
  CoursefeeTable: {
    description: ReactNode;
    title: string;
    courseData: {
      details: CourseData;
    };
  };
  RequiredSkill: {
    title: string;
    description: string;
    skills: string[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CourseSubjectsData: CourseSubjectsData = {
  title: "Course Fees Mechanical Engineering",
  description:
    "The syllabus for Mechanical Engineering at undergraduate courses depends on the institute, but it remains almost the same for every institute. Some of the subjects offered at undergraduate courses are Electrical Sciences, Engineering Drawing, Engineering Mathematics, Basic Electronics Laboratory, and Physics Laboratory. The below mentioned syllabus is from Indian Institute of Technology Guwahati.",
  CoursefeeTable: {
    title: "UG Courses",
    courseData: {
      details: {
        UG: {
          "Minimum Fees": {
            Private: "11.4K",
            Government: "---",
          },
          "Maximum Fees": {
            Private: "24.00 Lakhs",
            Government: "13.07 Lakhs",
          },
        },
        PG: {
          "Minimum Fees": {
            Private: "---",
            Government: "---",
          },
          "Maximum Fees": {
            Private: "13.20 Lakhs",
            Government: "5.12 Lakhs",
          },
        },
        Doctoral: {
          "Minimum Fees": {
            Private: "---",
            Government: "7.00K",
          },
          "Maximum Fees": {
            Private: "15.98 Lakhs",
            Government: "3.58 Lakhs",
          },
        },
        Diploma: {
          "Minimum Fees": {
            Private: "7.88K",
            Government: "615",
          },
          "Maximum Fees": {
            Private: "11.15 Lakhs",
            Government: "1.38 Lakhs",
          },
        },
      },
    },
    description: undefined,
  },
  RequiredSkill: {
    title: "Required Skill Set",
    description:
      "Students must possess certain skills to get the most out of their Mechanical Engineering programmes. These skills will help the candidate to get a good position in the top and reputed organisations. After earning Mechanical Engineering, students will need the following skill sets to advance in their careers.",
    skills: [
      "Creativity",
      "Problem-Solving Skills",
      "Team Work",
      "Technical Skills",
      "Ability to Work Under Pressure ",
      "Leadership Ability",
    ],
  },
};

const MechanicalEngUGCouseTable = ({
  courseSubjectsData,
}: {
  courseSubjectsData: CourseSubjectsData["CoursefeeTable"];
}) => {
  return (
    <>
      <div className="flex flex-col gap-6">
        <table className="w-full border-collapse border-[#A3A2AC] border">
          <thead>
            <tr className="bg-[#E5E5E5] py-3.5">
              <th className="py-3.5"></th>
              <th
                colSpan={2}
                className="border-[#A3A2AC] border-l border-r py-3.5 font-medium"
              >
                Minimum Fees
              </th>
              <th
                colSpan={2}
                className="border-[#A3A2AC] border-l border-r py-3.5 font-medium"
              >
                Maximum Fees
              </th>
            </tr>
            <tr className="text-[#201E29] font-normal">
              <th className="border-[#A3A2AC] border-l border-r"></th>
              <th className="py-2.5 px-5 text-base text-[#4C495C]">Private</th>
              <th className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] font-medium">
                Government
              </th>
              <th className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] font-medium">
                Private
              </th>
              <th className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] font-medium">
                Government
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(courseSubjectsData.courseData.details).map(
              ([courseLevel, fees], index) => (
                <tr
                  key={courseLevel}
                  className={`border-[#A3A2AC] border-l border-r ${
                    index % 2 === 0 ? "bg-[#E5E5E5]" : "bg-[#fff]"
                  }`}
                >
                  <td className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#201E29] uppercase">
                    {courseLevel}
                  </td>
                  <td className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] relative">
                    {fees["Minimum Fees"]["Private"]}{" "}
                    <span className="absolute top-0 right-0">
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </td>
                  <td className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] relative">
                    {fees["Minimum Fees"]["Government"]}
                    <span className="absolute top-0 right-0">
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </td>
                  <td className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] relative">
                    {fees["Maximum Fees"]["Private"]}
                    <span className="absolute top-0 right-0">
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </td>
                  <td className="border-[#A3A2AC] border-l border-r py-2.5 px-5 text-base text-[#4C495C] relative">
                    {fees["Maximum Fees"]["Government"]}
                    <span className="absolute top-0 right-0">
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
const boldifyText = (text: string) => {
  return text.replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>");
};
const CourseFeeMg = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", skillsets, coursefeeTable } = data || {};
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col bg-[#fff] relative" id="fees">
          <div className="flex flex-col ">
            <div className="flex max-md:w-full">
              <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                {`Course Fees ${courseTitle}`}
              </h3>
            </div>
          </div>
          <div className="flex flex-col gap-6 px-2 py-8 md:py-5 md:px-9">
            <div className="course-fee-details-section">
              {coursefeeTable?.description?.map(
                (desc: any, index: React.Key | null | undefined) => {
                  return (
                    <p
                      className="mb-2"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: boldifyText(desc) }}
                    />
                  );
                }
              )}
            </div>
            {/* <MechanicalEngUGCouseTable courseSubjectsData={coursefeeTable} /> */}
            <CourseFeeChart />
          </div>
        </div>
        <div
          className="flex flex-col gap-6 bg-[#fff] relative"
          id="required-skill-set"
        >
          <div className="flex flex-col">
            <div className="flex max-md:w-full">
              <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                {CourseSubjectsData.RequiredSkill.title}
              </h3>
            </div>
            <div className="flex flex-col px-2 py-8 md:py-5 md:px-9">
              <p className="text-base text-[#4C495C] leading-7">
                {skillsets?.description}
              </p>
              <ul className="list-decimal list-inside mt-4">
                {skillsets?.required_skillsets?.map(
                  (skill: string, index: number) => (
                    <li
                      key={index}
                      className="text-base text-[#4C495C] leading-7"
                    >
                      {skill}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseFeeMg;
