import React from "react";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
} from "../customHooks/useSelectedFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IFilterDataItem } from "./DesktopFilterMenu";
import { FilterActions, FilterCategory } from "../FilterEnum";

interface IProps {
  filterData: IFilterDataItem[];
  selectedFilter: ISelectedFilter;
  updateSelectedFilter: IUpdateSelectedFilter;
}

const FilterChips: React.FC<IProps> = ({
  filterData,
  selectedFilter,
  updateSelectedFilter,
}) => {
  const options: {
    value: string;
    category: string;
    optionName: string;
    type: FilterCategory.SingleSelect | FilterCategory.MultiSelect;
  }[] = [];

  Object.keys(selectedFilter.multiSelectFilters).forEach((category) =>
    Array.from(selectedFilter.multiSelectFilters[category] || []).forEach(
      (option) => {
        const optionName =
          filterData
            .find((item) => item.categoryKey == category)
            ?.options.find((item) => item.key == option)?.name || option;
        options.push({
          value: option,
          optionName: optionName,
          category: category,
          type: FilterCategory.MultiSelect,
        });
      }
    )
  );

  Object.keys(selectedFilter.singleSelectFilters).forEach((category) => {
    const optionKey = selectedFilter.singleSelectFilters[category];
    const optionName =
      filterData
        .find((item) => item.categoryKey == category)
        ?.options.find((item) => item.key == optionKey)?.name || optionKey;
    options.push({
      value: optionKey,
      optionName: optionName,
      category: category,
      type: FilterCategory.SingleSelect,
    });
  });

  const getChip = (
    option: string,
    category: string,
    optionName: string,
    type: string
  ) => {
    const handleClick = () => {
      let action = null;
      if (type == FilterCategory.MultiSelect)
        action = FilterActions.RemoveMultiSelect;
      else action = FilterActions.ChangeSingleSelect;
      updateSelectedFilter(category, action, option);
    };
    return (
      <div className="flex items-center gap-4 py-2 px-4 bg-white rounded-full text-base hover:bg-grey-1">
        {optionName}
        <button onClick={handleClick}>
          <FontAwesomeIcon icon={faClose} size="lg" className="text-grey" />
        </button>
      </div>
    );
  };

  if (options.length == 0) return <></>;

  return (
    <div className="flex flex-wrap items-center gap-2">
      {options.map((option) =>
        getChip(option.value, option.category, option.optionName, option.type)
      )}
    </div>
  );
};

export default FilterChips;
