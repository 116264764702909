// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.college-predictor-intermediate .description p {
  font-size: 1rem;
  margin-top: 1.5rem;
}
.college-predictor-intermediate .description h2 {
  font-size: 1rem;
  margin-top: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/intermediate/components/Banner.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;EACA,kBAAA;AADN;AAII;EACE,eAAA;EACA,kBAAA;AAFN","sourcesContent":[".college-predictor-intermediate {\n  .description {\n    p {\n      font-size: 1rem;\n      margin-top: 1.5rem;\n    }\n\n    h2 {\n      font-size: 1rem;\n      margin-top: 1.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
