import React, { useState, useEffect } from "react";

import CustomSwiper from "../../../components/Slider/CustomSwiper";
import "swiper/swiper-bundle.css";
import "./FrequentComparison.scss";
import useDebouncedSearch from "../../../components/filter/customHooks/useDebouncedSearch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { resetComparison } from "../collegeCompareSlice";
import { useHighlight } from './HighlightContext'; 

import {
  fetchAndAddCollegeData,
  fetchSearchData,
} from "../collegeCompareThunk";
interface Cards {
  collegeName: string;
  collegeId: string;
  collegeLogo: string;
  degreee: string;
  rating: string;
  ratedUserCount: string;
}
const scrollToComponent = () => {
  const element = document.getElementById("comparison");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const FrequentComparison: React.FC = () => {
  const [id, setId] = useState<number | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [arrayState, setArrayState] = useState<number[]>([]);
  const [collegeIds, setCollegeIds] = useState<number[]>([]);
  const { setHighlightField } = useHighlight();

  const frequencyComparion = useSelector(
    (state: RootState) => state.collegeCompare.landingData?.frequencyComparion
  );

  const totalStars = 5;

  const getStarFill = (rating: string, index: number) => {
    const numericRating = parseFloat(rating);
    return index < numericRating ? "#FFD700" : "#DCDCDC";
  };

  // Update collegeIds state based on frequencyComparison

  const handleCompareClick = (sectionIndex: number) => {
    const section = frequencyComparion[sectionIndex];
    if (section) {
      dispatch(resetComparison());
      const idsToDispatch = section.map((data: any) => data.collegeId);
      idsToDispatch.forEach((id: number) => {
        dispatch(fetchAndAddCollegeData(id));
      });
      setHighlightField("");
      scrollToComponent();
    }
  };
  if (!frequencyComparion || frequencyComparion.length <= 0) return;

  const Card: React.FC<Cards> = ({
    collegeName,
    collegeLogo,
    degreee,
    rating,
    ratedUserCount,
  }) => (
    <div className="flex  flex-col pt-4  w-[138px] pb-4  flex justify-center">
      <div className="flex  flex-col h-[192px]">
        {" "}
        <div className="flex flex-row justify-center py-2 border border-gray-300 w-full">
          <img src={collegeLogo} className="w-[60px] h-[60px] rounded-full" />
        </div>
        <div className="collegename-cs py-2 ">{collegeName}</div>
      </div>
      <div className="degree pb-2">{degreee} </div>
      <div className="rating flex flex-row inline-block">
        {rating}
        <div className="px-1 flex">
          {[...Array(totalStars)].map((_, index) => (
            <svg
              key={index}
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill={getStarFill(rating, index)}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_607)">
                <path d="M8.08171 1.06774L9.70283 4.35255C9.81556 4.58101 10.0335 4.73931 10.2857 4.77589L13.9108 5.30267C14.5458 5.39502 14.7991 6.17514 14.3398 6.62277L11.7167 9.17962C11.5344 9.35741 11.4511 9.61375 11.4942 9.8647L12.1134 13.4751C12.2219 14.1074 11.5581 14.5895 10.9902 14.2912L7.74802 12.5867C7.52255 12.4683 7.25302 12.4683 7.02755 12.5867L3.78532 14.2912C3.21746 14.5898 2.55366 14.1074 2.6622 13.4751L3.28132 9.8647C3.3245 9.61375 3.24115 9.35741 3.05886 9.17962L0.435747 6.62277C-0.0235747 6.17484 0.229772 5.39472 0.864787 5.30267L4.48989 4.77589C4.74204 4.73931 4.96001 4.58101 5.07274 4.35255L6.69385 1.06774C6.97748 0.492393 7.79778 0.492393 8.08171 1.06774Z" />
              </g>
              <defs>
                <clipPath id="clip0_1_607">
                  <rect
                    width="14.3733"
                    height="14.3733"
                    fill="white"
                    transform="translate(0.201172 0.323242)"
                  />
                </clipPath>
              </defs>
            </svg>
          ))}
        </div>
        ({ratedUserCount})
      </div>{" "}
    </div>
  );

  const finalCard = frequencyComparion?.map(
    (section: any, sectionIndex: number) => (
      <div
        key={sectionIndex}
        className="compare-section w-[339px] h-[342px] bg-white flex flex-col ITEMS"
      >
        <div className="flex flex-row px-4">
          {section?.map((data: any, dataIndex: number) => (
            <div className="flex flex-row items-start">
              <Card
                collegeName={data.collegeName}
                collegeLogo={data.collegeLogo}
                degreee={data.degreee}
                rating={data.rating}
                ratedUserCount={data.ratedUserCount}
                collegeId={data.collegeId}
              />

              <div className="translate-y-12 px-1">
                {dataIndex === 0 && (
                  <svg
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_519)">
                      <path
                        d="M20.5002 16.6244C20.5002 18.3709 19.0712 19.7998 17.3247 19.7998H4.62291C2.87641 19.7998 1.44745 18.3709 1.44745 16.6244V3.92253C1.44745 2.17603 2.87641 0.74707 4.62291 0.74707H17.3247C19.0712 0.74707 20.5002 2.17603 20.5002 3.92253V16.6244Z"
                        fill="#00B489"
                      />
                      <path
                        d="M9.16245 6.36768H10.8772L8.20981 14.1793H6.65384L3.98645 6.36768H5.73295L7.4477 12.3058L9.16245 6.36768Z"
                        fill="white"
                      />
                      <path
                        d="M13.0034 11.7978C13.0669 12.1471 13.1621 12.4329 13.2892 12.5917C13.5749 12.9093 14.0195 13.068 14.6864 13.068C15.0674 13.068 15.4167 13.0363 15.639 12.941C16.1153 12.7822 16.3376 12.4647 16.3376 12.0201C16.3376 11.7661 16.2106 11.5756 15.9883 11.4168C15.766 11.2898 15.385 11.1627 14.9086 11.0357L14.0513 10.8452C13.2256 10.6547 12.6541 10.4641 12.3365 10.2419C11.7967 9.89257 11.5427 9.32098 11.5427 8.55887C11.5427 7.86027 11.7967 7.28869 12.3048 6.81237C12.8128 6.33605 13.5749 6.11377 14.5911 6.11377C15.4167 6.11377 16.1471 6.33605 16.7186 6.78062C17.322 7.22518 17.6078 7.86027 17.6395 8.68589H16.0518C16.02 8.20957 15.7978 7.89203 15.4167 7.66974C15.1627 7.54273 14.8451 7.47922 14.4323 7.47922C13.9878 7.47922 13.6385 7.57448 13.3844 7.73325C13.1304 7.89203 13.0034 8.14606 13.0034 8.43185C13.0034 8.71765 13.1304 8.90817 13.3844 9.06695C13.5432 9.16221 13.8925 9.25747 14.4323 9.38449L15.7978 9.70204C16.4011 9.82906 16.8457 10.0196 17.1632 10.2736C17.6395 10.6547 17.8618 11.1627 17.8618 11.8613C17.8618 12.5917 17.576 13.1633 17.0362 13.6396C16.5599 14.1794 15.7978 14.4017 14.7816 14.4017C13.7337 14.4017 12.9399 14.1794 12.3365 13.7031C11.7332 13.2268 11.4474 12.5917 11.4474 11.7978H13.0034Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_519">
                        <rect
                          width="20.3229"
                          height="20.3229"
                          fill="white"
                          transform="translate(0.8125 0.112305)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center pt-4">
          <button
            onClick={() => handleCompareClick(sectionIndex)}
            className="compare button-styles white-color w-[122px] h-[40px] bg-[#173CBA] pt-2 rounded-lg flex justify-center"
          >
            Compare
          </button>
        </div>
      </div>
    )
  );

  return (
    <div className="bg-[#FFF9E5] min-h-[329px] pb-12">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
        Frequent Comparison{" "}
      </h1>
      <div className="container">
        <CustomSwiper
          className="!px-2"
          items={finalCard}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1440: { slidesPerView: 4, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default FrequentComparison;
