import { FC } from "react";

interface IProps extends React.ComponentProps<"img"> {
  className?: string;
  aspectRatio?: number;
}

const Image: FC<IProps> = ({
  className = "",
  aspectRatio = 100,
  ...imgProps
}) => {
  return (
    <div className={className}>
      <div
        className={"relative h-0 w-full"}
        style={{ paddingBottom: `${aspectRatio}%` }}
      >
        <img
          {...imgProps}
          className="absolute top-0 left-0 h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default Image;
