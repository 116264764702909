import { FC } from "react";

interface IProps {
  data: any[][];
  justify?: "left" | "center";
  className?: string;
  rowHeader?: boolean;
  colHeader?: boolean;
}

const Table: FC<IProps> = ({
  data,
  justify = "center",
  className = "",
  rowHeader = false,
  colHeader = false,
}) => {
  return (
    <div
      className={
        "flex flex-col border border-grey-3 bg-white max-w-full overflow-x-auto " +
        className
      }
    >
      {data.map((row) => (
        <div
          className={`min-w-fit flex border-b border-grey-3 last:border-none ${
            rowHeader ? "first:font-semibold first:text-black" : ""
          }`}
        >
          {row.map((col) => (
            <div
              className={`py-2 px-2 flex-1 min-w-36 flex items-center text-${justify} justify-${justify} border-r border-grey-3 last:border-none ${
                colHeader ? "first:font-semibold first:text-black" : ""
              }`}
            >
              {col}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Table;
