import React from "react";
import OverviewSection from "./research/OverviewSection";

const ResearchTab: React.FC = () => {
  return (
    <div>
      <OverviewSection />
    </div>
  );
};

export default ResearchTab;
