export const indianColleges = [
    {
        id: 1,
        image: '/images/careers/dcc/software-developer.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 1',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 2,
        image: '/images/careers/dcc/software-architect.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 2',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 3,
        image: '/images/careers/dcc/agri-scientist.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 3',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 4,
        image: '/images/careers/dcc/software-developer.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 4',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 5,
        image: '/images/careers/dcc/agri-scientist.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 5',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 6,
        image: '/images/careers/dcc/agri-scientist.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 6',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    },
    {
        id: 7,
        image: '/images/careers/dcc/software-developer.png',
        pillText: 'Best in Placements',
        university: 'NIIT University',
        address: 'neemrana, rajasthan 4',
        placement: '100% Placement',
        ctc: 'Highest CTC 44.27 LPA'
    }
]