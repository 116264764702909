import React, { useState, useEffect } from "react";
import TrendingTopics from "../components/TrendingTopics";
import BlogsBanner from "../components/BlogsAdBanner";
import BlogsBreadcrumb from "../components/BlogsBreadcrumb";
import BlogsListContainer from "./sections/BlogsListContainer";
import BlogsHeader from "../components/BlogsHeader";
import BannerImag1 from "../../../images/blog-article/blogsAdBanner.png";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import BlogShareFloatingMenu from "../components/BlogShareFloatingMenu";
import usePagination from "../../../components/filter/customHooks/usePagination";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";
import { url } from "inspector";

const BlogsIntermediate = () => {
  const dispatch = useDispatch();
  const hoveredMenu = useSelector((state: RootState) => state.blogSlice);
  console.log("hoveredMenu", hoveredMenu?.category);
  //get category from location
  const location = useLocation();

  const { pathname, state } = location;

  const { category } = useParams<{ category: string }>();

  // dispatch(
  //   setCurrentCategoryPageValue({ category: category, subCategory: "" })
  // );
  // console.log("location", location);

  // const {
  //   totalPages,
  //   currentPage,
  //   updateTotalCount,
  //   handlePrevPage,
  //   handleNextPage,
  //   goToPage,
  //   handleResetPagination,
  // } = usePagination(10);
  // console.log("totalPages", totalPages);

  //list of blogs under specific category
  const [blogsList, setBlogsList] = useState([]);
  //Page loading
  const [loading, setLoading] = useState<boolean>(true);

  //pagination default values
  // const [paginationValues, setPaginationValues] = useState({
  //   currentPage: 1,
  //   currentPageSize: 5,
  //   totalPages: 10,
  // });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(4);
  const [totalNoOfPages, setTotalNoOfPages] = useState(10);

  const [trendingTopic, setTrendingTopic] = useState([]);

  //Get All the list of Blogs based on category

  const getBlogsListByCategory = async (category: string) => {
    setLoading(true);
    let payload = {
      topic: category && category,
      subtopic: "",
      currentPage: currentPage,
      currentPageSize: currentPageSize,
    };
    await axios
      .post(`https://mentormenteedev.com/api/blogs/topic-details`, payload)
      .then((response) => {
        console.log(response.data?.details);
        setTrendingTopic(response?.data?.trendingTopics);
        setBlogsList(response?.data?.details);
        setCurrentPage(response?.data?.details?.currentPage);
        setCurrentPageSize(response?.data?.details?.currentPageSize);
        setTotalNoOfPages(response?.data?.details?.totalNoOfPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const handleResetPagination = () => {
    if (currentPage != 1) setCurrentPage(1);
  };

  // useEffect(() => {
  //   getBlogsListByCategory();
  // }, [location, currentPage]);
  // useEffect(() => {
  //   if (prevRoute.pathname != pathname) {
  //     handleResetPagination();
  //   }
  // }, [prevRoute]);

  useEffect(() => {
    if (category) getBlogsListByCategory(category);
  }, [category, currentPage]);

  if (loading) {
    return <Loader />;
  }

  // updateTotalCount(paginationValues.totalPages * paginationValues.currentPage);
  const handleNextPage = () => {
    setCurrentPage((prev) => {
      if (prev + 1 <= totalNoOfPages) return prev + 1;
      else return prev;
    });
    // setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => {
      if (prev - 1 > 0) return prev - 1;
      else return prev;
    });
  };

  console.log("trendingTopic", trendingTopic);

  const paginationProps = {
    currentPage: currentPage,
    totalPages: totalNoOfPages,
    handleNextPage: handleNextPage,
    handlePrevPage: handlePrevPage,
  };
  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-8 sm:grid-cols-1 md:grid-cols-1 gap-y-4">
        <TrendingTopics trendingTopics={trendingTopic} />
        <div className="hidden py-4 xs:hidden sm:hidden md:block ">
          <div className="flex justify-center w-full">
            <BlogsBanner width="" height="" image={BannerImag1} />
          </div>
        </div>
        <BlogsBreadcrumb />
        <BlogsListContainer
          blogsCategory={category}
          blogsList={blogsList}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
};

export default BlogsIntermediate;
