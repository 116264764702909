import React, { useState } from "react";
type FeeDetail = {
  campus: string;
  degree: string;
  branchWithSpecialization: string;
  intake: number;
  duration: number;
  annualFees: number;
};
const feeDetails: FeeDetail[] = Array(10).fill({
  campus: "KTR",
  degree: "B.tech",
  branchWithSpecialization: "Bio medical Engineering",
  intake: 60,
  duration: 4,
  annualFees: 2750000,
});

const examDetails = {
  content: [
    "Entrance exam duration: Two hours",
    "Exam pattern: The test will encompass both aptitude (45 minutes) and core subjects (75 minutes)",
    {
      "B.Tech Programs":
        "Mathematics, Physics, and Chemistry are mandatory subjects, with a weightage of 60%, 30%, and 10% respectively.",
    },
    {
      "B.Com, B.Com(PA) & B.Sc Economics(Data Science) Programs:": [
        "Students of Commerce & Arts: 40% in Mathematics, any two of Commerce, Accountancy, Economics, General Studies (English, Sociology, History or Computer Science), with a weightage of 30% each.",
        "Students of Science and Arts streams can also apply for the B. Com. / B. Com (Professional Accounting) programs.",
        "Students of Science will have to appear for Mathematics (for commerce), Physics, and Chemistry. Weightage for these 60%, 30% and 10% respectively.",
      ],
    },
    {
      "Syllabus - SNUCEE 2024": [
        "Mathematics",
        "Physics",
        "Chemistry",
        "Accountancy",
        "Commerce",
        "Economics",
        "English",
        "History",
        "Sociology",
      ],
    },
  ],
};

const basedAdmissions = {
  content:
    "Very few seats are set aside for Admission based on JEE Main Score. Students with JEE Main score of minimum 90 percentile may be called directly for Interview.",
  options: [
    "In the Application Form enter your JEE Mains Percentile Score in the field provided in the “Academic Details” Section",
    "The Institute reserves the right to offer a very limited number of candidates, out of those who score a minimum of 90 Percentile in JEE Main Exams, they have an option of direct Interview.",
    "As the number of seats through this route is very small, it is recommended that Applicants appear for SNUCEE even if they have scored more than 90 percentile in JEE Main Exams.",
  ],
};

const eligibility = {
  content:
    "Candidates who completed or appeared for the 12th standard board exams are eligible to apply for:",
  options: [
    "B. Tech: Mathematics, Physics, and Chemistry are mandatory subjects",
    "B. Com (Professional Accounting): Commerce and Accountancy are mandatory subjects",
    "B.Com. / B.Com. (Hons): Commerce and Accountancy are mandatory subjects; in addition Mathematics / Business Mathematics / Statistics / Computer Science as one of the subjects is preferred",
    "B.Sc. Economics (Data Science): Preferred to have Mathematics / Business Mathematics / Statistics / Computer Science should be one of the subjects",
    "Students of Commerce / Arts / Sciences streams can join B.Com. / B.Com. (Professional Accounting)/ B.Sc. Economics (Data Science)",
    "Minimum 60% marks in 10th in aggregate in Standard Board Exams is a mandatory Requirement",
    "Only students who secure minimum 75% in aggregate in 12th Standard Board Exams will be considered for shortlisting for the Interview stage",
  ],
};
const examDates = {
  content: "Exam Dates content for Engineering",
  headers: [
    "City",
    "Phase 1 - 13th Apr 2024",
    "Phase 2 - 27th Apr 2024",
    "Phase 3 - 11th May 2024",
    "Phase 4 - 8th June 2024",
  ],
  rows: [
    [
      "Last Date to Apply",
      "Phase 1 - 31st March 2024",
      "Phase 2 - 14th April 2024",
      "Phase 3 - 28th April 2024",
      "Phase 4 - 26th May 2024",
    ],
    [
      "SSN + SNU Campus",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "09.00 am - 11.00 am\n 01.00 pm - 03.00 pm",
    ],
    [
      "Coimbatore",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "",
    ],
    [
      "Chennai",
      "",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
      "",
      "09.00 am - 11.00 am \n01.00 pm - 03.00 pm",
    ],
    ["Bangalore", "", "", "09.00 am - 11.00 am \n01.00 pm - 03.00 pm", ""],
    ["Hyderabad", "", "", "", "09.00 am - 11.00 am \n01.00 pm - 03.00 pm"],
  ],
};

const admissionProcess = {
  content: "Admission Process content for Engineering",
  options: [
    "Step 1: Register and Apply Online",
    "Step 2: Fill particulars and pay fees for SNUCEE",
    "Step 3: Choose date and slot for exam",
    "Step 4: Shiv Nadar University Chennai Entrance Examination - (SNUCEE)",
    "Step 5: Shortlisted candidates will be called for interview",
    "Step 6: Final merit list",
  ],
};
const mainTabsContent: {
  [key: string]: { [key: string]: string | any[] };
} = {
  Engineering: {
    "Fee Details": feeDetails,
    "SNUCEE Exam Details": examDetails.content,
    "JEE Based Admissions": basedAdmissions.content,
    Eligibility: eligibility.content,
    "Exam Dates": examDates.content,
    "Admission Process": admissionProcess.content,
  },
  "Science & Humanities": {
    "Fee Details": feeDetails,
    "SNUCEE Exam Details": examDetails.content,
    "JEE Based Admissions": basedAdmissions.content,
    Eligibility: eligibility.content,
    "Exam Dates": examDates.content,
    "Admission Process": admissionProcess.content,
  },
  "Medicine & Health sciences": {
    "Fee Details": feeDetails,
    "SNUCEE Exam Details": examDetails.content,
    "JEE Based Admissions": basedAdmissions.content,
    Eligibility: eligibility.content,
    "Exam Dates": examDates.content,
    "Admission Process": admissionProcess.content,
  },
  Law: {
    "Fee Details": feeDetails,
    "SNUCEE Exam Details": examDetails.content,
    "JEE Based Admissions": basedAdmissions.content,
    "Eligibility content": eligibility.content,
    "Exam Dates": examDates.content,
    "Admission Process": admissionProcess.content,
  },
  Management: {
    "Fee Details": feeDetails,
    "SNUCEE Exam Details": examDetails.content,
    "JEE Based Admissions": basedAdmissions.content,
    "Eligibility content": eligibility.content,
    "Exam Dates": examDates.content,
    "Admission Process": admissionProcess.content,
  },
};

const AdmissionIndia: React.FC = () => {
  const [activeMainTab, setActiveMainTab] = useState("Engineering");
  const [activeSubTab, setActiveSubTab] = useState("Fee Details");

  const handleMainTabClick = (tabId: string) => {
    setActiveMainTab(tabId);
    setActiveSubTab(
      Object.keys(mainTabsContent[tabId as keyof typeof mainTabsContent])[0]
    );
  };

  const handleSubTabClick = (tabId: string) => {
    setActiveSubTab(tabId);
  };

  return (
    <div
      className="flex flex-col py-16 items-center justify-center bg-[#F4F7FB]"
      id="Admission-international"
    >
      <div className="container mx-auto flex flex-col gap-9">
        <h3 className="text-3xl font-extrabold text-[#231F40] text-center">
          Admission in International
        </h3>
        <div className="flex flex-col gap-5">
          <div
            className="main-tabs-wrapper flex gap-8 items-center justify-center"
            role="tablist"
          >
            {Object.keys(mainTabsContent).map((tabId) => (
              <button
                key={tabId}
                onClick={() => handleMainTabClick(tabId)}
                className={` py-3 px-4 font-medium text-lg rounded-lg text-white hover:bg-[#0ab38b] ${
                  activeMainTab === tabId ? "bg-[#0ab38b]" : "bg-[#113cc0]"
                }`}
              >
                {tabId}
              </button>
            ))}
          </div>
          {mainTabsContent[activeMainTab] && (
            <div
              className="sub-tabs-wrapper flex flex-col gap-6"
              role="tablist"
            >
              <div className="flex flex-row justify-center">
                {Object.keys(mainTabsContent[activeMainTab]).map((tabId) => (
                  <button
                    key={tabId}
                    onClick={() => handleSubTabClick(tabId)}
                    className={`text-lg text-black font-semibold border-r-0 border border-gray-400 p-4 last:border-r first:rounded-l-2xl last:rounded-r-2xl hover:bg-[#113cc0] hover:text-white ${
                      activeSubTab === tabId ? "bg-[#113cc0] text-white" : ""
                    }`}
                  >
                    {tabId}
                  </button>
                ))}
              </div>
              <div className="tab-content w-[1000px] mx-auto">
                {activeSubTab === "Fee Details" && (
                  <table className="w-full border border-gray-400 rounded-2xl">
                    <thead className="bg-gray-800 text-white">
                      <tr>
                        <th className="py-2 px-3 text-left">Campus</th>
                        <th className="py-2 px-3 text-left">Degree</th>
                        <th className="py-2 px-3 text-left">
                          Branch with Specialization
                        </th>
                        <th className="py-2 px-3 text-left">Intake</th>
                        <th className="py-2 px-3 text-left">Duration</th>
                        <th className="py-2 px-3 text-left">Annual Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(
                        mainTabsContent[activeMainTab][activeSubTab]
                      )
                        ? (
                            mainTabsContent[activeMainTab][
                              activeSubTab
                            ] as FeeDetail[]
                          ).map((feeDetail, index) => (
                            <tr
                              key={index}
                              className={index % 2 === 1 ? "bg-gray-300" : ""}
                            >
                              <td className="py-2 px-3 text-left">
                                {feeDetail.campus}
                              </td>
                              <td className="py-2 px-3 text-left">
                                {feeDetail.degree}
                              </td>
                              <td className="py-2 px-3 text-left">
                                {feeDetail.branchWithSpecialization}
                              </td>
                              <td className="py-2 px-3 text-left">
                                {feeDetail.intake}
                              </td>
                              <td className="py-2 px-3 text-left">
                                {feeDetail.duration}
                              </td>
                              <td className="py-2 px-3 text-left">
                                {feeDetail.annualFees}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                )}
                {activeSubTab === "SNUCEE Exam Details" && (
                  <div>
                    <div>
                      <ul className="list-disc pl-10 leading-6">
                        {Array.isArray(
                          mainTabsContent[activeMainTab][activeSubTab]
                        )
                          ? (
                              mainTabsContent[activeMainTab][
                                activeSubTab
                              ] as Array<string | { [key: string]: string }>
                            ).map((item, index) => (
                              <li
                                key={index}
                                className="text-black text-base mb-3"
                              >
                                {typeof item === "string"
                                  ? item
                                  : Object.keys(item).map((key) => (
                                      <div key={key}>
                                        <p className="text-black text-base mb-2">
                                          {key}
                                        </p>

                                        {typeof item[key] === "string" ? (
                                          <ul>
                                            <li
                                              className="text-black text-base ml-10"
                                              style={{
                                                listStyleType: "circle",
                                              }}
                                            >
                                              {item[key]}
                                            </li>
                                          </ul>
                                        ) : (
                                          <ul>
                                            {(
                                              item[key] as unknown as string[]
                                            ).map((subItem) => (
                                              <li
                                                key={subItem}
                                                className="text-black text-base ml-10"
                                                style={{
                                                  listStyleType: "circle",
                                                }}
                                              >
                                                {subItem}
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    ))}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                )}
                {activeSubTab === "JEE Based Admissions" && (
                  <div>
                    <div>
                      <h3 className="text-[#113cc0] text-xl mb-2 font-semibold">
                        {basedAdmissions.content}
                      </h3>
                      <ul>
                        {basedAdmissions.options.map((option, index) => (
                          <li
                            key={index}
                            className="text-black text-base mb-2 font-normal list-disc ml-10"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {activeSubTab === "Eligibility" && (
                  <div>
                    <div>
                      <h4 className="text-[#113cc0] text-xl mb-2 font-semibold">
                        {eligibility.content}
                      </h4>
                      <ul>
                        {eligibility.options.map((option, index) => (
                          <li
                            key={index}
                            className="text-black text-base mb-2 font-normal list-disc ml-10"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {activeSubTab === "Exam Dates" && (
                  <div>
                    <div>
                      <h3 className="text-[#113cc0] text-xl mb-2 font-semibold">
                        {examDates.content}
                      </h3>
                      <table className="w-full border border-gray-400 rounded-2xl">
                        <thead className="bg-gray-800 text-white">
                          <tr>
                            {examDates.headers.map((header, index) => (
                              <th key={index} className="py-2 px-3 text-left">
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {examDates.rows.map((row, index) => (
                            <tr
                              key={index}
                              className={index % 2 === 1 ? "bg-gray-300" : ""}
                            >
                              {row.map((cell, index) => (
                                <td key={index} className="py-2 px-3 text-left">
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {activeSubTab === "Admission Process" && (
                  <div>
                    <div>
                      <h3 className="text-[#113cc0] text-xl mb-2 font-semibold">
                        {admissionProcess.content}
                      </h3>
                      <ul>
                        {admissionProcess.options.map((option, index) => (
                          <li
                            key={index}
                            className="text-black text-base mb-2 font-normal list-disc ml-10"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionIndia;
