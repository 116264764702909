import React from "react";

interface BlogsAdBannerProps {
  width: string;
  height: string;
  image: any;
}
const BlogsAdBanner: React.FC<BlogsAdBannerProps> = ({
  width,
  height,
  image,
}) => {
  return (
    <>
      <img className="" src={image} width={width ? width : ""} />
    </>
  );
};

export default BlogsAdBanner;
