// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hc-card {
  width: 316px;
  height: 370px;
  flex-shrink: 0;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
}
.hc-card .hc-content {
  background-color: #fff;
  padding: 15px 10px;
  height: 144px;
  position: absolute;
  bottom: -100px;
}
.hc-card .hc-content h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.hc-card .hc-content p {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hc-card .hc-content button {
  color: #113cc0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hc-card .hc-content button svg {
  display: inline-block;
  margin-left: 6px;
}

.hc-card:hover .hc-content {
  bottom: 0;
  transition: 0.5s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/hover-card/HoverCard.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAME;EACE,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;AAJJ;AAKI;EACE,WAAA;EAEA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJN;AAMI;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AALN;AAOI;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AANN;AAOM;EACE,qBAAA;EACA,gBAAA;AALR;;AAUA;EACE,SAAA;EACA,4BAAA;AAPF","sourcesContent":[".hc-card {\n  width: 316px;\n  height: 370px;\n  flex-shrink: 0;\n  margin-bottom: 25px;\n  position: relative;\n  overflow: hidden;\n  // .hc-img {\n  //   height: 370px;\n  //   img {\n  //     width: 100%;\n  //   }\n  // }\n  .hc-content {\n    background-color: #fff;\n    padding: 15px 10px;\n    height: 144px;\n    position: absolute;\n    bottom: -100px;\n    h3 {\n      color: #333;\n      // font-family: Poppins;\n      margin-bottom: 10px;\n      font-size: 18px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: normal;\n    }\n    p {\n      color: #828282;\n      // font-family: Poppins;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: normal;\n    }\n    button {\n      color: #113cc0;\n      //   font-family: Poppins;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n      svg {\n        display: inline-block;\n        margin-left: 6px;\n      }\n    }\n  }\n}\n.hc-card:hover .hc-content {\n  bottom: 0;\n  transition: 0.5s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
