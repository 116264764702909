import React from "react";
import "./DidYouKnow.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";
export default function DidYouKnow() {
  return (
    <div className="did-you-know-main">
      <Swiper
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="flex flex-col mx-auto py-[52px] items-center max-w-[470px]">
            <div className="text-xl font-medium text-center text-white text-opacity-80">
              Did you know?
            </div>
            <div className="self-stretch mt-1 w-full text-2xl font-semibold text-center text-white">
              Parents apply to 7 schools on average,
            </div>
            <div className="mt-1 text-lg text-center text-white">
              for their kids Nursery admission
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col mx-auto py-[52px] items-center max-w-[470px]">
            <div className="text-xl font-medium text-center text-white text-opacity-80">
              Did you know?
            </div>
            <div className="self-stretch mt-1 w-full text-2xl font-semibold text-center text-white">
              Parents apply to 7 schools on average,
            </div>
            <div className="mt-1 text-lg text-center text-white">
              for their kids Nursery admission
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col mx-auto py-[52px] items-center max-w-[470px]">
            <div className="text-xl font-medium text-center text-white text-opacity-80">
              Did you know?
            </div>
            <div className="self-stretch mt-1 w-full text-2xl font-semibold text-center text-white">
              Parents apply to 7 schools on average,
            </div>
            <div className="mt-1 text-lg text-center text-white">
              for their kids Nursery admission
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col mx-auto py-[52px] items-center max-w-[470px]">
            <div className="text-xl font-medium text-center text-white text-opacity-80">
              Did you know?
            </div>
            <div className="self-stretch mt-1 w-full text-2xl font-semibold text-center text-white">
              Parents apply to 7 schools on average,
            </div>
            <div className="mt-1 text-lg text-center text-white">
              for their kids Nursery admission
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
