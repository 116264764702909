import React, { createContext, useState, ReactNode, FC } from 'react';

interface LoadingContextProps {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}
export const LoadingContext = createContext<LoadingContextProps>({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
});
export const LoadingProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};