import React, { useState, useRef, useEffect } from "react";
import "./DreamCareersStyles.scss";
import { Link } from "react-router-dom";
import type { SVGProps } from "react";

export function IcBaselinePlayArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="currentColor" d="M8 5v14l11-7z"></path>
    </svg>
  );
}

export function LineMdPlayToPauseTransition(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 15L8 18L8 6L13 9L13 9M13 9L18 12L18 12L13 15L13 15"
      >
        <animate
          fill="freeze"
          attributeName="d"
          dur="0.6s"
          keyTimes="0;0.33;1"
          values="M13 15L8 18L8 6L13 9L13 9M13 9L18 12L18 12L13 15L13 15;M13 15L8 18L8 6L13 9L13 15M13 9L18 12L18 12L13 15L13 9;M9 18L7 18L7 6L9 6L9 18M15 6L17 6L17 18L15 18L15 6"
        ></animate>
      </path>
    </svg>
  );
}

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 18L18 6M6 6l12 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LineMdArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path strokeDasharray={20} strokeDashoffset={20} d="M3 12h17.5">
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            dur="0.2s"
            values="20;0"
          ></animate>
        </path>
        <path
          strokeDasharray={12}
          strokeDashoffset={12}
          d="M21 12l-7 7M21 12l-7 -7"
        >
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            begin="0.2s"
            dur="0.2s"
            values="12;0"
          ></animate>
        </path>
      </g>
    </svg>
  );
}
interface Occupation {
  name: string;
  icon: string;
}

interface Career {
  name: string;
  iconUrl: string;
  title: string;
  occupations: Occupation[];
}

interface DiscoverDream {
  videoUrl: string;
  imageUrl: string;
  title: string;
}

interface DreamCareersProps {
  dreamCareers: {
    discoverDream: DiscoverDream;
    careers: Career[];
  };
}

const DreamBgColors = [
  "#F7F7F7",
  "#E8FDF6",
  "#F7F7E3",
  "#E5F1FB",
  "#FBE0E6",
  "#F7F7E3",
  "#DFFBDB",
];

const DreamCareers: React.FC<DreamCareersProps> = ({ dreamCareers }) => {
  const { discoverDream, careers } = dreamCareers;
  const [expandedCareerIndex, setExpandedCareerIndex] = useState<number | null>(
    null
  );
  const expandRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [heights, setHeights] = useState<number[]>([]);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const visibleOccupations = [3, 4, 2, 5, 3, 4, 2];

  useEffect(() => {
    if (
      expandedCareerIndex !== null &&
      expandRefs.current[expandedCareerIndex]
    ) {
      const newHeights = [...heights];
      newHeights[expandedCareerIndex] =
        expandRefs.current[expandedCareerIndex]?.scrollHeight || 0;
      setHeights(newHeights);
    }
  }, [expandedCareerIndex, careers]);

  const handleCareerClick = (index: number) => {
    if (expandedCareerIndex === index) {
      return;
    }
    setExpandedCareerIndex(index);
    if (expandRefs.current[index]) {
      const newHeights = [...heights];
      newHeights[index] = expandRefs.current[index]?.scrollHeight || 0;
      setHeights(newHeights);
    }
  };

  const togglePlayback = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (videoRef.current && entry.isIntersecting) {
            videoRef.current.play();
            setIsPlaying(true);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="py-8">
      <div className="container mx-auto">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            <h2 className="text-2xl lg:text-5xl font-bold lg:font-normal text-center">
              Dream Careers
            </h2>
          </div>

          <div className="flex flex-col lg:flex-row gap-1 h-full">
            <div className="w-full lg:w-9/12 relative">
              <ul className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-1">
                {careers.map((career, index) => {
                  const bgColor = DreamBgColors[index % DreamBgColors.length];
                  const isExpanded = expandedCareerIndex === index;
                  const contentHeight = heights[index] || 0;
                  const totalHeight = 151 + contentHeight;

                  return (
                    <li
                      key={index}
                      className={`dreamCareerSec transition-all duration-300 ${
                        isExpanded ? "expanded mb-10" : ""
                      }`}
                      style={{
                        height: isExpanded ? `${totalHeight}px` : "151px",
                      }}
                    >
                      <div
                        className="dreamCareerIcon relative p-4 border flex flex-col gap-3 justify-center items-center cursor-pointer"
                        style={{
                          backgroundColor: bgColor,
                        }}
                        onClick={() => handleCareerClick(index)}
                      >
                        <img
                          src={career.iconUrl}
                          alt={career.title}
                          className="w-14 h-14 object-cover mb-2"
                        />
                        <h5 className="text-sm text-black text-center">
                          {career.title}
                        </h5>
                      </div>

                      {isExpanded && (
                        <div
                          className="dreamCareerSecChild z-10 transition-all duration-300 overflow-hidden relative"
                          ref={(el) => (expandRefs.current[index] = el)}
                          style={{
                            maxHeight: `${contentHeight}px`,
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="bg-[#F8F8F8] shadow-md p-4 border border-[#cacaca] rounded-lg">
                            <button
                              className="closeButton absolute top-2 right-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                setExpandedCareerIndex(null);
                              }}
                            >
                              <CloseIcon />
                            </button>
                            <div className="careerExpandHeader flex flex-col gap-8">
                              <div className="flex flex-col gap-2.5">
                                <h6 className="text-blue text-base lg:text-lg font-bold">
                                  Explore occupations by {career.title}
                                </h6>
                                <p className="text-sm text-black">
                                  Select a learning area that interests you to
                                  view related occupations:
                                </p>
                              </div>
                              <div className="flex flex-wrap gap-4">
                                {career.occupations
                                  .slice(0, visibleOccupations[index])
                                  .map((occupation, occIndex) => (
                                    <Link
                                      key={occIndex}
                                      to="/career-intermediate"
                                      className="border border-[#CAC6C6] bg-white hover:bg-blue-gray-50 rounded-full flex items-center overflow-hidden"
                                    >
                                      <img
                                        src={occupation.icon}
                                        alt={occupation.name}
                                        className="w-10 h-10 rounded-tl-full rounded-bl-full object-cover"
                                      />
                                      <p className="py-2 px-3 text-base text-black">
                                        {occupation.name}
                                      </p>
                                    </Link>
                                  ))}
                              </div>

                              <Link
                                to="/career-intermediate"
                                className="text-blue text-base flex items-center justify-center gap-2"
                              >
                                View more <LineMdArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full lg:w-3/12 hidden lg:flex flex-col">
              <div className="flex flex-col gap-4 relative w-full h-full lg:h-full justify-between bg-top lg:bg-center bg-cover bg-no-repeat">
                <div className="flex flex-col justify-between h-full gap-4 relative">
                  <div className="flex flex-col relative h-full">
                    <video
                      ref={videoRef}
                      src={discoverDream.videoUrl}
                      className="w-full h-full object-cover"
                      muted={isMuted}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                    ></video>
                  </div>
                  <div className=" absolute top-1 left-1">
                    <p className="text-white text-sm">{discoverDream.title}</p>
                  </div>

                  <div className="flex justify-end gap-2 absolute bottom-2 right-2">
                    <button
                      className="bg-white text-black rounded-full p-2"
                      onClick={togglePlayback}
                    >
                      {isPlaying ? (
                        <LineMdPlayToPauseTransition />
                      ) : (
                        <IcBaselinePlayArrow />
                      )}
                    </button>
                    {/* <button
                      className="bg-white text-black rounded-full p-3"
                      onClick={toggleMute}
                    >
                      {isMuted ? (
                        <svg
                          width="1.5rem"
                          height="1.5rem"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 5h2.79l3.86-3.83.85.35v13l-.85.33L4.29 11H1.5l-.5-.5v-5l.5-.5zm3.35 5.17L8 13.31V2.73L4.85 5.85 4.5 6H2v4h2.5l.35.17zm9.381-4.108l.707.707L13.207 8.5l1.731 1.732-.707.707L12.5 9.207l-1.732 1.732-.707-.707L11.793 8.5 10.06 6.77l.707-.707 1.733 1.73 1.731-1.731z"
                          />
                        </svg>
                      ) : (
                        <svg
                          fill="currentColor"
                          width="1.5rem"
                          height="1.5rem"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.553 3.064A.75.75 0 0112 3.75v16.5a.75.75 0 01-1.255.555L5.46 16H2.75A1.75 1.75 0 011 14.25v-4.5C1 8.784 1.784 8 2.75 8h2.71l5.285-4.805a.75.75 0 01.808-.13zM10.5 5.445l-4.245 3.86a.75.75 0 01-.505.195h-3a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h3a.75.75 0 01.505.195l4.245 3.86V5.445z"
                          />
                          <path d="M18.718 4.222a.75.75 0 011.06 0c4.296 4.296 4.296 11.26 0 15.556a.75.75 0 01-1.06-1.06 9.5 9.5 0 000-13.436.75.75 0 010-1.06z" />
                          <path d="M16.243 7.757a.75.75 0 10-1.061 1.061 4.5 4.5 0 010 6.364.75.75 0 001.06 1.06 6 6 0 000-8.485z" />
                        </svg>
                      )}
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamCareers;
