import React, { useState, useRef, useEffect } from "react";
import BlogTab from "./BlogTab";
import CourseSpotlightsTab from "./CourseSpotlightsTab";
import CourseVisualStoriesTab from "./CourseVisualStoriesTab";
import WebinarsTab from "./WebinarsTab";
import "./ExploreMore.scss";
interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMore {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}
interface ExploreMoreProps {
  exploreMore: ExploreMore;
}

const ExploreMore: React.FC<ExploreMoreProps> = ({ exploreMore }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({ marginLeft: 0, width: 0 });
  const tabRef = useRef<HTMLButtonElement[]>([]);
  console.log(exploreMore);
  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setIndicatorToActiveTab(index);
  };

  const handleTabMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabRect = event.currentTarget.getBoundingClientRect();
    const containerRect =
      event.currentTarget.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;
    const halfWidth = tabRect.width / 2;
    const marginLeft =
      distanceTabStartToContainerStart + (tabRect.width - halfWidth) / 2;

    setTabIndicatorStyle({
      marginLeft: `${marginLeft}px`,
      width: `${halfWidth}px`,
    });
  };

  const handleTabMouseLeave = () => {
    setIndicatorToActiveTab(selectedTab);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;
    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const tabStart = tabRect.left;
    const containerStart = containerRect?.left || 0;
    const distanceTabStartToContainerStart = tabStart - containerStart;

    setTabIndicatorStyle({
      marginLeft: `${distanceTabStartToContainerStart + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(selectedTab);
  }, [selectedTab]);

  return (
    <div className="py-16 relative">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col justify-between gap-4 xl:w-4/5">
            <h3 className="text-3xl md:text-[40px] font-bold md:leading-[58px] text-black m-0">
              Explore More
            </h3>
          </div>
          <div className="flex flex-col gap-7">
            <div className="relative">
              <div
                className="tab-container gap-4 lg:gap-20"
                onMouseLeave={handleTabMouseLeave}
              >
                {[
                  "Blogs & Articles",
                  "Course Spotlights",
                  "Course Visual Stories",
                  "Webinars",
                ].map((tab, index) => (
                  <button
                    key={index}
                    ref={(el) => (tabRef.current[index] = el!)}
                    onClick={() => handleTabClick(index)}
                    onMouseEnter={handleTabMouseEnter}
                    className={`py-2 text-xs font-semibold leading-[1.375rem] font-open-sans md:text-xl hover:text-[#173CBA] transition-all duration-300 ease-in-out ${
                      selectedTab === index
                        ? " text-[#173CBA] font-bold"
                        : "font-normal text-[#A8A8A8]"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
                <div
                  className="absolute bottom-[-4px] h-[6px] bg-[#00C798]  rounded-lg transition-all duration-300 ease-in-out"
                  style={tabIndicatorStyle}
                />
              </div>
            </div>
            {selectedTab === 0 && <BlogTab articles={exploreMore.articles} />}
            {selectedTab === 1 && (
              <CourseSpotlightsTab spotLight={exploreMore.spotLight} />
            )}
            {selectedTab === 2 && (
              <CourseVisualStoriesTab
                visualStories={exploreMore.visualStories}
              />
            )}
            {selectedTab === 3 && (
              <WebinarsTab webinars={exploreMore.webinar} />
            )}
          </div>
        </div>
      </div>
      {selectedTab !== 2 && (
        <div className="flex gap-6 absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-6 md:-translate-y-12">
          <button className="mentor-prev-btn flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-solid border-black rounded-[50px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 11H9L12.29 7.71C12.3837 7.61703 12.4581 7.50643 12.5089 7.38457C12.5597 7.26272 12.5858 7.13201 12.5858 7C12.5858 6.86799 12.5597 6.73728 12.5089 6.61542C12.4581 6.49356 12.3837 6.38296 12.29 6.29C12.1026 6.10375 11.8492 5.99921 11.585 5.99921C11.3208 5.99921 11.0674 6.10375 10.88 6.29L6.59 10.59C6.21441 10.9633 6.00223 11.4704 6 12C6.00487 12.5261 6.21684 13.0291 6.59 13.4L10.88 17.7C10.9732 17.7926 11.0838 17.8659 11.2054 17.9157C11.3269 17.9656 11.4571 17.991 11.5885 17.9905C11.7199 17.9901 11.8499 17.9637 11.9712 17.913C12.0924 17.8623 12.2024 17.7882 12.295 17.695C12.3876 17.6017 12.4609 17.4912 12.5107 17.3696C12.5606 17.248 12.586 17.1178 12.5856 16.9864C12.5851 16.8551 12.5588 16.725 12.508 16.6038C12.4573 16.4826 12.3832 16.3726 12.29 16.28L9 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                fill="#333333"
              />
            </svg>
          </button>
          <button className="mentor-next-btn flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-solid border-black rounded-[50px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 11H15L11.71 7.71C11.6163 7.61703 11.5419 7.50643 11.4911 7.38457C11.4403 7.26272 11.4142 7.13201 11.4142 7C11.4142 6.86799 11.4403 6.73728 11.4911 6.61542C11.5419 6.49356 11.6163 6.38296 11.71 6.29C11.8974 6.10375 12.1508 5.99921 12.415 5.99921C12.6792 5.99921 12.9326 6.10375 13.12 6.29L17.41 10.59C17.7856 10.9633 17.9978 11.4704 18 12C17.9951 12.5261 17.7832 13.0291 17.41 13.4L13.12 17.7C13.0268 17.7926 12.9162 17.8659 12.7946 17.9157C12.6731 17.9656 12.5429 17.991 12.4115 17.9905C12.2801 17.9901 12.1501 17.9637 12.0288 17.913C11.9076 17.8623 11.7976 17.7882 11.705 17.695C11.6124 17.6017 11.5391 17.4912 11.4893 17.3696C11.4394 17.248 11.414 17.1178 11.4144 16.9864C11.4149 16.8551 11.4412 16.725 11.492 16.6038C11.5427 16.4826 11.6168 16.3726 11.71 16.28L15 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11Z"
                fill="#333333"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default ExploreMore;
