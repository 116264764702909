import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

interface WebinarCardProps {
  items: {
    title: string;
    by: string;
    description: string;
    date: string;
    time: string;
    image1: string;
    image2: string;
    name1: string;
    name2: string;
    link: string;
  }[];
  handlePopup: (link: string) => void;
}

const WebinarCard: React.FC<WebinarCardProps> = ({ items, handlePopup }) => {
  return (
    <Swiper
      spaceBetween={16}
      navigation
      modules={[Navigation]}
      breakpoints={{
        640: { slidesPerView: 1, slidesPerGroup: 1 },
        768: { slidesPerView: 2, slidesPerGroup: 2 },
        1024: { slidesPerView: 4, slidesPerGroup: 4 },
      }}
      className="ExploreMore w-full"
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="rounded-xl flex flex-col  translate-y-10 px-4">
            <div className="relative">
              <div className="flex flex-col h-[371px] rounded-xl border-b-4 border-[#FFD540]" style={{ backgroundImage: "linear-gradient(to left, #06AFA9, #173CBA)" }}>
                <div className="p-4">
                  <a href="/webinar-details-page">
                    <div className="text-white text-2xl font-bold line-clamp-1">{item.title}</div>
                  </a>
                  <p className="text-white text-sm font-light mt-1">Get guidance from experts</p>
                  <div className="mt-2">
                    <p className="font-roboto text-[10px] text-[#ffffff] border border-[#FFD540] inline-block p-1 rounded-lg translate-y-1" style={{ fontWeight: 300 }}>
                      Class XII students invited
                    </p>
                    <div className="bg-[#FFD540] inline-block px-2 pt-2 pl-6 -translate-x-6 rounded-br-lg">
                      <p className="font-roboto text-[10px] text-[#383838]">GET A PSYCHOMETRIC TEST WORTH</p>
                      <p className="font-roboto font-semibold text-base text-[#383838]">INR 1000 FOR FREE</p>
                    </div>
                  </div>
                  <p className="text-white text-xs mt-2 mb-4">{item.date} {item.time}</p>
                </div>
                <div className="flex justify-center items-center gap-2 mb-6">
                  <div className="text-center">
                    <img src={item.image1} alt={item.name1} className="w-12 h-12 rounded-full object-cover" />
                    <p className="text-white text-xs">{item.name1}</p>
                  </div>
                  <div className="text-center">
                    <img src={item.image2} alt={item.name2} className="w-12 h-12 rounded-full object-cover" />
                    <p className="text-white text-xs">{item.name2}</p>
                  </div>
                </div>
              </div>
              <div className="absolute -bottom-4 left-0 flex justify-center items-center bg-white border border-[#FFD540] text-[#383838] text-sm px-4 py-2 rounded-full">
                <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                  <path d="M11.5 0L22.3253 18.75H0.674683L11.5 0Z" fill="#D9D9D9" />
                </svg>
                <span>Online Event</span>
              </div>
              <div className="absolute -bottom-4 right-0 flex items-center bg-[#FFD540] text-[#383838] text-sm px-6 py-2 rounded-lg">
                <button onClick={() => handlePopup(item.link)} className="font-semibold">I'm Interested</button>
              </div>
            </div>
            
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default WebinarCard;
