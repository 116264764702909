import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

interface VideoCardWithModalProps {
  items: {
    title: string;
    description: string;
    image: string;
    views: string;
    postedOn: string;
    link: string;
  }[];
}

const VideoCardWithModal: React.FC<VideoCardWithModalProps> = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoLink, setVideoLink] = useState<string | null>(null);

  const openVideoModal = (link: string) => {
    setVideoLink(link);
    setIsModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsModalOpen(false);
    setVideoLink(null);
  };

  return (
    <Swiper
      spaceBetween={16}
      modules={[Navigation]}
      breakpoints={{
        640: { slidesPerView: 1, slidesPerGroup: 1 },
        768: { slidesPerView: 2, slidesPerGroup: 2 },
        1024: { slidesPerView: 4, slidesPerGroup: 4 },
      }}
      className="ExploreMore w-full"
      navigation={{
        prevEl: ".mentor-prev-btn",
        nextEl: ".mentor-next-btn",
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="max-w-xs mx-auto translate-y-10 px-4">
            <div
              className="block p-4 bg-blue-light rounded-xl overflow-hidden cursor-pointer"
              onClick={() => openVideoModal(item.link)}
            >
              <div className="relative">
                <img src={item.image} alt={item.title} className="w-full h-48 rounded-lg object-cover" />
                <FontAwesomeIcon
                  icon={faPlay}
                  size="lg"
                  className="absolute text-white opacity-80 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              </div>
              <h4 className="text-base font-semibold text-black line-clamp-2 mt-2">
                {item.title}
              </h4>
              <p className="text-sm text-grey">
                <span>{item.views}</span> • <span>{item.postedOn}</span>
              </p>
              <p className="text-sm text-grey line-clamp-2 mt-2">{item.description}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
      {isModalOpen && videoLink && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-black opacity-50 z-40">
        <div
          className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50"
          onClick={closeVideoModal}
        >
          <button className="absolute top-8 right-8" onClick={closeVideoModal}>
            &times;
          </button>
          <div
            className="modal-content relative m-0 duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
            style={{
              animation: "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={videoLink}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div></div>
      )}
    </Swiper>
  );
};

export default VideoCardWithModal;
