import React, { useState, useEffect, useRef, useMemo } from "react";
import "./ExcelinCompetitiveExams.scss";
import type { SVGProps } from "react";
import ApplyNowForm from "../ApplyNowForm";

import collegeImage from "../../../../images/exams/competitive/college-image.jpeg";
import { Sub } from "@radix-ui/react-navigation-menu";
import { Link } from "react-router-dom";

export function LineMdArrowSmallRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g transform="translate(24 0) scale(-1 1)">
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path strokeDasharray={14} strokeDashoffset={14} d="M19 12H5.5">
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.3s"
              values="14;0"
            ></animate>
          </path>
          <path
            strokeDasharray={8}
            strokeDashoffset={8}
            d="M5 12L10 17M5 12L10 7"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.3s"
              dur="0.2s"
              values="8;0"
            ></animate>
          </path>
        </g>
      </g>
    </svg>
  );
}

export function MdiLightArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19 13H6.75L12 18.25l-.66.75l-6.5-6.5l6.5-6.5l.66.75L6.75 12H19z"
      ></path>
    </svg>
  );
}

type IExamStatus =
  | "Active"
  | "Closing Soon"
  | "Completed"
  | "Pending"
  | "QUIZZES"
  | "Upcoming";

interface IExam {
  examOf: string;
  application: Application;
  stream: string;
  description: string;
  collegeImg: string;
  examName: string;
  id: number;
  type: string;
  status: IExamStatus;
  collegeMentorDiscount: string;
}

interface Application {
  fees: string;
  examDate: string;
  opendDates: string;
}

const title = "Excel in Competitive Exams";

const description =
  "Our platform offers the latest exam information, online study materials, test papers, model question papers, and AI-driven mock tests. Empower your exam preparation with our comprehensive resources.";

function ExcelinCompetitiveExams() {
  const [isCategoryLoading, setIsCategoryLoading] = useState<boolean>(true);
  const [isExamsLoading, setIsExamsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [examsOfs, setExamsOfs] = useState<string[]>([]);

  const [exams, setExams] = useState<Array<IExam>>([]);

  const [statuses, setStatuses] = useState<IExamStatus[]>([]);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);
  const [selectedExamOfIndex, setSelectedExamOfIndex] = useState<number>(0);

  const [selectedExamStatus, setSelectedExamStatus] =
    useState<IExamStatus>("Active");

  useEffect(() => {
    async function fetchCategories() {
      const selectedCategory = categories[selectedCategoryIndex];

      console.log("Selected Category", selectedCategory, selectedExamOfIndex);

      setIsCategoryLoading(true);
      try {
        const response = await fetch(
          `https://mentormenteedev.com/api/competitiveExam/details/onetwo`
        ).then((r) => r.json());

        const { categories } = response?.data;

        setCategories(categories);
      } catch (error) {
        console.log("Error fetching exams", error);
      } finally {
        setIsCategoryLoading(false);
      }
    }
    fetchCategories();
  }, []);
  useEffect(() => {
    async function fetchExams() {
      const selectedCategory = categories[selectedCategoryIndex];

      console.log("Selected Category", selectedCategory, selectedExamOfIndex);

      setIsExamsLoading(true);
      // if (!selectedCategory) {
      //   return console.log("No selected category");
      // }
      try {
        const response = await fetch(
          `https://mentormenteedev.com/api/competitiveExam/details/${selectedCategory}`
        ).then((r) => r.json());

        const { examsOfs, status, exams, categories } = response?.data;
        console.log("Exams", examsOfs, response.data);

        // setCategories(categories);

        setExamsOfs(examsOfs);
        setStatuses(status);
        setExams(exams);
      } catch (error) {
        console.log("Error fetching exams", error);
      } finally {
        setIsExamsLoading(false);
      }
    }
    fetchExams();
  }, [selectedCategoryIndex, categories]);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [mobScrollRight, setmobScrollRight] = useState(true);
  const [mobScrollLeft, setmobScrollLeft] = useState(false);

  const menuRef = useRef<HTMLUListElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (menuRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };
  const handleScrollMobile = () => {
    if (menuRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
      setmobScrollLeft(scrollLeft > 0);
      setmobScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.addEventListener("scroll", handleScrollMobile);
    }
    handleScrollMobile();
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener("scroll", handleScrollMobile);
      }
    };
  }, []);

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.addEventListener("scroll", handleScroll);
    }
    handleScroll();
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const scrollCarousel = (direction: number) => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const scrollAmount = 200;
      carousel.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    } else {
      console.log("carouselRef.current is null");
    }
  };

  return (
    <div className="flex flex-col justify-center bg-[#FFF9E5] py-9">
      <div className="container mx-auto">
        <div className="flex flex-col items-center gap-6">
          <div className="flex flex-col justify-center items-center gap-4 lg:w-1/2 mx-auto">
            <h3 className="text-3xl text-center font-bold lg:font-normal lg:text-5xl leading-normal text-black">
              {title}
            </h3>
            <p className="text-center text-base text-black leading-7">
              {description}
            </p>
          </div>

          <div
            className={`w-full mx-auto relative max-md:px-3 py-4 rounded-lg`}
            style={{
              background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
            }}
          >
            <div
              className={`w-full md:w-[90%] relative transition duration-300 flex items-center mx-auto`}
            >
              {isCategoryLoading && (
                <div className="w-full h-full mx-auto absolute bg-gray-50 bg-opacity-50 z-10">
                  <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </section>
                </div>
              )}

              <button
                onClick={scrollLeft}
                className={`absolute left-0 p-1 bg-[#00CC99] border border-white rounded text-white ${!canScrollLeft ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                disabled={!canScrollLeft}
              >
                <LineMdArrowSmallRight className="rotate-180" />
              </button>
              <div className="flex items-center overflow-hidden px-12">
                <ul
                  className="buttonTabs flex gap-4 overflow-x-auto whitespace-nowrap scrollbar-hide list-none"
                  ref={menuRef}
                >
                  {categories?.map((eachCategory, index) => (
                    <li key={eachCategory}>
                      <button
                        className={`buttonTab rounded text-black py-1.5 px-3.5 font-semibold ${selectedCategoryIndex === index
                          ? "active bg-[#14E0AD] duration-75"
                          : "bg-white duration-75"
                          } cursor-pointer`}
                        onClick={() => {
                          // setActiveTab(tab.id);
                          setSelectedCategoryIndex(index);
                          // setSelectedTopTab(tab.id);

                          // setSelectedSubTab(tab.subTabs[0].id);
                        }}
                      >
                        {eachCategory}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={scrollRight}
                className={`absolute right-0 p-1 bg-[#00CC99] border border-white rounded text-white ${!canScrollRight ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                disabled={!canScrollRight}
              >
                <LineMdArrowSmallRight />
              </button>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-5 w-full relative">
            {isExamsLoading && (
              <div className="w-full h-full mx-auto absolute bg-gray-50 bg-opacity-55 z-10">
                <section className="dots-container relative z-20">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </section>
              </div>
            )}

            <div className="flex flex-col gap-8 lg:w-1/4 bg-blue px-5 py-10 max-md:rounded-md">
              {examsOfs?.map((eachExamOf, index) => (
                <button
                  key={eachExamOf}
                  className={`p-4 rounded text-left cursor-pointer text-base font-semibold text-black ${selectedExamOfIndex === index
                    ? "border-l-4 border-pink bg-white"
                    : "border-l-4 border-[#BECDFD] bg-[#BECDFD]"
                    }`}
                  onClick={() => {
                    setSelectedExamOfIndex(index);
                  }}
                >
                  {eachExamOf}
                </button>
              ))}
            </div>
            <div className="flex flex-col gap-4 w-full lg:w-3/4">
              <div className="relative overflow-hidden">
                <div
                  className="flex flex-row gap-5 transition-transform duration-300 ease-in-out max-md:overflow-x-auto max-md:cursor-all-scroll"
                  ref={carouselRef}
                >
                  {statuses?.map((status) => (
                    <button
                      key={status}
                      className={`py-3 rounded-md  text-sm px-8 text-white duration-75 whitespace-nowrap cursor-pointer ${selectedExamStatus === status
                        ? "font-bold bg-green-3"
                        : "font-normal bg-[#93D7C6] md:bg-green-3"
                        }`}
                      onClick={() => {
                        setSelectedExamStatus(status);
                      }}
                    >
                      {status}
                    </button>
                  ))}
                </div>
                <button
                  className={`absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-600 text-white p-1 z-10 cursor-pointer hidden max-md:block `}
                  onClick={() => scrollCarousel(-1)}
                >
                  <MdiLightArrowLeft />
                </button>
                <button
                  className={`absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-600 text-white p-1 z-10 cursor-pointer hidden max-md:block `}
                  onClick={() => scrollCarousel(1)}
                >
                  <MdiLightArrowLeft className="rotate-180" />
                </button>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-8">
                {exams?.slice(0, 4).map((exam, index) => {
                  return <ExamCard exam={exam} key={index} />;
                })}
              </div>
              {exams.length > 4 && (
                <div className="flex justify-center mt-4">
                  <Link
                    to="/exam-intermediate"
                    className="bg-green text-white text-base font-bold py-3.5 px-8 rounded-full "
                  >
                    View all Exams
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ExamCard = ({ exam }: { exam: IExam }) => {
  const [liked, setLiked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleButtonClick = () => {
    setShowPopup(true);
    document.body.classList.add("no-scroll");
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.classList.remove("no-scroll");
  };
  return (
    <div
      className="flex flex-col gap-4 bg-white rounded-md p-1 border-2 border-white"
      style={{
        boxShadow: "0px 0.8px 1.6px 0px #A199C214",
      }}
    >
      <div className="flex flex-row gap-3 w-full px-3 pt-3 pb-5 items-start">
        <div className="flex flex-col gap-2">
          <div className="w-10 h-10 md:w-16 md:h-16 bg-[#F5F5F5] rounded-full overflow-hidden flex">
            <img
              src={exam.collegeImg}
              alt="college"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2 w-full justify-between">
            <div className="flex flex-row gap-2">
              <h3 className="text-base font-bold text-black">
                {exam.examName}
              </h3>
              <p className="text-base text-[#44941E] flex gap-1 items-center">
                <svg
                  width="5"
                  height="5"
                  viewBox="0 0 5 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2.5" cy="2.91895" r="2" fill="#44941E" />
                </svg>
                {exam.status}
                <span className="text-[#6B6C6B]">
                  {"("}
                  {exam.type}
                  {")"}
                </span>
              </p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <button
                onClick={() => setLiked(!liked)}
                className="focus:outline-none cursor-pointer"
              >
                <svg
                  width="14px"
                  height="13px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${liked
                    ? "fill-current text-[#DC0D0D] duration-75"
                    : "fill-current text-white"
                    }`}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.50001 2.60272C6.10047 0.895531 3.76182 0.367937 2.00829 1.93121C0.25475 3.49448 0.00787601 6.10816 1.38494 7.95707C2.52988 9.49425 5.99485 12.7364 7.13048 13.7858C7.25749 13.9032 7.32104 13.9619 7.39516 13.9849C7.45979 14.005 7.53057 14.005 7.59528 13.9849C7.66941 13.9619 7.73287 13.9032 7.85996 13.7858C8.9956 12.7364 12.4605 9.49425 13.6055 7.95707C14.9825 6.10816 14.7658 3.47803 12.9821 1.93121C11.1984 0.384382 8.89954 0.895531 7.50001 2.60272Z"
                    stroke={`${liked ? "#DC0D0D duration-75" : "black"}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <p className="text-sm text-black">
            {exam.description.length > 100
              ? `${exam.description.slice(0, 100)}...`
              : exam.description}
            <Link to="/exam-details" className="text-blue-500">
              {" "}
              Read More
            </Link>
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4 border-t-4 border-blue w-full bg-[#F5F3F7] rounded-br rounded-bl p-2.5">
        <div className="flex flex-row gap-3">
          <p className="text-xs text-grey-7 flex flex-col gap-2 relative">
            <span className="flex gap-[2px]">
              Appl Fees {"("}
              <em
                className="text-pink relative"
                // onMouseEnter={() => toggleTooltip(index)}
                // onMouseLeave={() => toggleTooltip(null)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {"i"}

                {isHovered && (
                  <div className="applicationFeeTooltip absolute bg-white -top-4 left-2 p-2 rounded-lg shadow-sm w-56">
                    <h5 className="text-[10px] text-black">
                      Get College Mentor Discount: Rs.100
                    </h5>
                    <p className="text-[#7D7B89] text-[10px]">
                      (Premium members of College Mentor will receive discounts)
                    </p>
                  </div>
                )}
              </em>
              {")"}
            </span>
            <span className="text-green-4 text-sm font-semibold">
              {exam.application.fees}
            </span>
          </p>
          <hr className="border-r border-[#CBCBCB] w-[1px] h-full rounded" />
          <p className="text-xs text-grey-7 flex flex-col gap-2">
            Appl Open
            <span className="text-green-4 text-sm font-semibold">
              {exam.application.opendDates}
            </span>
          </p>
          <hr className="border-r border-[#CBCBCB] w-[1px] h-full rounded" />
          <p className="text-xs text-grey-7 flex flex-col gap-2">
            Exam Date
            <span className="text-green-4 text-sm font-semibold">
              {exam.application.examDate}
            </span>
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <button
            className="bg-[#173CBA] text-white text-sm font-normal py-2 px-3 rounded-lg leading-4"
            onClick={handleButtonClick}
          >
            Apply Now
          </button>
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <button
                  className="absolute top-2 right-2 text-black"
                  onClick={handleClosePopup}
                >
                  &times;
                </button>
                <ApplyNowForm />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcelinCompetitiveExams;
