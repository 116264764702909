import React, { useState, useEffect } from "react";
import collegeHistory1985 from "../../../../images/college/details/history-1985.png";
import collegeHistory2002 from "../../../../images/college/details/history-2002.png";
import collegeHistory2003 from "../../../../images/college/details/history-2003.png";
import collegeHistory2010 from "../../../../images/college/details/history-2010.png";
import collegeHistory2013 from "../../../../images/college/details/history-2013.png";
import collegesIcon from "../../../../images/college/details/Colleges.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const historyContent = [
  {
    statisticsData: [
      {
        year: "1985",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Launch of SRM Engineering College in Kattankulathur, near Chennai",
      },
      {
        year: "2002",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Sec.3 of the UGC Act 1956 Deemed Status to SRM Institute of Science and Technology, Chennai (incl SRM Dental College, Ramapuram)",
      },
      {
        year: "2003",
        title: "SRM History",
        image: collegesIcon,
        content:
          "SRM Engineering College, Kattankulathur, Chennai was brought under the ambit of SRMIST, Chennai, a deemed to be university.",
      },
      {
        year: "2010",
        title: "SRM History",
        image: collegesIcon,
        content: "Inter Disciplinary School of Indian System of Medicine.",
      },
      {
        year: "2013",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Launch of SRM Institute of Science and Technology (formerly known as SRM University), Haryana",
      },
      {
        year: "2015",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Launch of SRM Engineering College in Kattankulathur, near Chennai",
      },
      {
        year: "2018",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Sec.3 of the UGC Act 1956 Deemed Status to SRM Institute of Science and Technology, Chennai (incl SRM Dental College, Ramapuram)",
      },
      {
        year: "2020",
        title: "SRM History",
        image: collegesIcon,
        content:
          "SRM Engineering College, Kattankulathur, Chennai was brought under the ambit of SRMIST, Chennai, a deemed to be university.",
      },
      {
        year: "2022",
        title: "SRM History",
        image: collegesIcon,
        content: "Inter Disciplinary School of Indian System of Medicine.",
      },
      {
        year: "2024",
        title: "SRM History",
        image: collegesIcon,
        content:
          "Launch of SRM Institute of Science and Technology (formerly known as SRM University), Haryana",
      }
    ],
  },
];

const CollegeHistorySection = (collegeDetails: any) => {
  const { collegeOverview } = collegeDetails.collegeDetails;
  const [showYear, setShowYear] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowYear(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="college-history tab-content py-16 bg-[#F4F7FB]" id="History">
        <div className="flex justify-between items-center">
          <div className="container mx-auto">
            <div className="flex flex-col items-center gap-14 w-full">
              <div className="flex flex-row items-center">
                <h3 className="text-3xl font-extrabold text-[#231F40]">
                  {collegeOverview.CollegeName} History
                </h3>
              </div>
              <div className="w-full h-full relative">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={5}
                  navigation={{
                    prevEl: ".history-swiper-button-prev",
                    nextEl: ".history-swiper-button-next",
                  }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Autoplay]}
                  loop={false}
                  className="h-full w-full flex flex-row justify-center items-center"
                >
                  {historyContent[0].statisticsData.map((history, index) => (
                    <SwiperSlide key={index}>
                      <div
                        key={index}
                        className="flex flex-col flex-grow justify-center items-center gap-2.5"
                      >
                        <div className="w-20 h-20 overflow-hidden rounded-full border bg-white	border-[#E0E0E0]	p-1 flex items-center justify-center">
                          <img
                            src={history.image}
                            alt="history"
                            className="w-1/2 h-1/2 object-cover"
                          />
                        </div>
                        <div className="relative w-full flex justify-center">
                          <h3
                            className={`stext-xl font-semibold text-[#000000] mb-0 mt-1 ${showYear ? "opacity-100" : "opacity-0"
                              }`}
                          >
                            {showYear ? history.year : ""}
                          </h3>
                          {index < historyContent[0].statisticsData.length - 1 && <div className="absolute w-1/2 top-1/2 -translate-y-1/2 left-3/4 flex items-center">
                            <div className="h-1 w-full bg-[#05B38B]"></div>
                            <svg className="svg-icon" width={12} viewBox="211.184 61.257 599.22 901.485" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M211.184 962.742l599.22-450.742-599.22-450.743z" fill="#05B38B" />
                            </svg>
                          </div>}
                        </div>
                        {/* <h4 className="text-2xl font-bold text-[#231F40]">
                      {history.title}
                    </h4> */}
                        <p className="text-sm text-[#4F4F4F] text-center font-medium leading-6	">
                          {history.content}
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="history-swiper-button-prev absolute top-1/2 -left-8">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={24}
                      height={24}
                    >
                      <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>{" "}
                  </button>
                </div>
                <div className="history-swiper-button-next absolute top-1/2 -right-8">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={24}
                      height={24}
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollegeHistorySection;
