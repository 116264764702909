import React from "react";
import "./ExamDownloads.scss";

const ExamDownloads = (data: any) => {
  const downloadsData = data?.data?.downloads;
  return (
    <div>
      <div className=" bg-[#DAFFF7] w-full mb-7">
        <h3 className="downloads-title text-center pt-11 mb-11">FreeDownloads</h3>
        <div className="container mx-auto">
          <div className="mx-auto lg:w-[1280px] max-md:flex-none max-md:h-[430px] max-md:overflow-hidden md:overflow-hidden   max-xl:mx-12 max-xl:flex-wrap  md:flex flex-row gap-5 w-full pb-12 max-md:flex-col  min-max-xs:ml-12 min-max-xss:ml-5 min-max-sm:ml-20 ">
            {downloadsData.map((data: any) => {
              return (
                <div className="max-md:mb-5   w-[305px] flex overflow-hidden gap-2.5 px-5 py-3.5 text-xs bg-white rounded-lg shadow-[0px_3px_12px_rgba(0,0,0,0.12)]">
                  <div className="w-[114px] flex flex-col px-2 py-3 text-center text-white rounded-md bg-[#0A0A60]">
                    <img
                      loading="lazy"
                      src="/images/exam-downloads.png"
                      className="object-contain aspect-[5.68] w-[91px] ml-1"
                    />
                    <h1 className="exam-downloads self-center mt-7 font-bold">
                      KEAM 2024 JUNE 09
                    </h1>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="91"
                      height="1"
                      viewBox="0 0 91 1"
                      fill="none"
                      className="object-contain mt-1 border border-white border-solid aspect-[90.91] w-[91px]"
                    >
                      <path
                        d="M0.5 0.498047H90.5798"
                        stroke="white"
                        stroke-width="0.614106"
                        stroke-linecap="round"
                      />
                    </svg>
                    <p className="mt-4 font-semibold download-paper">
                      {data.description}
                    </p>
                  </div>
                  <div className="w-[145px] flex flex-col self-start mt-4 md:h-full relative max-md:h-[160px]">
                    <p className="font-bold text-[#262626] download-jee">
                      {data.title}
                    </p>
                    <div className="flex gap-1.5 self-start mt-3.5 ">
                      <img
                        loading="lazy"
                        src="/images/download-img.jpg"
                        className="object-contain shrink-0 aspect-square w-[18px]"
                      />
                      <span className="flex-auto my-auto text-[#888]">
                        {data.downloads} + Downloads
                      </span>
                    </div>
                    <a
                      href="/downloads/sample.pdf"
                      download="sample.pdf"
                      className="flex gap-1.5 justify-center items-center py-2 pr-3.5 pl-3.5 mt-11 font-semibold text-white bg-[#173CBA] rounded-[50px] absolute bottom-6"
                    >
                      <span className="self-stretch my-auto">
                        Free Download
                      </span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[0.92] w-[11px] "
                      >
                        <path
                          d="M1.90716 8.7468C1.90716 8.42884 1.64939 8.17107 1.33143 8.17107C1.01347 8.17107 0.755707 8.42884 0.755707 8.7468H1.90716ZM12.3853 8.7468C12.3853 8.42884 12.1276 8.17107 11.8096 8.17107C11.4917 8.17107 11.2339 8.42884 11.2339 8.7468H12.3853ZM6.11654 9.16032C5.92103 9.41103 5.9657 9.77281 6.21641 9.96841C6.46712 10.1639 6.82891 10.1192 7.0245 9.86854L6.11654 9.16032ZM10.0183 6.03038C10.2138 5.77967 10.1691 5.41788 9.9184 5.22229C9.66769 5.02677 9.3059 5.07145 9.11031 5.32216L10.0183 6.03038ZM6.11654 9.86854C6.31214 10.1192 6.67392 10.1639 6.92463 9.96841C7.17534 9.77281 7.22001 9.41103 7.0245 9.16032L6.11654 9.86854ZM4.03072 5.32216C3.83516 5.07145 3.47338 5.02677 3.22267 5.22229C2.97195 5.41788 2.92724 5.77967 3.12279 6.03038L4.03072 5.32216ZM5.9948 9.51443C5.9948 9.83238 6.25257 10.0902 6.57052 10.0902C6.88847 10.0902 7.14625 9.83238 7.14625 9.51443H5.9948ZM7.14625 1.07048C7.14625 0.752514 6.88847 0.494751 6.57052 0.494751C6.25257 0.494751 5.9948 0.752514 5.9948 1.07048H7.14625ZM0.755707 8.7468V9.51443H1.90716V8.7468H0.755707ZM0.755707 9.51443C0.755707 11.0905 2.00508 12.3931 3.57676 12.3931V11.2416C2.66832 11.2416 1.90716 10.4822 1.90716 9.51443H0.755707ZM3.57676 12.3931H9.56429V11.2416H3.57676V12.3931ZM9.56429 12.3931C11.1359 12.3931 12.3853 11.0905 12.3853 9.51443H11.2339C11.2339 10.4822 10.4727 11.2416 9.56429 11.2416V12.3931ZM12.3853 9.51443V8.7468H11.2339V9.51443H12.3853ZM7.0245 9.86854L10.0183 6.03038L9.11031 5.32216L6.11654 9.16032L7.0245 9.86854ZM7.0245 9.16032L4.03072 5.32216L3.12279 6.03038L6.11654 9.86854L7.0245 9.16032ZM7.14625 9.51443V1.07048H5.9948V9.51443H7.14625Z"
                          fill="#00CC99"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="dc-btn-section text-center pb-12 max-md:mt-2">
          <a
            href="/download-exams"
            className="arrow-button btn-dc white-color button-styles map-mentor"
          >
            View all
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExamDownloads;
