import Arrow from "../../../images/Arrow.svg";
import { Link } from "react-router-dom";
const OtherCards: React.FC<{
    image: string;
    title: string;
    description: string;
    name: string;
    filterKey: string;
}> = ({ image, title, description, filterKey, name }) => {
    return (
        <Link
            to={`list-of-courses-in-india?${name}=${filterKey}`}
            className="block rounded-xl overflow-hidden"
        >
            <div className="flex flex-col gap-5 overflow-hidden">

                <img
                    src={image}
                    alt={title}
                    className="w-full h-full rounded-xl object-contain"
                />
            </div>
            <div className="flex px-6">
                <div className="relative w-full flex  flex-col bg-white border pb-2 -translate-y-6  z-2 rounded-2xl justify-center items-center">
                    <div className="text-base font-bold text-blue pt-4 pb-2">
                        {title}
                    </div>
                    <div className="font-open-sans text-sm line-clamp-2 text-center text-[#383838] px-10">
                        {description}
                    </div>
                    <div className="pt-4">
                        <img src={Arrow} className="arrow-icon" />
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default OtherCards;