// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-story .success-story-card {
  scale: 0.8;
  transition: all 2s ease-in-out;
}
.success-story .swiper-slide-next .success-story-card {
  scale: 1.1;
  border: 1px solid #173cba;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-compare/components/SuccessStories.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,8BAAA;AAAJ;AAII;EACE,UAAA;EACA,yBAAA;AAFN","sourcesContent":[".success-story {\n  .success-story-card {\n    scale: 0.8;\n    transition: all 2s ease-in-out;\n  }\n\n  .swiper-slide-next {\n    .success-story-card {\n      scale: 1.1;\n      border: 1px solid #173cba;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
