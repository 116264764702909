import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

export const CourseFeeChart = () => {
  const { data } = useCourseDetails();
  const { courseFeeBarGraph } = data || {};
  return (
    <ResponsiveContainer width="100%" height={442}>
      <BarChart
        data={courseFeeBarGraph}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="feesRange" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="noOfColleges"
          fill="#3e8ff2"
          activeBar={<Rectangle fill="#D1F2FB" stroke="#D1F2FB" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
