import React from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
interface Course {
  id: number;
  courseId: number;
  durationOfCourse: string;
  degreeLevel: string;
  eligibilityCriteria: string;
}

interface CourseTableProps {
  courseData: Course[];
}
export const EligibilityCriteria = ({ courseData }: CourseTableProps) => {
  const { data } = useCourseDetails();
  const { courseTitle = "" } = data || {};
  return (
    <div className="relative bg-white">
      <div className="flex max-md:w-full">
        <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
          {`Eligibility Criteria (Diploma, UG, PG & Ph.D) of ${courseTitle}`}
        </h3>
      </div>
      <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 relative overflow-x-auto">
        <table className="w-full border-[#383838] border">
          <thead>
            <tr className="bg-[#EEF2FF]">
              <th className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                Course Level
              </th>
              <th className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                Eligibility Criteria
              </th>
              <th className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                Duration
              </th>
            </tr>
          </thead>
          <tbody>
            {courseData.map((course: any, index: number) => (
              <tr
                key={course.id}
                className={`border-[#383838] border ${
                  index % 2 === 0 ? "bg-[#fff]" : "bg-[#EEF2FF]"
                }`}
              >
                <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                  {course.degreeLevel}
                </td>
                <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                  {course.eligibilityCriteria}
                </td>
                <td className="py-2.5 px-5 text-base text-[#4C495C]">
                  {course.durationOfCourse}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
