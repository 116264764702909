import React, { useState, useEffect } from "react";
import iconOne from "../../../../images/college/details/admissions/add-user.png";
import iconTwo from "../../../../images/college/details/admissions/check.png";
import iconThree from "../../../../images/college/details/admissions/user.png";
import iconFour from "../../../../images/college/details/admissions/upload.png";
import iconFive from "../../../../images/college/details/admissions/user.png";

const historyContent = [
  {
    statisticsData: [
      {
        title: "Create an account",
        image: iconOne,
      },

      {
        title: "Verify Email",
        image: iconTwo,
      },
      {
        title: "Fill application form online",
        image: iconThree,
      },
      {
        title: "Upload Required Documents",
        image: iconFour,
      },
      {
        title: "Submit Application",
        image: iconFive,
      },
    ],
  },
];

const Stepstofollow = () => {
  return (
    <>
      <div className="tab-content py-16 bg-[#F4F7FB]" id="History">
        <div className="flex justify-between items-center">
          <div className="container mx-auto">
            <div className="flex flex-col items-center gap-14 w-full">
              <div className="flex flex-row items-center">
                <h3 className="text-3xl font-extrabold text-[#231F40]">
                  {" "}
                  Steps to Follow
                </h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 flex-row justify-center items-start">
                {historyContent[0].statisticsData.map((history, index) => (
                  <div
                    key={index}
                    className="flex flex-col flex-grow justify-center items-center gap-2.5"
                  >
                    <div className="w-20 h-20 overflow-hidden rounded-full border bg-white	border-[#E0E0E0]	p-1 flex items-center justify-center">
                      <img
                        src={history.image}
                        alt="history"
                        className="w-full h-full object-cover rounded-lg p-3"
                      />
                    </div>

                    <h4 className="text-xl font-bold text-[#231F40] text-center">
                      {history.title}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepstofollow;
