import React from 'react'

const DreamAndFeature = () => {
    return (
        <>
            <div className='dreamAnd_feature_bg'>
                <div className='flex gap-14 ' style={{padding:'6rem'}}>
                    <div className='dreamAnd_feature_text '>college mentor is not the same for every student it is molded to match your talents and dreams through our specialized mentors</div>
                    <div className='flex flex-col gap-5'>
                        <button className='dreeam_feature_btn ' style={{color:'#fff'}}>Looking for a Mentor</button>
                        <button className='dreeam_feature_btn ' style={{color:'#fff'}}>Becoming a Mentor</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DreamAndFeature