import React from "react";
import bannerImg from "../images/course-inter-banner-img.svg";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

interface IProps {
  // TODO - remove this temporary fix
  title: string | undefined | null;
  description: string | null;
}

const Banner: React.FC<IProps> = ({ title, description }) => {
  const isMdDevice = window.innerWidth >= 768;
  return (
    <div
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)`,
      }}
    >
      <div className="container max-w-screen-2xl text-white py-6">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="relative w-full md:w-1/2 flex flex-col justify-center pt-12 md:pt-0">
            <div className="mb-auto">
              <Breadcrumbs
                links={[
                  { name: "Home", path: "/" },
                  {
                    name: "List of courses in India",
                    path: "/courses/list-of-courses-in-india",
                  },
                ]}
              />
            </div>
            <h1 className="text-4xl md:text-5xl mt-12 md:mt-0 font-bold line-clamp-2">
              {/* TODO - remove this temporary fix */}
              {title || "List of Courses in India"}
            </h1>
            <p className="text-lg md:text-xl mt-4 mb-auto line-clamp-3">
              {/* TODO - remove this temporary fix */}
              {description ||
                "India offers a diverse range of courses across various educational levels, from undergraduate to postgraduate and doctoral programs."}
            </p>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <img
              src={bannerImg}
              className="w-full md:w-2/3 aspect-square object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
