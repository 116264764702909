// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__91snw {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 32rem;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0rem 1rem 1rem 1rem;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Card_cardItem__FauCB {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  border-bottom: 1px solid #d1d5db;
  padding: 0.5rem 0;
}

.Card_cardItem__FauCB:last-child {
  border-bottom: 0;
}

.Card_cardItem__FauCB img {
  width: 100%;
  height: 100%;
  object-fit:contain;
  border-radius: 20px;
  max-height: 150px;
}

/* .cardItem h {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600px;
} */

.Card_image__V-grY {
  width: 25%;
  height: 100%;
  padding-top: 6%;

}

.Card_content__6GwvF {
  width: 75%;
  display: flex;
  padding-left: 2%;
  flex-direction: column;
  align-items: flex-start;

  
}

.Card_cardItem__FauCB p {
  /* margin: 0; */
  /* margin-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 21px;
}

.Card_cardItem__FauCB .Card_btnn__EJXgk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600px;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/blogs/Card.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;EACZ,wBAAmB;EAAnB,mBAAmB;EACnB,4BAA4B;EAC5B,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;;;;;GAKG;;AAEH;EACE,UAAU;EACV,YAAY;EACZ,eAAe;;AAEjB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,uBAAuB;;;AAGzB;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  width: 32rem;\n  height: fit-content;\n  padding: 0rem 1rem 1rem 1rem;\n  border-radius: 10px;\n  transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.cardItem {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  align-items: flex-start;\n  border-bottom: 1px solid #d1d5db;\n  padding: 0.5rem 0;\n}\n\n.cardItem:last-child {\n  border-bottom: 0;\n}\n\n.cardItem img {\n  width: 100%;\n  height: 100%;\n  object-fit:contain;\n  border-radius: 20px;\n  max-height: 150px;\n}\n\n/* .cardItem h {\n  margin-top: 0.5rem;\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 600px;\n} */\n\n.image {\n  width: 25%;\n  height: 100%;\n  padding-top: 6%;\n\n}\n\n.content {\n  width: 75%;\n  display: flex;\n  padding-left: 2%;\n  flex-direction: column;\n  align-items: flex-start;\n\n  \n}\n\n.cardItem p {\n  /* margin: 0; */\n  /* margin-top: 0.5rem; */\n  margin-bottom: 0.5rem;\n  font-size: 14px;\n  line-height: 21px;\n}\n\n.cardItem .btnn {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 600px;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__91snw`,
	"cardItem": `Card_cardItem__FauCB`,
	"image": `Card_image__V-grY`,
	"content": `Card_content__6GwvF`,
	"btnn": `Card_btnn__EJXgk`
};
export default ___CSS_LOADER_EXPORT___;
