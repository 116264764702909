import ProfileDetailCard from "./ProfileDetailCard";

export enum FormInput {
  Text = "TEXT",
  Date = "DATE",
  Radio = "RADIO",
  Dropdown = "DROPDOWN",
}

const PROFILE_DETAIL_SCHEMA = [
  {
    name: "Basic Details",
    subCategory: [
      {
        fields: [
          {
            key: "firstName",
            name: "First Name",
            type: FormInput.Text,
            required: true,
          },
          {
            key: "lastName",
            name: "Last Name",
            type: FormInput.Text,
            required: true,
          },
          {
            key: "email",
            name: "Email",
            type: FormInput.Text,
            required: true,
            validationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          },
          {
            key: "mobileNumber",
            name: "Mobile number",
            type: FormInput.Text,
            required: true,
            validationRegex: /^[0-9]{10}$/,
          },
        ],
      },
    ],
  },
  {
    name: "Preferences",
    subCategory: [
      {
        fields: [
          {
            key: "currentLevelOfStudy",
            name: "Level",
            type: FormInput.Radio,
            required: true,
            options: [
              { key: "12th", name: "12th" },
              { key: "UG", name: "UG" },
              { key: "PG", name: "PG" },
              { key: "Diploma", name: "Diploma" },
              { key: "Ph.D", name: "Ph.D" },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Others",
    subCategory: [
      {
        name: "Mobile details",
        fields: [
          {
            key: "city",
            name: "City",
            type: FormInput.Dropdown,
            required: true,
            options: [
              { key: "tamil-nadu", name: "Tamil Nadu" },
              { key: "kerala", name: "Kerala" },
              { key: "karnataka", name: "Karnataka" },
              { key: "andhra-pradesh", name: "Andhra Pradesh" },
              { key: "telangana", name: "Telangana" },
              { key: "assam", name: "Assam" },
            ],
          },
        ],
      },
      {
        name: "Email details",
        fields: [
          {
            key: "gender",
            name: "Gender",
            type: FormInput.Radio,
            required: true,
            options: [
              { key: "male", name: "Male" },
              { key: "female", name: "Female" },
              { key: "other", name: "Other" },
            ],
          },
          {
            key: "dob",
            name: "DOB",
            type: FormInput.Date,
            required: false,
          },
          {
            key: "phone-number",
            name: "Phone number",
            type: FormInput.Text,
            required: true,
            validationRegex: /^[0-9]{10}$/,
          },
          {
            key: "state",
            name: "State",
            type: FormInput.Dropdown,
            required: true,
            options: [
              { key: "tamil-nadu", name: "Tamil Nadu" },
              { key: "kerala", name: "Kerala" },
              { key: "karnataka", name: "Karnataka" },
              { key: "andhra-pradesh", name: "Andhra Pradesh" },
              { key: "telangana", name: "Telangana" },
              { key: "assam", name: "Assam" },
            ],
          },
        ],
      },
    ],
  },
];

const MyProfile = () => {
  return (
    <div className="flex flex-col gap-8 p-8">
      {PROFILE_DETAIL_SCHEMA.map((item) => (
        <ProfileDetailCard {...item} />
      ))}
    </div>
  );
};

export default MyProfile;
