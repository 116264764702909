import { useEffect, useState } from "react";
import ComparisonEditor from "./components/ComparisonEditor";
import axios from "axios";
import ComparisonDetailsTable from "./components/ComparisonDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import BookSession from "./components/BookSession";
import { fetchLandingData } from "./collegeCompareThunk";
import FAQ from "./components/FAQ";
import SuccessStories from "./components/SuccessStories";
import CompareScholarship from "./components/CompareScholarship";
import FrequentComparison from "./components/FrequentComparison";
import CompareBanner from "./components/CompareBanner";
import { HighlightProvider } from './components/HighlightContext'; // Adjust the path as needed

interface college {
  [key: string]: any;
}

const CollegeCompare = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchLandingData());
  }, []);

  return (
    <div className="font-inter flex flex-col gap-4 lg:gap-6">
                <HighlightProvider>

      <CompareBanner />
      <section className="py-12 bg-[#F5F8FF] text-black">
        <div className="container">
          <ComparisonEditor />

          <ComparisonDetailsTable />
        </div>
      </section>
      <FrequentComparison />
      <CompareScholarship />
      <SuccessStories />
      <FAQ />
      <BookSession />
      </HighlightProvider>

    </div>
  );
};
export default CollegeCompare;
