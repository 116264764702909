// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StudyVisualPopup .progress-bar {
  background-color: #f3f3f3;
}
.StudyVisualPopup .progress-bar.completed {
  background-color: #173cba;
}

.StudyKnowledgeHub .p-4.border.rounded-full.bg-blue.border-blue {
  background-color: white;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/study-abroad-landing/ExploreKnowledgeHub/KnowledgeHubStyle.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;;AAIE;EACE,uBAAA;EACA,YAAA;AADJ","sourcesContent":[".StudyVisualPopup {\n  .progress-bar {\n    background-color: #f3f3f3;\n  }\n  .progress-bar.completed {\n    background-color: #173cba;\n  }\n}\n.StudyKnowledgeHub {\n  .p-4.border.rounded-full.bg-blue.border-blue {\n    background-color: white;\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
