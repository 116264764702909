import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";

export const PERFERRED_STATES: string[] = [
  "Delhi",
  "Mumbai",
  "Telangana",
  "TamilNadu",
];

const PreferredState = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);
  const { pafData } = useSelector((state: RootState) => state.auth);

  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");

  const onSubmit = (data: any) => {
    dispatch(updateForm({ preferredStates: selectedCard }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={true}
        stepName="Where do you prefer to study?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto h-21rem">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"multiple"}
            options={PERFERRED_STATES}
            handleCardSelect={handleCardClick}
            cardSize={"40"}
          /> */}

          <OptionGroup
            options={pafData.states}
            mode="multiple"
            getTitle={(option) =>
              typeof option === "string" ? option : option.stateName
            }
            handleCardSelect={handleCardClick}
            cardSize={"50"}
          />
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default PreferredState;
