import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisiblity = () => {
      if (window.scrollY > 100) setIsVisible(true);
      else setIsVisible(false);
    };

    window.addEventListener("scroll", toggleVisiblity);

    return () => {
      window.removeEventListener("scroll", toggleVisiblity);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 bg-white blue-color button-styles duration-200 hover:border-white z-50"
          style={{ padding: "1rem" }}
        >
          <FontAwesomeIcon icon={faChevronUp} size="xl" />
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
