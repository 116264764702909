// src/components/Modal.js
import React from "react";
import { useNavigate } from "react-router-dom";

const Modal = () => {
  const navigate = useNavigate();

  const handleSlideClick = () => {
    navigate("/courses/quiz-start");
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-grey-1 bg-opacity-75">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-center">
          Quiz Instructions
        </h2>
        <p className="mb-4 text-lg">
          Here are the details of the quiz. You have 10 questions related to
          basic computer applications:
        </p>
        <ul className="list-disc list-inside mb-6 text-left text-grey">
          <li className="mb-2">
            You have 5 minutes to finish, just kidding take your own time.
          </li>
          <li className="mb-2">Each mark carries one point.</li>
          <li className="mb-2">Negative marks (don't worry about it).</li>
          <li className="mb-2">You can save questions for later review.</li>
          <li className="mb-2">
            Use the right-end navigation numbers to go to any particular
            question.
          </li>
          <li className="mb-2">
            After submitting, you will get a detailed analysis.
          </li>
          <li className="mb-2">Don't forget to download the analysis.</li>
          <li className="mb-2">Last but not least, have fun!</li>
        </ul>
        <div className="text-center">
          <button
            onClick={handleSlideClick}
            className="bg-blue text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-2 transition duration-200"
          >
            Start Quiz
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
