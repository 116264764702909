import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import {
  requiredFieldValidator,
  emailValidator,
  passwordValidator,
  matchIsNumeric,
  isPhoneValid,
} from "../../util/FormValidator";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import "react-international-phone/style.css";
import { MuiPhone } from "./MuiPhone";
import { registrationData } from "../../services/apiReq";
import InputField from "../../components/InputField";

function Registration() {
  const navigate = useNavigate();

  const [invalid, setValid] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [isValidPhone, setValidPhone] = useState(false);

  const [data, setData] = useState<registrationData>({
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    otp: "",
    personType: "",
    gender: "",
    standard: "",
    boardType: "",
    dreamCareer: "",
    dreamCourse: "",
    specialization: "",
    studyLocations: [],
    fundType: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validator(name, value);
  };

  const handleChangePhoneNum = (value: string) => {
    setData((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
    setValidPhone(isPhoneValid(value));
  };

  const validator = (name: any, value: any) => {
    let errorRes = "";
    if (name === "email") {
      errorRes = emailValidator(value);
    } else if (name === "password") {
      errorRes = passwordValidator(value);
    } else if (name === "fullName") {
      errorRes = requiredFieldValidator(value);
    }
    setValid(errorRes !== "");
    setErrors((prevState) => ({
      ...prevState,
      [name]: errorRes,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    Object.entries(data).map(
      // eslint-disable-next-line array-callback-return
      ([key, value]: [string, string], index: number) => {
        validator(key, value);
      }
    );

    if (!invalid && data?.otp !== "") {
      navigate("/personal-info", { state: { ...data } });
    }
  };

  const handleChangeOtp = (newValue: string) => {
    setData((prevState) => ({
      ...prevState,
      otp: newValue,
    }));
  };

  const sendOtp = (event: any) => {
    event.preventDefault();

    Object.entries(data).map(
      // eslint-disable-next-line array-callback-return
      ([key, value]: [string, string], index: number) => {
        validator(key, value);
      }
    );

    if (!invalid && isValidPhone) {
      setSentOtp(true);
    }
  };
  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value);
  };

  const loginAction = (event: any) => {
    navigate("/sign-in");
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>
        <Box
          sx={{
            border: "1px solid #047AE8",
            borderRadius: "10px",
            flexDirection: "row",
            marginLeft: 0,
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Button
            sx={{ height: "25px", fontFamily: "Arial", fontSize: 12 }}
            onClick={loginAction}
          >
            Login
          </Button>
          <Button
            style={{
              height: "25px",
              fontFamily: "Arial",
              fontSize: 12,
              backgroundColor: "#047AE8",
              borderRadius: "8px",
              color: "white",
            }}
          >
            Register
          </Button>
        </Box>
        <Box sx={{ mt: 1, width: "100%" }} width={"100%"}>
          <InputField
            type={"text"}
            label="Full Name"
            name="fullName"
            placeholder={"Enter your Full Name"}
            value={data?.fullName}
            onChange={handleChange}
            required={true}
            autoFocus={true}
            error={errors?.fullName !== ""}
            helperText={errors?.fullName !== "" ? errors?.fullName : " "}
          />

          <InputField
            type={"text"}
            required={true}
            id="email"
            label="Email Address"
            placeholder={"Enter your Email Address"}
            name="email"
            value={data?.email}
            onChange={handleChange}
            error={errors?.email !== ""}
            helperText={errors?.email !== "" ? errors?.email : " "}
          />

          <InputField
            type={"password"}
            required={true}
            id="password"
            name="password"
            label="Password"
            placeholder="Enter your password"
            value={data?.password}
            onChange={handleChange}
            sx={{ borderRadius: 10 }}
            error={errors?.password !== ""}
            helperText={errors?.password !== "" ? errors?.password : " "}
          />

          <Box sx={{ mt: "16px", mb: "8px" }}>
            <MuiPhone
              value={data?.phoneNumber}
              onChange={handleChangePhoneNum}
            />
            {!isValidPhone && (
              <div style={{ color: "red" }}>Phone is not valid</div>
            )}
          </Box>
          {sentOtp ? (
            <>
              <Box sx={{ mt: "16px", mb: "8px" }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 0.6)",
                    mb: "10px",
                  }}
                >
                  Enter OTP
                </Typography>
                <MuiOtpInput
                  TextFieldsProps={{ placeholder: "-" }}
                  length={6}
                  value={data?.otp}
                  onChange={handleChangeOtp}
                  validateChar={validateChar}
                />
              </Box>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: 10 }}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 10 }}
              onClick={sendOtp}
            >
              Get OTP
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Registration;
