import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface Tab {
  id: number;
  title: string;
}
interface TabNavigationProps {
  tabsData: { name: string }[];
  setTabName: (name: string) => void;
}
const TabNavigation = ({ tabsData, setTabName }: TabNavigationProps) => {
  const [activeTab, setActiveTab] = useState("");

  const swiperRef = useRef<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnding, setIsEnding] = useState(true);

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    if (swiper) {
      swiper.on("slideChange", () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnding(swiper.isEnd);
      });
    }
    setActiveTab(tabsData[0].name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase());
  }, []);

  return (
    <>
      <div className="w-full  tabNavigationBar py-3 px-7 min-max-xss:px-2 min-max-xs:px-2">
        <div className="flex flex-row items-center justify-center gap-4">
          <button
            className={`course-category-prev-btn !block rounded justify-center items-center px-2 py-2 text-white bg-[#14E0AD] border border-solid border-white ${
              isBeginning ? "swiper-button-disabled bg-[#A6EAD9]" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              transform="scale(-1, 1)"
            >
              <path
                d="M9.98315 6.38527L5.63917 2.04129L6.77733 0.903131L13.0752 7.20096L6.77733 13.4988L5.63917 12.3606L9.98315 8.01664H0.138062V6.38527H9.98315Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="w-full">
            <Swiper
              ref={swiperRef}
              onSwiper={(swiper) => {
                setIsBeginning(swiper.isBeginning);
                setIsEnding(swiper.isEnd);
              }}
              breakpoints={{
                350: { slidesPerView: 2 },
                640: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 7,
                },
              }}
              navigation={{
                prevEl: ".course-category-prev-btn",
                nextEl: ".course-category-next-btn",
              }}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation]}
              className="flex flex-row justify-center items-center min-max-xss:gap-1"
            >
              {tabsData.map((tab: any) => {
                return (
                  <SwiperSlide>
                    <button
                      className={classNames(
                        "text-[#000] min-max-xss:text-xs rounded w-28 h-8",
                        activeTab ===
                          tab.name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
                          ? "bg-[#14E0AD] !text-white"
                          : "bg-white text-black"
                      )}
                      onClick={() => {
                        setActiveTab(
                          tab.name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
                        );
                        setTabName(tab.name);
                      }}
                    >
                      {tab.name}
                    </button>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <button
            className={`course-category-next-btn !block rounded justify-center items-center px-2 py-2 text-white bg-[#14E0AD] border border-solid border-white ${
              isEnding ? "swiper-button-disabled !bg-[#A6EAD9]" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.98315 6.38527L5.63917 2.04129L6.77733 0.903131L13.0752 7.20096L6.77733 13.4988L5.63917 12.3606L9.98315 8.01664H0.138062V6.38527H9.98315Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default TabNavigation;
