// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scrollFade {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.logo-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slidetrack {
  display: flex;
  transition: transform 2s ease-in-out;
  will-change: transform;
}

.logo-item {
  height: 85px;
  width: 300px;
  display: flex;
  align-items: center;
  perspective: 100px;
  gap: 30px;
  max-width: 300px;
}

.logo-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 2s ease-in-out;
}

.logo-item img:hover {
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/study-abroad-landing/CarosalStyles.scss"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAA;EACF;EACA;IACE,4BAAA;EACF;AACF;AAEA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,oCAAA;EACA,sBAAA;AAAF;;AAGA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,gBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":["@keyframes scrollFade {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n\n.logo-container {\n  display: flex;\n  overflow: hidden;\n  position: relative;\n}\n\n.slidetrack {\n  display: flex;\n  transition: transform 2s ease-in-out;\n  will-change: transform;\n}\n\n.logo-item {\n  height: 85px;\n  width: 300px;\n  display: flex;\n  align-items: center;\n  perspective: 100px;\n  gap: 30px;\n  max-width: 300px;\n}\n\n.logo-item img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  transition: transform 2s ease-in-out;\n}\n\n.logo-item img:hover {\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
