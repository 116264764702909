import React, { useEffect, useState } from "react";
import TabNavigation from "./TabNavigation";
import { useNavigate, useLocation } from "react-router-dom";

export const ExploreMockTestSeries = (data: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { mockTestSeries } = data?.data;

  const testNamesList: string[] = mockTestSeries.mocks.map(
    (mock: { testName: any }) => mock.testName
  );
  const testNames = Array.from(new Set(testNamesList));
  const [activeSection, setActiveSection] = useState<string | null>(
    testNames[0]
  );
  const [selectedTab, setSelectedTab] = useState<string | null>(
    mockTestSeries.topicWiseTests[0].name
  );

  const handleClick = () => {
    if (!isLoggedIn) {
      window.location.href = "/login";
    } else {
      window.location.href = "/tcy-online";
    }
  };

  const [filteredMocks, setFilteredMocks] = useState([]);
  const [examDetails, setExamDetails] = useState<any>(null);

  const handleSideNavClick = (section: any) => {
    setActiveSection(section);
  };

  useEffect(() => {
    const filteredItems = mockTestSeries.mocks.filter(
      (mock: { testName: any; examName: any }) =>
        mock.testName === activeSection && mock.examName === selectedTab
    );
    setFilteredMocks(filteredItems);
  }, [activeSection, selectedTab]);

  const fetchData = async (category: string) => {
    try {
      const response = await fetch(
        `https://mentormenteedev.com/api/mockExamDetails?category=${category}`
      );

      const data = await response.json();
      setExamDetails(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleButtonClick = (category: string) => {
    const encodedCategory = encodeURIComponent(category);
    navigate(`?category=${encodedCategory}`);
    fetchData(category);
  };

  const handleSetTabName = (tabName: string) => {
    setSelectedTab(tabName);
  };

  console.log("#filteredMocks", filteredMocks);
  return (
    <div className="explore-mock-section bg-[#F7F7F7] pt-10 pb-[66px]">
      <div className="container">
        <div>
          <h2 className="text-5xl text-[#383838] text-center mb-9 min-max-xss:text-2xl">
            Explore Mock Test Series
          </h2>
          <div className="flex flex-row gap-1 ml-7 min-max-xss:ml-0 min-max-xss:overflow-auto">
            {mockTestSeries.categories.map((item: any, index: number) => (
              <button
                key={index}
                className="bg-[#14E0AD] text-white py-4 px-4 min-max-xss:px-1 min-max-xss:py-2 min-max-xss:text-xs rounded"
                onClick={() => handleButtonClick(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="bg-white px-7 py-4">
            <TabNavigation
              tabsData={mockTestSeries.topicWiseTests}
              setTabName={handleSetTabName}
            />

            <div className="explore-mock-grid-section flex min-max-xss:flex-col min-max-xs:flex-col lg:flex-row gap-7 mt-12">
              <div className="w-full lg:w-3/12">
                <div className="w-full  bg-[#fff] text-[#4F4C4C] flex flex-col">
                  <nav className="flex flex-col flex-grow">
                    {testNames.map((section: string, index: number) => (
                      <a
                        key={index}
                        href={`#${section
                          .replace(/[^a-zA-Z0-9]/g, "-")
                          .toLowerCase()}`}
                        className={`px-3 py-5  transition-colors flex flex-row border border-[#CECECE] ${
                          activeSection === section
                            ? "bg-[#DBF4ED] border-r-[6px] !border-r-[#4DC9A5]"
                            : "hover:bg-[#DBF4ED]"
                        }`}
                        onClick={() => handleSideNavClick(section)}
                      >
                        <span className="bg-[#CDB74F] border border-[#CDB74F] rounded-sm text-[#4F4C4C]  w-6 h-6 flex items-center justify-center mr-6">
                          {section.charAt(0)}
                        </span>
                        <span className="truncate text-base font-semibold">
                          {section}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="w-full lg:w-9/12">
                <div className="">
                  {filteredMocks.length === 0 ? (
                    <div className="text-center text-gray-500 p-4">
                      No data found
                    </div>
                  ) : (
                    filteredMocks.map((mock: any, index: number) => (
                      <div
                        key={index}
                        className="grid grid-cols-4 min-max-xss:grid-cols-3 min-max-xs:grid-cols-3 border-b border-b-[#CECECE] last:border-b-0 p-4 text-base items-center "
                      >
                        <div className="text-left min-max-xss:col-span-1 min-max-xs:col-span-1">
                          {mock.examName}
                        </div>
                        <div className="text-center min-max-xss:col-span-1 min-max-xs:col-span-1">
                          {mock.duration}
                        </div>
                        <div className="text-center min-max-xss:col-span-1 min-max-xs:col-span-1">
                          {mock.questions}
                        </div>
                        <div className="text-right flex justify-end min-max-xss:col-span-full min-max-xs:col-span-full min-max-xss:justify-center min-max-xs:justify-center">
                          <button
                            className={`bg-[#173CBA] text-center items-center text-sm text-white py-2 px-4 rounded w-[137px] h-[38px] ${
                              mock.action === "Unlock"
                                ? "bg-[#8B9DDD]"
                                : "bg-[#173CBA]"
                            }`}
                            disabled={mock.action === "Unlock"}
                            onClick={handleClick}
                          >
                            <span>
                              {mock.action === "Unlock" && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                  className="mr-2 inline-block"
                                >
                                  <path
                                    d="M8.5 11.2222V13M4.125 7.24782C4.53749 7.22222 5.04602 7.22222 5.7 7.22222H11.3C11.954 7.22222 12.4625 7.22222 12.875 7.24782M4.125 7.24782C3.61022 7.27973 3.245 7.35156 2.93328 7.51289C2.43935 7.76853 2.03778 8.17644 1.78611 8.67822C1.5 9.24871 1.5 9.99538 1.5 11.4889V12.7333C1.5 14.2268 1.5 14.9735 1.78611 15.544C2.03778 16.0458 2.43935 16.4537 2.93328 16.7093C3.49479 17 4.22986 17 5.7 17H11.3C12.7702 17 13.5052 17 14.0667 16.7093C14.5607 16.4537 14.9622 16.0458 15.2139 15.544C15.5 14.9735 15.5 14.2268 15.5 12.7333V11.4889C15.5 9.99538 15.5 9.24871 15.2139 8.67822C14.9622 8.17644 14.5607 7.76853 14.0667 7.51289C13.755 7.35156 13.3898 7.27973 12.875 7.24782M4.125 7.24782V5.44444C4.125 2.98985 6.08376 1 8.5 1C10.9162 1 12.875 2.98985 12.875 5.44444V7.24782"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              )}
                              {mock.action}
                            </span>
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
