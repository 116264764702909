import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import CustomTabsNavigationBar from "../../../components/tabs/CustomTabsNavigationBar";
import CustomSlickSlider from "../../../components/Slider/CustomSlickSlider";
import Trending from "./Trending";
export const SwiperList = [
  {
    name: "NEET revised results 2024",
    key: 1,
  },
  {
    name: "NIIT University Admission 2024",
    key: 2,
  },
  {
    name: "CUET UG 2024",
    key: 3,
  },
  {
    name: "CAT 2024 Registration",
    key: 4,
  },
  {
    name: "NEET revised results 2024",
    key: 1,
  },
  {
    name: "NIIT University Admission 2024",
    key: 2,
  },
  {
    name: "CUET UG 2024",
    key: 3,
  },
  {
    name: "CAT 2024 Registration",
    key: 4,
  },
  {
    name: "NIIT University Admission 2024",
    key: 2,
  },
  {
    name: "CUET UG 2024",
    key: 3,
  },
  {
    name: "CAT 2024 Registration",
    key: 4,
  },
];

export const TabsList = [
  {
    trendingTopic: "NEET revised results 2024",
    blogId: 76,
  },
  {
    trendingTopic: "NIIT University Admission 2024",
    blogId: 77,
  },
  {
    trendingTopic: "CUET UG 2024",
    blogId: 78,
  },
  {
    trendingTopic: "CAT 2024 Registration",
    blogId: 79,
  },
  {
    trendingTopic: "CA Foundation June 2024 result",
    blogId: 80,
  },
  {
    trendingTopic: "NEET revised results 2024",
    blogId: 161,
  },
  {
    trendingTopic: "NIIT University Admission 2024",
    blogId: 162,
  },
  {
    trendingTopic: "CUET UG 2024",
    blogId: 163,
  },
  {
    trendingTopic: "CAT 2024 Registration",
    blogId: 164,
  },
  {
    trendingTopic: "CA Foundation June 2024 result",
    blogId: 165,
  },
  {
    trendingTopic: "NEET revised results 2024",
    blogId: 246,
  },
  {
    trendingTopic: "NIIT University Admission 2024",
    blogId: 247,
  },
];

interface TrendingTopicsProps {
  trendingTopics: any;
}

//  Payload Generator for CustomTabsNavigationBar component
const payloadFormatter = (list: any) => {
  console.log("payloadFormatter", list);
  return list?.map((trendingTopic: any) => {
    return {
      // title: trendingTopic?.trendingTopic,
      // id: trendingTopic?.blogId,
      ...trendingTopic,
    };
  });
};

const TrendingTopics: React.FC<TrendingTopicsProps> = ({
  trendingTopics = [],
}) => {
  // console.log("TrendingTopics", payloadFormatter(trendingTopics));
  return (
    <>
      {/* <div className="grid grid-cols-5 mt-2 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 ">
        <div className="flex items-center justify-center text-sm text-2xl font-semibold md:justify-end">
          Trending Topics
        </div>

        <div className="col-span-3">
          <CustomSlickSlider />
        </div>
      </div> */}
      {/* <div className="grid items-center justify-center grid-cols-6 gap-4 md:grid-cols-6">
        <div className="flex items-center justify-center text-sm font-semibold sm:grid-cols-1">
          Trending Topics
        </div>
        <div className="col-span-5 md:col-start-2 md:col-span-6 sm:grid-cols-5">
          {" "}
          <CustomTabsNavigationBar tabs={TabsList} />
        </div>
      </div> */}

      {/* working version */}
      {/* <div className="grid items-center justify-center">
        <div className="flex items-center justify-center col-span-1 row-start-1 text-sm font-semibold md:text-base">
          Trending Topics
        </div>
        <div className="col-span-4 row-start-1 overflow-hidden">
          <CustomTabsNavigationBar tabs={payloadFormatter(TabsList)} />
        </div>
      </div> */}
      {/* <div className="grid items-center justify-center">
        <div className="flex items-center justify-center col-span-1 row-start-1 text-xs font-semibold md:text-base">
          Trending Topics
        </div>
        <div className="col-span-4 row-start-1 overflow-hidden">
          <CustomSlickSlider />
        </div>
      </div>{" "} */}
      {/* <div className="grid grid-cols-5 gap-4 mt-1 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5">
        <div className="flex items-center justify-center sm:col-span-1 md:col-sapn-1">
          Trending Topics
        </div>
        <div className="sm:col-span-4 md:col-span-4">
          <CustomSlickSlider />
        </div>
      </div> */}
      {/* <div className="grid grid-cols-5 gap-3 sm:grid-cols-5 md:grid-cols-5">
        <div className="col-span-1">Trending Topics</div>
        <div className="col-span-4">
          <CustomSlickSlider />
        </div>
      </div> */}

      {/* <div className="flex items-center justify-center gap-4 mb-4">
        <div className="w-1/5 ">Trending Topics</div>
        <div className="flex justify-center w-4/5 sm:flex-row md:flex-col">
          {" "}
          <CustomSlickSlider />
        </div>
      </div> */}

      {/* <Trending /> */}
      <span className="flex text-xs font-semibold md:hidden sm:flex md:text-base text-nowrap">
        Trending Topics
      </span>

      <div className="flex flex-row justify-center gap-4 md:items-center md:flex-row sm:flex-col">
        <div className="hidden text-xs font-semibold md:text-base text-nowrap sm:hidden md:flex ">
          Trending Topics
        </div>

        <div className="overflow-hidden ">
          <CustomTabsNavigationBar tabs={payloadFormatter(trendingTopics)} />
        </div>
      </div>
    </>
  );
};

export default TrendingTopics;
