// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Open Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 125% */
} /* App.css */
.form-container {
  display: flex;
  align-items: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/landing/components/Footer.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEJ,kBAAA;EACA,4CAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAAA,EAEC,YAAA;AAED;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ","sourcesContent":[".head{\n    color: #FFF;\n\ntext-align: center;\ntext-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\nfont-family: \"Open Sans\";\nfont-size: 40px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 50px; /* 125% */\n\n}/* App.css */\n\n.form-container {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
