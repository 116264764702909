import React from "react";
import Logo from "../../../images/navbar/newLogo.svg";
import NewLogo from "../../../images/college-mentor-logo/College_Mentor_Final_Logo_400px.svg";
import NavBar from "../../../components/nav-bar/NavBar";
import BlogsNavigationBar from "./BlogsNavigationBar";
// import { HeaderAPIReponse } from "../data/UtilsData";
import AlignRightMenu from "../../../images/blog-article/align-right-menu.svg";
import { NavLink } from "react-router-dom";
import AlertIcon from "../../../images/blog-article/alert-icon.svg";
import SearchIcon from "../../../images/blog-article/search-icon.svg";

interface BlogsHeaderProps {
  headerResponse?: any;
}
const BlogsHeader: React.FC<BlogsHeaderProps> = ({ headerResponse = [] }) => {
  // console.log("data", headerResponse);

  return (
    <>
      <div className="visible xs:block sm:block md:hidden">
        <NavBar />
      </div>

      <nav className="hidden md:block container bg-white border-gray-200 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} alt="logo" />
          </a>
          <div className="flex items-center space-x-1 md:order-2 md:space-x-2 rtl:space-x-reverse">
            <img src={SearchIcon} className="scale-[100%]" />
            <button
              data-collapse-toggle="mega-menu"
              type="button"
              className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mega-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <ul className="flex items-center justify-center gap-4 overflow-auto text-nowrap grow">
            {/* <li>
              <a className="hover:bg-[#113cc0] hover:text-[#ffffff] cursor-pointer">
                Home
              </a>
            </li> */}
            {/* <li>
              <a
                className="px-2.5 py-1 bg-[#000000] flex justify-center items-center gap-2 text-[#ffffff] leading-4 cursor-pointer"
                href="/blogs"
              >
                <img src={AlignRightMenu} />
                <p>All</p>
              </a>
            </li> */}

            {/* {Object.keys(headerResponse).map((navigationItem, index) => (
              <li className="flex text-md text-nowrap">
                <NavLink
                  to={`/blogs/${navigationItem.toLocaleLowerCase()}`}
                  state={{
                    from: "BlogsNavigationBar",
                    category: navigationItem,
                  }}
                  className="p-2 text-md"
                >
                  {navigationItem}
                </NavLink>
              </li>
            ))} */}
            <BlogsNavigationBar categories={headerResponse} />
          </ul>
        </div>
      </nav>

      {/* Current Implementation */}
      {/* <div className="hidden md:block">
        <div className="flex px-8 py-5 border-b-2 bg-white-300 border-black-1">
          <div className="">Friday, July 26, 2024</div>
          <div className="mx-2 grow"></div>
          <ul className="flex hidden gap-x-6 md:flex">
            <li>About</li>
            <li>Contact</li>
            <li>My Account</li>
          </ul>
        </div>
        <div className="flex justify-center p-5 border-b-2 border-black-1">
          <div className="flex flex-col gap-6">
            <a href="/" className="main-logo">
              <img src={NewLogo} alt="logo" width="500px" />
            </a>
            <div className="flex flex-row divide-x-2 divide-[#000000] justify-center">
              <div className="px-3">NEWS</div>
              <div className="px-3">BLOGS</div>
              <div className="px-3">ARTICLES</div>
            </div>
          </div>
        </div>
        </div> */}
    </>
  );
};

export default BlogsHeader;
