// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collegename-cs {
  color: #000;
  height: 29px;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.17px;
}

.degree {
  color: var(--Neutrals-2, #25242B);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rating {
  color: var(--Neutrals-2, #25242B);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.compare {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14.78px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-compare/components/FrequentComparison.scss"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACJ,YAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AAAA;;AAGA;EACI,iCAAA;EACJ,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAA;;AAGA;EACI,iCAAA;EACJ,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAA;;AAEA;EACI,WAAA;EAEJ,wBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAA","sourcesContent":[".collegename-cs\n{\n    color: #000;\nheight: 29px;\nfont-family: \"Open Sans\";\nfont-size: 13px;\nfont-style: normal;\nfont-weight: 500;\nline-height: 22.17px;\n}\n\n.degree{\n    color: var(--Neutrals-2, #25242B);\nfont-family: \"Open Sans\";\nfont-size: 12px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\n}\n\n.rating{\n    color: var(--Neutrals-2, #25242B);\nfont-family: \"Open Sans\";\nfont-size: 12px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n}\n.compare{\n    color: #FFF;\n\nfont-family: \"Open Sans\";\nfont-size: 14.78px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
