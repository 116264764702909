import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface IProps {
  handleShowFilter: () => void;
  changeFilterMenuActiveCategory: (index: number) => void;
}

const FilterTabs: FC<IProps> = ({
  handleShowFilter,
  changeFilterMenuActiveCategory,
}) => {
  const filterData = useSelector((state: RootState) => state.filter.filterData);
  const filterCategories = Object.keys(filterData);

  const numberOfFilteredCategories = Object.entries(filterData).reduce(
    (acc, [key, value]) => (value.selected.length > 0 ? acc + 1 : acc),
    0
  );

  return (
    <div className="flex gap-4">
      <button
        className="rounded-full border border-black px-4 py-2 relative"
        onClick={handleShowFilter}
      >
        <FontAwesomeIcon icon={faFilter} /> Filter
        <div className="text-white bg-blue rounded-full absolute top-0 right-0 translate-x-2 -translate-y-2 px-2 py-0.5">
          {numberOfFilteredCategories}
        </div>
      </button>
      {filterCategories.map((category, index) => (
        <button
          className="rounded-full border border-black px-4 py-2"
          onClick={() => {
            changeFilterMenuActiveCategory(index);
            handleShowFilter();
          }}
        >
          <FontAwesomeIcon icon={faFilter} /> {filterData[category].name}
        </button>
      ))}
    </div>
  );
};

export default FilterTabs;
