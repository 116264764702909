// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.changeinput {
  color: #173CBA;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor-result/Cards/Summary.scss"],"names":[],"mappings":"AAAA;EAGA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADA;;AAKA;EACI,cAAA;EAEA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHJ","sourcesContent":[".summary{\n\n\nfont-family: \"Open Sans\";\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\n}\n\n\n.changeinput{\n    color: #173CBA;\n\n    text-align: center;\n    font-family: \"Open Sans\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 21px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
