import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseURL from "../../services/api";

interface SendOtpPayload {
  mobile: string;
}

interface VerifyOtpPayload {
  mobile: string;
  otp: string;
}

interface SignupPayload {
  mobile: string;
}

interface ApiResponse {
  message: string;
  success: boolean;
  accessToken?: string;
  user?: any;
  refreshToken?: string;
  [key: string]: any;
}

//  sending OTP
export const sendOtp = createAsyncThunk<ApiResponse, SendOtpPayload>(
  "auth/sendOtp",
  async ({ mobile }, { rejectWithValue }) => {
    try {
      const response = await BaseURL.get(
        `/api/auth/mobile-otp-generation?mobileNumber=${mobile}`
      );
      return response?.data;
    } catch (error: any) {
      console.log("Error sending OTP");
      return rejectWithValue(error.response?.data || "Error sending OTP");
    }
  }
);

// verifying OTP
export const verifyOtp = createAsyncThunk<ApiResponse, VerifyOtpPayload>(
  "auth/verifyOtp",
  async ({ mobile, otp }, { rejectWithValue }) => {
    try {
      const response = await BaseURL.post(`/api/auth/verify-otp`, {
        mobileNumber: mobile,
        otp,
      });
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Invalid OTP");
    }
  }
);

// call signup API after OTP verification
export const signupPAFDetail = createAsyncThunk<
  ApiResponse,
  SignupPayload,
  { state: any }
>("auth/signupInfoPafDetails", async ({ mobile }, { getState, rejectWithValue }) => {
  const { auth } = getState();
  const token = auth.accessToken;

  try {
    const response = await BaseURL.get(`api/auth/signup-info`);
    return response?.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data || { message: "Signup failed", success: false }
    );
  }
});
