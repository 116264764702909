import React, { useEffect, useRef, useState } from 'react';
const fcData = [
    {
        image: "https://vtworksforwomen.org/wp-content/uploads/2022/12/Career-Mentor-Network_Mentee.jpg",
        title: "Career Mentor",
        description:
            "Career mentor guides individuals towards achieving their professional goals.",
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK3IaxPoFEow3Zl_TJv3XBSUNh1VZh7rASqw&s",
        title: "Student Mentor",
        description:
            "A student mentor offers guidance and support to help students personally and academically",
    },
    {
        image: "https://media.istockphoto.com/id/502391148/photo/group-of-doctors-on-presentation-at-hospital.jpg?s=612x612&w=0&k=20&c=DM-yg1qDUujPWrJ0ACBSGgjt_TBuerf1jFIHrC-Q7bI=",
        title: "Health Mentor",
        description:
            "A health mentor provides guidance for improving physical and mental well being.",
    },
];
function HoverCard({
    image,
    title,
    description,
}: {
    image: string;
    title: string;
    description: string;
}) {
    return (
        <div className="hc-card">
            <div className="hc-img">
                <img
                    src={image}
                    alt=""
                    className="object-cover aspect-[0.85] w-full rounded-2xl"
                    loading="lazy"
                />
            </div>
            <div className="hc-content">
                <h3>{title}</h3>
                <p>{description}</p>
                <button>
                    View details
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                    >
                        <path
                            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                            fill="#113CC0"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}
type TxtRotateProps = {
    toRotate: string[];
    period: number;
};
const TxtRotate: React.FC<TxtRotateProps> = ({ toRotate, period }) => {
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const txtRotateRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleTick = () => {
            const i = loopNum % toRotate.length;
            const fullTxt = toRotate[i];
            if (isDeleting) {
                setText(fullTxt.substring(0, text.length - 1));
            } else {
                setText(fullTxt.substring(0, text.length + 1));
            }
            let newDelta = 300 - Math.random() * 100;
            if (isDeleting) {
                newDelta /= 2;
            }
            if (!isDeleting && text === fullTxt) {
                newDelta = period;
                setIsDeleting(true);
            } else if (isDeleting && text === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                newDelta = 500;
            }
            setDelta(newDelta);
        };
        const timeout = setTimeout(handleTick, delta);
        return () => clearTimeout(timeout);
    }, [text, isDeleting, delta, loopNum, toRotate, period]);
    useEffect(() => {
        if (txtRotateRef.current) {
            txtRotateRef.current.innerHTML = `<span class="wrap">${text}</span>`;
        }
    }, [text]);
    return <div ref={txtRotateRef} className="txt-rotate"></div>;
};
const QuizCard = () => {
    useEffect(() => {
        const css = document.createElement('style');
        css.type = 'text/css';
        css.innerHTML = `.txt-rotate > .wrap { border-right: 0.08em solid #113CC0 }`;
        document.body.appendChild(css);
        return () => {
            document.body.removeChild(css);
        };
    }, []);
    const elements = [
        { toRotate: ["student", "career", "student health"], period: 2000 }
    ];
    return (
        <>
            <div  style={{ fontSize: '2rem', fontWeight: 'bold' }} className='text-center '>Quiz Card</div>
            <div className='flex container'>
                <div className='mt-5 ' style={{ width: '35%' }}>
                    <div style={{ fontSize: '2rem', color: '#000', letterSpacing: '0.2px', fontWeight: '600', lineHeight: '40px' }} className='text_torotate_heading'>Explore mentor <br /> Possibilities <br />That reflect who <br /> You are</div>
                    <div className='text_torotate_heading '>1-on-1</div>
                    <div className='text_torotate'>{elements.map((el, index) => (
                        <TxtRotate key={index} toRotate={el.toRotate} period={el.period} />
                    ))}</div>
                    <div className='text_torotate_heading '>mentorship</div>
                </div>
                <div className="c-featured-courses-section my-[40px]" >
                    <div className="flex gap-[24px]">
                        {fcData.map((card, index) => (
                            <HoverCard key={index} {...card} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
export default QuizCard