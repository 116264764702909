import React from "react";
import StudentInfo from "./StudentInfo";
import { Link } from "react-router-dom";

interface DreamersToAchieverProp {
  title: string;
  description: string;
  subtitle: string;
  buttonLinks?: {
    buttonLinksOne: { title: string; link: string }[];
    buttonLinksTwo: { title: string; link: string }[];
    buttonLinksThree: { title: string; link: string }[];
  };
}
const DreamersToAchieverContent: DreamersToAchieverProp[] = [
  {
    title: "Dreamer's to Achiever's",
    description: "India`s Leading platform for Practice Mock Exams",
    subtitle: "START YOUR EXAM JOURNEY WITH THE LEADERS OF EXAM PREPARATION",
    buttonLinks: {
      buttonLinksOne: [
        {
          title: "JEE (Main)",
          link: "https://jeemain.nta.ac.in/",
        },
        {
          title: "JEE (Advanced)",
          link: "https://jeeadv.ac.in/",
        },
        {
          title: "BITSAT",
          link: "https://www.bitsadmission.com/bitsatmain.aspx?id=11012016",
        },
      ],
      buttonLinksTwo: [
        {
          title: "NEET",
          link: "https://exams.nta.ac.in/NEET/",
        },
        {
          title: "CLAT",
          link: "https://consortiumofnlus.ac.in/",
        },
        {
          title: "NIFT",
          link: "https://www.nift.ac.in/",
        },
        {
          title: "CUET",
          link: "https://cuet.nta.nic.in/",
        },
      ],
      buttonLinksThree: [
        {
          title: "University Admissions India & Abroad",
          link: "/study-abroad",
        },
      ],
    },
  },
];

const DreamersToAchiever: React.FC = () => {
  return (
    <div className="bg-[#FFF9E5] py-16">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-center items-center gap-6">
          <div className="flex flex-col items-start w-full md:w-2/5 lg:w-1/2 xl:w-2/3">
            {DreamersToAchieverContent.map((content, index) => (
              <div key={index} className="flex flex-col gap-10">
                <div className="flex flex-col gap-3 md:gap-4">
                  <div className="flex flex-col gap-3 md:gap-6">
                    <h3 className="text-4xl md:text-5xl font-medium text-black">
                      {content.title}
                    </h3>
                    <p className="text-xl md:text-3xl text-blue font-medium">
                      {content.subtitle}
                    </p>
                  </div>
                  <div className="flex flex-col gap-6">
                    <p className="text-lg text-[#121212]">
                      {content.description}
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <ul className="flex flex-col gap-4 w-full md:w-auto max-md:mx-auto">
                    <li className="flex bg-green py-3.5 px-5 justify-center gap-2 rounded-md">
                      {content.buttonLinks?.buttonLinksOne.map(
                        (button, index) => (
                          <>
                            <Link
                              to={button.link}
                              key={index}
                              className="text-white text-base font-semibold flex gap-2 group"
                            >
                              {button.title}
                              <span className="text-white group-last-of-type:hidden">
                                |
                              </span>
                            </Link>
                          </>
                        )
                      )}
                    </li>
                    <li className="flex bg-green py-3.5 px-5 justify-center gap-2 rounded-md">
                      {content.buttonLinks?.buttonLinksTwo.map(
                        (button, index) => (
                          <Link
                            to={button.link}
                            key={index}
                            className="text-white text-base font-semibold flex gap-2 group"
                          >
                            {button.title}
                            <span className="text-white group-last-of-type:hidden">
                              |
                            </span>
                          </Link>
                        )
                      )}
                    </li>
                    <li className="flex bg-green py-3.5 px-5 justify-center gap-2 rounded-md">
                      {content.buttonLinks?.buttonLinksThree.map(
                        (button, index) => (
                          <Link
                            to={button.link}
                            key={index}
                            className="text-white text-base font-semibold"
                          >
                            {button.title}
                          </Link>
                        )
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-start w-full md:w-3/5 lg:w-1/2 xl:w-1/3">
            <StudentInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamersToAchiever;
