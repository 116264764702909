// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-container {
  position: relative;
  display: inline-block;
}

.popover-trigger {
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 1s;
}

.popover-content {
  position: absolute;
  top: 50%;
  right: 0%;
  padding: 6px;
  z-index: 1000;
  white-space: nowrap;
  transition: all 1s;
}`, "",{"version":3,"sources":["webpack://./src/components/popover/Popover.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EAEE,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EAQA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAPF","sourcesContent":[".popover-container {\n  position: relative;\n  display: inline-block;\n}\n\n.popover-trigger {\n  // background-color: #007BFF;\n  color: white;\n  border: none;\n  padding: 10px;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: all 1s;\n}\n\n.popover-content {\n  position: absolute;\n  top: 50%;\n  right: 0%;\n  // left: 50%;\n  // transform: translateX(-50%);\n  // margin-top: 10px;\n  // background-color: white;\n  // border: 1px solid #ccc;\n  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  // border-radius: 4px;\n  padding: 6px;\n  z-index: 1000;\n  white-space: nowrap;\n  transition: all 1s;\n  // transition: visibility .3s linear, opacity .3s ease, margin-top .3s ease, height .3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
