import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FilterMenu from "./FilterMenu";
import FilterTabs from "./FilterTabs";
import { FilterCategory } from "../../../components/filter/FilterEnum";
import { addNewFilter } from "../../../store/slices/filterSlice";

const streamFilterData = {
  categoryKey: "stream",
  categoryName: "Stream",
  type: FilterCategory.MultiSelect,
  options: [
    { key: "engineering", name: "Engineering" },
    { key: "management", name: "Management" },
    { key: "arts", name: "Arts" },
  ],
};

const stateFilterData = {
  categoryKey: "state",
  categoryName: "State",
  type: FilterCategory.MultiSelect,
  options: [
    { key: "tamil-nadu", name: "Tamil Nadu" },
    { key: "kerala", name: "Kerala" },
    { key: "karnataka", name: "Karnataka" },
    { key: "andhra-pradesh", name: "Andhra Pradesh" },
    { key: "telangana", name: "Telangana" },
    { key: "assam", name: "Assam" },
  ],
};

const Filter = () => {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const [selectedFilterCategoryIndex, setSelectedFilterCategoryIndex] =
    useState(0);

  const handleSelectedFilterCategoryIndexChange = (newIndex: number) =>
    setSelectedFilterCategoryIndex(newIndex);

  useEffect(() => {
    dispatch(addNewFilter(streamFilterData));
    dispatch(addNewFilter(stateFilterData));
  }, []);

  return (
    <>
      <FilterTabs
        handleShowFilter={() => setShowFilter(true)}
        changeFilterMenuActiveCategory={(index) =>
          setSelectedFilterCategoryIndex(index)
        }
      />
      <FilterMenu
        isOpen={showFilter}
        onClose={() => {
          setShowFilter(false);
        }}
        selectedCategoryIndex={selectedFilterCategoryIndex}
        handleSelectedCategoryIndexChange={
          handleSelectedFilterCategoryIndexChange
        }
      />
    </>
  );
};

export default Filter;
