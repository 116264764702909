import React, { useState, useEffect } from "react";
import "animate.css";
const accordiandown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
  >
    <circle cx="25.8311" cy="25" r="24.5" fill="white" stroke="#BDBDBD" />
    <path
      d="M22.8311 31L29.0511 24.7518L22.8311 18.5035"
      stroke="#113CC0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const accordianup = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
  >
    <circle cx="25.4871" cy="25.3309" r="25.3309" fill="#113CC0" />
    <path
      d="M18.8301 22L25.0783 28.22L31.3266 22"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Item {
  title: string;
  description: string;
}

const items: Item[] = [
  {
    title: "What is Webflow and why is it the best website builder?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "What is your favorite template from BRIX Templates?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "How do you clone a Webflow Template from the Showcase?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "Why is BRIX Templates the best Webflow agency out there?",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

const FaqCollegesection: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  useEffect(() => {
    setActiveItem(0);
  }, []);

  const handleClick = (index: number) => {
    setActiveItem(index === activeItem ? null : index);
  };

  return (
    <div className="flex flex-row py-16" id="faqs">
      <div className="container mx-auto">
        <div className="bg-[#F4F7FB] rounded-[20px] p-24">
          <div className="flex flex-col justify-center gap-8">
            <div className="flex flex-row flex-wrap justify-center">
              <h3 className="text-3xl font-bold text-[#231F40]">
                FAQ's About Software Engineer
              </h3>
            </div>

            <div className="items-container flex flex-col gap-7">
              {items.map((item, index) => (
                <>
                  <div
                    key={index}
                    className={`flex flex-col px-12 pt-9 pb-9  bg-white rounded-2xl  max-md:px-5 max-md:max-w-full gap-3 ${
                      activeItem === index
                        ? "border-2 border-blue-800 border-solid shadow-lg"
                        : ""
                    }`}
                  >
                    <div className="flex gap-5 font-medium text-[#333333] max-md:flex-wrap max-md:max-w-full">
                      <div className="flex-auto my-auto max-md:max-w-full">
                        {item.title}
                      </div>
                      <button
                        onClick={() => handleClick(index)}
                        className="hover:translate-x-1"
                      >
                        {activeItem === index ? (
                          <div>{accordianup}</div>
                        ) : (
                          <div>{accordiandown}</div>
                        )}
                      </button>
                    </div>
                    {activeItem === index && (
                      <div
                        className={`leading-7 text-[#828282] max-md:max-w-full ${
                          activeItem ? "animate__animated animate-fadeIn" : ""
                        }`}
                      >
                        {item.description}
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqCollegesection;
