import React from "react";
import { Link, useLocation, useMatches, useRoutes } from "react-router-dom";

const BlogsBreadcrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  let pathnames = location.pathname.split("/").filter((x) => x);

  pathnames.splice(2, 1);
  console.log("pathnames", pathnames);
  const segments = pathname?.split("/");

  const captilizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <>
      {/* <nav className="flex text-gray-700" aria-label="Breadcrumb">
        <ol className="inline-flex flex-wrap items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Exams
              </a>
            </div>
          </li>{" "}
          <li>
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Colleges Entrance Exams News
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">
                CUET 2024 Result Live: NTA CUET UG results date and time soon;
                Direct link, Cutoff, Updates
              </span>
            </div>
          </li>
        </ol>
      </nav> */}
      <nav className="flex text-gray-700" aria-label="Breadcrumb">
        <ol className="flex flex-wrap space-x-1 md:space-x-1">
          <li className="flex items-center">
            <Link
              to={"/blogs"}
              state={{
                from: "BreadCrumbs",
                category: "",
              }}
              className="ml-1 text-sm text-gray-700 font-inter hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
            >
              Home
            </Link>
          </li>
          {pathnames.map((value, index) => {
            const to = `/blogs/${pathnames.slice(1, index + 1).join("/")}`;
            // console.log("index", index, pathnames.length);
            return (
              <>
                {value !== "blogs" && (
                  <li key={index}>
                    <div className="flex items-center font-inter">
                      <svg
                        className="w-6 h-6 fill-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>

                      <Link
                        to={index == pathnames.length - 1 ? "" : to}
                        state={{
                          from: "BreadCrumbs",
                          category: decodeURIComponent(
                            captilizeFirstLetter(value)
                          ),
                        }}
                        className={`${
                          index == pathnames.length - 1
                            ? "font-inter  ml-1 md:ml-2 text-sm text-gray-500 cursor-auto"
                            : "font-inter  ml-1 md:ml-2 text-sm  text-gray-800 hover:text-gray-900"
                        }`}
                      >
                        {decodeURIComponent(captilizeFirstLetter(value))}
                      </Link>
                    </div>
                  </li>
                )}
              </>
            );
          })}
        </ol>
      </nav>
      {/* <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex flex-wrap space-x-1 md:space-x-1">
          <li className="flex items-center">
            <Link
              to={"/blogs"}
              state={{
                from: "BreadCrumbs",
                category: "",
              }}
              className="text-black-700 font-400"
            >
              Home
            </Link>
          </li>
          {pathnames.map((value, index) => {
            const to = `/blogs/${pathnames.slice(1, index + 1).join("/")}`;
            console.log("index", index, pathnames.length);
            return (
              <>
                {value !== "blogs" && (
                  <li key={index}>
                    <div className="flex items-center font-inter">
                      {">"}

                      <Link
                        to={to}
                        state={{
                          from: "BreadCrumbs",
                          category: decodeURIComponent(
                            captilizeFirstLetter(value)
                          ),
                        }}
                        className="mx-2"
                        // className={`${
                        //   index == pathnames.length - 1
                        //     ? "font-inter  ml-1 md:ml-2 text-sm "
                        //     : "font-inter  ml-1 md:ml-2 text-sm  "
                        // }`}
                      >
                        {decodeURIComponent(captilizeFirstLetter(value))}
                      </Link>
                    </div>
                  </li>
                )}
              </>
            );
          })}
        </ol>
      </nav> */}
      {/* <span className="mx-2">Exams</span> <span>{">"}</span>
      <span className="mx-2">Colleges Entrance Exams News</span>
      {">"}
      <span className="mx-2">
        CUET 2024 Result Live: NTA CUET UG results date and time soon; Direct
        link, Cutoff, Updates
      </span> */}
    </>
  );
};

export default BlogsBreadcrumb;
