import React from "react";
import MechanicalEng from "../../../components/courses/course-details/overview/AboutMecEngineering";
import HighlightMecEngineering from "../../../components/courses/course-details/overview/HighlightMecEngineering";
import TopMecEngineering from "../../../components/courses/course-details/overview/TopMecEngineering";
import TopUniversitiesMecEngineering from "./overview/TopUniversitiesMecEngineering";
import CourseCurriculamMecEngineering from "./overview/CourseCurriculamMecEngineering";
import ExamsList from "./overview/ExamsList";
import TopIndianPrivateColleges from "./overview/TopIndianPrivateColleges";
import { TopGovernmentCollegesInIndia } from "./overview/TopGovernmentCollegesInIndia";

const CourseOverview: React.FC = () => {
  return (
    <div>
      <div className="bg-[#ECECEC]">
        <div className="container mx-auto py-6 md:py-8 relative flex flex-col max-md:gap-0 md:gap-6">
          <MechanicalEng />
          <HighlightMecEngineering />
          <TopMecEngineering />
          {/* <TopUniversitiesMecEngineering /> */}
          <TopIndianPrivateColleges />
          <TopGovernmentCollegesInIndia />
          <CourseCurriculamMecEngineering />
        </div>
      </div>
    </div>
  );
};

export default CourseOverview;
