import React, { useState } from "react";
import DesktopFilters, { FilterCategory } from "./DesktopFilters";
import MobileFilters from "./MobileFilters";

interface FiltersSectionProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
  mobileFilter: boolean;
  handleMobileFilterClose: () => void;
}
const isMediumDevice = window.innerWidth >= 768;

const FiltersSection: React.FC<FiltersSectionProps> = ({
  filters,
  setSelectedVal,
  setFilters,
  mobileFilter,
  handleMobileFilterClose,
}) => {
  return (
    <div>
      {!isMediumDevice && mobileFilter && (
        <MobileFilters
          filters={filters}
          setSelectedVal={setSelectedVal}
          setFilters={setFilters}
          handleMobileFilterClose={handleMobileFilterClose}
        />
      )}
      {isMediumDevice && (
        <DesktopFilters
          filters={filters}
          setSelectedVal={setSelectedVal}
          setFilters={setFilters}
        />
      )}
    </div>
  );
};

export default FiltersSection;
