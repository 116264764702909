import { icon } from "@fortawesome/fontawesome-svg-core";
import { Description } from "@mui/icons-material";
import React from "react";
import userIcon from "../../../../images/courses/course-details/userIcon.svg";
import { list } from "@material-tailwind/react";
import { title } from "process";
import List from "../../../list/List";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

import { SVGProps } from "react";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const ScopeMechEngineeringContent = {
  title: "Scope of Mechanical Engineering in India and Abroad",
  description:
    "The scope of Mechanical Engineers in India and abroad is very broad, with a more number of opportunities in any industry. Students holding a degree in Mechanical Engineering can find employment in aerospace, chemical manufacturing plants, automobiles, research and development, oil exploration, railway factories and many others. With the advent of technology in recent times, the expertise and skills of a Mechanical Engineer are increasingly sought after in more technology-specific domains including nanotechnology, biomedical engineering, Artificial Intelligence, robotics, and energy conservation, among others. ",
  ScopeLiked: {
    title: "Student Also Liked:",
    List: [
      "Online Degree and Diploma Courses",
      "Online Free Courses and Certifications",
      "Online Short Term Courses and Certifications",
      "Online Certification Courses",
      "View all Online Courses and Certifications",
    ],
  },
};

const ScopeMechEngineeringIndia: React.FC = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", scopeOfCourse } = data || {};
  return (
    <div className="flex flex-col  bg-[#fff] relative">
      <div className="flex flex-col">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {`Scope of ${courseTitle} in India and Abroad`}
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9">
          <p className="text-base text-[#4C495C] m-0">
            {scopeOfCourse.description[0]}
          </p>
          {/* <div className="flex flex-col gap-2">
            <h5 className="text-base font-semibold text-[#4C495C] ">
              {ScopeMechEngineeringContent.ScopeLiked.title}
            </h5>
            <ul className="list-none list-inside">
              {scopeOfCourse?.students_also_liked.map(
                (item: string, index: number) => (
                  <li
                    key={index}
                    className="text-base text-[#173CBA] leading-7 flex gap-1"
                  >
                    {">"} {item}
                  </li>
                )
              )}
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ScopeMechEngineeringIndia;
