// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dreamcareerJourney-swiper {
  padding-bottom: 20px;
}
.dreamcareerJourney-swiper .swiper-button-next,
.dreamcareerJourney-swiper .swiper-button-prev {
  color: black;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.dreamcareerJourney-swiper .swiper-button-prev {
  left: -20px;
}
.dreamcareerJourney-swiper .swiper-button-next {
  right: -20px;
}
.dreamcareerJourney-swiper .swiper-slide {
  opacity: 0.5;
  transform: scale(0.9);
}
.dreamcareerJourney-swiper .swiper-slide-active {
  opacity: 1;
  transform: scale(1);
}

.learn-tab .tabscontent-highlight,
.experience-tab .tabscontent-highlight {
  background-color: #fff;
  border: #00cc99 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/careers-comp/dreamCareerJourney/DreamCareerJourneyStyles.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;;EAEE,YAAA;EACA,eAAA;EACA,QAAA;EACA,2BAAA;AAEJ;AACE;EACE,WAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,YAAA;EACA,qBAAA;AADJ;AAIE;EACE,UAAA;EACA,mBAAA;AAFJ;;AAKA;;EAEE,sBAAA;EACA,yBAAA;AAFF","sourcesContent":[".dreamcareerJourney-swiper {\n  padding-bottom: 20px;\n  .swiper-button-next,\n  .swiper-button-prev {\n    color: black;\n    font-size: 24px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  .swiper-button-prev {\n    left: -20px;\n  }\n\n  .swiper-button-next {\n    right: -20px;\n  }\n\n  .swiper-slide {\n    opacity: 0.5;\n    transform: scale(0.9);\n  }\n\n  .swiper-slide-active {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n.learn-tab .tabscontent-highlight,\n.experience-tab .tabscontent-highlight {\n  background-color: #fff;\n  border: #00cc99 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
