import { useState } from "react";
import "./Banner.scss";

const Banner = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <section className="relative bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] md:from-transparent md:to-[#89C9ED]">
      <div
        className="hidden md:block absolute inset-0 flex items-center justify-center"
        style={{
          backgroundImage: 'url(/images/college-predictor/banner.png)',
          backgroundSize: 'contain', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="relative flex flex-col items-center justify-center">
        <div className="container pl-8 md:pl-0 flex flex-col md:flex-row items-center md:justify-between py-12 lg:py-20 px-4">
          <div className="md:w-[50%] flex flex-col">
            <div className="predictor-heading">
              Find Your Perfect College Fit
              <br />
              Predict, Plan, and Succeed!
            </div>
            <div className="predictor-subheading mb-4 md:pr-40 lg:pr-60 pt-4">
              Get tailored recommendations and make smart choices with our College Predictor and Rank Predictor tools.
            </div>
            <div className="predictor-para">
              <p>
                Step 1: Choose your Exam <br />
                Step 2: Provide Your Details <br />
                Step 3: Predict Your Dream College and course
              </p>
            </div>
            <div>
              <button 
                onClick={openPopup}
                className="mt-4 w-[314px] h-[32px] flex-shrink-0 rounded-[5px] bg-[#0C9] text-white flex items-center justify-center border-none cursor-pointer"
              >
                Predict My Rank in 2 minutes&nbsp;&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" className="arrow-svg" width="29" height="12" viewBox="0 0 29 12" fill="none">
                  <path d="M28.783 6.53033C29.0758 6.23744 29.0758 5.76256 28.783 5.46967L24.01 0.696698C23.7171 0.403805 23.2422 0.403805 22.9493 0.696698C22.6564 0.989591 22.6564 1.46446 22.9493 1.75736L27.192 6L22.9493 10.2426C22.6564 10.5355 22.6564 11.0104 22.9493 11.3033C23.2422 11.5962 23.7171 11.5962 24.01 11.3033L28.783 6.53033ZM0.157898 6.75L28.2526 6.75L28.2526 5.25L0.157898 5.25L0.157898 6.75Z" fill="white"/>
                </svg>
              </button>

              {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-4 rounded shadow-lg">
                    <h2 className="text-lg font-bold">Prediction Results</h2>
                    <p>Your rank prediction will appear here.</p>
                    <button 
                      onClick={closePopup}
                      className="mt-2 p-2 bg-red-500 text-white rounded"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="block md:hidden">
          <img src="/images/college-predictor/banner2.png" alt="Banner"/>
        </div>
      </div>
    </section>
  );
};

export default Banner;
