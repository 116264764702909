import React, { useState } from "react";

interface Props {
  title: string;
  description: string;
  yearWiseData: {
    year: string;
    projects: {
      "Title of the project": string;
      "Name of PI": string;
      Department: string;
      "Funding agency": string;
      "Sanctioned amount in lakhs": number;
      "Reference number": string;
      "No of years": string;
    }[];
  }[];
}
const SponseredResearchContent = [
  {
    title: "Research funding & Sponsered research",
    description:
      "SRMIST facilitates research pursuits of our faculty members, scholars and students at scale and it is the impact. There is a dedicated focus on ensuring funding is in place so that research projects and studies progress satisfactorily. Incentives in the form of awards and prizes are given out to those demonstrating excellence in research. Each department of SRMIST has the flexibility, freedom and encouragement to pursue various grants and funding from Government, Industry and Multilateral organizations. It is due to this prevalent culture of research that SRMIST enjoys the credibility as a committed and competent institution. This is affirmed in the scale of research grants that annually exceed 100s of crores of Indian Rupees.",
    yearWiseData: [
      {
        year: "2024",
        projects: [],
      },
      {
        year: "2023",
        projects: [
          {
            "Title of the project":
              "National family Health survey – round 6 for the state TamilNadu (North and Puducherry)-Phase I.",
            "Name of PI": "Dr. Bharathi.P (project coordinator)",
            Department: "Public Health",
            "Funding agency": "International Institute for Population Sciences",
            "Sanctioned amount in lakhs": 750,
            "Reference number": "GEM/2022/B/2608070",
            "No of years": "1 year",
          },
          {
            "Title of the project":
              "ICMR Centre for Advanced Research (CAR)- Phase 1 Clinical trial Networks",
            "Name of PI": "Dr Satyajit Mohaptra",
            Department: "Centre for Clinical Trials and research",
            "Funding agency": "Indian Council of Medical Research (ICMR)",
            "Sanctioned amount in lakhs": 550,
            "Reference number": "ICMR/BMS/PhaseI/CAR/2023/SRM",
            "No of years": "5 years",
          },
          {
            "Title of the project": "Startup India Seed Fund Scheme.",
            "Name of PI": "Dr. Shantanu Patil",
            Department: "SIIEC",
            "Funding agency":
              "Department for Promotion of Industry and Internal Trade, Ministry of Commerce and Industry",
            "Sanctioned amount in lakhs": 300,
            "Reference number": "P-38015/2/2023-STARTUP INDIA",
            "No of years": "",
          },
          {
            "Title of the project":
              "India Norway cooperation project on capacity building for reducing plastic and chemical pollution in India (INOPOL).",
            "Name of PI": "Dr. Paromita Chakraborthy",
            Department: "REACH",
            "Funding agency": "Royal Norwegian Embassy in New Delhi",
            "Sanctioned amount in lakhs": 177,
            "Reference number": "0148/2023 project no:220260",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Data-driven Novel Enzyme Discovery towards Enzyme Combinations for Plastic Degradation.",
            "Name of PI": "Dr. Rajnish Narayanan & Dr. M.Thirumurthy",
            Department: "Genetics Engineering",
            "Funding agency":
              "Indo-Sweden Joint call -Circular Economy by Ministry of Earth Sciences (MoES).",
            "Sanctioned amount in lakhs": 150,
            "Reference number": "",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Visvesvaraya PhD Scheme for Electronics and IT: Phase II.",
            "Name of PI": "Dr. Malarvizhi",
            Department: "Electronics and Communication Engineering",
            "Funding agency":
              "Ministry of Electronics and Information Technologies",
            "Sanctioned amount in lakhs": 137.21,
            "Reference number": "PhD-02/2022-70/2023/50",
            "No of years": "5 years",
          },
          {
            "Title of the project":
              "Effect of targeted parental education intervention to identify early childhood developmental disorder-multisite interventional study.",
            "Name of PI": "Dr.Vadivelan",
            Department: "Physiotherapy",
            "Funding agency": "Indian Council of Medical Research (ICMR)",
            "Sanctioned amount in lakhs": 102.14,
            "Reference number": "",
            "No of years": "",
          },
          {
            "Title of the project":
              "Solar Light driven Highly Efficient Production of Hydrogen.",
            "Name of PI": "Prof. B.Neppolian",
            Department: "Chemistry",
            "Funding agency": "SPARC",
            "Sanctioned amount in lakhs": 81,
            "Reference number": "SPARC/2019-2020/P2197/SL",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Design and fabrication of Nanostructured metal hydride based hydrogen storage material for portable, light weight applications.",
            "Name of PI": "Dr. Iyakutty",
            Department: "Physics & Nanotechnology",
            "Funding agency": "Office of Naval Research (S&T)",
            "Sanctioned amount in lakhs": 66,
            "Reference number": "N62909-23-1-2081",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "IoT based device for Rapid STI Detection of T. pallidum, C. trachomatis, and N. gonorrhoeae",
            "Name of PI": "Dr. Ramya",
            Department: "Genetics Engineering",
            "Funding agency": "Indian Council of Medical Research (ICMR)",
            "Sanctioned amount in lakhs": 57.7,
            "Reference number": "",
            "No of years": "",
          },
          {
            "Title of the project":
              "Product Development of Indigenous Cost-Effective High-Performance Electrical Vehicles Automotive Grade 40kW PMSM and Controller.",
            "Name of PI": "Dr. Bharatiraja",
            Department: "Electrical and Electronics Engineering",
            "Funding agency": "Department of Science & Technology",
            "Sanctioned amount in lakhs": 50,
            "Reference number": "",
            "No of years": "",
          },
          {
            "Title of the project":
              "Exploring the novel piezoelectric ternary nitride system for realizing efficient high-temperature Ultrasonic Transducer and AE sensors.",
            "Name of PI": "Dr. Kiran Mangalampalli",
            Department: "Physics and nanotechnology",
            "Funding agency": "MHRD-STAR Scheme",
            "Sanctioned amount in lakhs": 45,
            "Reference number": "",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Digital Twin Assisted Operational Decision Support System for Deep Ocean Manned scientific submersible.",
            "Name of PI": "Dr. J.Preetha Roselyn",
            Department: "Electrical and Electronics Engineering",
            "Funding agency": "Ministry of Earth Sciences",
            "Sanctioned amount in lakhs": 49.08,
            "Reference number": "MoES/PAMC/DOM/07/2022(E-12846)",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Ultrafast Broadband Laser based on chalcogenide Photonic crystal fiber for THz generation and applications.",
            "Name of PI": "Dr. Balamurugan",
            Department: "Physics and Nanotechnology",
            "Funding agency": "DRDO",
            "Sanctioned amount in lakhs": 44.7,
            "Reference number": "",
            "No of years": "3 years",
          },
          {
            "Title of the project": "Continuous Ozone Monitoring Station",
            "Name of PI": "Dr. Arul",
            Department: "Chemistry",
            "Funding agency": "Department of Science and Technology (DST)",
            "Sanctioned amount in lakhs": 39.71,
            "Reference number": "2022/001-IND/ODE/023",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Thrust on development of self-healing materials for engineering applications- thrust area research",
            "Name of PI": "Dr. S. Manikandan",
            Department: "Physics and Nanotechnology",
            "Funding agency": "UGC",
            "Sanctioned amount in lakhs": 27,
            "Reference number": "UGC/2021-2022/THD/304",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Population Council for implementation of National Family Health survey – round 5.",
            "Name of PI": "Dr. Bharathi.P (project coordinator)",
            Department: "Public Health",
            "Funding agency": "Ministry of Health and Family welfare",
            "Sanctioned amount in lakhs": 25,
            "Reference number": "NFHS/2019-20/NORTH/01",
            "No of years": "1 year",
          },
          {
            "Title of the project":
              "Multi-Center Study on Post-vaccination Symptomatic and Immunological Profile in Adult Indian Population Receiving COVID-19 Vaccines.",
            "Name of PI": "Dr. Paromita Chakraborty",
            Department: "Research Department",
            "Funding agency": "ICMR",
            "Sanctioned amount in lakhs": 22.68,
            "Reference number": "ICMR/SRM/2022/0762",
            "No of years": "1 year",
          },
          {
            "Title of the project":
              "National family Health survey – round 5 for the state TamilNadu (North and Puducherry)-Phase II.",
            "Name of PI": "Dr. Bharathi.P (project coordinator)",
            Department: "Public Health",
            "Funding agency": "International Institute for Population Sciences",
            "Sanctioned amount in lakhs": 20,
            "Reference number": "GEM/2022/B/2608070",
            "No of years": "1 year",
          },
          {
            "Title of the project":
              "Development of a field portable, low-cost Microbial Fuel cell-based toxicity sensor for detection of aquatic pollutants in real field settings.",
            "Name of PI": "Dr. Paromita Chakraborty",
            Department: "Research Department",
            "Funding agency": "DST, Govt of India",
            "Sanctioned amount in lakhs": 16.56,
            "Reference number": "DST/TDT/DDP-39/2022",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "A detailed study of Raman scattering in CVD grown graphene layers and application of Raman spectroscopy to develop novel optical limiters.",
            "Name of PI": "Dr. Kiran. M",
            Department: "Physics and Nanotechnology",
            "Funding agency": "UGC",
            "Sanctioned amount in lakhs": 15.4,
            "Reference number": "UGC/2021/NC/0894",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Exploring the impact of anthropogenic activities on ecosystem services and human health across the natural wetland boundaries of north and south Pichavaram mangrove.",
            "Name of PI": "Dr. Vadivelan",
            Department: "Physiotherapy",
            "Funding agency": "ICMR",
            "Sanctioned amount in lakhs": 14.7,
            "Reference number": "ICMR/ENV/2022/2347",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Tissue-specific genetic testing for diagnosis and personalized medicine of heart diseases.",
            "Name of PI": "Dr. Rajnish Narayan & Dr. M. Thirumurthy",
            Department: "Genetics Engineering",
            "Funding agency": "DST",
            "Sanctioned amount in lakhs": 14.7,
            "Reference number": "DST/GEN/2022/6765",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Nutritional Intervention among Primary School Children with Iron Deficiency Anemia and effect on Gut Microbiota.",
            "Name of PI": "Dr. S.Sampath",
            Department: "Public Health",
            "Funding agency": "ICMR",
            "Sanctioned amount in lakhs": 14.52,
            "Reference number": "ICMR/NUT/2022/2830",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Time Domain Multi-scale Modelling for Characterization of Early Fatigue Damage in Heterogeneous Material System.",
            "Name of PI": "Dr. V. Chandrasekaran",
            Department: "Mechanical Engineering",
            "Funding agency": "DST-SERB",
            "Sanctioned amount in lakhs": 14.19,
            "Reference number": "DST/SERB/2023/3426",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Survey of Groups of Galaxies: A Multi-Wavelength Approach to Characterize the Dynamics and Galaxy Properties in Infall Regions of Galaxy Groups.",
            "Name of PI": "Dr. M.D.John",
            Department: "Physics",
            "Funding agency": "Department of Science and Technology",
            "Sanctioned amount in lakhs": 13.9,
            "Reference number": "DST/2023-2024/Astronomy/1548",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Extension project for NFHS -4 of Tamil Nadu and Puducherry - Phase I.",
            "Name of PI": "Dr. Bharathi. P (project coordinator)",
            Department: "Public Health",
            "Funding agency": "International Institute for Population Sciences",
            "Sanctioned amount in lakhs": 11.75,
            "Reference number": "GEM/2021/B/1450070",
            "No of years": "6 months",
          },
          {
            "Title of the project":
              "Assessing the health status of migrant workers and evaluation of Occupational health services in Chennai, India - A mixed methods approach.",
            "Name of PI": "Dr. Jameela George",
            Department: "Public Health",
            "Funding agency": "ICMR",
            "Sanctioned amount in lakhs": 11.56,
            "Reference number": "ICMR/BMS/2021-2022/PH",
            "No of years": "1 year",
          },
          {
            "Title of the project":
              "Intervention of the behavioral change approach to reduce STI/HIV prevalence among migrant laborers – A randomized control trial in Tamil Nadu.",
            "Name of PI": "Dr. S. Mathews",
            Department: "Public Health",
            "Funding agency": "Indian Council of Medical Research (ICMR)",
            "Sanctioned amount in lakhs": 9.57,
            "Reference number": "ICMR/2023/SRM",
            "No of years": "3 years",
          },
          {
            "Title of the project":
              "Exploring the barriers in treating Hypertension among fishermen community in Tamil Nadu.",
            "Name of PI": "Dr. Vadivelan",
            Department: "Physiotherapy",
            "Funding agency": "ICMR",
            "Sanctioned amount in lakhs": 5.23,
            "Reference number": "",
            "No of years": "2 years",
          },
          {
            "Title of the project":
              "Development and testing of a mobile application to access timely diagnosis and treatment of Neonatal jaundice – A pilot study.",
            "Name of PI": "Dr. Suma. N",
            Department: "Biomedical Engineering",
            "Funding agency": "Indian Council of Medical Research (ICMR)",
            "Sanctioned amount in lakhs": 4.75,
            "Reference number": "",
            "No of years": "1 year",
          },
        ],
      },
      {
        year: "2022",
        projects: [],
      },
      {
        year: "2021",
        projects: [],
      },
      {
        year: "2020",
        projects: [],
      },
      {
        year: "2019",
        projects: [],
      },
    ],
  },
];
const SponseredResearch: React.FC<Props> = (props) => {
  const [activeYear, setActiveYear] = useState("2023"); // Default to 2023

  const handleButtonClick = (year: string) => {
    setActiveYear(year);
  };

  const activeYearData = props.yearWiseData.find(
    (data) => data.year === activeYear
  );

  return (
    <div className="flex flex-row py-16">
      <div className="container mx-auto">
        <div className="flex flex-col gap-10">
          <h3 className="text-3xl font-bold text-[#231F40]">{props.title}</h3>
          <p className="text-lg text-[#231F40]">{props.description}</p>
          <div className="flex flex-row gap-5 justify-center items-center">
            {props.yearWiseData.map((data) => (
              <button
                key={data.year}
                className={activeYear === data.year ? "active" : ""}
                onClick={() => handleButtonClick(data.year)}
              >
                {data.year}
              </button>
            ))}
          </div>
          <div className="flex flex-row">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="px-4 py-2">Title of the project</th>
                  <th className="px-4 py-2">Name of PI</th>
                  <th className="px-4 py-2">Department</th>
                  <th className="px-4 py-2">Funding agency</th>
                  <th className="px-4 py-2">Sanctioned amount in lakhs</th>
                  <th className="px-4 py-2">Reference number</th>
                  <th className="px-4 py-2">No of years</th>
                </tr>
              </thead>
              <tbody>
                {activeYearData &&
                  activeYearData.projects.map((project, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">
                        {project["Title of the project"]}
                      </td>
                      <td className="border px-4 py-2">
                        {project["Name of PI"]}
                      </td>
                      <td className="border px-4 py-2">{project.Department}</td>
                      <td className="border px-4 py-2">
                        {project["Funding agency"]}
                      </td>
                      <td className="border px-4 py-2">
                        {project["Sanctioned amount in lakhs"]}
                      </td>
                      <td className="border px-4 py-2">
                        {project["Reference number"]}
                      </td>
                      <td className="border px-4 py-2">
                        {project["No of years"]}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponseredResearch;
