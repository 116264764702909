import { icon } from "@fortawesome/fontawesome-svg-core";
import { Description } from "@mui/icons-material";
import React from "react";
import "../careers/CareersModule.scss";
import userIcon from "../../../../images/courses/course-details/userIcon.svg";
import { list } from "@material-tailwind/react";
import { SVGProps } from "react";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay } from "swiper/modules";

import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const CareersMechEngContent = {
  title: "Careers in Mechanical Engineering",
  description:
    "A career in Mechanical Engineering can be highly rewarding for several reasons. This is a very reputed profession enjoying high demand, with average salaries on the higher side for both public and private organisations. In terms of career opportunities, these professionals can find opportunities in government organisations, management, and law among others. Therefore, Mechanical Engineering is probably one of the most lucrative, exciting, and opportunity-rich career options today.  Mentioned in the table below are the top career options in the field of Mechanical Engineering.",
  careerList: [
    {
      icon: userIcon,
      title: "Mechanical Engineer",
      moreLink: "#",
    },
    {
      icon: userIcon,
      title: "Automotive Engineer",
      moreLink: "#",
    },
    {
      icon: userIcon,
      title: "Aerospace Engineer",
      moreLink: "#",
    },
  ],
};

const CareersMechEngineering: React.FC = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "" } = data || {};
  return (
    <div className="flex flex-col bg-[#fff] relative" id="career-types">
      <div className="flex max-md:w-full">
        <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
          {`Careers in ${courseTitle}`}
        </h3>
      </div>
      <div className="flex flex-col px-2 py-8 md:py-5 md:px-9">
        <div className="flex w-full">
          <Swiper
            spaceBetween={14}
            className="carrerInCourse"
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 3,
                autoplay: false,
              },
            }}
          >
            {CareersMechEngContent.careerList.map((career) => (
              <SwiperSlide>
                <div className="flex flex-row gap-4 items-center bg-[#173CBA] rounded-full justify-between pr-2 cursor-pointer group">
                  <div className="flex flex-row items-center gap-4">
                    <img
                      src={career.icon}
                      alt="icon"
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <h4 className="text-base font-normal text-white m-0 leading-7">
                      {career.title}
                    </h4>
                  </div>
                  <svg
                    width="47"
                    height="47"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="-translate-x-2 group-hover:translate-x-2  transition-transform duration-300"
                  >
                    <path
                      d="M17 34.0292C26.3888 34.0292 34 26.4176 34 17.0283C34 7.63891 26.3888 0.0273438 17 0.0273438C7.61116 0.0273438 0 7.63891 0 17.0283C0 26.4176 7.61116 34.0292 17 34.0292Z"
                      fill="#00CC99"
                    />
                    <path
                      d="M12.8447 5.31641V10.5258L19.1369 17.0281L12.8447 23.5304V28.7399L24.1781 17.0281L12.8447 5.31641Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex flex-col gap-4 mt-5">
          <p className="text-base text-[#4C495C] m-0">
            {CareersMechEngContent.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareersMechEngineering;
