import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./DreamCareerJourneyStyles.scss";
import SwiperCore, { Autoplay, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";

type JourneyItem = {
  description: string;
  imageUrl: string;
  title: string;
  buttonText: string;
};

type DreamCareerJourneyProps = {
  Explore: JourneyItem[];
  Learn: JourneyItem[];
  Experience: JourneyItem[];
};

const DreamCareerJourney: React.FC<DreamCareerJourneyProps> = ({
  Explore,
  Learn,
  Experience,
}) => {
  const [activeWay, setActiveWay] = useState<string>("Explore");
  const [activeItem, setActiveItem] = useState<JourneyItem>(Explore[0]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);
  const [fade, setFade] = useState<boolean>(false);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleItemClick = (item: JourneyItem, index: number) => {
    setFade(true);
    setTimeout(() => {
      setActiveItem(item);
      setFade(false); // Remove fade after item is updated
    }, 200);
    if (swiperInstance) {
      swiperInstance.slideTo(index); // Move the clicked slide to the center
    }
  };

  const renderExploreItems = (items: JourneyItem[]) => (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-12 xl:gap-28">
      <div className="flex flex-col gap-10 lg:gap-24">
        <div className="flex flex-col">
          {items[0]?.imageUrl && (
            <div className="w-full h-full lg:h-48 rounded-lg shadow-md overflow-hidden">
              <img
                src={items[0].imageUrl}
                alt={items[0].title}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}
          <div className="bg-[#B7DCFF] p-2 pb-14 rounded-md relative mx-5 -mt-6 flex flex-col gap-4">
            <p className="text-base text-black text-center line-clamp-3">
              {items[0]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block absolute left-1/2 -bottom-4 -translate-x-1/2 whitespace-nowrap"
            >
              {items[0]?.buttonText || "Career Match"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          {items[2]?.imageUrl && (
            <div className="w-full h-full lg:h-48 rounded-lg shadow-md overflow-hidden">
              <img
                src={items[2].imageUrl}
                alt={items[2].title}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}
          <div className="bg-[#B7DCFF] p-2 pb-10 rounded-md relative mx-5 -mt-6 flex flex-col gap-4">
            <p className="text-base text-black text-center mb-2 line-clamp-3">
              {items[2]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block absolute left-1/2 -bottom-4 -translate-x-1/2 whitespace-nowrap"
            >
              {items[2]?.buttonText || "Career Library"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-col">
          {items[1]?.imageUrl && (
            <div className="w-full h-full lg:h-[480px] rounded-lg shadow-md overflow-hidden">
              <img
                src={items[1].imageUrl}
                alt={items[1].title}
                className="w-full h-full object-cover rounded-lg mb-4"
              />
            </div>
          )}
          <div className="bg-[#B7DCFF] p-2 pb-14 rounded-md relative mx-5 -mt-6 flex flex-col gap-4">
            <p className="text-base lg:text-xl text-black text-center mb-2 line-clamp-2 font-normal lg:font-medium">
              {items[1]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block absolute left-1/2 -bottom-4 -translate-x-1/2 whitespace-nowrap"
            >
              {items[1]?.buttonText || "Career Assessment Test"}{" "}
              <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            {items[3]?.imageUrl && (
              <div className="w-full h-full lg:h-[345px] rounded-lg shadow-md overflow-hidden">
                <img
                  src={items[3].imageUrl}
                  alt={items[3].title}
                  className="w-full h-full object-cover rounded-lg mb-4"
                />
              </div>
            )}
            <div className="bg-[#B7DCFF] p-2 pb-14 rounded-md relative mx-5 -mt-6 flex flex-col gap-4">
              <p className="text-base text-black text-center mb-2 line-clamp-3">
                {items[3]?.description}
              </p>
              <Link
                to="/career-intermediate"
                className="bg-blue text-white px-4 py-2 rounded-full mx-auto block absolute left-1/2 -bottom-4 -translate-x-1/2 whitespace-nowrap"
              >
                {items[3]?.buttonText || "Map My Career"} <span>&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderLearnItems = (items: JourneyItem[]) => (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-12 xl:gap-28">
      <div className="flex flex-col gap-10 lg:gap-24">
        <div className="flex flex-col gap-2">
          {items[0]?.imageUrl && (
            <div className="w-full border-2 border-[#00CC99] h-full lg:h-48 rounded-[98px] shadow-md overflow-hidden">
              <img
                src={items[0].imageUrl}
                alt={items[0].title}
                className="w-full h-full object-cover rounded-[98px]"
              />
            </div>
          )}
          <div className="p-2 rounded-md relative flex flex-col gap-3">
            <p className="text-base text-black text-center line-clamp-3">
              {items[0]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[0]?.buttonText || "Virtual Internships"}{" "}
              <span>&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {items[2]?.imageUrl && (
            <div className="w-full border-2 border-[#00CC99] h-full lg:h-48 rounded-[98px] shadow-md overflow-hidden">
              <img
                src={items[2].imageUrl}
                alt={items[2].title}
                className="w-full h-full object-cover rounded-[98px]"
              />
            </div>
          )}
          <div className="p-2 rounded-md relative flex flex-col gap-3">
            <p className="text-base text-black text-center mb-2 line-clamp-3">
              {items[2]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[2]?.buttonText || "Career goals"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {items[1]?.imageUrl && (
          <div className="w-full h-full border-2 border-[#00CC99] rounded-full overflow-hidden shadow-lg">
            <img
              src={items[1].imageUrl}
              alt={items[1].title}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <div className="p-2 lg:border border-green rounded-md relative flex flex-col gap-4 w-full">
          <p className="text-base lg:text-xl text-black text-center mb-2 line-clamp-2 font-normal lg:font-medium">
            {items[1]?.description}
          </p>
          <Link
            to="/career-intermediate"
            className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
          >
            {items[1]?.buttonText || "Certifications"} <span>&rarr;</span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-6 justify-end">
        <div className="flex flex-col">
          {items[2]?.imageUrl && (
            <div className="w-full border-2 border-[#00CC99] h-full rounded-full shadow-md overflow-hidden">
              <img
                src={items[2].imageUrl}
                alt={items[2].title}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          )}
          <div className="p-2 rounded-md relative flex flex-col gap-3 w-full">
            <p className="text-base text-black text-center mb-2 line-clamp-3">
              {items[2]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[2]?.buttonText || "Map My Career"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const renderExperienceItems = (items: JourneyItem[]) => (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-12 xl:gap-28">
      <div className="flex flex-col gap-10 lg:gap-12">
        <div className="flex flex-col justify-end items-center">
          {items[0]?.imageUrl && (
            <div className="w-full h-full lg:w-56 lg:h-56 rounded-full shadow-md overflow-hidden">
              <img
                src={items[0].imageUrl}
                alt={items[0].title}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          )}
          <div className="p-2 pb-14 rounded-md relative flex flex-col gap-4">
            <p className="text-base text-black text-center line-clamp-3">
              {items[0]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[0]?.buttonText || "Student Mentor"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-end items-center">
          {items[2]?.imageUrl && (
            <div className="w-full h-full xl:w-56 xl:h-56 rounded-full shadow-md overflow-hidden">
              <img
                src={items[2].imageUrl}
                alt={items[2].title}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          )}
          <div className="p-2 pb-14 rounded-md relative flex flex-col gap-4">
            <p className="text-base text-black text-center mb-2 line-clamp-3">
              {items[2]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[2]?.buttonText || "Career Spotlight"} <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end items-center gap-4 lg:gap-12">
        {items[1]?.imageUrl && (
          <div className="w-[500px] h-[500px] xl:w-[543px] xl:h-[543px] rounded-full overflow-hidden shadow-md">
            <img
              src={items[1].imageUrl}
              alt={items[1].title}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <div className="p-2 lg:border border-green rounded-md relative flex flex-col gap-4 w-full">
          <p className="text-base lg:text-xl text-black text-center mb-2 line-clamp-2 lg:font-medium">
            {items[1]?.description}
          </p>
          <Link
            to="/career-intermediate"
            className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
          >
            {items[1]?.buttonText || "Career pathway"} <span>&rarr;</span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-6 justify-end">
        <div className="flex flex-col items-center">
          {items[2]?.imageUrl && (
            <div className="w-full h-full xl:w-96 xl:h-96 rounded-full shadow-md overflow-hidden">
              <img
                src={items[2].imageUrl}
                alt={items[2].title}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          )}
          <div className="p-2 pb-14 rounded-md relative flex flex-col gap-4">
            <p className="text-base text-black text-center mb-2 line-clamp-3">
              {items[2]?.description}
            </p>
            <Link
              to="/career-intermediate"
              className="bg-blue text-white px-4 py-2 rounded-full mx-auto block whitespace-nowrap"
            >
              {items[2]?.buttonText || "Talk to Career Mentor"}{" "}
              <span>&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMobileContent = (items: JourneyItem[]) => {
    const tabClasses: { [key: string]: string } = {
      Explore: "explore-tab",
      Learn: "learn-tab",
      Experience: "experience-tab",
    };

    return (
      <div className={`flex flex-col gap-10 ${tabClasses[activeWay]}`}>
        <div className={`flex flex-col px-5 relative`}>
          {activeItem?.imageUrl && (
            <div
              className={`w-full h-full lg:h-48 rounded-lg overflow-hidden ${
                fade ? "opacity-0" : "opacity-100"
              } transition-opacity duration-500`}
            >
              <img
                src={activeItem.imageUrl}
                alt={activeItem.title}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}

          <div
            className={`flex flex-col gap-2 bg-[#B7DCFF] p-1 rounded-md relative items-center mx-auto w-11/12 bottom-5 tabscontent-highlight`}
          >
            <div className="flex flex-col min-h-14 pb-4">
              <p
                className={`text-base text-black text-center line-clamp-2 ${
                  fade ? "opacity-0" : "opacity-100"
                } transition-opacity duration-500`}
              >
                {activeItem?.description}
              </p>
            </div>
            <Link
              to="/career-intermediate"
              className={`bg-blue text-white px-3 py-2 rounded-full block whitespace-nowrap text-[8px] absolute left-1/2 -bottom-4 -translate-x-1/2`}
            >
              {activeItem?.buttonText || "Career Match"} <span>&rarr;</span>
            </Link>
          </div>
        </div>

        <Swiper
          spaceBetween={24}
          slidesPerView={1.5}
          centeredSlides={true}
          loop={true}
          modules={[Autoplay, Navigation]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onSlideChange={(swiper) => {
            setFade(true);
            setTimeout(() => {
              setActiveItem(items[swiper.realIndex]);
              setFade(false);
            }, 200);
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          className="flex flex-row gap-4 w-full dreamcareerJourney-swiper pb-6"
          breakpoints={{
            640: {
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide
              key={index}
              onClick={() => handleItemClick(item, index)}
            >
              <div
                className={`cursor-pointer flex flex-col items-center gap-2 relative ${tabClasses[activeWay]}`}
              >
                <div className="flex flex-col items-center w-full">
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="w-full h-44 object-cover rounded-lg"
                  />
                </div>
                <div className="flex flex-col gap-2 bg-[#B7DCFF] p-1 rounded-md relative items-center mx-auto w-11/12 bottom-5 tabscontent-highlight">
                  <p className="text-center text-black text-[8px] line-clamp-2 min-h-8 pb-3">
                    {item.description}
                  </p>
                  <Link
                    to="/career-intermediate"
                    className="bg-blue text-white px-3 py-2 rounded-full block whitespace-nowrap text-[8px] absolute left-1/2 -bottom-4 -translate-x-1/2"
                  >
                    {item.buttonText || "Career Library"} <span>&rarr;</span>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div className="swiper-button-prev">←</div>
          <div className="swiper-button-next">→</div>
        </Swiper>
      </div>
    );
  };

  return (
    <div className="bg-[#EEF2FF] py-12">
      <div className="container mx-auto">
        <div className="flex flex-col items-center gap-4">
          <h2 className="text-2xl lg:text-5xl font-bold text-center">
            Three ways to start your Dream Career journey
          </h2>
          <div className="flex justify-center gap-8 mb-8">
            <button
              onClick={() => {
                setActiveWay("Explore");
                setActiveItem(Explore[0]);
              }}
              className={`text-blue ${
                activeWay === "Explore"
                  ? " border-b-2 border-green font-bold"
                  : "border-b-2 font-normal duration-300"
              } text-lg pb-2`}
            >
              Explore
            </button>
            <button
              onClick={() => {
                setActiveWay("Learn");
                setActiveItem(Learn[0]);
              }}
              className={`text-blue ${
                activeWay === "Learn"
                  ? " border-b-2 border-green font-bold"
                  : "border-b-2 font-normal"
              } text-lg pb-2`}
            >
              Learn
            </button>
            <button
              onClick={() => {
                setActiveWay("Experience");
                setActiveItem(Experience[0]);
              }}
              className={`text-blue ${
                activeWay === "Experience"
                  ? " border-b-2 border-green font-bold"
                  : "border-b-2 font-normal"
              } text-lg pb-2`}
            >
              Experience
            </button>
          </div>
        </div>

        <div>
          {activeWay === "Explore" &&
            (isMobile
              ? renderMobileContent(Explore)
              : renderExploreItems(Explore))}
          {activeWay === "Learn" &&
            (isMobile ? renderMobileContent(Learn) : renderLearnItems(Learn))}
          {activeWay === "Experience" &&
            (isMobile
              ? renderMobileContent(Experience)
              : renderExperienceItems(Experience))}
        </div>
      </div>
    </div>
  );
};

export default DreamCareerJourney;
