// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-styles-explore {
  display: flex;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-styles-explore:hover {
  border-radius: 0;
  color: #fff;
  transition: 0.3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.29);
}

.green-color {
  background-color: #fff;
}

.title-explore {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
}

.green-color:hover {
  background: #00c798;
}

.blue-color {
  background-color: #fff;
}

.blue-color:hover {
  background: #113cc0;
}

.blue-text {
  color: #113cc0;
}

.blue-bg {
  background-color: #113cc0;
  color: #fff;
}

.tab-container-occupation {
  position: relative;
  display: inline-flex; /* Make the container only as wide as its contents */
  border-bottom: 2px solid #eaeaea; /* The line */
}

.ExploreMore-occupation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-icon {
  transition: transform 0.2s ease-in-out;
}

.arrow-icon:hover {
  transform: translateX(6px);
}`, "",{"version":3,"sources":["webpack://./src/components/courses/ExploreOccupation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,uCAAA;AACF;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;AAGF;;AADA;EACE,mBAAA;AAIF;;AAFA;EACE,sBAAA;AAKF;;AAHA;EACE,mBAAA;AAMF;;AAJA;EACE,cAAA;AAOF;;AAJA;EACE,yBAAA;EACA,WAAA;AAOF;;AAJA;EACE,kBAAA;EACA,oBAAA,EAAA,oDAAA;EAEA,gCAAA,EAAA,aAAA;AAMF;;AAJA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAOF;;AALA;EACE,sCAAA;AAQF;;AALA;EACE,0BAAA;AAQF","sourcesContent":[".button-styles-explore {\n  display: flex;\n  padding: 12px 12px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  border-radius: 50px;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.button-styles-explore:hover {\n  border-radius: 0;\n  color: #fff;\n  transition: 0.3s;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.29);\n}\n.green-color {\n  background-color: #fff;\n}\n.title-explore {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 21.79px;\n}\n.green-color:hover {\n  background: #00c798;\n}\n.blue-color {\n  background-color: #fff;\n}\n.blue-color:hover {\n  background: #113cc0;\n}\n.blue-text {\n  color: #113cc0;\n}\n\n.blue-bg {\n  background-color: #113cc0;\n  color: #fff;\n}\n\n.tab-container-occupation {\n  position: relative;\n  display: inline-flex; /* Make the container only as wide as its contents */\n\n  border-bottom: 2px solid #eaeaea; /* The line */\n}\n.ExploreMore-occupation {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.arrow-icon {\n  transition: transform 0.2s ease-in-out;\n}\n\n.arrow-icon:hover {\n  transform: translateX(6px); // Adjust the value as needed\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
