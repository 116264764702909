// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes translateY {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}
@keyframes translateY-2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}
.animate-translate {
  animation: translateY 0.5s ease-in-out forwards;
}

.animate-translate-2 {
  animation: translateY-2 0.5s ease-in-out forwards;
}

.animate-translate-delay-1 {
  animation-delay: 0.1s;
}

.banner-search-box {
  position: relative;
}

.search-suggestion {
  z-index: 100;
  position: absolute;
  top: 368px;
}

.searchz {
  z-index: 1000000;
}

.arw-button-college {
  background: none;
  cursor: pointer;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.arw-button-college:hover .arrow-icon {
  transform: translateX(6px); /* Adjust distance of movement */
}`, "",{"version":3,"sources":["webpack://./src/screens/colleges-new/landing/components/CollegeBanner.scss"],"names":[],"mappings":"AAAA;EACI;IACI,wBAAA;EACN;EAGE;IACE,6BAAA;EADJ;AACF;AAGE;EACE;IACI,wBAAA;EADN;EAKE;IACE,6BAAA;EAHJ;AACF;AAME;EACE,+CAAA;AAJJ;;AAME;EACE,iDAAA;AAHJ;;AAME;EACE,qBAAA;AAHJ;;AAKE;EACE,kBAAA;AAFJ;;AAKE;EACE,YAAA;EACA,kBAAA;EACA,UAAA;AAFJ;;AAIE;EAEE,gBAAA;AAFJ;;AAKE;EACE,gBAAA;EAEA,eAAA;AAHJ;;AAKE;EACE,qBAAA;EACA,mCAAA;AAFJ;;AAIE;EACE,0BAAA,EAAA,gCAAA;AADJ","sourcesContent":["@keyframes translateY {\n    0% {\n        transform: translateY(0);\n    }\n\n   \n    100% {\n      transform: translateY(-200px);\n    }\n  }\n  @keyframes translateY-2 {\n    0% {\n        transform: translateY(0);\n    }\n \n   \n    100% {\n      transform: translateY(-200px);\n    }\n  }\n  \n  .animate-translate {\n    animation: translateY 0.50s ease-in-out forwards;\n  }\n  .animate-translate-2 {\n    animation: translateY-2 0.50s ease-in-out forwards;\n  }\n  \n  .animate-translate-delay-1 {\n    animation-delay: 0.1s;\n  }\n  .banner-search-box {\n    position: relative;\n  }\n  \n  .search-suggestion {\n    z-index: 100;\n    position: absolute;\n    top: 368px;\n  }\n  .searchz\n  {\n    z-index: 1000000;\n  }\n\n  .arw-button-college {\n    background: none;\n   \n    cursor: pointer;\n  }\n  .arrow-icon {\n    display: inline-block;\n    transition: transform 0.3s ease-out;\n  }\n  .arw-button-college:hover .arrow-icon {\n    transform: translateX(6px); /* Adjust distance of movement */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
