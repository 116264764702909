import React, { useState } from 'react';
import { IoIosCloudDone } from "react-icons/io";
import freelancer1 from '../Images/freelancer1.jpg';
import freelancer2 from '../Images/freelancer2.jpg';
import freelancer3 from '../Images/freelancer3.png';
import freelancer4 from '../Images/freelancer4.jpg';
import freelancer5 from '../Images/freelancer5.jpg';
import freelancer6 from '../Images/freelancer6.jpg';
import "swiper/css";
import "swiper/css/bundle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import animate from "animejs";
interface Expert {
    id: number;
    name: string;
    title: string;
    role: string;
    profile_pic: string;
    previous_company: string;
    worked_company_logo: string;
    expertIn: string;
}
const experts: Expert[] = [
    {
        id: 1,
        name: "Matthew Warkentin",
        title: "in Engineering",
        role: "Python Developer",
        profile_pic: freelancer1,
        previous_company: "Cornell University",
        worked_company_logo: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
        expertIn: "Machine Learning"
    },
    {
        id: 2,
        name: "Casey Arrington",
        title: "in Product Management",
        role: "Product Manager",
        profile_pic: freelancer2,
        previous_company: "SpaceX",
        worked_company_logo: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg",
        expertIn: "Market Research"
    },
    {
        id: 3,
        name: "Anna D. Lukasiak",
        title: "in Project Management",
        role: "Project Manager",
        profile_pic: freelancer3,
        previous_company: "Goldman Sachs",
        worked_company_logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1280px-Amazon_logo.svg.png",
        expertIn: "Stakeholder Engagement"
    },
    {
        id: 4,
        name: "Emily Dubow",
        title: "in Design",
        role: "UX/UI Designer",
        profile_pic: freelancer4,
        previous_company: "Samsung",
        worked_company_logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1280px-Microsoft_logo.svg.png",
        expertIn: "Prototyping"
    },
    {
        id: 5,
        name: "Jonah Elbaz",
        title: "in Engineering",
        role: "JavaScript Developer",
        profile_pic: freelancer5,
        previous_company: "Facebook",
        worked_company_logo: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
        expertIn: "Node.js"
    },
    {
        id: 6,
        name: "Adan Perez",
        title: "in Product Management",
        role: "Product Owner",
        profile_pic: freelancer6,
        previous_company: "AT&T",
        worked_company_logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1280px-Netflix_2015_logo.svg.png",
        expertIn: "Requirement Analysis"
    }
];
const MentorHeroBanner: React.FC = () => {
    const [selectedExpert, setSelectedExpert] = useState<Expert>(experts[0]);
    const handleExpertClick = (expert: Expert) => {
        setSelectedExpert(expert);
        let timeline = animate.timeline({ autoplay: true });
        var anim = {
            targets: ".translate",
            translateX: ["0px", "100px"],
            duration: 800,
            easing: "easeOutElastic(1, .8)",
            loop: false
        };
        var anim_fadeIn = {
            targets: ".fadeIn",
            opacity: ["0%", "100%"],
            duration: 800,
            easing: "linear",
            loop: false
        };
        var anim_svg = {
            targets: ".polymorph",
            points: [
                {
                    value: [
                        "70 24 119.574 60.369 100.145 117.631 50.855 101.631 3.426 54.369",
                        "70 41 118.574 59.369 111.145 132.631 60.855 84.631 20.426 60.369"
                    ]
                },
                {
                    value:
                        "70 6 119.574 60.369 100.145 117.631 39.855 117.631 55.426 68.369"
                },
                {
                    value:
                        "70 57 136.574 54.369 89.145 100.631 28.855 132.631 38.426 64.369"
                },
                {
                    value:
                        "70 24 119.574 60.369 100.145 117.631 50.855 101.631 3.426 54.369"
                }
            ],
            easing: "easeOutQuad",
            duration: 800,
            loop: true
        };
        timeline.add(anim);
        timeline.add(anim_fadeIn, "-=800");
        timeline.add(anim_svg, "-=800");
        timeline.play();
    };
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 4,
        initialSlide: 0,
        loop: true,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container">
            {selectedExpert && (
                <div className="expert_details  flex gap-5  px-28  mt-4">
                    <div style={{ width: '35%' }}>
                        <div className='mentorbanner_heading'>Hire the <span className='heading_underline'>Top 3%</span> of Freelance Talent</div>
                        <div className='my-5'>
                            Toptal is an exclusive network of the top freelance software developers, designers, marketing experts, finance experts, product managers, and project managers in the world. Top companies hire Toptal freelancers for their most important projects.
                        </div>
                        <div className='my-5'>
                            <div className="w-52 flex gap-2 justify-center px-9 py-3.5 my-auto text-base font-semibold bg-white blue-color button-styles rounded-[50px] text-zinc-800 max-md:px-5">
                                <button>Hire Top Talent</button>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div style={{ width: '75%' }} className='flex items-center'>
                        <div>
                            <img className='expert_details' src={selectedExpert.profile_pic} style={{ width: '35vw', height: '50vh' }} />
                        </div>
                        <div className='fade_content'>
                            <div>
                                <div
                                    id="story"
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <div
                                        className="translate"
                                        id="title"
                                    >
                                        <div className="folded_corner">
                                            <div>
                                                <div className="font-bold text-xl mb-2">{selectedExpert.name}</div>
                                                <div className='flex gap-2'>
                                                    <div className='mt-1'>
                                                        <IoIosCloudDone className='verified_text' />
                                                    </div>
                                                    <div>
                                                        <p className='verified_text'>Verified Expert</p>
                                                        <p className="text-gray-700 text-base verified_text" style={{ fontWeight: 'normal' }}>{selectedExpert.title}</p>
                                                    </div>
                                                </div>
                                                <p className="text-gray-700 text-base verified_text1" style={{color:'#fff'}}>
                                                    {selectedExpert.expertIn}
                                                </p>
                                                <div>
                                                    <p className="text-gray-700 text-base" style={{color:'#fff'}}>Previously at </p>
                                                    <img src={selectedExpert.worked_company_logo} className='w-24 h-8' alt="Company logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='container'>
                <Slider {...settings}>
                    {experts.map((expert) => (
                        <div
                            className={`max-w-xs w-full rounded overflow-hidden shadow-lg m-4 h-100 flex flex-col justify-between cursor-pointer border-2 ${selectedExpert.id === expert.id ? 'border-blue-500' : 'border-transparent'}`}
                            onClick={() => handleExpertClick(expert)}
                        >
                            <div className="flex justify-center">
                                <img
                                    src={expert.profile_pic}
                                    alt={expert.name}
                                    className="w-full h-52"
                                />
                            </div>
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{expert.name}</div>
                                <div className='flex gap-2'>
                                    <div className='mt-1'>
                                        <IoIosCloudDone className='verified_text' />
                                    </div>
                                    <div>
                                        <p className='verified_text'>Verified Expert</p>
                                        <p className="text-gray-700 text-base verified_text" style={{ fontWeight: 'normal' }}>{expert.title}</p>
                                    </div>
                                </div>
                                <p className="text-gray-700 text-base verified_text1">
                                    {expert.expertIn}
                                </p>
                                <div>
                                    <p className="text-gray-700 text-base">Previously at </p>
                                    <img src={expert.worked_company_logo} className='w-24 h-8' alt="Company logo" />
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* </div> */}
                </Slider>
            </div>
        </div>
    );
};
export default MentorHeroBanner;
