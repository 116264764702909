import React from "react";
import OverviewSection from "./overview/OverviewSection";
import CollegeHistorySection from "./overview/HistorySection";
import CollegeCareerExcellSection from "./overview/CareerExcellence";
import CollegeUniversityHighlightsSection from "./overview/UniversityHighlights";
import AccredationRankings from "./overview/AccredationRankings";
import MediaReviewsSec from "./overview/NewsMediaReviews";
import CollegeContactUS from "./overview/ContactUs";
import SocialMediaComp from "./overview/SocialMedia";

const OverviewTab = (collegeDetails: any) => {
  return (
    <>
      <OverviewSection collegeDetails={collegeDetails} />
      <CollegeHistorySection
        collegeDetails={collegeDetails.collegeDetails.collegeDetails.data}
      />
      <CollegeCareerExcellSection />
      <CollegeUniversityHighlightsSection collegeDetails={collegeDetails} />
      <AccredationRankings />
      <MediaReviewsSec />
      <SocialMediaComp />
      <CollegeContactUS />
    </>
  );
};

export default OverviewTab;
