import Filter from "./Filter";

const Explore = () => {
  return (
    <section>
      <Filter />
    </section>
  );
};

export default Explore;
