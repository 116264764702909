// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbox {
  color: #717171;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d9d9e5;
  border-radius: 5px;
  padding: 0.375rem 2.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/searchbox/SearchBox.scss"],"names":[],"mappings":"AAOA;EACE,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,4BAAA;EACA,yBAAA;EACA,kBAAA;EACA,wBAAA;EACA,wEAAA;EACA,UAAA;AANF","sourcesContent":["// .search-box {\n//   position: relative;\n//   .expanded {\n//     position: absolute;\n//     right: 0;\n//   }\n// }\n.searchbox {\n  color: #717171;\n  background-color: #fff;\n  background-image: none;\n  background-clip: padding-box;\n  border: 1px solid #d9d9e5;\n  border-radius: 5px;\n  padding: 0.375rem 2.3rem;\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
