import React from "react";
import CutoffsContent from "./cutoffs/Cutoffs";

const CutoffsTab = ({}) => {
  return (
    <div>
      <CutoffsContent />
    </div>
  );
};

export default CutoffsTab;
