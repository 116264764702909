// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.breadcrumb-item:not(.active)::after {
  content: "|";
  padding: 0 0.5rem;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrumbs.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".breadcrumb {\n  display: flex;\n  flex-wrap: wrap;\n  list-style: none;\n}\n\n.breadcrumb-item:not(.active)::after {\n  content: \"|\";\n  padding: 0 0.5rem;\n}\n\n.breadcrumb-item a {\n  text-decoration: none;\n}\n\n.breadcrumb-item a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
