import React from "react";
import { Link } from "react-router-dom";
type RelatedBlogsProps = {
  blogRelated: {}[];
};
const RelatedBlogs: React.FC<RelatedBlogsProps> = ({ blogRelated }) => {
  console.log("blogRelated", blogRelated);
  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="col-span-1 bg-[#FFE9E9]">
        <h1 className="p-3 text-lg font-semibold text-center">Related Blogs</h1>
        <div className="px-4">
          {blogRelated.map((blog: any) => (
            <Link
              to={`/blogs/${blog?.topic}/${blog?.title}`}
              state={{
                from: "BlogDetail",
                blogDetail: blog,
              }}
              className="flex py-3 border-b-2 border-[#CCCCCC] related-blog last:border-b-0 text-xs md:text-base"
            >
              {blog?.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedBlogs;
