import React, { useState } from "react";
import MeettopMentorsImage from "../../images/courses/meet-top-mentors.png";
import "./MeetTopMentors.scss";
import { Link } from "react-router-dom";
import QuizWidget from "../QuizWidget";
import Modal from "../Modal";

const MeetTopMentorsContent = [
  {
    title: "Meet Top Mentors",
    highlight: "College Mentor",
    Description:
      " helps students pick the best courses and universities globally while also teaching important skills for today’s world. Receive personalized counselling from our College Mentors, who offer tailored guidance to students and parents alike, assisting with courses selection and providing invaluable direction.",
    videoLink: "https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1",
    button1: "Map My Mentors",
    button2: "Map My Course",
  },
];

const MeetTopMentors: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const [quizPopupIsOpen, setQuizPopupIsOpen] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <>
      <div className="bg-[#DFF4FF] flex py-20">
        <div className="container mx-auto">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col lg:flex-row gap-10 lg:gap-16 items-center">
              {MeetTopMentorsContent.map((content) => (
                <>
                  <div className="flex flex-col gap-4 lg:gap-16 w-full lg:w-1/2">
                    <div className="top-mentors flex flex-col gap-3">
                      <h3 className="text-2xl lg:text-[40px] font-bold lg:leading-[58px] text-black uppercase">
                        {" "}
                        {content.title}
                      </h3>
                      <p className="text-lg font-light text-[--paragraph-color]">
                        <strong className="font-bold text-[--primary-color]">
                          {content.highlight}
                        </strong>{" "}
                        {content.Description}
                      </p>
                    </div>
                    <div className="flex gap-4">
                      <Link
                        to={"/mentor"}
                        className="white-color map-mentor arrow-button  button-styles flex flex-row items-center justify-center gap-2"
                      >
                        {content.button1}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="currentColor"
                          className="arrow-icon"
                        >
                          <path
                            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Link>
                      <button
                        className="white-color map-mentor arrow-button  button-styles flex flex-row items-center justify-center gap-2"
                        onClick={() => setQuizPopupIsOpen(true)}
                      >
                        {content.button2}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="currentColor"
                          className="arrow-icon"
                        >
                          <path
                            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center w-full lg:w-1/2 relative">
                    {isPlaying ? (
                      <div
                        className="relative w-full h-0"
                        style={{ paddingBottom: "56.25%" }}
                      >
                        <iframe
                          width="100%"
                          height="100%"
                          src={content.videoLink}
                          title="YouTube Video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="absolute inset-0 w-full h-full rounded-lg"
                        ></iframe>
                      </div>
                    ) : (
                      <>
                        <button
                          className="watch-button"
                          onClick={handlePlayVideo}
                        ></button>
                        <img
                          src={MeettopMentorsImage}
                          alt="Image"
                          className="w-full h-full object-cover rounded-lg cursor-pointer"
                          onClick={handlePlayVideo}
                        />
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MapMyCoursePopup
        isOpen={quizPopupIsOpen}
        onClose={() => {
          setQuizPopupIsOpen(false);
        }}
      />
    </>
  );
};

export default MeetTopMentors;

const MapMyCoursePopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget destinationLink="/course-details" />
    </Modal>
  );
};
