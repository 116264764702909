import React, { useState } from "react";
import overviewImg from "../../../../images/college/details/overview-img.png";

const overViewContent = {
  title: "SRM Overview",
  content:
    "SRM Institute of Science and Technology  is one of the top ranking universities in India with over 52,000 full time students and more than 3200 faculty across all the campuses – Kattankulathur, Ramapuram, Vadapalani Campus – all in and around Chennai, Tiruchirappalli (in TN), Modinagar (in UP) & Sonepat (in Haryana) – both of which are located near Delhi NCR, Amaravati (in AP), Gangtok (in Sikkim) – offering a wide range of undergraduate, postgraduate and doctoral programs in six Faculties – Engineering & Technology, Management, Medicine & Health sciences, Science & Humanities, Law and Agricultural Sciences.",
  image: overviewImg,
};

const OverviewSection = (collegeDetails: any) => {
  const { collegeOverview } =
    collegeDetails.collegeDetails.collegeDetails.collegeDetails.data;

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="tab-content py-16" id="Overview">
        <div className="flex justify-between items-center">
          <div className="container mx-auto">
            <div className="flex flex-row items-center gap-6">
              <div className="w-full lg:w-1/2 ">
                <div className="flex gap-4 flex-col">
                  <h3 className="text-3xl font-bold text-[#231F40]">
                    {collegeOverview.CollegeName}
                  </h3>
                  <p className="text-base text-[#4F4F4F]">
                    {overViewContent.content}
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <img
                  src={overViewContent.image}
                  onClick={handleOpenModal}
                  alt="overview"
                  className="w-full h-full object-cover rounded-lg"
                />
                {isOpen && (
                  <>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                    <div
                      id="modal"
                      className="fixed inset-0 flex items-center justify-center z-50 w-full h-full rounded-lg"
                      onClick={handleCloseModal}
                    >
                      <button
                        className="absolute top-8 right-8"
                        onClick={handleCloseModal}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="white"
                          width={20}
                          height={20}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <div
                        className="modal-content relative m-0  duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
                        style={{
                          animation: "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
                        }}
                      >
                        <iframe
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/T6oKlQoHrIg?si=SRxz8q4ZyXoDqvb1?autoplay=1`}
                          title="YouTube Video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="inset-0 w-full h-full left-0 top-0 rounded-lg"
                        ></iframe>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewSection;
