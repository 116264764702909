import React from "react";

const CareerIntermediate: React.FC = () => {
  return (
    <div>
      <h1>Career Intermediate</h1>
      <p>This is the Career Intermediate component.</p>
    </div>
  );
};

export default CareerIntermediate;
