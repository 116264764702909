import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PlaceholderChip from "./common/PlaceholderChip/PlaceholderChip";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { useForm } from "react-hook-form";
import {
  nextStep,
  updateForm,
  prevStep,
} from "../../../../store/slices/registrationFormSlice";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import OptionGroup from "./common/OptionsCard/OptionGroup";

interface CurrentLevelStudyProps {
  currentLevelOfStudy: string;
}

export const CURRENT_LEVEL_OF_STUDY: string[] = [
  "8-11",
  "12",
  "UG",
  "PG",
  "Others",
];
const cards: string[] = ["Card 1", "Card 2", "Card 3"];
const CurrentLevelStudy = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);

  const { pafData } = useSelector((state: RootState) => state.auth);

  const [selectedCard, setSelectedCard] = useState<any>("");

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    dispatch(updateForm({ currentLevelOfStudy: selectedCard }));
    dispatch(nextStep());
  };

  const handleClick = (message: string): void => {
    console.log("Message from Child: ", message);

    onSubmit(message);
  };

  const handleCardClick = (card: string) => {
    console.log("CurrentLevelStudy - Message from Child: ", card);
    setSelectedCard(card);
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={true}
        stepName="What's your current level of Study ?"
        stepDescription=""
      >
        {/* <PlaceholderChipGroup
          cards={CURRENT_LEVEL_OF_STUDY}
          selectMode={"single"}
          handleCardSelect={handleCardClick}
        /> */}
        {/* <div className="flex flex-row justify-center w-full gap-2 mb-6 md:flex-wrap max-lg:flex-wrap ">
          {CURRENT_LEVEL_OF_STUDY.map((value: any) => {
            return (
              <PlaceholderChip
                value={value}
                handlePlaceholerClick={handleCardClick}
                selectedCard={selectedCard}
              />
            );
          })}
        </div> */}
        <div className="w-full p-4 overflow-auto md:h-21rem">
          {/* Old Implementation */}

          {/* <OptionGroup
            mode={"single"}
            options={CURRENT_LEVEL_OF_STUDY}
            handleCardSelect={handleCardClick}
            cardSize={"36"}
          /> */}

          <OptionGroup
            options={pafData?.currentLevelOfStudy}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            handleCardSelect={handleCardClick}
            cardSize={"36"}
          />
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default CurrentLevelStudy;
