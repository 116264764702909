// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-featured {
  color: var(--Black, #000);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/landing/components/FeaturedPredictors.scss"],"names":[],"mappings":"AACA;EACA,yBAAA;EAEA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADA","sourcesContent":["\n.name-featured{\ncolor: var(--Black, #000);\n\ntext-align: center;\nfont-family: \"Open Sans\";\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 700;\nline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
