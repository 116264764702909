import React from "react";
import foundedReaserchImage from "../../../../../images/college/details/research/founded-research.png";

interface Props {
  title: string;
  description: string;
  innerContent: {
    image: string;
    title: string;
    description: string;
  }[];
}
const foundedResearchContent: Props[] = [
  {
    title: "Funded Research",
    description:
      "SRMIST facilitates research pursuits of our faculty members, scholars and students at scale and it is the impact. There is a dedicated focus on ensuring funding is in place so that research projects and studies progress satisfactorily. Incentives in the form of awards and prizes are given out to those demonstrating excellence in research. Each department of SRMIST has the flexibility, freedom and encouragement to pursue various grants and funding from Government, Industry and Multilateral organizations. It is due to this prevalent culture of research that SRMIST enjoys the credibility as a committed and competent institution. This is affirmed in the scale of research grants that annually exceed 100s of crores of Indian Rupees.",
    innerContent: [
      {
        image: foundedReaserchImage,
        title: "Sponsored Research Projects Sanctioned - 2007 Onwards",
        description:
          "SRMIST invests in creating an ambience that makes it convenient for our researchers to engage in high quality research. The large multidisciplinary campus as well as increasing focus these days on inter-disciplinary research has long back prompted us at SRMIST to focus on creating platforms where scholars and students from diverse study areas and specializations come together to work on finding common solution through research and innovation.",
      },
    ],
  },
];

const FundedResearch: React.FC<Props> = () => {
  return (
    <div className="flex flex-col py-16">
      <div className="container mx-auto">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col justify-center items-center gap-4">
            {foundedResearchContent.map((content, index) => (
              <div
                key={index}
                className="flex flex-col gap-5 justify-center items-center"
              >
                <h3 className="text-3xl font-bold text-[#231F40]">
                  {content.title}
                </h3>
                <p className="text-lg text-[#231F40]">{content.description}</p>
              </div>
            ))}
          </div>
          <div className="flex lg:flex-row-reverse justify-center items-center lg:gap-20 p-10 bg-gray-200">
            <div className="w-full lg:w-1/3">
              <div className="flex justify-center items-center">
                <img
                  src={foundedResearchContent[0].innerContent[0].image}
                  alt="founded research"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
            <div className="w-full lg:w-2/3">
              <div className="flex flex-col gap-5">
                {foundedResearchContent[0].innerContent.map(
                  (innerContent, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-5 justify-center"
                    >
                      <h3 className="text-3xl font-bold text-[#231F40]">
                        {innerContent.title}
                      </h3>
                      <p className="text-lg text-[#231F40]">
                        {innerContent.description}
                      </p>
                    </div>
                  )
                )}
                <div className="flex flex-row">
                  <a href="#" className="blue-color button-styles font-bold">
                    Academic Research
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundedResearch;
