import { Weight } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import type { SVGProps } from "react";
import UKImg from "../../images/study-abroad/Country/uk-img.png";
import USAImg from "../../images/study-abroad/Country/usa-img.png";
import CanadaImg from "../../images/study-abroad/Country/canada-img.png";
import AustraliaImg from "../../images/study-abroad/Country/australia-img.png";
import FranceImg from "../../images/study-abroad/Country/france-img.png";
import GermanyImg from "../../images/study-abroad/Country/germany-img.png";
import IrelandImg from "../../images/study-abroad/Country/ireland-img.png";
import ItalyImg from "../../images/study-abroad/Country/italy-img.png";
import NewZealandImg from "../../images/study-abroad/Country/new-zealand-img.png";
import DubaiImg from "../../images/study-abroad/Country/dubai-img.png";
import SingaporeImg from "../../images/study-abroad/Country/singapore-img.png";
import SwedenImg from "../../images/study-abroad/Country/sweden-img.png";
import { Link } from "react-router-dom";
import { EpRight } from "./AboutEducationMentorsGlobally";

export function UiwRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m7.053 2.158l7.243 7.256a.66.66 0 0 1 .204.483a.7.7 0 0 1-.204.497q-3.93 3.834-7.575 7.401c-.125.117-.625.408-1.011-.024c-.386-.433-.152-.81 0-.966l7.068-6.908l-6.747-6.759q-.369-.509.06-.939q.43-.43.962-.04"
      ></path>
    </svg>
  );
}

const countries = [
  {
    id: 1,
    name: "USA",
    flag: "🇺🇸",
    bgColor: "#383572",
    imgUrl: USAImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Top-Ranked Universities</span>: Access over 2,000+ US universities consistently ranked among the world’s best for top-tier education and resources.",
        "<span class='font-semibold text-green'>Flexible Curriculum</span>: Enjoy a flexible academic system with a wide range of subjects and interdisciplinary options.",
        "<span class='font-semibold text-green'>Generous Scholarships</span>: Receive up to 25% in scholarships and financial aid to make high-quality education more affordable.",
        "<span class='font-semibold text-green'>Extended Post-Study Work Permit</span>: Benefit from up to 3 years of post-study work authorization to gain valuable experience in the US.",
        "<span class='font-semibold text-green'>High Earning Potential</span>: Graduates from US institutions often start with competitive salaries, averaging around $72,000 annually.",
        "<span class='font-semibold text-green'>Diverse Campus Experience</span>: Immerse yourself in vibrant campus life with students from around the world, offering rich cultural exchange.",
      ],
      button: "Connect with a USA Expert Mentor",
    },
  },
  {
    id: 2,
    name: "UK",
    flag: "🇬🇧",
    bgColor: "#012169",
    imgUrl: UKImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Top Global Universities</span>: Study at one of the 50 UK universities ranked among the top 150 worldwide for prestigious education.",
        "<span class='font-semibold text-green'>Affordable Tuition Fees</span>: Leading UK universities offer competitive tuition fees starting at approximately ₹12 lakhs.",
        "<span class='font-semibold text-green'>Substantial Scholarships</span>: Scholarships of ₹3 to ₹5 lakhs are available for Indian students to make education more accessible.",
        "<span class='font-semibold text-green'>No English Test Required</span>: Many UK universities allow study without English proficiency test scores.",
        "<span class='font-semibold text-green'>Post-Study Work Visa</span>: Stay and work in the UK for up to 2 years after graduation (3 years for PhD graduates) with the Graduate Route.",
        "<span class='font-semibold text-green'>Rich Cultural and Academic Environment</span>: Experience the UK’s diverse cultural and academic landscape with opportunities for personal and professional growth.",
      ],
      button: "Connect with a UK Expert Mentor",
    },
  },
  {
    id: 3,
    name: "Canada",
    flag: "🇨🇦",
    bgColor: "#FF1322",
    imgUrl: CanadaImg,
    content: {
      points: [
        "<span class='font-semibold text-white'>Top Global Universities</span>: Study at Canadian universities ranked among the top 2% globally, offering prestigious education and world-class resources.",
        "<span class='font-semibold text-white'>Extended Post-Study Work Permit</span>: Benefit from a 3-year post-study work permit for Master’s graduates, allowing ample time to gain valuable work experience.",
        "<span class='font-semibold text-white'>Dependents Allowed</span>: Bring dependents who can work full-time, making it easier to manage family life while studying.",
        "<span class='font-semibold text-white'>Competitive Tuition Fees</span>: Enjoy tuition fees starting at approximately ₹15 lakhs, offering high-quality education at a reasonable cost.",
        "<span class='font-semibold text-white'>High Employability Rate</span>: Benefit from a strong job market with a 95% employability rate for graduates, enhancing your career prospects.",
      ],
      button: "Connect with a Canada Expert Mentor",
    },
  },
  {
    id: 4,
    name: "Australia",
    flag: "🇦🇺",
    bgColor: "#002395",
    imgUrl: AustraliaImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Top International Destination</span>: Australia is the third most popular choice for international students, known for its high-quality education and vibrant student life.",
        "<span class='font-semibold text-green'>Wide Range of Programs</span>: Choose from over 40 universities offering more than 22,000 courses across diverse fields of study.",
        "<span class='font-semibold text-green'>High Employability</span>: Australia is ranked among the top 100 globally for graduate employability, enhancing your career prospects.",
        "<span class='font-semibold text-green'>Extended Post-Study Work Permit</span>: Enjoy post-study work opportunities starting from 3 years, depending on the region and your course of study.",
        "<span class='font-semibold text-green'>Dependents Allowed</span>: Bring dependents who can live and work in Australia, making it easier to manage family life while studying.",
      ],
      button: "Connect with an Australia Expert Mentor",
    },
  },
  {
    id: 5,
    name: "France",
    flag: "🇫🇷",
    bgColor: "#002395",
    imgUrl: FranceImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Top European Destination</span>: France is the #1 choice in Europe for international students, offering a rich educational and cultural experience.",
        "<span class='font-semibold text-green'>Prestigious Business Schools</span>: Home to 25 top-ranked, triple-accredited business schools, providing world-class business education.",
        "<span class='font-semibold text-green'>Affordable Tuition Fees</span>: Tuition fees start at approximately ₹9 lakhs, making high-quality education accessible.",
        "<span class='font-semibold text-green'>Extended Post-Study Visa</span>: Benefit from a 2-year post-study job search visa exclusively for Indian students, allowing ample time to find employment.",
        "<span class='font-semibold text-green'>Paid Internships</span>: Engage in mandatory paid internships with an average monthly compensation of €1,000, gaining practical experience and financial support.",
      ],
      button: "Connect with a France Expert Mentor",
    },
  },
  {
    id: 6,
    name: "Germany",
    flag: "🇩🇪",
    bgColor: "#000000",
    imgUrl: GermanyImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>World-Class Education</span>: Germany is renowned for its high-quality education and cutting-edge research opportunities across various fields.",
        "<span class='font-semibold text-green'>Leading Global Economy</span>: Study in one of Europe's strongest economies, offering excellent career prospects and industry connections.",
        "<span class='font-semibold text-green'>Extended Post-Study Work Permit</span>: Benefit from an 18-month post-study work visa, allowing ample time to find employment and gain practical experience.",
        "<span class='font-semibold text-green'>English-Taught Programs</span>: Access a wide range of programs taught in English, making it easier for international students to pursue their studies.",
        "<span class='font-semibold text-green'>Flexible Work Opportunities</span>: Work part-time for 20 to 40 hours per week while studying, providing financial support and valuable work experience.",
      ],
      button: "Connect with a Germany Expert Mentor",
    },
  },
  {
    id: 7,
    name: "Ireland",
    flag: "🇮🇪",
    bgColor: "#FF883E",
    imgUrl: IrelandImg,
    content: {
      points: [
        "<span class='font-semibold'>Top-Ranked Universities</span>: Study at Irish universities ranked among the top 3% worldwide, ensuring high-quality education and global recognition.",
        "<span class='font-semibold'>Affordable Tuition Fees</span>: Tuition fees start from approximately ₹9 lakhs, offering competitive rates for excellent education.",
        "<span class='font-semibold'>Extended Post-Study Work Permit</span>: Enjoy a 2-year post-study work permit for Master’s graduates, providing ample time to gain valuable work experience.",
        "<span class='font-semibold'>Generous Scholarships</span>: Access scholarships offering up to 50% tuition fee waivers, making education more accessible and affordable.",
        "<span class='font-semibold'>Simplified Admission Criteria</span>: Benefit from straightforward eligibility requirements at leading institutions, streamlining your path to higher education.",
      ],
      button: "Connect with an Ireland Expert Mentor",
    },
  },
  {
    id: 8,
    name: "Italy",
    flag: "🇮🇹",
    bgColor: "#088C53",
    imgUrl: ItalyImg,
    content: {
      points: [
        "<span class='font-semibold'>Top-Ranked Universities</span>: Study at one of 16 Italian universities ranked in the top 300 QS World University Rankings, ensuring high-quality education.",
        "<span class='font-semibold'>Strong Career Prospects</span>: Benefit from a favorable career outlook in both European and global job markets, with strong industry connections.",
        "<span class='font-semibold'>Part-Time Work Opportunities</span>: Work part-time for up to 20 hours per week during your studies, providing financial support and work experience.",
        "<span class='font-semibold'>Affordable Tuition Fees</span>: Enjoy competitive tuition fees starting at approximately ₹6 lakhs, making quality education more accessible.",
        "<span class='font-semibold'>Scholarships for Indian Students</span>: Access various scholarships specifically available for Indian students to help reduce education costs.",
      ],
      button: "Connect with an Italy Expert Mentor",
    },
  },
  {
    id: 9,
    name: "New Zealand",
    flag: "🇳🇿",
    bgColor: "#252C61",
    imgUrl: NewZealandImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Top-Ranked Universities</span>: All New Zealand universities are listed in the top 250 QS World University Rankings, ensuring high-quality education.",
        "<span class='font-semibold text-green'>Extended Post-Study Work Permit</span>: Enjoy up to three years of post-study work rights, providing ample time to gain valuable work experience.",
        "<span class='font-semibold text-green'>Dependents Allowed</span>: Bring dependents on your student visa, allowing them to live and work in New Zealand while you study.",
        "<span class='font-semibold text-green'>Competitive Tuition Fees</span>: Tuition fees start at approximately ₹17 lakhs per year, offering a cost-effective education in a beautiful country.",
        "<span class='font-semibold text-green'>Free University Applications</span>: Apply to all New Zealand universities without application fees, simplifying the admissions process.",
      ],
      button: "Connect with a New Zealand Expert Mentor",
    },
  },
  {
    id: 10,
    name: "Dubai",
    flag: "🇦🇪",
    bgColor: "#005C2E",
    imgUrl: DubaiImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Global University Campuses</span>: Access a wide range of international universities with campuses in Dubai, offering diverse and globally recognized programs.",
        "<span class='font-semibold text-green'>No IELTS Required</span>: Apply to universities in Dubai without the need for IELTS scores, simplifying the admission process.",
        "<span class='font-semibold text-green'>Affordable Tuition Fees</span>: Enjoy competitive tuition fees starting at approximately ₹8 lakhs, making quality education more accessible.",
        "<span class='font-semibold text-green'>Work Opportunities</span>: Gain valuable work experience in multinational companies based in Dubai, enhancing your career prospects.",
        "<span class='font-semibold text-green'>Diverse Diploma Options</span>: Pursue diplomas across various sectors, catering to a broad range of academic and professional interests.",
      ],
      button: "Connect with a Dubai Expert Mentor",
    },
  },
  {
    id: 11,
    name: "Singapore",
    flag: "🇸🇬",
    bgColor: "#FF2239",
    imgUrl: SingaporeImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Educational Hub of Asia</span>: Singapore is a premier educational hub in Asia, offering world-class academic programs and research opportunities.",
        "<span class='font-semibold text-green'>Top-Ranked Universities</span>: Eight out of nine public universities in Singapore are ranked among the top 200 globally, ensuring high-quality education.",
        "<span class='font-semibold text-green'>No IELTS Required</span>: Many universities offer admission without IELTS scores, simplifying the application process for international students.",
        "<span class='font-semibold text-green'>Competitive Tuition Fees</span>: Enjoy affordable course fees starting at approximately ₹9 lakhs, making quality education more accessible.",
        "<span class='font-semibold text-green'>UK-Equivalent Degrees</span>: Benefit from degree programs that follow a similar structure to those in the UK, providing a globally recognized education.",
      ],
      button: "Connect with a Singapore Expert Mentor",
    },
  },
  {
    id: 12,
    name: "Sweden",
    flag: "🇸🇪",
    bgColor: "#006AA7",
    imgUrl: SwedenImg,
    content: {
      points: [
        "<span class='font-semibold text-green'>Renowned Education System</span>: Experience Sweden's internationally acclaimed education system, known for its high quality and innovative approach.",
        "<span class='font-semibold text-green'>Tuition Fee Discounts</span>: Benefit from tuition fee discounts ranging from 20% to 50%, making high-quality education more affordable.",
        "<span class='font-semibold text-green'>Home to Leading Companies</span>: Study in a country home to major global companies like Ericsson, Volvo, Electrolux, Scania, and Ikea, offering excellent networking and career opportunities.",
        "<span class='font-semibold text-green'>Innovative Environment</span>: Learn in a hub of innovation known for pioneering technologies such as the computer mouse, Bluetooth, Skype, and Spotify.",
        "<span class='font-semibold text-green'>Dependents Allowed</span>: Bring dependents with you, who can live and work in Sweden while you study, making family life more manageable.",
      ],
      button: "Connect with a Sweden Expert Mentor",
    },
  },
];

const ChooseYourDreamCountry: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef<HTMLDivElement>(null);

  const scrollTabs = (direction: "left" | "right") => {
    if (tabsRef.current) {
      const scrollAmount = 150;
      const currentScroll = tabsRef.current.scrollLeft;
      if (direction === "left") {
        tabsRef.current.scrollTo({
          left: currentScroll - scrollAmount,
          behavior: "smooth",
        });
      } else {
        tabsRef.current.scrollTo({
          left: currentScroll + scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <section className="bg-[#061958] py-2">
      <div className="flex flex-col gap-7">
        <div className="container mx-auto">
          <h3 className="text-4xl lg:text-5xl text-white font-thin">
            Choose Your Dream Country
          </h3>
        </div>

        <div className="container mx-auto">
          <div className="flex justify-center items-center gap-3.5">
            <div className="flex items-center">
              <button
                className="w-9 h-9 bg-[#D9D9D9] hover:bg-white rounded-full rotate-180 flex items-center justify-center"
                onClick={() => scrollTabs("left")}
              >
                <UiwRight />
              </button>
            </div>

            <div
              ref={tabsRef}
              className="flex overflow-x-auto gap-3.5 hide-scrollbar"
            >
              {countries.map((country, index) => (
                <button
                  key={country.id}
                  className={`py-2.5 px-7 flex items-center border-2 rounded-md text-lg md:text-2xl leading-7 gap-3 whitespace-nowrap text-white ${
                    activeTab === index
                      ? "bg-green border-white"
                      : "bg-green border-transparent"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  <span>{country.flag}</span>
                  <span>{country.name}</span>
                </button>
              ))}
            </div>
            <div className="flex items-center">
              <button
                className="w-9 h-9 bg-[#D9D9D9] hover:bg-white rounded-full flex items-center justify-center"
                onClick={() => scrollTabs("right")}
              >
                <UiwRight />
              </button>
            </div>
          </div>
        </div>

        <div
          className={`p-4`}
          style={{ backgroundColor: countries[activeTab].bgColor }}
        >
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row gap-4 items-center">
              <div className="w-full lg:w-7/12">
                <div className="flex flex-col gap-6 md:py-6">
                  <div className="flex flex-col  gap-3">
                    <h4 className="text-2xl font-light text-white">
                      Study in{" "}
                      <strong className="font-bold">
                        {countries[activeTab].name}
                      </strong>
                    </h4>
                    <ul className="list-none p-0 m-0">
                      {countries[activeTab].content.points.map((point, idx) => (
                        <li key={idx} className="text-white">
                          <span
                            dangerouslySetInnerHTML={{ __html: point }}
                            className="text-base text-white leading-7"
                          ></span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex items-center gap-3">
                    <Link
                      to=""
                      className="hover:bg-blue text-white py-3.5 px-3 sm:px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-base font-semibold"
                    >
                      {countries[activeTab].content.button} <EpRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 flex items-end ">
                <div className="flex flex-col gap-2.5">
                  <div className="w-full h-full">
                    <img
                      src={countries[activeTab].imgUrl}
                      alt={countries[activeTab].name}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseYourDreamCountry;
