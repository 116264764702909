import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Banner from './components/Banner';
import FeaturedPredictors from './components/FeaturedPredictors';
import PredictorTools from './components/PredictorTools';
import KnowledgeHub from './components/KnowledgeHub';
import { update } from '../../../store/slices/predictorLanding'; // Correct import path
import News from './components/News';
import FeaturedColleges from "../../colleges-new/landing/components/FeaturedColleges"
import { useSelector } from "react-redux";
import { RootState } from '../../../store/store';
import EntranceExams from './components/EntranceExams';
import TestimonialPredictor from './components/TestimonialPredictor';
import Blogs from "./components/Blogs";
import Footer from "./components/Footer"
const CollegePredictorLanding: React.FC = () => {
  const dispatch = useDispatch();
  const applyToDirectAdmissions = useSelector (
    (state: RootState) => (state.predictorLanding.directAdmissions )
  );
  useEffect(() => {
    // Fetch data from API
    axios({
      url: 'https://mentormenteedev.com/api/college/predictorLandingPage',
      method: 'GET',
    })
      .then((response) => {
        dispatch(update(response.data.data));
      })
      .catch((error) => {
        console.error('Failed to fetch data', error);
      });
  }, [dispatch]);
  
  return (
    <div className="font-inter">
      <Banner />
      <FeaturedPredictors />
      <PredictorTools />
      
      <FeaturedColleges title="Apply To Direct Admissions" featuredColleges={applyToDirectAdmissions}/>
      <News/>
      <EntranceExams/>
      <TestimonialPredictor/>
      <Blogs/>
      <KnowledgeHub />
      <Footer/>
    </div>
  );
}

export default CollegePredictorLanding;
