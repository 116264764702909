import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import "swiper/swiper-bundle.css";
import "./EntranceExams.scss";

  
  
interface Cards {
  examName: string;
  iconUrl: string;
  collegesParticipating: string;
  examDate: string;
  examLevel: string;
}

const ExamCard: React.FC<Cards> = ({
  examName,
  iconUrl,
  collegesParticipating,
  examDate,
  examLevel
}) => (
  <div className="flex flex-col pt-4  bg-white rounded-xl w-full md:w-[360px] lg:w-[400px] pb-4 ">
    <div className="flex flex-row gap-2 pt-3 pl-3 pb-5">
      <img
        loading="lazy"
        src={iconUrl}
        className="shrink-0 max-w-full rounded-md aspect-[1.82] w-[50px] h-[50px] pr-2"
        alt={examName}
      />
      <div className="examName">{examName}</div>
      </div>
      <div className="names flex flex-row gap-4 px-4 pb-2">
        <div className="flex flex-col border-r border-[#7C7C7C] pr-2"><div>College participating</div><div>{collegesParticipating}</div></div>
        <div className="flex flex-col border-r border-[#7C7C7C] pr-2"><div >Exam Date</div><div className="text-[#173CBA]">{examDate}</div></div>
        <div className="flex flex-col "><div >Exam Level</div><div className="text-[#173CBA]">{examLevel}</div></div>
      </div>
      <div className=" mx-4 border border-[#7C7C7C] opacity-50 "></div>
      <div className="names flex flex-row gap-4 px-4 pt-2">
        <a href="/exam-details">
        <div className="border-r border-[#7C7C7C] pr-2">Exam Info</div></a>
        <a href="/exam-details"><div className="border-r border-[#7C7C7C] pr-2">Cutoff</div></a>
        <a href="/exam-details"><div className="border-r border-[#7C7C7C] pr-2">Practice Test</div></a>
        
        <a href="/college-predictor-intermediate">
        <div className="">Predict Now</div></a>
</div>
    </div>
);
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};
const EntranceExams: React.FC = () => {
  const entranceExamsData = useSelector((state: RootState) => state.predictorLanding.entranceExams);
  const windowWidth = useWindowWidth();

  if (!entranceExamsData || entranceExamsData.length === 0) return null;

  const isNarrowScreen = windowWidth < 768;

  const groupIntoPairsOrTriples = (cards: any[], isNarrowScreen: boolean): any[][] => {
    const result: any[][] = [];
    const groupSize = isNarrowScreen ? 3 : 2;

    for (let i = 0; i < cards.length; i += groupSize) {
      result.push(cards.slice(i, i + groupSize));
    }

    return result;
  };

  const pairedData = groupIntoPairsOrTriples(entranceExamsData, isNarrowScreen);

  const slidesContent = pairedData.map((pair, index) => (
    <div key={index} className="flex flex-col gap-8">
      {pair.map((card:any, subIndex:any) => (
        <ExamCard key={subIndex} {...card} />
      ))}
    </div>
  ));

  return (
    <div className="bg-[#EEFFFF] min-h-[329px] my-7 pb-6">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
      Entrance Exams in India - 2025      </h1>
      <div className="container">
        <CustomSwiper
          className="!px-2"
          items={slidesContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 }, // Show 1 pair per slide on larger screens as well
            1280: { slidesPerView: 3, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default EntranceExams;
