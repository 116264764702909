import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useLocation, useNavigate } from "react-router-dom";
import { matchIsNumeric } from "../../util/FormValidator";

function OTPVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    setEmail(state?.email || "");
    setPhoneNumber(state?.phoneNumber || "");
  }, [state]);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handleResend = (event: any) => {};

  const handleVerify = (event: any) => {
    navigate("/reset-password", { state: { email, phoneNumber } });
  };

  const handleChangeEmail = (event: any) => {
    if (email !== "") {
      navigate("/forget-password", { state: { email } });
    }
    if (phoneNumber !== "") {
      navigate("/forgot-email", { state: { phoneNumber } });
    }
  };

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value);
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      {/* <CssBaseline /> */}
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
            <LockOutlined />
          </Avatar> */}
        <Typography variant="h5">OTP Verification</Typography>
        <Box>
          {email === "" ? (
            <></>
          ) : (
            <>Enter the code from the Email we sent to {email} </>
          )}
          {phoneNumber === "" ? (
            <></>
          ) : (
            <>Enter the code from the Mobile we sent to {phoneNumber} </>
          )}
          <span
            onClick={handleChangeEmail}
            style={{ color: "#4674FF", textDecoration: "underline" }}
          >
            Change
          </span>
        </Box>
        <Box sx={{ justifyContent: "center" }}>
          {" "}
          {seconds > 0 || minutes > 0 ? (
            <Box sx={{ mt: 5 }}>
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box sx={{ mt: 5 }}>
          <MuiOtpInput
            length={6}
            value={otp}
            onChange={handleChange}
            validateChar={validateChar}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleResend}
            disabled={minutes > 0 && seconds > 0}
          >
            Resend
          </Button>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            onClick={handleVerify}
          >
            Verify
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default OTPVerification;
