import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideoCard = ({
  title,
  description,
  image,
  views,
  pushlishDate,
  link,
  openVideoModal,
}: {
  title: string;
  description: string;
  image: string;
  views?: number;
  pushlishDate: string;
  link: string;
  openVideoModal: () => void;
}) => {
  return (
    <div
      key={link}
      className="block p-4 bg-blue-light rounded-xl overflow-hidden cursor-pointer"
      onClick={openVideoModal}
    >
      <div className="relative">
        <img src={image} alt={title} className="w-full rounded-lg" />
        <FontAwesomeIcon
          icon={faPlay}
          size="lg"
          className="absolute text-white opacity-80 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <h4 className="text-base font-semibold text-black line-clamp-2 mt-2">
        {title}
      </h4>
      <p className="text-sm text-grey">
        {views ? (
          <>
            <span>{views}</span> • <span>{pushlishDate}</span>
          </>
        ) : null}{" "}
      </p>
      <p className="text-sm text-grey line-clamp-2 mt-2">{description}</p>
      <a className="flex gap-2 items-center w-fit bg-white px-2 py-1 rounded-md mt-2">
        <FontAwesomeIcon icon={faPlay} size="sm" /> Play
      </a>
    </div>
  );
};

export default VideoCard;
