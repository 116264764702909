// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preference {
  background-color: #173cba;
}

.para-pref {
  color: #fff;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.parasub-pref {
  color: #fff;
  /* H16 - Open Sans */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-pref {
  color: #fff;
  /* H14 - Open Sans */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  border: 1px solid #fff;
  border-radius: 5px;
  margin-right: 10px;
}

.number-in {
  border-radius: 50px;
  border: 1px solid #fff;
}`, "",{"version":3,"sources":["webpack://./src/screens/colleges-new/landing/components/Preference.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AACA;EACE,WAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,WAAA;EAEA,oBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,WAAA;EAEA,oBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AAEF;;AACA;EACE,mBAAA;EACA,sBAAA;AAEF","sourcesContent":[".preference {\n  background-color: #173cba;\n}\n.para-pref {\n  color: #fff;\n  font-family: \"Open Sans\";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n.parasub-pref {\n  color: #fff;\n\n  /* H16 - Open Sans */\n  font-family: \"Open Sans\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n.button-pref {\n  color: #fff;\n\n  /* H14 - Open Sans */\n  font-family: \"Open Sans\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 32px; /* 200% */\n  border: 1px solid #fff;\n  border-radius: 5px;\n  margin-right: 10px;\n}\n\n.number-in {\n  border-radius: 50px;\n  border: 1px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
