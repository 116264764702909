import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import BlogArticleImage1 from "../../../images/courses/blog-images-1.png";
import BlogArticleImage2 from "../../../images/courses/blog-images-2.png";
import BlogArticleImage3 from "../../../images/courses/blog-images-3.png";
import BlogArticleImage4 from "../../../images/courses/blog-images-4.png";
import BlogCard from "../../blogs-and-articles/BlogCard";
interface Article {
  publishDate: string;
  description: string;
  title: string;
  image?: string;
}
const blogsAndArticlesData = [
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage1,
    publishDate: "Jan 24, 2024",
  },
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage2,
    publishDate: "Jan 24, 2024",
  },
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage3,
    publishDate: "Jan 24, 2024",
  },
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage4,
    publishDate: "Jan 24, 2024",
  },
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage3,
    publishDate: "Jan 24, 2024",
  },
  {
    title: "Best LearnPress WordPress Theme Collection for 2023",
    description:
      "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
    image: BlogArticleImage4,
    publishDate: "Jan 24, 2024",
  },
];

const BlogTab: React.FC<{ articles: Article[] }> = ({ articles }) => {
  return (
    <Swiper
      spaceBetween={16}
      className="ExploreMore w-full"
      navigation={{
        prevEl: ".mentor-prev-btn",
        nextEl: ".mentor-next-btn",
      }}
      modules={[Navigation]}
      loop={false}
      breakpoints={{
        640: { slidesPerView: 1, slidesPerGroup: 1 },
        768: { slidesPerView: 2, slidesPerGroup: 2 },
        1024: { slidesPerView: 4, slidesPerGroup: 4 },
      }}
    >
      {articles.map((content, index) => (
        <SwiperSlide key={index}>
          <BlogCard {...content} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BlogTab;
