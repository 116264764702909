import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import StatesGroupButtons from "./StatesGroupButtons";
import { statesList } from "../../constants/signInForm";
import { useLocation, useNavigate } from "react-router-dom";
import { registrationData } from "../../services/apiReq";

function StateInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [states, setStates] = useState([]);
  const [statesCopy, setStatesCopy] = useState([]);
  const [data, setData] = useState<registrationData>(location.state);
  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      studyLocations: states,
    }));
    setStatesCopy(states);
  }, [states]);

  const handleContinue = (event: any) => {
    navigate("/fundInfo", { state: { ...data } });
  };
  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>
        <StatesGroupButtons
          list={statesList}
          title="Select your Dream Career:"
          setSelectedValue={setStates}
          selectedValue={states}
          moreButton={true}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          onClick={handleContinue}
          disabled={statesCopy?.length === 0}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default StateInfo;
