import React, { useState } from "react";

interface TabProps {
  label: string;
  onClick: () => void;
  active: boolean;
  bgColor: string;
  borderRadius: string;
  zIndex: number;
  position: number;
  width: number;
  textAlign: string;
}

const Tab = ({
  label,
  onClick,
  active,
  bgColor,
  borderRadius,
  zIndex,
  position,
  width,
  textAlign,
}: TabProps) => (
  <button
    className={`p-3 text-white rounded-tl-full rounded-bl-full ${
      active ? "bg-[#2196f3] text-white" : ""
    } ${bgColor} ${borderRadius} 
    }`}
    style={{
      zIndex,
      position: "absolute",
      left: `${position * 100}px`,
      top: "-65px",
      width,
      textAlign: "left",
      height: "72px",
    }}
    onClick={onClick}
  >
    {label}
  </button>
);
interface CardProps {
  image: string;
  shortDescription?: string;
  longDescription?: string;
  userName: string;
  time: string;
}
const Card = ({
  image,
  shortDescription,
  longDescription,
  userName,
  time,
}: CardProps) => (
  <div className="flex p-2 border-b  border-[#DAD5D5]  mb-4">
    <div>
      <span className="relative">
        <img src={image} alt="User" className="w-24 h-24  mr-4 " />
        <span className="text-xs bg-[#00B885] text-[#fff] absolute bottom-0 rounded right-4 px-3 py-1">
          New
        </span>
      </span>
    </div>

    <div className="flex-1">
      <p className="text-[#747474] text-xs">
        {shortDescription} : <strong>{longDescription}</strong>
      </p>
      <div className="flex flex-row gap-2 justify-end">
        <a
          href={"https://mentee-mentor-dev.vercel.app/blogdetails-page"}
          className="text-[#113CC0] font-medium text-xs ml-1"
        >
          Read more
        </a>
      </div>
      <div className="text-[#747474] text-[10px] mt-2">
        <p>{userName}</p>

        <div className="flex">
          <span className="mr-1">{time} </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="14"
            height="15"
            viewBox="0 0 30 30"
          >
            <path d="M 23 3 A 4 4 0 0 0 19 7 A 4 4 0 0 0 19.09375 7.8359375 L 10.011719 12.376953 A 4 4 0 0 0 7 11 A 4 4 0 0 0 3 15 A 4 4 0 0 0 7 19 A 4 4 0 0 0 10.013672 17.625 L 19.089844 22.164062 A 4 4 0 0 0 19 23 A 4 4 0 0 0 23 27 A 4 4 0 0 0 27 23 A 4 4 0 0 0 23 19 A 4 4 0 0 0 19.986328 20.375 L 10.910156 15.835938 A 4 4 0 0 0 11 15 A 4 4 0 0 0 10.90625 14.166016 L 19.988281 9.625 A 4 4 0 0 0 23 11 A 4 4 0 0 0 27 7 A 4 4 0 0 0 23 3 z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
);

interface Alert {
  image: string;
  shortDescription: string;
  longDescription: string;
  userName: string;
  time: string;
}

interface TabContentProps {
  alerts: Alert[];
}

const TabContent = ({ alerts }: TabContentProps) => (
  <div>
    {alerts.map((alert, index) => (
      <Card key={index} {...alert} />
    ))}
  </div>
);

const tabs = [
  {
    label: "Exam Alert",
    key: "exam",
    bgColor: "bg-[#173CBA]",
    borderRadius: "rounded-tl-3xl rounded-bl-3xl",
    zIndex: 1,
    position: 0,
    width: 130,
    textAlign: "left",
  },
  {
    label: "Admission Alert",
    key: "admission",
    bgColor: "bg-[#678CFF]",
    borderRadius: "rounded-tl-3xl rounded-bl-3xl",
    zIndex: 2,
    position: 1,
    width: 132,
    textAlign: "left",
  },
  {
    label: "News Alert",
    key: "news",
    bgColor: "bg-[#99BEFF]",
    borderRadius: "rounded-tl-3xl rounded-bl-3xl",
    zIndex: 3,
    position: 2,
    width: 112,
    textAlign: "left",
  },
];

const alertsData = {
  exam: [
    {
      image: "/images/course-details/user01.png",
      shortDescription: "NBEMS",
      longDescription:
        "extends the last date to collect the FMGE pass certificate, check details here",
      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user02.png",
      shortDescription: "AIIMS",
      longDescription:
        "Paramedical 2024 admit card to be out soon, check details about exam date,results here",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user01.png",

      shortDescription: "NBEMS",
      longDescription:
        "extends the last date to collect the FMGE pass certificate, check details here",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user03.png",

      shortDescription: "AP LAWCET",
      longDescription: "2024 results declared; check details here",
      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
  ],
  admission: [
    {
      image: "/images/course-details/user04.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user02.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user03.png",

      shortDescription: "AP LAWCET",
      longDescription: "2024 results declared; check details here",
      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user04.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
  ],
  news: [
    {
      image: "/images/course-details/user02.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user03.png",
      shortDescription: "AP LAWCET",
      longDescription: "2024 results declared; check details here",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user04.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",

      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
    {
      image: "/images/course-details/user02.png",
      shortDescription: "STUDY ABROAD: ",
      longDescription:
        "Internshala partners with ETS India to offer scholarships to TOEFL and GRE aspirants",
      userName: "Mr. Rajashekar",
      time: " June 29, 2024 19:51 IST",
    },
  ],
};

const AlertTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"exam" | "admission" | "news">(
    tabs[0].key as "exam" | "admission" | "news"
  );

  return (
    <div
      className="bg-white mx-auto rounded-tl-[20px] pt-10 rounded-tr-xl mt-8"
      style={{ boxShadow: "0px 0px 15px 0px #00000026" }}
    >
      <div className="flex border-b mb-4 relative">
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            label={tab.label}
            onClick={() =>
              setActiveTab(tab.key as "exam" | "admission" | "news")
            }
            active={activeTab === tab.key}
            bgColor={tab.bgColor}
            borderRadius={tab.borderRadius}
            zIndex={tab.zIndex}
            position={tab.position}
            width={tab.width}
            textAlign={tab.textAlign}
          />
        ))}
      </div>
      <TabContent alerts={alertsData[activeTab]} />
    </div>
  );
};

export default AlertTabs;
