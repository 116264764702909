import React from "react";
import BlogShareFloatingMenu from "../components/BlogShareFloatingMenu";
interface BlogProps {
  blogResponse: any;
}
interface HeaderProps {
  titleInfo: any;
}
const htmlString = `<div>
               <h1 class="bg-red text-[#ffffff]">
          Blogs HTML content will render here.. This is Sample Blog
        </h1>
        <div class="blog-details-page">
          <div class="">
            <div class="">
              <div class="article-body">
                <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                  Understanding Different Learning Styles: How to Cater to
                </h1>

                <p class="mt-7 mb-10 text-xl">
                  Understanding different learning styles is crucial for
                  educators to effectively cater to the diverse needs of
                  students. Every student has a unique way of processing
                  information, and by identifying and accommodating various
                  learning styles, educators can create an inclusive learning
                  environment where every student can thrive.
                </p>
                <div class="mb-10">
                  <img
                    src="https://w7.pngwing.com/pngs/188/520/png-transparent-learning-styles-education-professional-development-student-plan-text-people-public-relations.png"
                    loading="lazy"
                    alt=""
                    class="rounded-2xl w-full"
                  ></img>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Understanding Learning Styles
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    Learning styles refer to the different ways individuals
                    prefer to approach learning tasks. These styles can include
                    visual, auditory, kinesthetic, reading/writing, and
                    multimodal preferences. By understanding these styles,
                    educators can tailor their teaching methods and materials to
                    better suit each student's preferences and strengths.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Visual Learners
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    Visual learners prefer to learn through images, diagrams,
                    and spatial representations. They benefit from visual aids
                    such as charts, graphs, and videos, which help them grasp
                    concepts more effectively.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Auditory Learners
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    Auditory learners learn best through listening and verbal
                    instruction. They excel in discussions, lectures, and audio
                    recordings, where they can process information through sound
                    and speech.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Kinesthetic Learners
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    Kinesthetic learners learn by doing and engaging in hands-on
                    activities. They thrive in interactive lessons, experiments,
                    and physical tasks that allow them to explore and manipulate
                    objects.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Reading/Writing Learners
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    Reading/writing learners prefer to learn through written
                    text and note-taking. They excel in reading assignments,
                    written explanations, and essay-based assessments.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Catering to Every Student
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    To cater to every student, educators should incorporate a
                    variety of teaching methods and materials that address
                    different learning styles. This can include using visual
                    aids, providing audio recordings, incorporating hands-on
                    activities, and offering written explanations. By embracing
                    diversity in learning styles, educators can create a more
                    engaging and effective learning experience for all students.
                  </p>
                  <h1 class="mb-6 mt-6 text-4xl font-extrabold">
                    Wrapping This Up…
                  </h1>

                  <p class="mt-7 mb-10 text-xl">
                    In conclusion, understanding different learning styles is
                    essential for educators to create an inclusive learning
                    environment where every student can succeed. By
                    acknowledging and accommodating diverse learning
                    preferences, educators can ensure that their teaching
                    methods are accessible and effective for all students,
                    regardless of their individual learning styles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`;

const Header: React.FC<HeaderProps> = ({ titleInfo }) => {
  return (
    <div className="flex flex-col gap-2">
      <h1 className="font-bold text-4xl">{titleInfo?.title}</h1>
      <div className=" items-center text-xs xl:text-sm capitalize mr-2 mb-10px">
        <span className="leading-4 xl:leading-18px mr-1">By</span>
        <a className="font-bold hover:underline mr-1 xl:leading-18px leading-2">
          {titleInfo?.author},
        </a>
        <span className="leading-2">Technical Writer</span>
      </div>
      <div className="flex flex-row gap-1">
        <span>27, 2024 </span>
        <span>|</span>
        <span>09:53 AM IST</span>
      </div>
    </div>
  );
};
const Blog: React.FC<BlogProps> = ({ blogResponse }) => {
  console.log("blogResponse", blogResponse);
  return (
    <div>
      <Header titleInfo={blogResponse} />

      <div
        dangerouslySetInnerHTML={{
          // __html: `lorem <b onmouseover=\"alert('mouseover');\">ipsum<\/b>`,
          __html: blogResponse?.content,
          // __html: `<p class="p-4 mt-5">here is a blog test<img src="https://bucket.s3.ap-south-1.amazonaws.com/83a5e290-8a8d-11ea-8466.jpg" class= p-4></p>`,
        }}
      />
      <BlogShareFloatingMenu />
    </div>
  );
};

export default Blog;
