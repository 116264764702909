import React from "react";
import TitleCard from "../../components/TitleCard";
import BlogListItem from "../../components/BlogListItem";
import BlogsBanner from "../../components/BlogsAdBanner";
import BannerImage from "../../../../images/blog-article/blogsAdBanner.png";
import BlogShareFloatingMenu from "../../components/BlogShareFloatingMenu";
import addTopMechanicalEngineering from "../../../../images/courses/top-mechanical-add.svg";
import { Link } from "react-router-dom";
import usePagination from "../../../../components/filter/customHooks/usePagination";
import CustomPagination from "../../../../components/pagination/CustomPagination";
import { captilizeFirstLetter } from "../../../../util/helpers/helperFunctions";
interface BlogsListContainerProps {
  blogsCategory: any;
  blogsList: any;
  paginationProps: any;
}
const BlogsListContainer: React.FC<BlogsListContainerProps> = ({
  blogsCategory,
  blogsList,
  paginationProps,
}) => {
  console.log("paginationProps", paginationProps);
  const handleOnHoverListItem = (e: any) => {
    // console.log("From Parent Comp - handleOnHoverListItem", e);
  };
  return (
    <>
      <h1 className="text-3xl font-semibold">
        {captilizeFirstLetter(blogsCategory) || "Category Name"}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-y-4 md:gap-4">
        <div className="grid col-span-3 gap-4 grid-row">
          <div className="flex flex-col gap-4">
            {blogsList?.results
              ?.slice(0, 3)
              ?.map((blogItem: any, index: any) => (
                <div className="pb-3 border-b-2 border-[#CCCCCC] related-blog last:border-b-0">
                  <BlogListItem
                    parentFunction={handleOnHoverListItem}
                    blogItem={blogItem}
                  />
                </div>
              ))}
          </div>

          <div className="w-full py-4 md:block">
            <BlogsBanner width="100%" height="" image={BannerImage} />
          </div>

          <div className="flex flex-col gap-4">
            {blogsList?.results?.slice(3)?.map((blogItem: any, index: any) => (
              <div className="pb-3 border-b-2 border-[#CCCCCC] related-blog last:border-b-0">
                <BlogListItem
                  parentFunction={handleOnHoverListItem}
                  blogItem={blogItem}
                />
              </div>
            ))}
          </div>
          <CustomPagination
            currentPage={paginationProps?.currentPage}
            totalPages={paginationProps?.totalPages}
            handlePrevPage={paginationProps?.handlePrevPage}
            handleNextPage={paginationProps?.handleNextPage}
            goToPage={paginationProps?.goToPage}
          />
          <div className="visible p-4 bg-gray-100 md:hidden sm:block">
            Add Section
          </div>
          {/* <div className="flex flex-row items-center justify-center py-4">
            <a
              href="courses/list-of--courses-in-india"
              className="white-color map-mentor button-styles arrow-button"
            >
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                className="arrow-icon"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div> */}
        </div>
        <div className="hidden p-4 bg-gray-100 md:block sm:hidden">
          <div className="course-quiz-box  bg-[#C7EAFB] pt-4">
            <div>
              <img
                src="/images/course-details/course-quiz-text.png"
                alt="course quiz text"
                className="mx-auto"
              />
            </div>
            <div>
              <img
                src="/images/course-details/course-quiz.png"
                alt="course quiz"
                className=""
              />
            </div>
            <div>
              <Link
                to={"/courses/quiz-modal"}
                className="w-full h-12 bg-[#F6C] text-white justify-end text-xl font-extrabold"
              >
                Take Our Quiz
              </Link>
            </div>
          </div>
          <div className="chat-with-mentor bg-[#FFEFB5] mt-5">
            <div>
              <img
                src="/images/course-details/logo.png"
                alt="logo"
                className=""
              />
            </div>
            <div className="relative">
              <img src={addTopMechanicalEngineering} alt="logo" className="" />
              {/* <div className="absolute top-[85px] left-[75px] w-[75px] text-center">
                <p className="text-[14px] text-[#263238]">
                  Not Sure University is <br />
                  <strong>Right For You ?</strong>
                </p>
              </div> */}
              <a
                href="/mentor"
                className="mx-auto flex flex-row w-60 font-inter text-white text-xs sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-2"
              >
                <span>Chat with Buddy Mentor</span>
                <img
                  src="/images/rightarrow.svg"
                  className="w-5 h-5 transition-transform duration-300 lg:w-8 lg:h-8 group-hover:translate-x-2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsListContainer;
