// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/authentication/auth-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholderChip {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.auth-bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/features/auth/registration/Registration.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;;AACA;EACE,yDAAA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,4BAAA;EACA,iBAAA;AAEF","sourcesContent":[".placeholderChip {\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n.auth-bg {\n  background-image: url(\"../../../../public/images/authentication/auth-bg.svg\");\n  background-position: bottom;\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-attachment: fixed;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
