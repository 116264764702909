
import MentorCard from './MentorCard';

const mentorsData = [
  {
    id: 1,
    image: "https://via.placeholder.com/200",
    title: "Full Stack Web Development",
    createdBy: "Albert James",
    lessons: "24",
    hours: "40",
    rating: "4.9",
    people: "12k"
  },
  {
    id: 2,
    image: "https://via.placeholder.com/200",
    title: "React Basics",
    createdBy: "Emily Smith",
    lessons: "18",
    hours: "25",
    rating: "4.8",
    people: "8k"
  },
  {
    id: 3,
    image: "https://via.placeholder.com/200",
    title: "Node.js Advanced",
    createdBy: "Michael Johnson",
    lessons: "30",
    hours: "50",
    rating: "4.7",
    people: "10k"
  },
  {
    id: 4,
    image: "https://via.placeholder.com/200",
    title: "Python for Data Science",
    createdBy: "Sarah Lee",
    lessons: "20",
    hours: "35",
    rating: "4.6",
    people: "9k"
  }
];

const Mentors = () => {
  return (
    <div className="flex flex-wrap gap-4 m-4">
      {mentorsData.map((mentor) => (
        <MentorCard
          key={mentor.id}
          image={mentor.image}
          title={mentor.title}
          createdBy={mentor.createdBy}
          lessons={mentor.lessons}
          hours={mentor.hours}
          rating={mentor.rating}
          people={mentor.people}
        />
      ))}
    </div>
  );
};

export default Mentors;
