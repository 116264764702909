// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prepare-exam-title {
  color: #383838;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.prepare-exam-description {
  color: #6b7280;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/PrepareSuccess.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AACA;EACE,cAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEF","sourcesContent":[".prepare-exam-title {\n  color: #383838;\n  font-family: Roboto;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 60px;\n}\n.prepare-exam-description {\n  color: #6b7280;\n  text-align: center;\n  font-family: \"Open Sans\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
