import { useSelector } from "react-redux";
import Table from "./Table";
import { RootState } from "../../../../store/store";
import "./Details.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const isMobile = window.innerWidth < 768;

const Details = () => {
  const data = useSelector(
    (state: RootState) => state.collegePredictorIntermediate.landingData
  );

  const yearsSet = new Set<string>();
  const qualifyingMarkTableDataRaw = data?.previousYearsQualifyingMarks;

  // get all distinct years
  qualifyingMarkTableDataRaw?.data?.forEach(
    (category: { marks: { [year: string]: number } }) => {
      Object.keys(category?.marks).forEach((key) => yearsSet.add(key));
    }
  );

  // sort the distinct years in descending order
  const yearsList: string[] = Array.from(yearsSet).reverse();

  // store column names
  const qualifyingMarkTableData = [["Category", ...yearsList]];

  // store row data
  qualifyingMarkTableDataRaw?.data?.forEach(
    (category: { category: string; marks: { [year: string]: number } }) => {
      qualifyingMarkTableData.push([
        category.category,
        ...yearsList.map((year) => category.marks[year].toString()),
      ]);
    }
  );

  const participatingInstitutesTableDataRaw =
    data?.participatingInstitutes?.data;

  const participatingInstitutesTableData = [
    ["Participating Institutes", "Number of Institutes"],
  ];

  participatingInstitutesTableDataRaw?.forEach(
    (row: {
      "Participating Institutes": string;
      "Number of Institutes": number;
    }) => {
      participatingInstitutesTableData.push([
        row["Participating Institutes"],
        row["Number of Institutes"].toString(),
      ]);
    }
  );

  const topCollegeData = data?.topColleges?.data?.map(
    (college: { collegeName: string }) => [
      <Link to={`/exam-details/${college?.collegeName}`}>
        {college?.collegeName}
      </Link>,
    ]
  );
  return (
    <section className="college-predictor-details py-12 bg-[#FFF1FA] text-black">
      <div className="container">
        <h3 className="text-2xl font-semibold">
          {data?.collegePredictorSteps?.title}
        </h3>
        <div className="description text-grey">
          {parse(data?.collegePredictorSteps?.description || "")}
        </div>
        <h3 className="mt-8 text-2xl font-semibold">{data?.benefits?.title}</h3>
        <div className="description text-grey">
          {parse(data?.benefits?.description || "")}
        </div>
        <h3 className="mt-8 text-2xl font-semibold">
          {data?.previousYearsQualifyingMarks?.title}
        </h3>
        {qualifyingMarkTableDataRaw && (
          <Table
            data={qualifyingMarkTableData}
            justify={isMobile ? "left" : "center"}
            className="mt-6 text-grey w-full"
            colHeader={true}
          />
        )}
        <h3 className="mt-8 text-2xl font-semibold">
          {data?.participatingInstitutes?.title}
        </h3>
        <div className="description text-grey">
          {parse(data?.participatingInstitutes?.description || "")}
        </div>
        {participatingInstitutesTableDataRaw && (
          <Table
            data={participatingInstitutesTableData}
            justify={isMobile ? "left" : "center"}
            className="mt-6 text-grey w-full"
            rowHeader={true}
          />
        )}
        <h3 className="mt-8 text-2xl font-semibold">
          {data?.stateCounselling?.title}
        </h3>
        <div className="description text-grey">
          {parse(data?.stateCounselling?.description || "")}
        </div>
        <h3 className="mt-8 text-2xl font-semibold">
          {data?.topColleges?.title}
        </h3>
        {topCollegeData && (
          <Table
            data={topCollegeData}
            className="mt-6 text-grey w-fit"
            justify="left"
          />
        )}
      </div>
    </section>
  );
};

export default Details;

const TickIcon = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Tick_2 1">
      <g id="Group">
        <path
          id="Vector"
          d="M7.77599 11.272L2.82474 6.10796L4.08274 5.03996L7.70799 8.81996L13.8705 1.68396L15.1837 2.68796L7.77599 11.272Z"
          fill="#00CC99"
        />
      </g>
    </g>
  </svg>
);
