import "./TestimonialCard.scss";

interface IProps {
  imageUrl: string;
  reviewBy: string;
  review: string;
}

const TestimonialCard: React.FC<IProps> = ({ imageUrl, reviewBy, review }) => (
  <div className="flex flex-col pt-4  bg-transparent rounded-xl w-full md:w-[304px] h-[460px] pb-4 ">
    <div className="flex flex-col justify-center relative">
      <img
        className="cardimage absolute w-[163px] h-[163px] rounded-full top-0 left-1/2 -translate-x-1/2 z-100 border-8 border-white"
        src={imageUrl}
      />
      <div className="bg-white w-full rounded-lg relative -translate-y-20">
        <div className="reviews px-4 pt-28">“{review}”</div>
        <div className="reviewBy px-4 py-8">{reviewBy}</div>
      </div>
    </div>
  </div>
);

export default TestimonialCard;
