import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OTPInput, { InputProps } from "react-otp-input";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { RegisterAPI } from "../../../../services/authService";
import {
  sendOtp,
  signupPAFDetail,
  verifyOtp,
} from "../../../../store/thunks/authThunks";
// import { sendOtp } from "../../../../store/slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";

interface PersonalInfoFormInputProps {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
}

const PersonalInfoForm = () => {
  const dispatch = useDispatch<any>();

  const { width } = GetWindowDimensions();

  const { firstName, lastName, email, mobileNumber, otp } = useSelector(
    (state: RootState) => state.registrationFormSlice.data
  );

  const { otpSent, error, message, loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [otpValue, setOtp] = useState(otp);

  const { register, handleSubmit, control, watch, setValue } =
    useForm<PersonalInfoFormInputProps>();

  // const onSubmit: SubmitHandler<PersonalInfoFormInputProps> = (data) => {
  //   console.log("SubmitHandler", data);
  //   dispatch(nextStep());
  // };

  const onSubmit = (data: any) => {
    dispatch(updateForm({ ...data, otp: otpValue }));
    dispatch(nextStep());
  };

  const mobile = watch("mobileNumber");

  const handleVerifyOtp = () => {
    // add logic for otp verify
    if (otpValue) {
      dispatch(verifyOtp({ mobile, otp: otpValue }))
        .unwrap()
        .then(() => {
          dispatch(signupPAFDetail({ mobile }));
        })
        .catch((err: any) => {
          console.error("OTP verification failed:", err);
        });
    }
    setIsVerifyOtp(!isVerifyOtp);
  };

  const handleSendOTP = () => {
    if (mobile?.length == 10) {
      dispatch(sendOtp({ mobile }));
    } else {
      console.log("Enter 10 digit Mobile number");
    }
  };

  // const toastId = useRef<number | string | null>(null);

  // useEffect(() => {
  //   if (message) {
  //     toast.success(message);
  //   }
  // }, [message]);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(error);
  //   }
  // }, [error]);

  return (
    <div className="flex flex-col w-full gap-2 max-3xl">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={false}
        stepName="Personal Information"
        stepDescription="Please provide your personal information like Name, Email, Mobile number"
      >
        <form
          className="flex flex-col w-full max-w-lg gap-2 mb-6 md:h-21rem"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex gap-3 max-sm:flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2 md:mb-0">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-black-700"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border-2 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-first-name"
                type="text"
                placeholder="First name"
                defaultValue={firstName}
                {...register("firstName")}
              />
            </div>
            <div className="w-full md:w-1/2">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 "
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-last-name"
                type="text"
                placeholder="Last name"
                defaultValue={lastName}
                {...register("lastName")}
              />
            </div>
          </div>

          <div className="w-full ">
            <label
              htmlFor="email"
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              // name="email"
              id="email"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
              placeholder="name@company.com"
              {...register("email")}
              defaultValue={email}
            />
            {/* <p className="text-xs italic text-gray-600">
              Make it as long and as crazy as you'd like
            </p> */}
          </div>

          <div className="flex flex-col gap-3 mb-2 md:flex-row xs:flex-col sm:flex md:flex-nowrap">
            <div className="md:w-3/3 sm:w-full">
              <label
                htmlFor="mobileNumber"
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700"
              >
                Mobile Number
              </label>
              <input
                type="number"
                id="mobileNumber"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded focus:outline-none focus:bg-white focus:border-[#113cc0]"
                placeholder="123456789"
                {...register("mobileNumber")}
                defaultValue={mobileNumber}
                onChange={(e) => setValue("mobileNumber", e.target.value)}
              />

              {width < 700 && (
                <p className="flex justify-end text-xs italic text-gray-600">
                  <button
                    className="text-nowrap px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                    onClick={handleSendOTP}
                  >
                    {loading ? <Loader /> : "Send OTP"}
                  </button>
                </p>
              )}
            </div>
            {width > 700 && (
              <div className="flex items-end justify-center md:w-1/3">
                <button
                  className="text-nowrap  font-semibold px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={handleSendOTP}
                >
                  Send OTP
                </button>
              </div>
            )}
          </div>
          {otpSent && !isAuthenticated && (
            <div className="flex justify-center mb-2 sm:justify-normal">
              <OTPInput
                value={otpValue}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={false}
                renderInput={(props) => (
                  <input
                    {...props}
                    // id="otp"
                    className="mr-2"
                    // placeholder=""
                    // {...register("otp")}
                    // defaultValue={otp}
                  />
                )}
                inputStyle={{
                  border: "none",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: width > 700 ? "54px" : "34px",
                  height: width > 700 ? "54px" : "34px",
                  fontSize: "1.5rem",
                  fontWeight: 800,
                  backgroundColor: "#f1f1f1",
                  color: "#000",
                  display: "flex",
                  // caretColor: "blue",
                  outline: "none",
                }}
              />
            </div>
          )}

          {otpSent && !isAuthenticated && (
            <button
              type="button"
              onClick={handleVerifyOtp}
              className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
              style={{ padding: "8px 18px" }}
              // disabled={loading ? true : false}
            >
              Verify OTP
            </button>
          )}
        </form>
      </StepNavigationWrapper>
    </div>
  );
};

export default PersonalInfoForm;
