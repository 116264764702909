import React from "react";

interface TestimonialCardProps {
  name: string;
  university: string;
  imageSrc1: string;
  imageSrc2: string;
}

export const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  university,
  imageSrc1,
  imageSrc2,
}) => {
  return (
    <div className="flex-auto container mx-auto">
      <div className="flex max-md:flex-col justify-between md:flex-row md:w-[1200px]">
        <div className="flex md:gap-5  max-md:gap-4">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={imageSrc1}
              alt={`${name} from ${university}`}
              className="object-contain shrink-0 max-w-full aspect-square w-[194px] max-md:mt-10"
            />
          </div>
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full max-md:my-auto">
            <p className="my-auto text-base leading-7 text-black max-md:mt-10">
              <span className="font-semibold">{name},</span> {university}
            </p>
          </div>
        </div>

        <div className="flex md:gap-5 ">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full max-md:my-auto">
            <p className="my-auto font-semibold text-base text-black max-md:mt-10">
              {university}
            </p>
          </div>
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={imageSrc2}
              alt={`${name} from ${university}`}
              className="object-contain shrink-0 max-w-full aspect-square w-[194px] max-md:mt-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
