import { faSearch, faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDebouncedSearch from "../customHooks/useDebouncedSearch";
import { IUpdateSelectedFilter } from "../customHooks/useSelectedFilter";
import { FilterCategory, FilterActions } from "../FilterEnum";
import { IFilterDataItem } from "./DesktopFilterMenu";

interface IProps {
  data: IFilterDataItem;
  selectedFilter: Set<string> | string;
  updateSelectedFilter: IUpdateSelectedFilter;
}

const MobileFilterItem: React.FC<IProps> = ({
  data,
  selectedFilter,
  updateSelectedFilter,
}) => {
  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const options = data.options.map((option) => {
    let checked = null;
    if (
      data.type == FilterCategory.MultiSelect &&
      selectedFilter instanceof Set
    ) {
      checked = selectedFilter?.has(option.key) || false;
    } else if (
      data.type == FilterCategory.SingleSelect ||
      data.type == FilterCategory.Radio
    )
      checked = selectedFilter == option.key;
    else checked = false;

    return { ...option, checked };
  });

  console.log(selectedFilter, options);

  const sortedFilterOptions = options.sort(
    (a, b) => Number(b.checked) - Number(a.checked)
  );

  const searchFilteredOptions = sortedFilterOptions.filter((option) =>
    option.name.toLowerCase().includes(deboundedSearchKeyword.toLowerCase())
  );

  const handleCheckboxChange = (
    checked: boolean,
    category: string,
    option: string
  ) => {
    if (data.type == FilterCategory.MultiSelect) {
      if (checked)
        updateSelectedFilter(category, FilterActions.RemoveMultiSelect, option);
      else updateSelectedFilter(category, FilterActions.AddMultiSelect, option);
    } else if (data.type == FilterCategory.SingleSelect) {
      updateSelectedFilter(category, FilterActions.ChangeSingleSelect, option);
    } else {
      updateSelectedFilter(category, FilterActions.ChangeRadio, option);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {data?.options.length > 6 && (
        <div className="flex items-center bg-white pr-2 border border-black rounded-md overflow-hidden">
          <input
            className="p-2 text-sm w-full text-black"
            value={searchKeyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            placeholder={`Search ${data.categoryName}`}
          />
          {searchKeyword && searchKeyword.length > 0 ? (
            <button onClick={() => handleKeywordChange("")}>
              <FontAwesomeIcon icon={faX} size="sm" className="text-grey" />
            </button>
          ) : (
            <FontAwesomeIcon icon={faSearch} size="sm" className="text-grey" />
          )}
        </div>
      )}
      <ul className="flex flex-col gap-4 mt-4 h-full overflow-y-auto">
        {searchFilteredOptions.map((option) => (
          <li className="flex gap-4 items-center">
            <div
              className={`h-5 w-5 shrink-0 flex items-center justify-center border border-black bg-transparent rounded-sm cursor-pointer ${
                option.checked ? "bg-white" : ""
              }`}
              onClick={() =>
                handleCheckboxChange(
                  option.checked,
                  data.categoryKey,
                  option.key
                )
              }
            >
              {option.checked && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="text-green-2"
                />
              )}
            </div>
            <span className="text-sm">
              {option.name + (option.count ? ` (${option.count})` : "")}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileFilterItem;
