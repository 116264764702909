import React from "react";
import { College } from "../types";

interface props {
    data: College;
}

const CollegeCard: React.FC<props> = ({ data }) => {
    const {
        collegeLocation,
        collegeName,
        rankings,
        ownerShip,
        collegeShortName,
        collegeId,
        approvedBy,
        accrediationBy,
        collegeState,
        collegeCity,
        collegeUrl,
    } = data;

    return (
        <div className="border-2 p-2">
            {collegeName && <div>Name: {collegeName}</div>}
            {collegeLocation && <div>Location: {collegeLocation}</div>}
            {rankings && <div>Rankings: {rankings}</div>}
            {ownerShip && <div>OwnerShip: {ownerShip}</div>}
        </div>
    );
};

export default CollegeCard;
