import React from "react";
import Arrow from "../../../images/Arrow.svg";

interface Props {
  image?: string;
  imageUrls?: string[];
  title: string;
  description: string;
  onClick?: () => void;
}

const StudyAbroadCards: React.FC<Props> = ({
  image,
  imageUrls,
  title,
  description,
  onClick,
}) => {
  const displayImage = image || (imageUrls && imageUrls[0]);

  return (
    <div
      className="rounded-xl overflow-hidden cursor-pointer flex flex-col gap-4"
      onClick={onClick}
    >
      <div className="flex flex-col gap-5 overflow-hidden">
        {displayImage && (
          <img
            src={displayImage}
            alt={title}
            className="w-full h-48 rounded-xl object-cover"
          />
        )}
      </div>
      <div className="flex">
        <div className="relative w-full flex flex-col gap-2.5">
          <h4 className="text-base font-bold text-white line-clamp-2">
            {title}
          </h4>
          <div className="font-open-sans text-sm line-clamp-3 text-white">
            {description}
          </div>
          {/* <div className="pt-1">
            <img src={Arrow} className="arrow-icon" alt="arrow" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StudyAbroadCards;
