import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import webinar1 from "../../../images/courses/webinar1.png";
import webinar2 from "../../../images/courses/webinar2.png";
import ApplyNowForm from "../../applynow/ApplyNowForm";
import type { SVGProps } from "react";
import WebinarCard from "../../blogs-and-articles/WebinarCard";
interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}
const courseCards = [
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
  {
    title: "Future-Proof Your Career in Tech",
    by: "Manmeet Singh",
    description: "Explore the diverse range of career...",
    date: "14 Jul",
    time: "6:00 PM - 7:30 PM IST",
    image1: webinar1,
    image2: webinar2,
    name1: "xxxxxxx",
    name2: "xxxxxxx",
  },
];

const WebinarsTab: React.FC<{ webinars: Webinar[] }> = ({ webinars }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <>
      <Swiper
        spaceBetween={16}
        className="ExploreMore w-full"
        navigation={{
          prevEl: ".mentor-prev-btn",
          nextEl: ".mentor-next-btn",
        }}
        modules={[Navigation]}
        loop={false}
        breakpoints={{
          640: { slidesPerView: 1, slidesPerGroup: 1 },
          768: { slidesPerView: 2, slidesPerGroup: 2 },
          1024: { slidesPerView: 4, slidesPerGroup: 4 },
        }}
      >
        {webinars.map((content, index) => (
          <SwiperSlide key={index}>
            <WebinarCard {...content} handlePopup={handlePopup} />
          </SwiperSlide>
        ))}
      </Swiper>

      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#F7F7F7] rounded shadow-lg w-1/2 relative z-1000">
            <ApplyNowForm />
            <button
              onClick={handlePopup}
              className="text-white absolute top-3 right-2"
            >
              <MaterialSymbolsLightClose />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default WebinarsTab;
