import { Key, useEffect, useRef, useState } from "react";

import Banner from "./components/Banner";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
  IClearFilter,
} from "../../components/filter/customHooks/useSelectedFilter";
import { IFilterDataItem } from "../../components/filter/components/DesktopFilterMenu";
import {
  FilterActions,
  FilterCategory,
} from "../../components/filter/FilterEnum";
import TabSelector from "../../components/filter/components/TabSelector";
import axios from "axios";
import ExamCard from "./components/ExamCard";

import { ExamFiltersData } from "../exams/mock/mockData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { Option } from "../colleges/components/Accordion";
import { SortBy } from "../colleges/components/SortBy";
import Loader from "../../components/Loader/Loader";
import FiltersSection from "../colleges/components/FilterSection";
import Pagination from "../../components/filter/components/Pagination";
import useQueryParamPagination from "../../components/filter/customHooks/useQueryParamPagination";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";

const EXAM_CATEGORY_FILTER_CATEGORY = "examCategory";

const filterCategories: IFilterDataItem = {
  categoryName: "Exam Category",
  categoryKey: EXAM_CATEGORY_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Engineering", key: "engineering" },
    { name: "Medical", key: "medical" },
    { name: "Management", key: "management" },
    { name: "Law", key: "law" },
    { name: "Liberal Arts", key: "liberalArts" },
    { name: "Design", key: "design" },
    { name: "Hotel", key: "hotelManagement" },
  ],
};

const ExamIntermediate = () => {
  const examfiltersData = ExamFiltersData.filters;
  const listingContainerRef = useRef<HTMLDivElement>(null);

  const initialSelectedFilter: ISelectedFilter = {
    singleSelectFilters: {
      "": "",
    },
    multiSelectFilters: {},
    radioFilters: {},
  };
  interface SelectedFilters {
    [key: string]: string[];
  }
  const [selectedVal, setSelectedVal] = useState<SelectedFilters>({});
  const [intermediateData, setIntermediateData] = useState([]);
  const [filters, setFilters] = useState(examfiltersData);
  const [filtersData, setFiltersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMediumDevice = window.innerWidth >= 768;

  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>(
    initialSelectedFilter
  );
  const [stream, setStream] = useState("");
  const [totalExamCount, setTotalExamCount] = useState();

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const updateSelectedFilter: IUpdateSelectedFilter = (key, action, value) => {
    setStream(value);
    setSelectedFilter((prev) => ({
      ...prev,
      singleSelectFilters: {
        ...prev.singleSelectFilters,
        [key]: value,
      },
    }));
  };

  type Category = {
    categoryName: string;
    categoryKey: string;
    count?: number;
    options: Option[];
  };

  type FiltersType = Category[];
  const filterCategoriesData: FiltersType = filtersData;

  const [selectedSort, setSelectedSort] = useState<string>("popular");

  const sortOptions =
    filterCategoriesData?.find(
      (filter: any) => filter?.categoryKey === "sortBy"
    )?.options || [];

  const handleSortChange = (sortKey: string) => {
    setSelectedSort(sortKey);
  };
  const PAGE_SIZE = 10;
  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);

  useEffect(() => {
    handleResetPagination();
  }, [selectedVal]);

  useEffect(() => {
    updateTotalCount(totalExamCount || 0);
  }, [totalExamCount]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://mentormenteedev.com/api/exam/intermediate",
          {
            ...selectedVal,
            sortBy: selectedSort,
            stream: stream,
            offset: currentPage - 1,
            fetchCount: PAGE_SIZE,
            search: deboundedSearchKeyword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        );
        setIntermediateData(response.data.data.data.exams);
        setFiltersData(response.data.data.filters);
        setTotalExamCount(response.data?.data?.data?.totalexamCount);
      } catch (error) {
        console.error("Error making API request:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedVal, selectedSort, stream, currentPage, deboundedSearchKeyword]);

  const handleClearFilters = () => {
    setSelectedVal({});

    setFiltersData((prevFilters: any) => {
      const updatedFilters = prevFilters.map((filter: any) => {
        if (!filter.options) {
          return filter;
        }

        return {
          ...filter,
          options: filter.options.map((option: any) => ({
            ...option,
            isSelected: false,
          })),
        };
      });

      return updatedFilters;
    });

    handleResetPagination();

    if (listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const clearFilter: IClearFilter = () => {
    setSelectedFilter({
      singleSelectFilters: {
        "": "",
      },
      multiSelectFilters: {},
      radioFilters: {},
    });
  };
  const clearFilters = () => {
    setFilters(
      filters.map((filter: any) => {
        return {
          ...filter,
          options: filter.options.map((option: any) => {
            return {
              ...option,
              isSelected: false,
            };
          }),
        };
      })
    );
  };

  const colour = "green";

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const handleMobileFilter = () => {
    setIsFilterMenuOpen(true);
  };
  const handleMobileFilterClose = () => {
    setIsFilterMenuOpen(false);
  };

  return (
    <div className="bg-grey-2 text-black font-inter">
      <Banner />
      <div className="container max-w-screen-2xl">
        <div className="flex gap-8 justify-center pt-12">
          <TabSelector
            filterData={filterCategories}
            selectedFilter={selectedFilter}
            updateSelectedFilter={updateSelectedFilter}
            clearFilter={clearFilter}
            color={colour}
          />
        </div>
        <div className="flex md:gap-6 py-12">
          <div className="w-[30%] filter-section">
            <div
              className={`bg-[#061958] rounded-lg ${
                isMediumDevice ? "p-4" : ""
              }`}
            >
              {isMediumDevice && (
                <div className="flex flex-row justify-between border-b border-[#E0E0E0] items-center">
                  <h4 className="text-lg font-bold text-[#fff]">Filter by</h4>

                  <button
                    onClick={handleClearFilters}
                    className="text-[#fff] text-sm font-medium"
                  >
                    Clear All
                  </button>
                </div>
              )}
              <div>
                <FiltersSection
                  filters={filterCategoriesData}
                  setSelectedVal={setSelectedVal}
                  setFilters={setFilters}
                  mobileFilter={isFilterMenuOpen}
                  handleMobileFilterClose={handleMobileFilterClose}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-[70%] flex flex-col gap-6 relative">
            <div className="flex items-center justify-between gap-4">
              {!isMediumDevice && (
                <button
                  className="bg-white rounded-md p-3"
                  onClick={handleMobileFilter}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="filter"
                    className="svg-inline--fa fa-filter fa-lg "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    color="white"
                    stroke="#7D7B89"
                    stroke-width="40"
                  >
                    <path
                      fill="currentColor"
                      d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
                    ></path>
                  </svg>
                </button>
              )}

              <div className="text-base md:text-lg text-grey shrink-0 md:min-w-48">
                <h3>{`Showing ${intermediateData.length} Exams`}</h3>
              </div>

              <div className="search-box relative min md:-ml-[150px]">
                <input
                  type="text"
                  placeholder={`Search ${
                    isMediumDevice ? "your Dream College" : ""
                  }`}
                  className={`search-input ${
                    isMediumDevice ? "w-[378px]" : "w-[125px]"
                  } rounded-md px-5 py-3`}
                  value={searchKeyword}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                />
                {searchKeyword && searchKeyword.length > 0 ? (
                  <button
                    className="absolute right-5 top-3"
                    onClick={() => handleKeywordChange("")}
                  >
                    <FontAwesomeIcon
                      icon={faX}
                      size="sm"
                      className="text-grey"
                    />
                  </button>
                ) : (
                  <button className="absolute right-5 top-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      className="text-grey"
                    />
                  </button>
                )}
              </div>

              <div className="z-10 max-md:hidden">
                {sortOptions.length > 0 && (
                  <div className="mb-4">
                    <SortBy
                      options={sortOptions}
                      selectedSort={selectedSort}
                      onSortChange={handleSortChange}
                    />
                  </div>
                )}
              </div>
            </div>

            {loading ? <Loader /> : <ExamCard data={intermediateData} />}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamIntermediate;
