import React from 'react'
import chat_banner from '../Images/chat_banner.png'
const Every60Seconds = () => {
    return (
        <>
            <div className='flex justify-between px-20 py-10 section_clock'>
                <div className=' py-5  relative' style={{width:'40%'}}>
                    <ul className="sectionul clock">
                        <li className='sectionli'></li>
                        <li className='sectionli'></li>
                        <li className='sectionli'></li>
                    </ul>
                    <div className='position_clock_text'>
                        <div className='clock_heading'>Every</div>
                        <div className='flex items-end clock_heading1'>60 <span className='clock_heading2 mb-7'>Mins</span></div>
                        <div className='clock_heading3'>a student is on the phone with a college mentor Expert</div>
                    </div>
                </div>
                <div className=''>
                    <img src={chat_banner} />
                </div>
            </div>
        </>
    )
}

export default Every60Seconds