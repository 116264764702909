// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StudentInfoProps .swiper-pagination-bullet {
  background-color: #fff;
}
.StudentInfoProps .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #00cc99;
}
.StudentInfoProps .swiper-pagination {
  position: relative;
}

.parent-container::after {
  content: "";
  display: table;
  clear: both;
}

.universityLogoDeamser {
  position: relative;
  width: 180px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}

@media screen and (max-width: 787px) {
  .universityLogoDeamser {
    width: 130px;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/dreamersachiever/DreamersToAchiever.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ;AACI;EACE,yBAAA;AACN;AAEE;EACE,kBAAA;AAAJ;;AAGA;EACE,WAAA;EACA,cAAA;EACA,WAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AAAF;;AAEA;EACE;IACE,YAAA;EACF;AACF","sourcesContent":[".StudentInfoProps {\n  .swiper-pagination-bullet {\n    background-color: #fff;\n    &.swiper-pagination-bullet-active {\n      background-color: #00cc99;\n    }\n  }\n  .swiper-pagination {\n    position: relative;\n  }\n}\n.parent-container::after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n\n.universityLogoDeamser {\n  position: relative;\n  width: 180px;\n  padding: 10px;\n  overflow: hidden;\n  border-radius: 10px;\n  background-color: #fff;\n}\n@media screen and (max-width: 787px) {\n  .universityLogoDeamser {\n    width: 130px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
