import { icon } from "@fortawesome/fontawesome-svg-core";
import { read } from "fs";
import { title } from "process";
import React from "react";
import trainingIcon from "../../../../../images/college/details/placements/training-class.svg";
import internshipIcon from "../../../../../images/college/details/placements/internship.png";
import placementIcon from "../../../../../images/college/details/placements/placement.svg";
const WhatWeDoContent = [
  {
    icon: trainingIcon,
    title: "Training",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum",
    readMoreLink: "#",
  },
  {
    icon: internshipIcon,
    title: "Internship",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum",
    readMoreLink: "#",
  },
  {
    icon: placementIcon,
    title: "Placement",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum",
    readMoreLink: "#",
  },
];

const WhatWeDoSection: React.FC = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-5 justify-center items-center">
          <h2 className="text-3xl font-bold">What We Do</h2>
          <p className="text-lg text-[#6b7280]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            mollitia cum voluptate, iusto delectus, voluptatum
          </p>
        </div>
        <div className="flex flex-col justify-center items-center relative">
          <div className="flex">
            {WhatWeDoContent.map((content, index) => (
              <div
                key={index}
                className="flex flex-col gap-5 justify-center items-center"
              >
                <div className="w-20 h-20 overflow-hidden rounded-full border bg-white border-[#E0E0E0] p-1 flex items-center justify-center">
                  <img
                    src={content.icon}
                    alt="history"
                    className="w-14 h-14 object-cover rounded-lg"
                  />
                </div>
                <h3 className="text-xl font-semibold text-[#000000]">
                  {content.title}
                </h3>
                <p className="text-sm text-[#4F4F4F] text-center font-medium leading-6">
                  {content.content}
                </p>
                <a
                  href={content.readMoreLink}
                  className="text-[#113CC0] font-medium text-sm underline"
                >
                  Read More
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;
