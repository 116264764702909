// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuredCareerSec {
  position: relative;
}
.featuredCareerSec:hover .featuredCareer {
  opacity: 1;
  transform: translateY(0);
}
.featuredCareerSec .featuredCareer {
  opacity: 0;
  transform: translateY(0);
  transition: all 0.4s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  top: 0;
  left: 0;
  transform: translateY(100%);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  border-radius: 10px;
}
.featuredCareerSec .featuredCareer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: -9;
}
.featuredCareerSec .featuredCareerList {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/careers-comp/featuredCareers/FeaturedCareerStyles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACI;EACE,UAAA;EACA,wBAAA;AACN;AAEE;EACE,UAAA;EACA,wBAAA;EACA,gCAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAEE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".featuredCareerSec {\n  position: relative;\n  &:hover {\n    .featuredCareer {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n  .featuredCareer {\n    opacity: 0;\n    transform: translateY(0);\n    transition: all 0.4s ease-in-out;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    top: 0;\n    left: 0;\n    transform: translateY(100%);\n    background-repeat: no-repeat;\n    background-size: cover;\n    z-index: 10;\n    border-radius: 10px;\n  }\n  .featuredCareer::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    border-radius: 10px;\n    z-index: -9;\n  }\n  .featuredCareerList {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
