const ReportDownload = () =>{
    return(
        <div className="w-full flex  justify-center mx-1">
        <div className="w-full md:w-[592px] h-[33px] flex flex-row">
<div className="flex item-center  w-[80%] bg-[#173CBA] text-white text-[16px] pl-4 pt-1">
Get Personalised Report in PDF
</div>
<a 
  href="/pdf/sample.pdf" 
  download="Report.pdf"
  className="flex item-center justify-center w-[20%] bg-[#0C9] pt-1 text-white text-[16px] px-4 inline-block text-center cursor-pointer"
>
  Send
</a>
        </div>
        </div>
    )
}

export default ReportDownload;