import { title } from "process";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const HistoryContent = [
  {
    id: 1,
    title: "Marquee Companies",
    year: "2021",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum. ",
  },
  {
    id: 2,
    title: "Marquee Companies",
    year: "2020",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum. ",
  },
  {
    id: 3,
    title: "Marquee Companies",
    year: "2019",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum. ",
  },
  {
    id: 4,
    title: "Marquee Companies",
    year: "2018",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum. ",
  },
  {
    id: 5,
    title: "Marquee Companies",
    year: "2017",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium mollitia cum voluptate, iusto delectus, voluptatum. ",
  },
];
const HistorySection: React.FC = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-5 justify-center items-center">
          <h2 className="text-3xl font-bold">History</h2>
          <p className="text-lg text-[#6b7280]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            mollitia cum voluptate, iusto delectus, voluptatum
          </p>
        </div>
        <div className="flex flex-col gap-24 justify-center items-center relative">
          <div className="absolute h-full border-l-2 border-[#113CC0] left-1/2 transform -translate-x-1/2"></div>

          {HistoryContent.map((item) => (
            <div
              key={item.id}
              className="flex flex-row-reverse gap-20 justify-center items-center relative group historyContent"
            >
              <div className="w-full md:w-2/6">
                <h3 className="text-xl font-bold">{item.title}</h3>
                <p className="text-[#6b7280]">{item.year}</p>
              </div>
              <div className="relative w-4">
                <div className="absolute w-4 h-4 bg-white border-2 border-[#113CC0] rounded-full left-0 hoverIconanimate"></div>
              </div>
              <div className="w-full md:w-2/6 relative">
                <div className="bg-[#f1f1f1] p-12 rounded-lg flex gap-6 flex-row-reverse justify-center items-start group-hover:bg-[#113CC0] group-hover:text-white historyCon">
                  <div className="flex">
                    <p className="w-16 h-16 border border-black rounded-full flex flex-row justify-center items-center text-4xl group-hover:text-white group-hover:border-white">
                      {item.icon}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-black text-base group-hover:text-white">
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HistorySection;
