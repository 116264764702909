import React from 'react'
import faq from '../Images/faqMentor.png'
const FaqMentor = () => {
    return (
        <>
        
           <div className='grid grid-cols-3 my-5 gap-4'>
           <div className="flex flex-col gap-8 mx-5  col-span-2 ">
            <div className='MentorPages_heading_right text-justify'>FAQ's</div>
                <div className="bg-gray-200 p-8 rounded w-5/6"></div>
                <div className="bg-gray-300 p-8 rounded w-5/6"></div>
                <div className="bg-gray-400 p-8 rounded w-5/6"></div>
               
            </div>
            <div>
                <img src={faq}/>
            </div>
           </div>
        </>
    )
}

export default FaqMentor