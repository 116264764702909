import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
const ShapeYourFutureSection = () => {
    const videos: string[] = [
        "https://www.youtube.com/watch?v=a-YzGE9lEnM",
        "https://youtu.be/SyrO83x7g-E",
    ];

    const [index, setIndex] = useState(0);

    const playerRef = useRef<ReactPlayer>(null);

    const nextVideo = () => {
        if (index < videos.length - 1) {
            setIndex((idx) => idx + 1);
        } else {
            setIndex(0);
        }
    };

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.showPreview();
        }
    }, [index]);

    return (
        <>
            <div className="grid grid-cols-12 gap-4 container">
                <div className="col-span-4">
                    <div className="p-3 " style={{width:'100%'}} >
                    <ReactPlayer
                        light={true}
                        ref={playerRef}
                        url={videos[index]}
                        width='100%'
                        style={{borderRadius:'30px'}}
                    />
                    </div>
                </div>
                <div className="col-span-8 px-5">
                    <div className='shapeYourFeature_heading my-5'>Shape Your Future</div>
                    <div className='shapeYourFeature_subheading'>College mentor connects young people with caring mentors who help them navigate challenges and feel valued quality mentoring leads to personal growth,academic success and career oppurtunities sadly 1 in 3 young people lack this support college mentor is here to bridge the gap and make a lasting impact</div>
                </div>
            </div>
        </>
    )
}

export default ShapeYourFutureSection