export interface IVisualStoryData {
  id: number;
  title: string;
  description: string;
  src?: string;

  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

const VisualStoryCard: React.FC<IVisualStoryData> = ({
  src,
  title,
  description,
}) => (
  <div className="flex flex-col max-md:ml-0 max-md:w-full rounded-2xl relative mb-24">
    {src ? (
      <img
        loading="lazy"
        src={src}
        alt={title}
        className="grow shrink-0 max-w-full object-cover rounded-2xl aspect-[0.76] w-[317px] max-md:mt-6"
      />
    ) : null}
    <div className="career-visual-content flex flex-col gap-3 absolute bg-black/30 bottom-0 text-white p-4 rounded-2xl transition duration-200 ease-in-out">
      <h3 className="text-2xl text-white transition duration-200 ease-in-out font-bold">
        {title}
      </h3>
      <p className="transition duration-200 ease-in-out text-base line-clamp-3 text-white">
        {description}
      </p>
    </div>
  </div>
);

export default VisualStoryCard;
