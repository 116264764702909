import React, { useEffect, useState } from "react";
import ToggleGroup from "./GroupButtonsMore";
import { Box, Button, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { registrationData } from "../../services/apiReq";

function PersonalInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const iAmList: string[] = ["Student", "Father", "Mother", "Mentor", "Others"];
  const [iAmValue, setIAmValue] = useState("");
  const genderList: string[] = ["Male", "Female", "Others"];
  const [genderValue, setGenderValue] = useState("");
  const [data, setData] = useState<registrationData>(location.state);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      personType: iAmValue,
      gender: genderValue,
    }));
  }, [iAmValue, genderValue]);

  const handleContinue = (event: any) => {
    navigate("/standardInfo", { state: { ...data } });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>

        <ToggleGroup
          list={iAmList}
          title="I am a:"
          setSelectedValue={setIAmValue}
          selectedValue={iAmValue}
          moreButton={false}
        />
        {iAmValue === "" ? (
          <></>
        ) : (
          <ToggleGroup
            list={genderList}
            title="Gender:"
            setSelectedValue={setGenderValue}
            selectedValue={genderValue}
            moreButton={false}
          />
        )}

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          onClick={handleContinue}
          disabled={iAmValue === "" || genderValue === ""}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default PersonalInfo;
