// src/data/quizData.js
export const quizData = [
  {
    number: 1,
    question: "What does CPU stand for?",
    choices: [
      "Central Process Unit",
      "Central Processing Unit",
      "Central Processor Unit",
      "Central Performance Unit",
    ],
    correctChoice: 1,
    detailedAnswer: "CPU stands for Central Processing Unit.",
  },
  {
    number: 2,
    question: "What is the main function of the ALU in a computer?",
    choices: [
      "Store data",
      "Perform arithmetic and logic operations",
      "Control input and output operations",
      "Manage system memory",
    ],
    correctChoice: 1,
    detailedAnswer:
      "The Arithmetic Logic Unit (ALU) performs arithmetic and logic operations.",
  },
  {
    number: 3,
    question: "Which of the following is an example of non-volatile memory?",
    choices: ["RAM", "Cache", "ROM", "Register"],
    correctChoice: 2,
    detailedAnswer: "ROM (Read-Only Memory) is a type of non-volatile memory.",
  },
  {
    number: 4,
    question: "What does GPU stand for?",
    choices: [
      "General Processing Unit",
      "Graphics Processing Unit",
      "Graphical Performance Unit",
      "General Performance Unit",
    ],
    correctChoice: 1,
    detailedAnswer: "GPU stands for Graphics Processing Unit.",
  },
  {
    number: 5,
    question:
      "Which device is used to connect multiple computers in a network?",
    choices: ["Router", "Switch", "Modem", "Hub"],
    correctChoice: 1,
    detailedAnswer:
      "A router is used to connect multiple computers in a network.",
  },
  {
    number: 6,
    question: "What is the primary purpose of an operating system?",
    choices: [
      "To perform calculations",
      "To manage hardware and software resources",
      "To store data",
      "To execute user programs",
    ],
    correctChoice: 1,
    detailedAnswer:
      "The primary purpose of an operating system is to manage hardware and software resources.",
  },
  {
    number: 7,
    question: "Which of the following is a programming language?",
    choices: ["HTTP", "HTML", "C++", "FTP"],
    correctChoice: 2,
    detailedAnswer: "C++ is a programming language.",
  },
  {
    number: 8,
    question: "What does RAM stand for?",
    choices: [
      "Random Access Memory",
      "Read Access Memory",
      "Randomized Access Memory",
      "Read-Only Access Memory",
    ],
    correctChoice: 0,
    detailedAnswer: "RAM stands for Random Access Memory.",
  },
  {
    number: 9,
    question:
      "What type of software is used to create documents, spreadsheets, and presentations?",
    choices: [
      "System software",
      "Utility software",
      "Application software",
      "Firmware",
    ],
    correctChoice: 2,
    detailedAnswer:
      "Application software is used to create documents, spreadsheets, and presentations.",
  },
  {
    number: 10,
    question: "Which of the following is a web browser?",
    choices: ["Google", "Microsoft", "Apple", "Mozilla Firefox"],
    correctChoice: 3,
    detailedAnswer: "Mozilla Firefox is a web browser.",
  },
];
