// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.progress-bar.completed {
  width: 100%;
}

.progress-bar.current {
  animation: progress 5s linear forwards;
}

.progress-bar.current.paused {
  animation-play-state: paused;
}

.progress-bar {
  width: 0%;
  height: 0.3rem;
  background-color: #173cba;
}`, "",{"version":3,"sources":["webpack://./src/components/VisualStory.scss"],"names":[],"mappings":"AAAA;EACE;IACE,QAAA;EACF;EACA;IACE,WAAA;EACF;AACF;AAEA;EACE,WAAA;AAAF;;AAGA;EACE,sCAAA;AAAF;;AAGA;EACE,4BAAA;AAAF;;AAGA;EACE,SAAA;EACA,cAAA;EACA,yBAAA;AAAF","sourcesContent":["@keyframes progress {\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n}\n\n.progress-bar.completed {\n  width: 100%;\n}\n\n.progress-bar.current {\n  animation: progress 5s linear forwards;\n}\n\n.progress-bar.current.paused {\n  animation-play-state: paused;\n}\n\n.progress-bar {\n  width: 0%;\n  height: 0.3rem;\n  background-color: #173cba;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
