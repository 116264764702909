import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
  const data = useSelector(
    (state: RootState) => state.collegeCompare.landingData?.faqs
  );
  const [viewMore, setViewMore] = useState(false);

  if (!data) return;

  const dataToShow = viewMore ? data : data.slice(0, 3);

  const cardBgColors = ["#FFF9E5", "#FFEFEE", "#FFF1FA", "#EBFFFA"];
  const cardBorderColors = ["#FFD540", "#DA4A41", "#F6C", "#27D6AC"];

  return (
    <div className="py-12 bg-[#F0F4FF] text-black">
      <div className="container flex flex-col gap-6">
        <h1 className="text-4xl lg:text-5xl font-bold text-center">
          Frequently Asked Questions (FAQs)
        </h1>
        {dataToShow &&
          dataToShow.map((item: any, index: number) => (
            <div
              className="p-6 rounded-2xl"
              style={{
                border: `1px solid ${
                  cardBorderColors[index % cardBorderColors.length]
                }`,
                background: cardBgColors[index % cardBgColors.length],
              }}
            >
              <p className="text-sm lg:text-xl">
                Question:{" "}
                <span className="font-semibold">{item?.question}</span>
              </p>
              <p className="text-xs lg:text-lg">
                <span className="font-medium">Answer: </span>
                {item?.answer}
              </p>
            </div>
          ))}
        {data?.length > 3 && (
          <button
            className="self-end px-4 py-1 text-xs lg:text-xl text-white bg-blue"
            onClick={() => setViewMore((prev) => !prev)}
          >
            {`View ${viewMore ? "less" : "more"} `}
            <FontAwesomeIcon icon={viewMore ? faChevronUp : faChevronDown} />
          </button>
        )}
      </div>
    </div>
  );
};

export default FAQ;
