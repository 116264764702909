import React, { useEffect, useState } from "react";
import ToggleGroup from "./GroupButtonsMore";
import { Box, Button, Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { registrationData } from "../../services/apiReq";
import { fundTypes } from "../../constants/signInForm";

function PlansToFund() {
  // const navigate = useNavigate();
  const location = useLocation();
  const fundTypeList: string[] = fundTypes;
  const [fundType, setFundType] = useState("");
  const [data, setData] = useState<registrationData>(location.state);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      fundType,
    }));
  }, [fundType]);

  const handleContinue = (event: any) => {
    console.log(data);
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>

        <ToggleGroup
          list={fundTypeList}
          title="Plans to fund your education:"
          setSelectedValue={setFundType}
          selectedValue={fundType}
          moreButton={false}
        />

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          onClick={handleContinue}
          disabled={fundType === ""}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default PlansToFund;
