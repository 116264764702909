import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "../styles/InputField.scss";
const InputField = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="input-wrapp">
        {props.label && <div className="input-label">{props.label}</div>}
        <div className={"input-container" + (props?.error ? " error" : "")}>
          {props.icon && <div className="input-icon">{props.icon}</div>}

          <input
            type={showPassword ? "text" : props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            name={props.name}
          />
          {props.type === "password" && (
            <div className="input-icon" onClick={handleTogglePassword}>
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          )}
        </div>
        {props.helperText && (
          <div className="error-helperText">{props.helperText}</div>
        )}
      </div>
    </>
  );
};

export default InputField;
