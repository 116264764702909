// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.posts-section .swiper-pagination {
  position: relative;
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/Landing/components/posts/Posts.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,gBAAA;AAAR","sourcesContent":[".posts-section {\n    .swiper-pagination {\n        position: relative;\n        margin-top: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
