import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import userIcon from "../../../../../images/college/details/placements/usericon.jpeg";
import arunBg from "../../../../../images/college/details/placements/portrait-beautiful.jpeg";
import manuBg from "../../../../../images/college/details/placements/portrait-young.jpeg";

const NotableAlumniContent = [
  {
    title: "Notable Alumni",
    description:
      "SRM Institute of Science and Technology boasts an array of notable alumni across various fields. Among them is Arun Kumar Vembu, co-founder of Zoho Corporation, a renowned software development company. Additionally, Manu Kumar Jain, the Global Vice President of Xiaomi and Managing Director of Xiaomi India, is also an alumnus of SRM. Another distinguished figure is Pawan Kumar Goenka, the former Managing Director of Mahindra & Mahindra Limited, a prominent automotive manufacturer in India. These individuals exemplify the caliber of talent fostered by SRM, making significant contributions to their respective industries.",
    tabContentData: [
      {
        title: "Arun Kumar Vembu",
        department: "Co-founder of Zoho Corporation",
        image: userIcon,
        description:
          "<p>Arun Kumar Vembu is a co-founder of Zoho Corporation, a software development company that provides cloud-based software for businesses. Zoho offers a suite of online productivity tools and SaaS applications for businesses, including CRM, email marketing, project management, and more.</p> <p>As a co-founder of Zoho, Arun Kumar Vembu has played a key role in the company's growth and success. Zoho has become one of the leading software companies in the world, with millions of users and customers around the globe.</p>",
        winner: "Journalism Scholarship Winner",
        bgImage: arunBg,
      },
      {
        title: "Manu Kumar Jain",
        department: "Global Vice President of Xiaomi",
        image: userIcon,
        description:
          "<p>Manu Kumar Jain is the Global Vice President of Xiaomi and the Managing Director of Xiaomi India. He is responsible for overseeing Xiaomi's operations in India, one of the company's largest markets outside of China.</p> <p>Under Manu Kumar Jain's leadership, Xiaomi has become one of the top smartphone brands in India, with a strong presence in the market. He has been instrumental in driving Xiaomi's growth and success in the country, and has helped the company establish itself as a leading player in the Indian smartphone market.</p>",
        winner: "Journalism Scholarship Winner",
        bgImage: manuBg,
      },
      {
        title: "Pawan Kumar Goenka",
        department: "Former Managing Director of Mahindra & Mahindra Limited",
        image: userIcon,
        description:
          "<p>Pawan Kumar Goenka is the former Managing Director of Mahindra & Mahindra Limited, a prominent automotive manufacturer in India. He has played a key role in the growth and success of Mahindra & Mahindra, helping the company become one of the leading automotive brands in India.</p> <p>During his tenure at Mahindra & Mahindra, Pawan Kumar Goenka oversaw the company's expansion into new markets and product segments, and helped drive innovation and growth across the organization. He is widely regarded as one of the most influential figures in the Indian automotive industry.</p>",
        winner: "Journalism Scholarship Winner",
        bgImage: arunBg,
      },
      {
        title: "Pawan Kumar Goenka",
        department: "Former Managing Director of Mahindra & Mahindra Limited",
        image: userIcon,
        description:
          "<p>Pawan Kumar Goenka is the former Managing Director of Mahindra & Mahindra Limited, a prominent automotive manufacturer in India. He has played a key role in the growth and success of Mahindra & Mahindra, helping the company become one of the leading automotive brands in India.</p> <p>During his tenure at Mahindra & Mahindra, Pawan Kumar Goenka oversaw the company's expansion into new markets and product segments, and helped drive innovation and growth across the organization. He is widely regarded as one of the most influential figures in the Indian automotive industry.</p>",
        winner: "Journalism Scholarship Winner",
        bgImage: arunBg,
      },
      {
        title: "Pawan Kumar Goenka",
        department: "Former Managing Director of Mahindra & Mahindra Limited",
        image: userIcon,
        description:
          "<p>Pawan Kumar Goenka is the former Managing Director of Mahindra & Mahindra Limited, a prominent automotive manufacturer in India. He has played a key role in the growth and success of Mahindra & Mahindra, helping the company become one of the leading automotive brands in India.</p> <p>During his tenure at Mahindra & Mahindra, Pawan Kumar Goenka oversaw the company's expansion into new markets and product segments, and helped drive innovation and growth across the organization. He is widely regarded as one of the most influential figures in the Indian automotive industry.</p>",
        winner: "Journalism Scholarship Winner",
        bgImage: arunBg,
      },
    ],
  },
];

const NotableAlumni = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    setActiveTab(
      (prev) => (prev + 1) % NotableAlumniContent[0].tabContentData.length
    );
  };

  const handlePrev = () => {
    setActiveTab((prev) =>
      prev === 0 ? NotableAlumniContent[0].tabContentData.length - 1 : prev - 1
    );
  };

  return (
    <div className="bg-gray-200 py-16">
      <div className="container mx-auto">
        {NotableAlumniContent.map((content, index) => (
          <div key={index} className="flex flex-col gap-10">
            <div className="flex flex-col justify-center items-center gap-4">
              <h3 className="text-3xl font-bold text-center">
                {content.title}
              </h3>
              <p className="text-center">{content.description}</p>
            </div>
            <div className="flex justify-center items-center">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                centered
                className="flex flex-col justify-center items-center NotableAlumniContent"
              >
                {content.tabContentData.map((tab, tabIndex) => (
                  <Tab
                    key={tabIndex}
                    label={
                      <div className="flex items-center justify-center gap-4">
                        <div className="w-16 h-16 min-w-16 min-h-16 flex justify-center items-center">
                          <img
                            src={tab.image}
                            alt={tab.title}
                            className="w-full h-full object-cover rounded-full"
                          />
                        </div>
                        {activeTab === tabIndex && (
                          <div className="flex flex-col items-start justify-center">
                            <h4 className="text-lg text-[#262626] font-bold capitalize">
                              {tab.title}
                            </h4>
                            <p className="text-base text-[#444444] capitalize text-left">
                              {tab.department}
                            </p>
                          </div>
                        )}
                      </div>
                    }
                    className="text-gray-700 button-tab"
                  />
                ))}
              </Tabs>
            </div>
            <div className="flex justify-center items-center gap-4 relative">
              <div className="gap- h-9 bg-white absolute left-2 rounded-full flex flex-col justify-center items-center">
                <ChevronLeft
                  className="cursor-pointer text-xl w-8 h-8"
                  onClick={handlePrev}
                />
              </div>
              {content.tabContentData.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={`transition-opacity duration-500 ease-in-out ${
                    activeTab === tabIndex ? "opacity-100" : "opacity-0 hidden"
                  }`}
                  style={{
                    backgroundImage: `url(${tab.bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="flex flex-col justify-center items-start gap-10 p-16">
                    <div className="w-1/2 bg-white bg-opacity-95 p-10 flex flex-col gap-4">
                      <div>
                        <h3 className="text-3xl font-bold ">{tab.title}</h3>
                        <p className="text-base font-semibold">
                          {tab.department}
                        </p>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: tab.description }}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="w-9 h-9 bg-white absolute right-2 rounded-full flex flex-col justify-center items-center">
                <ChevronRight
                  className="cursor-pointer text-xl w-8 h-8"
                  onClick={handleNext}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotableAlumni;
