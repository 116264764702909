import { FC } from "react";
import storiesIcon from "./images/stories-icon.png";
import StudyAbroad from "../../images/blog-article/exam-section-image1.jpg";

interface IProps {
  title: string;
  description: string;
  image: string;
  openVisualStory: () => void;
}

const VisualStoryCard: FC<IProps> = ({
  title,
  description,
  image,
  openVisualStory,
}) => {
  return (
    <div
      className="p-4 bg-white flex flex-col gap-4 cursor-pointer"
      onClick={openVisualStory}
    >
      <div className="relative">
        <img src={StudyAbroad} className="w-full" />
        <img src={storiesIcon} className="absolute top-4 right-4 w-8" />
      </div>
      <h1 className="text-lg text-black font-bold line-clamp-1">{title}</h1>
      <p className="text-base text-grey line-clamp-5">{description}</p>
    </div>
  );
};

export default VisualStoryCard;
