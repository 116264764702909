import { useState } from "react";
import Explore from "./Explore";

const tabs = [
  {
    name: "Explore",
    element: <Explore />,
  },
  {
    name: "Applications",
    elements: <div>Yet to be implemented!</div>,
  },
];

const Admissions = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="p-8">
      <nav className="flex gap-4">
        {tabs.map((tab, index) => (
          <button
            className={`px-4 py-2 ${
              activeTab == index ? "text-white bg-green" : ""
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </button>
        ))}
      </nav>
      <div className="mt-8">{tabs[activeTab].element}</div>
    </div>
  );
};

export default Admissions;
