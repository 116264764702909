// src/components/Analysis.js
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { quizData } from "./quizData.js";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

const Analysis = () => {
  const { state } = useLocation();
  const { answers } = state;
  const componentRef = useRef();
  const navigate = useNavigate();

  const correctAnswersCount = quizData.filter(
    (q, index) => q.correctChoice === answers[index]
  ).length;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleSubmit = () => {
    navigate("/courses");
  };

  const handleDownload = () => {
    const input = componentRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("analysis.pdf");
    });
  };

  return (
    <div className="p-8 bg-grey-3 min-h-screen" ref={componentRef}>
      <h1 className="text-3xl font-bold mb-6 text-center">Quiz Analysis</h1>
      <p className="text-lg mb-6 text-center">
        You answered{" "}
        <span className="font-semibold">{correctAnswersCount}</span> out of{" "}
        <span className="font-semibold">{quizData.length}</span> questions
        correctly.
      </p>
      <div className="space-y-6">
        {quizData.map((question, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-2">{`${question.number}. ${question.question}`}</h2>
            <div className="mb-2">
              <span className="font-semibold">Correct answer:</span>{" "}
              {question.choices[question.correctChoice]}
            </div>
            <div className="mb-2">
              <span className="font-semibold">Your answer:</span>{" "}
              {answers[index] !== null
                ? question.choices[answers[index]]
                : "No answer"}
            </div>
            <p className="italic">{question.detailedAnswer}</p>
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <button
          onClick={handleSubmit}
          className="bg-blue text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-2 mr-4"
        >
          Go back to Course
        </button>
        <button
          onClick={handleDownload}
          className="bg-green text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-2"
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default Analysis;
