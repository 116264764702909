import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SpotlightImage1 from "../../../images/courses/spotlight-1.webp";
import SpotlightImage2 from "../../../images/courses/spotlight-2.webp";
import VideoCard from "../../blogs-and-articles/VideoCard";
import VideoModal from "../../blogs-and-articles/VideoModal";
interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
  views?: number;
}
const courseSpotlightsData = [
  {
    title: "Math for the Real World: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage1,
    link: "https://www.youtube.com/embed/Asi4ZRE6gUU?si=enLa9j-0W7KS4nae",
  },
  {
    title: "Principles of physics: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage2,
    link: "https://www.youtube.com/embed/e5YMVEFGWyk?si=RGLx90aVPPBMnrZ9",
  },
  {
    title: "Math for the Real World: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage1,
    link: "https://www.youtube.com/embed/Asi4ZRE6gUU?si=enLa9j-0W7KS4nae",
  },
  {
    title: "Principles of physics: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage2,
    link: "https://www.youtube.com/embed/e5YMVEFGWyk?si=RGLx90aVPPBMnrZ9",
  },
  {
    title: "Math for the Real World: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage1,
    link: "https://www.youtube.com/embed/Asi4ZRE6gUU?si=enLa9j-0W7KS4nae",
  },
  {
    title: "Principles of physics: Course Spotlight",
    description:
      "This course introduces students to probability theory and statistics, and their applications in engineering and data science.",
    views: "22K views",
    postedOn: "2 months ago",
    image: SpotlightImage2,
    link: "https://www.youtube.com/embed/e5YMVEFGWyk?si=RGLx90aVPPBMnrZ9",
  },
];

const CourseSpotlightsTab: React.FC<{ spotLight: SpotLight[] }> = ({
  spotLight,
}) => {
  const [videoModalURL, setVideoModalURL] = useState<string | null>(null);

  return (
    <>
      <Swiper
        spaceBetween={16}
        className="ExploreMore w-full"
        navigation={{
          prevEl: ".mentor-prev-btn",
          nextEl: ".mentor-next-btn",
        }}
        modules={[Navigation]}
        loop={false}
        breakpoints={{
          640: { slidesPerView: 1, slidesPerGroup: 1 },
          768: { slidesPerView: 2, slidesPerGroup: 2 },
          1024: { slidesPerView: 4, slidesPerGroup: 4 },
        }}
      >
        {spotLight.map((content, index) => (
          <SwiperSlide key={index}>
            <VideoCard
              {...content}
              openVideoModal={() => setVideoModalURL(content.link)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {videoModalURL && (
        <VideoModal
          link={videoModalURL}
          closeModal={() => setVideoModalURL(null)}
        />
      )}
    </>
  );
};

export default CourseSpotlightsTab;
