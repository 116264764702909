const Advertisment = () =>{
    return(
       <>
      <div className="w-full flex  justify-center mx-1 my-12">
        <div className="w-full md:w-[592px]  bg-white flex flex-row border border-black">
<div className="flex item-center  w-[75%]  text-[18px] pl-4 pt-1 font-bold">
Private Colleges and Universities Accepting Admission Through JEE Main Score / Rank</div>
<div className="flex item-center justify-center w-[25%]">
<a href="/college"
  className="my-4 bg-[#173CBA] pt-1 text-white text-[16px] px-4 inline-block text-center"
>
  Apply Now
</a></div>
        </div>
        </div>
       </>
    )
}

export default Advertisment;