import { title } from "process";
import React from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

import { SVGProps } from "react";
import { Link } from "react-router-dom";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const JobsProfilesContent = {
  title: "Job Profiles",
  description:
    "Upon completion of their studies in Mechanical Engineering, candidates are provided with an array of opportunities to begin their careers with. Candidates can work as a Design Engineer, Mechanical Engineer, and Assistant Mechanical Engineer. Some of the top recruiters in the field of Mechanical Engineering are mentioned below.",
  StrongParagraph: "Top Recruiters",
  listItems: [
    "Toshiba",
    "Indian Oil",
    "Tata Motors",
    "Hindustan Petroleum Corporation Limited",
    "Bharat Petroleum Corporation Limited",
    "Reliance Industries",
  ],
  jobProfileTableData: {
    tableData: [
      {
        title: "Purchase and Quality Control Executive",
        content:
          "Purchase and Quality Control Executive is responsible for supervising staff and overseeing product development procedures to ensure quality and efficiency standards are being met.",
      },
      {
        title: "Design Engineer",
        content:
          "Design Engineers are responsible for researching and developing new systems and products. They are also responsible for improving the efficiency and performance of existing products.",
      },
      {
        title: "Mechanical Engineer",
        content:
          "Mechanical Engineers are responsible for designing the specifications for, developing, designing, manufacturing, and installing modified or new components and systems.",
      },
      {
        title: "Assistant Mechanical Engineer",
        content:
          "Assistant Mechanical Engineers are professionals who are responsible for performing a variety of tasks to assist mechanics in operation, repair, and refurbishment of machines.",
      },
    ],
  },
};

const JobsProfilesTable = () => {
  const { data } = useCourseDetails();
  const { careers_in } = data;
  const jobProfiles = careers_in?.job_profiles;
  return (
    <>
      <table className="w-full border-collapse border-[#383838] border">
        <tbody>
          <tr className="border-[#383838] border bg-[#EEF2FF]">
            <th className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]  text-left">
              Job Profile
            </th>
            <th className="py-2.5 px-5 text-base text-[#4C495C] text-left">
              Job Descriptions
            </th>
          </tr>
          {jobProfiles.map((detail: any, index: number) => (
            <tr
              key={index}
              className={`border-[#383838] border ${
                index % 2 === 0 ? "bg-[#fff]" : "bg-[#EEF2FF]"
              }`}
            >
              <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                <Link to={`/careers/career-details/${detail.job_role}`}>
                  {detail.job_role}
                </Link>
              </td>
              <td className="py-2.5 px-5 text-base text-[#4C495C]">
                {detail.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const JobsProfiles: React.FC = () => {
  const { data } = useCourseDetails();
  const { recruiting_companies, courseTitle } = data;
  return (
    <div className="flex flex-col bg-[#fff] relative" id="job-profiles">
      <div className="flex flex-col gap-2.5">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {JobsProfilesContent.title}
          </h3>
        </div>
      </div>
      <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
        <JobsProfilesTable />
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2.5">
            <p className="text-base text-[#4C495C] m-0">
              {JobsProfilesContent.description}
            </p>
          </div>
          <div className="flex flex-col gap-2.5">
            <p className="flex text-base font-medium text-[#4C495C] m-0">
              {`${courseTitle} Recruiters`}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              {recruiting_companies &&
                Array.from({
                  length: Math.ceil(recruiting_companies.length / 6),
                }).map((_, colIndex) => (
                  <ul key={colIndex} className="list-disc list-inside">
                    {recruiting_companies
                      .slice(colIndex * 6, colIndex * 6 + 6)
                      .map((item: string, index: number) => (
                        <li
                          key={index}
                          className="text-base text-[#4C495C] leading-7"
                        >
                          {item}
                        </li>
                      ))}
                  </ul>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsProfiles;
