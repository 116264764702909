import React from "react";
import graduationImg from "../../images/comprehensive/graduated-2.png";
import collegeImg from "../../images/comprehensive/collegeIcon.png";
import certificationImg from "../../images/comprehensive/certification.png";
import CounterAnimation from "../CounterAnimation";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function ComprehensiveTest() {
  return (
    <div className="comprehensive-test py-11 bg-[#F7F6FA]">
      <div className="w-[1360px] mx-auto">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[6/12] max-md:ml-0 max-md:w-full">
            <div className="flex overflow-hidden relative flex-col grow justify-center min-h-[518px] max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/group.png"
                alt="group img"
                className="w-full  max-md:max-w-full"
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
              <div className="text-base font-extrabold tracking-widest text-indigo-500 max-md:max-w-full">
                Exams
              </div>
              <motion.div
                initial={{ x: "-8rem", opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.8 }}
                whileInView={{ x: 0, opacity: 1 }}
                className="mt-2 text-3xl font-extrabold text-slate-800 max-md:max-w-full"
              >
                Comprehensive Test Prep:
              </motion.div>
              <div className="mt-6 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                Your Pathway to Excelling
                <br />
                Boost your scores and enhance admission possibilities with our
                all-encompassing test prep covering 250+ exams, including JEE
                Mains, NEET, NIFT, SRMJEE, BITSAT, VITEEE
              </div>
              <Link
                to="/exams"
                className="arrow-button flex gap-2 justify-center self-start px-9 py-3.5 mt-6 text-base font-semibold text-white blue-color button-styles rounded-[50px] max-md:px-5"
              >
                Visit our test Library
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
              <div className="mt-6 max-md:pr-5 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="library-card hover:shadow-lg flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center px-5 py-9 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src={graduationImg}
                        alt="icon"
                        className="aspect-square w-[60px]"
                      />
                      <div className="self-stretch mt-4 text-2xl font-bold leading-5 text-[#004644]">
                        <span>
                          <CounterAnimation start={0} end={24198} /> +
                        </span>
                      </div>
                      <div className="mt-5 text-sm tracking-normal leading-5 text-zinc-600">
                        NEET
                      </div>
                    </div>
                  </div>
                  <div className="library-card hover:shadow-lg flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center px-7 py-9 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src={collegeImg}
                        alt="icon"
                        className="aspect-[1.22] w-[73px]"
                      />
                      <div className="self-stretch mt-4 text-2xl font-bold leading-5 text-[#004644]">
                        <span>
                          <CounterAnimation start={0} end={6214} /> +
                        </span>
                      </div>
                      <div className="mt-5 text-sm tracking-normal leading-5 text-zinc-600">
                        NEET
                      </div>
                    </div>
                  </div>
                  <div className="library-card hover:shadow-lg flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center px-7 py-8 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src={certificationImg}
                        alt="icon"
                        className="w-12 aspect-[0.8]"
                      />
                      <div className="self-stretch mt-4 text-2xl font-bold leading-5 text-[#004644]">
                        <span>
                          <CounterAnimation start={0} end={2500} /> +
                        </span>
                      </div>
                      <div className="mt-5 text-sm tracking-normal leading-5 text-zinc-600">
                        CTA
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
