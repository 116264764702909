// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-sample {
  color: #173CBA;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-description {
  color: #383838;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog-site {
  color: #173CBA;
  text-align: right;
  font-family: "Open Sans";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/landing/components/Blogs.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EAEA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAEA;EACI,cAAA;EAEA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAEA;EACI,cAAA;EAEA,iBAAA;EACA,wBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".blog-sample{\n    color: #173CBA;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n}\n.blog-description{\n    color: #383838;\n\n    font-family: \"Open Sans\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal\n}\n.blog-site{\n    color: #173CBA;\n\n    text-align: right;\n    font-family: \"Open Sans\";\n    font-size: 9px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
