import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sendOtp, signupPAFDetail, verifyOtp } from "../thunks/authThunks";

interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  user: string | null;
  mobile: string;
  otpSent: boolean;
  loading: boolean;
  error: string | null;
  message: string;
  [key: string]: any;
  pafData: any;
}

const pafData = {
  currentLevelOfStudy: ["8-11", "12th", "UG", "PG", "Others"],
  lookingForDegree: ["UG", "PG", "PhD"],
  lookingForStream: [
    {
      streamId: 1,
      streamName: "Engineering",
    },
    {
      streamId: 2,
      streamName: "Management and Business Administration",
    },
    {
      streamId: 3,
      streamName: "Medicine and Allied Sciences",
    },
    {
      streamId: 4,
      streamName: "Law",
    },
    {
      streamId: 5,
      streamName: "Animation and Design",
    },
    {
      streamId: 6,
      streamName: "Media and Mass Communication and Journalism",
    },
    {
      streamId: 7,
      streamName: "Arts, Humanities and Social Sciences",
    },
    {
      streamId: 8,
      streamName: "Sciences",
    },
    {
      streamId: 9,
      streamName: "Commerce",
    },
    {
      streamId: 10,
      streamName: "Computer Application and IT",
    },
    {
      streamId: 11,
      streamName: "Pharmacy",
    },
    {
      streamId: 12,
      streamName: "Education",
    },
    {
      streamId: 13,
      streamName: "Hospitality and Tourism",
    },
    {
      streamId: 14,
      streamName: "Architecture",
    },
  ],
  lookingForCourse: [
    {
      courseId: 3,
      courseName: "aerospace-engineering",
    },
    {
      courseId: 4,
      courseName: "mechanical-engineering",
    },
    {
      courseId: 5,
      courseName: "electrical-engineering",
    },
    {
      courseId: 6,
      courseName: "financial-accounting",
    },
    {
      courseId: 11,
      courseName: "automation-and-robotics",
    },
    {
      courseId: 12,
      courseName: "automobile-engineering",
    },
    {
      courseId: 45,
      courseName: "computer-science-engineering",
    },
    {
      courseId: 46,
      courseName: "it-engineering",
    },
    {
      courseId: 47,
      courseName: "mechanical-engineering2",
    },
    {
      courseId: 48,
      courseName: "computing-engineering",
    },
    {
      courseId: 49,
      courseName: "automobile-engineering",
    },
    {
      courseId: 50,
      courseName: "electrical-and-electronics-engineering",
    },
    {
      courseId: 51,
      courseName: "electronics-and-communication-engineering",
    },
    {
      courseId: 52,
      courseName: "anatomy",
    },
    {
      courseId: 53,
      courseName: "banking",
    },
    {
      courseId: 54,
      courseName: "architecture-engineering",
    },
    {
      courseId: 55,
      courseName: "human-resource-management",
    },
    {
      courseId: 56,
      courseName: "mechatronics-engineering",
    },
    {
      courseId: 57,
      courseName: "law",
    },
    {
      courseId: 58,
      courseName: "chemical-engineering",
    },
    {
      courseId: 59,
      courseName: "dentistry",
    },
    {
      courseId: 60,
      courseName: "radiology",
    },
    {
      courseId: 61,
      courseName: "marketing-management",
    },
    {
      courseId: 62,
      courseName: "ayurveda",
    },
    {
      courseId: 63,
      courseName: "operations-management",
    },
    {
      courseId: 64,
      courseName: "general-medicine-and-surgery",
    },
    {
      courseId: 65,
      courseName: "otolaryngology-ear,-nose-and-throat",
    },
    {
      courseId: 66,
      courseName: "endocrinology",
    },
    {
      courseId: 67,
      courseName: "physiotherapy",
    },
    {
      courseId: 68,
      courseName: "aviation-management",
    },
    {
      courseId: 69,
      courseName: "industrial-design",
    },
    {
      courseId: 70,
      courseName: "homeopathy",
    },
    {
      courseId: 71,
      courseName: "automotive-engineering",
    },
    {
      courseId: 72,
      courseName: "rheumatology",
    },
    {
      courseId: 73,
      courseName: "petroleum-engineering",
    },
    {
      courseId: 74,
      courseName: "sales",
    },
    {
      courseId: 75,
      courseName: "agricultural-and-food-engineering",
    },
    {
      courseId: 76,
      courseName: "tourism",
    },
    {
      courseId: 77,
      courseName: "aircraft-manufacturing-and-maintenance-engineering",
    },
    {
      courseId: 78,
      courseName: "ophthalmology",
    },
    {
      courseId: 79,
      courseName: "labour-law",
    },
    {
      courseId: 80,
      courseName: "general-management",
    },
    {
      courseId: 81,
      courseName: "commercial-law",
    },
    {
      courseId: 82,
      courseName: "oncology",
    },
    {
      courseId: 83,
      courseName: "petrochemical-engineering",
    },
    {
      courseId: 84,
      courseName: "business-analytics",
    },
    {
      courseId: 85,
      courseName: "media-management",
    },
    {
      courseId: 86,
      courseName: "cyber-law",
    },
    {
      courseId: 87,
      courseName: "nuclear-engineering",
    },
    {
      courseId: 88,
      courseName: "urology",
    },
    {
      courseId: 89,
      courseName: "agricultural-and-irrigation-engineering",
    },
    {
      courseId: 90,
      courseName: "construction-engineering",
    },
    {
      courseId: 91,
      courseName: "agricultural-engineering",
    },
    {
      courseId: 92,
      courseName: "civil-engineering",
    },
    {
      courseId: 93,
      courseName: "instrumentation-engineering",
    },
    {
      courseId: 94,
      courseName: "electronics-engineering",
    },
    {
      courseId: 95,
      courseName: "genetic-engineering",
    },
    {
      courseId: 96,
      courseName: "textile-engineering",
    },
    {
      courseId: 97,
      courseName: "physiology",
    },
    {
      courseId: 98,
      courseName: "environmental-engineering",
    },
    {
      courseId: 99,
      courseName: "dairy-technology",
    },
    {
      courseId: 100,
      courseName: "pathology",
    },
    {
      courseId: 101,
      courseName: "industrial-marketing",
    },
    {
      courseId: 102,
      courseName: "metallurgical-engineering",
    },
    {
      courseId: 103,
      courseName: "mining-engineering",
    },
    {
      courseId: 104,
      courseName: "logistics-and-supply-chain-management",
    },
    {
      courseId: 105,
      courseName: "energy-management",
    },
    {
      courseId: 106,
      courseName: "agriculture-business-management",
    },
    {
      courseId: 107,
      courseName: "apparel-management",
    },
    {
      courseId: 108,
      courseName: "water-management",
    },
    {
      courseId: 109,
      courseName: "disaster-management",
    },
    {
      courseId: 110,
      courseName: "industrial-and-production-engineering",
    },
    {
      courseId: 111,
      courseName: "financial-services",
    },
    {
      courseId: 112,
      courseName: "mechanical-and-automation-engineering",
    },
    {
      courseId: 113,
      courseName: "corporate-management",
    },
    {
      courseId: 114,
      courseName: "film-making",
    },
    {
      courseId: 115,
      courseName: "mechanical-and-industrial-engineering",
    },
    {
      courseId: 116,
      courseName: "technology-management",
    },
    {
      courseId: 117,
      courseName: "tool-engineering",
    },
    {
      courseId: 118,
      courseName: "product-management",
    },
    {
      courseId: 119,
      courseName: "bio-engineering",
    },
    {
      courseId: 120,
      courseName: "rural-management",
    },
    {
      courseId: 121,
      courseName: "geotechnical-engineering",
    },
    {
      courseId: 122,
      courseName: "mass-communication",
    },
    {
      courseId: 123,
      courseName: "polymer-engineering",
    },
    {
      courseId: 124,
      courseName: "hospital-administration",
    },
    {
      courseId: 125,
      courseName: "geo-informatics",
    },
    {
      courseId: 126,
      courseName: "mathematics-and-computing",
    },
    {
      courseId: 127,
      courseName: "health-care-management",
    },
    {
      courseId: 128,
      courseName: "materials-management",
    },
    {
      courseId: 129,
      courseName: "industrial-biotechnology",
    },
    {
      courseId: 130,
      courseName: "safety-and-occupational-health-engineering",
    },
    {
      courseId: 131,
      courseName: "product-design",
    },
    {
      courseId: 132,
      courseName: "telecom-management",
    },
    {
      courseId: 133,
      courseName: "computer-science",
    },
    {
      courseId: 134,
      courseName: "textile-management",
    },
    {
      courseId: 135,
      courseName: "mechanical-automobile",
    },
    {
      courseId: 136,
      courseName: "environment-management",
    },
    {
      courseId: 137,
      courseName: "retail-management",
    },
    {
      courseId: 138,
      courseName: "nano-technology-and-robotics",
    },
    {
      courseId: 139,
      courseName: "power-management",
    },
    {
      courseId: 140,
      courseName: "mechanical-production-engineering",
    },
    {
      courseId: 141,
      courseName: "ceramic-engineering",
    },
    {
      courseId: 142,
      courseName: "forestry",
    },
    {
      courseId: 143,
      courseName: "mechanical-engineering---design-and-manufacturing",
    },
    {
      courseId: 144,
      courseName: "infrastructure-management",
    },
    {
      courseId: 145,
      courseName: "strategic-management",
    },
    {
      courseId: 146,
      courseName: "petroleum-management",
    },
    {
      courseId: 147,
      courseName: "public-relations",
    },
    {
      courseId: 148,
      courseName: "leather-technology",
    },
    {
      courseId: 149,
      courseName: "production-engineering-and-management",
    },
    {
      courseId: 150,
      courseName: "digital-media",
    },
    {
      courseId: 151,
      courseName: "metallurgical-and-materials-engineering",
    },
    {
      courseId: 152,
      courseName: "chemistry",
    },
    {
      courseId: 153,
      courseName: "biomedical-engineering",
    },
    {
      courseId: 154,
      courseName: "food-processing",
    },
    {
      courseId: 155,
      courseName: "nano-technology-engineering",
    },
    {
      courseId: 156,
      courseName: "pharmaceutical-technology",
    },
    {
      courseId: 157,
      courseName: "engineering-physics",
    },
    {
      courseId: 158,
      courseName: "textile-technology",
    },
    {
      courseId: 159,
      courseName: "naval-architecture",
    },
    {
      courseId: 160,
      courseName: "mineral-engineering",
    },
    {
      courseId: 161,
      courseName: "political-science",
    },
    {
      courseId: 162,
      courseName: "pharmacy",
    },
    {
      courseId: 163,
      courseName: "psychology",
    },
    {
      courseId: 164,
      courseName: "journalism",
    },
    {
      courseId: 165,
      courseName: "fashion-design",
    },
    {
      courseId: 166,
      courseName: "biotechnology",
    },
    {
      courseId: 167,
      courseName: "zoology",
    },
    {
      courseId: 168,
      courseName: "fine-arts",
    },
    {
      courseId: 169,
      courseName: "production-engineering",
    },
    {
      courseId: 170,
      courseName: "safety-and-fire-engineering",
    },
    {
      courseId: 171,
      courseName: "business-design-and-innovation-management",
    },
    {
      courseId: 172,
      courseName: "thermal-engineering",
    },
    {
      courseId: 173,
      courseName: "english",
    },
    {
      courseId: 174,
      courseName: "insurance-law",
    },
    {
      courseId: 175,
      courseName: "alternative-medicine",
    },
    {
      courseId: 176,
      courseName: "applied-mechanics",
    },
    {
      courseId: 177,
      courseName: "cyber-defense",
    },
    {
      courseId: 178,
      courseName: "sculpture",
    },
    {
      courseId: 179,
      courseName: "apparel-design",
    },
    {
      courseId: 180,
      courseName: "solid-mechanics",
    },
    {
      courseId: 181,
      courseName: "automotive-electronics",
    },
    {
      courseId: 182,
      courseName: "immigration-law",
    },
    {
      courseId: 183,
      courseName: "construction-technology",
    },
    {
      courseId: 184,
      courseName: "smart-infrastructure",
    },
    {
      courseId: 185,
      courseName: "data-management",
    },
    {
      courseId: 186,
      courseName: "control-system-engineering",
    },
    {
      courseId: 187,
      courseName: "decorative-arts",
    },
    {
      courseId: 188,
      courseName: "genome-engineering",
    },
    {
      courseId: 189,
      courseName: "theatre",
    },
    {
      courseId: 190,
      courseName: "cloud-technology",
    },
    {
      courseId: 191,
      courseName: "intellectual-property-rights",
    },
    {
      courseId: 192,
      courseName: "game-technology",
    },
    {
      courseId: 193,
      courseName: "energy-law",
    },
    {
      courseId: 194,
      courseName: "information-design",
    },
    {
      courseId: 195,
      courseName: "intelligent-systems",
    },
    {
      courseId: 196,
      courseName: "enterprise-mobility",
    },
    {
      courseId: 197,
      courseName: "computational-sustainability",
    },
    {
      courseId: 198,
      courseName: "embedded-systems",
    },
    {
      courseId: 199,
      courseName: "infrastructure-development",
    },
    {
      courseId: 200,
      courseName: "interior-architecture-and-design",
    },
    {
      courseId: 201,
      courseName: "heritage-management",
    },
    {
      courseId: 202,
      courseName: "fashion-business-management",
    },
    {
      courseId: 203,
      courseName: "information-security",
    },
    {
      courseId: 204,
      courseName: "android-application-development",
    },
    {
      courseId: 205,
      courseName: "fluid-mechanics",
    },
    {
      courseId: 206,
      courseName: "fashion-communication",
    },
    {
      courseId: 207,
      courseName: "upstream",
    },
    {
      courseId: 208,
      courseName: "augmented-and-virtual-reality",
    },
    {
      courseId: 209,
      courseName: "financial-modelling",
    },
    {
      courseId: 210,
      courseName: "cost-accountancy",
    },
    {
      courseId: 211,
      courseName: "paediatrics",
    },
    {
      courseId: 212,
      courseName: "industrial-relations-and-labour-legislation",
    },
    {
      courseId: 213,
      courseName: "deep-learning",
    },
    {
      courseId: 214,
      courseName: "international-trade-law",
    },
    {
      courseId: 215,
      courseName: "rural-studies",
    },
    {
      courseId: 216,
      courseName: "cardiology-laboratory-techniques",
    },
    {
      courseId: 217,
      courseName: "radio-diagnosis",
    },
    {
      courseId: 218,
      courseName: "event-management",
    },
    {
      courseId: 219,
      courseName: "community-health-administration",
    },
    {
      courseId: 220,
      courseName: "managerial-economics",
    },
    {
      courseId: 221,
      courseName: "system-design",
    },
    {
      courseId: 222,
      courseName: "criminal-law",
    },
    {
      courseId: 223,
      courseName: "tally",
    },
    {
      courseId: 224,
      courseName: "microbiology",
    },
    {
      courseId: 225,
      courseName: "company-secretary",
    },
    {
      courseId: 226,
      courseName: "bartending",
    },
    {
      courseId: 227,
      courseName: "humanities-and-social-sciences",
    },
    {
      courseId: 228,
      courseName: "business-management-studies",
    },
    {
      courseId: 229,
      courseName: "mobile-app-development",
    },
    {
      courseId: 230,
      courseName: "telecommunication-engineering",
    },
    {
      courseId: 231,
      courseName: "beauty-and-fitness",
    },
    {
      courseId: 232,
      courseName: "autocad",
    },
    {
      courseId: 233,
      courseName: "sound-engineering",
    },
    {
      courseId: 234,
      courseName: "internet-of-things",
    },
    {
      courseId: 235,
      courseName: "food-technology",
    },
    {
      courseId: 236,
      courseName: "perfusion-technology",
    },
    {
      courseId: 237,
      courseName: "applied-arts",
    },
    {
      courseId: 238,
      courseName: "footwear-design",
    },
    {
      courseId: 239,
      courseName: "networking,-hardware-and-security",
    },
    {
      courseId: 240,
      courseName: "hospitality-and-travel",
    },
    {
      courseId: 241,
      courseName: "massage-and-spa-therapy",
    },
    {
      courseId: 242,
      courseName: "modelling",
    },
    {
      courseId: 243,
      courseName: "visual-merchandising",
    },
    {
      courseId: 244,
      courseName: "ethical-hacking",
    },
    {
      courseId: 245,
      courseName: "nutrition-and-dietetics",
    },
    {
      courseId: 246,
      courseName: "exhibition-design",
    },
    {
      courseId: 247,
      courseName: "cyber-security-ethical-hacking",
    },
    {
      courseId: 248,
      courseName: "echo-cardiology-and-cardiac-technology",
    },
    {
      courseId: 249,
      courseName: "vocational-education",
    },
    {
      courseId: 250,
      courseName: "ui-ux",
    },
    {
      courseId: 251,
      courseName: "food-and-beverage-service",
    },
    {
      courseId: 252,
      courseName: "bakery-and-confectionery",
    },
    {
      courseId: 253,
      courseName: "food-nutrition",
    },
    {
      courseId: 254,
      courseName: "ai-and-robotics",
    },
    {
      courseId: 255,
      courseName: "medical-transcription",
    },
    {
      courseId: 256,
      courseName: "mobile-repairing",
    },
    {
      courseId: 257,
      courseName: "film-and-video-design",
    },
    {
      courseId: 258,
      courseName: "liberal-arts",
    },
    {
      courseId: 259,
      courseName: "cardiovascular-technology",
    },
    {
      courseId: 260,
      courseName: "painting-and-drawing",
    },
    {
      courseId: 261,
      courseName: "visual-arts",
    },
    {
      courseId: 262,
      courseName: "doeacc-o-level",
    },
    {
      courseId: 263,
      courseName: "clinical-psychology",
    },
    {
      courseId: 264,
      courseName: "transport-logistics",
    },
    {
      courseId: 265,
      courseName: "company-law",
    },
    {
      courseId: 266,
      courseName: "family-law",
    },
    {
      courseId: 267,
      courseName: "dance-and-choreography",
    },
    {
      courseId: 268,
      courseName: "engineering-design",
    },
    {
      courseId: 269,
      courseName: "accounting-management",
    },
    {
      courseId: 270,
      courseName: "clinical-pathology",
    },
    {
      courseId: 271,
      courseName: "architecture,-town-and-regional-planning",
    },
    {
      courseId: 272,
      courseName: "biopharmaceutics",
    },
    {
      courseId: 273,
      courseName: "competition-law",
    },
    {
      courseId: 274,
      courseName: "communication-engineering",
    },
    {
      courseId: 275,
      courseName: "ceramic-design",
    },
    {
      courseId: 276,
      courseName: "clinical-toxicology",
    },
    {
      courseId: 277,
      courseName: "applied-geophysics",
    },
    {
      courseId: 278,
      courseName: "civil-law",
    },
    {
      courseId: 279,
      courseName: "artificial-intelligence",
    },
    {
      courseId: 280,
      courseName: "chemical-science-and-technology",
    },
    {
      courseId: 281,
      courseName: "consumer-behaviour",
    },
    {
      courseId: 282,
      courseName: "ayurvedic-medicine-and-surgery",
    },
    {
      courseId: 283,
      courseName: "knitwear-design",
    },
    {
      courseId: 284,
      courseName: "communication-design",
    },
    {
      courseId: 285,
      courseName: "medical-laboratory-technology",
    },
    {
      courseId: 286,
      courseName: "glass-design",
    },
    {
      courseId: 287,
      courseName: "consumer-law",
    },
    {
      courseId: 288,
      courseName: "communication-management",
    },
    {
      courseId: 289,
      courseName: "computer-aided-design",
    },
    {
      courseId: 290,
      courseName: "furniture-design",
    },
    {
      courseId: 291,
      courseName: "structural-engineering",
    },
    {
      courseId: 292,
      courseName: "digital-marketing",
    },
    {
      courseId: 293,
      courseName: "public-policy",
    },
    {
      courseId: 294,
      courseName: "management",
    },
    {
      courseId: 295,
      courseName: "automotive-design",
    },
    {
      courseId: 296,
      courseName: "microbiology-and-immunology-engineering",
    },
    {
      courseId: 297,
      courseName: "astrology",
    },
    {
      courseId: 298,
      courseName: "language",
    },
    {
      courseId: 299,
      courseName: "power-electronics",
    },
    {
      courseId: 300,
      courseName: "medical-radiography",
    },
    {
      courseId: 301,
      courseName: "hospitality",
    },
    {
      courseId: 302,
      courseName: "e-commerce",
    },
    {
      courseId: 303,
      courseName: "aircraft-maintenance-engineering",
    },
    {
      courseId: 304,
      courseName: "archaeology",
    },
    {
      courseId: 305,
      courseName: "robotics-engineering",
    },
    {
      courseId: 306,
      courseName: "cost-management-accounting",
    },
    {
      courseId: 307,
      courseName: "cloud-computing",
    },
    {
      courseId: 308,
      courseName: "export-import-management",
    },
    {
      courseId: 309,
      courseName: "film-acting",
    },
    {
      courseId: 310,
      courseName: "foreign-trade",
    },
    {
      courseId: 311,
      courseName: "earthquake-engineering",
    },
    {
      courseId: 312,
      courseName: "business-law",
    },
    {
      courseId: 313,
      courseName: "ent",
    },
    {
      courseId: 314,
      courseName: "pilot-training",
    },
    {
      courseId: 315,
      courseName: "avionics",
    },
    {
      courseId: 316,
      courseName: "medical-operation-theatre-technology",
    },
    {
      courseId: 317,
      courseName: "culinary-arts",
    },
    {
      courseId: 318,
      courseName: "dental-materials",
    },
    {
      courseId: 319,
      courseName: "neonatology",
    },
    {
      courseId: 320,
      courseName: "pharmaceutical-engineering-and-technology",
    },
    {
      courseId: 321,
      courseName: "finance",
    },
    {
      courseId: 322,
      courseName: "system-analysis",
    },
    {
      courseId: 323,
      courseName: "banking-law",
    },
    {
      courseId: 324,
      courseName: "electronics-and-instrumentation-engineering",
    },
    {
      courseId: 325,
      courseName: "cyber-security",
    },
    {
      courseId: 326,
      courseName: "thermal-science-and-engineering",
    },
    {
      courseId: 327,
      courseName: "electronics-and-electrical-communication-engineering",
    },
    {
      courseId: 328,
      courseName: "space-technology",
    },
    {
      courseId: 329,
      courseName: "catering",
    },
    {
      courseId: 330,
      courseName: "data-science",
    },
    {
      courseId: 331,
      courseName: "software-engineering",
    },
    {
      courseId: 332,
      courseName: "medical-record-and-health-information-management",
    },
    {
      courseId: 333,
      courseName: "naval-architecture-and-ocean-engineering",
    },
    {
      courseId: 334,
      courseName: "medical-imaging",
    },
    {
      courseId: 335,
      courseName: "vlsi-design",
    },
    {
      courseId: 336,
      courseName: "machine-learning",
    },
    {
      courseId: 337,
      courseName: "visual-effects",
    },
    {
      courseId: 338,
      courseName: "manufacturing-science-and-engineering",
    },
    {
      courseId: 339,
      courseName: "radiotherapy",
    },
    {
      courseId: 340,
      courseName: "business-administration",
    },
    {
      courseId: 341,
      courseName: "operation-theatre-techniques",
    },
    {
      courseId: 342,
      courseName: "video-editing",
    },
    {
      courseId: 343,
      courseName: "astronomy",
    },
    {
      courseId: 344,
      courseName: "power-engineering",
    },
    {
      courseId: 345,
      courseName: "marine-biology",
    },
    {
      courseId: 346,
      courseName: "library-and-information-science",
    },
    {
      courseId: 347,
      courseName: "religious-studies",
    },
    {
      courseId: 348,
      courseName: "microelectronics",
    },
    {
      courseId: 349,
      courseName: "furniture-and-interior-design",
    },
    {
      courseId: 350,
      courseName: "linguistics",
    },
    {
      courseId: 351,
      courseName: "data-and-computational-science",
    },
    {
      courseId: 352,
      courseName: "literature",
    },
    {
      courseId: 353,
      courseName: "anaesthesiology",
    },
    {
      courseId: 354,
      courseName: "engineering",
    },
    {
      courseId: 355,
      courseName: "rf-and-microwave-engineering",
    },
    {
      courseId: 356,
      courseName: "instrumentation-and-control-engineering",
    },
    {
      courseId: 357,
      courseName: "applied-geology",
    },
    {
      courseId: 358,
      courseName: "electronics-and-telecommunications-engineering",
    },
    {
      courseId: 359,
      courseName: "business-economics",
    },
    {
      courseId: 360,
      courseName: "astrophysics",
    },
    {
      courseId: 361,
      courseName: "community-medicine",
    },
    {
      courseId: 362,
      courseName: "advanced-manufacturing",
    },
    {
      courseId: 363,
      courseName: "applied-pharmacology",
    },
    {
      courseId: 364,
      courseName: "game-design",
    },
    {
      courseId: 365,
      courseName: "optometry",
    },
    {
      courseId: 366,
      courseName: "financial-management",
    },
    {
      courseId: 367,
      courseName: "cardiology",
    },
    {
      courseId: 368,
      courseName: "sales-and-marketing",
    },
    {
      courseId: 369,
      courseName: "occupational-therapy",
    },
    {
      courseId: 370,
      courseName: "entrepreneurship",
    },
    {
      courseId: 371,
      courseName: "banking-and-insurance",
    },
    {
      courseId: 372,
      courseName: "interior-design",
    },
    {
      courseId: 373,
      courseName: "animation",
    },
    {
      courseId: 374,
      courseName: "transportation-engineering",
    },
    {
      courseId: 375,
      courseName: "graphic-designing",
    },
    {
      courseId: 376,
      courseName: "construction-management",
    },
    {
      courseId: 377,
      courseName: "mechanics",
    },
    {
      courseId: 378,
      courseName: "family-business-management",
    },
    {
      courseId: 379,
      courseName: "pharmaceutical-management",
    },
    {
      courseId: 380,
      courseName: "hotel-management",
    },
    {
      courseId: 381,
      courseName: "marine-engineering",
    },
    {
      courseId: 382,
      courseName: "information-technology",
    },
    {
      courseId: 383,
      courseName: "international-business-communication",
    },
    {
      courseId: 384,
      courseName: "it-and-systems-management",
    },
    {
      courseId: 385,
      courseName: "textile-chemistry",
    },
    {
      courseId: 386,
      courseName: "plastic-engineering",
    },
    {
      courseId: 387,
      courseName: "risk-management",
    },
    {
      courseId: 388,
      courseName: "systems-science-engineering",
    },
    {
      courseId: 389,
      courseName: "mining-machinery-engineering",
    },
    {
      courseId: 390,
      courseName: "engineering-chemistry",
    },
    {
      courseId: 391,
      courseName: "printing-graphics-and-packaging",
    },
    {
      courseId: 392,
      courseName: "manufacturing-process-and-automation-engineering",
    },
    {
      courseId: 393,
      courseName: "paint-technology",
    },
    {
      courseId: 394,
      courseName: "man-made-fiber-technology",
    },
    {
      courseId: 395,
      courseName: "systems-management",
    },
    {
      courseId: 396,
      courseName: "planning-engineering",
    },
    {
      courseId: 397,
      courseName: "industrial-engineering",
    },
    {
      courseId: 398,
      courseName: "web-designing",
    },
    {
      courseId: 399,
      courseName: "geology",
    },
    {
      courseId: 400,
      courseName: "jute-and-fiber-technology",
    },
    {
      courseId: 401,
      courseName: "pharmaceutics",
    },
    {
      courseId: 402,
      courseName: "precision-manufacturing",
    },
    {
      courseId: 403,
      courseName: "pharmaceutical-biotechnology",
    },
    {
      courseId: 404,
      courseName: "pharmaceutical-analysis",
    },
    {
      courseId: 405,
      courseName: "sugar-technology",
    },
    {
      courseId: 406,
      courseName: "carpet-and-textile-technology",
    },
    {
      courseId: 407,
      courseName: "printing-and-packaging-technology",
    },
    {
      courseId: 408,
      courseName: "organisational-behaviour",
    },
    {
      courseId: 409,
      courseName: "rural-marketing",
    },
    {
      courseId: 410,
      courseName: "rubber-technology",
    },
    {
      courseId: 411,
      courseName: "nephrology",
    },
    {
      courseId: 412,
      courseName: "service-management",
    },
    {
      courseId: 413,
      courseName: "textile-production",
    },
    {
      courseId: 414,
      courseName: "silk-technology",
    },
    {
      courseId: 415,
      courseName: "automobile-marketing",
    },
    {
      courseId: 416,
      courseName: "unani-medicine",
    },
    {
      courseId: 417,
      courseName: "instrumentation-sciences",
    },
    {
      courseId: 418,
      courseName: "oral-surgery",
    },
    {
      courseId: 419,
      courseName: "chartered-accountancy",
    },
    {
      courseId: 420,
      courseName: "acupuncture",
    },
    {
      courseId: 421,
      courseName: "andrology",
    },
    {
      courseId: 422,
      courseName: "acting",
    },
    {
      courseId: 423,
      courseName: "audiology-and-speech",
    },
    {
      courseId: 424,
      courseName: "microbiology-and-virology",
    },
    {
      courseId: 425,
      courseName: "cosmeceutics",
    },
    {
      courseId: 426,
      courseName: "cardiology-and-thoracic-science",
    },
    {
      courseId: 427,
      courseName: "arabic",
    },
    {
      courseId: 428,
      courseName: "veterinary-science",
    },
    {
      courseId: 429,
      courseName: "allergy-and-immunology",
    },
    {
      courseId: 430,
      courseName: "agricultural-science",
    },
    {
      courseId: 431,
      courseName: "anesthesiology-and-pallative-medicine",
    },
    {
      courseId: 432,
      courseName: "commerce",
    },
    {
      courseId: 433,
      courseName: "comparative-law",
    },
    {
      courseId: 434,
      courseName: "community-health-nursing",
    },
    {
      courseId: 435,
      courseName: "clinical-epidemiology",
    },
    {
      courseId: 436,
      courseName: "community-medicine-and-public-health",
    },
    {
      courseId: 437,
      courseName: "communication",
    },
    {
      courseId: 438,
      courseName: "cinematography",
    },
    {
      courseId: 439,
      courseName: "clinical-research",
    },
    {
      courseId: 440,
      courseName: "clinical-embryology",
    },
    {
      courseId: 441,
      courseName: "cosmetics-technology",
    },
    {
      courseId: 442,
      courseName: "computer-management",
    },
    {
      courseId: 443,
      courseName: "cosmetic-dentistry",
    },
    {
      courseId: 444,
      courseName: "customer-relationship-management",
    },
    {
      courseId: 445,
      courseName: "creative-writing",
    },
    {
      courseId: 446,
      courseName: "computer-applications",
    },
    {
      courseId: 447,
      courseName: "conservative-dentistry-and-endodontics",
    },
    {
      courseId: 448,
      courseName: "direction-and-screenplay-writing",
    },
    {
      courseId: 449,
      courseName: "dental-radiography",
    },
    {
      courseId: 450,
      courseName: "diabetology",
    },
    {
      courseId: 451,
      courseName: "corporate-secretaryship",
    },
    {
      courseId: 452,
      courseName: "critical-care-nursing",
    },
    {
      courseId: 453,
      courseName: "corporate-law",
    },
    {
      courseId: 454,
      courseName: "family-medicine",
    },
    {
      courseId: 455,
      courseName: "dermatology-and-venereology",
    },
    {
      courseId: 456,
      courseName: "earth-science",
    },
    {
      courseId: 457,
      courseName: "engineering-science",
    },
    {
      courseId: 458,
      courseName: "digital-governance",
    },
    {
      courseId: 459,
      courseName: "environmental-law",
    },
    {
      courseId: 460,
      courseName: "engineering-management",
    },
    {
      courseId: 461,
      courseName: "development-studies",
    },
    {
      courseId: 462,
      courseName: "energy-engineering",
    },
    {
      courseId: 463,
      courseName: "electronics-sciences",
    },
    {
      courseId: 464,
      courseName: "design",
    },
    {
      courseId: 465,
      courseName: "economics",
    },
    {
      courseId: 466,
      courseName: "computer-science-engineering",
    },
    {
      courseId: 467,
      courseName: "environmental-studies",
    },
    {
      courseId: 468,
      courseName: "education",
    },
    {
      courseId: 469,
      courseName: "gemology",
    },
    {
      courseId: 470,
      courseName: "general-nursing-and-midwifery",
    },
    {
      courseId: 471,
      courseName: "haematology",
    },
    {
      courseId: 472,
      courseName: "gynaecology-and-obstetrics",
    },
    {
      courseId: 473,
      courseName: "genetics",
    },
    {
      courseId: 474,
      courseName: "general-surgery",
    },
    {
      courseId: 475,
      courseName: "intensive-care-medicine",
    },
    {
      courseId: 476,
      courseName: "internal-medicine",
    },
    {
      courseId: 477,
      courseName: "health-informatics",
    },
    {
      courseId: 478,
      courseName: "german",
    },
    {
      courseId: 479,
      courseName: "military-medicine",
    },
    {
      courseId: 480,
      courseName: "industrial-pharmacy",
    },
    {
      courseId: 481,
      courseName: "hospitality-management",
    },
    {
      courseId: 482,
      courseName: "home-science",
    },
    {
      courseId: 483,
      courseName: "jewellery-design",
    },
    {
      courseId: 484,
      courseName: "hindi",
    },
    {
      courseId: 485,
      courseName: "medical-surgical-nursing",
    },
    {
      courseId: 486,
      courseName: "ip-and-technology-law",
    },
    {
      courseId: 487,
      courseName: "insurance",
    },
    {
      courseId: 488,
      courseName: "italian",
    },
    {
      courseId: 489,
      courseName: "korean",
    },
    {
      courseId: 490,
      courseName: "horticulture",
    },
    {
      courseId: 491,
      courseName: "information-science",
    },
    {
      courseId: 492,
      courseName: "international-business",
    },
    {
      courseId: 493,
      courseName: "japanese",
    },
    {
      courseId: 494,
      courseName: "music",
    },
    {
      courseId: 495,
      courseName: "nuclear-medicine",
    },
    {
      courseId: 496,
      courseName: "library-science",
    },
    {
      courseId: 497,
      courseName: "oncology-nursing",
    },
    {
      courseId: 498,
      courseName: "medical-biotechnology",
    },
    {
      courseId: 499,
      courseName: "naturopathy",
    },
    {
      courseId: 500,
      courseName: "nautical-science",
    },
    {
      courseId: 501,
      courseName: "ngo-management",
    },
    {
      courseId: 502,
      courseName: "oncology-and-radiotherapy",
    },
    {
      courseId: 503,
      courseName: "ortho-and-rehabilitation-nursing",
    },
    {
      courseId: 504,
      courseName: "neurology",
    },
    {
      courseId: 505,
      courseName: "life-science",
    },
    {
      courseId: 506,
      courseName: "paediatric-surgery",
    },
    {
      courseId: 507,
      courseName: "oral-pathology",
    },
    {
      courseId: 508,
      courseName: "paediatric-nursing",
    },
    {
      courseId: 509,
      courseName: "paedodontics-and-preventive-dentistry",
    },
    {
      courseId: 510,
      courseName: "oil-and-gas-management",
    },
    {
      courseId: 511,
      courseName: "mathematics",
    },
    {
      courseId: 512,
      courseName: "orthopaedics",
    },
    {
      courseId: 513,
      courseName: "paediatrics-and-child-health",
    },
    {
      courseId: 514,
      courseName: "orthodontics",
    },
    {
      courseId: 515,
      courseName: "nursing",
    },
    {
      courseId: 516,
      courseName: "performance-arts",
    },
    {
      courseId: 517,
      courseName: "periodontics",
    },
    {
      courseId: 518,
      courseName: "physical-medicine-and-rehabilitation",
    },
    {
      courseId: 519,
      courseName: "pharmaceutical-quality-assurance",
    },
    {
      courseId: 520,
      courseName: "portuguese",
    },
    {
      courseId: 521,
      courseName: "pharmacognosy",
    },
    {
      courseId: 522,
      courseName: "polymer-science",
    },
    {
      courseId: 523,
      courseName: "photography",
    },
    {
      courseId: 524,
      courseName: "production-and-operation-management",
    },
    {
      courseId: 525,
      courseName: "podiatry",
    },
    {
      courseId: 526,
      courseName: "physical-education",
    },
    {
      courseId: 527,
      courseName: "physician-assistant",
    },
    {
      courseId: 528,
      courseName: "pharmacogenetics-and-drug-development",
    },
    {
      courseId: 529,
      courseName: "preventative-medicine",
    },
    {
      courseId: 530,
      courseName: "project-management",
    },
    {
      courseId: 531,
      courseName: "pharmacology-and-toxicology",
    },
    {
      courseId: 532,
      courseName: "plastic-surgery",
    },
    {
      courseId: 533,
      courseName: "regenerative-medicine",
    },
    {
      courseId: 534,
      courseName: "psychiatric-and-mental-health-nursing",
    },
    {
      courseId: 535,
      courseName: "public-health-dentistry",
    },
    {
      courseId: 536,
      courseName: "punjabi",
    },
    {
      courseId: 537,
      courseName: "psychiatry",
    },
    {
      courseId: 538,
      courseName: "public-administation",
    },
    {
      courseId: 539,
      courseName: "seismology",
    },
    {
      courseId: 540,
      courseName: "sanskrit",
    },
    {
      courseId: 541,
      courseName: "renewable-energy",
    },
    {
      courseId: 542,
      courseName: "transplantation-medicine-and-surgery",
    },
    {
      courseId: 543,
      courseName: "space-medicine",
    },
    {
      courseId: 544,
      courseName: "russian",
    },
    {
      courseId: 545,
      courseName: "transfusion-medicine",
    },
    {
      courseId: 546,
      courseName: "social-sciences",
    },
    {
      courseId: 547,
      courseName: "spanish",
    },
    {
      courseId: 548,
      courseName: "strategic-design-and-brand-leadership",
    },
    {
      courseId: 549,
      courseName: "sound-recording-and-sound-design",
    },
    {
      courseId: 550,
      courseName: "tb-and-chest-diseases",
    },
    {
      courseId: 551,
      courseName: "sports-medicine",
    },
    {
      courseId: 552,
      courseName: "visual-media",
    },
    {
      courseId: 553,
      courseName: "urdu",
    },
    {
      courseId: 554,
      courseName: "vascular-surgery",
    },
    {
      courseId: 555,
      courseName: "urban-management",
    },
    {
      courseId: 556,
      courseName: "sociology",
    },
    {
      courseId: 557,
      courseName: "visual-communication",
    },
    {
      courseId: 558,
      courseName: "sports-management",
    },
    {
      courseId: 559,
      courseName: "theology",
    },
    {
      courseId: 560,
      courseName: "social-work",
    },
    {
      courseId: 561,
      courseName: "travel-and-tourism",
    },
    {
      courseId: 562,
      courseName: "tropical-medicine",
    },
    {
      courseId: 563,
      courseName: "yoga",
    },
    {
      courseId: 564,
      courseName: "business-ethics-and-corporate-social-responsibility",
    },
    {
      courseId: 565,
      courseName: "taxation-law",
    },
    {
      courseId: 566,
      courseName: "agriculture-and-food-business",
    },
    {
      courseId: 567,
      courseName: "wilderness-medicine",
    },
    {
      courseId: 568,
      courseName: "ultrasonography",
    },
    {
      courseId: 569,
      courseName: "biochemical-engineering",
    },
    {
      courseId: 570,
      courseName: "demography",
    },
    {
      courseId: 571,
      courseName: "actuarial-science",
    },
    {
      courseId: 572,
      courseName: "biotechnology-engineering",
    },
    {
      courseId: 573,
      courseName: "urban-planning-and-design",
    },
    {
      courseId: 574,
      courseName: "pharmaceutical-regulatory-affairs",
    },
    {
      courseId: 575,
      courseName: "oil-and-paint-technology",
    },
    {
      courseId: 576,
      courseName: "manufacturing-engineering",
    },
    {
      courseId: 577,
      courseName: "pulp-and-paper-technology",
    },
    {
      courseId: 578,
      courseName: "printing-technology",
    },
    {
      courseId: 579,
      courseName: "philosophy",
    },
    {
      courseId: 580,
      courseName: "anthropology",
    },
    {
      courseId: 581,
      courseName: "sciences",
    },
    {
      courseId: 582,
      courseName: "botany",
    },
    {
      courseId: 583,
      courseName: "physics",
    },
    {
      courseId: 584,
      courseName: "advertising",
    },
    {
      courseId: 585,
      courseName: "history",
    },
    {
      courseId: 586,
      courseName: "auxiliary-nurse-and-midwifery",
    },
    {
      courseId: 587,
      courseName: "auditing",
    },
    {
      courseId: 588,
      courseName: "bengali",
    },
    {
      courseId: 589,
      courseName: "cardio-thoracic-nursing",
    },
    {
      courseId: 590,
      courseName: "geography",
    },
    {
      courseId: 591,
      courseName: "biomedical-sciences",
    },
    {
      courseId: 592,
      courseName: "big-data-analytics",
    },
    {
      courseId: 593,
      courseName: "criminology-and-forensic-law",
    },
    {
      courseId: 594,
      courseName: "clinical-pharmacy",
    },
    {
      courseId: 595,
      courseName: "bio-physics",
    },
    {
      courseId: 596,
      courseName: "biology",
    },
    {
      courseId: 597,
      courseName: "constitutional-and-administrative-law",
    },
    {
      courseId: 598,
      courseName: "bio-chemistry",
    },
    {
      courseId: 599,
      courseName: "bio-informatics",
    },
    {
      courseId: 600,
      courseName: "dental-anatomy",
    },
    {
      courseId: 601,
      courseName: "forensic-dentistry",
    },
    {
      courseId: 602,
      courseName: "fashion-and-textiles",
    },
    {
      courseId: 603,
      courseName: "forensic-medicine",
    },
    {
      courseId: 604,
      courseName: "film-technology",
    },
    {
      courseId: 605,
      courseName: "french",
    },
    {
      courseId: 606,
      courseName: "foundry-and-forge-technology",
    },
    {
      courseId: 607,
      courseName: "gastroenterology-and-hepatology",
    },
    {
      courseId: 608,
      courseName: "fashion-retail-management",
    },
    {
      courseId: 609,
      courseName: "foreign-language",
    },
    {
      courseId: 610,
      courseName: "fisheries-science",
    },
    {
      courseId: 611,
      courseName: "oral-hygiene",
    },
    {
      courseId: 612,
      courseName: "oral-medicine",
    },
    {
      courseId: 613,
      courseName: "persian",
    },
    {
      courseId: 614,
      courseName: "pharmaceutical-chemistry",
    },
    {
      courseId: 615,
      courseName: "prosthodontics-and-crown-and-bridge",
    },
    {
      courseId: 616,
      courseName: "sleep-medicine",
    },
    {
      courseId: 617,
      courseName: "pulmonology-and-respiratory-medicine",
    },
    {
      courseId: 618,
      courseName: "port-and-shipping-management",
    },
    {
      courseId: 619,
      courseName: "statistics",
    },
    {
      courseId: 620,
      courseName: "sustainable-development",
    },
    {
      courseId: 621,
      courseName: "textile-design",
    },
    {
      courseId: 622,
      courseName: "blockchain",
    },
    {
      courseId: 623,
      courseName: "accessory-designing",
    },
    {
      courseId: 624,
      courseName: "forensic-science",
    },
    {
      courseId: 625,
      courseName: "arts",
    },
    {
      courseId: 626,
      courseName: "materials-science-and-technology",
    },
    {
      courseId: 627,
      courseName: "polymer-technology",
    },
    {
      courseId: 628,
      courseName: "principles-of-management",
    },
    {
      courseId: 630,
      courseName: "business-statistics",
    },
    {
      courseId: 631,
      courseName: "human-capital-management",
    },
    {
      courseId: 638,
      courseName: "Automobile-Engineering",
    },
    {
      courseId: 639,
      courseName: "Electronics-and-Communication-Engineering",
    },
    {
      courseId: 640,
      courseName: "Anatomy",
    },
    {
      courseId: 641,
      courseName: "Banking",
    },
    {
      courseId: 642,
      courseName: "Homeopathy",
    },
    {
      courseId: 643,
      courseName: "Veterinary-Science",
    },
    {
      courseId: 644,
      courseName: "Conservative-Dentistry-and-Endodontics",
    },
    {
      courseId: 645,
      courseName: "Company-Law",
    },
    {
      courseId: 646,
      courseName: "Control-System-Engineering",
    },
    {
      courseId: 647,
      courseName: "Theatre",
    },
    {
      courseId: 648,
      courseName: "Sculpture",
    },
    {
      courseId: 649,
      courseName: "Alternative-Medicine",
    },
    {
      courseId: 650,
      courseName: "Immigration-Law",
    },
    {
      courseId: 651,
      courseName: "Apparel-Design",
    },
    {
      courseId: 652,
      courseName: "Insurance-Law",
    },
    {
      courseId: 653,
      courseName: "Embedded-Systems",
    },
    {
      courseId: 654,
      courseName: "Applied-Mechanics",
    },
    {
      courseId: 655,
      courseName: "Cyber-Defense",
    },
    {
      courseId: 656,
      courseName: "Data-Management",
    },
    {
      courseId: 657,
      courseName: "Upstream",
    },
    {
      courseId: 658,
      courseName: "Automotive-Electronics",
    },
    {
      courseId: 659,
      courseName: "Genome-Engineering",
    },
    {
      courseId: 660,
      courseName: "Fluid-Mechanics",
    },
    {
      courseId: 661,
      courseName: "Solid-Mechanics",
    },
    {
      courseId: 662,
      courseName: "Infrastructure-Development",
    },
    {
      courseId: 663,
      courseName: "Smart-Infrastructure",
    },
    {
      courseId: 664,
      courseName: "Construction-Technology",
    },
    {
      courseId: 665,
      courseName: "Decorative-Arts",
    },
    {
      courseId: 666,
      courseName: "Energy-Law",
    },
    {
      courseId: 667,
      courseName: "Information-Design",
    },
    {
      courseId: 668,
      courseName: "Information-Security",
    },
    {
      courseId: 669,
      courseName: "Cloud-Technology",
    },
    {
      courseId: 670,
      courseName: "Game-Technology",
    },
    {
      courseId: 671,
      courseName: "Computational-Sustainability",
    },
    {
      courseId: 672,
      courseName: "Intellectual-Property-Rights",
    },
    {
      courseId: 673,
      courseName: "Enterprise-Mobility",
    },
    {
      courseId: 674,
      courseName: "Intelligent-Systems",
    },
    {
      courseId: 675,
      courseName: "Augmented-and-Virtual-Reality",
    },
    {
      courseId: 676,
      courseName: "Android-Application-Development",
    },
    {
      courseId: 677,
      courseName: "Heritage-Management",
    },
    {
      courseId: 678,
      courseName: "Fashion-Business-Management",
    },
    {
      courseId: 679,
      courseName: "Fashion-Communication",
    },
    {
      courseId: 680,
      courseName: "Interior-Architecture-and-Design",
    },
    {
      courseId: 681,
      courseName: "Android-Application-Development",
    },
    {
      courseId: 682,
      courseName: "Heritage-Management",
    },
    {
      courseId: 683,
      courseName: "Fashion-Business-Management",
    },
    {
      courseId: 684,
      courseName: "Fashion-Communication",
    },
    {
      courseId: 685,
      courseName: "Interior-Architecture-and-Design",
    },
    {
      courseId: 687,
      courseName: "testCourse",
    },
    {
      courseId: 688,
      courseName: "testCourse1",
    },
    {
      courseId: 689,
      courseName: "TestCourse2",
    },
    {
      courseId: 690,
      courseName: "aeronautical-engineering",
    },
  ],
  states: [
    {
      stateId: 1,
      stateName: "Delhi",
    },
    {
      stateId: 2,
      stateName: "Maharashtra",
    },
    {
      stateId: 3,
      stateName: "Telangana",
    },
    {
      stateId: 4,
      stateName: "Karnataka",
    },
    {
      stateId: 5,
      stateName: "Tamil Nadu",
    },
  ],
  cities: [
    {
      cityId: 1,
      cityName: "New Delhi",
    },
    {
      cityId: 2,
      cityName: "North Delhi",
    },
    {
      cityId: 3,
      cityName: "Mumbai",
    },
    {
      cityId: 4,
      cityName: "Pune",
    },
    {
      cityId: 5,
      cityName: "Hyderabad",
    },
    {
      cityId: 6,
      cityName: "Warangal",
    },
    {
      cityId: 7,
      cityName: "Bengaluru",
    },
    {
      cityId: 8,
      cityName: "Mysuru",
    },
    {
      cityId: 9,
      cityName: "Chennai",
    },
    {
      cityId: 10,
      cityName: "Coimbatore",
    },
  ],
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  user: "",
  mobile: "",
  otpSent: false,
  loading: false,
  error: null,
  message: "",
  pafData: pafData,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string; user: string }>) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    },
  },
  extraReducers(builder) {
    //  send OTP
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      console.log("SendOTP", action.payload);
      state.loading = false;
      state.otpSent = true;
      state.message = action.payload.message;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    //  verify OTP
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.message = action.payload.message;

      // Store tokens in local storage
      if (action.payload.accessToken) {
        localStorage.setItem("accessToken", action.payload.accessToken);
      }
      if (action.payload.refreshToken) {
        localStorage.setItem("refreshToken", action.payload.refreshToken);
      }
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken || state.accessToken;
      state.refreshToken = action.payload.refreshToken || state.refreshToken;
      state.user = action.payload.user || state.user;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // signup after OTP verification
    builder.addCase(signupPAFDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signupPAFDetail.fulfilled, (state, action) => {
      console.log("signupPAFDetail", action);
      state.loading = false;
      state.pafData = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(signupPAFDetail.rejected, (state, action) => {
      state.loading = false;
    });

    // fetch and store all signup-info details (PAF details)
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
