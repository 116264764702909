import React from "react";
import webinarEventsImg1 from "../../../../images/college/details/course-fee/webinar-events-1.jpeg";
import webinarEventsImg2 from "../../../../images/college/details/course-fee/webinar-events-2.jpeg";
import webinarEventsImg3 from "../../../../images/college/details/course-fee/webinar-events-3.jpeg";

const WebinarEvents = () => {
  const webinars = [
    {
      image: webinarEventsImg1,
      title: "WATCH DEMO: ON24 WEBCAST ELITE",
      content:
        "Discover how to create engaging webinar experiences designed to captivate and convert your audience.",
      videoLink: "https://www.youtube.com/watch?v=JGwWNGJdvx8",
    },
    {
      image: webinarEventsImg2,
      title: "Benchmarking Digital KPIs in Life Sciences in 2024",
      content:
        "Discover how to create engaging webinar experiences designed to captivate and convert your audience.",
      videoLink: "https://www.youtube.com/watch?v=JGwWNGJdvx8",
    },
    {
      image: webinarEventsImg3,
      title: "10 Common Webinar Mistakes to Avoid in 2024",
      content:
        "Discover how to create engaging webinar experiences designed to captivate and convert your audience.",
      videoLink: "https://www.youtube.com/watch?v=JGwWNGJdvx8",
    },
  ];

  const [isOpen, setIsOpen] = React.useState(false);
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex flex-row py-16" id="Webinar-events">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col justify-center gap-6">
          <div className="flex flex-row flex-wrap justify-center">
            <h3 className="text-3xl font-bold text-[#231F40]">
              Webinar Events
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-6">
            {webinars.map((webinar, index) => (
              <div
                key={index}
                className="flex flex-col items-center w-full border-gray-300 border border-solid bg-gray-50 rounded-lg p-6"
              >
                <div className="w-full h-[200px] overflow-hidden">
                  <img
                    src={webinar.image}
                    alt={webinar.title}
                    className="h-full w-full rounded-xl object-cover"
                  />
                </div>
                <div className="flex flex-col gap-2 text-center pt-6">
                  <div className="flex flex-col w-full justify-start items-center overflow-hidden px-2 h-40 gap-2">
                    <h3 className="text-lg text-black font-semibold line-clamp-2">
                      {webinar.title}
                    </h3>
                    <p className="w-full text-center text-sm leading-[21px] text-[#4F4F4F] line-clamp-3">
                      {webinar.content}
                    </p>
                  </div>
                  <div className="flex flex-row w-full justify-center items-center">
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      className="blue-color button-styles py-3 px-6 mt-4 rounded-full flex flex-row items-center justify-center text-[#113CC0] gap-2 text-sm font-semibold hover:text-white"
                    >
                      Watch Video
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                    {isOpen && (
                      <>
                        <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                        <div
                          id="modal"
                          className="fixed inset-0 flex items-center justify-center z-50 w-full h-full rounded-lg"
                          onClick={handleCloseModal}
                        >
                          <button
                            className="absolute top-8 right-8"
                            onClick={handleCloseModal}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="white"
                              width={20}
                              height={20}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          <div
                            className="modal-content relative m-0  duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
                            style={{
                              animation:
                                "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
                            }}
                          >
                            <iframe
                              width="100%"
                              height="100%"
                              src={`https://www.youtube.com/embed/T6oKlQoHrIg?si=SRxz8q4ZyXoDqvb1?autoplay=1`}
                              title="YouTube Video"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              className="inset-0 w-full h-full left-0 top-0 rounded-lg"
                            ></iframe>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarEvents;
