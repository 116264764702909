import { useState, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface UseAxiosConfig extends AxiosRequestConfig {}

interface UseAxiosResult<T> {
  data: T | null;
  loading: boolean;
  error: any;
  fetchData: (newConfig?: Partial<UseAxiosConfig>, newBody?: any) => void;
}

export function useAxios<T = any>(config: UseAxiosConfig): UseAxiosResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(
    (newConfig: Partial<UseAxiosConfig> = {}, newBody: any = {}) => {
      setLoading(true);
      setError(null);

      axios({
        ...config,
        ...newConfig,
        data: newBody,
      })
        .then((response: AxiosResponse<T>) => {
          setData(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [config]
  );

  return { data, loading, error, fetchData };
}
