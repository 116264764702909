import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";

export const COURSE_DEPARTMENTS: string[] = [
  "Accounting",
  "Actuarial Science",
  "Artificial Intelligence",
  "Astronomy",
  "Biomedical Engineering",
];

const ChooseDepartments = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);
  const { register, handleSubmit } = useForm();

  const { pafData } = useSelector((state: RootState) => state.auth);

  const [selectedCard, setSelectedCard] = useState<any>("");

  const onSubmit = (data: any) => {
    dispatch(updateForm({ course: selectedCard }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const objectOptions = ["edi", "Settings", "Profile"];
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={true}
        stepName="Course Looking For?"
        stepDescription=""
      >
        {/* <PlaceholderChipGroup
          cards={COURSE_DEPARTMENTS}
          selectMode={"multiple"}
          handleCardSelect={handleCardClick}
        /> */}
        <div className="w-full p-4">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"single"}
            options={COURSE_DEPARTMENTS}
            handleCardSelect={handleCardClick}
            cardSize={"auto"}
          /> */}
          <OptionGroup
            options={pafData.lookingForStream}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.streamName
            }
            handleCardSelect={handleCardClick}
            cardSize={"80"}
          />
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default ChooseDepartments;
