import React from "react";

const likelyCollege: React.FC = () => {
  return (
    <div className="max-w-screen-lg mx-auto">
      <div
        className="rounded-24px border border-solid border-[#E0E0E0] bg-white py-11 px-16 rounded-3xl"
        style={{ boxShadow: "0px 10px 60px 0px #00000014" }}
      >
        <div className="flex flex-col items-center gap-9">
          <h3 className="text-xl font-semibold text-[#231F40] text-center">
            How likely are you to recommend college mentor.com to a friend or a
            colleague?
          </h3>
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap gap-6 items-center justify-center">
              {[...Array(10)].map((_, index) => (
                <p
                  key={index}
                  className="flex h-[54px] w-[54px] items-center justify-center rounded-full border border-[#E0E0E0] bg-gray-50 text-center text-[#4F4F4F]"
                >
                  {index + 1}
                </p>
              ))}
            </div>
            <div className="flex flex-wrap justify-between gap-5">
              <p className="text-xs text-gray-600">Not so Likely</p>
              <p className="text-xs text-gray-600">Highly Likely</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default likelyCollege;
