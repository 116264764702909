import { useEffect } from 'react';
import ApiService from '../../components/Shared/ApiService';

const useCopyWithMessage = () => {
    useEffect(() => {
        const handleCopy = (event: ClipboardEvent) => {
            const selectedText = window.getSelection()?.toString() || '';
            const currentPageUrl = window.location.href;
            const customMessage = `...Read more at: ${currentPageUrl}`;

            if (event.clipboardData) {
                event.clipboardData.setData('text/plain', selectedText + customMessage);
                event.preventDefault();
            }
        };

        document.addEventListener('copy', handleCopy);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, []);
};

export default useCopyWithMessage;
