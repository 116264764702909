import { FC, useMemo, useState } from "react";
import useDebouncedSearch from "../../../../components/filter/customHooks/useDebouncedSearch";
import searchIcon from "../images/search-icon.svg";
import { Link, useNavigate } from "react-router-dom";

interface IProps {
  data: { name: string; link: string }[];
}

const SearchBarWithSuggestion: FC<IProps> = ({ data }) => {
  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const [targetURL, setTargetURL] = useState("");

  const navigate = useNavigate();

  const searchFilteredData = useMemo(() => {
    return data.filter((item) =>
      item.name.toLowerCase().includes(deboundedSearchKeyword.toLowerCase())
    );
  }, [data, deboundedSearchKeyword]);

  const slicedData = searchFilteredData.slice(0, 10);

  return (
    <div className="w-full relative">
      <div className="w-full rounded border border-black flex overflow-hidden">
        <input
          className="flex-1 px-4 py-2 bg-grey-1 outline-none"
          placeholder="Search your Dream College"
          value={searchKeyword}
          onChange={(e) => {
            handleKeywordChange(e.target.value);
            setTargetURL("");
          }}
        />
        <button
          onClick={() => targetURL.length > 0 && navigate(targetURL)}
          className="p-2 bg-blue flex items-center justify-center"
        >
          <img src={searchIcon} className="w-5" />
        </button>
      </div>
      {deboundedSearchKeyword.length > 0 && targetURL.length == 0 && (
        <div className="absolute -bottom-2 left-0 right-0 translate-y-full z-10 rounded border border-grey-3 shadow-lg bg-white p-4 flex flex-col">
          {slicedData.map((item) => (
            <button
              onClick={() => {
                setTargetURL(item.link);
                handleKeywordChange(item.name);
              }}
              className="text-left p-2 rounded hover:bg-grey-1"
            >
              {item.name}
            </button>
          ))}
          {slicedData.length == 0 && <p>No results found</p>}
        </div>
      )}
    </div>
  );
};

export default SearchBarWithSuggestion;
