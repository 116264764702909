import React, { useState, useRef } from "react";
import Slider from "react-slick";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import jeeMainsRank from "../../../../images/rank-predictor/jee-mains-rank.svg";
import jeeAdvanced from "../../../../images/rank-predictor/jee-advanced.svg";
import "./RankPredictor.scss";

const RankPredictorSlider = [
  {
    image: jeeAdvanced,
    title: "JEE Advanced Rank Predictor",
    link: "/",
  },
  {
    image: jeeMainsRank,
    title: "JEE Mains Rank Predictor",
    link: "/",
  },
  {
    image: jeeMainsRank,
    title: "JEE Mains Predictor",
    link: "/",
  },
  {
    image: jeeAdvanced,
    title: "JEE Advanced Predictor",
    link: "/",
  },
  {
    image: jeeMainsRank,
    title: "Advanced Rank Predictor",
    link: "/",
  },
  {
    image: jeeAdvanced,
    title: "Mains Rank Predictor",
    link: "/",
  },
  {
    image: jeeMainsRank,
    title: "Rank Predictor",
    link: "/",
  },
  {
    image: jeeAdvanced,
    title: "Mains Rank",
    link: "/",
  },
  {
    image: jeeMainsRank,
    title: "Mains Predictor",
    link: "/",
  },
  {
    image: jeeAdvanced,
    title: " Predictor",
    link: "/",
  },
  // Additional cards...
];

export const RankPredictor = () => {
  const [isSliding, setIsSliding] = useState(false);
  const sliderRef = useRef<Slider>(null);

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} custom-class-prev ${
          isSliding ? "disabled" : ""
        }`}
        style={{ ...style }}
        onClick={!isSliding ? onClick : undefined}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
    );
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} custom-class-next ${
          isSliding ? "disabled" : ""
        }`}
        style={{ ...style }}
        onClick={!isSliding ? onClick : undefined}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    centerMode: false,
    cssEase: "ease-in-out",
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: () => setIsSliding(true),
    afterChange: (currentSlide: any) => {
      setTimeout(() => setIsSliding(false), 10); // Short delay to ensure smooth transition
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="rank-predictor-section flex py-14">
      <div className="container-section container mx-auto flex flex-col gap-16">
        <div className="flex flex-col items-start justify-center gap-[6px] w-full lg:w-1/2">
          <h3 className="text-3xl font-extrabold text-center text-[#231F40]">
            College & Rank Predictor
          </h3>
          <p className="text-left text-[#6B7280] max-w-[600px]">
            Discover your prospects for college admissions by course, factoring
            in your state of residence, caste, gender, and more Discover your
            prospects for college admissions by course, factoring in your state
            of residence, caste, gender, and more
          </p>
        </div>

        <div className="w-full lg:w-1/2 relative flex flex-col">
          <Slider {...settings} ref={sliderRef}>
            {RankPredictorSlider.map((item, index) => (
              <div
                key={index}
                className={`rankSlider flex flex-col p-8 font-semibold bg-white rounded-2xl`}
              >
                <div className="flex flex-col w-full h-full">
                  <img
                    loading="lazy"
                    src={item.image}
                    alt=""
                    className="transition-all duration-900 transform"
                  />
                  <div className="py-10 transition-all duration-900 transform">
                    <p className="text-lg leading-8 font-semibold text-center text-black line-clamp-2 min-h-4">
                      {item.title}
                    </p>
                  </div>
                  <div className="flex justify-center w-full gap-2 transition-all duration-900 transform">
                    <button className="flex gap-2 justify-center px-5 py-3.5 text-base text-white bg-[#113CC0] font-semibold rounded-full whitespace-nowrap">
                      Learn more
                      <ArrowForwardIcon className="shrink-0 my-auto w-2 h-2 aspect-square fill-white" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <img
        loading="lazy"
        src="/images/common/shape001.png"
        alt=""
        className="shrink-0 self-start w-11 aspect-[0.35]"
      />
    </div>
  );
};
