import React from "react";
import companyLogo from "../../images/footer/comapany-logo-footer.png";
import locationIcon from "../../images/footer/location.png";
import phoneIcon from "../../images/footer/phone.png";
import mainIcon from "../../images/footer/mail.png";
import youtubeIcon from "../../images/footer/youtube.png";
import twitterIcon from "../../images/footer/twitter.png";
import linkedinIcon from "../../images/footer/linkedin.png";
import instagramIcon from "../../images/footer/instagram.png";
import facebookIcon from "../../images/footer/facebook.png";

import "./Footer.scss";
import { colleges, predictors, quickLinks, topExams } from "./FooterData";
import { useNavigate } from "react-router-dom";

const quickLinksElement = quickLinks.map((item, index) => {
  return <li key={index}>{item}</li>;
});
const topExamElement = topExams.map((item, index) => {
  return <li key={index}>{item}</li>;
});
const collegeElement = colleges.map((item, index) => {
  return <li key={index}>{item}</li>;
});
const predictorElement = predictors.map((item, index) => {
  return <li key={index}>{item}</li>;
});

function Footer() {
  const navigate = useNavigate();

  const goToHomePageTop = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div>
      <footer className="footer-section bg-[#173CBA] font-open-sans">
        <div className="footer-container w-full md:max-w-[1360px] ">
          <div className="flex flex-col items-center self-stretch  py-10 md:py-16 px-1 max-md:px-5 pb-20 md:pb-0">
            <div className="justify-between w-full max-md:max-w-full">
              <div className="flex md:gap-[164px] flex-col md:flex-row max-md:gap-0">
                <div className="flex flex-col w-[320px] max-md:ml-0 max-md:w-full ml-4 md:ml-0">
                  <div className="flex flex-col grow self-stretch pb-16 text-sm text-gray-200 max-md:mt-10">
                    <img
                      loading="lazy"
                      src={companyLogo}
                      alt="company logo"
                      className="max-w-full aspect-[5.26] w-[227px]"
                    />
                    <div className="mt-4 text-base leading-6">
                      Commited to higher education's success, College Mentor is
                      on a mission to ensure every learner has access to the
                      experienced mentors and networks need to succeed.
                    </div>
                    <div className="self-start mt-6 font-semibold text-[#eaecf0] max-md:mt-10">
                      Quick Links
                    </div>

                    <ul className="quick-links text-sm">
                      {quickLinksElement.map((item, index) => (
                        <li
                          key={index}
                          className="m-2 text-[#EAECF0] translate-x-2"
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className="mt-6 font-semibold text-[#eaecf0] max-md:mt-10">
                      Contact Info
                    </div>
                    <div className="flex gap-1.5 mt-4">
                      <img
                        loading="lazy"
                        src={locationIcon}
                        alt="location icon"
                        className="shrink-0 self-start aspect-[0.76] w-[13px]"
                      />
                      <span className="text-[#EAECF0]">
                        275 Quadra Street Victoria Road,
                        <br />
                        New York
                      </span>
                    </div>
                    <div className="flex gap-1.5 mt-1.5">
                      <img
                        loading="lazy"
                        src={phoneIcon}
                        alt="phone icon"
                        className="shrink-0 my-auto aspect-square fill-[#eaecf0] w-[13px]"
                      />
                      <span className="text-[#EAECF0]">+ 1 (237) 382-2839</span>
                    </div>
                    <div className="flex gap-1.5 mt-1.5 whitespace-nowrap">
                      <img
                        loading="lazy"
                        src={mainIcon}
                        alt="mail icon"
                        className="shrink-0 self-start aspect-[0.89] w-[17px]"
                      />
                      <span className="text-[#EAECF0]">
                        yourmailaddress@example.com
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-full md:w-[800px]">
                  <div className="flex flex-wrap gap-0 md:gap-[75px]">
                    <div className="flex flex-col w-1/2 md:w-[160px]">
                      <div className="flex flex-col grow text-sm text-gray-200 max-md:mt-10">
                        <div className="mb-[16px] font-semibold text-[#eaecf0] text-sm">
                          Top Exam
                        </div>
                        <ul className="quick-links text-sm">
                          {topExamElement.map((item, index) => (
                            <li key={index} className="m-2 text-[#EAECF0]">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2 md:w-[230px] max-md:ml-0">
                      <div className="flex flex-col grow text-sm text-gray-200 max-md:mt-10">
                        <div className="self-start mb-[16px] font-semibold text-[#eaecf0] text-sm">
                          College
                        </div>
                        <ul className="quick-links text-sm">
                          {collegeElement.map((item, index) => (
                            <li
                              key={index}
                              className="m-2 pr-2 md:pr-0 text-[#EAECF0]"
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2 md:w-[250px] max-md:ml-0 mt-6 md:mt-0">
                      <div className="flex flex-col text-sm text-gray-200 max-md:mt-10">
                        <div className="self-start mb-[16px] font-semibold text-[#eaecf0] text-sm">
                          Predictors & Ebooks
                        </div>
                        <ul className="quick-links text-sm">
                          {predictorElement.map((item, index) => (
                            <li key={index} className="m-2 text-[#EAECF0]">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CopyRight sx={{ mt: 5 }} /> */}
      </footer>
      <div className="footer-bottom">
        <div className="flex justify-center items-center self-stretch px-16 py-4 bg-black max-md:px-5">
          <div className="flex flex-col md:flex-row gap-5 justify-between w-full max-w-[1200px] max-md:flex-wrap max-md:max-w-full">
            <div className="flex text-xs text-white justify-center items-center">
              © 2024 College Mentor. All rights reserved.
            </div>
            <div className="flex gap-5 md:self-start justify-center items-center">
              <a href="https://www.youtube.com/">
                <img
                  loading="lazy"
                  src={youtubeIcon}
                  alt="youtube icon"
                  className="shrink-0 aspect-[1.43] fill-white w-[23px]"
                />
              </a>
              <a href="https://twitter.com/">
                <img
                  loading="lazy"
                  src={twitterIcon}
                  alt="twitter icon"
                  className="shrink-0 w-4 aspect-square"
                />
              </a>
              <a href="https://in.linkedin.com/">
                <img
                  loading="lazy"
                  src={linkedinIcon}
                  alt="linkedin icon"
                  className="shrink-0 w-4 aspect-square"
                />
              </a>
              <a href="https://www.facebook.com/">
                <img
                  loading="lazy"
                  src={facebookIcon}
                  alt="facebook icon"
                  className="shrink-0 w-4 aspect-square"
                />
              </a>
              <a href="https://www.instagram.com/">
                <img
                  loading="lazy"
                  src={instagramIcon}
                  alt="instagram icon"
                  className="shrink-0 w-4 aspect-square fill-white"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
