import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Accordion = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen((prev) => !prev);
  return (
    <div className="self-start p-4 bg-white shadow-lg">
      <div className="flex gap-4 justify-between">
        {question}{" "}
        <button
          onClick={toggleAccordion}
          className="h-6 w-6 flex shrink-0 items-center justify-center bg-blue rounded-full"
        >
          <FontAwesomeIcon
            icon={isOpen ? faMinus : faPlus}
            className="text-white"
          />
        </button>
      </div>
      {isOpen && <div className="mt-2">{answer}</div>}
    </div>
  );
};

export default Accordion;
