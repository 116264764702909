import React from "react";

const NewsDetails = () => {
  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6 md:p-12 lg:p-24">
        <h1 className="text-2xl md:text-3xl font-bold mb-4">
          Page is Under Development
        </h1>
        <p className="text-base md:text-lg text-gray-700">
          We are currently working on this page. Please check back later for the
          latest updates and news.
        </p>
      </div>
    </div>
  );
};

export default NewsDetails;
