// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
}

.custom-tab {
  padding: 4px;
  cursor: pointer;
  border: 2px solid grey;
  border-radius: 10px;
  margin-right: 5px;
}

.custom-tab.active {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/CustomTabsNavigationBar.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEE;EACE,YAAA;EACA,eAAA;EAEA,sBAAA;EACA,mBAAA;EAEA,iBAAA;AADJ;;AAIE;EAEE,iBAAA;AAFJ","sourcesContent":[".tabs {\n    display: flex;\n  }\n  \n  .custom-tab {\n    padding: 4px;\n    cursor: pointer;\n    // border-bottom: 2px solid transparent;\n    border: 2px solid grey;\n    border-radius: 10px;\n    // transition: all 0.3s;\n    margin-right: 5px\n  }\n  \n  .custom-tab.active {\n    \n    font-weight: bold;\n    // color: #494677;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
