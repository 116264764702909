import React from "react";

interface Props {}

const MentorDetails: React.FC<Props> = (props) => {
  return (
    <div className="py-16">
      <div className="container mx-auto">
        <h3 className="text-2xl text-center"> Page is Under Development..</h3>
      </div>
    </div>
  );
};

export default MentorDetails;
