import {
  TextField,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { emailValidator } from "../../util/FormValidator";
function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  // const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    setEmail(state?.email);
  }, [state]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let errorRes = emailValidator(email);
    setEmailError(errorRes);
    if (errorRes === "") {
      navigate("/otp-verification", { state: { email } });
    }
  };
  const handleChange = (event: any) => {
    setEmail(event.target.value);
    let errorRes = emailValidator(event.target.value);
    setEmailError(errorRes);
  };
  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Forgot Password?</Typography>
        <Box>
          Enter the email address we’ll send you code to reset your password.
        </Box>
        <Box sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={email}
            onChange={handleChange}
            error={emailError !== ""}
            helperText={emailError !== "" ? emailError : " "}
          />

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "right",
              m: "5px",
            }}
          >
            <Grid item>
              <Link href={"/forgot-email"} variant="body2">
                Forgot email?
              </Link>
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ForgotPassword;
