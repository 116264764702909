const WebinarCard = ({
  title,
  by,
  description,
  date,
  time,
  image1,
  image2,
  name1,
  name2,
  handlePopup,
}: {
  title: string;
  by: string;
  description: string;
  date: string;
  time: string;
  image1: string;
  image2: string;
  name1: string;
  name2: string;
  handlePopup: () => void; // Correctly typing the handlePopup prop
}) => {
  return (
    <div key={title} className="rounded-xl overflow-hidden flex flex-col">
      <div
        className="flex flex-col border-b-4 border-[#FFD540]"
        style={{
          backgroundImage: "linear-gradient(to left, #06AFA9, #173CBA)",
        }}
      >
        <div className="flex flex-col ml-6 mt-2">
          <a href="/blogdetails-page">
            <div className="font-roboto text-[21px] text-[#ffffff] font-bold pr-2 line-clamp-1">
              {title}
            </div>
          </a>
          <div>
            <p
              className="font-roboto text-sm text-[#ffffff] font-light"
              style={{ fontWeight: 300 }}
            >
              Get guidance from experts
            </p>
          </div>
          <div className="mt-2">
            <p
              className="font-roboto text-[10px] text-[#ffffff] border border-[#FFD540] inline-block p-1 rounded-lg translate-y-1"
              style={{ fontWeight: 300 }}
            >
              Class XII students invited
            </p>
            <div className="bg-[#FFD540] inline-block px-2 pt-2 pl-6 translate-y-2 translate-x-4 md:-translate-x-6 rounded-br-lg">
              <p className="font-roboto text-[10px] text-[#383838]">
                GET A PSYCHOMETRIC TEST WORTH
              </p>
              <p className="font-roboto font-semibold text-base text-[#383838]">
                INR 1000 FOR FREE
              </p>
            </div>
          </div>
          <div className="font-roboto text-[10px] text-[#ffffff] whitespace-nowrap mt-2 mb-4">
            {date} {time}
          </div>
        </div>
        <div className="flex justify-center items-center gap-2 mb-6">
          <div>
            <img src={image1} alt="Speaker 1" className="w-[78px] h-[109px]" />
            <p className="font-roboto text-[8px] text-[#ffffff] text-center">
              Name
            </p>
            <p className="font-roboto text-[8px] text-[#ffffff] text-center">
              {name1}
            </p>
          </div>
          <div>
            <img src={image2} alt="Speaker 2" className="w-[78px] h-[109px]" />
            <p className="font-roboto text-[8px] text-[#ffffff] text-center">
              Name
            </p>
            <p className="font-roboto text-[8px] text-[#ffffff] text-center">
              {name2}
            </p>
          </div>
        </div>
      </div>
      <div className="flex-flex-col ml-6 mb-6 relative">
        <div className="absolute -top-6 -left-4 justify-center border-b-2 border-[#FFD540] items-center bg-[#ffffff] font-roboto text-[16px] text-[#383838] border-2 px-4 py-2 rounded-3xl flex space-x-2">
          <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.5 0L22.3253 18.75H0.674683L11.5 0Z" fill="#D9D9D9" />
          </svg>
          <span>Online Event</span>
        </div>
        <div className="absolute -top-6 left-40 md:left-36 justify-center items-center bg-[#FFD540] font-roboto text-[16px] text-[#383838] border-2 px-4 py-2 rounded-lg whitespace-nowrap">
          <button onClick={handlePopup}>I'm Interested</button>
        </div>
      </div>
    </div>
  );
};

export default WebinarCard;
