import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import GroupButtonsMore from "./GroupButtonsMore";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { registrationData } from "../../services/apiReq";

const styledButton = styled.button`
  background-color: #ffffff;
  color: #047ae8;
  padding: 5px 15px;
  border-radius: 50px;
  outline: 0;
  border: 1px solid #047ae8;
  text-transform: uppercase;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &.active-class {
    background-color: #047ae8;
    color: #ffffff;
    opacity: 1;
  }
`;
const ButtonToggle = styled(styledButton)`
  opacity: 0.7;
`;

const styledP = styled.p`color: "#3083FF", alignItems: "center"`;
const PStyle = styled(styledP)`
  opacity: 0.7;
`;
function DreamCareerInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dreamCareer, setDreamCareer] = useState("");
  const [dreamCourse, setDreamCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [dreamCareerCopy, setDreamCareerCopy] = useState("");
  const [dreamCourseCopy, setDreamCourseCopy] = useState("");
  const [specializationCopy, setSpecializationCopy] = useState("");

  const [data, setData] = useState<registrationData>(location.state);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      dreamCareer,
      dreamCourse,
      specialization,
    }));
    setDreamCareerCopy(dreamCareer);
    setDreamCourseCopy(dreamCourse);
    setSpecializationCopy(specialization);
  }, [dreamCareer, dreamCourse, specialization]);

  const dreamCareerList: string[] = [
    "Engineering",
    "Fashion design",
    "Marine",
    "Engineering1",
    "Fashion design1",
    "Marine1",
    "Architecture",
    "Medical",
    "managing",
    "Architecture1",
    "Medical1",
    "managing1",
    "Teaching",
    "Social science",
    "Marine2",
    "Teaching1",
    "Social science1",
    "Marine3",
    "Engineering2",
    "Chef",
    "Agriculture",
    "Engineering3",
    "Chef1",
    "Agriculture1",
    "professional studies",
    "Medical2",
    "Sports",
    "professional studies1",
    "Medical3",
    "Sports1",
    "LAW",
    "Fashion design2",
    "Media",
    "LAW1",
    "Fashion design3",
    "Media1",
  ];

  const dreamCourseList: string[] = [
    "CBSE",
    "ISCE",
    "State board",
    "IB",
    "International",
    "Cambridge",
    "NIOD",
    "Others",
  ];
  const specializationList: string[] = ["CSE", "EEE", "ECE", "M.Tech", "IT"];

  const handleContinue = (event: any) => {
    navigate("/stateInfo", { state: { ...data } });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Let’s get started</Typography>
        {dreamCareerCopy === "" ? (
          <GroupButtonsMore
            list={dreamCareerList}
            title="Select your Dream Career:"
            setSelectedValue={setDreamCareer}
            selectedValue={dreamCareer}
            moreButton={true}
          />
        ) : (
          <>
            <Box>
              <Typography>Select your Dream Career:</Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <ButtonToggle className={"active-class"}>
                  {dreamCareer}
                </ButtonToggle>
                <PStyle onClick={() => setDreamCareerCopy("")}>Edit</PStyle>
              </Box>
            </Box>
            {dreamCourseCopy === "" ? (
              <GroupButtonsMore
                list={dreamCourseList}
                title="Select your Dream Course :"
                setSelectedValue={setDreamCourse}
                selectedValue={dreamCourse}
                moreButton={true}
              />
            ) : (
              <>
                <Box>
                  <Typography>Select your Dream Career:</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ButtonToggle className={"active-class"}>
                      {dreamCourse}
                    </ButtonToggle>
                    <PStyle onClick={() => setDreamCourseCopy("")}>Edit</PStyle>
                  </Box>
                </Box>
                {specializationCopy === "" ? (
                  <GroupButtonsMore
                    list={specializationList}
                    title="Select your Specialization :"
                    setSelectedValue={setSpecialization}
                    selectedValue={specialization}
                    moreButton={false}
                  />
                ) : (
                  <Box>
                    <Typography>Select your Specialization :</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ButtonToggle className={"active-class"}>
                        {specialization}
                      </ButtonToggle>
                      <PStyle onClick={() => setSpecializationCopy("")}>
                        Edit
                      </PStyle>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          onClick={handleContinue}
          disabled={
            dreamCareerCopy === "" ||
            dreamCourseCopy === "" ||
            specializationCopy === ""
          }
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default DreamCareerInfo;
