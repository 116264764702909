import React from "react";
import AdmissionIcon from "../../../images/college/details/admissions-icon.svg";
import ChatIcon from "../../../images/college/details/chatIcon.svg";
import NewsIcon from "../../../images/college/details/newsIcon.svg";

const chatOptionsList = [
  {
    id: 1,
    title: "Apply admissions",
    imageIcon: AdmissionIcon,
  },
  {
    id: 2,
    title: "Chat",
    imageIcon: ChatIcon,
  },
  {
    id: 3,
    title: "News",
    imageIcon: NewsIcon,
  },
];
const ChatOptions = (applyLink: any) => {
  return (
    <div className="absolute top-1/2 right-0 translate-y-1/2 chatOptions z-10">
      <ul className="flex flex-col gap-[1px] rounded-lg shadow-lg bg-white">
        {chatOptionsList.map((chatOption) => {
          let href = "";
          if (chatOption.title === "Apply admissions") {
            href = applyLink.applyLink;
          } else {
            href = "";
          }
          return (
            <li
              key={chatOption.id}
              className="flex flex-col items-center justify-center gap-2 bg-[#113cc0] text-white p-2 cursor-pointer rounded-sm"
            >
              <a href={href} className="text-center">
                <img
                  src={chatOption.imageIcon}
                  alt={chatOption.title}
                  className="w-6 h-6 object-cover mx-auto"
                />
                <span className="text-base">{chatOption.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatOptions;
