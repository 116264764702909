import React from "react";
import { CarreVisualStories } from "../../../components/career-visual-stories/CarreVisualStories";
interface VisualStory {
  id: number;
  title: string;
  description: string;
  
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}
const careerVisualStoriesData = [
  {
    id: 1,
    src: "/images/careers/cvs01.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 2,
    src: "/images/careers/cvs02.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 3,
    src: "/images/careers/cvs03.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 4,
    src: "/images/careers/cvs04.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 5,
    src: "/images/careers/cvs01.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 6,
    src: "/images/careers/cvs02.jpg",
    title: "Career Visual Stories",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

const CourseVisualStoriesTab: React.FC<{ visualStories: VisualStory[] }> = ({ visualStories }) => {
  return (
    <CarreVisualStories
      careerVisualStoriesData={visualStories}
      title="Course Visual Stories"
    />
  );
};

export default CourseVisualStoriesTab;
