import React from "react";
import StudyAbroadImage from "../../../images/blog-article/study-abroad-image.png";
import ExamImage1 from "../../../images/blog-article/exam-section-image1.jpg";
import { Link } from "react-router-dom";
interface VerticalNewsCardProps {
  item: any;
}
const VerticalNewsCard: React.FC<VerticalNewsCardProps> = ({ item }) => {
  // console.log("VerticalNewsCard", item);
  return (
    <Link
      to={`/blogs/${item?.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`}
      state={{
        from: "blogInfoCard",
        blogDetail: item,
        blogCategory: item?.category,
      }}
      className="h-full"
    >
      <div className="h-full overflow-hidden rounded shadow-lg">
        <img
          className=""
          src={item?.imageUrl}
          alt=""
          width="100%"
          style={{ objectFit: "cover", height: "22rem" }}
        />

        <div className="p-4 py-2">
          <p className="mb-2 text-sm font-medium">{item?.category}</p>

          <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">
            {item?.title ||
              "Town Celebrates Grand Opening of New Public Library with Festive Ribbon Cutting"}
          </h5>

          <p className="font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
            {item?.description ||
              "In the dynamic world of business and entrepreneurship, success is not just about having a great idea or a solid business plan. It requires a combination of factors, including..."}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default VerticalNewsCard;
