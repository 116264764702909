// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-style {
  display: flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 37.455px;
  border: 2px solid #fff;
  background: #173cba;
}

@media (min-width: 321px) and (max-width: 450px) {
  .success-heading img {
    width: 20px;
    height: 20px;
  }
  .success-heading h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .success-btn button {
    width: 127px;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/courses/SuccessStrip.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EAEA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,WAAA;IACA,YAAA;EAAF;EAEA;IACE,eAAA;IACA,gBAAA;IACA,mBAAA;IACA,0BAAA;EAAF;EAEA;IACE,YAAA;IACA,eAAA;IACA,gBAAA;IACA,iBAAA;EAAF;AACF","sourcesContent":[".btn-style {\n  display: flex;\n  padding: 14px 34px;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  border-radius: 37.455px;\n  border: 2px solid #fff;\n  background: #173cba;\n}\n\n@media (min-width: 321px) and (max-width: 450px) {\n  .success-heading img {\n    width: 20px;\n    height: 20px;\n  }\n  .success-heading h3 {\n    font-size: 12px;\n    font-weight: 600;\n    line-height: normal;\n    text-transform: capitalize;\n  }\n  .success-btn button {\n    width: 127px;\n    font-size: 12px;\n    font-weight: 600;\n    padding: 8px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
