import {
  faChevronLeft,
  faChevronRight,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import NavHeader from "./NavHeader"
interface IProps {
  navLinks: { name: string; icon: ReactNode; link: string }[];
}

const CollapsibleSideBar: FC<IProps> = ({ navLinks }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    
    <div className="bg-white h-screen flex flex-col gap-4 p-4 relative text-grey ">
      <NavHeader expanded={expanded} setExpanded={setExpanded} />
<div className="mt-2"></div>
      {navLinks.map((item) => (
        <NavLink
          to={item.link}
          className={({ isActive }) =>
            `p-4 flex items-center ${
              isActive ? "bg-[#e6e6e6] text-black font-bold" : ""
            } rounded-lg flex gap-4`
          }
          style={{
            transition: "width 0.5s",
            width: expanded ? "10rem" : "3.5rem",
          }}
        >
          {item.icon}
          <div
            className="w-full overflow-hidden"
            style={{
              transition: "opacity 0.5s",
              opacity: expanded ? 1 : 0,
            }}
          >
            {item.name}
          </div>
        </NavLink>
      ))}
      {!expanded &&
      <button
        className="absolute -right-3 top-4 h-6 w-6 rounded-full flex items-center justify-center bg-white shadow"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <FontAwesomeIcon
          icon={ faChevronRight}
          size="sm"
        />
      </button>}
    </div>
  );
};

export default CollapsibleSideBar;
