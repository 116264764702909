import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  destinationLink: string;
}

const quizQuestions = [
  {
    question: "What is your favorite subject?",
    options: ["Mathematics", "Physics", "Chemistry", "Biology"],
  },
  {
    question: "Which type of environment do you prefer?",
    options: ["Urban", "Suburban", "Rural", "Coastal"],
  },
  {
    question: "What is your preferred learning style?",
    options: ["Visual", "Auditory", "Reading/Writing", "Kinesthetic"],
  },
  {
    question: "What is your ideal class size?",
    options: [
      "Small (less than 20)",
      "Medium (20-50)",
      "Large (50-100)",
      "Very Large (100+)",
    ],
  },
  {
    question: "Which extracurricular activities are you interested in?",
    options: ["Sports", "Arts", "Science clubs", "Community service"],
  },
  {
    question: "What type of campus setting do you prefer?",
    options: ["City", "Suburban", "Rural", "Online"],
  },
  {
    question: "What is your preferred class schedule?",
    options: ["Morning", "Afternoon", "Evening", "Flexible"],
  },
  {
    question: "What is your primary reason for attending college?",
    options: [
      "Career preparation",
      "Personal growth",
      "Networking",
      "Academic interest",
    ],
  },
  {
    question: "Which type of housing do you prefer?",
    options: [
      "On-campus dormitory",
      "Off-campus apartment",
      "Commute from home",
      "No preference",
    ],
  },
  {
    question: "What is your preferred method of study?",
    options: [
      "Group study",
      "Solo study",
      "Tutoring sessions",
      "Online resources",
    ],
  },
];

const QuizWidget: FC<IProps> = ({ destinationLink }) => {
  const navigate = useNavigate();
  const numberOfQuestions = quizQuestions.length;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState<
    { question: string; answer: string }[]
  >([]);

  const handleResponse = (question: string, answer: string) => {
    setResponses((prev) => [...prev, { question, answer }]);
    if (currentQuestion + 1 < numberOfQuestions)
      setCurrentQuestion((prev) => prev + 1);
    else navigate(destinationLink);
  };

  return (
    <div className="bg-white p-8 w-80 md:w-[640px]">
      <h1 className="text-3xl font-bold text-blue">Map your college!</h1>
      <span className="text-sm text-grey">{`Qn: ${
        currentQuestion + 1
      }/${numberOfQuestions}`}</span>
      <Question
        {...quizQuestions[currentQuestion]}
        handleResponse={handleResponse}
      />
    </div>
  );
};

const Question = ({
  question,
  options,
  handleResponse,
}: {
  question: string;
  options: string[];
  handleResponse: (question: string, answer: string) => void;
}) => {
  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold">{question}</h2>
      <ul className="mt-4 flex flex-col gap-2 text-base text-grey">
        {options.map((option) => (
          <li>
            <button
              onClick={() => handleResponse(question, option)}
              className="w-full border border-grey px-4 py-2 rounded-lg"
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuizWidget;
