import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.scss";

interface IProps {
  links: { name: string; path: string }[];
}

const Breadcrumbs: React.FC<IProps> = ({ links }) => {
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-parent text-sm">
      <ol className="breadcrumb !pl-0">
        {links.map((link, index) => {
          const isLast = index === links.length - 1;

          return isLast ? (
            <li
              key={link.path}
              className="inline breadcrumb-item active"
              aria-current="page"
            >
              {link.name}
            </li>
          ) : (
            <li key={link.path} className="inline breadcrumb-item">
              <Link to={link.path}>{link.name}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
