// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-tools {
  color: #383838;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/screens/college-predictor/landing/components/PredictorTools.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EAEA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".heading-tools{\n    color: #383838;\n\n    text-align: center;\n    font-family: Roboto;\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 60px;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
