import React, { useState } from "react";

interface Option {
  name: string;
  count: number;
  key: string;
}

interface SortByDropdownProps {
  options: Option[];
  selectedSort: string;
  onSortChange: (key: string) => void;
}

export const SortBy: React.FC<SortByDropdownProps> = ({
  options,
  selectedSort,
  onSortChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={toggleDropdown}
        className="bg-white border border-gray-300 rounded-md p-2 w-48 flex justify-between items-center"
      >
        <span className="text-[#6A6777] text-sm">
          {options.find((option) => option.key === selectedSort)?.name || ""}
        </span>
        <span className="material-icons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clip-path="url(#clip0_1_1900)">
              <path
                d="M3.0853 6.52565H10.9169C11.3906 6.52565 11.3518 5.74531 11.3027 5.35468C11.2876 5.23417 11.2122 5.05668 11.1424 4.9572L7.56802 0.411567C7.25494 -0.137189 6.74733 -0.137189 6.43409 0.411567L2.86013 4.9566C2.79036 5.05608 2.71495 5.23357 2.69987 5.35408C2.65065 5.74486 2.61164 6.52565 3.0853 6.52565Z"
                fill="#4F4D4D"
              />
              <path
                d="M2.86013 10.0432L6.43409 14.5884C6.74718 15.1371 7.25479 15.1371 7.56802 14.5884L11.1424 10.0425C11.2122 9.94307 11.2875 9.76575 11.3027 9.64507C11.3518 9.25446 11.3906 8.47412 10.9169 8.47412H3.0853C2.61164 8.47412 2.65065 9.25491 2.69987 9.64567C2.7151 9.76618 2.79036 9.94367 2.86013 10.0432Z"
                fill="#4F4D4D"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_1900">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </button>

      {isOpen && (
        <ul className="absolute bg-white border border-gray-300 rounded-md w-48 mt-2 shadow-lg z-10">
          {options.map((option) => (
            <li
              key={option.key}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selectedSort === option.key ? "font-bold" : ""
              }`}
              onClick={() => {
                onSortChange(option.key);
                setIsOpen(false);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
