import { useEffect, useRef, useState } from "react";
import DesktopFilterMenu, {
  IFilterDataItem,
} from "../../../components/filter/components/DesktopFilterMenu";
import useSelectedFilter, {
  ICategory,
  IGetFilterDataFromPath,
  IGetPathFromFilterData,
} from "../../../components/filter/customHooks/useSelectedFilter";
import { FilterCategory } from "../../../components/filter/FilterEnum";
import ScholarshipCard from "../../course-intermediate/components/ScholarshipCard";
import Banner from "./components/Banner";
import useDebouncedSearch from "../../../components/filter/customHooks/useDebouncedSearch";
import useQueryParamPagination from "../../../components/filter/customHooks/useQueryParamPagination";
import MobileFilterMenu from "../../../components/filter/components/MobileFilterMenu";
import Pagination from "../../../components/filter/components/Pagination";
import { faFilter, faX, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterChips from "../../../components/filter/components/FilterChips";

const PAGE_SIZE = 10;

const EDUCATION_FILTER_CATEGORY = "education";

const FILTER_CATEGORIES: ICategory[] = [
  {
    key: EDUCATION_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,
  },
];

const educationFilterData: IFilterDataItem = {
  categoryName: "Select Education",
  categoryKey: EDUCATION_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Class 11", key: "class-11" },
    { name: "Class 12", key: "class-12" },
    { name: "Certificate", key: "certificate" },
    { name: "Diploma", key: "diploma" },
    { name: "Under Graduation", key: "ug" },
    { name: "Post Graduation", key: "pg" },
    { name: "PhD", key: "phd" },
    { name: "Vocational", key: "vocational" },
  ],
};

const getPathName: IGetPathFromFilterData = (filterData) => {
  return "list-of-scholarships-in-india";
};

const getPathData: IGetFilterDataFromPath = (path) => {
  return {};
};

const ScholarshipIntermediate = () => {
  const listingContainerRef = useRef<HTMLDivElement>(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const isMediumDevice = window.innerWidth >= 768;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const {
    selectedFilter,
    updateSelectedFilter,
    clearFilter,
    filterDataForApiBody,
  } = useSelectedFilter(
    FILTER_CATEGORIES,
    "scholarships",
    getPathName,
    getPathData
  );

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);

  useEffect(() => {
    if (!isFirstLoad && listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView();
      console.log("change scroll");
    }
  }, [currentPage]);

  const filterData: IFilterDataItem[] = [];

  filterData.push(educationFilterData);

  return (
    <div className="bg-pink-light text-black font-inter">
      <Banner />
      <div ref={listingContainerRef} className="container">
        <div className="flex gap-6 py-12">
          {isMediumDevice && (
            <div className="w-[30%]">
              <DesktopFilterMenu
                filterData={filterData}
                selectedFilter={selectedFilter}
                updateSelectedFilter={updateSelectedFilter}
                clearFilter={clearFilter}
              />
            </div>
          )}
          {!isMediumDevice && showMobileFilter && (
            <MobileFilterMenu
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
              clearFilter={clearFilter}
              closeFilter={() => setShowMobileFilter(false)}
            />
          )}

          <div className="w-full md:w-[70%] flex flex-col gap-6">
            <FilterChips
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
            />
            <div className="flex items-center justify-between gap-4">
              {!isMediumDevice && (
                <button
                  className="bg-white rounded-md p-3"
                  onClick={() => setShowMobileFilter(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    color="white"
                    stroke="#7D7B89"
                    size="lg"
                    strokeWidth={40}
                  />
                </button>
              )}
              <div className="flex-1 flex items-center bg-white pr-4 rounded-md overflow-hidden">
                <input
                  className="p-2 text-base placeholder-grey w-full"
                  value={searchKeyword}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                  placeholder={
                    isMediumDevice ? "Search your dream course" : "Search"
                  }
                />
                {searchKeyword && searchKeyword.length > 0 ? (
                  <button onClick={() => handleKeywordChange("")}>
                    <FontAwesomeIcon
                      icon={faX}
                      size="sm"
                      className="text-grey"
                    />
                  </button>
                ) : (
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="sm"
                    className="text-grey"
                  />
                )}
              </div>
            </div>

            <ScholarshipCard />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipIntermediate;
