import { FC } from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";

interface IProps {
  data?: any;
}

interface ISlideData {
  title: string;
  category: string;
  description: string;
  imageUrl: string;
}

const Banner: FC<IProps> = ({ data }) => {
  const slideData = data[0]?.topArticlesAndBlogsAndLatestNews?.subcategories[
    "Articles & Blogs"
  ].results.slice(0, 4);
  const slides = slideData.map((item: ISlideData) => <Slide {...item} />);

  return (
    <CustomSwiper
      items={slides}
      autoplay={true}
      slidesPerView={1}
      customParams={{ effect: "fade", fadeEffect: { crossFade: true } }}
    />
  );
};

export default Banner;

const Slide: FC<ISlideData> = ({ title, category, description, imageUrl }) => {
  return (
    <div
      className="bg-no-repeat bg-center text-white"
      style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover" }}
    >
      <div className="px-4 py-20 w-full md:w-1/2 ml-auto">
        <span className="text-base bg-green px-4 py-1">{category}</span>
        <h2 className="text-3xl font-bold line-clamp-2">{title}</h2>
        <p className="text-lg line-clamp-3">{description}</p>
      </div>
    </div>
  );
};
