// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-clg-mentor-main {
  background: linear-gradient(to right, #fff0e1, #f5eef5);
  flex-shrink: 0;
}

.img1 {
  position: relative;
}
.img1 .badge {
  position: absolute;
  top: -32px;
  left: 33%;
}

.img3 {
  position: relative;
}
.img3 .total-students {
  position: absolute;
  bottom: 25px;
  left: -25px;
  width: 170.69px;
  height: 76px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
}
.img3 .total-students p {
  color: #6f6b80;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/why-college-mentor/WhyCollegeMentor.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;EAEA,cAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,iDAAA;EACA,aAAA;AACJ;AAAI;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACN","sourcesContent":[".why-clg-mentor-main {\n  background: linear-gradient(to right, #fff0e1, #f5eef5);\n  // height: 809.64px;\n  flex-shrink: 0;\n}\n\n.img1 {\n  position: relative;\n  .badge {\n    position: absolute;\n    top: -32px;\n    left: 33%;\n  }\n}\n\n.img3 {\n  position: relative;\n  .total-students {\n    position: absolute;\n    bottom: 25px;\n    left: -25px;\n    width: 170.69px;\n    height: 76px;\n    flex-shrink: 0;\n    border-radius: 8px;\n    background: #fff;\n    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08);\n    padding: 15px;\n    p {\n      color: #6f6b80;\n      // font-family: Urbanist;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 22px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
