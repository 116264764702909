import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import "swiper/swiper-bundle.css";
import "./TestimonialPredictors.scss";
import TestimonialCard from "../../components/TestimonialCard";
const TestimonialsData = [
  {
    imageUrl: "",
    reviewBy: "Kavya",
    review:
      "The college rank predictor gave me a clear idea of where I stand, helping me make informed choices. It boosted my confidence and made the counseling process much smoother.",
  },
];

const TestimonialPredictor: React.FC = () => {
  const studentReviewsData = useSelector(
    (state: RootState) => state.predictorLanding.studentsReviews
  );

  if (!studentReviewsData || studentReviewsData.length <= 0) return;

  const testimonialsContent = studentReviewsData?.map((data, index) => (
    <TestimonialCard {...data} />
  ));

  return (
    <div className="bg-[#F7F7F7] min-h-[329px] my-7 pb-12">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
        Students Love College Mentor Predictor Tools{" "}
      </h1>
      <div className="container hidden lg:block">
        <CustomSwiper
          className="!px-2"
          items={testimonialsContent}
          navigation={"hidden"}
          autoplay={true}
          breakpoints={{
            1024: { slidesPerView: 4, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 30 },
          }}
          progressBar={false}
        />
      </div>
      <div className="container block lg:hidden">
        <CustomSwiper
          className="!px-2"
          items={testimonialsContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
          }}
          progressBar={true}
        />
      </div>
    </div>
  );
};

export default TestimonialPredictor;
