// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-nav {
  height: 55px;
}

.icon {
  cursor: pointer;
  display: inline-block;
  padding: 3px 0 0;
}

.contact-text {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.navbar {
  display: flex; /* Make the navbar a flex container */
  justify-content: center; /* Distribute items horizontally */
}

.nav-list-contact {
  display: flex; /* Make the list items a flex container */
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.nav-item-contact {
  margin-right: 10px; /* Add some margin between items */
}

.nav-link-contact {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/contact-bar/ContactBar.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;AACF;;AAEA;EAME,WAAA;EAEA,eAAA;EACA,gBAAA;AANF;;AAQA;EACE,aAAA,EAAA,qCAAA;EACA,uBAAA,EAAA,kCAAA;AACF;;AAEA;EACE,aAAA,EAAA,yCAAA;EACA,gBAAA,EAAA,gCAAA;EACA,UAAA,EAAA,2BAAA;EACA,SAAA,EAAA,0BAAA;AACF;;AAEA;EACE,kBAAA,EAAA,kCAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".contact-nav {\n  height: 55px;\n}\n\n.icon {\n  cursor: pointer;\n  display: inline-block;\n  padding: 3px 0 0;\n}\n\n.contact-text {\n  font-size: 13px;\n  font-weight: 500;\n}\n\n.contact-text {\n  color: #fff;\n  // font-family: Poppins;\n  font-size: 12px;\n  font-weight: 500;\n}\n.navbar {\n  display: flex; /* Make the navbar a flex container */\n  justify-content: center; /* Distribute items horizontally */\n}\n\n.nav-list-contact {\n  display: flex; /* Make the list items a flex container */\n  list-style: none; /* Remove default list styling */\n  padding: 0; /* Remove default padding */\n  margin: 0; /* Remove default margin */\n}\n\n.nav-item-contact{\n  margin-right: 10px; /* Add some margin between items */\n}\n\n.nav-link-contact {\n  color: #ffffff;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
