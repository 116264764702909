// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clg-by-destination-main {
  position: relative;
}
.clg-by-destination-main .image-card-main .imgZoom {
  width: 100%;
  height: 15.625rem;
  overflow: hidden;
  border-radius: 12px;
}
.clg-by-destination-main .image-card-main .imgZoom img {
  max-width: 100%;
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.clg-by-destination-main .image-card-main:hover .imgZoom img {
  transform: scale(1.3);
}
.clg-by-destination-main .mySwiper {
  height: 440px;
}

.clg-by-destination-main .btn-section {
  position: absolute;
  bottom: 35px;
  left: 44%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Landing/components/colleges-by-destination/CollegesByDestination.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACI;EACE,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACN;AAAM;EACE,eAAA;EACA,wBAAA;EACA,sCAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AAER;AACI;EACE,qBAAA;AACN;AAEE;EACE,aAAA;AAAJ;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;AAAF","sourcesContent":[".clg-by-destination-main {\n  position: relative;\n  .image-card-main {\n    .imgZoom {\n      width: 100%;\n      height: 15.625rem;\n      overflow: hidden;\n      border-radius: 12px;\n      img {\n        max-width: 100%;\n        transform-origin: center;\n        transition: transform 0.4s ease-in-out;\n        height: 100%;\n        overflow: hidden;\n        object-fit: cover;\n      }\n    }\n    &:hover .imgZoom img {\n      transform: scale(1.3);\n    }\n  }\n  .mySwiper {\n    height: 440px;\n  }\n}\n.clg-by-destination-main .btn-section {\n  position: absolute;\n  bottom: 35px;\n  left: 44%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
