import { Title } from "@mui/icons-material";

import React, { useEffect, useState, useRef } from "react";
// import { Bar } from "react-chartjs-2"

const SummaryTable = [
  {
    year: "2018-19",
    eligibleStudents: 1665,
    studentsPlaced: 1505,
    placementPercentage: 90.33,
  },
  {
    year: "2019-20",
    eligibleStudents: 1843,
    studentsPlaced: 1645,
    placementPercentage: 91.32,
  },
  {
    year: "2020-21",
    eligibleStudents: 2315,
    studentsPlaced: 2162,
    placementPercentage: 93.9,
  },
  {
    year: "2021-22",
    eligibleStudents: 2270,
    studentsPlaced: 2174,
    placementPercentage: 94.23,
  },
  {
    year: "2022-23",
    eligibleStudents: 3342,
    studentsPlaced: "3154*",
    placementPercentage: "94.33*",
  },
];

const SummarySection: React.FC<{}> = () => {
  const [renderChart, setRenderChart] = useState(true);
  const years = SummaryTable.map((item) => item.year);
  const eligibleStudents = SummaryTable.map((item) => item.eligibleStudents);
  const studentsPlaced = SummaryTable.map((item) => item.studentsPlaced);

  const data = {
    labels: years,
    datasets: [
      {
        label: "Eligible Students",
        data: eligibleStudents,
        backgroundColor: "rgba(75,192,192,0.6)",
      },
      {
        label: "Students Placed",
        data: studentsPlaced,
        backgroundColor: "rgba(153,102,255,0.6)",
      },
    ],
  };
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-5 justify-center items-center">
            <h2 className="text-3xl font-bold">
              Placements Summary(last 5 years)
            </h2>
            <p className="text-lg text-[#6b7280]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Praesentium mollitia cum voluptate, iusto delectus, voluptatum
            </p>
          </div>
          <div className="flex flex-col gap-24 justify-center items-center relative">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-white uppercase bg-blue-gray-800 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Desription/Year
                  </th>
                  {SummaryTable.map((table, index) => (
                    <th key={index} scope="col" className="px-6 py-3">
                      {table.year}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Eligible Students
                  </td>
                  {SummaryTable.map((table, index) => (
                    <td key={index} className="px-6 py-4">
                      {table.eligibleStudents}
                    </td>
                  ))}
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Students Placed
                  </td>
                  {SummaryTable.map((table, index) => (
                    <td key={index} className="px-6 py-4">
                      {table.studentsPlaced}
                    </td>
                  ))}
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    % Placement
                  </td>
                  {SummaryTable.map((table, index) => (
                    <td key={index} className="px-6 py-4">
                      {table.placementPercentage}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-col gap-5 justify-center items-center">
            {/* {renderChart && <Bar data={data} />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummarySection;
