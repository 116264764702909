import React, { useEffect, useState } from "react";
import Blogs from "../../../images/blog-article/blogsandarticles.jpeg";
import moment from "moment";
import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/filter/customHooks/usePagination";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";

interface NavDropdownProps {
  subCategories: any;
  category: any;
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  subCategories,
  category,

  //   setHoveredMenu,
}) => {
  const dispatch = useDispatch();

  // console.log("NavDropdown - subCategories", subCategories);
  const subCategoriesKeys = Object.keys(subCategories);

  const [hoveredSubTopicIndex, setHoveredSubTopicIndex] = useState(0);
  const hoveredSubTopic = subCategoriesKeys[hoveredSubTopicIndex];

  const [blogList, setBlogList] = useState(
    subCategories[hoveredSubTopic]?.paginatedResponse
  );

  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    goToPage,
    handleResetPagination,
  } = usePagination(4);

  const handleMouseEnteredOnSubTopic = (selectedSubTopic: any, index: any) => {
    setHoveredSubTopicIndex(index);
    dispatch(
      setCurrentCategoryPageValue({
        category: category,
        subCategory: subCategoriesKeys[index],
      })
    );
  };
  useEffect(() => {
    //set the sub category to initial value on each hover of the categories - 0
    setHoveredSubTopicIndex(0);
  }, [subCategories]);

  useEffect(() => {
    updateTotalCount(
      subCategories[hoveredSubTopic]?.paginatedResponse.totalNoOfPages *
        subCategories[hoveredSubTopic]?.paginatedResponse.currentPageSize
    );
    handleResetPagination();
  }, [subCategories, hoveredSubTopic]);

  useEffect(() => {
    if (currentPage == 1)
      setBlogList(subCategories[hoveredSubTopic]?.paginatedResponse);
    else {
      axios({
        url: `https://mentormenteedev.com/api/blogs/topic-details`,
        method: "POST",
        data: {
          topic: category,
          subTopic: hoveredSubTopic,
          currentPage: currentPage,
          currentPageSize: 4,
        },
      }).then((response) => {
        setBlogList(response.data?.details);
      });
    }
  }, [subCategories, hoveredSubTopic, currentPage]);

  console.log(blogList);

  return (
    <>
      <div
        className={`container sm:w-full w-full md:w-full absolute lg:w-full mx-auto left-0 right-0 pr-2 pl-0 z-[1111] bg-white rounded-none gap-4 flex justify-start items-start`}
        style={{ boxShadow: "0 5px 4px rgba(0,0,0,.161)" }}
      >
        <div className="hidden w-full grid-flow-col grid-rows-1 gap-4 md:block">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-1 w-full height-full flex flex-col text-end h-100 bg-[#00C798]">
              <div className="w-full">
                <ul className="flex flex-col sub-topics gap-y-0">
                  {subCategoriesKeys?.map((subCategory: any, index: any) => (
                    <li
                      key={index}
                      onMouseOver={(e) =>
                        handleMouseEnteredOnSubTopic(subCategory, index)
                      }
                      className={
                        hoveredSubTopicIndex == index
                          ? "menu-items p-3 cursor-pointer bg-[#ffffff] text-[#173CBA]"
                          : "menu-items p-3 cursor-pointer text-[#ffffff]"
                      }
                    >
                      {subCategory}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-span-5">
              <div className="grid w-full grid-cols-1 gap-4 py-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
                {blogList?.results?.map((blog: any, index: any) => (
                  <div className="">
                    <div className="max-w-sm">
                      <Link
                        to={`${category}/${blog.title}`}
                        state={{
                          from: "BlogHeader",
                          blogDetail: blog,
                        }}
                      >
                        <img src={blog.imageUrl} />
                      </Link>
                      <p className="flex mb-2 font-normal text-gray-700 whitespace-normal dark:text-gray-400">
                        {blog.title}
                      </p>
                      <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                        {`${moment(blog?.publishDate).format("LL")}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  goToPage={goToPage}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Working version */}

        {/* <div className="flex flex-row relative items-center justify-end bg-[#00C798]">
          <ul className="flex flex-col sub-topics gap-y-0">
            {subMenus?.map((title: any, index: any) => (
              <li key={index} className="p-3 menu-items">
                {title}
              </li>
            ))}
          </ul>
        </div> */}

        {/* <div className="grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4">
          <div className="w-full">
            <a href="#">
              <img src={Blogs} />
            </a>
            <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
              Environmental impact on E-waste
            </p>
            <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
              July 14 2024
            </p>
          </div>

          <div className="">
            <div className="max-w-sm">
              <a href="#">
                <img src={Blogs} />
              </a>
              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Environmental impact on E-waste
              </p>
              <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                July 14 2024
              </p>
            </div>
          </div>
          <div className="">
            <div className="max-w-sm">
              <a href="#">
                <img src={Blogs} />
              </a>
              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Environmental impact on E-waste
              </p>
              <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                July 14 2024
              </p>
            </div>
          </div>
          <div className="">
            <div className="max-w-sm">
              <a href="#">
                <img src={Blogs} />
              </a>
              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Environmental impact on E-waste
              </p>
              <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                July 14 2024
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default NavDropdown;
