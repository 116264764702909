// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-scroll::-webkit-scrollbar {
  width: 6px;
}

.filter-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.filter-scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 999px;
}

@supports (-moz-appearance: none) {
  .filter-scroll {
    scrollbar-width: thin;
    scrollbar-color: #fff transparent;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/filter/components/DesktopFilterItem.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE;IACE,qBAAA;IACA,iCAAA;EACF;AACF","sourcesContent":[".filter-scroll::-webkit-scrollbar {\n  width: 6px;\n}\n\n.filter-scroll::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.filter-scroll::-webkit-scrollbar-thumb {\n  background: #fff;\n  border-radius: 999px;\n}\n\n@supports (-moz-appearance: none) {\n  .filter-scroll {\n    scrollbar-width: thin;\n    scrollbar-color: #fff transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
