import React from "react";
import dm1 from "../../../../images/college/details/course-fee/dm1.png";
import dm2 from "../../../../images/college/details/course-fee/dm2.png";
import dm3 from "../../../../images/college/details/course-fee/dm3.png";
import dm4 from "../../../../images/college/details/course-fee/dm4.png";
import dm5 from "../../../../images/college/details/course-fee/dm5.png";
import dm6 from "../../../../images/college/details/course-fee/dm6.png";
// import arrowCircle from "../../images/dream-mentor/arrow-circle.png";
// import arrowRight from "../../images/dream-mentor/arrow-right.png";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
// import "./Dream-mentor.scss";

const facultyContent = {
  faculty: [
    {
      image: dm1,
      name: "Jane Cooper",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
    {
      image: dm2,
      name: "Wade Warren",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
    {
      image: dm3,
      name: "Jenny Wilson",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
    {
      image: dm4,
      name: "Robert Fox",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
    {
      image: dm5,
      name: "Jane Cooper",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
    {
      image: dm6,
      name: "Jane Cooper",
      designation: "Voice Over Artist, Teacher, Madhya Pradesh",
    },
  ],
};

export default function Faculty() {
  return (
    <div className="dream-mentor-main pt-8" id="Faculty">
      <div className="container mx-auto px-4">
        <div className="flex justify-center items-center">
          <h3 className="text-black text-3xl font-extrabold">Faculty</h3>
        </div>

        {/* slider section starts */}
        <div className="swiper-section mx-auto p-8 relative">
          <Swiper
            slidesPerView={4}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="flex flex-row w-full justify-center items-center">
              {facultyContent.faculty.map((faculty, index) => (
                <SwiperSlide>
                  <div key={index} className="mentor-card">
                    <img
                      loading="lazy"
                      src={faculty.image}
                      alt="dream mentor"
                      className="self-center"
                    />
                    <div className="mentor-details">
                      <h3 className="mentor-name">{faculty.name}</h3>
                      <p>{faculty.designation}</p>
                      <span>
                        View details
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                        >
                          <path
                            d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                            fill="#113CC0"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>

            <div className="flex flex-row w-full justify-center items-center absolute top-[79%] z-10">
              <a
                href="#"
                className="blue-color button-styles py-3 px-6 mt-4 rounded-full flex flex-row items-center justify-center text-[#113CC0] gap-2 text-sm font-semibold hover:text-white"
              >
                View all Faculty
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </Swiper>
        </div>
      </div>
      {/* slider section ends */}
    </div>
  );
}
