import React, { useEffect, useState } from "react";
import jeeMains from '../Images/jee-main-exam-centres.webp'
import reviews_banner from '../Images/reviews_banner.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ChatWithAmbasiddors } from "./Pick-Info/ChatWithAmbasiddors";
import { Visual_stories } from "./Pick-Info/Visual_stories";
import Contact_College_Details from "./Pick-Info/Contact_College_Details";

interface DataItem {
  question: string;
  answer: string;
}

const data: DataItem[] = [
  {
    question: "Why to study in Masters?",
    answer:
      "Yes! High-quality customer support is extremely important for a quality product, that’s why we do our best to resolve any issues you may encounter."
  },
  {
    question: "Can you study in English in Manchester?",
    answer:
      "We value our clients and you can always feel free to contact us for:Installing and adjusting\n bug fixing and troubleshooting"
  },
  {
    question: "How Many Universities are in Manchester?",
    answer:
      "We are ready to help with this! You can always contact us at apps@elfsight.com and we will help you to set up and change the settings of the app on your website the way you need."
  },
  {
    question: "What is the Best University in Manchester?",
    answer:
      "We are ready to help with this! You can always contact us at apps@elfsight.com and we will help you to set up and change the settings of the app on your website the way you need."
  }
];
interface PickInfoTabProps {
  review: string;
  VisualStories: string;
  Contacts: string;
  news: string;
}
interface Tab {
  label: string;
  content: React.ReactNode;
}
interface TabsProps {
  tabs: Tab[];
}
interface TabPanelProps {
  children: React.ReactNode;
  isActive: boolean;
}
const TabPanel: React.FC<TabPanelProps> = ({ children, isActive }) => {
  return isActive ? <div className="mt-5" style={{ background: '#DEE1E6' }}>{children}</div> : null;
};
interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}
const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => {
  return (
    <div
      className={`cursor-pointer px-4 py-2  ${isActive ? 'active_tab' : 'inactive_tab_pickInfo'}`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};
const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);
  return (
    <div className='grid justify-items-center' >
      <div className="flex">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            isActive={activeTab === index}
            onClick={() => setActiveTab(index)}
          />
        ))}
      </div>
      {tabs.map((tab, index) => (
        <TabPanel key={index} isActive={activeTab === index}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};
const PickInfoTab: React.FC<PickInfoTabProps> = ({ review,VisualStories,Contacts ,news}) => {
  const [selected, setSelected] = useState<number | null>(null);

  const toggle = (index: number) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  const tabs: Tab[] = [
    { label: 'Admissions', content: <Admission /> },
    { label: 'Placements', content: <Placements /> },
    { label: 'Research', content: <Research /> },
    { label: 'Campus life', content: <CampusLife /> },
    { label: 'Achievements', content: <Achievements /> },
  ];
  return (
    <>
      <div id={news} className='container my-8'>
        <Tabs tabs={tabs} />
      </div>
      <div id={review}>
        <div className="font-bold container my-5" style={{ fontSize: '1.3rem' }}>Reviews</div>
        <div className="relative">
          <img src={reviews_banner} style={{ width: '100%', filter: 'blur(1px) contrast(40%) ' }} />
          <div className="flex container  banner_review_content_position ">
            <div className="mx-10 banner_review_content">Over 5,000 verified students share their experiences every month</div>
            <div className="flex items-center gap-0">

              <img className="w-10 h-10  rounded-full ring-2 ring-dark-300 dark:ring-dark-500 shadow-lg" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnxHfZgbkATzAj8TNKBxN8H8Rt4RWFu0OZhtriXUAjmA&s" alt="Bordered avatar" />
              <img className="w-10 h-10  rounded-full ring-2 ring-dark-300 dark:ring-dark-500 shadow-lg" src="https://i.pinimg.com/736x/aa/c9/4e/aac94e41310947cbcd5f38a41ccc0132.jpg" alt="Bordered avatar" />
              <img className="w-10 h-10  rounded-full ring-2 ring-dark-300 dark:ring-dark-500 shadow-lg" src="https://img.freepik.com/free-photo/smiling-happy-indian-student-with-backpack-pointing-his-finger-wall_496169-1532.jpg" alt="Bordered avatar" />
              <img className="w-10 h-10  rounded-full ring-2 ring-dark-300 dark:ring-dark-500 shadow-lg" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAMqZQfNdjhsV7CjzK4WNUn8rbIK6E8oOYisJy7XYkLA&s" alt="Bordered avatar" />
              <img className="w-10 h-10  rounded-full ring-2 ring-dark-300 dark:ring-dark-500 shadow-lg" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnxHfZgbkATzAj8TNKBxN8H8Rt4RWFu0OZhtriXUAjmA&s" alt="Bordered avatar" />
              <div className="messageBox shadow-lg">
                <div className="fileUploadWrapper">
                  <label >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
                      <circle
                        stroke-width="20"
                        stroke="#6c6c6c"
                        fill="none"
                        r="158.5"
                        cy="168.5"
                        cx="168.5"
                      ></circle>
                      <path
                        stroke-linecap="round"
                        stroke-width="25"
                        stroke="#6c6c6c"
                        d="M167.759 79V259"
                      ></path>
                      <path
                        stroke-linecap="round"
                        stroke-width="25"
                        stroke="#6c6c6c"
                        d="M79 167.138H259"
                      ></path>
                    </svg>
                    <span className="tooltip">Add an image</span>
                  </label>
                  <input type="file" id="file" name="file" />
                </div>
                <input placeholder="Write Your Review..." type="text" id="messageInput" />
                <button id="sendButton">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                    <path
                      fill="none"
                      d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="33.67"
                      stroke="#6c6c6c"
                      d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                    ></path>
                  </svg>
                </button>
              </div>

            </div>
          </div>
          <div className="review_card_positions mx-20 mb-20">
            {Array.from({ length: 2 }, (_, index) => (
              <div className="container   mx-auto px-4" >
                <div className="flex flex-wrap -mx-4">
                  <div data-aos="flip-left" className="w-full  md:w-1/3 px-4 my-3 md:mb-0">
                    <div>
                      <figure className="snip1390 shadow-lg">
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" className="profile" />
                        <figcaption>
                          <h2>Eleanor Crisp</h2>
                          <h4>UX Design</h4>
                          <hr />
                          <div className="">
                            <div style={{ color: '#C1AFA1' }}>Student AT</div>
                            <div style={{ color: '#FFFFFF' }} >Jawaharlal Nehru University</div>
                          </div>
                          <hr />
                          <blockquote>Dad buried in landslide! Jubilant throngs fill streets! Stunned father inconsolable - demands recount!

                            <br />
                            <div className="flex items-center mt-5">
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">4.95</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">out of</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">5</p>
                            </div>
                          </blockquote>
                        </figcaption>
                      </figure>




                    </div>
                  </div>
                  <div data-aos="flip-left" className="w-full md:w-1/3 px-4 my-3 md:mb-0">
                    <div>
                      <figure className="snip1390 shadow-lg"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg" alt="profile-sample6" className="profile" />
                        <figcaption>
                          <h2>Sue Shei</h2>
                          <h4>Public Relations</h4>

                          <hr />
                          <div className="">
                            <div style={{ color: '#C1AFA1' }}>Student AT</div>
                            <div style={{ color: '#FFFFFF' }} >Anurag University</div>
                            <hr />
                          </div>
                          <blockquote>The strength to change what I can, the inability to accept what I can't and the incapacity to tell the difference.
                            <br />
                            <div className="flex items-center mt-5">
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">4.95</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">out of</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">5</p>
                            </div>
                          </blockquote>
                        </figcaption>
                      </figure>
                    </div>

                  </div>
                  <div data-aos="flip-left" className="w-full md:w-1/3 px-4 my-3 md:mb-0">
                    <div>
                      <figure className="snip1390 hover"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" className="profile" />
                        <figcaption>
                          <h2>Gordon Norman</h2>
                          <h4>Accountant</h4>
                          <hr />
                          <div className="">
                            <div style={{ color: '#C1AFA1' }}>Student AT</div>
                            <div style={{ color: '#FFFFFF' }} >Institute of Chemical Technology</div>

                          </div>
                          <hr />
                          <blockquote>Wormwood : Calvin, how about you? Calvin : Hard to say ma'am. I think my cerebellum has just fused.
                            <br />
                            <div className="flex items-center mt-5">
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">4.95</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">out of</p>
                              <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">5</p>
                            </div>
                          </blockquote>
                        </figcaption>
                      </figure>
                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="wrapper">
          <div className="container mx-auto px-4 py-8">
            <div className="accordion_title">Frequently Asked Quetions About Manchester</div>
            <div className="">
              {data.map((item, index) => (
                <div data-aos="zoom-in-left" className="item shadow-lg my-5" key={index}>
                  <div
                    className="title bg-gray-200 p-4 cursor-pointer  flex items-center justify-between"
                    onClick={() => toggle(index)}
                  >
                    <h2 className="text-xl font-semibold">{item.question}</h2>
                    <span style={{ fontSize: '1.5rem' }}>{selected === index ? "-" : "+"}</span>
                  </div>
                  <div
                    className={
                      selected === index
                        ? "content bg-white p-4  border-l border-b border-r border-gray-200 show"
                        : "content bg-white  border-l border-b border-r border-gray-200"
                    }
                  >
                    <p className="accordion_content">{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ChatWithAmbasiddors/>
      <Visual_stories VisualStories={VisualStories}/>
      <Contact_College_Details Contacts={Contacts}/>
      
    </>
  );
};
export default PickInfoTab;
const Admission = () => {
  return (
    <>
      {[0, 1, 2].map(p => <div className="flex  gap-20">
        <div className="w-1/2 p-4">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4 ">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}
const Placements = () => {
  return (
    <>
      {[0, 1, 2].map(p => <div className="flex  gap-20">
        <div className="w-1/2 p-4">
          <div className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4 ">
          <div className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}
const Research = () => {
  return (
    <>
      {[0, 1, 2].map(p => <div className="flex  gap-20">
        <div className="w-1/2 p-4">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4 ">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}
const CampusLife = () => {
  return (
    <>
      {[0, 1, 2].map(p => <div className="flex  gap-20">
        <div className="w-1/2 p-4">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4 ">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}
const Achievements = () => {
  return (
    <>
      {[0, 1, 2].map(p => <div className="flex  gap-20">
        <div className="w-1/2 p-4">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4 ">
          <div data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0" className="flex items-center gap-10">
            <div className="w-1/2 p-4">
              <img src={jeeMains} style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-4 font-bold">
              Reminder: JEE Mains exam approaching!
            </div>
          </div>
        </div>
      </div>)}
      <div>
       

      </div>
    </>
  )
}