import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

interface BlogCardProps {
  items: {
    title: string;
    description: string;
    image: string;
    publishDate: string;
  }[];
}

const BlogCard: React.FC<BlogCardProps> = ({ items }) => {
  return (
    <Swiper
      spaceBetween={16}
      modules={[Navigation]}
      breakpoints={{
        640: { slidesPerView: 1, slidesPerGroup: 1 },
        768: { slidesPerView: 2, slidesPerGroup: 2 },
        1024: { slidesPerView: 4, slidesPerGroup: 4 },
      }}
      className="ExploreMore w-full"
      navigation={{
        prevEl: ".mentor-prev-btn",
        nextEl: ".mentor-next-btn",
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <div className='px-4'>
          <div className=" bg-white rounded-xl overflow-hidden  translate-y-10  ">
            
            <a href="/blogdetails-page">
              <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
            </a>
            <div className="flex flex-col p-4">
              <h4 className="text-base font-semibold text-black line-clamp-1 mb-2">
                {item.title}
              </h4>
              <p className="text-sm text-gray-600 mb-2">
                {item.publishDate}
              </p>
              <p className="text-sm text-gray-600 line-clamp-2">
                {item.description}
              </p>
            </div></div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BlogCard;
