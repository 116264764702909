import React from "react";
import ArticlesBlogs from "./components/ArticlesBlogs";
import GetAdmissionAdvise from "./components/GetAdmissionAdvise";
import WhatWeOffer from "./components/WhatWeOffer";
import CollegeAdmissionProcess from "./components/CollegeAdmissionProcess";
import Testimonials from "./components/Testimonials";
import ApplicatonToAdmission from "./components/ApplicationToAdmission";
import TopColleges from "./components/TopColleges";
import ExploreCollegeLife from "./components/ExploreCollegeLife";

const ApplicationsDirectAdmissions = () => {
  return (
    <div>
      <TopColleges />

      <CollegeAdmissionProcess />

      <GetAdmissionAdvise />
      <ExploreCollegeLife />
      <Testimonials />
      <ApplicatonToAdmission />
      <ArticlesBlogs />
    </div>
  );
};

export default ApplicationsDirectAdmissions;
