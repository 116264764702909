import {
    faChevronLeft,
    faChevronRight,
    faBars
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NavHeaderProps {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  const NavHeader: React.FC<NavHeaderProps> = ({ expanded, setExpanded }) => 
    {
return(
    <>
      <div  style={{
            transition: "width 0.5s",
            width: expanded ? "10rem" : "3.5rem",
          }}
          className="flex flex-row items-center space-x-4 overflow-hidden">
  <img src="/images/CM.png" alt="Icon" className="w-[30px] h-[30px]" />
  
  <div className="flex flex-col"  style={{
              transition: "opacity 0.5s",
              opacity: expanded ? 1 : 0,
            }}>
    <div className="font-bold text-[12px] whitespace-nowrap">College Mentor</div>
    <div className="text-grey text-[10px] whitespace-nowrap">Education panel</div>
  </div>
  
  <div className="ml-auto cursor-pointer"  style={{
              transition: "opacity 0.5s",
              opacity: expanded ? 1 : 0,
            }}        onClick={() => setExpanded((prev) => !prev)}
  >
          <FontAwesomeIcon icon={faBars} className="text-[16px]" />
        </div>
</div>
    </>
)
}

export default NavHeader;