import Accordion from "../../components/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const KnowledgeHub = () => {
  const data = useSelector(
    (state: RootState) =>
      state.collegePredictorIntermediate.landingData?.knowledgeHubFaqs
  );

  return (
    <section className="py-12 bg-[#FFF1FA] text-black">
      <div className="container flex flex-col gap-4">
        <h1 className="text-4xl lg:text-5xl text-center">
          <span className="font-bold">Knowledge Hub</span> (FAQs)
        </h1>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-2">
          {data &&
            data.map((item: { question: string; answer: string }) => (
              <Accordion question={item.question} answer={item.answer} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
