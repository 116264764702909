import { useState, useEffect } from "react";
import "./Dreams.scss";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const DreamsCard = ({
  image,
  name,
  experience,
  role,
}: {
  image: string;
  name: string;
  experience: string;
  role: string;
}) => (
  <div className="cards-individual bg-white w-[243px] h-[260px]  relative translate-x-12  lg:translate-x-0 ">
    <div className="flex flex-col justify-center relative -translate-y-8">
      <img
        className="cardimage absolute w-[90px] h-[90px] rounded-full top-8 left-20 z-100"
        src={image}
      />
      <div className=" bg-white w-[243px] h-[201px] rounded-lg relative">
        <img
          src="/images/College-landing/dreams/Group.svg"
          className="absolute top-2 right-2"
        />
        <div className="name-cards mt-12 -translate-y-4"> {name} </div>
        <div className="experience-cards"> {experience} </div>
        <div className="role-cards text-center"> {role} </div>
        <div className="flex justify-center m-4">
          <div className=" w-[156px] h-[41px] bg-[#ECFFFF] rounded-[50px] flex flex-row justify-between pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
            >
              <path
                d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
                fill="#00CC99"
              />
            </svg>
            <div className="top-men"> TOP MENTOR </div>
            <a
              href="https://mentee-mentor-dev.vercel.app/mentor-details"
              className="arrow-ani"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              >
                <path
                  d="M11.2 22.4C17.3856 22.4 22.4 17.3856 22.4 11.2C22.4 5.01441 17.3856 0 11.2 0C5.01441 0 0 5.01441 0 11.2C0 17.3856 5.01441 22.4 11.2 22.4Z"
                  fill="#00CC99"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4097 11.2C19.4097 11.7432 19.1241 12.2192 18.7041 12.4992L11.6033 18.1104C10.9369 18.6424 9.96248 18.5248 9.43608 17.8584C8.90408 17.192 9.02168 16.2176 9.68808 15.6912L13.4065 12.7456H4.54168C3.68488 12.7456 2.99048 12.0568 2.99048 11.2C2.99048 10.3432 3.68488 9.64879 4.54168 9.64879H13.4121L9.69368 6.70319C9.02728 6.17679 8.90968 5.20799 9.44168 4.53599C9.96808 3.86959 10.9425 3.75199 11.6089 4.28399L18.7041 9.90079C19.1241 10.1808 19.4097 10.6568 19.4097 11.2Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const Dreams = () => {
  const { dreamsHappen, cardsDreams } = useSelector(
    (state: RootState) => state.collegeLanding
  );
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if(dreamsHappen && dreamsHappen.length)
         setCurrentIndex((prevIndex) => (prevIndex + 1) % dreamsHappen.length);
    }, 5000);

    return () => clearInterval(interval); 
  }, [dreamsHappen]);
  if (!dreamsHappen || dreamsHappen.length == 0 || !dreamsHappen[currentIndex]) return null;
  if (!cardsDreams || cardsDreams.length == 0) return null;

  const { 
    bg , 
    title, 
    bannerPath , 
    bendPath, 
    description , 
    name , 
    college 
  } = dreamsHappen[currentIndex];
  const cardContent = cardsDreams.map((card, index) => (
    <DreamsCard
      key={index} 
      {...card}
    />
  ));

  return (
    <div className="Dreams" style={{ backgroundColor: bg }}>
      <div className="container flex flex-col py-12 overflow-hidden">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2 translate-x-0 md:translate-x-24 lg:translate-x-0">
            <img src={bannerPath} />
          </div>
          <div className="lg:w-1/2 flex  lg:justify-start lg:items-start flex-col">
            <div className="title-dreams text-center lg:px-20 "> {title} </div>
            <div className="hidden md:block">
            <div className=" w-[584px] h-[241px] flex-shrink-0 -translate-y-4  -translate-x-32 md:translate-x-12 lg:translate-x-0">
              <div className="">
                <div className=" curved-text-container flex justify-start items-start  lg:justify-center lg:items-center">
                  <svg width="600" height="300">
                    <defs>
                      <path id="curve1" d="M10,150 Q300,0 590,150" />
                      <path id="curve2" d="M10,200 Q300,50 590,200" />
                      <path id="curve3" d="M10,250 Q300,100 590,250" />
                    </defs>
                    <text fontSize="32" fill="#FFFFFF">
                      <textPath
                        href="#curve1"
                        startOffset="50%"
                        textAnchor="middle"
                      >
                        Thanks to
                      </textPath>
                    </text>
                    <text fontSize="48" fill="#FFFFFF" fontWeight="bold">
                      <textPath
                        href="#curve2"
                        startOffset="50%"
                        textAnchor="middle"
                      >
                        COLLEGE MENTOR
                      </textPath>
                    </text>
                    <text fontSize="48" fill="#FFFFFF" fontWeight="bold">
                      <textPath
                        href="#curve3"
                        startOffset="50%"
                        textAnchor="middle"
                      >
                        {bendPath}
                      </textPath>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div className=" text-center font-roboto font-bold text-3xl leading-10 text-primary lg:pl-12 lg:pr-40 text-[#173CBA]">
              {description}
            </div>
            <div className="absolute left-[30%] lg:left-[75%] pt-6 lg:pt-4 ">
              <p className="name-dreams"> {name} </p>
              <p className="uni-dreams"> {college} </p>
            </div>
            <div className="lg:mb-4"></div>
            </div>
          </div>
        </div>
        <div className="cards-container flex justify-center mt-12 ">
          <CustomSwiper
            className="!px-2"
            items={cardContent}
            navigation={"hidden"}
            autoplay={true}
            breakpoints={{
              220: { slidesPerView: 1, spaceBetween: 20 },
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 5, spaceBetween: 20 },
              1280: { slidesPerView: 5, spaceBetween: 30 },
            }}
            progressBar={false}
          />
        </div>
      </div>
    </div>
  );
};
export default Dreams;
