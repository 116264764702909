import React, { useState } from "react";
import "./App.css";
import useCopyWithMessage from "./hooks/clipboard/useCopyWithMessage";
import theme from "./components/ThemeComponent";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Navigate,
} from "react-router-dom";

// import Login from "./screens/sign-in-form/Login";
import LandingPage from "./screens/Landing/Landing";
import { ThemeProvider } from "@mui/material/styles";
import ForgotPassword from "./screens/sign-in-form/ForgotPassword";
import OTPVerification from "./screens/sign-in-form/OTPVerification";
import PasswordReset from "./screens/sign-in-form/PasswordReset";
import Registration from "./screens/sign-in-form/Registration";
import PersonalInfo from "./screens/sign-in-form/PersonInfo";
import StandardInfo from "./screens/sign-in-form/StandardInfo";
import ForgotEmail from "./screens/sign-in-form/ForgotEmail";
import DreamCareerInfo from "./screens/sign-in-form/DreamCareerInfo";
import Layout from "./components/Layout";
import ResetPassword from "./screens/sign-in-form/ResetPassword";
import StateInfo from "./screens/sign-in-form/StateInfo";
import SignInOutlet from "./screens/sign-in-form/SignInOutlet";
import PlansToFund from "./screens/sign-in-form/PlansToFund";
import Careers from "./screens/careers/careers";
import { Exams } from "./screens/exams/Exams";
import { Colleges } from "./screens/colleges/Colleges";
import Courses from "./screens/courses/courses";
import CareerDetails from "./screens/career-details/CareerDetails";
import CollegeDetails from "./screens/college-details/CollegeDetails";
import DreamCareer from "./components/dream-careers/DreamCareer";
import ExamDetails from "./screens/exam-details-new/ExamDetails";

import CollegeIntermediate from "./screens/colleges/CollegeIntermediate";
import MentorsMain from "./screens/Mentor/MentorsMain";
import CourseDetails from "./screens/course-details/CourseDetails";
import CourseIntermediate from "./screens/course-intermediate/CourseIntermediate";
import CollegeIntermediate2 from "./screens/colleges/CollegeIntermediate2";
import NotFoundPage from "./screens/notfound-page/NotFoundPage";
import { CourseDetailsProvider } from "./screens/course-details/CourseDetailsContext";
import BlogDetailsPage from "./screens/blogdetails-page/BlogDetailsPage";
import Modal from "./components/courses/quiz/Modal.js";
import Quiz from "./components/courses/quiz/Quiz.js";
import Analysis from "./components/courses/quiz/Analysis.js";
import ReviewPage from "./screens/review-page/ReviewPage";
import Scholarships from "./screens/Landing/components/Scholarships";
import CollegeLanding from "./screens/colleges-new/landing/CollegeLanding";
import BlogsLayout from "./screens/blogs-articles/components/BlogsLayout";
import BlogsAndArticlesMain from "./screens/blogs-articles/blogs-articles-landing/BlogsAndArticlesMain";
import DownloadExams from "./screens/exams/download-exams/DownloadExams";
import NewsDetails from "./screens/news-details/NewsDetails";
import CollegeCompare from "./screens/college-compare/CollegeCompare";
import CollegeReviews from "./screens/college-reviews/CollegeReviews";
import CollegeScholarships from "./screens/college-scholarships/CollegeScholarships";
import EducationLoan from "./screens/education-loan/EducationLoan";
import CollegeAdmissions from "./screens/college-admissions/CollegeAdmissions";
import BlogsIntermediate from "./screens/blogs-articles/blogs-articles-intermediate/BlogsIntermediate";
import BlogDetails from "./screens/blogs-articles/blogs-articles-details/BlogDetails";
import Examalerts from "./screens/exams/components/Examalerts";
import TcyOnline from "./screens/tcy-online/TcyOnline";
import MentorDetails from "./screens/mentor-details/MentorDetails";
import ExamIntermediate from "./screens/exam-intermediate/ExamIntermediate";
import ScrollTop from "./components/ScrollTop";
import CourseCompare from "./screens/course-compare/CourseCompare";
import ApplicationForms from "./screens/application-forms/ApplicationForms";
import CollegePredictorLanding from "./screens/college-predictor/landing/CollegePredictorLanding";
import CollegePredictorIntermediate from "./screens/college-predictor/intermediate/CollegePredictorIntermediate";
import CollegeRankings from "./screens/college-rankings/CollegeRankings";
import CareerIntermediate from "./screens/career-intermediate/CareerIntermediate";
import MapView from "./screens/colleges/components/MapView";
import ApplicationsDirectAdmissions from "./screens/applications-admissions/ApplicationsDirectAdmissions";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./features/auth/login/Login";
import Registeration from "./features/auth/registration/Registration";
import PrivateRoute from "./routes/ProtectedRoute";
import DashboardLayout from "./layouts/DashboardLayout";
import Dashboard from "./features/dashboard/DashboardHome";
import dashboard from "./features/dashboard/DashboardHome";
import DashboardHome from "./features/dashboard/DashboardHome";
import MyProfile from "./features/dashboard/my-profile/MyProfile";
import Mentor from "./features/dashboard/mentors/Mentors";
import ProtectedRoute from "./routes/ProtectedRoute";
// import Dashboard from "./screens/dashboard/Dashboard";
// import MyProfile from "./screens/dashboard/components/MyProfile";
import Admissions from "./features/dashboard/college-admission/Admissions";
import ScholarshipLanding from "./screens/scholarship/landing/ScholarshipLanding";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StudyAbroadLanding } from "./screens/study-abroad/StudyAbroadLanding";
import CollegePredictorResults from "./screens/college-predictor-result/CollegePredictorResult";
import ScholarshipIntermediate from "./screens/scholarship/intermediate/ScholarshipIntermediate";
function App() {
  useCopyWithMessage();

  const [selectedCollege, setSelectedCollege] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      {/* <CustomizedDialogs /> */}
      <div className="App">
        <Router>
          <ScrollTop />
          {/* auth-wrapper auth-inner*/}
          <div className="router-class router-container">
            <div className="router-class">
              <Routes>
                <Route path="/college" element={<Layout />}>
                  <Route path="/college" element={<CollegeLanding />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/college-details" element={<CollegeDetails />} />
                  <Route
                    path="/college-details/:collegeName"
                    element={
                      <CollegeDetails selectedCollege={selectedCollege} />
                    }
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/course-details/:courseName"
                    element={<CourseDetailsWrapper />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/exam-details" element={<ExamDetails />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/exam-intermediate"
                    element={<ExamIntermediate />}
                  />
                </Route>
                <Route path="/careers" element={<Layout />}>
                  <Route path="/careers" element={<Careers />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/career-intermediate"
                    element={<CareerIntermediate />}
                  />
                </Route>
                <Route path="/mentor" element={<Layout />}>
                  <Route path="/mentor" element={<MentorsMain />} />
                </Route>
                <Route path="/mentor-details" element={<Layout />}>
                  <Route path="/mentor-details" element={<MentorDetails />} />
                </Route>
                <Route path="/college-admissions" element={<Layout />}>
                  <Route
                    path="/college-admissions"
                    element={<CollegeAdmissions />}
                  />
                </Route>
                <Route path="/college-compare" element={<Layout />}>
                  <Route path="/college-compare" element={<CollegeCompare />} />
                </Route>
                <Route path="/college-reviews" element={<Layout />}>
                  <Route path="/college-reviews" element={<CollegeReviews />} />
                </Route>
                <Route path="/college-scholarships" element={<Layout />}>
                  <Route
                    path="/college-scholarships"
                    element={<CollegeScholarships />}
                  />
                </Route>
                <Route path="/education-loan" element={<Layout />}>
                  <Route path="/education-loan" element={<EducationLoan />} />
                </Route>
                <Route
                  path="/careers/career-details/:careerName"
                  element={<Layout />}
                >
                  <Route
                    path="/careers/career-details/:careerName"
                    element={<CareerDetails />}
                  />
                </Route>
                <Route path="/courses" element={<Layout />}>
                  <Route path="/courses" element={<Courses />} />
                  <Route path="/courses/quiz-modal" element={<Modal />} />
                  <Route path="/courses/quiz-start" element={<Quiz />} />
                  <Route path="/courses/quiz-analysis" element={<Analysis />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/" element={<LandingPage />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/exams" element={<Exams />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/exam-alerts" element={<Examalerts />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/career-details" element={<CareerDetails />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/blogdetails-page"
                    element={<BlogDetailsPage />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/review-page" element={<ReviewPage />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/download-exams" element={<DownloadExams />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/news-details" element={<NewsDetails />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/tcy-online" element={<TcyOnline />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/course-compare" element={<CourseCompare />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/application-forms"
                    element={<ApplicationForms />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/applications-admissions"
                    element={<ApplicationsDirectAdmissions />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/college-intermediate"
                    element={
                      <CollegeIntermediate
                        setSelectedCollege={setSelectedCollege}
                      />
                    }
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/college-intermediate2"
                    element={<CollegeIntermediate2 />}
                  />
                </Route>

                <Route element={<Layout />}>
                  <Route
                    path="/courses/:pageTitle"
                    element={<CourseIntermediate />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/college-predictor-result"
                    element={<CollegePredictorResults />}
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/scholarships"
                    element={<ScholarshipLanding />}
                  />
                </Route>
                {/* Blogs and Articles */}

                <Route path="blogs" element={<BlogsLayout />}>
                  <Route path="" element={<BlogsAndArticlesMain />}></Route>
                  <Route
                    path=":category"
                    element={<BlogsIntermediate />}
                  ></Route>
                  <Route
                    path=":category?/:id/:topic"
                    element={<BlogDetails />}
                  ></Route>
                </Route>

                {/* Auth Routes */}
                <Route path="/secure" element={<AuthLayout />}>
                  {/*Fallback for auth login */}
                  <Route index element={<Navigate to="/secure/login" />} />

                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Registeration />} />

                  {/* Redirect to login page from any of not available auth page */}
                  <Route path="*" element={<Navigate to="/secure/login" />} />
                </Route>

                {/* DashBoard Routes  - Protected*/}

                <Route path="/dashboard" element={<ProtectedRoute />}>
                  <Route element={<DashboardLayout />}>
                    <Route path="" element={<DashboardHome />} />
                    <Route path="my-profile" element={<MyProfile />} />
                    <Route path="college-admission" element={<Admissions />} />
                    <Route path="mentors" element={<Mentor />} />

                    {/* Add more dashboard routes here */}

                    {/* Fallback route for dashboard */}
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Route>

                <Route element={<Layout />}>
                  <Route path="/scholarships" element={<Scholarships />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path="/map-view" element={<MapView />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/study-abroad-landing"
                    element={<StudyAbroadLanding />}
                  ></Route>
                </Route>

                <Route element={<SignInOutlet />}>
                  {/* <Route path="/sign-in" element={<Login />} /> */}
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/forget-password" element={<ForgotPassword />} />
                  <Route
                    path="/otp-verification"
                    element={<OTPVerification />}
                  />
                  <Route path="/forgot-email" element={<ForgotEmail />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/personal-info" element={<PersonalInfo />} />
                  <Route path="/standardInfo" element={<StandardInfo />} />
                  <Route
                    path="/dreamCareerInfo"
                    element={<DreamCareerInfo />}
                  />
                  <Route path="/stateInfo" element={<StateInfo />} />
                  <Route path="/fundInfo" element={<PlansToFund />} />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/college-predictor"
                    element={<CollegePredictorLanding />}
                  />
                  <Route
                    path="/college-predictor-intermediate"
                    element={<CollegePredictorIntermediate />}
                  />
                  <Route
                    path="/college-rankings"
                    element={<CollegeRankings />}
                  />

                  <Route
                    path="/scholarships/list-of-scholarships-in-india"
                    element={<ScholarshipIntermediate />}
                  />
                </Route>
              </Routes>
            </div>
          </div>
        </Router>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

const CourseDetailsWrapper: React.FC = () => {
  const { courseName } = useParams<{ courseName: string }>();

  return (
    <CourseDetailsProvider courseName={courseName!}>
      <CourseDetails />
    </CourseDetailsProvider>
  );
};

export default App;
