// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dreamCareerSec {
  display: inline-block;
  vertical-align: top;
  transition: height 500ms ease;
}
.dreamCareerSec .dreamCareerIcon {
  position: relative;
  width: auto;
  height: 151px;
  cursor: pointer;
}
.dreamCareerSec .dreamCareerIcon:after {
  display: block;
  position: absolute;
  bottom: -21px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  content: "";
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  transition: border-bottom-width 500ms ease;
  border-bottom-color: #cacaca;
}
.dreamCareerSec .dreamCareerSecChild {
  margin-top: 20px;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 500ms ease;
  border-radius: 8px;
}
.dreamCareerSec .dreamCareerSecChild .careerExpandHeader {
  margin: 0 auto;
  width: 100%;
}
.dreamCareerSec.expanded .dreamCareerIcon:after {
  border-bottom-width: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/careers-comp/dreamCareers/DreamCareersStyles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;EAGA,6BAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;AAEJ;AADI;EACE,cAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,mCAAA;EACA,oCAAA;EACA,0BAAA;EACA,wBAAA;EAGA,0CAAA;EACA,4BAAA;AAGN;AAAE;EACE,gBAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,gBAAA;EACA,aAAA;EAGA,iCAAA;EACA,kBAAA;AAEJ;AADI;EACE,cAAA;EACA,WAAA;AAGN;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".dreamCareerSec {\n  display: inline-block;\n  vertical-align: top;\n  -webkit-transition: height 500ms ease;\n  -o-transition: height 500ms ease;\n  transition: height 500ms ease;\n  .dreamCareerIcon {\n    position: relative;\n    width: auto;\n    height: 151px;\n    cursor: pointer;\n    &:after {\n      display: block;\n      position: absolute;\n      bottom: -21px;\n      left: 50%;\n      margin-left: -10px;\n      width: 0;\n      height: 0;\n      content: \"\";\n      border-left: 15px solid transparent;\n      border-right: 15px solid transparent;\n      border-bottom-style: solid;\n      border-bottom-width: 0px;\n      -webkit-transition: border-bottom-width 500ms ease;\n      -o-transition: border-bottom-width 500ms ease;\n      transition: border-bottom-width 500ms ease;\n      border-bottom-color: #cacaca;\n    }\n  }\n  .dreamCareerSecChild {\n    margin-top: 20px;\n    position: absolute;\n    left: 0;\n    right: 0;\n    overflow: hidden;\n    max-height: 0;\n    -webkit-transition: max-height 500ms ease;\n    -o-transition: max-height 500ms ease;\n    transition: max-height 500ms ease;\n    border-radius: 8px;\n    .careerExpandHeader {\n      margin: 0 auto;\n      width: 100%;\n    }\n  }\n  &.expanded .dreamCareerIcon:after {\n    border-bottom-width: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
