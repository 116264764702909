// YearTabs.js
import React, { useState } from "react";
import LocationIcon from "../../../../images/college/details/location.svg";
import MailIcon from "../../../../images/college/details/mail.svg";
import PhoneIcon from "../../../../images/college/details/phone.svg";

const CollegeContactUS = () => {
  return (
    <div className=" relative py-16" id="Contact-us">
      <div className="container mx-auto flex flex-col gap-10">
        <div className="flex justify-center items-center">
          <h3 className="text-[#231f40] text-3xl font-extrabold text-center">
            Contact SRM
          </h3>
        </div>
        <div className="flex flex-col justify-between items-center gap-6 bg-[#05b38b] rounded-2xl p-8 w-full">
          <div className="flex flex-col lg:flex-row justify-between items-center w-full">
            <div className="flex flex-col w-full lg:w-[40%] gap-4 justify-start items-start">
              <div className="flex flex-row items-start justify-center gap-4">
                <div className="flex gap-0.5">
                  <img src={LocationIcon} alt="location" />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#fff] text-base font-extrabold tracking-[1.60px]">
                    Address
                  </h3>
                  <h4 className="font-semibold text-[#fff] text-lg tracking-[0] leading-[32px]">
                    SRM Nagar, Kattankulathur, Kanchipuram District, Tamil Nadu
                    603203
                  </h4>
                </div>
              </div>
              <div className="flex flex-row items-start justify-center gap-4">
                <div className="flex gap-0.5">
                  <img src={PhoneIcon} alt="location" />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#fff] text-base font-extrabold tracking-[1.60px]">
                    Call Us At{" "}
                  </h3>
                  <h4 className="font-semibold text-[#fff] text-lg tracking-[0] leading-[32px]">
                    +91 44 27417000 <br />
                    +91 44 27417777
                  </h4>
                </div>
              </div>
              <div className="flex flex-row items-start justify-center gap-4">
                <div className="flex gap-0.5">
                  <img src={MailIcon} alt="location" />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h3 className="text-[#fff] text-base font-extrabold tracking-[1.60px]">
                    Information Center For Admissions{" "}
                  </h3>
                  <h4 className="font-semibold text-[#fff] text-lg tracking-[0] leading-[32px]">
                    Mobile No: 080 6908 7000
                    <br />
                    Email ID:{" "}
                    <a href="mailto:infodesk@srmist.edu.in">
                      infodesk@srmist.edu.in
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 w-full lg:w-[40%] rounded-2xl overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.799987272101!2d78.41578022582861!3d17.51705874905527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91d800543355%3A0x521b7f8d6bf19243!2sKailash%20Hills%2C%20Mahadevpur%20Colony%2C%20Hyderabad%2C%20Telangana%20500055!5e0!3m2!1sen!2sin!4v1714675498930!5m2!1sen!2sin"
                width="100%"
                height="450"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeContactUS;
