import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface DataItem {
  position: string;
  ThisYear: number;
  LastYear: number;
}

const data: DataItem[] = [
  { position: "May", ThisYear: 250000, LastYear: 200000 },
  { position: "June", ThisYear: 390000, LastYear: 310000 },
  { position: "July", ThisYear: 280000, LastYear: 220000 },
  { position: "August", ThisYear: 420000, LastYear: 300000 },
];

const SalaryChart = () => {
  const formatYAxis = (tickItem: any) => {
    return `$${(tickItem / 1000).toFixed(0)}K`;
  };
  return (
    <BarChart
      width={765}
      height={455}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="position" />
      <YAxis tickFormatter={formatYAxis} />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="LastYear"
        fill="#BBE7FF"
        activeBar={<Rectangle fill="#D1F2FB" stroke="#D1F2FB" />}
      />
      <Bar
        dataKey="ThisYear"
        fill="#1D3B89"
        activeBar={<Rectangle fill="#3366CC" stroke="#3366CC" />}
      />
    </BarChart>
  );
};

export default SalaryChart;
