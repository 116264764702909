import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
} from "../customHooks/useSelectedFilter";
import useDebouncedSearch from "../customHooks/useDebouncedSearch";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faSearch,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFilterDataItem } from "./DesktopFilterMenu";
import { FilterActions, FilterCategory } from "../FilterEnum";
import "./DesktopFilterItem.scss";

interface IProps {
  data: IFilterDataItem;
  selectedFilter: Set<string> | string;
  updateSelectedFilter: IUpdateSelectedFilter;
}

const DesktopFilterItem: React.FC<IProps> = ({
  data,
  selectedFilter,
  updateSelectedFilter,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const [optionsHeight, setOptionsHeight] = useState(0);
  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptionsHeight(
      showOptions ? optionsContainerRef.current?.scrollHeight || 0 : 0
    );
  }, [
    showOptions,
    isExpanded,
    deboundedSearchKeyword,
    optionsContainerRef,
    data,
  ]);

  const options = data.options.map((option) => {
    let checked = null;
    if (
      data.type == FilterCategory.MultiSelect &&
      selectedFilter instanceof Set
    ) {
      checked = selectedFilter?.has(option.key) || false;
    } else if (data.type == FilterCategory.SingleSelect)
      checked = selectedFilter == option.key;
    else checked = false;

    return { ...option, checked };
  });

  const sortedFilterOptions = options.sort(
    (a, b) => Number(b.checked) - Number(a.checked)
  );

  const searchFilteredOptions = sortedFilterOptions.filter((option) =>
    option.name.toLowerCase().includes(deboundedSearchKeyword.toLowerCase())
  );
  const filterOptionsToShow = isExpanded
    ? searchFilteredOptions
    : searchFilteredOptions.slice(0, 6);

  const handleCheckboxChange = (
    checked: boolean,
    category: string,
    option: string
  ) => {
    if (data.type == FilterCategory.MultiSelect) {
      if (checked)
        updateSelectedFilter(category, FilterActions.RemoveMultiSelect, option);
      else updateSelectedFilter(category, FilterActions.AddMultiSelect, option);
    } else if (data.type == FilterCategory.SingleSelect) {
      updateSelectedFilter(category, FilterActions.ChangeSingleSelect, option);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="border-t-2 border-t-white"></div>
      <button
        className="mt-2 flex items-center justify-between"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        <span className="text-base font-medium">{data.categoryName}</span>
        <FontAwesomeIcon
          icon={showOptions ? faChevronUp : faChevronDown}
          size="lg"
        />
      </button>
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden`}
        style={{
          height: `${optionsHeight}px`,
        }}
      >
        <div ref={optionsContainerRef} className="flex flex-col">
          {data?.options.length > 6 && (
            <div className="flex items-center bg-white pr-2 border border-black rounded-md overflow-hidden">
              <input
                className="p-2 text-sm w-full text-black"
                value={searchKeyword}
                onChange={(e) => handleKeywordChange(e.target.value)}
                placeholder={`Search ${data.categoryName}`}
              />
              {searchKeyword && searchKeyword.length > 0 ? (
                <button onClick={() => handleKeywordChange("")}>
                  <FontAwesomeIcon icon={faX} size="sm" className="text-grey" />
                </button>
              ) : (
                <FontAwesomeIcon
                  icon={faSearch}
                  size="sm"
                  className="text-grey"
                />
              )}
            </div>
          )}
          <ul
            className={`filter-scroll flex flex-col gap-4 mt-4 ${
              isExpanded ? "max-h-[13.75rem] overflow-y-auto" : ""
            }`}
          >
            {filterOptionsToShow.map((option) => (
              <li className="flex gap-4 items-center">
                <div
                  className={`h-5 w-5 shrink-0 flex items-center justify-center border border-white bg-transparent rounded-sm cursor-pointer ${
                    option.checked ? "bg-white" : ""
                  }`}
                  onClick={() =>
                    handleCheckboxChange(
                      option.checked,
                      data.categoryKey,
                      option.key
                    )
                  }
                >
                  {option.checked && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="sm"
                      className="text-green-2"
                    />
                  )}
                </div>
                <span className="text-sm">
                  {option.name + (option.count ? ` (${option.count})` : "")}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DesktopFilterItem;
