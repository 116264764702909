import React from 'react';
interface MentorCardProps {
  image: string;
  title: string;
  createdBy: string;
  lessons: string;
  hours: string;
  rating: string;
  people: string;
}

const MentorCard: React.FC<MentorCardProps> = ({ image, title, createdBy, lessons, hours, rating, people }) => {
 

  return (
    <div className="border border-gray-300 rounded-lg w-full md:w-[300px] overflow-hidden shadow-lg max-w-sm p-2">
      <img src={image} alt={""} className="w-full h-[160px] mx-auto rounded-lg" />
      <div className="py-2 px-2">
        <p className='text-[14px] text-green-800 py-2  '>
          <span className='bg-green-200 px-2 py-1 rounded-2xl font-bold'> Development </span>
        </p>        <h3 className="text-[16px] font-bold mb-2 whitespace-nowrap pt-2">{title}</h3>
        <div className='flex flex-row pt-2 border-b border-gray-300'><img src="" className='w-[20px] h-[20px] rounded-full' />
          <p className=" text-sm mb-4 pl-1"> Created by <span className='font-bold'>{createdBy}</span></p></div>
        <div className="mb-4 flex flex-row pt-2">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9513 4.61421L11.7868 5.77174C11.9711 6.31333 12.0711 6.89474 12.0711 7.50005C12.0711 10.4416 9.70658 12.827 6.79079 12.827C3.875 12.827 1.51053 10.443 1.51053 7.50005C1.51053 4.55846 3.875 2.17306 6.79079 2.17306C8.24079 2.17306 9.55395 2.76244 10.5079 3.71687L11.7789 2.85138C10.5382 1.4974 8.76316 0.64917 6.79079 0.64917C3.04079 0.64917 0 3.71687 0 7.50005C0 11.2832 3.04079 14.3509 6.79079 14.3509C10.5408 14.3509 13.5816 11.2832 13.5816 7.50005C13.5816 6.46864 13.3553 5.49032 12.9513 4.61421Z" fill="#00CC99" />
            <path d="M6.79063 8.27261C8.73274 5.55004 11.2064 3.72084 14.0104 2.44385C14.1512 2.3496 14.2591 2.54473 14.1604 2.62703C11.6327 4.79739 9.31037 7.38721 7.16958 10.35C6.94853 10.5943 6.563 10.577 6.36432 10.3142L5.04458 8.56332C4.21169 7.51199 3.41432 7.09385 2.65642 7.34075C2.59458 7.36332 2.55116 7.29694 2.60642 7.24119L3.18142 6.55624C4.56037 5.09208 6.12748 7.62217 6.79063 8.27261Z" fill="#00CC99" />
          </svg>
          <span className="block text-[14px] pr-4">{lessons} lessons</span>

          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9513 4.61421L11.7868 5.77174C11.9711 6.31333 12.0711 6.89474 12.0711 7.50005C12.0711 10.4416 9.70658 12.827 6.79079 12.827C3.875 12.827 1.51053 10.443 1.51053 7.50005C1.51053 4.55846 3.875 2.17306 6.79079 2.17306C8.24079 2.17306 9.55395 2.76244 10.5079 3.71687L11.7789 2.85138C10.5382 1.4974 8.76316 0.64917 6.79079 0.64917C3.04079 0.64917 0 3.71687 0 7.50005C0 11.2832 3.04079 14.3509 6.79079 14.3509C10.5408 14.3509 13.5816 11.2832 13.5816 7.50005C13.5816 6.46864 13.3553 5.49032 12.9513 4.61421Z" fill="#00CC99" />
            <path d="M6.79063 8.27261C8.73274 5.55004 11.2064 3.72084 14.0104 2.44385C14.1512 2.3496 14.2591 2.54473 14.1604 2.62703C11.6327 4.79739 9.31037 7.38721 7.16958 10.35C6.94853 10.5943 6.563 10.577 6.36432 10.3142L5.04458 8.56332C4.21169 7.51199 3.41432 7.09385 2.65642 7.34075C2.59458 7.36332 2.55116 7.29694 2.60642 7.24119L3.18142 6.55624C4.56037 5.09208 6.12748 7.62217 6.79063 8.27261Z" fill="#00CC99" />
          </svg>

          <span className=" block text-[14px]">
            {hours} hours</span>
        </div>
        <div className="flex items-center ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9513 4.61421L11.7868 5.77174C11.9711 6.31333 12.0711 6.89474 12.0711 7.50005C12.0711 10.4416 9.70658 12.827 6.79079 12.827C3.875 12.827 1.51053 10.443 1.51053 7.50005C1.51053 4.55846 3.875 2.17306 6.79079 2.17306C8.24079 2.17306 9.55395 2.76244 10.5079 3.71687L11.7789 2.85138C10.5382 1.4974 8.76316 0.64917 6.79079 0.64917C3.04079 0.64917 0 3.71687 0 7.50005C0 11.2832 3.04079 14.3509 6.79079 14.3509C10.5408 14.3509 13.5816 11.2832 13.5816 7.50005C13.5816 6.46864 13.3553 5.49032 12.9513 4.61421Z" fill="#00CC99" />
            <path d="M6.79063 8.27261C8.73274 5.55004 11.2064 3.72084 14.0104 2.44385C14.1512 2.3496 14.2591 2.54473 14.1604 2.62703C11.6327 4.79739 9.31037 7.38721 7.16958 10.35C6.94853 10.5943 6.563 10.577 6.36432 10.3142L5.04458 8.56332C4.21169 7.51199 3.41432 7.09385 2.65642 7.34075C2.59458 7.36332 2.55116 7.29694 2.60642 7.24119L3.18142 6.55624C4.56037 5.09208 6.12748 7.62217 6.79063 8.27261Z" fill="#00CC99" />
          </svg>
          <span className="text-gray-800 text-sm text-[14px] pr-12">{rating} ({people})</span>
          <button className="bg-white text-blue py-1 px-4 border border-blue rounded-2xl">
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
