import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
  currentLevelOfStudy: string;
  degree: string;
  stream: string;
  course: string;
  preferredStates: [];
}

interface FormState {
  step: number;
  direction: number;
  data: FormData;
}

const initialState: FormState = {
  step: 1,
  direction: 0,
  data: {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    otp: "",
    currentLevelOfStudy: "",
    degree: "",
    stream: "",
    course: "",
    preferredStates: [],
  },
};

const registrationFormSlice = createSlice({
  name: "registrationForm",
  initialState,
  reducers: {
    nextStep(state) {
      if (state.step < 6) {
        state.step += 1;
        state.direction = 1;
      } else console.log("All steps Reached - show Success / Error Msg!");
    },
    prevStep(state) {
      if (state.step > 0) {
        state.step -= 1;
        state.direction = -1;
      }
    },
    updateForm(state, action: PayloadAction<Partial<FormData>>) {
      state.data = { ...state.data, ...action.payload };
    },
    resetForm(state) {
      state.step = 1;
      state.data = initialState.data;
    },
  },
});

export const { nextStep, prevStep, updateForm, resetForm } =
  registrationFormSlice.actions;
export default registrationFormSlice.reducer;
