import React, { FC, useEffect, useState } from "react";

import BlogsBanner from "../components/BlogsAdBanner";
import BlogInfoCard from "../components/BlogInfoCard";
import BlogHoverContainer from "../components/BlogHoverContainer";

import TitleCard from "../components/TitleCard";
import ExamsAndAdmissions from "./sections/ExamsAndAdmissions";
import StudyAbroad from "./sections/StudyAbroad";
import CollegeAndScholarship from "./sections/CollegeAndScholarship";
import PremiumBlogs from "./sections/PremiumBlogs";
import CareersAndCourses from "./sections/CareersAndCourses";
import WebAndVisualStories from "./sections/WebAndVisualStories";
import PopularMentors from "./sections/PopularMentors";
import BlogsHeader from "../components/BlogsHeader";
import { AllSectionDetails, HeaderAPIResponse } from "../data/UtilsData";
import ArticlesAndLatestNews from "./sections/ArticlesAndLatestNews";
import axios from "axios";
import BlogsSubscriptionFooter from "../components/BlogsSubscriptionFooter";
import BannerImage2 from "../../../images/blog-article/banner-image2.png";
import BannerImage1 from "../../../images/blog-article/blogsAdBanner.png";
import Loader from "../../../components/Loader/Loader";
import BlogsAdBanner from "../components/BlogsAdBanner";
import Banner from "./sections/Banner";

const BlogsAndArticlesMain = () => {
  const [allSectionData, setAllSectionData] = useState<any>({});
  const [sectionWiseData, setSectionWiseData] = useState<any>([]);
  const [headerResponse, setHeaderResponse] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const getHeaderData = async () => {
    await axios
      .get("https://mentormenteedev.com/api/blogs/allsections")
      .then((response) => {
        setHeaderResponse(response.data);
        setLoading(false);
        // setSectionWiseData(allSectionData?.sections[0]);
      })
      .catch((error) => console.log("error", error));
    setLoading(false);

    // setHeaderResponse(HeaderAPIResponse);
  };

  const fetchAllSectionsResponse = async () => {
    await axios
      .get("https://mentormenteedev.com/api/blogs/allsections/blogsandnews")
      .then((response) => {
        setAllSectionData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    // setAllSectionData(AllSectionDetails);
    // setLoading(false);
  };
  useEffect(() => {
    // getHeaderData();
    fetchAllSectionsResponse();
  }, []);
  // console.log("allSectionData", allSectionData);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* <BlogsHeader headerResponse={headerResponse?.categories} /> */}
      <Banner data={allSectionData?.sections} />

      <div className="justify-center hidden w-full py-6 bg-gray-300 md:flex lg:flex">
        <BlogsAdBanner width="" height="" image={BannerImage1} />
      </div>
      <div className="py-4">
        <div className="grid grid-flow-row gap-0 grid-rows">
          <ArticlesAndLatestNews
            data={allSectionData?.sections}
            title="Articles & Blogs"
            secondaryTitle="Latest News"
          />

          <ExamsAndAdmissions
            examsAndAdmissionsResponse={allSectionData?.sections}
          />
          <div className="hidden py-4 md:flex lg:flex ">
            <div className="flex justify-center w-full py-4 bg-gray-300">
              <BlogsAdBanner width="" height="" image={BannerImage2} />
            </div>
          </div>

          <CollegeAndScholarship
            collegeAndScholarshipResponse={allSectionData?.sections}
          />
          <PremiumBlogs premiumBlogs={allSectionData?.sections} />
          <CareersAndCourses
            careersAndCoursesResponse={allSectionData?.sections}
          />
          <StudyAbroad studyAbroadResponse={allSectionData?.sections} />
          <WebAndVisualStories
            webAndVisualStoriesResponse={allSectionData?.sections}
          />
          {/* <PopularMentors popularMentorResponse={allSectionData?.sections} /> */}
          <BlogsSubscriptionFooter />
        </div>
      </div>
    </>
  );
};

export default BlogsAndArticlesMain;
