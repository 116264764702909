import { useState } from "react";
import Banner from "./components/Banner";
import TabContent from "./components/TabContent";
import TabNavigations from "./components/TabNavigation";
import "./ExamDetails.scss";

const apis = [
  {
    api: "https://my.api.mockaroo.com/exam-details/overview?key=cd140de0",
  },
  {
    api: "https://my.api.mockaroo.com/exam-details/applicationform?key=cd140de0",
  },
  {
    api: "https://my.api.mockaroo.com/exam-details/eligibility?key=c5cd0010",
  },
  {
    api: "https://my.api.mockaroo.com/exam-details/pattern?key=c5cd0010",
  },
  {
    api: "https://my.api.mockaroo.com/exam-details/admitcard?key=c5cd0010",
  },
  {
    api: "https://my.api.mockaroo.com/exam-details/answer-key?key=c5cd0010",
  },
];

const tabs = [
  "Overview",
  "Application Form",
  "Eligibility",
  "Pattern",
  "Admit Card",
  "Answer Key",
];

const ExamDetails = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="exam-details">
      <Banner />
      <TabNavigations
        tabs={tabs}
        activeTabIndex={activeTab}
        changeActiveTabIndex={(index: number) => setActiveTab(index)}
      />
      <TabContent apiURL={apis[activeTab].api} />
    </div>
  );
};

export default ExamDetails;
