import { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import Modal from "../../../../components/Modal";
import ApplyNowForm from "../../../../components/applynow/ApplyNowForm";
import { useNavigate } from "react-router-dom";
import QuizWidget from "../../../../components/QuizWidget";

const BuildingBridges = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [mapMyCollegeModalIsOpen, setMapMyCollegeModalIsOpen] = useState(false);
  const [
    contactAdmissionMentorModalIsOpen,
    setContactAdmissionMentorModalIsOpen,
  ] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  return (
    <>
      <div className="bg-[#FAF8F0] text-black">
        <div className="container py-12 flex flex-col lg:flex-row-reverse gap-12 items-center">
          <div className="w-full lg:w-1/2">
            <h2 className="text-4xl font-bold">
              Building Bridges to Bright Futures
            </h2>
            <h3 className="text-2xl lg:text-xl">
              Guiding Your Child Towards Their Dream College Journey
            </h3>
            <ul className="mt-6 text-grey text-base flex flex-col gap-4">
              {[
                "Meet with a College mentor advisor to discuss your student's needs and goals.",
                "Whether your teen is aiming for the Ivy League, other Private Universities, or small liberal arts colleges",
                "Our mentors are here to guide your family to a dream college and help you navigate scholarship opportunities.",
              ].map((point) => (
                <li>
                  <CircledTickIcon /> {point}
                </li>
              ))}
            </ul>
            <div className="flex flex-wrap gap-4 mt-6 text-base font-bold">
              <button
                className="arrow-button button-styles blue-color items-center"
                onClick={() => {
                  setMapMyCollegeModalIsOpen(true);
                }}
              >
                Map My College in 2 mins
                <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
              </button>
              <button
                className="arrow-button button-styles green-color flex items-center px-3 py-2 "
                onClick={() => {
                  setContactAdmissionMentorModalIsOpen(true);
                }}
              >
                Contact admission mentors
                <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
              </button>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            {/* height: 0, padding bottom: 56.25% set to maintain specific aspect ratio of height and width */}
            <div className="relative w-full h-0 pb-[56.25%]">
              {isPlaying ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={"https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1"}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                ></iframe>
              ) : (
                <button
                  onClick={handlePlayVideo}
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                >
                  <img
                    src={
                      "/images/college/landing/building-bridges-video-thumbnail.png"
                    }
                    alt="video thumbnail"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <MapMyCollegePopup
        isOpen={mapMyCollegeModalIsOpen}
        onClose={() => {
          setMapMyCollegeModalIsOpen(false);
        }}
      />
      <ContactAdmissionMentorPopup
        isOpen={contactAdmissionMentorModalIsOpen}
        onClose={() => {
          setContactAdmissionMentorModalIsOpen(false);
        }}
      />
    </>
  );
};

export default BuildingBridges;

const CircledTickIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block mr-2"
  >
    <path
      d="M13.6822 4.18881L12.452 5.41166C12.6466 5.98382 12.7523 6.59804 12.7523 7.23751C12.7523 10.3451 10.2544 12.8651 7.17403 12.8651C4.09368 12.8651 1.59577 10.3465 1.59577 7.23751C1.59577 4.12991 4.09368 1.60989 7.17403 1.60989C8.70586 1.60989 10.0931 2.23254 11.1009 3.24082L12.4437 2.32649C11.1329 0.8961 9.2577 0 7.17403 0C3.2124 0 0 3.24082 0 7.23751C0 11.2342 3.2124 14.475 7.17403 14.475C11.1357 14.475 14.3481 11.2342 14.3481 7.23751C14.3481 6.14789 14.109 5.11436 13.6822 4.18881Z"
      fill="#00CC99"
    />
    <path
      d="M7.17371 8.0539C9.22541 5.17769 11.8387 3.24525 14.8009 1.89619C14.9496 1.79663 15.0636 2.00277 14.9594 2.08972C12.2891 4.38255 9.83564 7.11853 7.57404 10.2486C7.34051 10.5066 6.93323 10.4884 6.72333 10.2107L5.32912 8.36101C4.44922 7.25035 3.60685 6.80861 2.80618 7.06945C2.74085 7.09329 2.69498 7.02317 2.75336 6.96428L3.36081 6.24066C4.81758 4.69388 6.47312 7.36675 7.17371 8.0539Z"
      fill="#00CC99"
    />
  </svg>
);

// TODO update modal content
const MapMyCollegePopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget destinationLink="/college-details" />
    </Modal>
  );
};

const ContactAdmissionMentorPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const imagesAndNames = [
    { image: '/images/College-landing/mentor1.png', name: 'Trish Cathy' },
    { image: '/images/College-landing/mentor2.png', name: 'Jess Tina' },
    { image: '/images/College-landing/mentor3.png', name: 'mandelina' },

  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ApplyNowForm
              imagesAndNames={imagesAndNames}
              />
    </Modal>
  );
};
