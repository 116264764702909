import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHeart, faComment, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import "./Posts.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const PostsData = [
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/why-clg-mentor/gallery02.jpeg",
        likes: 0,
        comments: 0
    },
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/center-image.png",
        likes: 0,
        comments: 0
    },
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/careers/cvs01.jpg",
        likes: 0,
        comments: 0
    },
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/careers/cvs02.jpg",
        likes: 0,
        comments: 0
    }
    ,
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/careers/cvs03.jpg",
        likes: 0,
        comments: 0
    }
    ,
    {
        hashtag: '@collegementor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.',
        image: "/images/careers/cvs04.jpg",
        likes: 0,
        comments: 0
    }
]

const Posts = () => {
    return <section className="posts-section container max-w-screen-2xl py-12">
        <h3 className="text-3xl font-extrabold text-center text-[#231F40]">
          Posts @CollegeMentor
        </h3>
        <Swiper
            slidesPerView={4}
            spaceBetween={0}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="h-full w-full flex flex-row justify-center items-center mt-6"
        >
            {PostsData.map((postData, index) =>
                <SwiperSlide key={index}>
                    <PostItem
                        hashtag={postData.hashtag}
                        description={postData.description}
                        image={postData.image}
                        likes={postData.likes}
                        comments={postData.comments}
                    />
                </SwiperSlide>)}
        </Swiper>
    </section>
}

const PostItem = ({ hashtag, description, image, likes, comments }: { hashtag: string, description: string, image: string, likes: number, comments: number }) => {
    return <div className="relative aspect-square group">
        <img
            loading="lazy"
            src={image}
            alt=""
            className="h-full w-full object-cover"
        />
        <div className="absolute top-0 left-0 h-full w-full flex flex-col gap-4 p-6 bg-[#000000BB] opacity-0 group-hover:opacity-100 transition duration-300">
            <p className='text-white'><FontAwesomeIcon icon={faInstagram} size="2x" className='mr-2' />{hashtag}</p>
            <div className='flex-1 overflow-hidden'>
                <p className='text-white'>{description}</p>
            </div>
            <div className='text-white flex'>
                <div>
                    <FontAwesomeIcon icon={faHeart} size="1x" className='mr-2' />
                    <span className='mr-4'>{likes}</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faComment} size="1x" className='mr-2' />
                    {comments}
                </div>
                <a href='#' className='block ml-auto'><FontAwesomeIcon icon={faExternalLink} size="1x" /></a>
            </div>
        </div>
    </div>
}

export default Posts