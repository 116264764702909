import { PhoneNumberUtil } from "google-libphonenumber";

export const requiredFieldValidator = (value: string) => {
  if (!value) {
    return "this field is required";
  }
  return "";
};
export const emailValidator = (email: string) => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Incorrect email format";
  }
  return "";
};

export const passwordValidator = (password: string) => {
  if (!password) {
    return "Password is required";
  } else if (
    !new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W])(?!.*\s).{8,}$/).test(
      password
    )
  ) {
    return "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.";
  }
  return "";
};

export const confirmPasswordValidator = (
  confirmPassword: string,
  password: string
) => {
  if (!confirmPassword) {
    return "Confirm password is required";
  } else if (confirmPassword !== password) {
    return "Passwords do not match";
  }
  return "";
};

export const matchIsNumeric = (text: string) => {
  const isNumber = typeof text === "number";
  const isString = matchIsString(text);
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
};

export const matchIsString = (text: string) => {
  const isString = typeof text === "string";
  return isString;
};

export const isPhoneValid = (phone: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
