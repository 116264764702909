import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type HeaderCategoryProps = {
  category: string;
  subCategory: string;
};

const initialState: HeaderCategoryProps = {
  category: "",
  subCategory: "",
};

// Create the slice
const blogSlice = createSlice({
  name: "blogSlice",
  initialState,
  reducers: {
    setCurrentCategoryPageValue: (
      state,
      action: PayloadAction<HeaderCategoryProps>
    ) => {
      console.log("setHoveredValue", action.payload);

      state.category = action.payload.category;
      state.subCategory = action.payload.subCategory;
    },
  },
});

export const { setCurrentCategoryPageValue } = blogSlice.actions;
export default blogSlice.reducer;
