// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exam-details .no-scrollbar::-webkit-scrollbar {
  display: none;
}
.exam-details .no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.exam-details .html-content th,
.exam-details .html-content td {
  border: 1px solid black;
  padding: 1rem;
}
.exam-details .html-content p,
.exam-details .html-content h2,
.exam-details .html-content h3,
.exam-details .html-content ul,
.exam-details .html-content ol,
.exam-details .html-content div,
.exam-details .html-content table {
  margin-top: 1rem;
}
.exam-details .html-content h2 {
  font-size: 24px;
  font-weight: bold;
}
.exam-details .html-content h3 {
  font-size: 20px;
  font-weight: bold;
}
.exam-details .html-content ul {
  list-style-type: disc;
}
.exam-details .html-content ol {
  list-style-type: decimal;
}`, "",{"version":3,"sources":["webpack://./src/screens/exam-details-new/ExamDetails.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAAJ;AAII;;EAEE,uBAAA;EACA,aAAA;AAFN;AAKI;;;;;;;EAOE,gBAAA;AAHN;AAMI;EACE,eAAA;EACA,iBAAA;AAJN;AAOI;EACE,eAAA;EACA,iBAAA;AALN;AAQI;EACE,qBAAA;AANN;AASI;EACE,wBAAA;AAPN","sourcesContent":[".exam-details {\n  .no-scrollbar::-webkit-scrollbar {\n    display: none;\n  }\n  .no-scrollbar {\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n  }\n\n  .html-content {\n    th,\n    td {\n      border: 1px solid black;\n      padding: 1rem;\n    }\n\n    p,\n    h2,\n    h3,\n    ul,\n    ol,\n    div,\n    table {\n      margin-top: 1rem;\n    }\n\n    h2 {\n      font-size: 24px;\n      font-weight: bold;\n    }\n\n    h3 {\n      font-size: 20px;\n      font-weight: bold;\n    }\n\n    ul {\n      list-style-type: disc;\n    }\n\n    ol {\n      list-style-type: decimal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
