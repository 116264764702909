import React, { useEffect, useState } from "react";
import BlogsImage from "../../../images/blog-article/blogsandarticles.jpeg";
import BlogsImage1 from "../../../images/blog-article/exam-section-image2.jpeg";
import { useNavigate, Link } from "react-router-dom";

interface BlogHoverContainerProps {
  blogInfo: any;
  hoveredContainerItem: any;
}
const BlogHoverContainer: React.FC<BlogHoverContainerProps> = ({
  blogInfo,
  hoveredContainerItem,
}) => {
  // console.log("BlogHoverContainer - blogInfo", hoveredContainerItem);
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState<any>({});

  // useEffect(() => {
  //   setHoveredItem(blogInfo);
  // }, []);

  useEffect(() => {
    setHoveredItem(hoveredContainerItem);
  }, [hoveredContainerItem]);

  return (
    <Link
      // to={`/blogs/${hoveredItem.category?.toLocaleLowerCase()}/${hoveredItem?.title?.toLocaleLowerCase()}`}
      to={`/blogs/${hoveredItem?.category?.toLocaleLowerCase()}/${hoveredItem?.title?.toLocaleLowerCase()}`}
      state={{ from: "BlogHoverContainer", blogDetail: hoveredItem }}
    >
      <div className="h-full">
        {/* <div className="h-[50%]">
          <img
            className=""
            src={hoveredItem?.imageUrl}
            // src={BlogsImage1}
            alt=""
            width="100%"
          />
        </div> */}
        <div className="h-3/4 w-auto rounded-lg">
          <img
            className=""
            src={hoveredItem?.imageUrl}
            style={{ objectFit: "cover", height: "100%" }}
            // src={BlogsImage1}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div className="py-2">
          <a href="">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">
              {hoveredItem?.title}
            </h5>
          </a>
          <p className="">College | September 13, 2024</p>
          <p className="font-normal text-gray-700 dark:text-gray-400 line-clamp-2">
            {hoveredItem?.description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogHoverContainer;
