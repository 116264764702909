import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { TestimonialCard } from "./TestimonialCard";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";

interface TestimonialData {
  name: string;
  university: string;
  imageSrc1: string;
  imageSrc2: string;
}

const testimonials: TestimonialData[] = [
  {
    name: "Arjun S.",
    university: "University of Edinburgh",
    imageSrc1: "/images/testimonials-student.png",
    imageSrc2: "/images/testimonials-clg.png",
  },
  {
    name: "Arjun S.",
    university: "University of Edinburgh",
    imageSrc1: "/images/testimonials-student.png",
    imageSrc2: "/images/testimonials-clg.png",
  },
  {
    name: "Arjun S.",
    university: "University of Edinburgh",
    imageSrc1: "/images/testimonials-student.png",
    imageSrc2: "/images/testimonials-clg.png",
  },
  {
    name: "Arjun S.",
    university: "University of Edinburgh",
    imageSrc1: "/images/testimonials-student.png",
    imageSrc2: "/images/testimonials-clg.png",
  },
];

const Testimonials: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full bg-[#FFF9E5] max-md:px-5 max-md:max-w-full mb-7">
      <div className="flex flex-col items-center container md:px-[100px] py-20 max-md:max-w-full">
        <div className="md:w-[708px] max-md:w-[400px]">
          <h2 className="text-4xl font-bold text-center text-[#173CBA]  ">
            <span className="font-light">High Praise From our Lovely</span>
            <br />
            <span className="text-[#0C9]">Students & Parents</span>
          </h2>
        </div>
        <div className="md:w-[854px] mt-4">
          <p className=" text-base  text-center text-black ">
            College Mentor made my dream of studying abroad a reality. From the
            application process to receiving my acceptance letter, their
            guidance was invaluable. The personalized support helped me navigate
            the complexities of international admissions with confidence.
          </p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{ clickable: true }} // Enable pagination
          loop={true}
          modules={[Autoplay, Pagination]} // Include Pagination module
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className="w-full mt-9"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
