// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education-top-colleges-section .swiper-button-prev::after,
.education-top-colleges-section .swiper-button-next::after {
  display: none;
}
.education-top-colleges-section .carosalButtons {
  min-width: 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #cdcdcd;
}
.education-top-colleges-section .carosalButtons svg {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/screens/career-details/components/TabsSlider.scss"],"names":[],"mappings":"AACE;;EAEE,aAAA;AAAJ;AAEE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,2CAAA;EACA,yBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;AACN","sourcesContent":[".education-top-colleges-section {\n  .swiper-button-prev::after,\n  .swiper-button-next::after {\n    display: none;\n  }\n  .carosalButtons {\n    min-width: 44px;\n    width: 44px;\n    height: 44px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #f5f5f5;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    border: 1px solid #cdcdcd;\n    svg {\n      width: 20px;\n      height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
