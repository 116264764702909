import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
  IClearFilter,
} from "../customHooks/useSelectedFilter";
import { FilterCategory } from "../FilterEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import MobileFilterItem from "./MobileFilterItem";

interface IFilterDataItem {
  categoryName: string;
  categoryKey: string;
  type:
    | FilterCategory.MultiSelect
    | FilterCategory.SingleSelect
    | FilterCategory.Radio;
  options: { count?: number; name: string; key: string }[];
}

interface IProps {
  filterData: IFilterDataItem[];
  selectedFilter: ISelectedFilter;
  updateSelectedFilter: IUpdateSelectedFilter;
  clearFilter: IClearFilter;
  closeFilter: () => void;
}

const MobileFilterMenu: React.FC<IProps> = ({
  filterData,
  selectedFilter,
  updateSelectedFilter,
  clearFilter,
  closeFilter,
}) => {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  const isClearBtnDisabled = !(
    selectedFilter &&
    (Object.keys(selectedFilter.multiSelectFilters).length > 0 ||
      Object.keys(selectedFilter.singleSelectFilters).length > 0)
  );

  const selectedCategoryFilterData = filterData[selectedCategoryIndex];
  let particularCategorySelectedFilter = null;
  if (selectedCategoryFilterData.type == FilterCategory.MultiSelect)
    particularCategorySelectedFilter =
      selectedFilter.multiSelectFilters[selectedCategoryFilterData.categoryKey];
  else if (selectedCategoryFilterData.type == FilterCategory.SingleSelect)
    particularCategorySelectedFilter =
      selectedFilter.singleSelectFilters[
        selectedCategoryFilterData.categoryKey
      ];
  else
    particularCategorySelectedFilter =
      selectedFilter.radioFilters[selectedCategoryFilterData.categoryKey];

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-white z-[100] flex flex-col">
      <div className="h-[10%] flex items-center justify-between bg-blue p-4 text-white">
        <button className="flex items-center gap-4" onClick={closeFilter}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            className="text-white"
          />
          <span className="text-lg">Filter By</span>
        </button>
        <button
          className={`text-base ${
            isClearBtnDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isClearBtnDisabled}
          onClick={clearFilter}
        >
          Clear All
        </button>
      </div>
      <div className="flex h-[80%]">
        <div className="w-2/5 bg-grey-3 overflow-y-auto">
          {filterData.map((data, index) => (
            <div
              className={`mt-4 text-base font-medium text-black px-2 py-4 cursor-pointer border-l-4  ${
                selectedCategoryIndex == index
                  ? "bg-white text-blue border-green-2"
                  : "border-grey-3"
              }`}
              onClick={() => setSelectedCategoryIndex(index)}
            >
              {data.categoryName}
            </div>
          ))}
        </div>
        <div className="w-3/5 p-4 h-full">
          <MobileFilterItem
            data={selectedCategoryFilterData}
            selectedFilter={particularCategorySelectedFilter}
            updateSelectedFilter={updateSelectedFilter}
          />
        </div>
      </div>
      <div
        className="h-[10%] flex items-center justify-end p-4"
        style={{
          boxShadow:
            "0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -4px rgba(0, 0, 0, 0.05)",
        }}
      >
        <button
          className="px-8 py-2 bg-blue rounded-md flex items-center gap-4"
          onClick={closeFilter}
        >
          <FontAwesomeIcon icon={faCheck} size="lg" className="text-white" />
          <span className="text-base text-white">Apply</span>
        </button>
      </div>
    </div>
  );
};

export default MobileFilterMenu;
