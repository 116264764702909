// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.2);
}

.blog-exam-image {
  transition: transform 0.3s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.2);
}

.blog-image-title-container:hover .image-container img {
  transform: scale(1.2);
}

.premium-blog-link {
  opacity: 0;
  overflow: hidden;
  transition: all 0.2s ease-out;
  transform: translateY(10%);
}

.premium-image-container:hover .premium-blog-link {
  opacity: 1;
  transform: translateY(0%);
}`, "",{"version":3,"sources":["webpack://./src/screens/blogs-articles/blogs-articles-landing/BlogsAndArticlesMain.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,sCAAA;AACJ;;AACA;EACI,qBAAA;AAEJ;;AAAA;EACI,sCAAA;AAGJ;;AADA;EACI,qBAAA;AAIJ;;AAEI;EACI,qBAAA;AACR;;AAKA;EACI,UAAA;EACA,gBAAA;EACA,6BAAA;EACA,0BAAA;AAFJ;;AAMA;EAEI,UAAA;EACA,yBAAA;AAJJ","sourcesContent":[".image-container {\n    position: relative;\n    overflow: hidden;\n    transition: transform 0.3s ease-in-out;\n}\n.image-container:hover {\n    transform: scale(1.2); \n}\n.blog-exam-image{\n    transition: transform 0.3s ease-in-out;\n}\n.image-container:hover img  {\n    transform: scale(1.2); \n}\n\n\n.blog-image-title-container:hover {\n \n    .image-container img{\n        transform: scale(1.2);\n    }\n}\n\n\n\n.premium-blog-link{\n    opacity: 0;\n    overflow: hidden;\n    transition: all .2s ease-out;\n    transform: translateY(10%);\n    \n}\n\n.premium-image-container:hover .premium-blog-link {\n   \n    opacity: 1;\n    transform: translateY(-0%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
