import React from "react";
import InstagramIcon from "../../../images/blog-article/instagram.svg";
import FaceBookIcon from "../../../images/blog-article/facebook.svg";
import TwitterIcon from "../../../images/blog-article/twitter-icon.svg";
import YoutubeIcon from "../../../images/blog-article/yt.svg";
import FooterImg1 from "../../../images/blog-article/blog-footer-img1.png";
import FooterImg2 from "../../../images/blog-article/blog-footer-img2.png";
import FooterImg3 from "../../../images/blog-article/blog-footer-img3.png";
import FooterImg4 from "../../../images/blog-article/blog-footer-img4.png";

export const SocialMediaIconsList = [
  TwitterIcon,
  InstagramIcon,
  FaceBookIcon,
  YoutubeIcon,
];
export const FooterImagesList = [
  FooterImg1,
  FooterImg2,
  FooterImg3,
  FooterImg4,
];
const BlogsSubscriptionFooter = () => {
  return (
    <>
      <div className="flex flex-col-reverse sm:col-reverse md:flex-row">
        <div className="basis-2/4 bg-gray-100">
          <div className="flex flex-row">
            <div className="w-full flex">
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg1} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg2} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg3} alt="" className="w-full" />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-full flex">
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg2} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg1} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg3} alt="" className="w-full" />
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-full flex">
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg2} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg4} alt="" className="w-full" />
              </div>
              <div className="inline-flex basis-1/3 w-full">
                <img src={FooterImg1} alt="" className="w-full" />
              </div>
            </div>
          </div>
          {/* <div className="grid grid-rows-3 grid-flow-col">
            <div class="row-span-1 grid grid-flow-col">01</div>
            <div class="row-span-1 grid grid-flow-col">01</div>
          </div> */}
        </div>

        <div className="basis-2/4 bg-[#0EB78F] text-white">
          <div className="p-6 h-full flex flex-col  md:mx-20 justify-center items-center gap-6 ">
            <div className="text-center font-bold text-5xl text-pretty">
              Follow Us!
            </div>
            <h4 className="text-center text-base leading-8">
              Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </h4>
            <div className="flex flex-row gap-4 justify-center items-center md:w-[70%]">
              <input
                type="email"
                id="email"
                className="flex-col col-span-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 "
                placeholder="Email"
                required
              />
              <button class="bg-[#173CBA] h-full text-center items-center text-sm text-white py-2 px-4 rounded w-[137px] h-[38px] bg-[#173CBA]">
                <span>Subscribe</span>
              </button>
            </div>
            <div className="flex grow"></div>
            <div className="grow flex items-end">
              <div className="flex flex-row items-center justify-center gap-6 h-50">
                {SocialMediaIconsList?.map((icon) => (
                  <img
                    src={icon}
                    alt=""
                    width="80px"
                    // style={{ height: "100%" }}
                    // className="object-cover"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mt-2 md:flex-row flex sm:flex-col-reverse">
        <div className="bg-gray-100 p-4 basis-2/4">Image container</div>
        <div className="bg-[#0EB78F] p-4 text-white basis-2/4">
          <div className="p-4 h-full flex flex-col  md:mx-20 justify-center items-center gap-6 ">
            <div className="text-center font-bold text-3xl text-pretty">
              Follow Us!
            </div>
            <h4 className="text-center text-base">
              Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </h4>
            <div className="flex flex-row gap-4 justify-center items-center">
              <input
                type="email"
                id="email"
                className="flex-col col-span-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 "
                placeholder="Email"
                required
              />
              <button class="bg-[#173CBA] h-full text-center items-center text-sm text-white py-2 px-4 rounded w-[137px] h-[38px] bg-[#173CBA]">
                <span>Subscribe</span>
              </button>
            </div>
            <div className="flex grow"></div>
            <div className="grow flex items-end">
              <div>Social Media Icons</div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default BlogsSubscriptionFooter;
