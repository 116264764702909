import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import "swiper/swiper-bundle.css";
import "./Blogs.scss";
const blogsData= 
     [
        {
            "blogId": 1,
            "description": "The gap in North American corporate mentorship",
            "imageUrl": ""
        },
   ]
  
  
interface Cards {
  blogId: number;
  description: string;
  imageUrl: string;
}

const Card: React.FC<Cards> = ({
    blogId,
  description,
  imageUrl
}) => (
  <div className="w-full flex justify-center items-center">
  <a href="/blogdetails-page">
  <div className="flex  flex-col pt-4 w-[197px] h-[273px] pb-4  flex justify-center items-center">
  <div className="">
  <img className="top-0 w-[197px] h-[154px]" src={imageUrl} />
</div>
     <div className="flex bg-white flex-col relative pl-2 gap-2 py-2">
     <div className="blog-sample">sample text</div>
     <div className="blog-description">
        {description}
      </div>
      <div className="flex flex-row gap-4">
        <svg width="69" height="12" viewBox="0 0 69 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.0386 5.49792H36.93L38.2247 9.48314H38.2671L39.5618 5.49792H40.4532V9.96812H39.9438V5.9829H39.9014L38.6067 9.96812H37.8851L36.5904 5.9829H36.548V9.96812H36.0386V5.49792Z" fill="#00CC99"/>
<path d="M41.4087 8.30237C41.4087 7.24808 42.1303 6.55225 43.0217 6.55225C43.9131 6.55225 44.6135 7.24808 44.6135 8.23911V8.42888H41.9393C41.9393 9.16689 42.385 9.5886 43.0854 9.5886C43.7858 9.5886 43.9768 9.31449 44.0829 8.87169L44.5711 8.97711C44.3801 9.5886 43.8283 10.0314 43.0642 10.0314C42.0454 10.0314 41.4087 9.31449 41.4087 8.30237ZM44.0829 8.04934C44.0405 7.35351 43.5736 7.03722 43.0217 7.03722C42.4699 7.03722 41.9817 7.43785 41.9393 8.04934H44.0829Z" fill="#00CC99"/>
<path d="M45.5049 6.65746H46.0143V7.14244H46.0567C46.2053 6.84724 46.5661 6.57312 47.0755 6.57312C47.8608 6.57312 48.3277 7.14244 48.3277 7.88044V9.96794H47.8183V7.92261C47.8183 7.39547 47.4363 7.05809 46.9694 7.05809C46.5024 7.05809 46.0355 7.45872 46.0355 8.00695V9.96794H45.5049V6.65746Z" fill="#00CC99"/>
<path d="M49.707 9.01918V7.07929H49.0703V6.65757H49.7283V5.68762H50.1952V6.65757H51.214V7.07929H50.1952V9.01918C50.1952 9.37764 50.3438 9.52524 50.7046 9.52524H51.1927V9.96804H50.7258C50.1103 9.96804 49.707 9.69393 49.707 9.01918Z" fill="#00CC99"/>
<path d="M51.9146 8.28128C51.9146 7.29025 52.6574 6.55225 53.6549 6.55225C54.6525 6.55225 55.3953 7.29025 55.3953 8.28128C55.3953 9.27232 54.6525 10.0103 53.6549 10.0103C52.6362 10.0314 51.9146 9.2934 51.9146 8.28128ZM54.8647 8.28128C54.8647 7.54328 54.3553 6.99505 53.6337 6.99505C52.9121 6.99505 52.4239 7.54328 52.4239 8.28128C52.4239 9.01929 52.9333 9.56752 53.6337 9.56752C54.3341 9.56752 54.8647 9.01929 54.8647 8.28128Z" fill="#00CC99"/>
<path d="M56.2866 6.65746H56.796V7.20569H56.8385C56.9446 6.93158 57.2629 6.57312 57.7511 6.57312H57.9633V7.07918H57.6662C57.0507 7.07918 56.8172 7.56415 56.8172 8.19673V9.96794H56.3078V6.65746H56.2866Z" fill="#00CC99"/>
<path d="M56.032 10.4108C57.836 9.82041 60.8499 7.71183 61.9535 6.69971C60.8499 7.83834 57.7087 10.0734 55.7136 10.7271C53.2516 11.5284 51.0018 11.5916 49.3039 11.0223L48.8794 10.5584C50.5986 11.3597 53.2728 11.3175 56.032 10.4108Z" fill="#00CC99"/>
<path d="M8.78662 7.73301C8.78662 6.42569 9.76294 5.41357 11.0576 5.41357C12.3523 5.41357 12.8405 6.02506 13.1376 6.8685H12.5645C12.3311 6.29918 11.758 5.89855 11.0576 5.89855C10.0601 5.89855 9.31723 6.67872 9.31723 7.73301C9.31723 8.7873 10.0601 9.56748 11.0576 9.56748C12.0552 9.56748 12.3311 9.18793 12.5645 8.59753H13.1376C12.8617 9.46205 12.0552 10.0525 11.0576 10.0525C10.0601 10.0525 8.78662 9.06142 8.78662 7.73301Z" fill="#173CBA"/>
<path d="M13.7534 8.28128C13.7534 7.29025 14.4963 6.55225 15.4938 6.55225C16.4913 6.55225 17.2342 7.29025 17.2342 8.28128C17.2342 9.27232 16.4913 10.0103 15.4938 10.0103C14.475 10.0314 13.7534 9.2934 13.7534 8.28128ZM16.7036 8.28128C16.7036 7.54328 16.1942 6.99505 15.4726 6.99505C14.751 6.99505 14.2628 7.54328 14.2628 8.28128C14.2628 9.01929 14.7722 9.56752 15.4726 9.56752C16.173 9.56752 16.7036 9.01929 16.7036 8.28128Z" fill="#173CBA"/>
<path d="M19.6112 9.52501V9.96781H17.7646V9.52501H18.4438V5.98259L18.9532 5.85608V9.52501H19.6112Z" fill="#00CC99"/>
<path d="M22.1156 9.52513V9.96793H20.269V9.52513H20.9482V5.8562L21.4364 5.98272V9.52513H22.1156Z" fill="#00CC99"/>
<path d="M22.604 8.30237C22.604 7.24808 23.3256 6.55225 24.217 6.55225C25.1085 6.55225 25.8089 7.24808 25.8089 8.23911V8.42888H23.1346C23.1346 9.16689 23.5803 9.5886 24.2807 9.5886C24.9811 9.5886 25.1721 9.31449 25.2783 8.87169L25.7664 8.97711C25.5754 9.5886 25.0236 10.0314 24.2595 10.0314C23.2407 10.0314 22.604 9.31449 22.604 8.30237ZM25.2783 8.04934C25.2358 7.35351 24.7689 7.03722 24.217 7.03722C23.6652 7.03722 23.1771 7.43785 23.1346 8.04934H25.2783Z" fill="#173CBA"/>
<path d="M27.2521 10.9378H28.6953C29.0561 10.9378 29.2047 10.7691 29.2047 10.4107V9.39855H29.1622C29.0561 9.60941 28.7165 10.01 28.0161 10.01C27.3157 10.01 26.4243 9.29312 26.4243 8.25992C26.4243 7.22672 27.1035 6.53088 28.0586 6.53088C29.0137 6.53088 29.0986 7.01586 29.1835 7.14237H29.2259V6.61523H29.7141V10.4528C29.7141 11.1276 29.3108 11.4017 28.6953 11.4017H27.2521V10.9378ZM29.2259 8.28101C29.2259 7.58517 28.7802 7.01586 28.101 7.01586C27.4219 7.01586 26.9549 7.52192 26.9549 8.28101C26.9549 9.04009 27.4219 9.56724 28.0798 9.56724C28.7378 9.56724 29.2259 9.10335 29.2259 8.28101Z" fill="#173CBA"/>
<path d="M30.5415 8.30237C30.5415 7.24808 31.2631 6.55225 32.1545 6.55225C33.046 6.55225 33.7464 7.24808 33.7464 8.23911V8.42888H31.0721C31.0721 9.16689 31.5178 9.5886 32.2182 9.5886C32.9186 9.5886 33.1096 9.31449 33.2158 8.87169L33.7039 8.97711C33.5129 9.5886 32.9611 10.0314 32.197 10.0314C31.1782 10.0314 30.5415 9.31449 30.5415 8.30237ZM33.2158 8.04934C33.1733 7.35351 32.7064 7.03722 32.1545 7.03722C31.6027 7.03722 31.1146 7.43785 31.0721 8.04934H33.2158Z" fill="#173CBA"/>
<path d="M67.7901 0.479251C67.7901 0.479251 67.9174 0.542508 67.6203 0.415994C67.4081 0.33165 66.9411 0.247307 66.6015 0.500337C66.4954 0.58468 66.4105 0.711195 66.3469 0.858796C66.1771 1.3016 65.9648 1.38594 65.9648 1.38594C65.9648 1.38594 65.8587 1.34377 65.7314 1.3016C65.4767 1.21725 65.0946 1.09074 64.9461 1.06965C64.8612 1.06965 64.8187 1.06965 64.7763 1.02748C64.6701 1.02748 64.4791 0.943139 63.7999 0.669023C63.2269 0.437079 63.1844 0.521423 62.76 0.563594C61.2318 0.647937 60.6375 0.479251 61.5502 0.98531C61.7412 1.09074 62.0383 1.09074 62.3142 1.06965H62.3355L63.5028 0.879881L62.1657 1.28051C61.9393 1.33674 61.9464 1.43514 62.1869 1.57571C62.3355 1.66006 62.6326 1.57571 62.7175 1.57571C62.7812 1.57571 62.76 1.57571 62.8873 1.53354L64.2669 1.21725L62.8873 1.70223C62.5477 1.84983 62.5477 1.892 62.9085 1.99743C63.0571 2.0396 63.0995 1.99743 63.524 1.87091L64.7126 1.51246L63.5028 2.0396C63.2057 2.16612 63.2906 2.20829 63.5453 2.25046C63.7999 2.31372 63.715 2.31372 64.0122 2.1872L64.8824 1.892L64.9885 1.84983L64.8824 1.91309L64.0971 2.31372C63.7999 2.4824 63.7999 2.52457 64.1395 2.58783C64.2457 2.58783 64.3306 2.63 64.5003 2.54566C64.6489 2.4824 64.9461 2.37697 65.1158 2.29263C65.2856 2.20829 65.2432 2.22937 65.2432 2.22937L65.1371 2.29263L64.5852 2.60892C64.2881 2.81978 64.2457 2.88303 64.5003 2.98846H64.5216L64.564 3.17823C64.564 3.17823 64.6277 3.24149 64.564 3.34692C64.5003 3.45235 64.2669 3.76864 64.1608 3.91624C64.0546 4.06384 63.7363 4.63316 63.5453 4.8651C63.3542 5.09704 63.0146 5.51876 62.951 5.62419C62.8661 5.72962 62.8236 5.83505 63.0995 5.62419C63.3755 5.39224 62.9085 5.85613 63.0571 5.7507L63.8424 5.20247C63.8424 5.20247 64.0122 5.24464 64.2881 5.03379C64.564 4.82293 65.031 4.5699 65.031 4.5699C65.031 4.5699 65.0522 4.7175 65.2432 4.5699C65.4342 4.4223 65.6465 4.63315 65.7738 4.5699C65.9224 4.50664 66.7926 4.23252 67.4081 3.09389C68.0236 1.95526 67.9811 1.21725 68.4056 0.753366L68.9999 0.162964L67.7901 0.521423V0.479251Z" fill="#00CC99"/>
<path d="M60.6587 2.35577C60.6587 2.35577 60.7011 2.35577 60.595 2.35577C60.5101 2.3136 60.2979 2.27143 60.1493 2.39794C60.1069 2.44011 60.0644 2.48229 60.0432 2.56663C59.9795 2.7564 59.8734 2.77749 59.8734 2.77749C59.8734 2.77749 59.8309 2.77749 59.7673 2.73531C59.6611 2.69314 59.4914 2.65097 59.4277 2.62989C59.3994 2.62989 59.3711 2.62989 59.3428 2.62989C59.3003 2.62989 59.2154 2.58771 58.9183 2.48229C58.6636 2.37686 58.6636 2.41903 58.4726 2.44011C57.8146 2.48229 57.56 2.39794 57.942 2.6088C58.0269 2.65097 58.1542 2.65097 58.2816 2.65097L58.791 2.56663L58.2179 2.73531C58.1189 2.76343 58.1189 2.8056 58.2179 2.86183C58.2816 2.904 58.4089 2.86183 58.4514 2.86183C58.4938 2.86183 58.4726 2.86183 58.515 2.86183L59.1093 2.71423L58.515 2.92509C58.3665 2.98834 58.3665 3.00943 58.515 3.0516C58.5787 3.0516 58.5999 3.0516 58.7697 2.98834L59.2791 2.84074L58.7697 3.07269C58.6424 3.13595 58.6848 3.13595 58.791 3.15703C58.8971 3.15703 58.8546 3.15703 59.0032 3.15703L59.3852 3.03052L59.0456 3.1992C58.9183 3.26246 58.9183 3.28355 59.0669 3.32572C59.1093 3.32572 59.1518 3.32572 59.2154 3.32572C59.2791 3.32572 59.4065 3.24137 59.4914 3.22029C59.5338 3.22029 59.555 3.22029 59.555 3.22029H59.5126L59.2791 3.36789C59.1518 3.45223 59.1305 3.4944 59.2367 3.53658V3.62092C59.2579 3.62092 59.2791 3.62092 59.2579 3.68418C59.2367 3.74743 59.1305 3.85286 59.0881 3.93721C59.0456 4.00046 58.8971 4.25349 58.8334 4.33784C58.7485 4.44327 58.5999 4.61195 58.5787 4.67521C58.5363 4.71738 58.515 4.75955 58.6424 4.67521C58.7697 4.56978 58.5575 4.78064 58.6212 4.71738L58.9608 4.48544C58.9608 4.48544 59.0244 4.48544 59.1518 4.42218C59.2791 4.35892 59.4701 4.21132 59.4701 4.21132C59.4701 4.21132 59.4701 4.27458 59.555 4.21132C59.6399 4.14806 59.7248 4.21132 59.7885 4.21132C59.8522 4.21132 60.2342 4.06372 60.4889 3.57875C60.7436 3.09377 60.7436 2.77749 60.9134 2.56663L61.1681 2.3136L60.6587 2.48229V2.35577Z" fill="#173CBA"/>
<path d="M22.1156 4.06391V4.59106L19.9507 4.00066V3.47351L22.1156 4.06391Z" fill="#173CBA"/>
<path d="M19.9507 3.47351V4.00066L18.1467 4.48563L17.7646 4.59106V4.06391L19.9507 3.47351Z" fill="#00CC99"/>
<path d="M21.6062 5.09716V5.60322L19.9507 5.13933V4.61218L21.6062 5.09716Z" fill="#FFD540"/>
<path d="M19.9506 4.61218V5.13933L18.2739 5.60322V5.07607L19.9506 4.61218Z" fill="#FF66CC"/>
<path d="M3.67162 7.77472C3.85917 7.77472 4.01121 7.62367 4.01121 7.43735C4.01121 7.25102 3.85917 7.09998 3.67162 7.09998C3.48407 7.09998 3.33203 7.25102 3.33203 7.43735C3.33203 7.62367 3.48407 7.77472 3.67162 7.77472Z" fill="#EC1A3B"/>
<path d="M3.5869 3.79007C3.5869 5.75105 1.97385 7.35358 0 7.37466V6.25711C0 5.6878 0.297139 5.18174 0.785297 4.90762L2.8865 3.70573C3.09874 3.57922 3.31098 3.51596 3.54445 3.49487H3.5869V3.79007Z" fill="#FF66CC"/>
<path d="M3.75684 3.79007C3.75684 5.75105 5.36988 7.35358 7.34373 7.37466V6.25711C7.34373 5.6878 7.04659 5.18174 6.55844 4.90762L4.45724 3.70573C4.24499 3.57922 4.03275 3.51596 3.79928 3.49487H3.75684C3.77806 3.6003 3.75684 3.70573 3.75684 3.79007Z" fill="#173CBA"/>
<path d="M3.5869 11.1274C3.5869 9.16645 1.97385 7.56393 0 7.54285V8.66039C0 9.22971 0.297139 9.73577 0.785297 10.0099L2.8865 11.2118C3.09874 11.3383 3.31098 11.4015 3.54445 11.4226H3.5869V11.1274Z" fill="#00CC99"/>
<path d="M3.75684 11.1274C3.75684 9.16645 5.36988 7.56393 7.34373 7.54285V8.66039C7.34373 9.22971 7.04659 9.73577 6.55844 10.0099L4.45724 11.2329C4.24499 11.3594 4.03275 11.4226 3.79928 11.4437H3.75684C3.77806 11.3383 3.75684 11.2329 3.75684 11.1274Z" fill="#FFD540"/>
</svg> <span className="blog-site">collegementor.com</span>
</div>
  </div>  </div></a></div>
);

const Blogs: React.FC = () => {
  const blogsArticleData = useSelector(
    (state: RootState) => state.predictorLanding.blogsAndArticles
  );
  if (!blogsArticleData || blogsArticleData.length <= 0) return;

 
  const blogsContent =  blogsArticleData?.map((data, index) => (
    <Card {...data}/> ));



  return (
    <div className="bg-[#EEF2FF] min-h-[329px] my-7 pb-12">
      <h1 className="news-alerts-title text-center pt-9 mb-10 min-max-xs:mb-7 min-max-xss:text-3xl text-[#383838] text-[40px] font-medium">
      Blogs and Articles      </h1>
      <div className="container">
        <CustomSwiper
          className="!px-2"
          items={blogsContent}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 3, spaceBetween: 20 },
            1024: { slidesPerView: 4, spaceBetween: 20 }, 
            1280: { slidesPerView: 6, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      </div>
     
    </div>
  );
};

export default Blogs;
