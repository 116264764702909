// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloads-title {
  color: #383838;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
}

.exam-downloads {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.download-paper {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.download-jee {
  font-size: 13px;
  color: #262626;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/screens/exams/components/ExamDownloads.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAEA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,WAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGF;;AADA;EACE,eAAA;EACA,cAAA;EACA,mBAAA;EAEA,kBAAA;EACA,gBAAA;AAGF","sourcesContent":[".downloads-title {\n  color: #383838;\n  text-align: center;\n  font-family: Roboto;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 60px;\n  text-align: center;\n}\n.exam-downloads {\n  color: #fff;\n  text-align: center;\n  font-family: \"Open Sans\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n.download-paper {\n  color: #fff;\n  text-align: center;\n  font-family: \"Open Sans\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n.download-jee {\n  font-size: 13px;\n  color: #262626;\n  font-family: Roboto;\n\n  font-style: normal;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
