import React from "react";
import Stepstofollow from "./admissions/StepstoFollow";
import AdmissionIndia from "./admissions/AdmissionIndia";
import AdmissionInternational from "./admissions/AdmissionInternational";
import ClgScholarship from "./admissions/Scholarship";
import ReCounselling from "./admissions/ResultsCounselling";
import FaqAccordion from "../../career-details/components/FaqAccordion";
import FaqCollegesection from "./courseFees/FaqCollegesection";

interface AdmissionsTabProps {}

const AdmissionsTab: React.FC<AdmissionsTabProps> = ({}) => {
  return (
    <div>
      <Stepstofollow />
      <AdmissionIndia />
      <AdmissionInternational />
      <ClgScholarship />
      <ReCounselling />
      <FaqCollegesection />
    </div>
  );
};

export default AdmissionsTab;
