// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-section {
  margin: 40px 0;
  height: 555px;
}

.parent {
  display: flex;
  flex-direction: row;
  width: 1360px;
  margin: 0 auto;
}

.banner-left {
  text-align: left;
  height: 555px;
  width: 55%;
  align-content: center;
}

.banner-right {
  width: 45%;
  justify-content: center;
}

.small-circle {
  position: relative;
}

.student {
  position: absolute;
  top: -60px;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 660px;
  height: 360px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#close-btn {
  background-color: #ccc;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/banner-section/Banner.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;AACF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,UAAA;EACA,qBAAA;AAEF;;AACA;EACE,UAAA;EACA,uBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EACE,kBAAA;EACA,UAAA;AAIF;;AAAA;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,wCAAA;EACA,aAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;AAGF;;AAAA;EACE,sBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAGF","sourcesContent":[".banner-section {\n  margin: 40px 0;\n  height: 555px;\n}\n\n.parent {\n  display: flex;\n  flex-direction: row;\n  width: 1360px;\n  margin: 0 auto;\n}\n.banner-left {\n  text-align: left;\n  height: 555px;\n  width: 55%;\n  align-content: center;\n}\n\n.banner-right {\n  width: 45%;\n  justify-content: center;\n}\n.small-circle {\n  position: relative;\n}\n.student {\n  position: absolute;\n  top: -60px;\n}\n\n// modal styles starts here\n.modal {\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 660px;\n  height: 360px;\n  background-color: white;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);\n  padding: 20px;\n}\n\n.modal-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n\n#close-btn {\n  background-color: #ccc;\n  padding: 5px 10px;\n  border: none;\n  border-radius: 3px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
