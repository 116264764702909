// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 321px) and (max-width: 450px) {
  .top-mentors h3 {
    color: var(--Black, #000);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    text-transform: uppercase;
  }
  .top-mentors p {
    color: #292929;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
  }
}
.map-mentor {
  border: 1px solid #173cba;
  color: white;
  background-color: #173cba;
}`, "",{"version":3,"sources":["webpack://./src/components/courses/MeetTopMentors.scss"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAA;IACA,mBAAA;IACA,eAAA;IACA,kBAAA;IACA,gBAAA;IACA,iBAAA,EAAA,aAAA;IACA,yBAAA;EACF;EACA;IACE,cAAA;IACA,wBAAA;IACA,eAAA;IACA,kBAAA;IACA,gBAAA;IACA,iBAAA;EACF;AACF;AACA;EACE,yBAAA;EACA,YAAA;EACA,yBAAA;AACF","sourcesContent":["@media (min-width: 321px) and (max-width: 450px) {\n  .top-mentors h3 {\n    color: var(--Black, #000);\n    font-family: Roboto;\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 36px; /* 128.571% */\n    text-transform: uppercase;\n  }\n  .top-mentors p {\n    color: #292929;\n    font-family: \"Open Sans\";\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 38px;\n  }\n}\n.map-mentor {\n  border: 1px solid #173cba;\n  color: white;\n  background-color: #173cba;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
