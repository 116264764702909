import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useForm, UseFormRegister } from "react-hook-form";

const FIELDS = [
  { key: "counselling", name: "Counselling", type: "dropdown" },
  { key: "homeState", name: "Home state", type: "dropdown" },
  { key: "casteGroup", name: "Caste group", type: "dropdown" },
  { key: "gender", name: "Gender", type: "radio" },
  { key: "speciallyAbled", name: "Specially abled", type: "radio" },
];

interface FormData {
  [field: string]: string | number;
}

const PredictionForm = () => {
  const data = useSelector(
    (state: RootState) => state.collegePredictorIntermediate.landingData
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    console.log(data);
  };

  return (
    <section className="py-12 bg-[#FFF6F1] text-black">
      <div className="container">
        <div className="shadow-xl bg-white p-4 w-fit mx-auto">
          <h5 className="text-xl font-bold text-center">
            <span className="text-[#42f554]">
              Title data not available from API yet
            </span>
          </h5>
          <form
            className="mt-6 flex flex-col gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            {FIELDS.map((field) => {
              if (data && data[field.key]) {
                switch (field.type) {
                  case "dropdown":
                    return (
                      <DropDown
                        fieldName={field.name}
                        field={field.key}
                        options={data[field.key]}
                        register={register}
                        errors={errors[field.key]?.message || ""}
                      />
                    );
                  case "radio":
                    return (
                      <Radio
                        fieldName={field.name}
                        field={field.key}
                        options={data[field.key]}
                        register={register}
                        errors={errors[field.key]?.message || ""}
                      />
                    );
                  case "input":
                }
              } else return <></>;
            })}
            <span className="text-sm font-semibold text-green">
              College predictor report will be sent to you on:
            </span>
            <div>
              <label className="text-xs text-grey">
                Enter Your Mobile Number
              </label>
              <input
                {...register("mobileNumber", {
                  required: "Mobile number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Invalid mobile number",
                  },
                })}
                className="w-full mt-2 py-1 rounded border border-grey-3"
              />
              <p className="text-red-500">{errors["mobileNumber"]?.message}</p>
            </div>
            <button
              type="submit"
              className="text-base font-semibold text-white px-12 py-2 bg-blue mx-auto rounded"
            >
              Predictor
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PredictionForm;

const DropDown = ({
  options,
  fieldName,
  register,
  field,
  errors,
}: {
  options: { key: string; value: string }[];
  fieldName: string;
  field: string;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  return (
    <div>
      <label className="text-xs text-grey">{fieldName}</label>
      <select
        className="w-full mt-2 py-1 rounded border border-grey-3 text-sm"
        {...register(field, { required: true })}
      >
        {options.map((option) => (
          <option key={option.key} value={option.key} className="text-sm">
            {option.value}
          </option>
        ))}
      </select>
      <p className="text-red-500">{errors}</p>
    </div>
  );
};

const Radio = ({
  options,
  fieldName,
  field,
  register,
  errors,
}: {
  options: { key: string; value: string }[];
  fieldName: string;
  field: string;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  return (
    <div>
      <label className="text-xs text-grey">{fieldName}</label>
      <div className="flex gap-4 mt-2">
        {options.map((option) => (
          <label key={option.key} className="flex gap-2 items-center text-sm">
            <input
              type="radio"
              value={option.key}
              className="text-sm"
              {...register(field, { required: `${fieldName} is required` })}
            />
            <span>{option.value}</span>
          </label>
        ))}
      </div>
      <p className="text-red-500">{errors}</p>
    </div>
  );
};
