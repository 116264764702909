// YearTabs.js
import { title } from "process";
import React, { useEffect, useLayoutEffect, useState } from "react";
import collegeLogo from "../../../../images/college/details/collegelogo.png";
import bookIcon from "../../../../images/college/details/bookicon.svg";
import { motion, useAnimation } from "framer-motion";

const CollegeNavigationList = [
  {
    id: 1,
    title: "NIRF Ranking",
    image: collegeLogo,
    content:
      "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
    years: {
      2024: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2023: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University)",
        },
        {
          title: "Dental",
          rank: 6,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2022: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content: "SRM Institute of Science and Technology (formerly known ",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2021: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2022. SRMIST has been ranked 12th in the overall category by NIRF in 2022.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2020: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2021. SRMIST has been ranked 12th in the overall category by NIRF in 2021.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
    },
  },
  {
    id: 2,
    title: "National Assessment & Accreditation Council",
    image: collegeLogo,
    content:
      "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in",
    years: {
      2022: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University)",
        },
        {
          title: "Dental",
          rank: 6,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2019: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content: "SRM Institute of Science and Technology (formerly known ",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2018: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2022. SRMIST has been ranked 12th in the overall category by NIRF in 2022.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2017: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2021. SRMIST has been ranked 12th in the overall category by NIRF in 2021.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2016: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
    },
  },
  {
    id: 3,
    title: "QS World University Ranking Asia",
    image: collegeLogo,
    content:
      "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",

    years: {
      2023: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University)",
        },
        {
          title: "Dental",
          rank: 6,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2022: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content: "SRM Institute of Science and Technology (formerly known ",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2020: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2022. SRMIST has been ranked 12th in the overall category by NIRF in 2022.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2016: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2021. SRMIST has been ranked 12th in the overall category by NIRF in 2021.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2015: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
    },
  },
  {
    id: 4,
    title: "Shanghai World University Ranking",
    image: collegeLogo,
    content:
      "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",

    years: {
      2000: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University)",
        },
        {
          title: "Dental",
          rank: 6,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      1999: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content: "SRM Institute of Science and Technology (formerly known ",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      1998: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2022. SRMIST has been ranked 12th in the overall category by NIRF in 2022.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      1997: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2021. SRMIST has been ranked 12th in the overall category by NIRF in 2021.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      1996: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
    },
  },
  {
    id: 5,
    title: "Shanghai (ARWU) by subject",
    image: collegeLogo,
    content:
      "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",

    years: {
      2015: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University)",
        },
        {
          title: "Dental",
          rank: 6,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2014: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content: "SRM Institute of Science and Technology (formerly known ",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2013: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2022. SRMIST has been ranked 12th in the overall category by NIRF in 2022.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2012: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2021. SRMIST has been ranked 12th in the overall category by NIRF in 2021.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
      2011: [
        {
          title: "University",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
          content:
            "SRM Institute of Science and Technology (formerly known as SRM University) has been accredited by NAAC with the Highest 'A++' Grade in the year 2020. SRMIST has been ranked 12th in the overall category by NIRF in 2020.",
        },
        {
          title: "Dental",
          rank: "06",
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Architecture",
          rank: 14,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Pharmacy",
          rank: 15,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
        {
          title: "Management",
          rank: 12,
          pdf: "https://www.srmist.edu.in/sites/default/files/files/2021-06/NAAC%20SSR%20Final%20-%20SRMIST%20-%20June%202021.pdf",
        },
      ],
    },
  },
];
const YearTabs = () => {
  const [activeInstitute, setActiveInstitute] = useState(
    CollegeNavigationList[0]
  );
  const [selectedYear, setSelectedYear] = useState(
    parseInt(
      Object.keys(activeInstitute.years).sort(
        (a, b) => parseInt(b) - parseInt(a)
      )[0]
    )
  );
  const [activeTab, setActiveTab] = useState<number | undefined>(1);

  const ContainerAnimationControls = useAnimation();
  const ContentAnimationControls = useAnimation();

  const startContainerAnimation = () => {
    ContainerAnimationControls.start({
      opacity: [0, 1],
      y: ["100%", 0],
      transition: { duration: 0.7, ease: "easeOut" },
    });
  };

  const startContentAnimation = () => {
    ContentAnimationControls.start({
      opacity: [0, 1],
      scale: [0, 1],
      transition: { duration: 0.7, ease: "easeOut" },
    });
  };

  useEffect(() => {
    startContainerAnimation();
    startContentAnimation();
  }, []);

  const handleTabClick = (year: number) => {
    setSelectedYear(year);
  };

  return (
    <div className="py-20" id="Accredation">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-6 w-full">
            <div className="flex flex-row gap-4 items-center justify-center">
              <h3 className="text-3xl font-extrabold text-[#231F40] text-center">
                {" "}
                SRM University- Accredation & Rankings{" "}
              </h3>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-full lg:w-1/4 bg-[#05B38B] rounded-2xl p-6">
                <ul className="flex flex-col gap-5">
                  {CollegeNavigationList.map((nav) => (
                    <li key={nav.id}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          setActiveInstitute(nav);
                          setSelectedYear(
                            parseInt(
                              Object.keys(nav.years).sort(
                                (a, b) => parseInt(b) - parseInt(a)
                              )[0]
                            )
                          );
                          setActiveTab(nav.id as number | undefined);
                          startContainerAnimation();
                        }}
                        className={`group cursor-pointer text-base font-medium flex flex-row items-start gap-2 ${
                          activeTab === nav.id
                            ? "text-white"
                            : "text-white/80 hover:text-white"
                        }`}
                      >
                        <img
                          src={bookIcon}
                          alt="book-icon"
                          className={`cursor-pointer text-base font-medium flex flex-row items-start gap-2 ${
                            activeTab === nav.id
                              ? "opacity-100"
                              : "opacity-80 group-hover:opacity-100"
                          }`}
                        />{" "}
                        {nav.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-full lg:w-3/4">
                <motion.div
                  initial={{ y: "100%", opacity: 0 }}
                  animate={ContainerAnimationControls}
                  className="flex flex-row relative"
                >
                  <div
                    className={`flex flex-col bg-[#F4F7FB] p-6 pr-24 rounded-2xl w-[800px] gap-6 relative`}
                  >
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-4 w-full">
                        <h3 className="text-black text-base font-extrabold tracking-[1.60px]">
                          {activeInstitute.title}
                        </h3>
                      </div>
                      <div className="flex flex-row gap-4 w-full">
                        {Object.keys(activeInstitute.years)
                          .sort((a, b) => parseInt(b) - parseInt(a))
                          .map((year) => (
                            <button
                              key={year}
                              onClick={() => {
                                handleTabClick(Number(year));
                                startContentAnimation();
                              }}
                              className={` border-b-2 p-1 text-base font-medium ${
                                selectedYear === Number(year)
                                  ? " text-[#020842] border-[#020842]"
                                  : "border-transparent text-[#6F6F6F]"
                              }`}
                            >
                              {year}
                            </button>
                          ))}
                      </div>
                    </div>
                    <motion.div
                      animate={ContentAnimationControls}
                      initial={{ scale: 0, opacity: 0 }}
                      className="years-content-sec grid grid-cols-3 grid-flow-row gap-4"
                    >
                      {(
                        activeInstitute.years[
                          selectedYear as keyof typeof activeInstitute.years
                        ] ?? []
                      ).map((info, index) => (
                        <div
                          key={index}
                          className={`inner-years-content bg-white p-4 rounded-2xl flex flex-col gap-4 ${
                            index == 0 ? "row-span-2" : ""
                          }`}
                          style={{
                            boxShadow: "0px 10px 20px 0px #0000000F",
                          }}
                        >
                          <div className="flex flex-col gap-1">
                            <h3 className="text-[#231F40] text-base font-semibold">
                              {info.title}
                            </h3>
                            <p className="text-[#003FBC] text-4xl font-semibold leading-[51px]">
                              {info.rank}
                            </p>
                            <p className="text-[#4F4F4F] text-sm">
                              {trimText(info.content, index == 0 ? 500 : 50)}
                            </p>
                          </div>
                          <div className="flex flex-row justify-center items-center mt-auto">
                            <a
                              href={info.pdf}
                              target="_blank"
                              rel="noreferrer"
                              className="flex flex-row border border-[#EB5757] py-2 px-3 justify-center items-center rounded-full text-sm leading-6 text-[#EB5757] gap-2"
                            >
                              Download PDF
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      ))}
                    </motion.div>
                  </div>
                  <div
                    className="flex flex-col gap-4 border bg-white border-[#E0E0E0] p-6 rounded-2xl w-[320px] absolute right-0 top-2/4 "
                    style={{
                      boxShadow: "0px 10px 20px 0px #0000000F",
                      transform: "translate(0%, -50%)",
                    }}
                  >
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <img
                        src={activeInstitute.image}
                        alt="college-logo"
                        className="object-cover"
                      />
                      <h3 className="text-[#231F40] text-lg font-semibold">
                        {activeInstitute.title}
                      </h3>
                      <p className="text-center text-sm text-[#4F4F4F]">
                        {" "}
                        {activeInstitute.content}{" "}
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function trimText(text: string | undefined, charLimit: number) {
  if (!text) return "";
  else if (text.length <= charLimit) {
    return text;
  }
  return text.substring(0, charLimit) + "...";
}

export default YearTabs;
