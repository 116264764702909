import React from "react";
import "./ContactBar.scss";
import phoneIcon from "../../images/contact-bar/phone.png";
import envlopeIcon from "../../images/contact-bar/envelope.png";

const menuItems = [
  "For students",
  "Architecture",
  "Engineering",
  "Medical",
  "Management",
  "Law",
  "Liberal arts",
  "Design",
  "Others",
];
export default function ContactBar() {
  return (
    <div className="relative z-10 hidden md:block contact-nav    bg-[#113CC0]  max-md:px-5">
      <div className="container mx-auto flex gap-5 justify-center items-center pt-4 font-medium text-white max-md:flex-wrap">
        <div className="contact-nav-left flex gap-[24px] justify-center items-center text-sm whitespace-nowrap">
          <nav className=" navbar-contact items-center">
            <ul className="nav-list-contact gap-[16px]">
              {menuItems.map((item, index) => (
                <li key={index} className="nav-item">
                  <a
                    href={`/${item.toLowerCase()}`}
                    className="nav-link-contact flex gap-[4px] "
                  >
                    {item}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
