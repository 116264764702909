import React, { useState } from "react";

const ReviewPage: React.FC = () => {
  const [reviewText, setReviewText] = useState("");

  const handleSubmitReview = () => {
    console.log("Submitting review:", reviewText);

    setReviewText("");
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-blue-500 text-white py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-center text-3xl font-bold">Write Your Review</h1>
        </div>
      </header>

      <main className="flex-1 flex justify-center items-center px-4">
        <div className="container mx-auto max-w-lg">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <textarea
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              placeholder="Enter your review here..."
              rows={6}
              className="w-full p-3 border border-gray-300 rounded-md mb-4 resize-y focus:outline-none focus:border-blue-500"
            ></textarea>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md shadow-md transition duration-300"
              onClick={handleSubmitReview}
            >
              Submit Review
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReviewPage;
