// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css (or your preferred stylesheet) */
/* Basic reset */
body,
h1,
p {
  margin: 0;
  padding: 0;
}

/* Center container vertically and horizontally */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

/* Header styles */
.not-found-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  width: 100%;
}

/* Main content styles */
.not-found-main {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/screens/notfound-page/NotFoundPage.scss"],"names":[],"mappings":"AAAA,8CAAA;AAEA,gBAAA;AACA;;;EAGE,SAAA;EACA,UAAA;AAAF;;AAGA,iDAAA;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA,EAAA,yBAAA;EACA,kBAAA;AAAF;;AAGA,kBAAA;AACA;EACE,sBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;AAAF;;AAGA,wBAAA;AACA;EACE,aAAA;AAAF","sourcesContent":["/* styles.css (or your preferred stylesheet) */\n\n/* Basic reset */\nbody,\nh1,\np {\n  margin: 0;\n  padding: 0;\n}\n\n/* Center container vertically and horizontally */\n.not-found-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full viewport height */\n  text-align: center;\n}\n\n/* Header styles */\n.not-found-header {\n  background-color: #333;\n  color: #fff;\n  padding: 20px;\n  width: 100%;\n}\n\n/* Main content styles */\n.not-found-main {\n  padding: 20px;\n}\n\n// /* Footer styles */\n// .not-found-footer {\n//   background-color: #333;\n//   color: #fff;\n//   padding: 10px 0;\n//   width: 100%;\n//   position: fixed;\n//   bottom: 0;\n//   text-align: center;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
