import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
} from "../customHooks/useSelectedFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FilterActions } from "../FilterEnum";

interface IFilterDropdownData {
  categoryName: string;
  categoryKey: string;
  options: { name: string; key: string }[];
}

interface IProps {
  selectedFilter: ISelectedFilter;
  updateSelectedFilter: IUpdateSelectedFilter;
  filterDropdownData: IFilterDropdownData;
}

const FilterDropdown: React.FC<IProps> = ({
  selectedFilter,
  updateSelectedFilter,
  filterDropdownData,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);

  function handleOptionClick(value: string): void {
    updateSelectedFilter(
      filterDropdownData.categoryKey,
      FilterActions.ChangeRadio,
      value
    );
  }

  return (
    <div className="text-base text-grey relative">
      <div
        className="flex items-center gap-12 px-4 py-2 bg-white rounded-md cursor-pointer"
        onClick={() => setDropDownOpen((prev) => !prev)}
      >
        <span>{filterDropdownData.categoryName}</span>
        <FontAwesomeIcon
          icon={faChevronDown}
          size="sm"
          className={`text-grey transition ${dropdownOpen ? "rotate-180" : ""}`}
        />
      </div>
      {dropdownOpen && (
        <div className="absolute top-full w-full translate-y-2 shadow-lg bg-white rounded-md overflow-hidden flex flex-col">
          {filterDropdownData.options.map((option, index) => {
            const isSelected =
              selectedFilter.radioFilters[filterDropdownData.categoryKey] ==
              option.key;
            return (
              <>
                {index > 0 && <div className="border-t border-t-grey-2"></div>}
                <div
                  className={`px-4 py-2 cursor-pointer ${
                    isSelected ? "bg-green-light" : "bg-white"
                  }`}
                  onClick={() => handleOptionClick(option.key)}
                >
                  {option.name}
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
