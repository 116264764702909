import React, { useEffect, useState } from "react";
import HoverCard from "../../../components/hover-card/HoverCard";
import FaqAccordion from "./FaqAccordion";
import { Swiper, SwiperSlide } from "swiper/react";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GoogleIcon from "@mui/icons-material/Google";
import { motion } from "framer-motion";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import DreamMentors, {
  dreamImagesCarosal,
} from "../../../components/dream-mentors/DreamMentors";
import axios from "axios";

const careerOptionsData = [
  {
    image: "/images/CareerDetails/overview-tab/ac01.png",
    title: "Chemical  Engineer",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/CareerDetails/overview-tab/ac02.png",
    title: "Environment  Engineer ",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/CareerDetails/overview-tab/ac03.png",
    title: "Metallurgy  Engineer",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
  {
    image: "/images/CareerDetails/overview-tab/ac04.png",
    title: "{overView}",
    description:
      "Blueprint your future with engineering and architectural career possibilities.",
  },
];

const quizData = {
  title: "Let's see do you how much you know about software engineer role",
  questions: [
    {
      question: "What is the full form of HTML?",
      options: [
        "Hyper text mark up language",
        "Higher text mark up language",
        "Hyper text make up language",
        "Hyper translation mark up language",
      ],
      answer: 0,
    },
    {
      question: "What is the first step in the software development lifecycle?",
      options: [
        "Design",
        "Implementation",
        "Testing",
        "Preliminary Investigation and Analysis",
      ],
      answer: 0,
    },
    {
      question:
        "What does RAD stand for in the context of software development?",
      options: [
        "Rapid Application Development",
        "Rapid Analysis and Design",
        "Rapid Application Design",
        "Rapid Analysis Development",
      ],
      answer: 0,
    },
    {
      question:
        "Which model involves breaking the development process into small parts called iterations?",
      options: [
        "Waterfall model",
        "Iterative model",
        "Incremental model",
        "Spiral model",
      ],
      answer: 0,
    },
  ],
};
const careerHistory = [
  {
    title: "Begining of Computing",
    img: "/images/CareerDetails/overview-tab/first-computer.png",
    date: "15 Feb - 1946",
    description:
      "The Beginning of Computing refers to the early stages and milestones in the development of computing technology.",
  },
  {
    title: "Fortran first programming language",
    img: "/images/CareerDetails/overview-tab/f.png",
    date: "15 Feb - 1946",
    description:
      "Fortran, short for Formula Translation, holds the distinction of being the first high-level programming language, with its roots dating back to the mid-1950.",
  },
  {
    title: "Begining of Computing",
    img: "/images/CareerDetails/overview-tab/first-computer.png",
    date: "15 Feb - 1946",
    description:
      "The Beginning of Computing refers to the early stages and milestones in the development of computing technology.",
  },
  {
    title: "Fortran first programming language",
    img: "/images/CareerDetails/overview-tab/f.png",
    date: "15 Feb - 1946",
    description:
      "Fortran, short for Formula Translation, holds the distinction of being the first high-level programming language, with its roots dating back to the mid-1950.",
  },
];

export const OverviewTab = ({ overView }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alternateCarrers, setCareers] = useState<any | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://mentormenteedev.com/api/career/get-alternate-careers"
        );
        setCareers(response.data);
      } catch (error) {
        console.error("Error making API request:", error);
      }
    };

    fetchData();
    return () => {};
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = "/images/CareerDetails/overview-tab/anatomy.png";
    link.download = "image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="who-is-software-engineer relative container my-16 h-[480px] flex mx-auto">
        <div className="w-[60%] content-center">
          <div className="flex flex-col text-sm font-medium leading-6 w-full pr-6 text-neutral-600">
            <div className="w-full text-3xl font-extrabold text-slate-800 max-md:max-w-full">
              Who is {overView}
            </div>
            <div className="mt-4 w-full max-md:max-w-full">
              A {overView} is a professional who applies principles of
              engineering and computer science to design, develop, and maintain
              software systems and applications. They are responsible for the
              entire software development lifecycle, from conceptualization and
              design to implementation, testing, and deployment.
            </div>
            <div className="mt-4 w-full max-md:max-w-full">
              {overView} typically work on a wide range of projects, including
              but not limited to web applications, mobile apps, operating
              systems, databases, and embedded systems. They collaborate with
              cross-functional teams, including product managers, designers, and
              other engineers, to understand requirements and deliver
              high-quality software solutions that meet the needs of clients or
              end-users.
            </div>
          </div>
        </div>
        <div className="w-[40%] relative">
          <div className="overview-tab-animation  flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col justify-center ">
              <div className="jss-parent">
                <div className="jss27 jss28">
                  <div className="jss29">
                    <span
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                        overflow: "hidden",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: 1,
                        border: 0,
                        margin: 0,
                        padding: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleOpenModal}
                    >
                      <img
                        className="object-cover h-full"
                        src="/images/overview-youtube.jpeg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                <div className="jss27 jss30">
                  <div className="jss27 jss44"></div>
                  <div className="jss27 jss45"></div>
                </div>
                <div className="jss27 jss31">
                  <div className="jss27 jss43"></div>
                </div>
                <div className="jss27 jss32"></div>

                <div className="jss27 jss42">
                  <span className="span42 z-10">
                    <img
                      className="india-logo"
                      sizes="100vw"
                      alt=""
                      src="/images/india-flag.png"
                      decoding="async"
                      data-nimg="fill"
                    />
                  </span>
                </div>
                <div className="jss27 jss39 jss40"></div>
                <div className="jss27 jss39 jss41"></div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
            <div
              id="modal"
              className="fixed inset-0 flex items-center justify-center z-50 w-full h-full rounded-lg"
              onClick={handleCloseModal}
            >
              <button
                className="absolute top-8 right-8"
                onClick={handleCloseModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  width={20}
                  height={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div
                className="modal-content relative m-0  duration-150 lg:max-h-[calc(100vh-80px)] w-4/5 h-96 lg:max-w-[calc(100vw-80px)] box-border mx-auto rounded-lg lg:h-[calc(100vh-80px)]"
                style={{
                  animation: "cbSlideOut .3s cubic-bezier(0,0,.2,1)",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/T6oKlQoHrIg?si=SRxz8q4ZyXoDqvb1?autoplay=1`}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="inset-0 w-full h-full left-0 top-0 rounded-lg"
                ></iframe>
              </div>
            </div>
          </>
        )}
      </div>
      {/* overviewTab section 2 */}
      <div className="overview-tab-section2 mb-10">
        <div className="flex container mx-auto gap-2 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-8/12 max-md:ml-0 max-md:w-full overflow-hidden">
            <div className="bg-[#f4f7fb] pt-12">
              <div className="flex justify-center items-center">
                <h3 className="text-black text-3xl font-extrabold">
                  Career Mentors
                </h3>
              </div>
              <div className="w-full relative p-6">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={3}
                  navigation={{
                    prevEl: ".career-mentor-swiper-button-prev",
                    nextEl: ".career-mentor-swiper-button-next",
                  }}
                  // autoplay={{
                  //   delay: 3000,
                  //   disableOnInteraction: false,
                  //   pauseOnMouseEnter: true,
                  // }}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Autoplay]}
                  loop={false}
                  className="h-full w-full flex flex-row justify-center items-center"
                >
                  {dreamImagesCarosal.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div key={index} className="w-full relative mb-20">
                        <img
                          src={item.dreamImgCarosal}
                          alt=" "
                          loading="lazy"
                          className="w-full rounded-2xl"
                        />

                        <div className="bg-[#ffffffdd] p-4 rounded-2xl w-4/5 absolute left-1/2 -translate-x-1/2 top-3/4 shadow-md">
                          <h3 className="">{item.name}</h3>
                          <p className="text-sm text-black">{item.details}</p>
                          <span className="flex items-center gap-2 text-[#113CC0] mt-4">
                            View details
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path
                                d="M9.12305 5.74414L5.09766 1.71875L6.15234 0.664062L11.9883 6.5L6.15234 12.3359L5.09766 11.2812L9.12305 7.25586H0V5.74414H9.12305Z"
                                fill="#113CC0"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="career-mentor-swiper-button-prev bg-white rounded-full border-2 flex items-center p-4 absolute -top-16 right-32">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={24}
                      height={24}
                    >
                      <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>{" "}
                  </button>
                </div>
                <div className="career-mentor-swiper-button-next bg-white rounded-full border-2 flex items-center p-4 absolute -top-16 right-12">
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={24}
                      height={24}
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-4/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto text-3xl font-extrabold text-slate-800 max-md:mt-10 max-md:max-w-full">
              <h3 className="max-md:max-w-full">What does {overView} do?</h3>
              <div className="mt-4 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                {overView}s play a crucial role in shaping technology,
                converting concepts into code, ensuring efficient and reliable
                software through creative problem-solving and collaborative
                project execution.Job Roles include Application
                Development,Coding and Programming,Testing and Quality
                Assurance,Software Architecture and Design,Product development.
              </div>
              <h3 className="mt-9 max-md:max-w-full">
                How to become a {overView}
              </h3>
              <div className="mt-4 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                To become a {overView} in India, typically earn a bachelor's
                degree in computer science, {overView}ing, or a related field,
                followed by continuous learning and skill development through
                workshops, online courses, and coding projects.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* overviewTab section 2 ends */}
      {/* overviewTab section 3  */}
      <div className="flex justify-center items-center bg-[#F4F7FB] px-16 py-16 bg-slate-100 max-md:px-5">
        <div className="flex flex-col max-w-full w-[688px]">
          <h2 className="self-center text-4xl font-extrabold text-center text-slate-800 max-md:max-w-full">
            Anatomy with Highlights
          </h2>
          <img
            loading="lazy"
            src="/images/CareerDetails/overview-tab/anatomy.png"
            alt="anotom"
            className="mt-11 w-full aspect-[0.7] max-md:mt-10 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-9 w-full max-md:flex-wrap max-md:max-w-full">
            <button
              onClick={downloadImage}
              className="flex gap-3 justify-center px-6 py-3.5 text-base font-semibold text-white blue-color button-styles rounded-[50px] max-md:px-5"
            >
              Download Here
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <g clip-path="url(#clip0_944_592)">
                  <path
                    d="M8.23169 15.1017C8.46386 15.334 8.73953 15.5183 9.04295 15.644C9.34636 15.7698 9.67159 15.8345 10 15.8345C10.3285 15.8345 10.6537 15.7698 10.9571 15.644C11.2605 15.5183 11.5362 15.334 11.7684 15.1017L14.4442 12.4258C14.5877 12.2672 14.6646 12.0594 14.6591 11.8455C14.6536 11.6317 14.5661 11.4282 14.4147 11.2771C14.2633 11.126 14.0595 11.0389 13.8457 11.0338C13.6318 11.0288 13.4242 11.1062 13.2659 11.25L10.8275 13.6892L10.8334 0.833333C10.8334 0.61232 10.7456 0.400358 10.5893 0.244078C10.433 0.0877974 10.221 0 10 0V0C9.77901 0 9.56705 0.0877974 9.41077 0.244078C9.25449 0.400358 9.16669 0.61232 9.16669 0.833333L9.15919 13.6733L6.73419 11.25C6.57783 11.0937 6.36579 11.006 6.14473 11.0061C5.92367 11.0062 5.7117 11.0941 5.55544 11.2504C5.39919 11.4068 5.31145 11.6188 5.31152 11.8399C5.3116 12.0609 5.39949 12.2729 5.55586 12.4292L8.23169 15.1017Z"
                    fill="currentColor"
                  />
                  <path
                    d="M19.1667 13.3333C18.9457 13.3333 18.7337 13.421 18.5774 13.5773C18.4211 13.7336 18.3333 13.9456 18.3333 14.1666V17.4999C18.3333 17.7209 18.2455 17.9329 18.0893 18.0892C17.933 18.2454 17.721 18.3332 17.5 18.3332H2.5C2.27899 18.3332 2.06702 18.2454 1.91074 18.0892C1.75446 17.9329 1.66667 17.7209 1.66667 17.4999V14.1666C1.66667 13.9456 1.57887 13.7336 1.42259 13.5773C1.26631 13.421 1.05435 13.3333 0.833333 13.3333C0.61232 13.3333 0.400358 13.421 0.244078 13.5773C0.0877974 13.7336 0 13.9456 0 14.1666L0 17.4999C0 18.163 0.263392 18.7988 0.732233 19.2677C1.20107 19.7365 1.83696 19.9999 2.5 19.9999H17.5C18.163 19.9999 18.7989 19.7365 19.2678 19.2677C19.7366 18.7988 20 18.163 20 17.4999V14.1666C20 13.9456 19.9122 13.7336 19.7559 13.5773C19.5996 13.421 19.3877 13.3333 19.1667 13.3333Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_944_592">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <div className="flex gap-3 justify-center my-auto">
              <div className="my-auto text-base font-semibold text-zinc-800">
                Share with
              </div>
              <div className="flex gap-1.5 justify-center">
                <a
                  href="https://www.google.com"
                  target="_blank"
                  className="flex justify-center items-center p-3.5 w-12 h-12 bg-white border border-solid border-[#E0E0E0] rounded-[50px]"
                  rel="noreferrer"
                >
                  <GoogleIcon />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="flex justify-center items-center p-3.5 w-12 h-12 bg-white border border-solid border-[#E0E0E0] rounded-[50px]"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  className="flex justify-center items-center p-3.5 w-12 h-12 bg-white border border-solid border-[#E0E0E0] rounded-[50px]"
                  rel="noreferrer"
                >
                  <XIcon />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER"
                  target="_blank"
                  className="flex justify-center items-center p-3.5 w-12 h-12 bg-white border border-solid border-[#E0E0E0] rounded-[50px]"
                  rel="noreferrer"
                >
                  <WhatsAppIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* overviewTab section 3 ends */}

      {/* overviewTab section 3 */}
      <div>
        <div className="flex container py-10 mx-auto gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-3/5 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 mt-10 text-sm max-md:mt-10 max-md:max-w-full">
              <div className="text-4xl font-extrabold  text-slate-800 max-md:max-w-full">
                Historical events of {overView}ing
              </div>
              <div className="relative flex flex-col gap-24 mt-12">
                {careerHistory.map((data, index) => historyItem(data, index))}
                <div className="absolute top-0 left-1/2 -translate-x-1/2 h-full w-2 bg-[#ddd]"></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col text-xs font-semibold max-md:mt-6">
              <div className="flex flex-col justify-center p-4 w-full bg-white rounded-3xl shadow-2xl">
                <div className="flex gap-4">
                  <img
                    loading="lazy"
                    src="/images/iit-madras.png"
                    alt=""
                    className="shrink-0 self-start aspect-square w-[92px]"
                  />

                  <div className="flex flex-col flex-1">
                    <div className="text-sm text-zinc-800">IIT Madras</div>
                    <div className="mt-1 text-neutral-600">
                      IIT Madras is an institute of National Importance that was
                      established in the year 1959.
                    </div>
                    <button className="justify-center px-5 py-2.5 mt-1 w-[105px] text-white bg-blue-800 text-xs rounded-[50px]">
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center p-4 mt-4 w-full bg-white rounded-3xl shadow-2xl">
                <div className="flex gap-4">
                  <img
                    loading="lazy"
                    src="/images/vit-vellore.png"
                    alt="vit vellore"
                    className="shrink-0 self-start aspect-square w-[92px]"
                  />
                  <div className="flex flex-col flex-1">
                    <div className="text-sm text-zinc-800">VIT Vellore</div>
                    <div className="mt-1 text-neutral-600">
                      Vellore Institute of Technology (VIT), Vellore is a
                      private deemed university established in 2001.
                    </div>
                    <button className="justify-center px-5 py-2.5 mt-1 w-[105px] text-white bg-blue-800 rounded-[50px]">
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center p-4 mt-4 w-full bg-white rounded-3xl shadow-2xl">
                <div className="flex gap-4">
                  <img
                    loading="lazy"
                    src="/images/thapar-university.png"
                    alt="thapar university"
                    className="shrink-0 self-start aspect-square w-[92px]"
                  />
                  <div className="flex flex-col flex-1">
                    <div className="text-sm text-zinc-800">
                      Thapar University
                    </div>
                    <div className="mt-1 text-neutral-600">
                      Established in the year 1956, Thapar University is a
                      private university, which is also known as TIET Patiala.
                    </div>
                    <button className="justify-center px-5 py-2.5 mt-1 w-[105px] text-white bg-blue-800 rounded-[50px]">
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* overviewTab section 3 ends */}
      {/* pros and cons section */}
      <div className="flex justify-center items-center px-16 py-20 bg-[#F4F7FB] max-md:px-5">
        <div className="w-full container mx-auto max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow p-6 w-full bg-white rounded-2xl border border-solid shadow-lg border-neutral-200 max-md:px-5 max-md:mt-6 max-md:max-w-full">
                <div className="justify-center px-4 py-1.5 text-3xl font-extrabold text-white whitespace-nowrap bg-[#27AE60] rounded-md max-md:max-w-full">
                  Pros
                </div>
                <div className="mt-4 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                  High Demand: Software developers are in high demand across
                  various industries, offering excellent job opportunities and
                  career growth prospects.
                  <br />
                  <br />
                  Creativity and Problem-Solving: The role allows for creativity
                  and innovation in solving complex technical challenges,
                  providing a rewarding and intellectually stimulating work
                  environment.
                  <br />
                  <br />
                  Flexibility and Remote Work: Many software development jobs
                  offer flexible work arrangements, including remote work
                  options, allowing for a better work-life balance and autonomy
                  in managing one's schedule.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow p-6 w-full bg-white rounded-2xl border border-solid shadow-lg border-neutral-200 max-md:px-5 max-md:mt-6 max-md:max-w-full">
                <div className="justify-center px-4 py-1.5 text-3xl bg-[#EB5757] font-extrabold text-white whitespace-nowrap  rounded-md max-md:max-w-full">
                  Cons
                </div>
                <div className="mt-4 text-sm font-medium leading-6 text-neutral-600 max-md:max-w-full">
                  High Pressure and Deadlines: Software development projects
                  often come with tight deadlines and high-pressure situations,
                  which can lead to stress and burnout if not managed
                  effectively.
                  <br />
                  <br />
                  Continuous Learning Curve: The field of software development
                  is constantly evolving, requiring developers to stay updated
                  with new technologies and tools, which can be demanding and
                  time-consuming.
                  <br />
                  <br />
                  Sedentary Lifestyle: Software development typically involves
                  long hours of sitting in front of a computer screen, which can
                  lead to health issues such as eye strain, back problems, and a
                  sedentary lifestyle if not balanced with regular exercise and
                  breaks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* pros and cons section ends */}

      {/* slider  */}
      <div className="green-bg">
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {quizData.questions.map((question) => (
            <SwiperSlide>
              <div className="flex flex-col mx-auto w-fit max-w-[50%] py-[52px] items-center ">
                <div className="w-max text-lg max-md:max-w-full">
                  {quizData.title}
                </div>
                <div className="w-fit mt-6 text-2xl">{question.question}</div>
                <div className="flex w-full mt-1">
                  <div className="w-1/2 flex flex-col gap-5 text-base mt-2.5 max-w-full max-md:flex-wrap">
                    <div>A. {question.options[0]}</div>
                    <div>C. {question.options[2]}</div>
                  </div>
                  <div className="w-1/2 flex flex-col gap-5 text-base mt-2.5 max-w-full max-md:flex-wrap">
                    <div>B. {question.options[1]}</div>
                    <div>D. {question.options[3]}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* slider ends */}

      {/* alternative career options section */}
      <div className="alternative-career-options my-10">
        <div className="container mx-auto">
          <h3 className="text-center text-3xl text-[#231F40] font-extrabold mb-9">
            Alternative Career Options
          </h3>
          <div className="flex gap-[24px]">
            {alternateCarrers &&
              alternateCarrers.map((card: any) => {
                return <HoverCard data={card} />;
              })}
          </div>
        </div>
      </div>
      {/* alternative career options section ends */}

      {/* FAQ section */}
      <div className="overview-tab-faq">
        <div className="flex justify-center w-[1200px] items-center px-16 py-20 text-base mx-auto rounded-3xl mb-10 bg-[#F4F7FB] max-md:px-5">
          <div className="flex flex-col mt-7 w-full max-w-4xl max-md:max-w-full">
            <div className="self-center text-3xl font-semibold text-center text-black max-md:max-w-full">
              FAQ's About {overView}
            </div>
            <FaqAccordion />
          </div>
        </div>
      </div>
      {/* FAQ section ends */}

      {/* section 8 */}
      <div className="flex flex-col items-center px-20 py-7 text-2xl font-semibold text-center text-white bg-[#00C798] mb-10 max-md:px-5">
        <div>Still Confused?</div>
        <a href="/mentor" className="flex gap-1.5 max-md:flex-wrap">
          Chat with our expert mentor and get more clarity
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M30.0003 19.9998C29.9922 19.1229 29.6389 18.2846 29.017 17.6665L21.867 10.4998C21.5547 10.1894 21.1323 10.0151 20.692 10.0151C20.2517 10.0151 19.8293 10.1894 19.517 10.4998C19.3608 10.6547 19.2368 10.8391 19.1522 11.0422C19.0676 11.2453 19.024 11.4631 19.024 11.6831C19.024 11.9031 19.0676 12.121 19.1522 12.3241C19.2368 12.5272 19.3608 12.7115 19.517 12.8665L25.0003 18.3331H8.33366C7.89163 18.3331 7.46771 18.5087 7.15515 18.8213C6.84259 19.1338 6.66699 19.5578 6.66699 19.9998C6.66699 20.4418 6.84259 20.8657 7.15515 21.1783C7.46771 21.4909 7.89163 21.6664 8.33366 21.6664H25.0003L19.517 27.1498C19.2032 27.4614 19.026 27.8849 19.0244 28.3272C19.0228 28.7695 19.197 29.1943 19.5087 29.5081C19.8203 29.8219 20.2438 29.9991 20.6861 30.0007C21.1284 30.0023 21.5532 29.8281 21.867 29.5164L29.017 22.3498C29.643 21.7275 29.9966 20.8824 30.0003 19.9998Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      {/* section 8 ends */}
    </div>
  );
};

function historyItem(
  data: { title: string; img: string; date: string; description: string },
  index: number
): JSX.Element {
  return (
    <div
      className="flex max-md:flex-wrap max-md:pr-5 max-md:mt-10 justify-between"
      style={{ flexDirection: index % 2 == 0 ? "row" : "row-reverse" }}
    >
      <motion.div
        className="flex justify-start gap-4 p-5 text-sm font-semibold bg-white rounded-3xl shadow-2xl w-[305px] text-zinc-800"
        initial={{ x: index % 2 == 0 ? -150 : 150, opacity: 0 }}
        whileInView={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 4,
            type: "spring",
            stiffness: 150,
            damping: 10,
            delay: 0.2,
          },
        }}
      >
        <img
          loading="lazy"
          src={data.img}
          alt="first computer"
          className="shrink-0 aspect-square w-[92px]"
        />
        <div className="my-auto">{data.title}</div>
      </motion.div>
      <motion.div
        className="shrink-0  my-auto bg-[#113CC0] rounded-full h-[26px] w-[26px] z-10"
        initial={{ scale: 0, opacity: 0 }}
        whileInView={{
          scale: 1,
          opacity: 1,
          transition: {
            duration: 4,
            type: "spring",
            stiffness: 300,
            damping: 20,
            delay: 0.2,
          },
        }}
      />
      <motion.div
        className="flex flex-col py-6 w-[305px]"
        initial={{ x: index % 2 == 0 ? -150 : 150, opacity: 0 }}
        whileInView={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 4,
            type: "spring",
            stiffness: 300,
            damping: 20,
            delay: 0.2,
          },
        }}
      >
        <div className="font-semibold text-zinc-800">{data.date}</div>
        <div className="mt-1 text-neutral-600">{data.description}</div>
      </motion.div>
    </div>
  );
}
