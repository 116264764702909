import React from "react";

import type { SVGProps } from "react";
import StudyAbroadBannerBG from "../../../images/study-abroad/banner-bg.png";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}

interface BannerData {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

const bannerData: BannerData[] = [
  {
    title: "Studying Abroad",
    subtitle: "Can Change the Way You See the World",
    description:
      "We Partner with Top Universities Globally. The World is Ready for You - Are You Ready for the World?",
    buttonText: "Book your Campus Tour",
    buttonLink: "#",
  },
];

const Banner: React.FC = () => {
  return (
    <div
      className="relative w-full flex flex-col"
      style={{
        backgroundImage: `url(${StudyAbroadBannerBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src="your-video-url.mp4" type="video/mp4" />
      </video>
      <div className="container mx-auto relative z-10">
        <div className="py-16 lg:py-40">
          {bannerData.map((banner, index) => (
            <div
              key={index}
              className="relative z-10 flex flex-col items-start gap-6 justify-center h-full text-white"
            >
              <div className="flex flex-col gap-5">
                <div className="flex flex-col">
                  <h2 className="text-4xl lg:text-5xl font-bold leading-10 lg:leading-[55px]">
                    {banner.title}
                  </h2>
                  <p className="text-4xl lg:text-5xl leading-10 lg:leading-[55px] font-thin">
                    {banner.subtitle}
                  </p>
                </div>
                <div className="flex flex-col w-full lg:w-35rem">
                  <p className="text-base lg:text-xl">{banner.description}</p>
                </div>
              </div>
              <div className="flex flex-col">
                <button className="hover:bg-blue text-white py-3.5 px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-base font-semibold">
                  {banner.buttonText} <EpRight />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-0"></div>
    </div>
  );
};

export default Banner;
