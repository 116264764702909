import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { FormInput } from "./MyProfile";
import ProfileDetailEditModal from "./ProfileDetailEditModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const DYMMY_API_RESPONSE: {
  [key: string]: string;
} = {
  gender: "male",
  email: "barath2001.j@gmail.com",
  city: "tamil-nadu",
};

interface IProps {
  name: string;
  subCategory: {
    name?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string; name: string }[];
      validationRegex?: RegExp;
    }[];
  }[];
}

const ProfileDetailCard: FC<IProps> = ({ name, subCategory }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const userData = useSelector((state: RootState) => state.myProfile.userData);

  if (!userData) return;

  return (
    <>
      <div className="bg-white rounded-lg p-2">
        <div className="flex items-center justify-between p-2">
          <h2 className="text-xl font-bold">{name}</h2>
          <button>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => setShowEditPopup(true)}
            />
          </button>
        </div>
        {subCategory.map((subCategory) => (
          <div className="p-2 border-b last:border-none">
            {subCategory.name && (
              <h3 className="text-md font-medium text-grey mb-1">
                {subCategory.name}
              </h3>
            )}
            <div className="grid grid-cols-3">
              {subCategory.fields.map((field) => {
                return (
                  <div>
                    <div>{field.name}</div>
                    <div className="font-bold">
                      {getFieldValue(field, userData)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <ProfileDetailEditModal
        isOpen={showEditPopup}
        onClose={() => setShowEditPopup(false)}
        name={name}
        subCategory={subCategory}
      />
    </>
  );
};

export default ProfileDetailCard;

const getFieldValue = (
  field: {
    key: string;
    type: FormInput;
    options?: { key: string; name: string }[];
  },
  userData: { [key: string]: string | number }
) => {
  let value: string | number = "N/A";

  if (userData[field.key])
    if ([FormInput.Dropdown, FormInput.Radio].includes(field.type))
      value =
        field.options?.find((option) => option.key == userData[field.key])
          ?.name || "N/A";
    else value = userData[field.key];

  return value;
};
