import Banner from "./components/Banner"
const ScholarshipLanding = () =>
{
return (

    <>
    <div>
<Banner/>
 </div>
    </>
)
}

export default ScholarshipLanding