import React, { useEffect, useRef, useState } from 'react';
import { GiVolleyballBall, GiBasketballBall, GiTennisBall } from "react-icons/gi";
import { TbBuildingStadium } from "react-icons/tb";
import { MdPool, MdOutlineSportsHockey, MdSportsCricket, MdSportsTennis } from "react-icons/md";
import { IoFootball } from "react-icons/io5";
import { FaRunning } from "react-icons/fa";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import badminton from '../Images/badminton.jpeg'
import outdoor_game from '../Images/outdoor_game.jpeg'
import indoor_stadium from '../Images/indoor_stadium.jpeg'
import fitness_gym from '../Images/fitness_gym.jpeg'
import yoga from '../Images/yoga.jpeg'
import { IoMdArrowForward } from "react-icons/io";
import { BiPlus, BiMinus } from "react-icons/bi";
interface SportsProps {
    id: string;
}
interface PerformanceData {
    id: number;
    sport: string;
    gender: string | null;
}
const performanceData: PerformanceData[] = [
    { id: 1, sport: "Volleyball", gender: "Men" },
    { id: 2, sport: "Volleyball", gender: "Women" },
    { id: 3, sport: "Football", gender: null },
    { id: 4, sport: "Kabaddi", gender: null },
    { id: 5, sport: "Chess", gender: "Men" },
    { id: 6, sport: "Chess", gender: "Women" },
    { id: 7, sport: "Table Tennis", gender: "Women" },
    { id: 8, sport: "Table Tennis", gender: "Men" },
    { id: 9, sport: "Tennis", gender: "Women" },
    { id: 10, sport: "Tennis", gender: "Men" },
    { id: 11, sport: "Hockey", gender: "Men" },
    { id: 12, sport: "Cricket", gender: "Men" },
    { id: 13, sport: "Badminton", gender: "Men" },
    { id: 14, sport: "Badminton", gender: "Women" },
    { id: 15, sport: "Ball Badminton", gender: "Men" },
    { id: 16, sport: "Ball Badminton", gender: "Women" },
    { id: 17, sport: "Performance of SRMIST in the Khelo India University tournaments", gender: null }
];
const sportsFacilities = [
    { quantity: '5', name: "Floodlit Volleyball Courts", icon: <GiVolleyballBall style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '4', name: "Floodlit Concrete Basketball Courts", icon: <GiBasketballBall style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '2', name: "Floodlit Synthetic Tennis Courts", icon: <GiTennisBall style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '4', name: "Floodlit Ball Badminton Courts", icon: <MdSportsTennis style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '2', name: "Indoor Stadiums", icon: <TbBuildingStadium style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '1', name: "A/C Multipurpose Indoor Stadium", icon: <TbBuildingStadium style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '400', name: "Floodlit Standard Track & Field", icon: <FaRunning style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '6', name: "Synthetic Badminton Courts (Indoor)", icon: <MdSportsTennis style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '2', name: "Football Fields (1 Turf)", icon: <IoFootball style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '2', name: "Hockey Fields", icon: <MdOutlineSportsHockey style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '1', name: "International Standard Swimming Pool - (UC)", icon: <MdPool style={{ fontSize: '3rem', color: '#FFFFFF' }} /> },
    { quantity: '1', name: "Cricket Field (Turf Wicket) - (UC)", icon: <MdSportsCricket style={{ fontSize: '3rem', color: '#FFFFFF' }} /> }
];
const sportsImg = [
    'badminton.jpeg',
    'basketball.jpeg',
    'football.jpeg',
    'running.jpeg',
    'soccer.jpeg',
    'vallyball.webp',
    'winning.jpeg',
    'badminton.jpeg',
    'football.jpeg',
]
const Sports: React.FC<SportsProps> = ({ id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const handleRotate = () => {
        setIsRotated(prevState => !prevState);
    };
    const [toggle, setToggle] = useState(false);
    const [heightEl, setHeightEl] = useState<string | undefined>(undefined);
    const refHeight = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (refHeight.current) {
            setHeightEl(`${refHeight.current.scrollHeight}px`);
        }
    }, []);
    const toggleState = (index: number, item: PerformanceData) => {
        if (index + 1 == item.id) {
            setToggle(!toggle)
        }
    };
    return < >
        <div id={id} className='container'>
            <div className='sports_text_heading my-3'>Sports Overview</div>
            <div className='sports_text_subheading'>Sport is any form of physical activity or game, which is often competitive in nature. Sports contribute to the health of anyone by generating physical and mental energy that can be transformed into action, creativity, and innovation. It is organised with the aim of using, maintaining, or improving physical ability and skills while providing enjoyment for the participants and entertainment for the spectators. It is an integral part of human life and there is a great importance of sports in all spheres of life</div>
            <div className='my-5'>
                <div className='sports_text_heading1'>Sports Facilities & Infrastructure</div>
                <div className="grid-container mt-10">
                    {sportsFacilities.map((facility, index) => (
                        <div key={index} className="card_container shadow-lg flex flex-col h-full">
                            <div className="grid grid-cols-12 gap-0 h-full">
                                <div style={{ background: '#113CC0' }} className="p-4 col-span-4 rounded-lg shadow-md flex justify-center items-center w-full">
                                    {facility.icon}
                                </div>
                                <div className="p-4 flex col-span-8 flex-col justify-between w-full">
                                    <div className="mb-4">
                                        <div style={{ color: '#113CC0', fontSize: '2rem' }} className="text-xl text-center font-bold mb-2">
                                            {facility.quantity === "400" ? <div className='flex justify-center'>
                                                <CountUp end={Number(facility.quantity)} duration={0.6} redraw={true}>
                                                    {({ countUpRef, start }) => {
                                                        return <>
                                                            <VisibilitySensor onChange={start} delayedCall>
                                                                <span ref={countUpRef} />
                                                            </VisibilitySensor>
                                                        </>
                                                    }}
                                                </CountUp>
                                                <h1 className='ms-1'>m</h1>
                                            </div> : <CountUp end={Number(facility.quantity)} duration={0.6} redraw={true}>
                                                {({ countUpRef, start }) => {
                                                    return <>
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    </>
                                                }}
                                            </CountUp>}
                                        </div>
                                        <div className='game_courts'>{facility.name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-3 gap-0 my-3">
                    {sportsImg.map((imageUrl, index) => {
                        return (
                            <div key={index} className="relative">
                                <img src={require(`../Images/${imageUrl}`)} alt={`Image ${index}`} className="w-full h-full object-cover" />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className=" grid grid-cols-12 gap-0 items-center my-5">
                <div className="col-span-2 " style={{ position: 'relative', left: '25%' }}>
                    <div>
                        <img src={outdoor_game} style={{ width: '100%' }} />
                    </div>
                </div>
                <div className="bg-gray-200 p-20 col-span-10 " style={{ width: '100%', background: '#E0E8F2' }}>
                    <div className="ms-20">
                        <div style={{ fontSize: '18px', fontWeight: '600', font: 'Poppins' }}>Outdoor Games</div>
                        <div className='sports_text_subheading'>
                            Most popular sports like basketball, vollyball, football, cricket, tennis, kabbadi, badminton are played by a large number of students in the evenings as well as early morning in our beautiful sprawling outdoor grounds and courts.
                        </div>
                    </div>
                </div>
            </div>
            <div className=" grid grid-cols-12 gap-0 items-center my-5">
                <div className="bg-gray-200 p-20 col-span-10 " style={{ width: '100%', background: '#E0E8F2' }}>
                    <div className="ms-20">
                        <div style={{ fontSize: '18px', fontWeight: '600', font: 'Poppins' }}>Indoor Stadiums</div>
                        <div className='sports_text_subheading'>
                            Indoor sports activity is vibrant and our students have access to a vast range of indoor sports like Table Tennis, Shuttle, and other fitness activitis.</div>
                    </div>
                </div>
                <div className="col-span-2 " style={{ position: 'relative', right: '25%' }}>
                    <div>
                        <img src={indoor_stadium} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className=" grid grid-cols-12 gap-0 items-center my-5">
                <div className="col-span-2 " style={{ position: 'relative', left: '25%' }}>
                    <div>
                        <img src={fitness_gym} style={{ width: '100%' }} />
                    </div>
                </div>
                <div className="bg-gray-200 p-20 col-span-10 " style={{ width: '100%', background: '#E0E8F2' }}>
                    <div className="ms-20">
                        <div style={{ fontSize: '18px', fontWeight: '600', font: 'Poppins' }}>Fitness Center</div>
                        <div className='sports_text_subheading'>For students to stay fit and healthy, all hostels are equipped with gym and fitness centre facilities – treadmill, exercise bikes and more of the latest fitness equipment. </div>
                        <div className='sports_text_subheading my-3'>The central gym for men at SRM is also equipped with body building equipment.</div>
                        <div className='sports_text_subheading my-3'>All gyms and fitness centers are open both in the morning and evening hours.</div>
                    </div>
                </div>
            </div>
            <div className=" grid grid-cols-12 gap-0 items-center my-5">
                <div className="bg-gray-200 p-20 col-span-10 " style={{ width: '100%', background: '#E0E8F2' }}>
                    <div className="ms-20">
                        <div style={{ fontSize: '18px', fontWeight: '600', font: 'Poppins' }}>A Healthy Body And A Healthy Mind With Yoga</div>
                        <div className='sports_text_subheading'>100s of students and staff of SRM Institute of Science and Technology (SRMIST) participated in the virtual celebration of International Day of Yoga. Large scale Yoga programs are held in the many facilities within the large sprawling campus of SRMIST including in its multipurpose indoor stadium, lawns, playgrounds, auditoriums. Renowned guests and Yoga specialists often visit our campus every year to help our students and staff master the ancient Indian gift to the world’s wellbeing and health. There is also students run Yoga club within SRMIST</div>
                    </div>
                </div>
                <div className="col-span-2 " style={{ position: 'relative', right: '25%' }}>
                    <div>
                        <img src={yoga} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className='bg_sports_img_container my-5'>
                <div className=' bg_sports_img'>
                    <div className='heading_sport_achievement'>Sporting Achievements</div>
                    <div className='px-20 mt-10'>
                        <div className='my-5 sports_text_subheading' style={{ color: '#FFFFFF', position: 'relative', zIndex: '999' }}>SRM Institute of Science and Technology values the inspiration and accomplishments that sports enable to our students and our institution. It is with this unflinching conviction that we are dedicated to keep scaling up our facilities, support, funding and commitment towards excellence in sports. </div>
                        <div className='my-5 sports_text_subheading' style={{ color: '#FFFFFF', position: 'relative', zIndex: '999' }}>SRM Institute of Science and Technology values the inspiration and accomplishments that sports enable to our students and our institution. It is with this unflinching conviction that we are dedicated to keep scaling up our facilities, support, funding and commitment towards excellence in sports. </div>
                        <div className='my-5 sports_text_subheading' style={{ color: '#FFFFFF', position: 'relative', zIndex: '999' }}>SRM Institute of Science and Technology values the inspiration and accomplishments that sports enable to our students and our institution. It is with this unflinching conviction that we are dedicated to keep scaling up our facilities, support, funding and commitment towards excellence in sports. </div>
                        <div className='flex justify-center'>
                            <button className="all_sports shadow-lg">
                                <span className="all_sports-content flex items-center">All Sports Acheivements <IoMdArrowForward className='ms-3' /> </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='bg_sports_img_overlay'></div>
            </div>
            <div className=''>
                <div className='text-center heading_campus'>South Zone / All India University Tournaments</div>
                <div className='sports_text_subheading'>SRMIST plays a pivotal role in nurturing students to make them excel in sports by actively participating in various national-level competitions. Notably, in the Inter University Tournaments, encompassing the South Zone, All India Inter University, and Khelo India Universities Games. SRMIST encourages younger athletes to engage and involve more in the sporting activities, providing them with well equipped training facilities. The institution’s dedication in providing comprehensive opportunities for students to improvise not only academically but also athletically</div>
            </div>
            {performanceData.map((p, index) => {
                return (
                    <>
                        <div className="accordion my-3">
                            <button onClick={() => toggleState(index, p)} className="accordion-visible">
                                <span>{p.sport} ({p.gender})</span>
                                {toggle ? <BiMinus className={toggle ? "active" : ""} /> : <BiPlus className={toggle ? "active" : ""} />}
                            </button>
                            <div
                                className={toggle ? "accordion-toggle animated" : "accordion-toggle"}
                                style={{ height: toggle ? heightEl : "0px" }}
                                ref={refHeight}
                            >
                                <p aria-hidden={toggle ? "true" : "false"}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, suscipit quae maiores sunt ducimus est dolorem perspiciatis earum corporis unde, dicta quibusdam aut placeat dignissimos distinctio vel quo eligendi ipsam.
                                </p>
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    </>;
};
export default Sports;